import React, {useEffect, useState, useContext, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box, Link, Typography, Grid, IconButton, InputAdornment} from '@mui/material';
import {IUser, IMonthlyReviewResponse} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {COPIES, LOADING_MESSAGE, MONTHLY_REVIEW_STATUS, LEARN_MORE_LINK} from '~/constants';
import {getAbbreviationFromBrackets} from '~/utils/nameUtils';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {TableButton} from '~/components/Common/Button/TableButton';
import {createMonthlyReview, getRequiresMonthlyReview} from '~/services/userServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import CloseIcon from '@mui/icons-material/Close';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import './UserList.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setTeam} from '~/store/team/team.thunk';
import {setUsers} from '~/store/user/user.thunk';
import {getUsers} from '~/store/user/user.selector';
import {getUsersFetchState, getTeamFetchState} from '~/store/fetchState/fetchState.selector';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';

export const UserList = () => {
    const dispatch = useAppDispatch();
    const {accessToken} = useContext(AuthenticationContext);
    const history = useHistory();
    const {teamId} = useParams<{teamId: string}>();

    let agencyName = (accessToken as IAccessToken).AdditionalUserInfo.Agency || '';
    agencyName = getAbbreviationFromBrackets(agencyName) !== '' ? getAbbreviationFromBrackets(agencyName) : agencyName;
    const opsUnitName = (accessToken as IAccessToken).AdditionalUserInfo.OpsUnit;
    const teamName = (accessToken as IAccessToken).AdditionalUserInfo.Team;

    const users = Object.values(useTypedSelector(getUsers(Number(teamId))));
    const teamDetails = useTypedSelector((state) => state.data.admin.teams[Number(teamId)]);

    const usersFetchState = useTypedSelector(getUsersFetchState);
    const teamFetchState = useTypedSelector(getTeamFetchState);
    const fetchStatus = consolidateFetchState([teamFetchState, usersFetchState]);

    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const [searchValue, setSearchValue] = useState('');

    const handleEdit = (userId: number) => {
        history.push(`/admin/teams/${teamId}/users/${userId}`);
    };

    const handleClick = () => {
        history.push(`/admin/teams/${teamId}/users/create`);
    };

    useEffect(() => {
        dispatch(setUsers(Number(teamId)));
        dispatch(setTeam(Number(teamId)));
        getRequiresMonthlyReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const userDetails = (user: IUser) => {
        return (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {user.Name}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {user.Designation}
                </Box>
                {!user.IsActive && <SuspendedIcon />}
            </Box>
        );
    };

    const getAdminText = (userId: number | undefined): string => {
        if (!userId || !teamDetails || !teamDetails.Admins) {
            return '';
        }
        if (
            teamDetails.Admins.find((admin) => {
                return admin.Id == userId;
            })
        ) {
            return 'Team Admin';
        }
        return 'Ops Unit Admin';
    };

    const userLastUpdatedAdmin = (user: IUser) => {
        return user.UpdatedByUser ? (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {user.UpdatedByUser?.Name}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {getAdminText(user.UpdatedByUser?.Id)}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };
    const userLastUpdatedDateComponent = (user: IUser) => {
        return user.UpdatedByUser ? (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {user.UpdatedAt ? formatDate(new Date(user.UpdatedAt), false, true, false) : ''}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {user.UpdatedAt ? formatTime(new Date(user.UpdatedAt), true, true, true, true, false) : ''}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };

    const userControls = (user: IUser) => {
        return <TableButton text="Edit" onClick={() => handleEdit(user.Id as number)} />;
    };

    const userNameAndDesignation = (user: IUser) => user.Name + user.Designation;
    const userLastUpdatedAdminName = (user: IUser) => (user.UpdatedByUser ? user.UpdatedByUser.Name : '');
    const userLastUpdatedDate = (user: IUser) => (user.UpdatedAt ? new Date(user.UpdatedAt) : new Date());
    const handleReviewSubmit = () => {
        createMonthlyReview()
            .then(() => {
                openGrowl(COPIES.SUBMIT_MONTHLY_REVIEW_MESSAGE.replace('zz', 'Ops Unit Admin(s)'));
                getRequiresMonthlyReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const getUsertListContent = () => {
        const filteredUsers = users.filter((user) => user.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        const domainHeaderRef = useRef<HTMLDivElement>(null);

        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            User Management
                        </Typography>
                        <Typography component="h1" variant="h5">
                            {`${agencyName} / ${opsUnitName} / ${teamName}`}
                        </Typography>
                    </Grid>
                    {monthlyReviewStatus && monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW && (
                        <ReviewBanner
                            monthlyReviewStatus={monthlyReviewStatus}
                            handleReviewSubmit={handleReviewSubmit}
                            messageCallToAction={
                                <Link display="inline" target="_blank" rel="noopener noreferrer" href={LEARN_MORE_LINK}>
                                    Learn more {'>'}
                                </Link>
                            }
                        />
                    )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="user-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search User Name"
                                type="search"
                                style={{
                                    background: '#FFFFFF',
                                    width: '320px',
                                    height: '44px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                isDense={true}
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary text="Add User" onClick={handleClick} id="add-user-button" />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {users.length > 0 ? (
                            <ListModel
                                columns={[
                                    userDetails,
                                    userLastUpdatedAdmin,
                                    userLastUpdatedDateComponent,
                                    userControls,
                                ]}
                                headers={['User Name', 'Last Updated by', 'Date Updated', '']}
                                modelList={filteredUsers}
                                modelComparator={[
                                    getAlphabeticalComparator(userNameAndDesignation),
                                    getAlphabeticalComparator(userLastUpdatedAdminName),
                                    getDateComparator(userLastUpdatedDate),
                                    () => 0,
                                ]}
                                variant="paginated"
                                hover
                            />
                        ) : (
                            <EmptyList entity="User" handleClick={handleClick} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: null,
                            message:
                                growl.message.length > 0 ? (
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" alignItems="center" marginRight={1.75}>
                                            <CheckCircleIcon />
                                        </Box>

                                        {growl.message}
                                    </Box>
                                ) : undefined,
                            action: (
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>
            </>
        );
    };

    const UserListWithAdminLoader = WithAdminLoader<{}>(getUsertListContent, fetchStatus, LOADING_MESSAGE.DATA);

    return <UserListWithAdminLoader />;
};
