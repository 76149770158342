import React from 'react';
import {Box, Button, Card, Grid, Typography} from '@mui/material';
import {CheckCircleRounded, Error, Info} from '@mui/icons-material';
import {assertNewLineIfMoreThanOne} from '~/utils/contentUtils';

import styles from './ActionCard.scss';
export interface MessageType {
    title: string;
    body: string;
}
export interface IActionCardProps {
    message: MessageType;
    color: string;
    buttonText?: string;
    onSubmit?: () => void;
    status?: boolean;
    disabled?: boolean;
    showError?: boolean;
    messageCallToAction?: React.ReactNode;
}

export const ActionCard = ({
    message,
    color,
    buttonText = 'Submit',
    onSubmit,
    status = false,
    disabled = false,
    showError = false,
    messageCallToAction = undefined,
}: IActionCardProps) => {
    const formattedMsg = assertNewLineIfMoreThanOne(message.body);
    return (
        <div>
            <Box className={styles.coloredBar} style={{backgroundColor: color}} />
            <Card className={styles.card}>
                <Grid>
                    <Grid item>
                        <Box display="flex" justifyContent="left" alignItems="center" marginBottom={1}>
                            {status && !showError && (
                                <CheckCircleRounded
                                    fontSize="inherit"
                                    className={styles.infoIcon}
                                    style={{color: color}}
                                />
                            )}
                            {!status && !showError && (
                                <Info fontSize="inherit" className={styles.infoIcon} style={{color: color}} />
                            )}
                            {!status && showError && (
                                <Error fontSize="inherit" className={styles.infoIcon} style={{color: color}} />
                            )}
                            <Typography className={styles.infoTitle}>{message.title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" justifyContent="left" ml={0.5}>
                            <Typography className={styles.infoText} component="div">
                                {formattedMsg} {messageCallToAction}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {onSubmit && (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={styles.button}
                        onClick={onSubmit}
                        disabled={disabled}
                        style={disabled ? {backgroundColor: '#DEDEDE', border: 0} : {}}
                    >
                        {buttonText}
                    </Button>
                )}
            </Card>
        </div>
    );
};
