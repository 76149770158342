import React, {useState, useEffect, useRef} from 'react';
import {
    List,
    ListItem,
    Typography,
    Collapse,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    Paper,
    TableCell,
    ToggleButtonGroup,
    ToggleButton,
    Chip,
} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {useOnScreen} from '~/utils/hooks/useOnScreen';

import {Fields} from './Fields';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getSubDomainReason} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';

import './DataAccessMatrixEdit.scss';

export interface ISubDomainProps {
    subDomain: IDataAccessMatrixSubDomain;
    expandAll: boolean;
    opsUnitId: number;
    domainId: number;
}

export interface ISubDomainHeaderProps {
    subDomain: IDataAccessMatrixSubDomain;
    expand: boolean;
    setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ISubDomainReasonProps {
    subDomainId: number;
    opsUnitId: number;
    domainId: number;
}

export const SubDomainHeader = ({subDomain, expand, setExpand}: ISubDomainHeaderProps) => {
    const handleClickSubDomain = () => {
        setExpand(!expand);
    };

    return (
        <ListItem button onClick={handleClickSubDomain} styleName="expandHeader">
            <Typography component={'span'} styleName="expandTitle">
                {subDomain.Name}
                {subDomain.HasNewFields && (
                    <Chip
                        label="New fields"
                        size="small"
                        style={{color: '#ffffff', background: '#3F91EF', marginLeft: '10px'}}
                    />
                )}
                <span styleName="expandIcon" style={{color: '#ffffff'}}>
                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
            </Typography>
        </ListItem>
    );
};

export const SubDomainReason = ({opsUnitId, domainId, subDomainId}: ISubDomainReasonProps) => {
    const [reasonStatus, setReasonStatus] = React.useState('');
    const dispatch = useAppDispatch();

    const reason = useTypedSelector(getSubDomainReason(opsUnitId, domainId, subDomainId));

    const handleChange = (_: React.MouseEvent<HTMLElement>, status: string) => {
        setReasonStatus(status);
        const payload = {opsUnitId, domainId, subDomainId, status};
        dispatch(dataAccessMatrix2Actions.SET_SUBDOMAIN_REASON_STATUS(payload));
    };

    return (
        <Paper style={{borderRadius: '0'}}>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow styleName="subDomainReasonHeaderRow">
                            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '38%'}}>
                                Approved reason(s) for access
                            </TableCell>
                            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '37%'}}>
                                Updated reason(s) for access
                            </TableCell>
                            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '25%'}}>
                                Manage updated reason
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell styleName="subDomainReasonCell" style={{width: '38%', whiteSpace: 'pre-wrap'}}>
                                {reason.approvedReasonForAccess || '-'}
                            </TableCell>
                            <TableCell styleName="subDomainReasonCell" style={{width: '37%', whiteSpace: 'pre-wrap'}}>
                                {reason.updatedReasonForAccess?.reason || '-'}
                            </TableCell>
                            <TableCell>
                                {reason.updatedReasonForAccess?.referenceId && (
                                    <ToggleButtonGroup
                                        size="small"
                                        style={{width: '25%'}}
                                        color="primary"
                                        value={reasonStatus}
                                        exclusive
                                        onChange={handleChange}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton
                                            value="approve"
                                            color="success"
                                            style={{letterSpacing: '1.25px', paddingLeft: '12px', paddingRight: '12px'}}
                                        >
                                            Approve
                                        </ToggleButton>
                                        <ToggleButton
                                            value="reject"
                                            color="error"
                                            style={{letterSpacing: '1.25px', paddingLeft: '12px', paddingRight: '12px'}}
                                        >
                                            Reject
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const FieldsMemo = React.memo(Fields);
export const SubDomain = ({subDomain, expandAll, opsUnitId, domainId}: ISubDomainProps) => {
    const intersectionRef = useRef(null);
    const {isOnScreen} = useOnScreen(intersectionRef);

    const [expand, setExpand] = useState(true);

    useEffect(() => {
        setExpand(expandAll);
    }, [expandAll]);

    return (
        <List style={{padding: '0', marginTop: '20px'}} ref={intersectionRef}>
            <SubDomainHeader subDomain={subDomain} expand={expand} setExpand={setExpand} />

            <Collapse in={expand} timeout={500}>
                <SubDomainReason opsUnitId={opsUnitId} domainId={domainId} subDomainId={subDomain.Id} />
                {isOnScreen && subDomain.DataFields && (
                    <FieldsMemo
                        rows={subDomain.DataFields}
                        opsUnitId={opsUnitId}
                        domainId={domainId}
                        subDomainId={subDomain.Id}
                        subDomain={subDomain}
                    />
                )}
            </Collapse>
        </List>
    );
};
