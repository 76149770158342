import {Container} from '@mui/material';
import React, {useContext} from 'react';
import {useHistory} from 'react-router';
import {ReleaseNotesIcon, ResourcesIcon, TermsOfUseIcon} from '~/components/Common/Icons';
import {ILink, Sidebar} from '~/components/Common/Sidebar/Sidebar';
import {LOGIN_STATE} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {DataDictionary} from './DataDictionary';
import {DetailedTermsAndCondition} from './DetailedTermsAndCondition';
import {GettingStarted} from './GettingStarted';
import {ParticipatingAgencies} from './ParticipatingAgencies';
import {PrivacyStatement} from './PrivacyStatement';
import {ReleaseNoteEdit} from './ReleaseNoteEdit';
import {ReleaseNotes} from './ReleaseNotes';
import {Resources} from './Resources';
import './SiteResources.scss';
import {WebsiteTou} from './WebsiteTou';

export const PARTICIPATING_AGENCIES_PATH = '/participating-agencies';
export const DATA_DICTIONARY_PATH = '/data-dictionary';
export const GETTING_STARTED_PATH = '/getting-started';
export const RESOURCES_PATH = '/guides-and-toolkits';
export const RELEASE_NOTES_PATH = '/release-notes';
export const EDIT_RELEASE_NOTES_PATH = '/release-notes/edit/';
export const CREATE_RELEASE_NOTES_PATH = '/release-notes/create';
export const PRIVACY_STATEMENT_PATH = '/privacy-and-terms-of-use';
export const WEBSITE_TOU_PATH = '/website-terms-of-use';
export const DETAILED_TERMS_AND_CONDITION_PATH = '/undertaking-to-safeguard-confidentiality-of-personal-data';

export const SiteResources = () => {
    const pathname = useHistory().location.pathname;
    const {loginState} = useContext(AuthenticationContext);

    const initialSidebarLinksMap: ILink[] = [
        {
            label: 'Privacy and Terms of Use',
            isSelected:
                DETAILED_TERMS_AND_CONDITION_PATH === pathname ||
                PRIVACY_STATEMENT_PATH === pathname ||
                WEBSITE_TOU_PATH === pathname,
            icon: <TermsOfUseIcon />,
            children: [
                {
                    label: 'Privacy Statement',
                    path: PRIVACY_STATEMENT_PATH,
                    isSelected: PRIVACY_STATEMENT_PATH === pathname,
                },
                {
                    label: 'Terms of Use',
                    path: WEBSITE_TOU_PATH,
                    isSelected: WEBSITE_TOU_PATH === pathname,
                },
                {
                    label: 'Undertaking to Safeguard Confidentiality of Personal Data',
                    path: DETAILED_TERMS_AND_CONDITION_PATH,
                    isSelected: DETAILED_TERMS_AND_CONDITION_PATH === pathname,
                },
            ],
        },
        {
            label: 'Release Notes',
            path: RELEASE_NOTES_PATH,
            isSelected:
                RELEASE_NOTES_PATH === pathname ||
                pathname.includes(EDIT_RELEASE_NOTES_PATH) ||
                pathname.includes(CREATE_RELEASE_NOTES_PATH),
            icon: <ReleaseNotesIcon />,
        },
        {
            label: 'Resources',
            path: RESOURCES_PATH,
            isSelected: PARTICIPATING_AGENCIES_PATH === pathname,
            icon: <ResourcesIcon />,
            children: [
                {
                    label: 'List of Participating Agencies',
                    path: PARTICIPATING_AGENCIES_PATH,
                    isSelected: PARTICIPATING_AGENCIES_PATH === pathname,
                },
            ],
        },
    ];

    // Append Guides and Toolkits to resources' children only if authenticated
    const routes =
        loginState === LOGIN_STATE.LOGGED_IN
            ? initialSidebarLinksMap.map((element) => {
                  if (element.path == RESOURCES_PATH) {
                      const childrenElements = [
                          ...(element.children || []),
                          {
                              label: 'Getting Started',
                              path: GETTING_STARTED_PATH,
                              isSelected: GETTING_STARTED_PATH === pathname,
                          },
                          {
                              label: 'Guides and Toolkits',
                              path: RESOURCES_PATH,
                              isSelected: RESOURCES_PATH === pathname,
                          },
                          {
                              label: 'Data Dictionary',
                              path: DATA_DICTIONARY_PATH,
                              isSelected: DATA_DICTIONARY_PATH === pathname,
                          },
                      ];
                      return {
                          ...element,
                          isSelected:
                              element.isSelected ||
                              RESOURCES_PATH === pathname ||
                              GETTING_STARTED_PATH === pathname ||
                              DATA_DICTIONARY_PATH === pathname,
                          children: childrenElements.sort((a, b) =>
                              a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
                          ),
                      };
                  }
                  return element;
              })
            : initialSidebarLinksMap;

    return (
        <>
            <Sidebar linksMap={routes} />
            <Container disableGutters id="siteResources">
                {pathname === DATA_DICTIONARY_PATH && <DataDictionary />}
                {pathname === GETTING_STARTED_PATH && <GettingStarted />}
                {pathname === RESOURCES_PATH && <Resources />}
                {pathname === PRIVACY_STATEMENT_PATH && <PrivacyStatement />}
                {pathname === WEBSITE_TOU_PATH && <WebsiteTou />}
                {pathname === DETAILED_TERMS_AND_CONDITION_PATH && <DetailedTermsAndCondition />}
                {pathname === PARTICIPATING_AGENCIES_PATH && <ParticipatingAgencies />}
                {pathname === RELEASE_NOTES_PATH && <ReleaseNotes />}
                {pathname.includes(EDIT_RELEASE_NOTES_PATH) && <ReleaseNoteEdit />}
                {pathname === CREATE_RELEASE_NOTES_PATH && <ReleaseNoteEdit />}
            </Container>
        </>
    );
};
