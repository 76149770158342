import React, {useState, useEffect, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Grid, Typography, Button, TextField, InputAdornment, Stack, List, ListItem, Box, Snackbar} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {cloneDeep, uniq} from 'lodash';
import {
    SearchRounded as SearchIcon,
    Edit as EditIcon,
    ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
} from '@mui/icons-material';
import * as services from '~/services/dataAccessMatrixService';
import {API_FETCH_STATE} from '~/constants';
import {IDataAccessMatrixDomain, IOpsUnitDataAccess, IUser, IOpsUnit} from '~/interfaces/admin';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {nextLineToParagraphs} from '~/utils/nextLineToParagraphUtils';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {BackButton} from '~/components/Common/Button/BackButton';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {Domain} from './Domain';
import {OpsUnitTypeEditModal} from './OpsUnitTypeEditModal';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDomains} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {getDamFieldsFetchState, getDamOpsUnitFetchState} from '~/store/fetchState/fetchState.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {setDamOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {getSelectedFieldIds, getSubDomainAccessReasonStatus} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {getDamOpsUnit, getDamOpsUnitExist} from '~/store/opsUnit/opsUnit.selector';
import {opsUnitActions} from '~/store/opsUnit/opsUnit.slice';
import {IDataFieldRequestStatus} from '~/store/dataReducers';
import {RouterLeavingGuardModalDialog} from './RouteLeavingGuard';

import style from './OpsUnitTypeEditModal.scss';
import '../Admin.scss';
import './DataAccessMatrixEdit.scss';

const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

export interface IInitialMatrix {
    dataFieldRequestStatuses: {
        [domainId: number]: {
            [subDomainId: number]: {
                [fieldId: number]: IDataFieldRequestStatus;
            };
        };
    };
    subDomainReason: {
        [domainId: number]: {
            [subDomainId: number]: {
                approvedReasonForAccess: string;
                updatedReasonForAccess?: {
                    formId: number;
                    referenceId: number;
                    reason: string;
                    status: string | null;
                };
            };
        };
    };
}

const getOpsUnitDataAccessMatrix2 = (
    domains: IDataAccessMatrixDomain[],
    opsUnitDataAccessFields: IOpsUnitDataAccess[],
    opsUnitId: string,
) => {
    const domainMatrix = domains.reduce(
        (matrix, domain) => {
            const statuses: {[subDomainId: number]: {[fieldId: number]: IDataFieldRequestStatus}} = {};
            const reason: {
                [subDomainId: number]: {
                    approvedReasonForAccess: string;
                    updatedReasonForAccess?: {
                        formId: number;
                        referenceId: number;
                        reason: string;
                        status: string | null;
                    };
                };
            } = {};
            domain.SubDomains.forEach((subDomain) => {
                statuses[subDomain.Id] = {};
                const dataAccessBySubdomain = opsUnitDataAccessFields?.find((d) => d.SubdomainId === subDomain.Id);
                subDomain.DataFields.forEach((field) => {
                    if (dataAccessBySubdomain) {
                        const dataFieldRequestStatus = dataAccessBySubdomain.DataFieldRequestStatuses?.find(
                            (d) => d.DataFieldId === field.Id,
                        );
                        statuses[subDomain.Id][field.Id] = {
                            dataFieldId: dataFieldRequestStatus?.DataFieldId || field.Id,
                            requestStatus: dataFieldRequestStatus?.RequestStatus || '',
                            formId: dataFieldRequestStatus?.FormId || 0,
                            referenceId: dataFieldRequestStatus?.ReferenceId || 0,
                            checked: dataFieldRequestStatus?.RequestStatus === 'approved',
                        };
                    } else {
                        statuses[subDomain.Id][field.Id] = {
                            dataFieldId: field.Id,
                            requestStatus: '',
                            formId: 0,
                            referenceId: 0,
                            checked: false,
                        };
                    }
                });
                reason[subDomain.Id] = {
                    approvedReasonForAccess: dataAccessBySubdomain?.ApprovedReasonForAccess || '',
                };
                if (dataAccessBySubdomain && dataAccessBySubdomain.UpdatedReasonForAccess) {
                    reason[subDomain.Id] = {
                        ...reason[subDomain.Id],
                        updatedReasonForAccess: {
                            formId: dataAccessBySubdomain.UpdatedReasonForAccess.FormId,
                            referenceId: dataAccessBySubdomain.UpdatedReasonForAccess.ReferenceId,
                            reason: dataAccessBySubdomain.UpdatedReasonForAccess.Reason,
                            status: null,
                        },
                    };
                }
            });
            matrix.dataFieldRequestStatuses[domain.Id] = statuses;
            matrix.subDomainReason[domain.Id] = reason;
            matrix.initialMatrix = {
                dataFieldRequestStatuses: cloneDeep(matrix.dataFieldRequestStatuses),
                subDomainReason: cloneDeep(matrix.subDomainReason),
            };

            return matrix;
        },
        {
            dataFieldRequestStatuses: {},
            subDomainReason: {},
            initialMatrix: {dataFieldRequestStatuses: {}, subDomainReason: {}},
        } as {
            dataFieldRequestStatuses: {
                [domainId: number]: {[subDomainId: number]: {[fieldId: number]: IDataFieldRequestStatus}};
            };
            subDomainReason: {
                [domainId: number]: {
                    [subDomainId: number]: {
                        approvedReasonForAccess: string;
                        updatedReasonForAccess?: {
                            formId: number;
                            referenceId: number;
                            reason: string;
                            status: string | null;
                        };
                    };
                };
            };
            initialMatrix: IInitialMatrix;
        },
    );

    return {
        [Number(opsUnitId)]: domainMatrix,
    };
};

export const SaveDataAccessMatrixButton = ({opsUnitId}: {opsUnitId: number}) => {
    const dispatch = useAppDispatch();
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const {accessToken} = useContext(AuthenticationContext);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const selectedFieldIds = useTypedSelector(getSelectedFieldIds(Number(opsUnitId)));
    const {approvedIds, rejectedIds} = useTypedSelector(getSubDomainAccessReasonStatus(Number(opsUnitId)));
    const domains = useTypedSelector(getDomains);

    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));
    const saveClickHandler = async () => {
        try {
            setIsSubmitting(true);
            const fields = await services.updateOpsUnitDataAccessMatrix2(opsUnitId, {
                AcceptSubdomainAccessReason: approvedIds,
                RejectSubdomainAccessReason: rejectedIds,
                UpdatedDataFields: selectedFieldIds,
            });
            if (fields) {
                dispatch(
                    dataAccessMatrix2Actions.SET_OPS_UNIT_DATA_ACCESS_MATRIX(
                        getOpsUnitDataAccessMatrix2(domains, fields, opsUnitId.toString()),
                    ),
                );
            }
            setIsSubmitting(false);
            openGrowl(`Data access updated successfully`);

            // Update local DAM opsUnit
            const user: IUser = {
                Name: accessToken?.AdditionalUserInfo.Name || '-',
                UserName: '-',
                Designation: accessToken?.AdditionalUserInfo?.Designation || '-',
                Division: '-',
                Email: '-',
                OfficeNumber: '-',
                IsActive: true,
            };

            if (opsUnit) {
                const updatedOpsUnit: IOpsUnit = {
                    ...opsUnit,
                    DataDomainUpdatedAt: new Date(),
                    DataDomainUpdatedByUser: user,
                };

                dispatch(opsUnitActions.UPDATE_DAM_OPS_UNIT(updatedOpsUnit));
            }
        } catch (e) {
            const error = e as Error;
            openGrowl(error.message);
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={saveClickHandler} disabled={isSubmitting}>
                Save
            </Button>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                    bottom: 90,
                }}
            />
        </>
    );
};

export const OpsUnitName = ({opsUnitId}: {opsUnitId: number}) => {
    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));

    return (
        <>
            <Typography variant="h5">{opsUnit?.Name}</Typography>
            <Stack direction="row" spacing={1}>
                <Typography variant="subtitle1">{opsUnit?.Agency?.Name}</Typography>
                <Typography variant="subtitle1">{'|'}</Typography>
                <Link
                    to={`/admin/dam2-data-access-matrix/ops-units/${opsUnit?.Id}/reject`}
                    style={{
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        wordSpacing: '2px',
                        lineHeight: 1.75,
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        fontSize: '14px',
                        color: '#3949ab',
                        textDecorationColor: 'none',
                    }}
                >
                    Rejected Access History
                    {'  '}
                    <ArrowForwardIosOutlinedIcon style={{fontSize: '12px', marginLeft: '8px'}} />
                </Link>
            </Stack>
        </>
    );
};

export const OpsUnitDetails = ({opsUnitId}: {opsUnitId: number}) => {
    const [isEditingOpsUnitType, setIsEditOpsUnitType] = useState<boolean>(false);
    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));

    const reasonForAccess = nextLineToParagraphs(opsUnit?.ReasonForAccess || '');
    return (
        <>
            <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6}>
                    <List style={{paddingTop: '0'}}>
                        <ListItem disableGutters style={{paddingTop: '0'}}>
                            <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                Ops Unit Type
                            </Typography>
                            <Typography component={'div'} variant="body1">
                                {opsUnit?.OpsUnitType || '-'}
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        style={{float: 'right', backgroundColor: 'transparent', padding: '0'}}
                        color="primary"
                        onClick={() => setIsEditOpsUnitType(true)}
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <List style={{paddingTop: '0'}}>
                        <ListItem disableGutters>
                            <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                Reason(s) for accessing One Client View
                            </Typography>
                            <Typography component={'div'} variant="body1" style={{whiteSpace: 'pre-wrap'}}>
                                {reasonForAccess}
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <hr className={style.line}></hr>
            {opsUnit && (
                <OpsUnitTypeEditModal
                    open={isEditingOpsUnitType}
                    onClose={() => setIsEditOpsUnitType(false)}
                    currentOpsUnitType={opsUnit.OpsUnitType}
                    currentOpsUnitReasonForAccess={opsUnit.ReasonForAccess || ''}
                    opsUnit={opsUnit}
                />
            )}
        </>
    );
};

export const DAM2DataAccessMatrixEdit = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [domainOptions, setDomainOptions] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const [filteredDomains, setFilteredDomains] = useState<IDataAccessMatrixDomain[]>([]);
    const [fetchOpsUnitFieldsStatus, setFetchOpsUnitFieldsStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [opsUnitDataAccessFields, setOpsUnitDataAccessFields] = useState<IOpsUnitDataAccess[]>([]);

    const domains = useTypedSelector(getDomains);

    const domainFilterClasses = useDomainFilterStyles();
    const damFieldsFetchState = useTypedSelector(getDamFieldsFetchState);
    const opsUnitFetchState = useTypedSelector(getDamOpsUnitFetchState);

    const opsUnitIdInt = isNaN(parseInt(opsUnitId)) ? -1 : parseInt(opsUnitId);
    const opsUnitExist = useTypedSelector(getDamOpsUnitExist(opsUnitIdInt));

    useEffect(() => {
        if (
            damFieldsFetchState === API_FETCH_STATE.SUCCESS &&
            opsUnitFetchState === API_FETCH_STATE.SUCCESS &&
            fetchOpsUnitFieldsStatus === API_FETCH_STATE.SUCCESS
        ) {
            if (!opsUnitExist) {
                history.push('/admin/dashboard');
                return;
            }
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        }

        if (
            damFieldsFetchState === API_FETCH_STATE.ERROR ||
            opsUnitFetchState === API_FETCH_STATE.ERROR ||
            fetchOpsUnitFieldsStatus === API_FETCH_STATE.ERROR
        ) {
            setCombinedFetchStatus(API_FETCH_STATE.ERROR);
        }
    }, [damFieldsFetchState, opsUnitFetchState, fetchOpsUnitFieldsStatus]);

    useEffect(() => {
        dispatch(setDataAccessMatrix());
        dispatch(setDamOpsUnits());
        (async () => {
            try {
                const fields = await services.getDam2OpsUnitDataAccessMatrix(opsUnitIdInt);
                if (fields) {
                    setOpsUnitDataAccessFields(fields);
                }
                setFetchOpsUnitFieldsStatus(API_FETCH_STATE.SUCCESS);
            } catch (e) {
                setFetchOpsUnitFieldsStatus(API_FETCH_STATE.ERROR);
            }
        })();
    }, []);

    useEffect(() => {
        if (domains.length > 0 && combinedFetchStatus === API_FETCH_STATE.SUCCESS) {
            const dataAccessMatrix = getOpsUnitDataAccessMatrix2(domains, opsUnitDataAccessFields, opsUnitId);
            dispatch(dataAccessMatrix2Actions.SET_OPS_UNIT_DATA_ACCESS_MATRIX(dataAccessMatrix));

            if (domainOptions.length === 0) {
                setDomainOptions(uniq(domains.map((row) => row.Name)));
            }
            applySearchFilter(searchValue);
        }
    }, [domains, combinedFetchStatus]);

    const applySearchFilter = (searchVal: string) => {
        const lowerCaseSearchValue = searchVal.toLowerCase();
        const filteredByDomains = cloneDeep(filterSelectedDomains(selectedDomains));

        const filteredDomains = filteredByDomains.filter((domain) => {
            domain.SubDomains = domain.SubDomains.filter((subDomain) => {
                subDomain.DataFields = subDomain.DataFields?.filter((field) => {
                    return (
                        (field.DataSource && field.DataSource.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1) ||
                        field.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                        subDomain.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1
                    );
                });
                return subDomain.DataFields && subDomain.DataFields?.length > 0;
            });
            return domain.SubDomains.length > 0;
        });
        setFilteredDomains(filteredDomains);
    };

    const filterSelectedDomains = (selectedDomains: string[]): IDataAccessMatrixDomain[] => {
        if (selectedDomains.length === 0) return domains;
        return domains.filter((domain) => selectedDomains.includes(domain.Name));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        applySearchFilter(e.target.value);
    };

    const handleSelectDomain = (selectedDomains: string[]) => {
        setSelectedDomains(selectedDomains);
        setFilteredDomains(filterSelectedDomains(selectedDomains));
    };

    const handleClearDomains = () => {
        handleSelectDomain([]);
    };

    const handleSelectAllDomains = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectDomain(domainOptions);
        } else {
            handleClearDomains();
        }
    };

    const DomainMemo = React.memo(Domain);
    const DomainContent = () => {
        return (
            <>
                {filteredDomains.map((domain) => {
                    return (
                        <DomainMemo
                            domain={domain}
                            onExpandAll={true}
                            key={domain.Id}
                            opsUnitId={parseInt(opsUnitId)}
                        />
                    );
                })}
            </>
        );
    };

    const cancelClickHandler = () => {
        history.push('/admin/data-access-matrix');
    };

    const DomainContentWithLoader = WithLoader<{}>(DomainContent, combinedFetchStatus);

    const FloatingSaveDiv = () => {
        return (
            <footer style={{position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000}}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            style={{
                                width: '100%',
                                height: '84px',
                                background: 'white',
                                boxShadow:
                                    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                            }}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                style={{marginRight: '8px'}}
                                onClick={cancelClickHandler}
                            >
                                Cancel
                            </Button>
                            <Box mr={3}>
                                <SaveDataAccessMatrixButton opsUnitId={Number(opsUnitId)} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </footer>
        );
    };

    return (
        <>
            {combinedFetchStatus === API_FETCH_STATE.SUCCESS && (
                <RouterLeavingGuardModalDialog opsUnitId={opsUnitIdInt} />
            )}
            <Grid container data-testid="damHeader" direction="row" justifyContent="center" spacing={3}>
                <Grid item xs={12} className={style.backBtn}>
                    <BackButton
                        onClick={() => {
                            history.push('/admin/data-access-matrix');
                        }}
                        label="Back"
                    />
                </Grid>
                <Grid item xs={8}>
                    <OpsUnitName opsUnitId={Number(opsUnitId)} />
                </Grid>
                <Grid item id="downloadReport" styleName="downloadReportBtn" xs={4}>
                    <Button variant="text" color="primary" style={{marginRight: '8px'}} onClick={cancelClickHandler}>
                        Cancel
                    </Button>
                    <SaveDataAccessMatrixButton opsUnitId={Number(opsUnitId)} />
                </Grid>
                <Grid item xs={12}>
                    <OpsUnitDetails opsUnitId={Number(opsUnitId)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        label="Search Subdomain or Field Name"
                        type="search"
                        variant="outlined"
                        value={searchValue}
                        onChange={handleSearchChange}
                        styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MultiSelectAutocomplete
                        options={domainOptions}
                        selectedValues={selectedDomains}
                        label="Select Domains"
                        tagRenderer={(value) => (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {value.length} Domains Selected
                            </Typography>
                        )}
                        onToggleOption={handleSelectDomain}
                        onClearOptions={handleClearDomains}
                        onSelectAll={handleSelectAllDomains}
                        classes={domainFilterClasses}
                    />
                </Grid>
            </Grid>
            <Grid styleName="reportTableStyle">
                <DomainContentWithLoader />
            </Grid>
            <FloatingSaveDiv />
        </>
    );
};
