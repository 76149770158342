import {createSlice} from '@reduxjs/toolkit';
import {IParticipatingAgencies, IParticipatingAgency} from '~/interfaces/common';
import {setParticipatingAgencies} from './participatingAgency.thunk';

export const initialParticipatingAgenciesState: IParticipatingAgencies = {
    DataAgencies: [],
    UserAgencies: [],
};

export const participatingAgenciesSlice = createSlice({
    name: 'participatingAgency',
    initialState: initialParticipatingAgenciesState,
    reducers: {
        setNewDataSource(state, action) {
            if (action.payload) {
                state.DataAgencies.push(action.payload);
            }
        },
        editDataSource(state, action) {
            return {
                ...state,
                DataAgencies: state.DataAgencies.map((agency: IParticipatingAgency) =>
                    agency.Id == action.payload.Id ? action.payload : agency,
                ),
            };
        },
        deleteDataSource(state, action) {
            return {
                ...state,
                DataAgencies: state.DataAgencies.filter((agency) => agency.Id !== action.payload.Id),
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setParticipatingAgencies.fulfilled, (_, action) => {
            if (action.payload) {
                return action.payload;
            }
        });
    },
});

export const participatingAgencyActions = participatingAgenciesSlice.actions;
export default participatingAgenciesSlice.reducer;
