import React from 'react';
import {FIELD_VALUES, INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import './MandatoryCounsellingProgramme.scss';
import {extractAllValues, allNoResults, formatDateField, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';

export type IMandatoryCounsellingProgrammeData = IIntervention[];

interface IIntervention {
    CaseReferenceNo: string;
    CaseStatus: string;
    InterventionStartDate: string;
    InterventionEndDate: string;
    CaseLastAppointDate: string;
    CaseWorkerName: string;
    CaseLocationName: string;
    CaseClosureReason: string;
}

const getInterventionEndDate = (interventionStartDate: string, interventionEndDate: string) => {
    if (isNotEmpty(interventionStartDate) && interventionEndDate == FIELD_VALUES.NO_RESULTS) {
        return 'Ongoing';
    } else {
        return formatDateField(interventionEndDate, true, true);
    }
};

const mcpInterventionToFields = (intervention: IIntervention) => {
    const {
        CaseReferenceNo,
        CaseStatus,
        InterventionStartDate,
        InterventionEndDate,
        CaseLastAppointDate,
        CaseWorkerName,
        CaseLocationName,
        CaseClosureReason,
    } = intervention;

    const caseStatusStyle = CaseStatus === 'Open' ? 'activeCase' : '';

    const caseDetails = [
        {label: 'Case Reference Number', value: CaseReferenceNo},
        {label: 'Name of Family Service Centre', value: CaseLocationName},
        {label: 'Case Status', value: CaseStatus, styleName: caseStatusStyle},
        {
            label: 'Case Start Date',
            value: formatDateField(InterventionStartDate, true, true),
        },
        {label: 'Name of Caseworker', value: CaseWorkerName},
        {
            label: 'Last Appointment Date',
            value: formatDateField(CaseLastAppointDate, true, true),
        },
    ];

    const dischargeDetails = [
        {
            label: 'Case Closure Date',
            value: getInterventionEndDate(InterventionStartDate, InterventionEndDate),
        },
        {label: 'Case Closure Reason', value: CaseClosureReason},
    ];

    return CaseStatus === 'Open' ? caseDetails : [...caseDetails, ...dischargeDetails];
};

const sortOrder: {[key: string]: number} = {Open: 1, 'Submitted for Closure': 2, Suspended: 3, Closed: 4};

export const MandatoryCounsellingProgramme: React.FunctionComponent<{data: IMandatoryCounsellingProgrammeData}> = ({
    data,
}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    // Order by statuses as in sortOrder, intervention type, start date
    const sortedInterventions = noData
        ? data
        : data
              .sort((caseA, caseB) => {
                  return sortOrder[caseA.CaseStatus] - sortOrder[caseB.CaseStatus];
              })
              .sort((caseA, caseB) => {
                  return caseA.CaseStatus == caseB.CaseStatus
                      ? new Date(caseB.InterventionStartDate).getTime() -
                            new Date(caseA.InterventionStartDate).getTime()
                      : 0;
              });

    const fieldsToRender = sortedInterventions.map(mcpInterventionToFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <MultiGridDisplay
                    dataFieldGroups={fieldsToRender}
                    dataTestId="mandatoryCounsellingProgramme"
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
