import React, {useState} from 'react';
import {Button, Chip, Grid, Typography} from '@mui/material';
import {IRecipientGroup} from '~/interfaces/admin';
import {take} from 'lodash';
import './RecipientList.scss';

export interface IRecipientListProps {
    recipientGroups: IRecipientGroup[];
    onDelete: (recipientGroup: IRecipientGroup) => void;
    limit: number;
}

export const RecipientList = ({recipientGroups, onDelete, limit}: IRecipientListProps) => {
    const [showAll, setShowAll] = useState<boolean>(true);

    const handleShow = () => setShowAll(!showAll);
    const toShowButton = () => recipientGroups.length > limit;
    const limitedChips = take(recipientGroups, limit);
    return (
        <Grid container styleName="recipientList">
            <Grid item>
                <Typography styleName="toLabel">To</Typography>
            </Grid>
            <Grid item xs={9} styleName="chipContainer">
                {
                    <>
                        {(showAll ? recipientGroups : limitedChips).map((group) => {
                            return (
                                <Chip
                                    key={group.Description}
                                    label={group.Description}
                                    onDelete={group.Immutable ? undefined : () => onDelete(group)}
                                    color="primary"
                                    styleName="chip"
                                />
                            );
                        })}
                    </>
                }
            </Grid>

            {toShowButton() && (
                <Grid item styleName="buttonContainer">
                    <Button size="small" color="primary" variant="outlined" onClick={handleShow} styleName="button">
                        {showAll ? 'Show Less' : 'Show More'}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
