import {AGENCY_TYPE, OPS_UNIT_TYPE} from '~/constants';
import {IConsent} from './client';
import {ISourceSystem, ISubDomain} from './common';

export interface ISystemAdmin {
    Id: number;
    User: IUser;
}
export interface IAgency {
    Id: number;
    Name: string;
    AgencyType: AGENCY_TYPE;
    Admins: IUser[] | null;
    DataDomainUpdatedAt?: Date;
    DataDomainUpdatedByUser?: IUser;
    SubDomains?: ISubDomain[];
    OpsUnits?: number[];
    SysAdminOpsUnits?: ISysAdminOpsUnit[];
    EntityInfo: string;
    IsDataContributor?: boolean;
    DateAdded?: string;
}

export interface IOpsUnit {
    Id: number;
    AgencyId: number;
    Name: string;
    Description: string;
    OpsUnitType: OPS_UNIT_TYPE;
    Admins: IUser[] | null;
    DataDomainUpdatedAt?: Date;
    DataDomainUpdatedByUser?: IUser;
    Teams?: number[];
    Users?: number[];
    Agency?: IAgency;
    CanScreenClient: boolean;
    ReasonForAccess?: string;
}

export interface IAdminRow {
    Id: number;
    Name: string;
    Admins: IUser[] | null;
    OpsUnitId?: number;
    AgencyId?: number;
    Reviews?: [];
    Status?: string;
    MostRecentReview?: string;
    RecentReviewBy?: string;
    ReviewerRole?: string;
    ApprovalStatus?: string;
    MostRecentApproval?: string;
    RecentApprovalBy?: string;
    CreatedAt?: string;
    CanScreenClient?: boolean;
}
export interface ITeam {
    Id: number;
    OpsUnitId: number;
    OpsUnit: IOpsUnit;
    Name: string;
    Description: string;
    Admins: IUser[] | null;
    Users?: number[];
    CanScreenClient: boolean;
}
export interface ITeamResponse {
    Team: ITeam;
    CurrentTeamReviews: ICurrentAdminReview;
}
export interface ICurrentAdminReview {
    Status: string;
    MostRecentReview: string;
    RecentReviewBy: string;
}

export interface IUser {
    Id?: number;
    Name: string;
    UserName: string;
    Designation: string;
    Division: string;
    Email: string;
    OfficeNumber: string;
    MobileNumber?: string;
    IsActive: boolean;
    TeamId?: number;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    UpdatedByUser?: IUser;
    UserType?: string;
    isOA?: boolean;
    CanScreenClient?: boolean;
}

// Factory Method to Create Concrete Empty User
export const emptyUser = (): IUser => ({
    Name: '',
    UserName: '',
    Designation: '',
    Division: '',
    Email: '',
    OfficeNumber: '',
    MobileNumber: '',
    IsActive: true,
    UserType: '',
});

export interface IUserResponse {
    User: IUser;
    Error?: string;
}

export interface ITeamResponse {
    Team: ITeam;
    Error?: string;
}

export interface IOpsUnitResponse {
    OpsUnit: IOpsUnit;
    CurrentOpsUnitReviews: ICurrentAdminReview;
    Error?: string;
}

export interface IAgencyResponse {
    Agency: IAgency;
    CurrentOpsUnitReviews: ICurrentAdminReview;
    Error?: string;
}

export interface ISystemAdminResponse {
    SystemAdminId: number;
    UpdatedAt?: Date;
    UpdatedByUser?: IUser;
    Error?: string;
}

export class AdminResponseError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AdminResponseError';
    }
}
export class AdminInputError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AdminInputError';
    }
}

export interface IAnnouncement {
    Id: number;
    name: string;
    message: string;
    updatedAt: Date | null;
    updatedBy: string;
    endDate: Date | null;
    startDate: Date | null;
    isActive: boolean | null;
}

export const emptyAnnouncement = (): IAnnouncement => ({
    Id: 0,
    name: '',
    message: '',
    updatedAt: null,
    updatedBy: '',
    endDate: null,
    startDate: null,
    isActive: null,
});

export interface IGetAnnouncementResponse {
    default: IAnnouncement;
    scheduled: IAnnouncement[];
}

export type TEntityType = 'Agency' | 'Ops Unit' | 'Team' | '';
export type TUserGroup = 'All Groups' | 'Agency Admins' | 'Ops Unit Admins' | 'Team Admins' | 'Users';

export interface IRecipientGroup {
    Id: string;
    EntityType: TEntityType;
    EntityIds: number[];
    UserGroup: TUserGroup;
    Description: string;
    Immutable?: boolean;
}

export interface IEntity {
    Id: number;
    Name: string;
}

export interface IDataContributor {
    Id?: number;
    User: IUser;
    Agency: IAgency;
}

export interface IUserSearchParams {
    Email: string;
}

export interface IUserSearchResults {
    User: IUser;
    Agency?: IAgency;
}

export interface IDataSource {
    Id?: number;
    Name: string;
    DateAdded: string;
}

export interface IDataFieldGroup {
    Id: number;
    Name: string;
}

export interface IDataAccessMatrixField {
    Id: number;
    Name: string;
    Description: string;
    DataFieldGroup?: IDataFieldGroup;
    DataSource?: IDataSource;
    DataSourceId?: number;
    SubDomainId?: number;
    Remarks?: string;
    CreatedAt?: string;
}

export interface IDataAccessMatrixSubDomain extends Omit<ISubDomain, 'DataDictionaryFields'> {
    DomainId: number;
    SourceSystems: ISourceSystem[];
    DateAdded: string;
    DataFields: IDataAccessMatrixField[];
    Remarks?: string;
    Guidelines?: string;
}
export interface IDataAccessMatrixDomain {
    Id: number;
    Name: string;
    SubDomains: IDataAccessMatrixSubDomain[];
}

export type IOpsUnitDataAccessMatrix = {
    [opsUnitId: number]: {
        [domainId: number]: {
            [subDomainId: number]: {
                [fieldId: number]: boolean;
            };
        };
    };
};

export type IDataAccessMatrix = IDataAccessMatrixDomain[];

export interface IOpsUnitDamFields {
    dataFieldIds: number[];
}

export interface ISysAdminAgency {
    Id: number;
    Name: string;
    AgencyType: AGENCY_TYPE;
    Admins: IUser[] | null;
    DataDomainUpdatedAt?: Date;
    DataDomainUpdatedByUser?: IUser;
    SubDomains?: ISubDomain[];
    OpsUnits?: ISysAdminOpsUnit[];
    EntityInfo: string;
    IsDataContributor?: boolean;
    DateAdded?: string;
}

export interface ISysAdminOpsUnit {
    Id: number;
    AgencyId: number;
    Name: string;
    Description: string;
    OpsUnitType: OPS_UNIT_TYPE;
    Admins: IUser[] | null;
    DataDomainUpdatedAt?: Date;
    DataDomainUpdatedByUser?: IUser;
    Teams?: ITeam[];
    Users?: number[];
    Agency?: IAgency;
    CanScreenClient: boolean;
}

export interface IClientConsentHistory {
    ClientName: string;
    ConsentHistory: IConsent[];
}

export interface IUpdateClientConsentRequest {
    Id: number;
    Remarks: string;
    EffectiveDate: string;
}

export interface IUpdateClientConsentResponse {
    RequestId: number;
}

export interface IMonthlyReviewResponse {
    Status: string;
    MostRecentReview: string;
    RecentReviewBy: string;
    ReviewerRole?: string;
}

export interface IDashboardAdminReviewBanner {
    Status: string;
    MostRecentReview: string;
    RecentReviewBy: string;
    ReviewerRole?: string;
    Message: string;
    Link: string;
}

export interface ICurrentRequestWindow {
    CurrentWindowPeriod: IRequestWindow | null;
}

export interface IRequestWindow {
    Id: number;
    Status: string;
    WindowStart: string;
    WindowEnd: string;
    CreatedAt?: string;
    CreatedBy?: string;
    LastUpdatedAt?: string;
    LastUpdatedBy?: string;
}

export interface IAllRequestWindows {
    RequestWindows: IRequestWindow[];
}

export interface IWindowCreationRequest {
    WindowStart: string;
    WindowEnd: string;
}

export interface IWindowCreationResponse {
    Id: number;
    WindowStart: string;
    WindowEnd: string;
}

export interface IWindowPeriodReportResponse {
    Url: string;
}

export interface ISubdomainLatestStatus {
    SubdomainId: number;
    LastUpdatedAt: string;
    LastUpdatedBy: string;
}

export interface IDomainReqStatus {
    DomainId: number;
    Status: string;
}

export interface IDataFieldRequestStatuses {
    DataFieldId: number;
    FormId: number;
    RequestStatus: string;
    ReferenceId: number;
    Checked?: boolean;
}

export interface IOpsUnitDataAccess {
    DomainId: number;
    SubdomainId: number;
    ApprovedReasonForAccess: string;
    DataFieldRequestStatuses: IDataFieldRequestStatuses[];
    UpdatedReasonForAccess?: {
        FormId: number;
        ReferenceId: number;
        Reason: string;
    };
}

export interface IOpsUnitDataFieldRejectReason {
    Reason: string;
    SubdomainId: number;
    Fields: {
        DataFieldId: number;
        FormId: number;
        ReferenceId: number;
    }[];
    RejectionBy: {
        UserName: string;
        Role: string;
    };
    RejectedOn: Date;
}

export interface IOpsUnitDataFieldRejectRequest {
    Reason: string;
    SubdomainId: number;
    FormReferences: number[];
}

export interface IDomainDataRequest {
    WindowPeriodId: number;
    FormId: number;
    SubdomainDataRequest: ISubdomainDataRequest[];
}

export interface ISubdomainDataRequest {
    SubdomainId: number;
    ReasonForAccess: string;
    Fields: number[];
    SubdomainRequestSummary: ISubdomainRequestSummaryObj;
}

export interface ISubdomainDataRequestField {
    FieldId: number;
    State: string;
    LastUpdatedBy: {
        Name: string;
        Role: string;
    };
    Remark: string;
    DeletedAt: string;
}

export interface ISubdomainRequestSummaryObj {
    SubdomainName: string;
    ReasonUpdated: boolean;
    FieldsRequested: number;
    FieldsRemoved: number;
}

export interface ISubdomainRequestFields {
    fields: number[];
}
export interface IOpsUnitDam2UpdateRequest {
    RejectSubdomainAccessReason: number[];
    AcceptSubdomainAccessReason: number[];
    UpdatedDataFields: number[];
}

export interface IRequestWindowLog {
    Period: {
        Start: Date;
        End: Date;
    };
    Status: string;
    SubdomainRequestSummaries: ISubdomainRequestSummary[];
}

export interface ISubdomainRequestSummary {
    State: string;
    ActionBy: {
        User: string;
        Role: string;
    };
    ActionOn: Date;
    Actions: {
        ReasonUpdated: boolean;
        FieldsAdded: number;
        FieldsRemoved: number;
    };
}
