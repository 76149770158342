import React from 'react';
import {Divider, Typography} from '@mui/material';
import {IComcareAssistance, IComcareData} from './interfaces';
import {ComcareAssistanceTable} from './ComcareAssistanceTable';
import {ErrorSubDomain} from '../ErrorSubDomain';
import {SubDomainStatus} from '~/interfaces/client';
import styles from './ComcareAssistance.scss';
import {objNoAccess} from '~/utils/contentUtils';
import {UnsuccessfulMessage, SuccessMessage, FixedMessage} from '~/components/Common/IconMessage/IconMessage';
import {COPIES} from '~/constants';

export const ComCareIndBanner = ({data}: {data: IComcareAssistance[]}) => {
    if (data.some((comcareAssistance: IComcareAssistance) => comcareAssistance.ComCareInd === 'Y')) {
        return <SuccessMessage message={COPIES.COMCARE1_IND_YES} data-testid="comCareIndicator" />;
    }
    if (data.every((comcareAssistance: IComcareAssistance) => comcareAssistance.ComCareInd === 'N')) {
        return <UnsuccessfulMessage message={COPIES.COMCARE1_IND_NO} data-testid="comCareIndicator" />;
    }
    // handles no access, no results cases
    return <></>;
};

export const ComcareAssistance: React.FunctionComponent<{data: IComcareData}> = ({data}) => {
    const {COMCARE, INTERIM} = data;
    const showComcare =
        (COMCARE && !objNoAccess(COMCARE.Data)) || (COMCARE && COMCARE.Data && COMCARE.Data.length === 0); // Comcare data will be [] if all data from comcare is interim
    const showInterim = INTERIM && !objNoAccess(INTERIM.Data);

    return (
        <div className={styles.comcareAssistance}>
            {showComcare && (
                <>
                    <Typography variant="h5" className={styles.sectionTitle}>
                        ComCare Assistance
                    </Typography>
                    {COMCARE?.Status === SubDomainStatus.ERROR ? (
                        <ErrorSubDomain isSubSection={true} includeHeader={false} />
                    ) : (
                        <>
                            {COMCARE?.Data && <ComCareIndBanner data={COMCARE.Data} />}
                            <FixedMessage message={COPIES.COMCARE_FIXED_MESSAGE} />
                            <ComcareAssistanceTable
                                data={COMCARE?.Data}
                                type="comcare"
                                data-testid="comcareAssistance"
                            />
                        </>
                    )}
                </>
            )}

            {showComcare && showInterim && <Divider variant="fullWidth" className={styles.divider} />}

            {showInterim && (
                <>
                    <Typography variant="h5" className={styles.sectionTitle}>
                        Interim Assistance
                    </Typography>
                    {INTERIM?.Status === SubDomainStatus.ERROR ? (
                        <ErrorSubDomain isSubSection={true} includeHeader={false} />
                    ) : (
                        <ComcareAssistanceTable data={INTERIM?.Data} type="comcare2" data-testid="interimAssistance" />
                    )}
                </>
            )}
        </div>
    );
};
