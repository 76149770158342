import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {format} from 'date-fns';
import {Box, Grid, Typography, Button} from '@mui/material';
import {COPIES, REPORT_TYPE} from '~/constants';
import {InputField} from '~/components/Common/InputField/InputField';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {IOpsUnit} from '~/interfaces/admin';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import {IReportRequest} from '~/pages/Admin/Reports/Report';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setDamOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {getDamOpsUnitFetchState} from '~/store/fetchState/fetchState.selector';
import {getReports} from '~/services/reportServices';

import {config} from '~/config';

import '../Admin.scss';
import './DataAccessMatrix.scss';

export const DataAccessMatrix = () => {
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState('');
    const opsUnits = useTypedSelector((state) => state.data.admin.opsUnits?.dam || []);
    const fetchStatus = useTypedSelector(getDamOpsUnitFetchState);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);

    const history = useHistory();

    useEffect(() => {
        dispatch(setDamOpsUnits());
    }, []);

    const handleEdit = (opsUnitId: number) => {
        if (config.featureFlags.ENABLE_DAM2) {
            history.push(`/admin/dam2-data-access-matrix/ops-units/${opsUnitId}`);
        } else {
            history.push(`/admin/data-access-matrix/ops-units/${opsUnitId}`);
        }
    };

    const opsUnitNameCell = (opsUnit: IOpsUnit) => (
        <>
            <Box styleName="nameWrapper">{opsUnit.Name}</Box>
            <Box styleName="designationWrapper">{opsUnit.Agency?.Name}</Box>
        </>
    );

    const opsUnitTypeCell = (opsUnit: IOpsUnit) => <Box styleName="nameWrapper">{opsUnit.OpsUnitType}</Box>;

    const lastUpdatedByCell = (opsUnit: IOpsUnit) =>
        opsUnit.DataDomainUpdatedByUser ? (
            <>
                <Box styleName="nameWrapper">{opsUnit.DataDomainUpdatedByUser?.Name}</Box>
                <Box styleName="designationWrapper">{opsUnit.DataDomainUpdatedByUser?.Designation}</Box>
            </>
        ) : (
            <>-</>
        );

    const lastUpdatedOnCell = (opsUnit: IOpsUnit) => {
        const dateObj = opsUnit.DataDomainUpdatedAt ? new Date(opsUnit.DataDomainUpdatedAt) : undefined;
        return dateObj ? (
            <>
                <Box styleName="nameWrapper">{format(dateObj, 'd MMM yyyy')}</Box>
                <Box styleName="designationWrapper">{format(dateObj, 'hh:mmaa')}</Box>
            </>
        ) : (
            <>-</>
        );
    };

    const opsUnitName = (opsUnit: IOpsUnit) => opsUnit.Name.toLowerCase();
    const opsUnitType = (opsUnit: IOpsUnit) => opsUnit.OpsUnitType;
    const lastUpdatedName = (opsUnit: IOpsUnit) =>
        opsUnit.DataDomainUpdatedByUser ? opsUnit.DataDomainUpdatedByUser.Name : '';
    const lastUpdatedTime = (opsUnit: IOpsUnit) =>
        opsUnit.DataDomainUpdatedAt ? opsUnit.DataDomainUpdatedAt : new Date();

    const editBtnCell = (opsUnit: IOpsUnit) => {
        return (
            <Button styleName="editBtn" onClick={() => handleEdit(opsUnit.Id)}>
                Edit Access
            </Button>
        );
    };

    const filteredOpsUnits = opsUnits.filter(
        (opsUnit) => opsUnit.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
    );

    const headers = ['Ops Unit Name', 'Type', 'Last Updated By', 'Date Updated', 'Data Access'];
    const headerCellStyle = (data: React.ReactNode): React.CSSProperties => {
        const style: React.CSSProperties = {backgroundColor: '#C5CAE9', color: '#3C3C3C'};
        if (data?.toString() === headers[0]) {
            style.width = '35%';
        }
        if (data?.toString() === headers[1]) {
            style.width = '15%';
        }
        if (data?.toString() === headers[2]) {
            style.width = '23%';
        }
        if (data?.toString() === headers[3]) {
            style.width = '23%';
        }
        return style;
    };

    const ListModelContent = () => (
        <ListModel
            columns={[opsUnitNameCell, opsUnitTypeCell, lastUpdatedByCell, lastUpdatedOnCell, editBtnCell]}
            headers={headers}
            customHeaderCellStyle={headerCellStyle}
            modelList={filteredOpsUnits}
            modelComparator={[
                getAlphabeticalComparator(opsUnitName),
                getAlphabeticalComparator(opsUnitType),
                getAlphabeticalComparator(lastUpdatedName),
                getDateComparator(lastUpdatedTime),
                () => 0,
            ]}
            defaultSortOrder="asc"
            variant="paginated"
            hover
            disableSortColumns={['Data Access']}
        />
    );

    const ListModelContentWithLoader = WithLoader<{}>(ListModelContent, fetchStatus);

    const handleGenerateReport = () => {
        setPasswordModalOpen(true);
    };

    const handlePasswordModalClose = () => {
        setPasswordModalOpen(false);
    };

    const handlePasswordSubmit = (password: string) => {
        const reportRequest: IReportRequest = {
            Reports: [
                {
                    Id: 0,
                    Type: REPORT_TYPE.DAM_REPORT,
                    Name: 'DAM Report',
                    Description: 'Data Access Matrix by Ops Units',
                    Selected: true,
                },
            ],
            Password: password,
        };
        setIsLoadingReport(true);
        getReports(reportRequest)
            .then(() => {
                setIsLoadingReport(false);
            })
            .catch(() => {
                setIsLoadingReport(false);
            });
    };

    return (
        <>
            <Grid
                container
                data-testid="damHeader"
                styleName="adminPageHeader"
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={3}
            >
                <Grid item xs={8}>
                    <Typography variant="h4">Data Access Management</Typography>
                </Grid>
                <Grid item id="downloadReport" styleName="downloadReportBtn" xs={4}>
                    <Button
                        size="large"
                        color="primary"
                        data-testid="downloadReport"
                        styleName={isLoadingReport ? 'normal disabled' : 'normal'}
                        disabled={isLoadingReport}
                        onClick={() => handleGenerateReport()}
                    >
                        <span>DOWNLOAD REPORT</span>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        htmlFor="ops-unit-search"
                        label="Search Ops Unit"
                        type="search"
                        value={searchValue}
                        onChange={setSearchValue}
                    />
                </Grid>
                <Grid item xs={12} styleName="reportTableStyle">
                    <ListModelContentWithLoader />
                </Grid>
            </Grid>
            <ReportPasswordDialog
                open={passwordModalOpen}
                onSubmit={handlePasswordSubmit}
                onClose={handlePasswordModalClose}
                title={COPIES.REPORT_PASSWORD_DIALOG_TITLE}
                description={COPIES.REPORT_PASSWORD_DIALOG_BODY}
            />
        </>
    );
};
