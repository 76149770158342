import React from 'react';
import {Button, Divider, Typography} from '@mui/material';
import {NotificationItem} from './NotificationItem';
import {Link} from 'react-router-dom';
import {IUserNotification} from '~/interfaces/common';
import './NotificationsDropdown.scss';

export interface INotificationsDropdownProps {
    userNotifications: IUserNotification[];
    handleClose: () => void;
}

export const NotificationsDropdown = ({userNotifications, handleClose}: INotificationsDropdownProps) => {
    return (
        <div styleName="notificationsDropdown">
            <Typography styleName="title">Notifications</Typography>
            <Divider />

            <div styleName="content">
                {userNotifications.length === 0 ? (
                    <Typography styleName="emptyMessage">No new notifications</Typography>
                ) : (
                    userNotifications.map((notification, index) => (
                        <NotificationItem
                            key={notification.Notification.Id}
                            notification={notification}
                            type="dropdown"
                            divider={index < userNotifications.length - 1}
                            onClick={handleClose}
                        />
                    ))
                )}
            </div>

            <Divider />
            <Link to="/notifications" style={{textDecoration: 'none'}}>
                <Button variant="text" color="primary" styleName="viewAllButton" onClick={handleClose}>
                    VIEW ALL
                </Button>
            </Link>
        </div>
    );
};
