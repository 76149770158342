import {createAsyncThunk} from '@reduxjs/toolkit';
import * as userServices from '~/services/userServices';
import {IUserFlags} from '~/interfaces/common';

export const setUserFlags = createAsyncThunk<IUserFlags | void, void>('userFlags/setUserFlags', async () => {
    try {
        const userFlags = await userServices.getUserFlags();
        return userFlags;
    } catch (err) {
        throw err;
    }
});

export const updateUserFlags = createAsyncThunk<string, string>('/userFlags/updateUserFlags', async (flagName) => {
    try {
        await userServices.updateUserFlags(flagName);
        return flagName;
    } catch (err) {
        throw err;
    }
});
