import React from 'react';
import {Grid, Typography, IconButton} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import GetAppIcon from '@mui/icons-material/GetApp';
import './FileBox.scss';

interface IFileBoxProps {
    filename: string;
    time: string;
    onClickHandler: React.MouseEventHandler<HTMLButtonElement>;
}

export const FileBox = ({filename, time, onClickHandler}: IFileBoxProps) => {
    return (
        <>
            <Grid container styleName="boxWrapper">
                <Grid item>
                    <DescriptionIcon styleName="fileIcon" />
                </Grid>
                <Grid item styleName="textWrapper">
                    <Typography styleName="filename">{filename}</Typography>
                    <Typography styleName="timeText">{time}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClickHandler}>
                        <GetAppIcon styleName={'downloadIcon'} />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};
