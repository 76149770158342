import {SubDomainStatus} from '~/interfaces/client';

export interface IComcareData {
    COMCARE?: IComcareAssistanceData;
    INTERIM?: IInterimAssistanceData;
}

export interface IComcareAssistanceData {
    Status: SubDomainStatus;
    Data: IComcareAssistance[];
}

export interface IInterimAssistanceData {
    Status: SubDomainStatus;
    Data: TAssistanceDataType[];
}

export interface IComcare2Assistance {
    Id: number;
    CaseLocationName: string;
    CaseWorkerName: string;
    CaseReferenceNo: string;
    SchemeType: string;
    CaseClosureDate?: string;
    CaseClosureReason: string;
    CaseCreatedDate?: string;
    CaseStatus: string;
    Indicator: string;
    AssistanceEarliestStartDate: string;
    AssistanceLatestEndDate: string;
    BenefitInfo: NullableArray<IComcare2BenefitInfo>;
}

export interface IComcareAssistance {
    Id: number;
    ComCareInd: string;
    CaseLocationName: string;
    CaseWorkerName: string;
    CaseReferenceNo: string;
    AssistanceType: string;
    CaseClosureReason: string;
    CaseClosedDate: string;
    Indicator: string;
    CaseStatus: string;
    AssistanceEarliestStartDate: string;
    AssistanceLatestEndDate: string;
    BenefitInfo: NullableArray<IBenefitInfo>;
}

export interface IComcare2BenefitInfo {
    BenefitType: string;
    Amount: string;
    AssistanceStartDate: string;
    AssistanceEndDate?: string;
    BeneficiaryNames: string;
    DepositAmount?: string;
    TotalSugRecAmount?: string;
    TotalSugToBePaid?: string;
}

export interface IBenefitInfo {
    BenefitType: string;
    MonthlyDisbursement: string;
    AssistanceStartDate: string;
    AssistanceEndDate: string;
    BeneficiaryNames: string;
}

export type TBenefitInfoType = IBenefitInfo | IComcare2BenefitInfo;
export type TAssistanceDataType = IComcareAssistance | IComcare2Assistance;
export type TAssistanceType = 'comcare' | 'comcare2';

export const getAmount = (benefitInfo: TBenefitInfoType) => {
    if ('MonthlyDisbursement' in benefitInfo) return benefitInfo.MonthlyDisbursement;
    if ('Amount' in benefitInfo) return benefitInfo.Amount;
    return '';
};

export const getDepositAmount = (benefitInfo: TBenefitInfoType) => {
    if ('DepositAmount' in benefitInfo) return benefitInfo.DepositAmount;
    return '';
};

export const getTotalSugRecAmount = (benefitInfo: TBenefitInfoType) => {
    if ('TotalSugRecAmount' in benefitInfo) return benefitInfo.TotalSugRecAmount;
    return '';
};

export const getTotalSugToBePaid = (benefitInfo: TBenefitInfoType) => {
    if ('TotalSugToBePaid' in benefitInfo) return benefitInfo.TotalSugToBePaid;
    return '';
};

export const getAssistanceType = (data: TAssistanceDataType) => {
    if ('AssistanceType' in data) return data.AssistanceType;
    if ('SchemeType' in data) return data.SchemeType;
    return '';
};
