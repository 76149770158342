import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {formatCurrency} from '~/utils/currencyUtils';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {SubDomainStatus} from '~/interfaces/client';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {formatValue, formatDateField, allNoResults, extractAllValues} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export interface IContribution {
    Id: number;
    ContributionPaidDate: string;
    ContributionMonth: string;
    Amount: string;
    EmployerName: string;
    ContributionType: string;
}

export interface IContributionData {
    Status: string;
    Data: IContribution[];
    ErrorMessage: string;
}
interface IContributionHistory {
    details: IContributionData;
}

interface IContributionRowRecord extends Pick<IContribution, 'Amount' | 'EmployerName' | 'ContributionType'> {
    ReceiptDate: Date | string;
    ContributionMonth: Date | string;
}

type IHeadRowData = Pick<
    IContribution,
    'ContributionMonth' | 'ContributionPaidDate' | 'Amount' | 'EmployerName' | 'ContributionType'
>;

interface IContributionRows {
    sortedRows: IContributionRowRecord[];
}

export const ContributionRows = ({sortedRows}: IContributionRows) => {
    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <TableRow tabIndex={-1} key={index}>
                        <TableCell>{formatDateField(row.ContributionMonth, false, false, true)}</TableCell>
                        <TableCell>{formatDateField(row.ReceiptDate)}</TableCell>
                        <TableCell>{formatValue(row.Amount, parseFloat, formatCurrency)}</TableCell>
                        <TableCell>{row.EmployerName}</TableCell>
                        <TableCell>{row.ContributionType}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const ContributionDetails = ({details}: IContributionHistory) => {
    if ((!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    if (allNoResults(extractAllValues(details.Data)))
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                    Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                }`}
            />
        );

    if (details.ErrorMessage === '#1011') {
        return (
            <p>
                Screening was successful with multiple results found ({details.ErrorMessage}). Please contact the system
                administrator to check the records.
            </p>
        );
    }

    type ContributionHeaderColumn = IHeaderColumn<keyof IHeadRowData>;

    const headColumns: ContributionHeaderColumn[] = [
        {id: 'ContributionMonth', align: 'left', disablePadding: true, label: 'Contribution Month'},
        {id: 'ContributionPaidDate', align: 'left', disablePadding: false, label: 'Receipt Date'},
        {id: 'Amount', align: 'left', disablePadding: false, label: 'Contribution Amount ($)'},
        {id: 'EmployerName', align: 'left', disablePadding: false, label: 'Contribution Source'},
        {id: 'ContributionType', align: 'left', disablePadding: false, label: 'Contribution Type'},
    ];

    const filteredHeadColumns: ContributionHeaderColumn[] = headColumns.filter(
        (head: ContributionHeaderColumn): Boolean =>
            (details.Data as IContribution[])[0][head.id as keyof IContribution] !== '',
    );

    const data = (details.Data as IContribution[]).map((contribution) => {
        return {
            ...contribution,
            ContributionMonth: contribution.ContributionMonth,
            ReceiptDate: contribution.ContributionPaidDate,
        };
    });

    const headerProps = {
        columns: filteredHeadColumns,
        customStyle: {
            backgroundColor: '#5c6bc0',
            color: '#ffffff',
        },
    };

    const hocProps = {
        rows: data,
        colSpan: filteredHeadColumns.length,
        headerProps,
    };

    const props = {
        ...headerProps,
        ...hocProps,
    };

    const EnhancedTable = WithEnhancedTable<IContributionRows, IHeadRowData, IContributionRowRecord>(
        ContributionRows,
        'ContributionMonth',
        data,
        'desc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return <EnhancedTable {...props} />;
};
