import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {DeleteRounded as DeleteIcon, Clear as ClearIcon} from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Typography,
} from '@mui/material';
import {uploadClientConsentFile} from '~/services/consentServices';
import {IConsentFileMeta} from '~/interfaces/client';
import {getFileSizeInKb} from '~/pages/Admin/DataUpload/DataUploadHelpers';
import {DocumentIcon, UploadFileIcon} from '~/components/Common/Icons';
import {COPIES} from '~/constants';

import './UploadConsent.scss';
export interface IUploadConsentProps {
    selectedFile: File | null;
    setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
    selectedFilename: string;
    setSelectedFilename: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    resetFile: () => void;
    setFileId: (id: number) => void;
}

export const UploadConsent = ({
    selectedFile,
    setSelectedFile,
    selectedFilename,
    setSelectedFilename,
    resetFile,
    errorMessage,
    setErrorMessage,
    setFileId,
}: IUploadConsentProps) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [history, setHistory] = useState<IConsentFileMeta[]>([]);
    const [uploadingInProgress, setUploadingInProgress] = useState<boolean>(progress < 100);
    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    useEffect(() => {
        const timer = setInterval(() => {
            if (progress >= 100) {
                setUploadingInProgress(false);
            }
        }, 500);
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    // Set Max File Size to 4 MB
    const isValidFileSize = (file: File) => file && file.size < 4 * 1024 * 1024;

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event || !event.target || !event.target.files) return;
        const file = event.target.files[0];

        setProgress(40);

        if (!isValidFileSize(file)) {
            setErrorMessage('The file exceeds 4MB. Please try again.');
            return;
        }

        setSelectedFile(file);
        const filename =
            file.name.length < 35
                ? file.name
                : file.name.substring(0, 17) + '...' + file.name.substring(file.name.length - 16);

        setSelectedFilename(filename);
        uploadFile(filename, file);
    };

    const uploadFile = async (name: string, selected: File) => {
        try {
            if (!selected) {
                throw new Error('No file selected');
            }

            setUploading(true);

            const responseFileMeta = await uploadClientConsentFile(selected, name, setProgress, cancelToken);
            if (!responseFileMeta || !responseFileMeta.Id) {
                throw new Error(
                    'File could not be uploaded. Please upload a different file or contact us for support.',
                );
            }

            setHistory([responseFileMeta, ...history]);
            setUploading(false);
            setFileId(responseFileMeta.Id);
        } catch (err) {
            resetFile();
            const error = err as Error;
            const errorMsg = (error.message as string).trim();
            setErrorMessage(`The file "${name}" could not be uploaded: ${errorMsg}.`);
            setUploading(false);
        }
    };

    const cancelUpload = () => {
        cancelTokenSource.cancel();
        setUploading(false);
        resetFile();
    };

    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirm(false);
    };

    const DeleteDialog = ({open, id}: {open: boolean; id: string}) => {
        return (
            <Dialog
                open={open}
                data-testid={id}
                onClose={handleDeleteConfirmClose}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <Typography variant="h4" styleName="tooltipTitle">
                        Are you sure you want to delete &quot;{selectedFilename}&quot;?
                    </Typography>
                    <Typography variant="body1" styleName="tooltipSubtitle">
                        {COPIES.DELETE_FILE_IMMEDIATELY}
                    </Typography>
                </DialogContent>
                <DialogActions style={{padding: '24px'}}>
                    <Button
                        data-testid="cancel"
                        variant="outlined"
                        styleName="tooltipButton cancel"
                        onClick={handleDeleteConfirmClose}
                    >
                        CANCEL
                    </Button>
                    <Button
                        data-testid="delete"
                        variant="contained"
                        styleName="tooltipButton delete"
                        onClick={() => {
                            resetFile();
                            handleDeleteConfirmClose();
                        }}
                    >
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            {!selectedFile && (
                <>
                    <Box styleName="files">
                        <input
                            id="file"
                            type="file"
                            name="file"
                            onClick={() => resetFile()}
                            onChange={fileChangeHandler}
                            accept={'application/pdf'}
                        />
                        <Box styleName="upload-box">
                            <UploadFileIcon />
                            <Typography styleName="upload-bluePrint">ATTACH FORM (MAX 4MB, PDF ONLY)</Typography>
                        </Box>
                    </Box>
                    <Typography styleName="errorMsg">{errorMessage}</Typography>
                </>
            )}
            {selectedFile && (
                <Paper
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignContent: 'center',
                        backgroundColor: 'var(--disabled-row-grey, #fafafa)',
                    }}
                >
                    <Grid container styleName="uploadContainer">
                        <Grid item styleName="uploadIconWrapper">
                            <DocumentIcon />
                        </Grid>
                        {uploading && (
                            <Grid item styleName="uploadDocumentWrapper">
                                <LinearProgress styleName="progressBar" variant="determinate" value={progress} />
                                <Typography styleName="progressText">Uploading {progress}%</Typography>
                                <IconButton
                                    styleName="iconButton"
                                    onClick={cancelUpload}
                                    disabled={!uploadingInProgress}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        )}
                        {!uploading && (
                            <Grid item styleName="uploadDocumentWrapper">
                                <Typography noWrap styleName="uploadedText fileName">
                                    {selectedFilename}
                                </Typography>

                                <Typography styleName="uploadedText fileSize">
                                    {getFileSizeInKb(selectedFile.size)}
                                </Typography>
                                <IconButton styleName="iconButton" onClick={() => setOpenDeleteConfirm(true)}>
                                    <DeleteIcon />
                                </IconButton>
                                <DeleteDialog open={openDeleteConfirm} id={'deleteConfirm'} />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            )}
        </>
    );
};
