import React, {useEffect, ReactNode} from 'react';
import {Paper, Table, TableCell, TableRow, TableContainer, TablePagination, TableBody} from '@mui/material';
import {Order, getComparator, stableSort} from '~/utils/tableUtils';
import {TableHeader, ITableHeaderProps, IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import Emitter, {EVENT_TYPE} from '~/utils/events/emitter';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {settingsActions} from '~/store/settings/settings.slice';

// Specify the type of enchanced table rendering
// DEFAULT : With Pagination
// SIMPLE: No Pagination
export type ITableType = TABLE_TYPE;

export enum TABLE_TYPE {
    DEFAULT = 'DEFAULT',
    SIMPLE = 'SIMPLE',
}
export interface IWithEnhancedTableComponentProps<K> {
    colSpan: number;
    customStyle?: React.CSSProperties;
    headerProps: IWithEnhancedTableHeaderProps<K>;
    tableType?: ITableType;
    tableSortLabelIconStyle?: React.CSSProperties;
    dataTestId?: string;
    colGroup?: JSX.Element;
}

export interface IWithEnhancedTableHeaderProps<K> {
    columns: IHeaderColumn<keyof K>[];
    onSelectAllClick?: () => void;
    numSelected?: number;
    showCheckbox?: boolean;
}

export const WithEnhancedTable = <P, K extends {}, T>(
    Component: React.ComponentType<P>,
    sortBy: keyof K,
    rows: T[],
    orderDirection?: Order,
    tableKey?: string,
    columnRenderFunc?: ((data: never) => ReactNode)[],
    isExpandable?: boolean,
    currentPage?: number,
    pageHandler?: (page: number) => void,
    tableType: TABLE_TYPE = TABLE_TYPE.DEFAULT,
    defaultRows = 10,
) => {
    const [page, setPage] = React.useState(currentPage || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        tableType === TABLE_TYPE.SIMPLE ? Number.MAX_SAFE_INTEGER : defaultRows,
    );
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof K>(sortBy);
    const dispatch = useAppDispatch();
    const setting = tableKey ? useTypedSelector((state) => state.data.settings.sortTableSettings[tableKey]) : null;
    const isPersistTableOrder = tableKey !== undefined;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    useEffect(() => {
        // Set sort order direction from component
        if (orderDirection) {
            setOrder(orderDirection);
        }
    }, []);

    useEffect(() => {
        function resetPage() {
            setPage(0);
        }
        Emitter.on(EVENT_TYPE.RESET_TABLE_PAGE, resetPage);
        return () => {
            Emitter.off(EVENT_TYPE.RESET_TABLE_PAGE, resetPage);
        };
    });

    useEffect(() => {
        if (isPersistTableOrder && setting) {
            setOrder(setting.order);
            setOrderBy(setting.orderBy as keyof K);
        }
    }, [setting]);

    const getSortedRows = () => {
        const currRows = rows as unknown;
        if (!order) return currRows;
        return stableSort(currRows as K[], getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        if (pageHandler) pageHandler(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (_: unknown, property: keyof K) => {
        const isAsc = orderBy === property && order === 'asc';
        const updatedOrderDirection = isAsc ? 'desc' : 'asc';

        if (!isPersistTableOrder) {
            setOrder(updatedOrderDirection);
            setOrderBy(property);
        } else {
            dispatch(
                settingsActions.SET_SORT_TABLE_SETTING({
                    [tableKey as string]: {
                        order: updatedOrderDirection,
                        orderBy: property as string,
                    },
                }),
            );
        }
    };

    const WithEnhancedTableComponent = ({
        colSpan,
        customStyle,
        headerProps,
        tableSortLabelIconStyle,
        dataTestId,
        colGroup,
        ...passThroughProps
    }: IWithEnhancedTableComponentProps<K>) => {
        const tableHeaderProps: ITableHeaderProps<K> = {
            ...headerProps,
            order,
            orderBy,
            onRequestSort: handleRequestSort,
            rowCount: rows.length,
            customStyle,
            tableSortLabelIconStyle,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const componentProps: any = {
            ...passThroughProps,
            sortedRows: getSortedRows(),
            columnRenderFunc: columnRenderFunc,
            isExpandable: isExpandable,
        };

        return (
            <Paper>
                <TableContainer>
                    <Table
                        aria-labelledby="enhanced_table"
                        aria-label="enhanced_table"
                        size="medium"
                        style={{width: '100%'}}
                        data-testid={dataTestId}
                    >
                        {colGroup && colGroup}
                        <TableHeader {...tableHeaderProps} />
                        <TableBody>
                            <Component {...componentProps} />
                            {emptyRows > 0 && tableType === 'DEFAULT' && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={colSpan} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {tableType === 'DEFAULT' && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        );
    };

    return WithEnhancedTableComponent;
};
