// from https://gist.github.com/enqtran/25c6b222a73dc497cc3a64c090fb6700
export const isPageAtBottom = () => {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    return windowBottom >= docHeight;
};
