import React from 'react';
import {Box} from '@mui/material';
import {SubDomainStatus, StatusColor} from '~/interfaces/client';
import {ISubDomainData} from './subDomainData';
import {CompletedSubDomain} from '~/components/Client/SubDomainDisplay/CompletedSubDomain';
import {PendingSubDomain} from '~/components/Client/SubDomainDisplay/PendingSubDomain';
import {ErrorSubDomain} from '~/components/Client/SubDomainDisplay/ErrorSubDomain';
import './SubDomainDisplay.scss';
import {isNotEmpty} from '~/utils/contentUtils';
import {CareshieldEldershieldError} from './CareshieldEldershield/CareshieldEldershieldError';

export type StatusObject = {[source: string]: SubDomainStatus};
export interface ISubDomainDisplayProps {
    name: string;
    code: string;
    lastUpdatedAt: string;
    status: StatusObject;
    data: ISubDomainData;
    source?: string;
    errorMessages?: string[];
    isLastSubDomain: boolean;
}

export const renderTextByStatus = (text: string, status: SubDomainStatus) => {
    if (isNotEmpty(text)) {
        return <p style={{display: 'inline', color: StatusColor[status], fontWeight: 'bold'}}>{text}</p>;
    }
    return <p style={{display: 'inline'}}>{text}</p>;
};

export const renderIncompleteFieldByStatus = (status: string) => {
    if (status == 'pending') {
        return 'Pending';
    }
    return 'Unsuccessful';
};

const displayByStatus = {
    [SubDomainStatus.COMPLETED]: CompletedSubDomain,
    [SubDomainStatus.PENDING]: PendingSubDomain,
    [SubDomainStatus.ERROR]: ErrorSubDomain,
};

const SubDomainDisplayComponent = (props: ISubDomainDisplayProps) => {
    // to remove line 42-50 when sftp is decommissioned.
    if (
        props.code === 'SILVERSUPPORTSCHEME' ||
        props.code === 'DEPENDANTSPROTECTIONSCHEME' ||
        props.code === 'CARESHIELDLIFEANDELDERSHIELDLIFE'
    ) {
        if (props.status['CPFB'] === SubDomainStatus.ERROR) {
            if (props.code === 'CARESHIELDLIFEANDELDERSHIELDLIFE') {
                return <CareshieldEldershieldError {...props} />;
            }

            return <ErrorSubDomain {...props} />;
        }
        if (!props.status['CPFB'] && props.status['SSNET'] === SubDomainStatus.PENDING) {
            return <PendingSubDomain {...props} />;
        }
    }
    const {status} = props;
    const statusArray = Object.values(status);
    const toShowStatus = statusArray.every((_status) => _status === SubDomainStatus.ERROR)
        ? SubDomainStatus.ERROR
        : statusArray.every((_status) => _status === SubDomainStatus.PENDING)
        ? SubDomainStatus.PENDING
        : SubDomainStatus.COMPLETED;
    const Component = displayByStatus[toShowStatus];

    return props.isLastSubDomain ? (
        <Box style={{minHeight: '80vh'}}>
            <Component {...props} />
        </Box>
    ) : (
        <Component {...props} />
    );
};

export const SubDomainDisplay = React.memo(SubDomainDisplayComponent);
