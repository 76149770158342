import React, {useState, ReactNode} from 'react';
import {Collapse, TableCell, TableRow, Checkbox} from '@mui/material';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {
    KeyboardArrowUpRounded as KeyboardArrowUpIcon,
    KeyboardArrowDownRounded as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {IBaseModel} from '~/components/Common/ListModel/ListModel';
import {Order} from '~/utils/tableUtils';

export interface IEnhancedTableProps<IModel, IRowData extends IBaseRowData<IModel>> {
    sortedRows: IRowData[];
    columnRenderFunc: ((data: IModel) => ReactNode)[];
    isExpandable: boolean;
    isRowExpand: (id: number) => boolean;
    handleClickExpand: (id: number) => void;
}

export interface IBaseRowData<IModel> {
    id: number;
    model: IModel;
    expandableContent?: React.ReactElement;
}

export const TableRows = <IModel, IRowData extends IBaseRowData<IModel>>({
    sortedRows,
    columnRenderFunc,
    isExpandable,
    isRowExpand,
    handleClickExpand,
}: IEnhancedTableProps<IModel, IRowData>) => {
    return (
        <>
            {sortedRows.map((rowDataContent: IRowData, index: number) => {
                const hasExpandableContent = rowDataContent.expandableContent;
                return (
                    <React.Fragment key={index}>
                        <TableRow
                            data-testid={`content_${index}`}
                            key={`content_${index}`}
                            onClick={() => handleClickExpand(rowDataContent.id)}
                        >
                            {isExpandable ? (
                                hasExpandableContent ? (
                                    <TableCell style={{width: '60px'}}>
                                        <Checkbox
                                            checked={isRowExpand(rowDataContent.id)}
                                            checkedIcon={<KeyboardArrowUpIcon style={{color: '#8d8d8d'}} />}
                                            icon={<KeyboardArrowDownIcon style={{color: '#ccc'}} />}
                                            color="primary"
                                            style={{padding: 0}}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell style={{width: '60px'}} />
                                )
                            ) : (
                                <></>
                            )}
                            {columnRenderFunc.map((column, cellIndex: number) => {
                                return (
                                    <TableCell data-testid={`cell_${cellIndex}`} key={`cell_${cellIndex}`}>
                                        {column(rowDataContent.model)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow
                            style={{backgroundColor: '#f6f6f6'}}
                            data-testid={`expandableRow_${index}`}
                            key={`expandableRow_${index}`}
                        >
                            <TableCell colSpan={7} padding="none" style={{borderBottom: 'none'}}>
                                <Collapse in={isRowExpand(rowDataContent.id)}>
                                    {rowDataContent.expandableContent}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export interface ICustomHeaderColumn<IModel extends IBaseModel, K> extends IHeaderColumn<K> {
    column: (data: IModel) => ReactNode;
}

export const EnhancedTableExpandableRow = <
    Header extends {},
    IRowData extends IBaseRowData<IModel>,
    IModel extends IBaseModel,
>(
    header: ICustomHeaderColumn<IModel, keyof Header>[],
    rowData: IRowData[],
    isExpandable: boolean,
    pagination: boolean,
    sortKey = header[0].id,
    defaultRows = 10,
    sortOrder = 'asc',
    colGroup?: JSX.Element,
    expandedRows?: number[],
) => {
    const [expandList, setExpandList] = useState<number[]>(expandedRows ? expandedRows : []);

    const isRowExpand = (id: number) => expandList.includes(id);

    const expandCollapseRow = (id: number) => {
        const isExpand = isRowExpand(id);
        const updatedList: number[] = isExpand ? expandList.filter((val) => val !== id) : Array.from(expandList);
        !isExpand && updatedList.push(id);
        setExpandList(updatedList);
    };

    const handleClickExpand = (id: number) => {
        isExpandable && expandCollapseRow(id);
    };

    const defaultColumns: IHeaderColumn<keyof Header>[] = isExpandable
        ? [{align: 'inherit', disablePadding: true, label: ''}]
        : [];

    const headerProps = {
        columns: [...defaultColumns, ...header],
    };

    const customStyle = {
        backgroundColor: '#5c6bc0',
        color: 'white',
    };

    const hocProps = {
        colSpan: 12,
        customStyle,
        headerProps,
        tableType: pagination === true ? TABLE_TYPE.DEFAULT : TABLE_TYPE.SIMPLE,
        isRowExpand,
        handleClickExpand,
    };

    const columnRenderFunc = header.map((headerValue): ((data: IModel) => ReactNode) => {
        return headerValue.column;
    });

    const EnhancedTable = WithEnhancedTable<
        {
            sortedRows: IRowData[];
            columnRenderFunc: ((data: IModel) => ReactNode)[];
            isExpandable: boolean;
            isRowExpand: (id: number) => boolean;
            handleClickExpand: (id: number) => void;
        },
        Header,
        IBaseRowData<IModel>
    >(
        TableRows,
        sortKey as keyof Header,
        rowData,
        sortOrder as Order,
        undefined,
        columnRenderFunc,
        isExpandable,
        undefined,
        undefined,
        hocProps.tableType,
        defaultRows,
    );

    return <EnhancedTable {...hocProps} colGroup={colGroup} />;
};
