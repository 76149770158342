import React from 'react';
import {Dialog, DialogContent, DialogActions, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {WarningIcon} from '../Icons';

export interface ICountdownDialogProps {
    modalOpen: boolean;
    countdownInSecs: number;
    onLogout: () => void;
    onResume: () => void;
}

const useStyles = makeStyles({
    container: {
        textAlign: 'center',
    },
    title: {
        color: 'grey',
        fontSize: '15px',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    countDownText: {
        color: 'red',
        fontSize: '40px',
    },
});

export const CountdownDialog = (props: ICountdownDialogProps) => {
    const classes = useStyles();
    return (
        <Dialog classes={{container: classes.container}} open={props.modalOpen}>
            <DialogContent classes={{root: classes.title}}>
                <WarningIcon />
                <br />
                Timeout Warning
            </DialogContent>
            <DialogContent classes={{root: classes.text}}>YOUR CURRENT SESSION WILL END IN</DialogContent>
            <DialogContent classes={{root: classes.countDownText}}>
                {Math.floor(props.countdownInSecs / 60)}m {('0' + (props.countdownInSecs % 60)).slice(-2)}s
            </DialogContent>
            <DialogActions classes={{root: classes.button}}>
                <Button color="secondary" variant="contained" onClick={props.onLogout} id="logout-button">
                    Logout
                </Button>
                <Button variant="contained" color="primary" onClick={props.onResume} id="resume-button">
                    Resume
                </Button>
            </DialogActions>
        </Dialog>
    );
};
