import React from 'react';
import {Typography} from '@mui/material';
import {IIncomeData, IncomeInformationType} from '~/components/Client/SubDomainDisplay/Income/Income';
import './Income.scss';

const getIncomeInfoText = (latestYearOfAssessment: string): {[info: string]: string} => ({
    [IncomeInformationType.NOT_FOUND]: 'Not found in system. Client is not a taxpayer.',
    [IncomeInformationType.NO_ASSESSMENT]: `Tax Assessment for year ${latestYearOfAssessment} is not finalised.`,
    [IncomeInformationType.EXCEEDED]: `Client's income has exceeded $75,000 in year ${latestYearOfAssessment}`,
});

export const NoAnnualIncome = ({InformationType, LatestYearOfAssessment}: IIncomeData) => {
    return (
        <>
            <Typography variant="h6">Income Information</Typography>
            <Typography styleName="noIncome" variant="body2">
                {getIncomeInfoText(LatestYearOfAssessment)[InformationType]}
            </Typography>
        </>
    );
};
