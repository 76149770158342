import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {IUser, emptyUser} from '~/interfaces/admin';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import style from './OpsUnitUserEdit.scss';

import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getTeams} from '~/store/team/team.selector';
import {setTeams} from '~/store/team/team.thunk';
import {activateUser, createUser, deleteUser, updateUser} from '~/store/user/user.thunk';
import {isInternetUser} from '~/utils/userType';

export const OpsUnitUserEdit = () => {
    const dispatch = useAppDispatch();
    const {userId} = useParams<{userId?: string}>();
    const userDetails = useTypedSelector((state) => state.data.admin.users[Number(userId)]);

    const {accessToken} = useContext(AuthenticationContext);

    const opsUnitId = (accessToken as IAccessToken).Permissions.OpsUnitId;
    const agencyName = (accessToken as IAccessToken).AdditionalUserInfo.Agency;
    const opsUnitName = (accessToken as IAccessToken).AdditionalUserInfo.OpsUnit;

    const teamId = userDetails ? userDetails.TeamId : undefined;
    const teamDetails = useTypedSelector((state) => state.data.admin.teams[Number(teamId)]);
    const teamName = teamDetails ? teamDetails.Name : undefined;

    const teams = Object.values(useTypedSelector(getTeams(Number(opsUnitId))));
    const url = `/admin/ops-units/${opsUnitId}/users`;

    useEffect(() => {
        dispatch(setTeams(Number(opsUnitId)));
    }, []);

    const isToCreateUser = !userDetails;
    const [user, setUser] = useState<IUser>(userDetails || emptyUser);

    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();

    const [isCreateUser, setIsCreateUser] = useState<boolean>(isToCreateUser);
    const [isEditUser, setIsEditUser] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const isShowSubmitButton = isCreateUser || isEditUser;

    const [hasError, setHasError] = useState(false);
    const disableButton = !allowSaveUsers(user) || !user.TeamId;
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(disableButton);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeAllEdits = () => {
        setIsEditUser(false);
        setIsCreateUser(false);
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        closeAllEdits();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        if (isCreateUser) {
            const updatedUser = prepareUserCreation([user])[0];
            const thunkActionResult = await dispatch(
                createUser({teamId: Number(updatedUser.TeamId), userDetails: updatedUser, opsUnitId: opsUnitId}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                history.push(`${url}/${thunkActionResult.payload.userDetails.Id}`);
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isEditUser) {
            const updatedUserWithTeamId = {...user, TeamId: Number(user.TeamId)};
            const thunkActionResult = await dispatch(updateUser(updatedUserWithTeamId as IUser));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
            return;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleActivateUser = async (_?: number) => {
        if (user) {
            const thunkActionResult = await dispatch(activateUser({...user, IsActive: true}));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl(`${user.Name} has been reactivated`);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDeleteUser = (_: number) => {
        setIsDeleteDialogOpen(true);
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };
    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(deleteUser({userId: Number(userId), deletedReason}));
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
            setIsDeleting(false);
        } else {
            history.push(url);
        }
    };

    const handleEditUser = () => {
        setIsEditUser(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };
    const confirmCancel = () => {
        if (isCreateUser) {
            history.push(url);
        }
        closeAllEdits();
        userDetails && setUser(userDetails);
        closeConfirmDialog();
    };

    useEffect(() => {
        userDetails && setUser(userDetails);
    }, [userDetails]);

    useEffect(() => {
        setIsButtonDisabled(disableButton || hasError);
    }, [user, hasError]);

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push(url);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.userEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            User Details
                        </Typography>
                    </Grid>
                    <Grid item id="domainHeader" ref={domainHeaderRef}>
                        {isShowSubmitButton && <SubmitButtons />}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                            {`${agencyName} / ${opsUnitName}`}
                        </Typography>
                    </Grid>
                </Grid>
                {isCreateUser || isEditUser ? (
                    <UserDetails
                        details={user}
                        setUser={setUser}
                        fieldsToDisable={
                            isCreateUser ? [] : isInternetUser(user) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        isOaUserMgmt
                        teams={teams}
                        disableInput={isLoading}
                        setHasError={setHasError}
                    />
                ) : (
                    <UserView
                        details={user}
                        userRole="User"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser}
                        handleDeleteUser={handleDeleteUser}
                        teamName={teamName}
                    />
                )}
                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <DeleteWithReasonDialog
                id="ConfirmDeleteOpsUnitUser"
                title={`Delete User?`}
                buttonMessage="USER"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
