import {AccountCircleRounded as AccountCircleIcon} from '@mui/icons-material';
import {SwitchAccountIcon} from '../Common/Icons';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {getStyles} from './Navbar.styles';
import {AppBar, Button, Divider, IconButton, Menu, MenuItem, Toolbar, Typography, ListItemIcon} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React, {useContext, useRef, useState, useEffect} from 'react';
import {LOGIN_STATE, ACCESS_LEVELS, ACCESS_LEVELS_ROLE} from '~/constants';
import oneCVHorizontalLogo from '~/assets/images/oneCVHorizontalLogo.svg';
import {useUpdateWigetSetting} from '~/utils/hooks/useUpdateWidgetSetting';
import Masthead from './Masthead/Masthead';
import {config} from '~/config';
import {NotificationsBell} from '~/components/Common/Notification/NotificationsBell';
import {isRole} from '~/utils/roleUtils';
import './Navbar.scss';

export const Navbar = () => {
    const {accessToken, updateLoginState} = useContext(AuthenticationContext);
    const {updateWidgetVerticalOffset} = useUpdateWigetSetting();
    const profileMenuRef = useRef(null);
    const [isProfileMenuOpen, toggleProfileMenu] = useState<boolean>(false);
    const [isDataUploadPage, setIsDataUploadPage] = useState<boolean>(false);
    const isDisplayMyClientLink = accessToken?.Permissions
        ? accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.APP_USER ||
          accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.DATA_UPLOADER_APP_USER ||
          accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER ||
          accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER ||
          accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR
        : false;
    const shouldDisplayDataContributorLink = accessToken?.Permissions
        ? accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.DATA_UPLOADER_APP_USER
        : false;
    const shouldDiplayAdminDashboardLink =
        isRole(accessToken, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) ||
        isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER) ||
        isRole(accessToken, ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER);
    const profileMenuId = 'primaryProfileMenu';

    const theme = useTheme();

    const styles = getStyles(theme);
    const history = useHistory();
    const {pathname} = useLocation();

    const isInAdminPages = (pathname: string) => {
        return pathname.includes('/admin') || pathname.includes('/data-upload') || pathname.includes('/reports');
    };

    const mainLogoLink = isInAdminPages(pathname) ? '/' : '/search';

    const handleLogOut = async () => {
        updateLoginState(LOGIN_STATE.LOGGED_OUT_MANUAL);
        toggleProfileMenu(false);
    };

    const handleGoToAdminDashboard = async () => {
        toggleProfileMenu(false);
        isRole(accessToken, ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER)
            ? history.push(`/admin/teams/${accessToken?.Permissions.TeamId}/users`)
            : history.push('/admin/dashboard');
    };

    const handleGoToUserScreeningPage = async () => {
        toggleProfileMenu(false);
        history.push('/search');
    };

    const handleGoToSearch = async () => {
        toggleProfileMenu(false);
        history.push('/search');
    };

    const handleDataUploader = async () => {
        toggleProfileMenu(false);
        history.push('/data-upload');
    };

    const handleProfileMenuOpen = () => {
        toggleProfileMenu(true);
    };

    const handleMenuClose = () => {
        toggleProfileMenu(false);
    };

    useEffect(() => {
        if (pathname === '/data-upload') {
            setIsDataUploadPage(true);
        } else {
            setIsDataUploadPage(false);
        }
    }, [pathname]);

    if (config.internetEnabled) updateWidgetVerticalOffset('75px');
    const dataUploaderAppUserMenu = isDataUploadPage
        ? [
              <MenuItem key={0} id="profileMenuSearchClientLink" styleName="menuItem" onClick={handleGoToSearch}>
                  <ListItemIcon>
                      <SwitchAccountIcon />
                  </ListItemIcon>
                  Switch to User
              </MenuItem>,
              <Divider key={1} styleName="divider" />,
          ]
        : [
              <MenuItem key={0} id="profileMenuDataUploadLink" styleName="menuItem" onClick={handleDataUploader}>
                  <ListItemIcon>
                      <SwitchAccountIcon />
                  </ListItemIcon>
                  Switch to Data Contributor
              </MenuItem>,
              <Divider key={1} styleName="divider" />,
          ];

    const dataUploaderAppUserRoleName =
        history.location.pathname === '/data-upload'
            ? ACCESS_LEVELS_ROLE['DataUploaderOnly']
            : ACCESS_LEVELS_ROLE['AppUser'];

    const renderMenu = (
        <Menu
            keepMounted
            anchorEl={profileMenuRef.current}
            anchorOrigin={{vertical: 62, horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
            id={profileMenuId}
            open={isProfileMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{style: {padding: 0}}}
            transitionDuration={shouldDisplayDataContributorLink ? {enter: 500, exit: 0} : 'auto'}
        >
            <div>
                {shouldDisplayDataContributorLink ? dataUploaderAppUserMenu : null}
                {shouldDiplayAdminDashboardLink &&
                    (isInAdminPages(pathname) ? (
                        <>
                            <MenuItem
                                key={0}
                                id="profileMenuSearchClientLink"
                                styleName="menuItem"
                                onClick={handleGoToUserScreeningPage}
                            >
                                <ListItemIcon>
                                    <SwitchAccountIcon />
                                </ListItemIcon>
                                Switch to User
                            </MenuItem>
                            <Divider styleName="divider" />
                        </>
                    ) : (
                        <>
                            <MenuItem
                                key={0}
                                id="profileMenuAdminDashboardLink"
                                styleName="menuItem"
                                onClick={handleGoToAdminDashboard}
                            >
                                <ListItemIcon>
                                    <SwitchAccountIcon />
                                </ListItemIcon>
                                Switch to Admin
                            </MenuItem>
                            <Divider styleName="divider" />
                        </>
                    ))}
                <Button
                    id="profileMenuLogoutLink"
                    variant="text"
                    color="primary"
                    styleName="logOutButton"
                    onClick={handleLogOut}
                >
                    Log out
                </Button>
            </div>
        </Menu>
    );

    const renderLogo = (
        <Link className={styles.link} id="siteMasthead" to={mainLogoLink}>
            <img src={oneCVHorizontalLogo} alt="logo" className={styles.logo} />
        </Link>
    );

    /**
     * Usage of mui menu and popover will cause
     * `findDOMNode is deprecated in StrictMode` warning in strict mode
     * This is a known issue and may be resolved in future material-ui releases
     *  https://github.com/mui-org/material-ui/issues/13394
     */
    return !!accessToken ? (
        <div id="nav-bar">
            <AppBar position="fixed">
                <Toolbar>
                    {renderLogo}

                    <>
                        <div className={styles.grow}>
                            {isDisplayMyClientLink && !isInAdminPages(pathname) && (
                                <Typography noWrap className={styles.sdHide} variant="h6">
                                    <Link className={styles.link} id="navBarMyClientsLink" to="/my-clients">
                                        <div styleName="link">MY CLIENTS</div>
                                    </Link>
                                </Typography>
                            )}
                        </div>

                        <NotificationsBell />

                        <div>
                            <IconButton
                                ref={profileMenuRef}
                                aria-controls={profileMenuId}
                                aria-haspopup="true"
                                aria-label="account of current user"
                                color="inherit"
                                edge="end"
                                id="primaryProfileMenuButton"
                                onClick={handleProfileMenuOpen}
                            >
                                <div className={styles.profileWrapper}>
                                    <AccountCircleIcon className={styles.accountCircle} />
                                    <div className={styles.profileText}>
                                        <Typography id="profileName" variant="body2" className={styles.profileName}>
                                            {accessToken.AdditionalUserInfo.Name}
                                        </Typography>
                                        <Typography id="profileRole" variant="body2" className={styles.profileRole}>
                                            {isRole(accessToken, ACCESS_LEVELS.DATA_UPLOADER_APP_USER)
                                                ? dataUploaderAppUserRoleName
                                                : ACCESS_LEVELS_ROLE[accessToken.Permissions.AccessLevel]}
                                        </Typography>
                                    </div>
                                </div>
                            </IconButton>
                            {renderMenu}
                        </div>
                    </>
                </Toolbar>
            </AppBar>
        </div>
    ) : (
        <AppBar position="fixed">
            <Masthead />
            <Toolbar>{renderLogo}</Toolbar>
        </AppBar>
    );
};
