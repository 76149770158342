import React, {useEffect, useState} from 'react';
import {
    DialogActions,
    Dialog,
    DialogTitle,
    Typography,
    FormControl,
    Button,
    FormHelperText,
    LinearProgress,
    Snackbar,
} from '@mui/material';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';

import './UploadResourceDialog.scss';
import {useResourceTypeAutocompleteStyles} from './UploadResourceDialog.styles';
import {InputField} from '~/components/Common/InputField/InputField';
import {required} from '~/components/Common/InputField/ValidationRules';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {uploadResource} from '~/services/apiServices';
import {ALLOWED_UPLOAD_MIME_TYPES, ALLOWED_UPLOAD_EXTENSIONS} from '~/constants';
import {useGrowl} from '~/utils/hooks/useGrowl';
import axios from 'axios';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {setResources} from '~/store/resources/resources.thunk';

export interface IUploadResourceDialogProps {
    open: boolean;
    onClose: () => void;
    resourceTypeOptions: string[];
}

export const UploadResourceDialog: React.FC<IUploadResourceDialogProps> = ({open, onClose, resourceTypeOptions}) => {
    const dispatch = useAppDispatch();
    const [validationError, setValidationError] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState<boolean>(false);
    const [formCompleted, setFormCompleted] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [uploadFailed, setUploadFailed] = useState<boolean>(false);
    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    const resourceTypeClasses = useResourceTypeAutocompleteStyles();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    useEffect(() => {
        if (name.length && name.length <= 60 && selectedFile && selectedTypes.length) {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
    }, [name, description, selectedFile, selectedTypes]);

    // Set Max File Size to 25 MB
    const isValidFileSize = (file: File) => file && file.size < 25 * 1024 * 1024;

    // Allowed File Types: Excel, Jpeg, PDF, Powerpoint, Word Doc
    const isValidFileType = (file: File) => ALLOWED_UPLOAD_MIME_TYPES.includes(file.type);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event || !event.target || !event.target.files) return;
        const file = event.target.files[0];

        if (!isValidFileSize(file)) {
            setValidationError('The uploaded file exceeds 25mb. Please try again.');
            return;
        }

        if (!isValidFileType(file)) {
            setValidationError('Invalid file type. Please try again.');
            return;
        }

        setSelectedFile(file);
        setName(file.name.substring(0, 60));
    };

    const toggleSelectAll = () => {
        if (resourceTypeOptions.length === selectedTypes.length) {
            setSelectedTypes([]);
        } else {
            setSelectedTypes(resourceTypeOptions);
        }
    };

    const cancelUpload = () => {
        onClose();
        cancelTokenSource.cancel();
    };

    const handleUpload = async () => {
        try {
            if (selectedFile) {
                await uploadResource(selectedFile, name, description, selectedTypes, setProgress, cancelToken);
                dispatch(setResources());
                onClose();
                openGrowl(`${name} successfully uploaded!`);
            }
        } catch (err) {
            const error = err as Error;
            setProgress(0);
            setUploadFailed(true);
            openGrowl(error.message);
        }
    };

    const uploadFileForm = (
        <div styleName="uploadFileForm">
            <FormControl fullWidth styleName="formControl">
                <InputField
                    htmlFor="name"
                    value={name}
                    onChange={setName}
                    label="File Name"
                    validations={[required('file name')]}
                    maxLength={60}
                />
            </FormControl>

            <FormControl fullWidth styleName="formControl">
                <InputField
                    htmlFor="description"
                    value={description}
                    onChange={setDescription}
                    label="Add description"
                    multiline
                    rows={2}
                    maxLength={100}
                />
                <FormHelperText styleName="textRight">{100 - description.length} characters remaining</FormHelperText>
            </FormControl>

            <FormControl fullWidth styleName="formControl">
                <MultiSelectAutocomplete
                    options={resourceTypeOptions}
                    selectedValues={selectedTypes}
                    label="User Group(s)"
                    tagRenderer={(value) => (
                        <Typography variant="subtitle1" styleName="filterTag">
                            {value.length} User Groups Selected
                        </Typography>
                    )}
                    onToggleOption={setSelectedTypes}
                    onClearOptions={() => setSelectedTypes([])}
                    onSelectAll={toggleSelectAll}
                    classes={resourceTypeClasses}
                />
            </FormControl>
        </div>
    );

    return (
        <>
            <Dialog
                open={open && progress === 0 && !uploadFailed}
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
                styleName={confirmCancelOpen ? 'hidden' : ''}
            >
                <DialogTitle id="form-dialog-title">Upload File</DialogTitle>

                {!selectedFile ? (
                    <>
                        <div styleName="files">
                            <input
                                id="file"
                                type="file"
                                name="file"
                                onChange={onChangeHandler}
                                accept={[...ALLOWED_UPLOAD_MIME_TYPES, ...ALLOWED_UPLOAD_EXTENSIONS].join(',')}
                            />
                            <span styleName="upload-smallPrint">
                                We accept Excel, Jpeg, PDF, Powerpoint, Word Doc formats with max file size of 25mb.
                            </span>
                            <label htmlFor="file">Browse</label>
                        </div>
                        {validationError && <span styleName="upload-errorMessage">{validationError}</span>}
                    </>
                ) : (
                    uploadFileForm
                )}

                <DialogActions>
                    <Button onClick={() => setConfirmCancelOpen(true)} color="primary" variant="text">
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} color="primary" variant="contained" disabled={!formCompleted}>
                        UPLOAD
                    </Button>
                </DialogActions>
            </Dialog>

            {selectedFile && (
                <Dialog
                    open={progress > 0 || uploadFailed}
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth={true}
                    styleName={confirmCancelOpen ? 'hidden' : ''}
                >
                    <DialogTitle id="form-dialog-title">Uploading in Progress</DialogTitle>
                    <span styleName="remainOnScreenText">
                        Please remain on this page as we are uploading the resources.
                    </span>

                    <div styleName="progressContainer">
                        <span styleName="fileName">{selectedFile.name}</span>
                        <span styleName="fileSize">({(selectedFile.size / (1024 * 1024)).toFixed(2)} MB)</span>
                        <LinearProgress styleName="progressBar" variant="determinate" value={progress} />
                    </div>
                    {uploadFailed && (
                        <span styleName="upload-errorMessage">There is an error in the upload. Please try again.</span>
                    )}

                    <DialogActions>
                        <Button onClick={() => setConfirmCancelOpen(true)} color="primary" variant="text">
                            Cancel Upload
                        </Button>
                        {uploadFailed && (
                            <Button
                                onClick={handleUpload}
                                color="primary"
                                variant="contained"
                                disabled={!formCompleted}
                            >
                                TRY AGAIN
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}

            <ConfirmDialog
                onCancel={() => setConfirmCancelOpen(false)}
                onConfirm={cancelUpload}
                open={confirmCancelOpen}
                title="Cancel upload?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />

            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};
