/* eslint-disable react/display-name */
import React from 'react';
import {Card, CardContent, Typography, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {
    PersonRounded as PersonIcon,
    LocationCityRounded as LocationCityIcon,
    GroupRounded as GroupIcon,
    ApartmentRounded as ApartmentIcon,
    SearchRounded as SearchIcon,
    AssignmentIndRounded as ScreeningIcon,
} from '@mui/icons-material';
import './DashboardCard.scss';

export interface ICountViewProps {
    title: string;
    value: string | null;
    cardType: CardType;
}

export enum CardType {
    AGENCY = 'AGENCY',
    OPSUNIT = 'OPSUNIT',
    TEAM = 'TEAM',
    USER = 'USER',
    SEARCH = 'SEARCH',
    SCREENING = 'SCREENING',
}

interface ICardType {
    color: string;
    icon: JSX.Element;
}

export const getCard = (cardType: CardType): ICardType | null => {
    switch (cardType) {
        case CardType.AGENCY:
            return {
                color: '#1A237E',
                icon: <LocationCityIcon data-testid="agencyIcon" styleName="cardIcon" />,
            };
        case CardType.OPSUNIT:
            return {
                color: '#1A237E',
                icon: <ApartmentIcon data-testid="opsUnitIcon" styleName="cardIcon" />,
            };
        case CardType.TEAM:
            return {
                color: '#1A237E',
                icon: <GroupIcon data-testid="teamIcon" styleName="cardIcon" />,
            };
        case CardType.USER:
            return {
                color: '#303F9F',
                icon: <PersonIcon data-testid="userIcon" styleName="cardIcon" />,
            };
        case CardType.SEARCH:
            return {
                color: '#5C6AC0',
                icon: <SearchIcon data-testid="searchIcon" styleName="cardIcon" />,
            };
        case CardType.SCREENING:
            return {
                color: '#5C6AC0',
                icon: <ScreeningIcon data-testid="screeningIcon" styleName="cardIcon" />,
            };
        default:
            return null;
    }
};

const useStyles = makeStyles({
    root: {
        background: (props: ICountViewProps) => getCard(props.cardType)?.color,
    },
});

export const DashboardCard: React.FunctionComponent<ICountViewProps> = (props) => {
    const classes = useStyles(props);
    const valueIsCount = !isNaN(Number(props.value));
    const icon = getCard(props.cardType)?.icon;
    return (
        <Card styleName="card" className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography data-testid="title" variant="subtitle1">
                            {props.title}
                        </Typography>
                    </Grid>
                    <Grid data-testid="icon" item xs={1}>
                        {icon}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            data-testid="value"
                            variant="body2"
                            style={valueIsCount ? {fontSize: '48px'} : {marginTop: '8px', fontSize: '20px'}}
                        >
                            {props.value}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
