import React from 'react';
import {Tooltip} from '@mui/material';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {withStyles} from '@mui/styles';
import {COPIES} from '~/constants';

interface IToolTip {
    message?: string;
}

export const ToolTip = ({message = COPIES.SUBDOMAIN_NOT_GRANTED_MESSAGE}: IToolTip) => {
    const StyledTooltip = withStyles({
        tooltip: {
            fontSize: '12px',
            minWidth: '580px',
            textAlign: 'center',
        },
    })(Tooltip);
    return (
        <StyledTooltip
            arrow={true}
            title={message}
            placement="top"
            style={{verticalAlign: 'middle', marginLeft: '3px', color: '#606060'}}
        >
            <InfoIcon fontSize={'small'} />
        </StyledTooltip>
    );
};
