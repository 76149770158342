import React from 'react';
import {allNoResults, extractAllValues, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {dateFormatter} from '~/utils/dateUtils';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {Typography} from '@mui/material';
import {COLORS} from '~/components/Common/Colors';

export type IVehicleOwnershipsData = IVehicleOwnership[];

interface IVehicleOwnership {
    VehicleModel: string;
    VehicleMake: string;
    VehicleType: string;
    VehicleStatus: string;
    OwnershipEffectiveDatetime: string;
    FirstRegistrationDate: string;
    COEExpiryDate: string;
}

const statusSequence = ['Live', 'Deregistered'];

export const VehicleOwnerships: React.FunctionComponent<{data: IVehicleOwnershipsData}> = ({data}) => {
    const allFields = extractAllValues(data);
    if (allNoResults(allFields)) {
        return <NoData />;
    }

    const GetFields = (vehicleOwnership: IVehicleOwnership) => [
        {
            label: 'Status of Vehicle',
            value:
                vehicleOwnership.VehicleStatus == 'Live' ? (
                    <Typography variant="body1" style={{display: 'inline', color: COLORS.GREEN, fontWeight: 'bold'}}>
                        {vehicleOwnership.VehicleStatus}
                    </Typography>
                ) : (
                    vehicleOwnership.VehicleStatus
                ),
            fullWidth: true,
        },
        {
            label: 'First Registration Date of Vehicle',
            value: formatValue(vehicleOwnership.FirstRegistrationDate, dateFormatter),
        },
        {
            label: 'Ownership Effective Date',
            value: formatValue(vehicleOwnership.OwnershipEffectiveDatetime, dateFormatter),
        },
        {label: 'COE Expiry Date', value: formatValue(vehicleOwnership.COEExpiryDate, dateFormatter)},
        {
            label: 'Type of Vehicle',
            value: vehicleOwnership.VehicleType,
        },
        {
            label: 'Make of Vehicle',
            value: vehicleOwnership.VehicleMake,
        },
        {
            label: 'Model of Vehicle',
            value: vehicleOwnership.VehicleModel,
        },
    ];

    const sortedData = data
        .sort((data1, data2) =>
            statusSequence.indexOf(data1.VehicleStatus) > statusSequence.indexOf(data2.VehicleStatus) ? 1 : -1,
        )
        .sort((data1, data2) =>
            data1.VehicleStatus == data2.VehicleStatus
                ? new Date(data2.OwnershipEffectiveDatetime).getTime() >
                  new Date(data1.OwnershipEffectiveDatetime).getTime()
                    ? 1
                    : -1
                : 0,
        );

    const fieldsToRender = sortedData.map((vehicleOwnership) => GetFields(vehicleOwnership));

    return (
        <MultiGridDisplay
            dataFieldGroups={fieldsToRender}
            displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
            dataTestId="vehicleOwnership"
        />
    );
};
