import React, {useState} from 'react';
import {ClickAwayListener, Box} from '@mui/material';
import {DateInput} from '~/components/Common/DateInput/DateInput';
import {IReport} from '~/pages/Admin/Reports/Report';
import {oneCVMonthlyAdminReviewStartDay} from '~/constants';

export interface IDateRange {
    Id: number;
    DateFrom?: Date | string;
    DateTo?: Date | string;
}

interface IDateRangePickerDefaults {
    dateFormat?: {
        byMonth?: string;
        byRange?: string;
    };
}

interface IDateRangePickerSettings {
    dateFormat: {
        byMonth: string;
        byRange: string;
    };
}

export interface IDateRangePickerProps {
    data: IReport;
    onDateChange?: (range: IDateRange) => void;
    storeReportWithErrors?: (value: number) => void;
    removeReportFromErrors?: (value: number) => void;
    defaults?: IDateRangePickerDefaults;
}

const selectByMonthDateFormat = 'MMM yyyy';

const selectByRangeDateFormat = 'dd/MM/yyyy';

const parseSettings = ({defaults = {}}: {defaults?: IDateRangePickerDefaults}): IDateRangePickerSettings => {
    return {
        dateFormat: {
            byMonth: defaults?.dateFormat?.byMonth || selectByMonthDateFormat,
            byRange: defaults?.dateFormat?.byRange || selectByRangeDateFormat,
        },
    };
};

export const ByMonthSelector: React.FunctionComponent<IDateRangePickerProps> = ({
    data,
    onDateChange,
    storeReportWithErrors,
    removeReportFromErrors,
    defaults,
}) => {
    const settings = parseSettings({defaults});

    const [dateTo, setDateTo] = useState(new Date());
    const [openDateTo, setOpenDateTo] = useState(false);

    const handleDateToClickAway = () => {
        setOpenDateTo(false);
    };

    React.useEffect(() => {
        if (onDateChange) {
            onDateChange({Id: data.Id, DateFrom: data.DateFrom, DateTo: dateTo});
        }
    }, [dateTo]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box mr={1} mb={2}>
                <span>For</span>
            </Box>

            <ClickAwayListener onClickAway={handleDateToClickAway}>
                <div style={{width: '135px'}}>
                    <DateInput
                        label=""
                        value={dateTo}
                        onChange={(v) => setDateTo(v as unknown as Date)}
                        dateFormat={settings.dateFormat?.byMonth}
                        minDate={new Date(oneCVMonthlyAdminReviewStartDay)}
                        maxDate={new Date()}
                        selectOnly={true}
                        open={openDateTo}
                        setOpen={setOpenDateTo}
                        smallSize={true}
                        selectByMonth={data.SelectByMonth}
                        storeReportWithErrors={() => storeReportWithErrors && storeReportWithErrors(data.Id)}
                        removeReportFromErrors={() => removeReportFromErrors && removeReportFromErrors(data.Id)}
                    />
                </div>
            </ClickAwayListener>
        </div>
    );
};

export const ByRangeSelector: React.FunctionComponent<IDateRangePickerProps> = ({data, onDateChange, defaults}) => {
    const settings = parseSettings({defaults});

    const [dateFrom, setDateFrom] = useState(
        typeof data.DateFrom === 'string' ? new Date(data.DateFrom) : data.DateFrom || new Date(),
    );
    const [dateTo, setDateTo] = useState(new Date());
    const [openDateFrom, setOpenDateFrom] = useState(false);
    const [openDateTo, setOpenDateTo] = useState(false);

    const handleDateFromClickAway = () => {
        setOpenDateFrom(false);
    };
    const handleDateToClickAway = () => {
        setOpenDateTo(false);
    };

    React.useEffect(() => {
        if (onDateChange) {
            onDateChange({Id: data.Id, DateFrom: dateFrom, DateTo: dateTo});
        }
    }, [dateTo, dateFrom]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <ClickAwayListener onClickAway={handleDateFromClickAway}>
                <div style={{minWidth: '45%'}}>
                    <DateInput
                        label=""
                        value={dateFrom}
                        onChange={(v) => setDateFrom(v as unknown as Date)}
                        dateFormat={settings.dateFormat.byRange}
                        maxDate={dateTo}
                        selectOnly={true}
                        open={openDateFrom}
                        setOpen={setOpenDateFrom}
                        smallSize={true}
                    />
                </div>
            </ClickAwayListener>
            <div style={{padding: '8px', paddingTop: '10px'}}>to</div>
            <ClickAwayListener onClickAway={handleDateToClickAway}>
                <div style={{minWidth: '45%'}}>
                    <DateInput
                        label=""
                        value={dateTo}
                        onChange={(v) => setDateTo(v as unknown as Date)}
                        dateFormat={settings.dateFormat.byRange}
                        maxDate={new Date()}
                        minDate={dateFrom}
                        selectOnly={true}
                        open={openDateTo}
                        setOpen={setOpenDateTo}
                        smallSize={true}
                    />
                </div>
            </ClickAwayListener>
        </div>
    );
};

export const DateRangePicker: React.FunctionComponent<IDateRangePickerProps> = ({
    data,
    onDateChange,
    storeReportWithErrors,
    removeReportFromErrors,
    defaults,
}) => {
    if (data.DateFrom && data.DateTo) {
        return (
            <>
                {data.SelectByMonth && (
                    <ByMonthSelector
                        data={data}
                        onDateChange={onDateChange}
                        storeReportWithErrors={storeReportWithErrors}
                        removeReportFromErrors={removeReportFromErrors}
                        defaults={defaults}
                    />
                )}
                {!data.SelectByMonth && <ByRangeSelector data={data} onDateChange={onDateChange} defaults={defaults} />}
            </>
        );
    }

    if (data.fixedDate) {
        return <>As of 16 Aug 2022</>;
    }

    return <>As of Today</>;
};
