export enum DENOMINATOR {
    CENT = 100,
    DOLLAR = 1,
}

export const formatCurrency = (
    amountInCents: number,
    fractionalDigits = 2,
    style: Intl.NumberFormatPartTypes = 'decimal',
    denominator = DENOMINATOR.DOLLAR,
) =>
    new Intl.NumberFormat('en-US', {
        style,
        currency: 'USD',
        minimumFractionDigits: fractionalDigits,
        maximumFractionDigits: fractionalDigits,
    }).format(amountInCents / denominator);

export const currencyFormatter = (value: string): string => {
    return formatCurrency(+value);
};
