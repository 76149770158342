import React from 'react';
import {Snackbar, SnackbarProps} from '@mui/material';

export const ClientSnackBar = (props: SnackbarProps) => {
    return (
        <Snackbar
            {...props}
            ContentProps={{
                style: {
                    textAlign: 'left',
                    width: '100%',
                },
            }}
            style={{
                width: '50%',
            }}
        />
    );
};
