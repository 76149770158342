import {useHistory} from 'react-router-dom';
import {INavigationLink, INACTIVE_COLOR, SELECTED_COLOR} from './AdminNavigation';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {ListItem, Grid, Typography, Collapse, Divider} from '@mui/material';
import React, {useState} from 'react';
import {ChecklistIcon} from '~/components/Common/Icons';
import './AdminSideBar.scss';

export interface IAdminSideBarDropdownProps {
    dropdownChildren: INavigationLink[];
    name: string;
}

export const DropdownLinksList = (props: IAdminSideBarDropdownProps) => {
    const {dropdownChildren, name} = props;

    const history = useHistory();
    const pathname = history.location.pathname;
    const [open, setOpen] = useState<boolean>(true);

    return (
        <>
            <ListItem
                onClick={() => setOpen(!open)}
                disableGutters
                styleName="listItem"
                data-testid={`dropdown ${name}`}
            >
                <Grid container>
                    <Grid item xs={2}>
                        <ChecklistIcon fill={INACTIVE_COLOR} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography styleName={'label'}>{name}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                </Grid>
            </ListItem>

            <Collapse in={open} timeout="auto">
                {dropdownChildren.map((child, index) => (
                    <span key={index}>
                        <ListItem
                            disableGutters
                            styleName="listItem"
                            onClick={() => {
                                history.push(child.path);
                            }}
                            data-testid={`dropdown item ${child.name}`}
                        >
                            <Grid container>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={10}>
                                    <span styleName="dropownItemLabel">
                                        <Typography
                                            styleName={child.isSelected(pathname) ? 'selected labelSelected' : 'label'}
                                        >
                                            {child.name}
                                        </Typography>
                                    </span>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </span>
                ))}
            </Collapse>
        </>
    );
};
