const footerId = 'siteFooter';
export const setFooterDisplay = (isVisible: boolean) => {
    const footer = document.getElementById(footerId);

    if (footer) {
        if (isVisible) {
            footer.style.display = 'block';
        } else {
            footer.style.display = 'none';
        }
    }
};
export const unsetFooterDisplay = () => {
    const footer = document.getElementById(footerId);
    if (footer) {
        footer.style.display = '';
    }
};
