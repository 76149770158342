import {Box, Grid, Typography} from '@mui/material';
import {get} from 'lodash';
import React from 'react';
import {InputField} from '~/components/Common/InputField/InputField';
import {COPIES} from '~/constants';
import {IAnnouncement} from '~/interfaces/admin';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {ActiveIcon} from '../ActiveIcon/ActiveIcon';
import {InactiveIcon} from '../InactiveIcon/InactiveIcon';
import style from './AnnouncementEdit.scss';
import {DateTimeRangePicker} from './DateTimeRangePicker';

const MESSAGE_MAX_CHARACTERS = 350;
const NAME_MAX_CHARACTERS = 50;

interface IAnnouncementEdit {
    announcement: IAnnouncement;
    setAnnouncement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
    isEdit: boolean;
    isDefault: boolean;
    setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AnnouncementEdit = ({
    announcement,
    setAnnouncement,
    isEdit,
    isDefault,
    setHasError,
}: IAnnouncementEdit) => {
    const message = get(announcement, 'message', '');
    const updatedAtDate = announcement?.updatedAt && new Date(announcement.updatedAt);
    const formattedDateTime =
        updatedAtDate && `${formatDate(updatedAtDate, true)} ${formatTime(updatedAtDate, true, true, false, true)}`;
    const startDate = announcement?.startDate && new Date(announcement.startDate);
    const endDate = announcement?.endDate && new Date(announcement.endDate);
    const formattedStartDate =
        startDate && `${formatDate(startDate, true)} ${formatTime(startDate, true, true, false, true)}`;
    const formattedEndDate = endDate && `${formatDate(endDate, true)} ${formatTime(endDate, true, true, false, true)}`;

    const HeaderView = () => (
        <Grid item>
            <Typography component="h2" variant="h6" data-testid="announcementHeader">
                {isDefault ? 'Default Announcement' : announcement.name}
            </Typography>
            <Typography variant="body2" data-testid="announcementSubheader">
                {isDefault
                    ? 'This is the default announcement message that users will see'
                    : startDate && endDate
                    ? `This announcement is scheduled from ${formattedStartDate} to ${formattedEndDate}`
                    : `There is no scheduled time for this announcement`}
            </Typography>
        </Grid>
    );

    return (
        <>
            <Grid container direction="column" spacing={2}>
                {isDefault || !isEdit ? (
                    <HeaderView />
                ) : (
                    <Grid item>
                        <Typography component="h2" variant="h6">
                            <InputField
                                key="nameInput"
                                className={style.messageInput}
                                htmlFor="name-input"
                                onChange={(value) => {
                                    setAnnouncement({...announcement, name: value} as IAnnouncement);
                                }}
                                value={announcement.name}
                                placeholder="Announcement Name"
                                label="Announcement Name"
                                data-testid="announcementNameInputField"
                                maxLength={NAME_MAX_CHARACTERS}
                            />
                        </Typography>
                        <Box component="div" className={style.dateHeading}>
                            <Typography variant="subtitle1">Scheduled from</Typography>
                        </Box>
                        <DateTimeRangePicker
                            announcement={announcement}
                            setAnnouncement={setAnnouncement}
                            setHasError={setHasError}
                        />
                    </Grid>
                )}
                {!isDefault && (
                    <Grid item>
                        {
                            announcement.isActive ? <ActiveIcon /> : <InactiveIcon /> // if default announcement, don't need to show active status
                        }
                    </Grid>
                )}
                <Grid item>
                    {isEdit ? (
                        <InputField
                            key="messageInput"
                            className={style.messageInput}
                            htmlFor="message-input"
                            onChange={(value) => {
                                setAnnouncement({...announcement, message: value} as IAnnouncement);
                            }}
                            value={message}
                            placeholder="Broadcast a message to users!"
                            multiline
                            rows={4}
                            label="Message"
                            maxLength={MESSAGE_MAX_CHARACTERS}
                            displayCharCounter
                            data-testid="announcementMessageInputField"
                        />
                    ) : (
                        <Typography data-testid="announcementMessage">
                            {announcement?.message ? announcement.message : COPIES.NO_ANNOUNCEMENT_MESSAGE}
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    <Typography className={style.updatedMessage} variant="caption" data-testid="updatedInfo">
                        {announcement?.updatedBy &&
                            announcement.updatedAt &&
                            `Last update was made on ${formattedDateTime} by ${announcement.updatedBy}`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};
