import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Grid, Typography, Box, Tabs, Tab} from '@mui/material';
import {orderBy} from 'lodash';

import {API_FETCH_STATE} from '~/constants';
import {IOpsUnitDataFieldRejectReason, IRequestWindowLog} from '~/interfaces/admin';
import * as services from '~/services/dataAccessMatrixService';

import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {BackButton} from '~/components/Common/Button/BackButton';
import listNotFound from '~/assets/images/listNotFound.svg';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {getSubDomainName} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {getDamFieldsFetchState} from '~/store/fetchState/fetchState.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';

import {RequestWindowLog} from './RequestWindowLog';
import {RejectedAccessHistory} from './RejectedAccessHistory';

import '../Admin.scss';
import './DataAccessRequestHistory.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface INoData {
    mainMessage: string;
    message: string;
}

export const DataAccessRequestHistory = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [value, setValue] = useState(0);
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const {domainId} = useParams<{domainId: string}>();
    const {subdomainId} = useParams<{subdomainId: string}>();
    const [reasons, setReasons] = useState<IOpsUnitDataFieldRejectReason[]>([]);
    const [windowLogs, setWindowLogs] = useState<IRequestWindowLog[]>([]);
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [fetechReasonsStatus, setFetechReasonsStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const damFieldsFetchState = useTypedSelector(getDamFieldsFetchState);
    const subdomainName = useTypedSelector(getSubDomainName(parseInt(domainId), parseInt(subdomainId)));

    useEffect(() => {
        const opsUnitIdInt = isNaN(parseInt(opsUnitId)) ? 0 : parseInt(opsUnitId);
        const subdomainIdInt = isNaN(parseInt(subdomainId)) ? 0 : parseInt(subdomainId);
        const domainIdInt = isNaN(parseInt(domainId)) ? 0 : parseInt(domainId);

        if (!opsUnitIdInt || !subdomainIdInt || !domainIdInt) {
            history.push('/admin/dashboard/');
            setFetechReasonsStatus(API_FETCH_STATE.ERROR);
            return;
        }
        dispatch(setDataAccessMatrix());
        (async () => {
            try {
                const rejectedReasonsPromise = services.getRejectAccessHistoryListBySubdomain(
                    opsUnitIdInt,
                    subdomainIdInt,
                );
                const logsPromise = services.getRequestWindowLogs(parseInt(opsUnitId), parseInt(subdomainId));
                const [rejectedReasons, logs] = await Promise.all([rejectedReasonsPromise, logsPromise]);
                if (rejectedReasons) {
                    const sortedReason = orderBy(rejectedReasons, ['RejectedOn'], ['desc']);
                    setReasons(sortedReason);
                }
                if (logs) {
                    setWindowLogs(logs);
                }
                setFetechReasonsStatus(API_FETCH_STATE.SUCCESS);
            } catch (e) {
                setFetechReasonsStatus(API_FETCH_STATE.ERROR);
            }
        })();
    }, []);

    useEffect(() => {
        if (damFieldsFetchState === API_FETCH_STATE.SUCCESS && fetechReasonsStatus === API_FETCH_STATE.SUCCESS) {
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        }

        if (damFieldsFetchState === API_FETCH_STATE.ERROR || fetechReasonsStatus === API_FETCH_STATE.ERROR) {
            setCombinedFetchStatus(API_FETCH_STATE.ERROR);
        }
    }, [damFieldsFetchState, fetechReasonsStatus]);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const CustomTabPanel = (props: TabPanelProps) => {
        const {children, value, index, ...other} = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{pt: 3}}>{children}</Box>}
            </div>
        );
    };

    const NoData = ({mainMessage, message}: INoData) => {
        return (
            <Grid container style={{marginTop: '20px'}}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <img src={listNotFound} alt="Not Found" styleName="not-found-svg" />
                        <h1 styleName="not-found-header">{mainMessage}</h1>
                        <p styleName="not-found-text">{message}</p>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    const TabPanels = () => {
        return (
            <>
                <CustomTabPanel value={value} index={0}>
                    {windowLogs?.length !== 0 && <RequestWindowLog windowLogs={windowLogs} />}
                    {windowLogs?.length === 0 && (
                        <NoData
                            mainMessage="No request windows yet"
                            message="Request windows and the activity of each window will show up here."
                        />
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {reasons?.length !== 0 && (
                        <RejectedAccessHistory
                            subDomainId={parseInt(subdomainId)}
                            domainId={parseInt(domainId)}
                            reasons={reasons}
                        />
                    )}
                    {reasons?.length === 0 && (
                        <NoData
                            mainMessage="No rejected access found"
                            message="Data fields that are rejected and the reasons for rejection will show up here."
                        />
                    )}
                </CustomTabPanel>
            </>
        );
    };

    const TabPanelsWithLoader = WithLoader<{}>(TabPanels, combinedFetchStatus);

    return (
        <Grid container data-testid="damHeader" direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={12}>
                <BackButton
                    onClick={() => {
                        history.push(`/admin/v2/data-access-management/ops-unit/${opsUnitId}/domain/${domainId}`);
                    }}
                    label="Back"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Data Request History for {subdomainName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid styleName="reportTableStyle">
                    <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                        <Tabs value={value} onChange={handleChange} variant="fullWidth" styleName="tabsWrapper">
                            <Tab label="Request window log" />
                            <Tab label="Rejected Access History" />
                        </Tabs>
                    </Box>
                    <TabPanelsWithLoader />
                </Grid>
            </Grid>
        </Grid>
    );
};
