import {Button, Grid, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {startCase} from 'lodash';
import {format} from 'date-fns';
import {InputField} from '~/components/Common/InputField/InputField';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import {config} from '~/config';
import {COPIES, REPORT_TYPE} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IOpsUnit} from '~/interfaces/admin';
import {getReports} from '~/services/reportServices';
import {getOpsUnits} from '~/store/opsUnit/opsUnit.selector';
import {setOpsUnits as storeOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import btnStyle from '../../../../components/Common/Button/Button.scss';
import {IReportRequest} from '../../Reports/Report';
import style from './OpsUnits.scss';

const compareFun = (a: IOpsUnit, b: IOpsUnit) => {
    if (a.Name < b.Name) return -1;
    if (a.Name > b.Name) return 1;
    return 0;
};

export const OpsUnits = () => {
    const {accessToken} = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const opsUnitsData =
        Object.values(useTypedSelector(getOpsUnits(accessToken?.Permissions.AgencyId))).sort(compareFun) || [];
    const [opsUnits, setOpsUnits] = useState<IOpsUnit[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const headers = ['Ops Unit Name', 'Last updated date', 'Last updated by', 'Data Access'];

    const nameCell = (data: {Id?: number; Name: string}) => <div>{data.Name}</div>;
    const lastUpdatedAtCell = (data: {Id?: number; DataDomainUpdatedAt?: Date}) => (
        <div>{data.DataDomainUpdatedAt ? format(new Date(data.DataDomainUpdatedAt), 'dd MMMM yyyy') : '-'}</div>
    );
    const lastUpdatedByCell = (data: {Id?: number; DataDomainUpdatedByUser?: {Id?: number; Name: string}}) => (
        <div>{data.DataDomainUpdatedByUser?.Name ? startCase(data.DataDomainUpdatedByUser?.Name || '') : '-'}</div>
    );
    const viewBtnCell = (opsUnit: IOpsUnit) => {
        return (
            <Button
                className={style.viewBtn}
                onClick={() => {
                    history.push(`/admin/data-access-management/ops-unit/${opsUnit.Id}`);
                }}
            >
                VIEW
            </Button>
        );
    };

    const headerCellStyle = (data: React.ReactNode): React.CSSProperties => {
        const style: React.CSSProperties = {backgroundColor: '#C5CAE9', color: '#3C3C3C'};
        if (data?.toString() === headers[0]) {
            style.width = '40%';
        }
        if (data?.toString() === headers[1]) {
            style.width = '22%';
        }
        if (data?.toString() === headers[2]) {
            style.width = '23%';
        }
        if (data?.toString() === headers[3]) {
            style.textAlign = 'center';
        }
        return style;
    };

    const handleDownloadReport = () => {
        setPasswordModalOpen(true);
        setDisableBtn(true);
    };

    const handlePasswordModalClose = () => {
        setPasswordModalOpen(false);
        setDisableBtn(false);
    };

    const handlePasswordSubmit = async (password: string) => {
        const reportRequest: IReportRequest = {
            Reports: [
                {
                    Id: 0,
                    Type: REPORT_TYPE.DAM_REPORT_AA,
                    Name: 'DAM Report',
                    Description: 'Data Access Management for AA',
                    Selected: true,
                },
            ],
            Password: password,
        };
        await getReports(reportRequest);
    };

    useEffect(() => {
        setOpsUnits(opsUnitsData);
    }, [opsUnitsData.length]);

    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2_VIEW) {
            history.goBack();
        }
        if (accessToken && accessToken.Permissions.AgencyId) dispatch(storeOpsUnits(accessToken.Permissions.AgencyId));
    }, []);

    return (
        <Grid container alignItems="center">
            <Grid item xs={9}>
                <Typography variant="h4">Data Access Management</Typography>
            </Grid>
            <Grid item xs>
                <Button
                    size="large"
                    color="primary"
                    data-testid="downloadReport"
                    className={disableBtn ? btnStyle.disabled : btnStyle.normal}
                    disabled={disableBtn}
                    onClick={handleDownloadReport}
                >
                    <span>DOWNLOAD REPORT</span>
                </Button>
            </Grid>
            <Grid className={style.inputContainer} item xs={9}>
                <InputField
                    className={style.input}
                    htmlFor="ops-unit-search"
                    label="Search Ops Unit"
                    type="search"
                    value={searchValue}
                    onChange={setSearchValue}
                />
            </Grid>
            <Grid xs={12} item>
                <div>
                    <ListModel
                        columns={[nameCell, lastUpdatedAtCell, lastUpdatedByCell, viewBtnCell]}
                        headers={headers}
                        customHeaderCellStyle={headerCellStyle}
                        modelList={opsUnits.filter(
                            (unit) => unit.Name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                        )}
                        rowsPerPage={opsUnits.length}
                        defaultSortOrder="asc"
                        variant="expanded"
                        sortable={false}
                    />
                </div>
            </Grid>
            <ReportPasswordDialog
                open={passwordModalOpen}
                onSubmit={handlePasswordSubmit}
                onClose={handlePasswordModalClose}
                title={COPIES.REPORT_PASSWORD_DIALOG_TITLE}
                description={COPIES.REPORT_PASSWORD_DIALOG_BODY}
            />
        </Grid>
    );
};
