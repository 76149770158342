import React, {useState, useEffect, SetStateAction} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Fade,
    FormControl,
    Grid,
    MenuItem,
    Typography,
    Paper,
    Popover,
} from '@mui/material';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {CONSENT_TYPE, COPIES, consentClause} from '~/constants';
import {IFamilyMember} from '~/interfaces/client';
import {formatDate} from '~/utils/dateUtils';
import {
    ArrowDropDownRounded as ArrowDropDownIcon,
    ArrowRightRounded as ArrowRightIcon,
    CheckCircleRounded as CheckCircleIcon,
    InfoRounded as InfoIcon,
} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {UploadConsent} from './UploadConsent';
import {DateInput} from '~/components/Common/DateInput/DateInput';
import {addDays, isAfter, isBefore, subYears} from 'date-fns';

import styles from './Consent.scss';
interface IConsentProps {
    familyMember: IFamilyMember;
    items: IRowData[];
    onConsentTypeChange: (
        familyMember: IFamilyMember,
        consent: CONSENT_TYPE,
        consentId?: number,
        minorConsentDate?: Date,
    ) => void;
    setHasMinorDateError: React.Dispatch<SetStateAction<boolean>>;
}

export const Consent = ({familyMember, items, onConsentTypeChange, setHasMinorDateError}: IConsentProps) => {
    const [inputValue, setInputValue] = useState<number | null>(null);
    const isUpload = Boolean(inputValue === 0 || inputValue === 1 || inputValue === 2);
    const placeholder = 'Select Type of Consent';

    const placeholderStyle = (style: string) => (inputValue === null ? `${style} placeholder` : style);

    const currentDate = new Date();
    const minDate = subYears(currentDate.setHours(0, 0, 0, 0), 21);
    const maxDate = new Date();
    const [dateError, setDateError] = useState<boolean>(false);
    const [dateHelperText, setDateHelperText] = useState<string>('');

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFilename, setSelectedFilename] = useState<string>('');
    const [consentFormId, setConsentFormId] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [minorConsentDate, setMinorConsentDate] = useState<Date | null>(null);

    const [selectedConsent, setSelectedConsent] = useState<CONSENT_TYPE>(CONSENT_TYPE.NOT_SELECTED);

    const handleChange = (consent: CONSENT_TYPE, minorConsentDate?: Date) => {
        onConsentTypeChange(familyMember, consent, consentFormId, minorConsentDate);
    };

    useEffect(() => {
        handleConsentChange();
    }, [minorConsentDate, selectedFile, consentFormId, selectedConsent]);

    useEffect(() => {
        setHasMinorDateError(dateError);
    }, [dateError]);

    const handleConsentChange = () => {
        if (familyMember.Consent) {
            return;
        }
        switch (selectedConsent) {
            case CONSENT_TYPE.ONECV_CONSENT:
            case CONSENT_TYPE.ONECV_MI_CONSENT:
                consentFormId ? handleChange(selectedConsent) : handleChange(CONSENT_TYPE.NOT_SELECTED);
                return;
            case CONSENT_TYPE.ONECV_MINOR_CONSENT:
                !dateError && minorConsentDate && consentFormId
                    ? handleChange(selectedConsent, minorConsentDate)
                    : handleChange(CONSENT_TYPE.NOT_SELECTED);
                return;
            case CONSENT_TYPE.SSNET_MINOR_CONSENT:
                !dateError && minorConsentDate
                    ? handleChange(selectedConsent, minorConsentDate)
                    : handleChange(CONSENT_TYPE.NOT_SELECTED);
                return;
            default:
                handleChange(selectedConsent);
        }
    };

    const [openTooltip, setOpenTooltip] = React.useState(false);
    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const [anchorMenu, setAnchorMenu] = React.useState<HTMLDivElement | null | undefined>(null);
    const menuRef = React.useRef<HTMLDivElement>();
    const open = Boolean(anchorMenu === menuRef.current);
    const handleClick = () => {
        handleSubMenuClose();
        setAnchorMenu(menuRef.current);
    };
    const handleClose = () => {
        setAnchorSubMenuOne(null);
        setAnchorSubMenuTwo(null);
        setAnchorMenu(null);
    };
    const [anchorSubMenuOne, setAnchorSubMenuOne] = React.useState<HTMLSpanElement | null | undefined>(null);
    const [anchorSubMenuTwo, setAnchorSubMenuTwo] = React.useState<HTMLSpanElement | null | undefined>(null);
    const subMenuRefOne = React.useRef<HTMLSpanElement>();
    const subMenuRefTwo = React.useRef<HTMLSpanElement>();
    const openSubOne = Boolean(anchorSubMenuOne === subMenuRefOne.current);
    const openSubTwo = Boolean(anchorSubMenuTwo === subMenuRefTwo.current);
    const handleSubMenuClick = (option: number) => {
        setOptionId(option);

        if (openSubOne || openSubTwo) {
            setAnchorSubMenuOne(null);
            setAnchorSubMenuTwo(null);
        }

        option === 0 ? setAnchorSubMenuOne(subMenuRefOne.current) : setAnchorSubMenuTwo(subMenuRefTwo.current);
    };
    const handleSubMenuClose = () => {
        setOptionId(null);
        setAnchorSubMenuOne(null);
        setAnchorSubMenuTwo(null);
    };

    const [optionId, setOptionId] = useState<number | null>(null);
    const options = [
        <>
            <span style={{fontWeight: 'bold'}}>{'OneCV (Omnibus)'}</span>
            {' consent given - Normal'}
        </>,
        <>
            <span style={{fontWeight: 'bold'}}>{'OneCV (Omnibus)'}</span>
            {' consent given - Minor'}
        </>,
        <>
            <span style={{fontWeight: 'bold'}}>{'OneCV (Omnibus)'}</span>
            {' consent given - Mentally incapacitated'}
        </>,

        <>
            {'Consent uploaded on '}
            <span style={{fontWeight: 'bold'}}>{'SSNet'}</span>
            {' - Normal'}
        </>,
        <>
            {'Consent uploaded on '}
            <span style={{fontWeight: 'bold'}}>{'SSNet'}</span>
            {' - Minor'}
        </>,
        <>
            {'Consent uploaded on '}
            <span style={{fontWeight: 'bold'}}>{'SSNet'}</span>
            {' - Mentally incapacitated'}
        </>,

        <>
            <span style={{fontWeight: 'bold'}}>{'Agency-specific'}</span>
            {' consent given only'}
        </>,
        <>
            {'Consent '}
            <span style={{fontWeight: 'bold'}}>{'has not been given'}</span>
        </>,
    ];

    const TooltipDialog = ({open, id}: {open: boolean; id: string}) => {
        return (
            <Dialog
                open={open}
                data-testid={id}
                onClose={handleTooltipClose}
                aria-labelledby="responsive-dialog-title"
                disableEnforceFocus
            >
                <DialogContent style={{paddingTop: '32px'}}>
                    <Typography variant="h2" styleName="tooltipHeader">
                        Types of Consent
                    </Typography>
                    <Box styleName="tooltipBox">
                        <Typography variant="subtitle2" styleName="tooltipSubtitle">
                            {COPIES.CONSENT_OMNIBUS}
                        </Typography>
                        <Typography variant="body2" className={styles.formSubtitle}>
                            Person has given consent using the{' '}
                            <Link to="/guides-and-toolkits" target="_blank" rel="noopener noreferrer">
                                Omnibus Consent Form
                            </Link>
                            {
                                ". You and other officers will be able to view the selected sub-domains(s) without having to seek consent again. This may be embedded in your agency's consent form. Please check with your Ops Unit Administrator."
                            }
                        </Typography>
                    </Box>
                    <Box styleName="tooltipBox">
                        <Typography variant="subtitle2" styleName="tooltipSubtitle">
                            {COPIES.CONSENT_SSNET}
                        </Typography>
                        <Typography variant="body2" className={styles.formSubtitle}>
                            {COPIES.CONSENT_SSNET_DESCRIPTION}
                        </Typography>
                    </Box>
                    <Box styleName="tooltipBox">
                        <Typography variant="subtitle2" styleName="tooltipSubtitle">
                            {COPIES.CONSENT_AGENCY}
                        </Typography>
                        <Typography variant="body2" className={styles.formSubtitle}>
                            {COPIES.CONSENT_AGENCY_DESCRIPTION}
                        </Typography>
                    </Box>
                    <Box styleName="tooltipBox">
                        <Typography variant="subtitle2" styleName="tooltipSubtitle">
                            {COPIES.CONSENT_NONE}
                        </Typography>
                        <Typography variant="body2" className={styles.formSubtitle}>
                            {COPIES.CONSENT_NONE_DESCRIPTION}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions style={{paddingBottom: '24px'}}>
                    <Button
                        data-testid="learnMore"
                        href="/guides-and-toolkits"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LEARN MORE
                    </Button>
                    <Button data-testid="close" onClick={handleTooltipClose}>
                        GOT IT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    const cascadingMenuItem = (menu: number) => (
        <MenuItem
            data-testid={menu === 0 ? 'optionMainOne' : 'optionMainTwo'}
            aria-haspopup="true"
            aria-describedby={menu === 0 ? 'consent-submenu-one' : 'consent-submenu-two'}
            onClick={() => {
                {
                    menu === 0 ? handleSubMenuClick(0) : handleSubMenuClick(1);
                }
            }}
            styleName="menuItem"
        >
            {menu === 0 ? (
                <Typography styleName="menuText">
                    <span style={{fontWeight: 'bold'}}>{'OneCV (Omnibus)'}</span>
                    {' consent given'}
                </Typography>
            ) : (
                <Typography styleName="menuText">
                    {'Consent uploaded on '}
                    <span style={{fontWeight: 'bold'}}>{'SSNet'}</span>
                    {' (SSNet users)'}
                </Typography>
            )}
            <ArrowRightIcon styleName="menuIcon" />
            <span
                ref={
                    menu === 0
                        ? (subMenuRefOne as React.RefObject<HTMLSpanElement>)
                        : (subMenuRefTwo as React.RefObject<HTMLSpanElement>)
                }
            />
        </MenuItem>
    );

    const resetAndCloseMenu = () => {
        setMinorConsentDate(null);
        resetFile();
        handleSubMenuClose();
        handleClose();
    };

    const setMenuOption = (consent: CONSENT_TYPE, input: number) => {
        setInputValue(input);
        setSelectedConsent(consent);
    };

    const subMenu = (option: number) => (
        <Popover
            data-testid="consentSubmenu"
            id={option === 0 ? 'consent-submenu-one' : 'consent-submenu-two'}
            anchorEl={option === 0 ? anchorSubMenuOne : anchorSubMenuTwo}
            open={option === 0 ? openSubOne : openSubTwo}
            onClose={handleSubMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={Fade}
            PaperProps={{
                style: {
                    width: '440px',
                    paddingTop: '8px',
                    paddingBottom: '10px',
                },
            }}
        >
            <MenuItem
                styleName="menuItem"
                onClick={() => {
                    if (option === 0) {
                        setMenuOption(CONSENT_TYPE.ONECV_CONSENT, 0);
                    } else {
                        setMenuOption(CONSENT_TYPE.SSNET_CONSENT, 3);
                    }
                    resetAndCloseMenu();
                }}
            >
                <Typography>{'Normal Consent'}</Typography>
            </MenuItem>
            <MenuItem
                styleName="menuItem"
                onClick={() => {
                    if (option === 0) {
                        setMenuOption(CONSENT_TYPE.ONECV_MINOR_CONSENT, 1);
                    } else {
                        setMenuOption(CONSENT_TYPE.SSNET_MINOR_CONSENT, 4);
                    }
                    resetAndCloseMenu();
                }}
            >
                <Typography>
                    {'Consent on '}
                    <span style={{fontWeight: 'bold'}}>{'behalf of minor'}</span>
                </Typography>
            </MenuItem>
            <MenuItem
                styleName="menuItem"
                value={CONSENT_TYPE.AGENCY_CONSENT}
                onClick={() => {
                    if (option === 0) {
                        setMenuOption(CONSENT_TYPE.ONECV_MI_CONSENT, 2);
                    } else {
                        setMenuOption(CONSENT_TYPE.SSNET_MI_CONSENT, 5);
                    }
                    resetAndCloseMenu();
                }}
            >
                <Typography>
                    {'Consent on '}
                    <span style={{fontWeight: 'bold'}}>{'behalf of mentally incapacitated'}</span>
                </Typography>
            </MenuItem>
        </Popover>
    );

    const resetFile = () => {
        const element = document.getElementById('file') as HTMLInputElement;
        if (element) element.value = '';
        setSelectedFile(null);
        setSelectedFilename('');
        setConsentFormId(undefined);
        setErrorMessage('');
    };

    const onDateInput = (date: string) => {
        setMinorConsentDate(new Date(date));
    };

    const isBetween = (targetDate: Date, minDate: Date, maxDate: Date) => {
        if (isBefore(targetDate, minDate) || isAfter(targetDate, maxDate)) {
            return false;
        }
        return true;
    };

    const onDateTextChange = (date: string) => {
        if (isBetween(new Date(date), minDate, maxDate)) {
            onDateInput(date);
            setDateError(false);
            setDateHelperText('');
        } else if (new Date(date) > maxDate) {
            setDateError(true);
            setDateHelperText('You cannot enter a future date');
        } else {
            setDateError(true);
            setDateHelperText('Minor must be younger than 21 years old');
        }
    };

    const renderConsentForm = () => (
        <FormControl component="fieldset" fullWidth data-testid="consentForm">
            <Grid container style={{rowGap: '8px'}}>
                <Grid container style={{marginBottom: '16px', rowGap: '8px', columnGap: '16px'}}>
                    <Grid item xs={5}>
                        <div styleName="flexContainer">
                            <Typography variant="body2" className={styles.formSubtitle}>
                                Type of Consent
                            </Typography>
                            <InfoIcon
                                data-testid="tooltipIcon"
                                styleName="tooltip"
                                onClick={() => setOpenTooltip(true)}
                            />
                            <TooltipDialog open={openTooltip} id={'tooltipDialog'} />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        {isUpload && (
                            <Typography variant="body2" className={styles.formSubtitle}>
                                Consent Form (For audit purposes)
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={5}>
                        <div ref={menuRef as React.RefObject<HTMLDivElement>}>
                            <Paper data-testid="consentSelectDropdown" style={{width: '100%'}}>
                                <Box
                                    styleName="selectBox"
                                    id="consent-select"
                                    data-testid="consentSelect"
                                    aria-controls={open ? 'consent-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-describedby={'consent-menu'}
                                    onClick={handleClick}
                                    style={{padding: '0px 16px'}}
                                >
                                    <Typography styleName={placeholderStyle('selectedText')}>
                                        {inputValue === null ? placeholder : options[inputValue]}
                                    </Typography>
                                    <ArrowDropDownIcon />
                                </Box>
                                <Popover
                                    id="consent-menu"
                                    data-testid="consentMenu"
                                    aria-labelledby="consent-select"
                                    anchorEl={anchorMenu}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    TransitionComponent={Fade}
                                    PaperProps={{
                                        style: {
                                            width: '467px',
                                            paddingTop: '8px',
                                            paddingBottom: '10px',
                                        },
                                    }}
                                >
                                    {cascadingMenuItem(0)}
                                    {cascadingMenuItem(1)}
                                    <MenuItem
                                        styleName="menuItem"
                                        onClick={() => {
                                            setInputValue(6);
                                            handleChange(CONSENT_TYPE.AGENCY_CONSENT);
                                            handleClose();
                                        }}
                                    >
                                        <Typography styleName="menuText">
                                            <span style={{fontWeight: 'bold'}}>{'Agency-specific'}</span>
                                            {' consent given only'}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        styleName="menuItem"
                                        onClick={() => {
                                            setInputValue(7);
                                            handleChange(CONSENT_TYPE.SKIP_CONSENT);
                                            handleClose();
                                        }}
                                    >
                                        <Typography styleName="menuText">
                                            {'Consent '}
                                            <span style={{fontWeight: 'bold'}}>{'has not been given'}</span>
                                        </Typography>
                                    </MenuItem>
                                    {optionId != null && subMenu(optionId)}
                                </Popover>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        {isUpload && (
                            <Box>
                                <UploadConsent
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    selectedFilename={selectedFilename}
                                    setSelectedFilename={setSelectedFilename}
                                    resetFile={resetFile}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    setFileId={setConsentFormId}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {(inputValue === 1 || inputValue === 4) && (
                    <Grid container direction="row" style={{marginBottom: '16px', rowGap: '8px', columnGap: '16px'}}>
                        <Grid item xs={12}>
                            <div styleName="flexContainer">
                                <Typography data-testid="dateInputText" variant="body2" className={styles.formSubtitle}>
                                    Minor&apos;s date of birth
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput
                                data-testid="dateInput"
                                value={minorConsentDate}
                                onChange={(date) => {
                                    onDateTextChange(date as string);
                                }}
                                dateFormat="dd/MM/yyyy"
                                minDate={addDays(minDate, 1)}
                                maxDate={maxDate}
                                error={dateError}
                                helperText={dateHelperText}
                                hasError={setDateError}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </FormControl>
    );

    const renderConsentGivenIndication = () => (
        <>
            <Grid container data-testid="consentGiven">
                <CheckCircleIcon color="primary" className={styles.consentTrueIcon} />
                <Typography className={styles.consentGiven}>
                    {familyMember.Consent &&
                        consentClause(familyMember.Consent?.Agency, familyMember.Consent?.ConsentType)}
                    {familyMember.Consent?.CreatedAt &&
                        ` on ${formatDate(new Date(familyMember.Consent?.CreatedAt), false, true)}.`}
                </Typography>
            </Grid>
        </>
    );

    return (
        <Box component="div" className={styles.consent}>
            {items.length > 0 ? (
                <>
                    <Paper className={styles.consentForm} square>
                        <Divider className={styles.divider} />
                        <Typography variant="h5" color="primary" className={styles.formTitle}>
                            {familyMember.Name}
                        </Typography>
                        <Typography color="primary" className={styles.consentSubtitle}>
                            {familyMember.Relationship}
                        </Typography>
                        {familyMember.Consent ? renderConsentGivenIndication() : renderConsentForm()}
                    </Paper>
                </>
            ) : (
                <Typography variant="h6" className={styles.noItem}>
                    Sub-Domains Removed.
                </Typography>
            )}
        </Box>
    );
};
