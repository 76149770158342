import {Grid} from '@mui/material';
import React from 'react';
import style from './ListHeader.scss';

interface IListHeader {
    style?: React.CSSProperties;
    header: string;
}

export const ListHeader = (param: IListHeader) => {
    return (
        <Grid className={`${style.header} ${style.cell}`} item xs={12} style={param.style}>
            {param.header}
        </Grid>
    );
};
