import {ISystemAdminResponse, IUser} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getSystemAdmins = async (): Promise<IUser[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/system-admins`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createSystemAdmin = async (systemAdminDetails: IUser): Promise<ISystemAdminResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/system-admins`,
        data: JSON.stringify(systemAdminDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const updateSystemAdmin = async (systemAdminDetails: IUser) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/system-admins/${systemAdminDetails.Id}`,
        data: JSON.stringify(systemAdminDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const activateSystemAdmin = async (userId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/system-admins/${userId}/reactivate`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const deleteSystemAdmin = async (userId: number, deletedReason: string) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/system-admins/${userId}`,
        data: JSON.stringify({DeletedReason: deletedReason}),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};
