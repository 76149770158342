import React, {useState} from 'react';
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Radio,
    Snackbar,
} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {OPS_UNIT_TYPE} from '~/constants';
import {IOpsUnit} from '~/interfaces/admin';
import {updateOpsUnitType} from '~/services/dataAccessMatrixService';
import './OpsUnitTypeEditModal.scss';
import {useGrowl} from '~/utils/hooks/useGrowl';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {opsUnitActions} from '~/store/opsUnit/opsUnit.slice';

export interface IOpsUnitTypeEditModalDialog {
    open: boolean;
    onClose: () => void;
    currentOpsUnitType: OPS_UNIT_TYPE;
    opsUnit: IOpsUnit;
}

export const OpsUnitTypeEditModal = ({open, onClose, currentOpsUnitType, opsUnit}: IOpsUnitTypeEditModalDialog) => {
    const dispatch = useAppDispatch();
    const [opsUnitType, setOpsUnitType] = useState<OPS_UNIT_TYPE>(currentOpsUnitType);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleClose = () => {
        setOpsUnitType(currentOpsUnitType);
        onClose();
    };

    const handleSave = async () => {
        try {
            await updateOpsUnitType(String(opsUnit.Id), opsUnitType);
            openGrowl(`Ops Unit updated to ${opsUnitType} successfully!`);
            dispatch(opsUnitActions.UPDATE_DAM_OPS_UNIT({...opsUnit, OpsUnitType: opsUnitType}));
            onClose();
        } catch (e) {
            const error = e as Error;
            openGrowl(error.message);
        }
    };

    return (
        <>
            {' '}
            <Dialog open={open} aria-describedby="alert-dialog-description">
                <Grid container>
                    <Grid item xs={11}>
                        <DialogTitle>Select Ops Unit Type</DialogTitle>
                    </Grid>
                    <Grid item xs={1}>
                        <DialogActions>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                    </Grid>

                    <Grid item xs={12}>
                        <DialogContent>
                            <DialogContentText>
                                Please select the user type this Ops Unit falls under. NGE users should not have access
                                to family members information.
                            </DialogContentText>
                            <div styleName="radioGroup">
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.PSA)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.PSA} color="primary" />
                                    PSA
                                </label>
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.NGE_BIP)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.NGE_BIP} color="primary" />
                                    NGE (BIP)
                                </label>
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.NGE_NON_BIP)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.NGE_NON_BIP} color="primary" />
                                    NGE (Non-BIP)
                                </label>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="text">
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={currentOpsUnitType === opsUnitType}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};
