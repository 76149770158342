import {createSlice} from '@reduxjs/toolkit';
import {IDataSource} from '~/interfaces/admin';
import {setDataSource, createDataSource, editDataSource, deleteDataSource} from './dataSource.thunk';

export const initialDataSourcesState: IDataSource[] = [];

export const dataSourcesSlice = createSlice({
    name: 'dataSources',
    initialState: initialDataSourcesState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDataSource.fulfilled, (_, action) => {
            if (action.payload) {
                return action.payload;
            }
        });
        builder.addCase(createDataSource.fulfilled, (state, action) => {
            if (action.payload) {
                state.push(action.payload);
            }
        });
        builder.addCase(editDataSource.fulfilled, (state, action) => {
            if (action.payload) {
                return state.map((dataSource) => (dataSource.Id == action.payload.Id ? action.payload : dataSource));
            }
        });
        builder.addCase(deleteDataSource.fulfilled, (state, action) => {
            if (action.payload) {
                return state.filter((dataSource) => dataSource.Id !== action.payload);
            }
        });
    },
});

export const dataSourcesActions = dataSourcesSlice.actions;
export default dataSourcesSlice.reducer;
