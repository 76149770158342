import React, {useState, useEffect} from 'react';
import {Grid, Typography, IconButton, LinearProgress, CircularProgress} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import './UploadProgressBox.scss';

interface IUploadProgressBoxProps {
    filename: string;
    time: string;
    progress: number;
    onClickCancelHandler: React.MouseEventHandler<HTMLButtonElement>;
}

export const UploadProgressBox = ({filename, time, progress = 0, onClickCancelHandler}: IUploadProgressBoxProps) => {
    const [uploadingInProgress, setUploadingInProgress] = useState<boolean>(progress < 100);

    useEffect(() => {
        const timer = setInterval(() => {
            if (progress >= 100) {
                setUploadingInProgress(false);
            }
        }, 500);
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    return (
        <>
            <Grid container styleName="boxWrapper">
                <Grid item styleName="fileIconWrapper">
                    <DescriptionIcon styleName="fileIcon" />
                </Grid>
                {uploadingInProgress ? (
                    <React.Fragment>
                        <Grid item styleName={'uploadInProgressWrapper'}>
                            <Grid container styleName="textWrapper">
                                <Typography styleName="filename">{filename}</Typography>
                                <Typography styleName={'progressText'} data-testid="progress-text">
                                    {progress}%
                                </Typography>
                            </Grid>
                            <LinearProgress styleName="progressBar" variant="determinate" value={progress} />
                        </Grid>
                        <Grid item styleName="cancelIconWrapper">
                            <IconButton onClick={onClickCancelHandler} disabled={!uploadingInProgress}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item styleName={'uploadDoneWrapper'}>
                            <Typography styleName="filename">{filename}</Typography>
                            <Typography styleName={'timeText'} data-testid="time-text">
                                {time}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container styleName="processingWrapper">
                                <Typography styleName="processingText" data-testid="processing-text">
                                    Processing file...
                                </Typography>
                                <CircularProgress size={27} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </>
    );
};
