import {config} from '~/config';
import {httpRequest} from './middleware';
import fileDownload from 'js-file-download';
import {IReportRequest} from '~/pages/Admin/Reports/Report';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getReports = async (reportRequest: IReportRequest) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/reports`,
        data: JSON.stringify(reportRequest),
        headers: {
            'Content-Type': 'application/zip',
            Accept: 'application/zip',
        },
        method: 'POST',
        responseType: 'blob',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    const fileData = response.data;
    fileDownload(fileData, `OneCV_Reports.zip`);
};
