import React from 'react';
import {Divider, Grid, Typography} from '@mui/material';
import styles from './PerCapitaHouseholdIncome.scss';
import {CollapsibleCard} from '~/components/Common/Cards/CollapsibleCard';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {format, getTime} from 'date-fns';
import {
    allNoAccess,
    extractAllValues,
    isNoAccess,
    isNoResults,
    NotAuthorizedMessage,
    allNoResults,
} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {IconMessage, ICON_TYPE} from '~/components/Common/IconMessage/IconMessage';
import {FIELD_VALUES} from '~/constants';

export type IPerCapitaHouseholdIncomeData = IPerCapitaHouseholdIncome;

export interface IPerCapitaHouseholdIncome {
    PerCapitaHouseholdIncomeItems: IPerCapitaHouseholdIncomeItem[];
    NumberOfNonshareableRecords: number;
}

interface IPerCapitaHouseholdIncomeItem {
    BandRange: string;
    MTDate: string;
    MeansTestedAs: string;
    MTCIndicator: string;
    Address: string;
    HouseholdMembers: IHouseholdMember[];
}

interface IHouseholdMember {
    Id?: number;
    Name: string;
    UIN: string;
    DateOfIncomePeriod: string;
}

const parseMTDate = (date: string) =>
    isNoAccess(date) || isNoResults(date) ? date : format(new Date(date), 'dd MMMM yyyy');
const CompulsorySection = (income: IPerCapitaHouseholdIncomeItem) => {
    const {MTDate, BandRange, MeansTestedAs} = income;
    const fields = [
        {
            label: 'Date of Assessment',
            value: parseMTDate(MTDate),
        },
        {label: 'Per-Capita Household Income Band', value: BandRange},
        {label: 'Means Tested As', value: MeansTestedAs},
    ];

    return (
        <Grid container spacing={2} style={{paddingTop: '10px'}}>
            <SimpleGridDisplay fields={fields} />
        </Grid>
    );
};

const hasAccessToHouseholdMembers = (HouseholdMembers: IHouseholdMember[]) =>
    !allNoAccess(HouseholdMembers && HouseholdMembers.length > 0 ? extractAllValues(HouseholdMembers[0]) : []);

const shouldRenderMainSection = (income: IPerCapitaHouseholdIncomeItem) =>
    hasAccessToHouseholdMembers(income.HouseholdMembers) || !allNoAccess([income.Address, income.MTCIndicator]);

export const MainSection = ({income}: {income: IPerCapitaHouseholdIncomeItem}) => {
    const {Address, MTCIndicator, HouseholdMembers} = income;

    const fields = [
        {label: 'Household Address', value: Address},
        {label: 'Indicator of Means Test Computation Deviation', value: MTCIndicator},
    ];
    const name = (member: IHouseholdMember) => member.Name;
    const nric = (member: IHouseholdMember) => member.UIN;
    const dateOfIncomePeriod = (member: IHouseholdMember) =>
        member.DateOfIncomePeriod == FIELD_VALUES.NO_RESULTS || member.DateOfIncomePeriod == FIELD_VALUES.NO_ACCESS
            ? member.DateOfIncomePeriod
            : `${member.DateOfIncomePeriod.substring(0, 8)} to ${member.DateOfIncomePeriod.substring(
                  member.DateOfIncomePeriod.length - 8,
                  member.DateOfIncomePeriod.length,
              )}`;

    return (
        <Grid container spacing={2}>
            <Grid data-testid="divider" item xs={12}>
                <Divider
                    style={{
                        marginTop: 24,
                        marginBottom: 10,
                    }}
                />
            </Grid>
            <SimpleGridDisplay data-testid="mainSectionFields" fields={fields} />
            {hasAccessToHouseholdMembers(HouseholdMembers) && (
                <>
                    <Grid data-testid="householdSection" item xs={12}>
                        <Typography data-testid="title_household_members" variant="h6">
                            Household Members
                        </Typography>
                    </Grid>
                    <Grid data-testid="householdMembers" item xs={12} id={styles.table}>
                        <div id={styles.table}>
                            <ListModel
                                columns={[name, nric, dateOfIncomePeriod]}
                                headers={['Name of Member', 'NRIC of Family', 'Date of Income Period']}
                                modelList={HouseholdMembers}
                                modelComparator={[() => 0, () => 0, () => 0]}
                                variant="expanded"
                                numbering={true}
                            />
                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export const PerCapitaHouseholdIncome: React.FunctionComponent<{data: IPerCapitaHouseholdIncomeData}> = ({data}) => {
    const hasRecordsToDisplay = !allNoResults(extractAllValues(data.PerCapitaHouseholdIncomeItems));

    if (data.NumberOfNonshareableRecords == 0 && !hasRecordsToDisplay) {
        return <NoData />;
    }

    const sortedPCHIs = [...data.PerCapitaHouseholdIncomeItems].sort((pchiA, pchiB) =>
        isNoResults(pchiA.MTDate) ? getTime(new Date(pchiB.MTDate)) - getTime(new Date(pchiA.MTDate)) : -1,
    );

    return (
        <>
            {sortedPCHIs.length > 0 && (
                <IconMessage
                    data-testid="past2YearMessage"
                    style={{backgroundColor: '#E8EAF6', marginBottom: '24px'}}
                    type={ICON_TYPE.BLUE_INFO}
                    message="Records displayed are from the last 2 years."
                />
            )}
            {data.NumberOfNonshareableRecords > 0 && (
                <NotAuthorizedMessage data-testid="notAuthorizedMessage" hasRecordsToDisplay={hasRecordsToDisplay} />
            )}
            {sortedPCHIs.map((income: IPerCapitaHouseholdIncomeItem, key: number) => (
                // eslint-disable-next-line react/jsx-key
                <CollapsibleCard compulsorySection={CompulsorySection(income)} data-testid={`pchi_item_${key}`}>
                    {shouldRenderMainSection(income) && <MainSection income={income} data-testid="mainSection" />}
                </CollapsibleCard>
            ))}
        </>
    );
};
