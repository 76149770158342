import {some} from 'lodash';

export const validateChecksum = (query: string) => {
    if (query.length != 9) return false;

    const series = query[0];
    const inputCheckLetter = query[8];
    const serial = query
        .substring(1, 8)
        .split('')
        .map((char) => parseInt(char));
    const ST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
    const FG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    const M = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X'];
    const multipliers = [2, 7, 6, 5, 4, 3, 2];

    if (some(serial, isNaN)) return false;
    if (!['S', 'T', 'F', 'G', 'M'].includes(series)) return false;

    let sum = serial.reduce((acc, curr, i) => acc + curr * multipliers[i], 0);
    if (series === 'T' || series === 'G') {
        sum += 4;
    } else if (series === 'M') {
        sum += 3;
    }

    const remainder = sum % 11;

    let algoCheckLetter = '';
    if (series === 'S' || series === 'T') {
        algoCheckLetter = ST[remainder];
    } else if (series === 'F' || series === 'G') {
        algoCheckLetter = FG[remainder];
    } else if (series === 'M') {
        // Docs state 11 - remainder, but their index for array starts from 1, not 0
        algoCheckLetter = M[10 - remainder];
    }
    return inputCheckLetter === algoCheckLetter;
};
