import {createSlice} from '@reduxjs/toolkit';
import {omit} from 'lodash';
import {IUser} from '~/interfaces/admin';
import {setUsers, setUsersFromOpsUnit, createUser, updateUser, deleteUser, activateUser} from './user.thunk';

export const initialUserState: {[userId: number]: IUser} = {};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setUsers.fulfilled, (_, action) => {
                if (action.payload) {
                    return action.payload.usersObject;
                }
            })
            .addCase(setUsersFromOpsUnit.fulfilled, (_, action) => {
                if (action.payload) {
                    return action.payload.usersObject;
                }
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state[action.payload.userDetails.Id as number] = action.payload.userDetails;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.Id as number] = action.payload;
                }
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                if (action.payload) {
                    return omit(state, action.payload);
                }
            })
            .addCase(activateUser.fulfilled, (state, action) => {
                state[action.payload.Id as number] = action.payload;
            });
    },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
