import React from 'react';
import {
    getAssistanceType,
    IBenefitInfo,
    IComcare2BenefitInfo,
    TAssistanceDataType,
    TAssistanceType,
} from './interfaces';
import {isEmpty, orderBy} from 'lodash';
import {Grid, Typography} from '@mui/material';
import {SubDomainStatus} from '~/interfaces/client';
import {
    EnhancedTableExpandableRow,
    ICustomHeaderColumn,
} from '~/components/Common/Table/EnhancedTable/EnhancedTableExpandableRow';
import {ActiveIntervention, IActiveInterventionProps} from './ActiveIntervention';
import {ClosedIntervention} from './ClosedIntervention';
import {MissingIntervention} from './MissingIntervention';
import {renderTextByStatus} from '../SubDomainDisplay';
import {NoData} from '../CompletedSubDomain';
import {formatDateField, isNotEmpty, objNoAccess, objNoResults} from '~/utils/contentUtils';
import {FIELD_VALUES} from '~/constants';
import './ComcareAssistanceTable.scss';

export const CASE_STATUS_APPROVED = 'Approved';
export const CASE_STATUS_ACTIVE = 'Active';
export const CASE_STATUS_CLOSED = 'Closed';
export const CASE_STATUS_OPEN = 'Open';
export const DEFAULT_MISSING_DATE_VALUE = ''; // Ben requested for missing fields to be empty

export const interventionDetails = (intervention: TAssistanceDataType): React.ReactElement => {
    const type: TAssistanceType = 'AssistanceType' in intervention ? 'comcare' : 'comcare2';
    const props: IActiveInterventionProps<IBenefitInfo | IComcare2BenefitInfo> = {
        type: type,
        rows: intervention.BenefitInfo as IBenefitInfo[] | IComcare2BenefitInfo[],
    };

    return (
        <>
            {intervention.BenefitInfo &&
                !objNoResults(intervention.BenefitInfo) &&
                !objNoAccess(intervention.BenefitInfo) && (
                    <ActiveIntervention data-testid="interventionDetailsActive" {...props} />
                )}

            {!objNoResults(intervention.CaseClosureReason) && !objNoAccess(intervention.CaseClosureReason) && (
                <ClosedIntervention
                    data-testid="interventionDetailsClosed"
                    CaseClosedDate={
                        'CaseClosedDate' in intervention
                            ? formatDateField(intervention.CaseClosedDate, false, true)
                            : 'NA'
                    }
                    CaseClosureReason={intervention.CaseClosureReason}
                />
            )}

            {intervention.BenefitInfo &&
                objNoResults(intervention.BenefitInfo) &&
                objNoResults(intervention.CaseClosureReason) && <MissingIntervention />}
        </>
    );
};

export enum StatusOrder {
    'active' = 1,
    'open' = 2,
    'submitted' = 3,
    'submitted for closure' = 4,
    'suspended' = 5,
    'closed' = 6,
}

export type StatusOrderType = 'open' | 'submitted' | 'active' | 'suspended' | 'submitted for closure' | 'closed';
interface IRowData<T> {
    id: number;
    model: T;
    expandableContent?: React.ReactElement;
    assistancePeriod: string | number;
    ssoLocation: string;
    assistanceType: string;
    status: number;
}

export const dataWithExpandableContent = <T extends TAssistanceDataType>(data: T[]) => {
    const parsedData = data.map((intervention: T): IRowData<T> => {
        return {
            id: intervention.Id,
            model: intervention,
            assistancePeriod:
                isNotEmpty(intervention.AssistanceEarliestStartDate) && isNotEmpty(intervention.AssistanceLatestEndDate)
                    ? new Date(intervention.AssistanceEarliestStartDate).getTime() * -1
                    : 0,
            ssoLocation: intervention.CaseLocationName,
            assistanceType: getAssistanceType(intervention),
            status: StatusOrder[intervention.CaseStatus.toLowerCase() as StatusOrderType],
            expandableContent: interventionDetails(intervention),
        };
    });

    // Construct a 2 dimensional ordered array by ordering with assistance period, follow by status
    const sortedByDate = orderBy(parsedData, 'assistancePeriod', 'asc');
    const twoDimensionalOrderedData = orderBy(sortedByDate, 'status', 'asc');
    return twoDimensionalOrderedData;
};

export const getAssistancePeriod = (startDate: string, endDate: string | undefined) => {
    const hasEndDate = endDate && endDate !== '' && endDate !== FIELD_VALUES.NO_RESULTS;

    return `${formatDateField(startDate, false, true)}${
        endDate && hasEndDate ? ` to ${formatDateField(endDate, false, true)}` : ''
    }`;
};

export interface IComcareAssistanceTableProps<T> {
    data: T[] | undefined;
    type: TAssistanceType;
    'data-testid'?: string;
}

export const ComcareAssistanceTable = <T extends TAssistanceDataType>({
    data,
    type,
    'data-testid': dataTestId,
}: IComcareAssistanceTableProps<T>) => {
    type IHeadRowData = Pick<IRowData<T>, 'assistancePeriod' | 'ssoLocation' | 'assistanceType' | 'status'>;

    const assistancePeriodColumnComp = (model: T) => (
        <Grid>
            <Typography variant="body1">
                {getAssistancePeriod(model.AssistanceEarliestStartDate, model.AssistanceLatestEndDate)}
            </Typography>
            <Typography styleName="fieldName">{model.CaseReferenceNo}</Typography>
        </Grid>
    );

    const ssoLocationColumnComp = (model: T) => (
        <Grid>
            <Typography variant="body1">{model.CaseLocationName}</Typography>
            <Typography styleName="fieldName">{isEmpty(model.CaseWorkerName) ? 'NA' : model.CaseWorkerName}</Typography>
        </Grid>
    );

    const assistanceTypeColumnComp = (model: T) => (
        <Grid>
            <Typography variant="body1">{getAssistanceType(model)}</Typography>
            <Typography styleName="fieldName">{model.Indicator}</Typography>
        </Grid>
    );

    const statusColumnComp = (model: T) => (
        <Grid>
            <Typography variant="body1">
                {model.CaseStatus === CASE_STATUS_ACTIVE || model.CaseStatus === CASE_STATUS_APPROVED
                    ? renderTextByStatus(model.CaseStatus, SubDomainStatus.COMPLETED)
                    : model.CaseStatus !== CASE_STATUS_CLOSED
                    ? renderTextByStatus(model.CaseStatus, SubDomainStatus.PENDING)
                    : model.CaseStatus}
            </Typography>
            <Typography styleName="fieldName">
                {
                    // Requested by business to include definition for both active and open cases for clarity
                    model.CaseStatus === CASE_STATUS_ACTIVE
                        ? 'Approved'
                        : model.CaseStatus === CASE_STATUS_OPEN
                        ? 'Pending review'
                        : ''
                }
            </Typography>
        </Grid>
    );

    const headColumns: ICustomHeaderColumn<T, keyof IHeadRowData>[] = [
        {
            id: 'assistancePeriod',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Assistance Period</Typography>
                    <Typography variant="caption"> Case Reference Number </Typography>
                </Grid>
            ),
            column: assistancePeriodColumnComp,
        },
        {
            id: 'ssoLocation',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1"> {type === 'comcare' ? 'SSO Location' : 'Location'} </Typography>
                    <Typography variant="caption"> Caseworker Name </Typography>
                </Grid>
            ),
            column: ssoLocationColumnComp,
        },
        {
            id: 'assistanceType',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1"> Assistance Type </Typography>
                    <Typography variant="caption"> Primary Client / Beneficiary </Typography>
                </Grid>
            ),
            column: assistanceTypeColumnComp,
        },
        {
            id: 'status',
            align: 'inherit',
            disablePadding: false,
            label: <Typography variant="body1">Status</Typography>,
            column: statusColumnComp,
        },
    ];

    const filteredData = data?.filter((row) => !objNoAccess(row) && !objNoResults(row));

    return filteredData && !objNoResults(filteredData) ? (
        <div styleName="table" data-testid={`${dataTestId}Body`}>
            {EnhancedTableExpandableRow<IHeadRowData, IRowData<T>, T>(
                headColumns,
                dataWithExpandableContent(filteredData),
                true,
                false,
                'status',
            )}
        </div>
    ) : (
        <NoData />
    );
};
