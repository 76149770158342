export function jwtDecode(token: string) {
    try {
        // Extract body (encoded using base64Url) from jwt token
        const base64Url = token.split('.')[1];

        // Convert to base64 as `atob()` works only on base64
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Convert to percent encoded utf8-string to properly handle utf-8 strings
        // Needed as `atob()` returns a string where each character is a single byte
        const utf8String = atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('');

        // Decode percent encoded utf-8 string into a normal string properly
        const jsonPayload = decodeURIComponent(utf8String);

        return JSON.parse(jsonPayload);
    } catch (e) {
        throw new Error(`Error decoding jwt token ${token} - ${e}`);
    }
}
