import {makeStyles} from '@mui/styles';

export const useReleaseFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});
