import React from 'react';
import {LinearProgress} from '@mui/material';
import {withStyles} from '@mui/styles';

interface IExpiryIndicatorProps {
    dayLeftToExpire: number;
}

export enum COLORS {
    YELLOW = '#d47500',
    GREEN = '#007b4f',
    RED = '#b50000',
    GREY = '#efefef',
}

export const ExpiryIndicator = ({dayLeftToExpire}: IExpiryIndicatorProps) => {
    const progress = Math.ceil((dayLeftToExpire / 30) * 100);
    let color = COLORS.YELLOW; // Yellow
    if (progress > 69) {
        color = COLORS.GREEN; // Green
    } else if (progress < 35) {
        color = COLORS.RED; // Red
    }
    const getDisplayText = () => (dayLeftToExpire > 1 ? `${dayLeftToExpire} days left` : 'Expiring tomorrow');
    const BorderLinearProgress = withStyles({
        root: {
            borderRadius: 16,
            height: 8,
            backgroundColor: COLORS.GREY,
        },
        bar: {
            backgroundColor: color,
        },
    })(LinearProgress);

    return progress > 0 ? (
        <>
            <BorderLinearProgress variant="determinate" value={progress} />
            <span style={{color}}>{getDisplayText()}</span>
        </>
    ) : (
        <span>Not Applicable</span>
    );
};
