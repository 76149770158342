import React, {useState} from 'react';
import {formatCurrency} from '~/utils/currencyUtils';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {Grid, Tooltip, TooltipProps, styled, tooltipClasses} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {allNoResults, extractAllValues} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {SubSectionStatusWrapper} from '../SubSectionStatusWrapper';
import {SubDomainStatus} from '~/interfaces/client';
import {Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface ICareshieldEldershield {
    CslEshClaimsApiDetails: CslEshApiDetails;
}
export interface CslEshApiDetails {
    InsurerCodeList: string;
    SupplementPlanTag: string;
    Claims: ICslEshClaim[];
}
export interface ICslEshClaim {
    LatestClaimEligibilityStartMonth: string;
    LatestTransactionMonthAmount: string;
    CoverPlanType: string;
    MonthlyPayoutQuantumAmount: string;
    CoverStatus: string;
    TotalNumberOfPayouts: string;
    LatestTransactionMonth: string;
    ClaimTag: string;
}
export const CareshieldEldershield: React.FunctionComponent<{
    data: ICareshieldEldershield;
    errorMessages?: string[];
}> = ({data, errorMessages}) => {
    const {CslEshClaimsApiDetails} = data;
    // errorMessages = ["Unsuccessful"]
    const allFields = extractAllValues(CslEshClaimsApiDetails);

    const insurerCodeList = (code: string) => {
        return {'': '-', AVI: 'Singlife', GEL: 'Great Eastern', NTU: 'Income'}[code] ?? code;
    };
    const formatSchemeType = (code: string) => {
        if (code === '') {
            return '-';
        }

        const value =
            {ESH300: 'ElderShield', ESH400: 'ElderShield', CSL: 'CareShield Life', '(No results)': '-'}[code] ?? code;
        return value ? <Typography variant="h6">{value}</Typography> : '-';
    };

    const formatCoverPlanType = (code: string) => {
        return {
            '': 'N.A',
            ESH300: 'ElderShield300',
            ESH400: 'ElderShield400',
            CSL: 'N.A',
        }[code];
    };
    const formatClaimStatus = (activeField: string) =>
        activeField === 'Y' ? 'Yes' : activeField === 'N' ? 'No' : activeField;

    const formatCoverStatus = (coverPlanType: string, code: string): string => {
        if (coverPlanType === 'CSL') {
            return code === 'A' ? 'Active' : 'Inactive';
        }

        const description = {
            A: 'Active',
            APU: 'Active Paid Up',
            AFP: 'Active Fully Paid',
            APT: 'Active Pending Termination',
            APC: 'Active Pending Conversion',
            PR: 'Pending Renewal',
            PE: 'Pending Reinstatement',
            W: 'Waiting',
            C: 'Cancelled',
            T: 'Terminated',
            N: 'No Cover',
        }[code];

        return description ?? code;
    };

    const formatAmount = (amount: string) =>
        Number.isNaN(parseFloat(amount)) ? '-' : formatCurrency(parseFloat(amount), 2, 'currency');
    const parseYNField = (ynField: string) => (ynField === 'Y' ? 'Yes' : ynField === 'N' ? 'No' : ynField);

    const capitaliseText = (text: string) => {
        if (text == '') {
            return '-';
        }

        const splitted = text.toLowerCase().split(' ');
        return splitted.map((e) => e.charAt(0).toUpperCase() + e.substring(1)).join(' ');
    };

    const formatInsurerCodeList = (codeList: string) => {
        if (codeList.length === 0) {
            return '-';
        }

        return codeList
            .split(',')
            .map((e) => insurerCodeList(e))
            .join(' / ');
    };

    const claim: ICslEshClaim =
        CslEshClaimsApiDetails.Claims.length > 0
            ? CslEshClaimsApiDetails.Claims[0]
            : {
                  LatestClaimEligibilityStartMonth: '',
                  LatestTransactionMonthAmount: '',
                  CoverPlanType: '',
                  MonthlyPayoutQuantumAmount: '',
                  CoverStatus: '',
                  TotalNumberOfPayouts: '',
                  LatestTransactionMonth: '',
                  ClaimTag: '',
              };

    // No Cover
    // claim = {
    //     LatestClaimEligibilityStartMonth: "",
    //     LatestTransactionMonthAmount: "0",
    //     CoverPlanType: "ESH300",
    //     MonthlyPayoutQuantumAmount: "0",
    //     CoverStatus: "N",
    //     TotalNumberOfPayouts: "0",
    //     LatestTransactionMonth: "",
    //     ClaimTag: ""
    // }

    function renderNormal() {
        return (
            <div id="normal">
                <Grid container spacing={3} data-testid="csleshAPI">
                    <SimpleGridDisplay
                        fields={[
                            {label: 'Scheme Type', value: formatSchemeType(claim.CoverPlanType)},
                            {label: '', value: ''},
                            {label: 'Cover Status', value: formatCoverStatus(claim.CoverPlanType, claim.CoverStatus)},
                            {label: 'Cover Plan Type', value: formatCoverPlanType(claim.CoverPlanType)},
                            {label: 'Claim Status', value: formatClaimStatus(claim.ClaimTag)},
                            {label: '', value: ''},
                        ]}
                    />
                </Grid>

                <div style={{marginTop: 24}}>
                    <SubSectionStatusWrapper name="Scheme Details" status={SubDomainStatus.COMPLETED}>
                        <Grid container spacing={3} data-testid="csleshAPISchemeDetails">
                            <SimpleGridDisplay
                                fields={[
                                    {
                                        label: 'Latest Claim Eligibility Start Month',
                                        value: capitaliseText(claim.LatestClaimEligibilityStartMonth),
                                    },
                                    {
                                        label: 'Monthly Payout Quantum Amount',
                                        value: formatAmount(claim.MonthlyPayoutQuantumAmount),
                                    },
                                    {
                                        label: 'Latest Transaction Month',
                                        value: capitaliseText(claim.LatestTransactionMonth),
                                    },
                                    {
                                        label: 'Latest Transaciton Month Amount',
                                        value: formatAmount(claim.LatestTransactionMonthAmount),
                                    },
                                    {
                                        label: 'Total Number of Payouts',
                                        value: claim.TotalNumberOfPayouts == '' ? '0' : claim.TotalNumberOfPayouts,
                                    },
                                    {
                                        label: 'Supplement Plan(s) Available',
                                        value: parseYNField(CslEshClaimsApiDetails.SupplementPlanTag),
                                    },
                                    {
                                        label: 'Insurer(s)',
                                        value: formatInsurerCodeList(CslEshClaimsApiDetails.InsurerCodeList),
                                    },
                                ]}
                            />
                        </Grid>
                    </SubSectionStatusWrapper>
                </div>
            </div>
        );
    }

    function renderNoCoverStatus() {
        return (
            <>
                <Grid container spacing={3} data-testid="csleshAPI">
                    <SimpleGridDisplay
                        fields={[
                            {label: 'Scheme Type', value: formatSchemeType(claim.CoverPlanType)},
                            {label: '', value: ''},
                            {
                                label: (
                                    <div>
                                        Cover Status{' '}
                                        {renderTooltip(
                                            tooltipMessage(formatCoverStatus(claim.CoverPlanType, claim.CoverStatus)),
                                        )}
                                    </div>
                                ),
                                value: <div>{formatCoverStatus(claim.CoverPlanType, claim.CoverStatus)}</div>,
                            },
                        ]}
                    />
                </Grid>
            </>
        );
    }

    function tooltipMessage(coverStatus: string): React.ReactElement {
        if (coverStatus === 'Inactive') {
            return <>Policy is either cancelled, terminated, or waiting for commencement.</>;
        }

        return (
            <>
                For ElderShield, member is not covered.
                <br /> <br />
                For CareShield Life, member&apos;s policy is either cancelled, terminated, not covered, or waiting for
                commencement.
            </>
        );
    }

    const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}} />
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#E0EDFD',
            color: '#000',
            maxWidth: 272,
            fontSize: theme.typography.pxToRem(16),
            boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);',
        },
    }));

    function renderTooltip(element: React.ReactElement) {
        const [tooltipOpen, setTooltipOpen] = useState(false);

        const handleTooltipOpen = () => {
            setTooltipOpen(true);
        };

        const handleTooltipClose = () => {
            setTooltipOpen(false);
        };

        return (
            <HtmlTooltip
                title={
                    <div style={{padding: 16, paddingRight: 50}}>
                        <Typography variant="body1">{element}</Typography>

                        <IconButton
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                top: '14px',
                                right: '16px',
                            }}
                            onClick={handleTooltipClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                }
                placement="right-start"
                open={tooltipOpen}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <IconButton onClick={handleTooltipOpen} size={'small'}>
                    <InfoIcon />
                </IconButton>
            </HtmlTooltip>
        );
    }

    function render(allFields: string[], errorMessages: string[] | undefined, coverStatus: string): React.ReactElement {
        // All fields are = No results
        if (allNoResults(allFields)) {
            // Check if theres any error message if not display the default
            let noDataMessage = null;
            if (errorMessages && errorMessages.length > 0) {
                noDataMessage = `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${errorMessages[0]}`;
            }

            return <NoData message={noDataMessage} />;
        }

        return ['N', 'PE', 'W', 'C', 'T'].includes(coverStatus) ? renderNoCoverStatus() : renderNormal();
    }

    return <>{render(allFields, errorMessages, claim.CoverStatus)}</>;
};
