import {validateChecksum} from '~/utils/validateChecksum';

const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const email = (value: string) =>
    !emailRegex.test(value) ? 'Please enter a valid email address. Eg. john.doe@example.com' : undefined;

export const required =
    (fieldName = 'value') =>
    (value: string) =>
        value ? undefined : `Please enter a ${fieldName}`;

export const singaporeMobileNumber = (value: string) =>
    value && !/^[89]/.test(value) ? `Must start with 8 or 9` : undefined;

export const fixedLength = (fixed: number) =>
    function fixedLengthInner(fieldValue: string) {
        return fieldValue && fieldValue.length !== fixed ? `Must be ${fixed} digits` : undefined;
    };

export const sameEmail = (user1Email: string, user2Email: string) => {
    return user1Email &&
        user1Email.length > 0 &&
        user2Email &&
        user2Email.length > 0 &&
        user1Email.toLowerCase() == user2Email.toLowerCase()
        ? `Both admins cannot have the same email`
        : undefined;
};

export const sameUserName = (user1Username: string, user2Username: string) => {
    return user1Username &&
        user1Username.length > 0 &&
        user2Username &&
        user2Username.length > 0 &&
        user1Username.toLowerCase() == user2Username.toLowerCase()
        ? `Both admins cannot have the same NRIC/FIN`
        : undefined;
};

export const preventNRICInsertion = (val: string) => {
    if (validateChecksum(val)) return 'Please remove sensitive information';
    return;
};

export const singaporeNric = (value: string) =>
    value.length !== 0 && !validateChecksum(value) ? 'NRIC/FIN is not valid' : undefined;

export const checkVersionNumber =
    (fieldName = 'value') =>
    (value: string) => {
        return !/^\d+(\.\d+){0,2}$/.test(value) ? `Please enter a valid ${fieldName}` : undefined;
    };

export const checkMaxLength = (value: string, fieldName: string, maxLength: number) => {
    return value.length > maxLength ? `${fieldName} should not exceed ${maxLength} characters` : undefined;
};
