import {IBaseModel} from './ListModel';

export const getDateComparator =
    <IModel extends IBaseModel>(modelFn: (model: IModel) => Date) =>
    (a: IModel, b: IModel) => {
        // redux-persist will automatically serialize the Date objects in our store to strings,
        // so we explicitly convert to Date for now
        const dateA = new Date(modelFn(a));
        const dateB = new Date(modelFn(b));
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    };

export const getAlphabeticalComparator =
    <IModel extends IBaseModel>(modelFn: (model: IModel) => string) =>
    (a: IModel, b: IModel) => {
        const a1 = modelFn(a).toLowerCase();
        const b1 = modelFn(b).toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
    };

export const getNumericComparator =
    <IModel extends IBaseModel>(modelFn: (model: IModel) => number) =>
    (a: IModel, b: IModel) =>
        modelFn(a) - modelFn(b);
