import React, {useState, useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Typography, Button, Divider, Grid, Box, CircularProgress} from '@mui/material';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AGENCY_TYPE, DELETE_REASONS} from '~/constants';
import style from './AgencyEdit.scss';
import {sameEmail, sameUserName} from '~/components/Common/InputField/ValidationRules';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {AdminInputError, emptyUser, IAgency, ISysAdminOpsUnit, ITeam} from '~/interfaces/admin';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {EntityDetails} from '~/components/Admin/EntityDetails/EntityDetails';
import {EntityView} from '~/components/Admin/EntityView/EntityView';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    createAgency,
    updateAgencyDetails,
    createAgencyAdmin,
    updateAgencyAdmin,
    deleteAgencyAdmin,
    activateAgencyAdmin,
} from '~/store/agency/agency.thunk';
import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';
import {deleteAgency} from '~/services/agencyServices';
import {isInternetUser} from '~/utils/userType';

export const AgencyEdit = () => {
    const dispatch = useAppDispatch();
    const {agencyId} = useParams<{agencyId?: string}>();
    const agencyDetails = useTypedSelector((state) => state.data.admin.agencies.agencies)[Number(agencyId)];
    const allAgencies = useTypedSelector((state) => state.data.admin.agencies.agencies);

    const usersDetails = agencyDetails?.Admins || [];
    const canCreateAgency = !agencyDetails;
    const [name, setName] = useState(agencyDetails?.Name || '');
    const [entityInfo, setEntityInfo] = useState(agencyDetails?.EntityInfo || '');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isAgency, setIsAgency] = useState(true);
    const [dateAdded, setDateAdded] = useState(agencyDetails?.DateAdded || '');
    const [agencyType, setAgencyType] = useState(agencyDetails?.AgencyType || '');
    const [dataContributor, setDataContributor] = useState(agencyDetails?.IsDataContributor || false);
    const [user1, setUser1] = useState({...usersDetails[0]});
    const [user2, setUser2] = useState({...usersDetails[1]});
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();
    const disableButton =
        !allowSaveUsers(user1, user2) ||
        name.length == 0 ||
        entityInfo.length == 0 ||
        agencyType.length == 0 ||
        dataContributor == undefined ||
        dateAdded === '';

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(disableButton);
    const [isAgencyHasError, setIsAgencyHasError] = useState(false);
    const [isAdmin1HasError, setIsAdmin1HasError] = useState(false);
    const [isAdmin2HasError, setIsAdmin2HasError] = useState(false);
    const [hasError, setHasError] = useState(false);
    const backUrl = '/admin/agencies';

    const pathname = history.location.pathname;
    const scrollId = history.location.hash.split('#')[1];
    const scrollRef = useRef<HTMLDivElement>(null);

    const hasMoreThanOneAgency = Object.keys(allAgencies).length > 1;
    const isOnlyOneAdmin = usersDetails && usersDetails.length == 1;
    const [isCreateAgencyDetails, setIsCreateAgencyDetails] = useState<boolean>(canCreateAgency);
    const [isEditAgencyDetails, setIsEditAgencyDetails] = useState<boolean>(false);
    const [isCreateUser1, setIsCreateUser1] = useState<boolean>(canCreateAgency);
    const [isCreateUser2, setIsCreateUser2] = useState<boolean>(canCreateAgency);
    const [isEditUser1, setIsEditUser1] = useState<boolean>(false);
    const [isEditUser2, setIsEditUser2] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteAgencyOpen, setIsConfirmDeleteAgencyOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState({name: '', userId: 0});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isShowSubmitButton =
        isCreateUser1 || isCreateUser2 || isEditUser1 || isEditUser2 || isCreateAgencyDetails || isEditAgencyDetails;
    const isCreateAgency = isCreateAgencyDetails && isCreateUser1 && isCreateUser2;
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const closeAllEdits = () => {
        setIsCreateUser1(false);
        setIsCreateUser2(false);
        setIsEditUser1(false);
        setIsEditUser2(false);
        setIsCreateAgencyDetails(false);
        setIsEditAgencyDetails(false);
    };

    const removeUrlFragment = () => {
        if (scrollId) {
            history.push(pathname);
        }
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        closeAllEdits();
        removeUrlFragment();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // eslint-disable-next-line
        const filteredAdmins = [user1, user2].filter((user) => user.Name);
        const updatedAgency: IAgency = {
            Id: isCreateAgency ? 0 : Number(agencyId),
            Name: name,
            AgencyType: agencyType as AGENCY_TYPE,
            EntityInfo: entityInfo,
            DateAdded: new Date(dateAdded).toJSON(),
            IsDataContributor: dataContributor,
            Admins: filteredAdmins,
        };
        setIsLoading(true);
        if (isCreateAgency) {
            updatedAgency.Admins = updatedAgency.Admins
                ? prepareUserCreation(updatedAgency.Admins)
                : updatedAgency.Admins;
            const createdAgency = await dispatch(createAgency(updatedAgency));
            if ('error' in createdAgency) {
                openGrowl(createdAgency.payload as string);
            } else {
                history.push(`/admin/agencies/${createdAgency.payload.Id}`);
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isEditAgencyDetails) {
            updatedAgency.Admins = updatedAgency.Admins
                ? prepareUserCreation(updatedAgency.Admins)
                : updatedAgency.Admins;
            const updatedAgencyResult = await dispatch(updateAgencyDetails(updatedAgency));
            if ('error' in updatedAgencyResult) {
                openGrowl(updatedAgencyResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isCreateUser1) {
            await handleCreateUser(0, updatedAgency);
        } else if (isCreateUser2) {
            await handleCreateUser(1, updatedAgency);
        } else if (isEditUser1) {
            handleUpdateUser(0, updatedAgency);
        } else if (isEditUser2) {
            handleUpdateUser(1, updatedAgency);
        } else {
            setIsLoading(false);
            return;
        }
    };

    /**
     * Dispatch thunk to create single user. Assumption: Atleast 1 user when calling this method.
     */
    const handleCreateUser = async (userPos: number, agency: IAgency) => {
        if (agency.Admins == null) throw new AdminInputError('No Agency Admin specified');
        agency.Admins = prepareUserCreation(agency.Admins);
        const createAgencyAdminResult = await dispatch(
            createAgencyAdmin({agencyId: Number(agencyId), adminDetails: [agency.Admins[userPos]]}),
        );
        if ('error' in createAgencyAdminResult) {
            openGrowl(createAgencyAdminResult.payload as string);
        } else {
            successfulSubmit();
        }
        setIsLoading(false);
    };

    /**
     * Dispatch thunk to edit single user. Assumption: Atleast 1 user when calling this method.
     */
    const handleUpdateUser = async (userPos: number, agency: IAgency) => {
        if (agency.Admins == null) throw new AdminInputError('No Agency Admin specified');
        const updateAgencyAdminResult = await dispatch(
            updateAgencyAdmin({agencyId: Number(agencyId), adminDetails: agency.Admins[userPos]}),
        );
        if ('error' in updateAgencyAdminResult) {
            openGrowl(updateAgencyAdminResult.payload as string);
        } else {
            successfulSubmit();
        }
        setIsLoading(false);
    };

    const handleActivateUser = async (userId?: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);

        if (user) {
            const thunkActionResult = await dispatch(activateAgencyAdmin({agencyId: Number(agencyId), admin: user}));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl(`${user.Name} has been reactivated`);
            }
        }
    };

    const handleDeleteUser = (userId: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);
        if (user) {
            setIsDeleteDialogOpen(true);
            setUserToBeDeleted({name: user.Name, userId: userId});
        }
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setIsConfirmDeleteAgencyOpen(false);
    };
    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteAgencyAdmin({agencyId: Number(agencyId), userId: Number(userToBeDeleted.userId), deletedReason}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            openGrowl(`${userToBeDeleted.name} has been deleted`);
        }
        setIsDeleting(false);
        setUserToBeDeleted({name: '', userId: 0});
        closeDeleteDialog();
        closeAllEdits();
        removeUrlFragment();
    };

    const handleEditAgency = () => {
        history.push(`${pathname}#details`);
        setIsEditAgencyDetails(true);
    };

    const handleEditUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsEditUser1(true);
    };
    const handleEditUser2 = () => {
        history.push(`${pathname}#admin2`);
        setIsEditUser2(true);
    };

    const handleCreateUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsCreateUser1(true);
    };
    const handleCreateUser2 = () => {
        history.push(`${pathname}#admin2`);
        setUser2(emptyUser);
        setIsCreateUser2(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleAgencyDelete = () => {
        setIsConfirmDeleteAgencyOpen(true);
    };

    const confirmAgencyDelete = async (reason: string) => {
        try {
            setIsDeleting(true);
            await deleteAgency(Number(agencyId), reason);
            closeAllEdits();
            openGrowl(`${agencyDetails.Name} has been deleted successfully!`);
            history.push(backUrl);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        }
        setIsDeleting(false);
    };

    const confirmCancel = () => {
        if (isCreateAgency) {
            history.push(backUrl);
        }
        closeAllEdits();
        removeUrlFragment();

        const usersDetails = agencyDetails?.Admins || [];
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
        setName(agencyDetails?.Name || '');
        setEntityInfo(agencyDetails?.EntityInfo || '');
        setDataContributor(agencyDetails?.IsDataContributor ?? false);
        setDateAdded(agencyDetails?.DateAdded || '');
        setAgencyType(agencyDetails?.AgencyType || '');
        closeConfirmDialog();
    };

    useEffect(() => {
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
    }, [agencyDetails]);

    useEffect(() => {
        setHasError(isAgencyHasError || isAdmin1HasError || isAdmin2HasError);
    }, [isAgencyHasError, isAdmin1HasError, isAdmin2HasError]);

    useEffect(() => {
        setIsButtonDisabled(disableButton || hasError);
    }, [user1, user2, name, entityInfo, dateAdded, agencyType, dataContributor, hasError]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [scrollId]);

    const getAgencyUserCounts = (agency: IAgency) => {
        let userCount = 0;
        if (agency.SysAdminOpsUnits && agency.SysAdminOpsUnits.length > 0) {
            agency.SysAdminOpsUnits.map((opsUnit: ISysAdminOpsUnit) => {
                if (opsUnit.Teams && opsUnit.Teams.length > 0) {
                    opsUnit.Teams.map((team: ITeam) => {
                        if (team.Users) {
                            userCount += team.Users.length;
                        }
                    });
                }
            });
        }
        return userCount;
    };

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const DeleteAgencyButton = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    id="delete-agency-button"
                    data-testid="deleteAgencyButton"
                    onClick={() => {
                        handleAgencyDelete();
                    }}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Delete'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push(backUrl);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.agencyEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Agency Details
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        {!isShowSubmitButton && hasMoreThanOneAgency ? (
                            <DeleteAgencyButton />
                        ) : isShowSubmitButton ? (
                            <SubmitButtons />
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                <div ref={scrollId === 'details' ? scrollRef : null} />
                {isCreateAgencyDetails || isEditAgencyDetails ? (
                    <EntityDetails
                        name={name}
                        nameLabel="Agency Name"
                        description={entityInfo}
                        descriptionLabel="Description of Agency"
                        descriptionPlaceholder="mindef.gov.sg"
                        setName={setName}
                        setDescription={setEntityInfo}
                        disableInput={isLoading}
                        isAgency={true}
                        setIsAgency={setIsAgency}
                        dateAdded={dateAdded}
                        setDateAdded={setDateAdded}
                        agencyType={agencyType}
                        setAgencyType={setAgencyType}
                        dataContributor={dataContributor}
                        setDataContributor={setDataContributor}
                        setHasError={setIsAgencyHasError}
                    />
                ) : (
                    <EntityView
                        name={name}
                        nameLabel="Agency Name"
                        description={entityInfo}
                        descriptionLabel="Description of Agency"
                        isDisplayControls={!isShowSubmitButton}
                        handleEditEntity={handleEditAgency}
                        isAgency={true}
                        dateAdded={dateAdded}
                        agencyType={agencyType}
                        dataContributor={dataContributor}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin1' ? scrollRef : null} />
                {isCreateUser1 || isEditUser1 ? (
                    <UserDetails
                        heading="Agency Admin 1"
                        details={user1}
                        setUser={setUser1}
                        fieldsToDisable={
                            isCreateUser1 ? [] : isInternetUser(user1) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setIsAdmin1HasError}
                    />
                ) : (
                    <UserView
                        heading="Agency Admin 1"
                        details={user1}
                        userRole="Agency Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser1}
                        isDisplayControls={
                            !(isEditUser2 || isCreateUser2 || isCreateAgencyDetails || isEditAgencyDetails)
                        }
                        hideDeleteButton={isOnlyOneAdmin}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser1}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin2' ? scrollRef : null} />
                {isCreateUser2 || isEditUser2 ? (
                    <UserDetails
                        heading="Agency Admin 2"
                        details={user2}
                        setUser={setUser2}
                        fieldsToDisable={
                            isCreateUser2 ? [] : isInternetUser(user2) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        customError={{
                            email: sameEmail(user1.Email, user2.Email),
                            userName: sameUserName(user1.UserName, user2.UserName),
                        }}
                        disableInput={isLoading}
                        setHasError={setIsAdmin2HasError}
                    />
                ) : (
                    <UserView
                        heading="Agency Admin 2"
                        details={user2}
                        userRole="Agency Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser2}
                        hideDeleteButton={isOnlyOneAdmin}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser2}
                        isDisplayControls={
                            !(isEditUser1 || isCreateUser1 || isCreateAgencyDetails || isEditAgencyDetails)
                        }
                    />
                )}
                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteAgencyDialog"
                users={(agencyDetails && getAgencyUserCounts(agencyDetails)) || 0}
                title="Delete Agency?"
                requireAcknowledgement={true}
                acknowledgementMessage={DELETE_REASONS['Agency']}
                warningMessage="Existing Users will not be able to use OneCV anymore."
                buttonMessage="AGENCY"
                open={isConfirmDeleteAgencyOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmAgencyDelete}
                isLoading={isDeleting}
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteAgencyAdminDialog"
                title={`Delete Agency Admin?`}
                buttonMessage="AGENCY ADMIN"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
