import React, {useContext, useEffect} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE} from '~/constants';
import {config} from '~/config';
import Cookies from 'universal-cookie';

export const AdfsLoginView = () => {
    const {setAccessToken, loginState, updateLoginState} = useContext(AuthenticationContext);
    const location = useLocation();
    const cookies = new Cookies();

    /**
     * When the ADFS server redirects back to onecv's '/saml/acs' endpoint,
     * a `sso__${cookieIndex}` with the value of the user's JWT token is set
     * for successful login. The user is then redirected to '/login' page
     * with the cookieIndex value set in the url parameter [sso]
     */
    const fetchToken = (cookieIndex: string) => {
        const token = cookies.get(`sso_${cookieIndex}`);
        if (token) {
            setAccessToken(token);
            updateLoginState(LOGIN_STATE.LOGGED_IN);
        }
    };

    useEffect(() => {
        (() => {
            if (loginState == LOGIN_STATE.LOGGED_IN) return;
            let matchedQuery = false;
            const queries = location.search.substring(1).split('&');

            queries.forEach((query) => {
                const queryPair = query.split('=');
                if (queryPair[0] === 'sso') {
                    matchedQuery = true;
                    fetchToken(queryPair[1]);
                }
            });

            // Initiate ADFS login flow if [sso] url parameter is not present
            if (!matchedQuery) {
                window.location.href = `${config.api.AUTH_SERVICE}/adfs-login`;
            }
        })();
    }, []);

    return loginState == LOGIN_STATE.LOGGED_IN ? <Redirect to={'/'} /> : null;
};
