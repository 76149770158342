import React from 'react';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {format, getTime} from 'date-fns';
import {transformInvalidDateString} from '~/utils/dateUtils';
import {formatCurrency} from '~/utils/currencyUtils';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {Typography} from '@mui/material';
import {allNoResults, extractAllValues, formatValue, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type ICDACAssistanceData = ICDACAssistance[];

interface ICDACAssistance {
    ApplicantIndicator: string;
    BeneficiaryIndicator: string;
    FinancialAssistanceScheme: string;
    PaymentSchemeElement: string;
    Status: string;
    OutcomeDate: string;
    StartDate: string;
    EndDate: string;
    PaymentFrequency: string;
    AmountSubsidyReceived: string;
    ReferenceNo: string;
}

const formatLongDate = (dateString: string) => createCdacDate(dateString, false);
const formatShortDate = (dateString: string) => createCdacDate(dateString);

const createCdacDate = (dateString: string, isShortMonth = true) =>
    format(new Date(transformInvalidDateString(dateString, 'DDMMYYYY')), `dd ${isShortMonth ? 'MMM' : 'MMMM'} yyyy`);

const ASSISTANCE_PERIOD = 'Assistance Period';
const CASE_REFERENCE_NO = 'Case Reference Number';
const STATUS = 'Status';
const ASSISTANCE_TYPE = 'Assistance Type';
const BENEFIT_TYPE = 'Benefit Type';
const FREQUENCY = 'Frequency';
const AMOUNT = 'Amount';
const OUTCOME_DATE = 'Outcome Date';

const formatCdacAmount = (amount: string): string => {
    return `$${formatCurrency(+amount)}`;
};

const cdacAssistanceFields = (assistance: ICDACAssistance) => {
    const allFields = [
        {
            label: ASSISTANCE_PERIOD,
            value:
                isNotEmpty(assistance.StartDate) && isNotEmpty(assistance.EndDate) ? (
                    <Typography variant="h6">
                        {`${formatValue(assistance.StartDate, formatShortDate)} to ${formatValue(
                            assistance.EndDate,
                            formatShortDate,
                        )}`}
                    </Typography>
                ) : (
                    assistance.StartDate
                ),
            fullWidth: true,
        },
        {label: CASE_REFERENCE_NO, value: assistance.ReferenceNo},
        {label: STATUS, value: assistance.Status},
        {label: ASSISTANCE_TYPE, value: assistance.FinancialAssistanceScheme},
        {label: BENEFIT_TYPE, value: assistance.PaymentSchemeElement},
        {label: FREQUENCY, value: assistance.PaymentFrequency},
        {label: AMOUNT, value: formatValue(assistance.AmountSubsidyReceived, formatCdacAmount)},
        {label: OUTCOME_DATE, value: formatValue(assistance.OutcomeDate, formatLongDate)},
    ];
    return allFields;
};

export const CDACAssistance: React.FunctionComponent<{data: ICDACAssistanceData}> = ({data}) => {
    //Check for data
    const noData = allNoResults(extractAllValues(data));

    //Sort by descending start date if data is available
    const sortedCases = noData
        ? data
        : data.sort(
              (caseA, caseB) =>
                  getTime(new Date(transformInvalidDateString(caseB.StartDate, 'DDMMYYYY'))) -
                  getTime(new Date(transformInvalidDateString(caseA.StartDate, 'DDMMYYYY'))),
          );

    //Map each field with corresponding display
    const fieldsToRender = sortedCases.map(cdacAssistanceFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <MultiGridDisplay
                    dataTestId="cdacInfo"
                    dataFieldGroups={fieldsToRender}
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
