import {createAsyncThunk} from '@reduxjs/toolkit';
import * as dashboardServices from '~/services/dashboardServices';
import {IDashboardInfo} from '~/interfaces/dashboard';
import {RootState} from '../index';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {API_FETCH_STATE} from '~/constants';

export const setDashboard = createAsyncThunk<IDashboardInfo, void, {state: RootState; rejectValue: string}>(
    '/dashboard/setDashboard',
    async (_, {rejectWithValue, dispatch}) => {
        dispatch({type: fetchStateActions.SET_DASHBOARD_FETCH_STATE, payload: API_FETCH_STATE.PENDING});
        try {
            const dashboardInfo = await dashboardServices.getDashboardInfo();
            dispatch({type: fetchStateActions.SET_DASHBOARD_FETCH_STATE, payload: API_FETCH_STATE.SUCCESS});
            return dashboardInfo;
        } catch (error) {
            dispatch({type: fetchStateActions.SET_DASHBOARD_FETCH_STATE, payload: API_FETCH_STATE.ERROR});
            return rejectWithValue('Error in fetching dashboard info');
        }
    },
);
