import {createAsyncThunk} from '@reduxjs/toolkit';
import {ERROR_MESSAGE} from '~/constants';
import {IFavourite, IFavourites, IScreenHistory, ISubDomain} from '~/interfaces/clientUser';
import * as apiServices from '~/services/apiServices';
import {RootState} from '..';

export const setClientUserScreeningHistory = createAsyncThunk<IScreenHistory[], void, {rejectValue: string}>(
    '/clientUser/setClientUserScreeningHistory',
    async (_, {rejectWithValue}) => {
        try {
            const histories = await apiServices.getClientUserScreeningHistory();
            return histories;
        } catch (err) {
            return rejectWithValue('setClientUserScreeningHistory error');
        }
    },
);

export const setClientUserDataAccess = createAsyncThunk<ISubDomain[], void, {rejectValue: string}>(
    '/clientUser/setClientUserDataAccess',
    async (_, {rejectWithValue}) => {
        try {
            const subDomains = await apiServices.getClientUserDataAccess();
            return subDomains;
        } catch (err) {
            return rejectWithValue('setClientUserDataAccess error');
        }
    },
);

export const setClientUserSubDomainFavourites = createAsyncThunk<IFavourites, void>(
    '/clientUser/setClientUserSubDomainFavourites',
    async () => {
        const favourites = await apiServices.getClientUserSubDomainFavourites();
        return favourites;
    },
);

export const setNewClientUserSubDomainFavourites = createAsyncThunk<IFavourite, IFavourite, {rejectValue: string}>(
    '/clientUser/setNewClientUserSubDomainFavourites',
    async (favourite, {rejectWithValue}) => {
        try {
            const respond = await apiServices.postClientUserSubDomainFavourite(favourite);
            return respond;
        } catch (err) {
            return rejectWithValue('setClientUserSubDomainFavourites error');
        }
    },
);

export const deleteClientUserSubDomainFavourite = createAsyncThunk<
    number,
    number,
    {rejectValue: string; state: RootState}
>('/clientUser/deleteClientUserSubDomainFavourite', async (favouriteId, {getState, rejectWithValue, dispatch}) => {
    const currentFavourites = getState().data.clientUser.favourites;
    const favouriteToDelete = currentFavourites.find((fav) => fav.Id === favouriteId);
    if (favouriteToDelete) {
        try {
            await apiServices.deleteClientUserSubDomainFavourite(favouriteId);
            return favouriteToDelete.Id;
        } catch (err) {
            await dispatch(setNewClientUserSubDomainFavourites(favouriteToDelete));
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    } else {
        return rejectWithValue('subdomain favourite not found');
    }
});
