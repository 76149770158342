import {AppContainer} from '~/AppContainer';
import {StyledEngineProvider} from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import {loadConfig} from '~/config';

import './styles/main.scss';

const redirectScheme = () => {
    if (
        !window.location.href.includes('localhost') &&
        !window.location.href.includes('127.0.0.1') &&
        window.location.protocol !== 'https:'
    ) {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
};

const init = async (): Promise<void> => {
    redirectScheme();
    await loadConfig();
    ReactDOM.render(
        <StyledEngineProvider injectFirst>
            <AppContainer />
        </StyledEngineProvider>,
        document.getElementById('app'),
    );
};

init();
