import React, {useState, useContext, useEffect, useRef} from 'react';
import {Autocomplete, Grid, Typography, Box, Tabs, Tab, TextField} from '@mui/material';
import axios from 'axios';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {ISummaryFileRequest, ISummaryFileMeta} from '~/interfaces/common';
import {COPIES, API_FETCH_STATE} from '~/constants';
import {IFetchStatus} from '~/interfaces/common';
import {getAbbreviationFromBrackets} from '~/utils/nameUtils';
import {TabPanel} from '~/components/Common/TabPanel';
import {SimpleDialog} from '~/components/Common/SimpleDialog';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import {FileBox} from '~/components/Admin/FileBox/FileBox';
import {UploadProgressBox} from '~/components/Admin/UploadProgressBox/UploadProgressBox';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {DataUploadResponse} from '~/pages/Admin/DataUploadResponse/DataUploadResponse';
import {getCurrentTime, getFileSizeInKb, getTimeFromApiDate} from './DataUploadHelpers';
import {downloadSummaryFile, getUploadHistory, uploadDataFile, rollbackUpload} from '~/services/dataUploadServices';
import {UploadHistory} from '~/components/Admin/UploadHistory/UploadHistory';
import {ACCESS_LEVELS} from '~/constants';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import './DataUpload.scss';

//Temp disabled MINDEF
const ROLLBACK_ENABLED_AGENCY = ['CDAC', 'WSG', 'MINDEF'];

export const DataUpload = () => {
    const headerRef = useRef<HTMLDivElement>(null);
    const {accessToken} = useContext(AuthenticationContext);
    const agencies = accessToken?.Permissions.DataUploadAllowedFor ?? [];
    const [dataContributor, setDataContributor] = useState<string>(agencies[0]);
    const [tabValue, setTabValue] = useState<number>(0);
    const MAIN_UPLOAD_PAGE = 0;
    const RESPONSE_PAGE = 1;
    const [activePage, setActivePage] = useState<number>(MAIN_UPLOAD_PAGE);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFilename, setSelectedFilename] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [obtainedResponse, setObtainedResponse] = useState<boolean>(false);
    const [noOfErrorRecords, setNoOfErrorsRecords] = useState<number>(0);
    const [allRecordsFailed, setAllRecordsFailed] = useState<boolean>(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [filenameToDownload, setFilenameToDownload] = useState<string>('');
    const [uploadRequestIdToDownload, setUploadRequestIdToDownload] = useState<string>('');
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);

    const [errorGettingHistory, setErrorGettingHistory] = useState<boolean>(false);
    const [history, setHistory] = useState<ISummaryFileMeta[]>([]);
    const [historyApiStatus, setHistoryApiStatus] = useState<IFetchStatus>(API_FETCH_STATE.SUCCESS);
    const [showDialog, setDialog] = useState<boolean>(false);
    const [rollbackId, setRollbackId] = useState<string>('');
    const [rollbackFile, setRollbackFile] = useState<string>('');
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    // Set Max File Size to 10 MB
    const isValidFileSize = (file: File) => file && file.size < 10 * 1024 * 1024;

    const tabsHandler = (_: React.ChangeEvent<{}>, val: number) => {
        setTabValue(val);
    };

    async function fetchAndLoadHistory() {
        setErrorGettingHistory(false);
        try {
            setHistoryApiStatus(API_FETCH_STATE.PENDING);
            const response = await getUploadHistory();
            setHistory(response);
            setHistoryApiStatus(API_FETCH_STATE.SUCCESS);
        } catch (e) {
            setErrorGettingHistory(true);
            setHistoryApiStatus(API_FETCH_STATE.ERROR);
        }
    }

    useEffect(() => {
        fetchAndLoadHistory();
    }, []);

    const filterHistoryByCurrentAgency = (): ISummaryFileMeta[] => {
        return history.filter((fileMeta) => fileMeta.DataContributor == getAbbreviationFromBrackets(dataContributor));
    };

    const resetFile = () => {
        const element = document.getElementById('file') as HTMLInputElement;
        if (element) element.value = '';
        setSelectedFile(null);
        setSelectedFilename('');
    };

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event || !event.target || !event.target.files) return;
        const file = event.target.files[0];

        setObtainedResponse(false);
        setProgress(0);

        if (!isValidFileSize(file)) {
            setErrorTitle('Validation Error');
            setErrorMessage('The uploaded file exceeds 10mb. Please try again.');
            setErrorDialogOpen(true);
            return;
        }

        setSelectedFile(file);
        const filename = file.name.substring(0, 60);
        setSelectedFilename(filename);
        uploadFile(filename, file);
    };

    const uploadFile = async (name: string, selected: File) => {
        try {
            if (!selected) {
                throw new Error('No file selected');
            }
            if (!dataContributor) {
                throw new Error('You are not authorised to upload data');
            }

            setUploading(true);
            setAllRecordsFailed(false);

            const responseFileMeta = await uploadDataFile(selected, name, dataContributor, setProgress, cancelToken);
            setHistory([responseFileMeta, ...history]);
            setUploading(false);
            setObtainedResponse(true);
            setNoOfErrorsRecords(responseFileMeta.NoOfRecordsFailed);
            setAllRecordsFailed(responseFileMeta.IsAllFailure || false);
            setFilenameToDownload(responseFileMeta.ResponseFilename);
            setUploadRequestIdToDownload(responseFileMeta.UploadRequestId);

            setActivePage(RESPONSE_PAGE);
        } catch (err) {
            const error = err as Error;
            const errorMsg = (error.message as string).trim();
            setErrorTitle('Upload Error');
            setErrorMessage(`The file "${name}" could not be uploaded: ${errorMsg}.`);
            setErrorDialogOpen(true);
            setUploading(false);
            resetFile();
        }
    };

    const cancelUpload = () => {
        cancelTokenSource.cancel();
        setUploading(false);
        resetFile();
    };

    const clickDownloadFileHandler = (responseFilename: string, uploadRequestId: string) => {
        setPasswordModalOpen(true);
        setFilenameToDownload(responseFilename);
        setUploadRequestIdToDownload(uploadRequestId);
    };

    const rollbackHandler = (fileName: string, uploadRequestId: string) => {
        setDialog(true);
        setRollbackId(uploadRequestId);
        setRollbackFile(fileName);
    };

    const confirmRollback = async () => {
        try {
            await rollbackUpload(rollbackId);
            openGrowl(`“${rollbackFile}” has been rolled back`);
        } catch (err) {
            const error = err as Error;
            const errorMsg = (error.message as string).trim();
            setErrorTitle('Roll Back Error');
            //TODO Error message
            setErrorMessage(`The file "${rollbackFile}" could not be rollbacked: ${errorMsg}.`);
            setErrorDialogOpen(true);
        }

        await fetchAndLoadHistory();
        setDialog(false);
    };

    const downloadFile = async (password: string) => {
        try {
            if (filenameToDownload == '') {
                throw new Error('File name is empty');
            }
            if (uploadRequestIdToDownload == '') {
                throw new Error('Upload request ID is empty');
            }
            const fileRequest: ISummaryFileRequest = {
                password: password,
                filename: filenameToDownload,
                uploadRequestId: uploadRequestIdToDownload,
            };
            await downloadSummaryFile(fileRequest);
            setFilenameToDownload('');
            setUploadRequestIdToDownload('');
        } catch (err) {
            const error = err as Error;
            const errorMsg = (error.message as string).trim();
            setErrorTitle('Download Error');
            setErrorMessage(`The file "${filenameToDownload}" could not be downloaded: ${errorMsg}.`);
            setErrorDialogOpen(true);
            setFilenameToDownload('');
            setUploadRequestIdToDownload('');
        }
    };

    return (
        <>
            {activePage == RESPONSE_PAGE ? (
                <DataUploadResponse
                    goBackMainPage={() => setActivePage(MAIN_UPLOAD_PAGE)}
                    setPasswordModalOpen={setPasswordModalOpen}
                    filename={selectedFilename}
                    dataAgency={dataContributor}
                    numberOfErrors={noOfErrorRecords}
                    allRecordsFailed={allRecordsFailed}
                />
            ) : (
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h4">Data Upload</Typography>
                    </Grid>
                    <Grid item styleName="dataAgencyGrid">
                        <Typography variant="h5">{dataContributor}</Typography>
                    </Grid>
                    <Grid item styleName="tabsGrid">
                        <Tabs
                            value={tabValue}
                            onChange={tabsHandler}
                            aria-label="simple tabs"
                            variant="fullWidth"
                            styleName="tabsWrapper"
                        >
                            <Tab label="UPLOAD DATA" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                            <Tab label="SUMMARY REPORT HISTORY" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        </Tabs>
                    </Grid>

                    <Grid item styleName={tabValue == 0 ? 'show' : 'hidden'}>
                        <TabPanel value={tabValue} index={0}>
                            {agencies.length > 1 && (
                                <React.Fragment>
                                    <Typography variant="inherit" styleName="uploadHeader">
                                        1. Select Agency
                                    </Typography>
                                    <Autocomplete
                                        styleName="agencyDropdownUpload"
                                        options={agencies}
                                        onChange={(_: React.ChangeEvent<{}>, agency: string | null) =>
                                            agency && setDataContributor(agency)
                                        }
                                        value={dataContributor}
                                        disableClearable={true}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Upload for" />
                                        )}
                                    />
                                </React.Fragment>
                            )}
                            <Typography variant="inherit" styleName="uploadHeader">
                                {agencies.length > 1 && `2. `}Upload File
                            </Typography>
                            <Typography styleName="description">
                                Please make sure that your file is a ZIP file, protected with your agency&apos;s given
                                password.
                            </Typography>

                            <Box styleName="files">
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    onClick={resetFile}
                                    onChange={fileChangeHandler}
                                    accept={'application/zip'}
                                    disabled={uploading}
                                />
                                <span styleName="upload-normalPrint">
                                    Drag and drop your file to upload, or{' '}
                                    <span styleName="upload-bluePrint">click to browse.</span>
                                </span>
                                <span styleName="upload-smallPrint">
                                    Please only upload a ZIP file protected by the pre-determined password. The ZIP file
                                    should contain the file with your data.
                                </span>
                                <span styleName="upload-smallPrint">Maximum file size: 10mb</span>
                            </Box>
                            {selectedFile && !obtainedResponse && (
                                <UploadProgressBox
                                    filename={selectedFilename + getFileSizeInKb(selectedFile.size)}
                                    time={getCurrentTime()}
                                    progress={progress}
                                    onClickCancelHandler={cancelUpload}
                                />
                            )}
                            {obtainedResponse && (
                                <FileBox
                                    filename={history[0].ResponseFilename}
                                    time={getTimeFromApiDate(history[0].CreatedAt)}
                                    onClickHandler={() =>
                                        clickDownloadFileHandler(
                                            history[0].ResponseFilename,
                                            history[0].UploadRequestId,
                                        )
                                    }
                                />
                            )}
                        </TabPanel>
                    </Grid>

                    <Grid item>
                        <TabPanel value={tabValue} index={1}>
                            {agencies.length > 1 && (
                                <Autocomplete
                                    styleName="agencyDropdownHistory"
                                    options={agencies}
                                    onChange={(_: React.ChangeEvent<{}>, agency: string | null) =>
                                        agency && setDataContributor(agency)
                                    }
                                    value={dataContributor}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="View history for" />
                                    )}
                                />
                            )}
                            <UploadHistory
                                history={filterHistoryByCurrentAgency()}
                                errorGettingHistory={errorGettingHistory}
                                clickDownloadFileHandler={clickDownloadFileHandler}
                                rollbackHandler={rollbackHandler}
                                isAdmin={accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR}
                                historyApiStatus={historyApiStatus}
                                enableRollback={ROLLBACK_ENABLED_AGENCY.includes(
                                    getAbbreviationFromBrackets(dataContributor),
                                )}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            )}

            <SimpleDialog
                id="errorDialog"
                open={errorDialogOpen}
                onClose={() => setErrorDialogOpen(false)}
                message={errorMessage}
                title={errorTitle}
            />

            <ReportPasswordDialog
                open={passwordModalOpen}
                onSubmit={downloadFile}
                onClose={() => setPasswordModalOpen(false)}
                title={COPIES.UPLOAD_PASSWORD_DIALOG_TITLE}
                description={COPIES.UPLOAD_PASSWORD_DIALOG_BODY}
            />
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={() => setDialog(false)}
                onConfirm={confirmRollback}
                open={showDialog}
                title="Roll back Upload?"
                message={`The upload “${rollbackFile}”, and its data will be rolled back. `}
                cancelOption="Cancel"
                confirmOption="Roll Back"
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={headerRef}
            />
        </>
    );
};
