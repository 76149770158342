import {KeyboardArrowRight} from '@mui/icons-material';

import React from 'react';
import {useHistory} from 'react-router-dom';
import {Link} from '@mui/material';
import {config} from '~/config';
import style from './DomainHeader.scss';
interface IDomainHeader {
    title: string;
    btnName: React.ReactNode;
    domainId: number;
    opsUnitId: number;
    showKeyboardArrowRight?: boolean;
    requestStatus?: JSX.Element;
}

export const DomainHeader = (params: IDomainHeader) => {
    const {title, btnName, domainId, opsUnitId, showKeyboardArrowRight = true, requestStatus} = params;
    const history = useHistory();
    return (
        <div className={style.titleContainer}>
            <span className={style.title}>{title}</span>
            <span className={style.accessBtn}>
                {requestStatus && (
                    <span className={style.requestStatusContainer}>
                        <span>Request Status: {requestStatus}</span>
                    </span>
                )}
                <Link
                    onClick={() => {
                        const page = config.featureFlags.ENABLE_DAM2_VIEW
                            ? `/admin/v2/data-access-management/ops-unit/${opsUnitId}/domain/${domainId}`
                            : `/admin/data-access-management/ops-unit/${opsUnitId}/domain/${domainId}`;
                        history.push(page);
                    }}
                    style={{letterSpacing: '1.25px'}}
                >
                    {btnName}
                    {showKeyboardArrowRight && <KeyboardArrowRight className={style.arrow} />}
                </Link>
            </span>
        </div>
    );
};
