import {config} from '~/config';
import {httpRequest} from './middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {IReleaseNote} from '~/interfaces/common';

export const getReleaseNotes = async (): Promise<IReleaseNote[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/release-notes`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getReleaseNote = async (releaseNoteId: number): Promise<IReleaseNote> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/release-notes/${releaseNoteId}`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createReleaseNote = async (releaseNoteDetails: IReleaseNote): Promise<{}> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/release-notes`,
        data: JSON.stringify(releaseNoteDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const updateReleaseNoteDetails = async (releaseNote: IReleaseNote): Promise<{}> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/release-notes/${releaseNote.Id}`,
        data: JSON.stringify(releaseNote),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const deleteReleaseNote = async (releaseNoteId: number): Promise<{}> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/release-notes/${releaseNoteId}`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};
