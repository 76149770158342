import {createAsyncThunk} from '@reduxjs/toolkit';
import {ISurvey, ISurveyResponse, ISurveyTrigger} from '~/interfaces/clientUser';
import * as userServices from '~/services/userServices';

export const setSurveyTrigger = createAsyncThunk<ISurveyTrigger, void, {rejectValue: string}>(
    '/survey/setSurveyTrigger',
    async (_, {rejectWithValue}) => {
        try {
            const surveyTrigger = await userServices.getSurveyTrigger();
            return surveyTrigger;
        } catch (err) {
            return rejectWithValue('setSurveyTrigger error');
        }
    },
);

export const createSurvey = createAsyncThunk<ISurveyResponse, ISurvey, {rejectValue: string}>(
    '/survey/createSurvey',
    async (survey, {rejectWithValue}) => {
        const surveyResponse = await userServices.createSurvey(survey);
        if (surveyResponse.Error) {
            // throw new Error(surveyResponse.Error);
            return rejectWithValue(surveyResponse.Error);
        }
        return surveyResponse;
    },
);
