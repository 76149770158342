import React, {Dispatch, SetStateAction} from 'react';
import {Box, Button, Link, Typography} from '@mui/material';
import {IAdminRow, IMonthlyReviewResponse} from '~/interfaces/admin';
import {CheckCircle, NavigateNext} from '@mui/icons-material';
import {MONTHLY_REVIEW_STATUS} from '~/constants';
import {formatDate} from '~/utils/dateUtils';

import './ReviewStatus.scss';

export const ReviewStatus =
    (
        monthlyReviewStatus: IMonthlyReviewResponse | undefined,
        setReviewOpen: Dispatch<SetStateAction<boolean>> | undefined,
        handleApprove: (opsUnit: IAdminRow) => void,
    ) =>
    (admin: IAdminRow) => {
        const NIL_DATE = '0001-01-01T00:00:00Z';
        const isOpsUnitAdmin = admin.OpsUnitId || admin.AgencyId;
        const isAgencyAdmin = !admin.OpsUnitId;
        const recentApprovalBy = admin.RecentApprovalBy;
        if (isOpsUnitAdmin) {
            if (admin.Status === MONTHLY_REVIEW_STATUS.COMPLETED) {
                if (admin.ApprovalStatus === MONTHLY_REVIEW_STATUS.COMPLETED) {
                    return (
                        <Box component="div">
                            <Box component="div" styleName="dateWrapper">
                                {admin.MostRecentApproval && admin.MostRecentApproval != NIL_DATE
                                    ? formatDate(new Date(admin.MostRecentApproval))
                                    : '-'}
                            </Box>
                            <Box component="div" styleName="nameWrapper">
                                {recentApprovalBy}
                            </Box>
                        </Box>
                    );
                } else if (admin.ApprovalStatus === MONTHLY_REVIEW_STATUS.ONGOING) {
                    return (
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleApprove(admin)}
                            styleName="approveAdminButton"
                        >
                            APPROVE
                        </Button>
                    );
                } else {
                    return (
                        <Box component="div" styleName="greyText" id="statusOfReview">
                            No approval recorded
                        </Box>
                    );
                }
            } else if (admin.Status === MONTHLY_REVIEW_STATUS.ONGOING) {
                return (
                    <Box component="div" styleName="greyText" id="statusOfReview">
                        Pending review
                    </Box>
                );
            } else if (admin.Status === MONTHLY_REVIEW_STATUS.NEW && admin.CreatedAt) {
                const startReviewDate = new Date(
                    new Date(admin.CreatedAt).setMonth(new Date(admin.CreatedAt).getMonth() + 1),
                );
                return (
                    <Box component="div" style={{color: '#979797'}} id="statusOfReview">
                        {`Starts in ${startReviewDate.toLocaleString('en-US', {month: 'short', year: 'numeric'})}`}
                    </Box>
                );
            } else {
                return (
                    <Box component="div" styleName="greyText" id="statusOfReview">
                        No approval recorded
                    </Box>
                );
            }
        } else if (isAgencyAdmin && setReviewOpen) {
            if (monthlyReviewStatus && monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.COMPLETED) {
                return (
                    <Box component="div" styleName="verifyText" id="statusOfReview">
                        <CheckCircle styleName="checkIcon" fontSize="small" />
                        ACTIVE
                    </Box>
                );
            }
            if (monthlyReviewStatus && monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.ONGOING) {
                return (
                    <Link onClick={() => setReviewOpen(true)} target="_blank" rel="noopener noreferrer">
                        <Box display="flex" alignItems="center">
                            <Typography styleName="verifyAdminButton">MARK AS ACTIVE</Typography>
                            <NavigateNext fontSize="small" />
                        </Box>
                    </Link>
                );
            } else {
                return (
                    <Box component="div" styleName="greyText" id="statusOfReview">
                        No acknowledgement recorded
                    </Box>
                );
            }
        }
        return <>-</>;
    };

export default ReviewStatus;
