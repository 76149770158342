import {format} from 'date-fns';

export const formatDate = (date: Date, isMonthLong = false, isDayLong = true, hideDayValue = false) =>
    date.toLocaleDateString('en-gb', {
        day: hideDayValue ? undefined : isDayLong ? '2-digit' : 'numeric',
        year: 'numeric',
        month: isMonthLong ? 'long' : 'short',
    });

export const formatDateMonthName = (date: string, includeDay = true) => {
    if (includeDay) {
        return format(new Date(date), 'dd MMMM yyyy');
    } else {
        return format(new Date(date), 'MMMM yyyy');
    }
};

// format date to dd mmm yyyy
export const formatDateddmmmyyyy = (date: string) => {
    const dateObj = new Date(date);
    const monthName = dateObj.toLocaleString('default', {month: 'short'});
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${day} ${monthName} ${year}`;
};

export const dateFormatter = (date: string): string => {
    return formatDate(new Date(date), true, true);
};

export const formatDateNumeric = (date: string) => {
    const parseDate = new Date(date.split('(')[0]);
    return parseDate.getFullYear() + '-' + (parseDate.getMonth() + 1) + '-' + parseDate.getDate();
};

export const formatDateForHTMLInput = (date: string) => {
    const parseDate = new Date(date);
    let month = '' + (parseDate.getMonth() + 1);
    let day = '' + parseDate.getDate();
    const year = parseDate.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

export const formatTime = (
    date: Date,
    isHourLong = false,
    isMinutesLong = false,
    isSecondsLong = false,
    hideSecondsValue = false,
    hideAmPm = false,
) =>
    date.toLocaleTimeString(hideAmPm ? 'en-gb' : 'en-US', {
        second: hideSecondsValue ? undefined : isSecondsLong ? '2-digit' : 'numeric',
        minute: isMinutesLong ? '2-digit' : 'numeric',
        hour: isHourLong ? '2-digit' : 'numeric',
    });

export const getDayDiffFromToday = (date: string) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const secondDate = new Date().getTime();
    const firstDate = new Date(date).getTime();

    return Math.ceil((firstDate - secondDate) / oneDay);
};

export const getLatestDate = (dateArray: string[]) =>
    dateArray.reduce((a, b) => {
        return new Date(a) > new Date(b) ? a : b;
    });

export const getYearMonthFormat = (months: string): string => {
    const monthsFloat = parseFloat(months);
    if (isNaN(monthsFloat)) {
        return 'Invalid format';
    }
    const monthsInt = Math.round(monthsFloat);

    if (monthsInt < 12) {
        return monthsInt + ' month' + (monthsInt > 1 ? 's' : '');
    }

    const monthsMod = monthsInt % 12;
    const year = Math.floor(monthsInt / 12);
    if (monthsMod == 0) {
        return year.toString() + ` year${year > 1 ? 's' : ''}`;
    }

    return `${year.toString()} year${year > 1 ? 's' : ''} ${monthsMod.toString()} month${monthsMod > 1 ? 's' : ''}`;
};

export const getDate = (date: string) => {
    return new Date(+date.substr(0, 4), +date.substr(4, 2) - 1, +date.substr(6, 2));
};

export const isToday = (dateString: string): boolean => {
    const date = new Date(dateString);
    const today = new Date();
    return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    );
};

// Convert invalid date string to valid date string parseable by Date object
export const transformInvalidDateString = (
    date: string,
    format: 'DD/MM/YY' | 'YYYYMMDD' | 'YYYYMM' | 'DDMMYYYY' = 'DD/MM/YY',
) => {
    switch (format) {
        case 'DD/MM/YY': {
            const splitDate = date.split('/');
            return `${parseInt(splitDate[1])}/${parseInt(splitDate[0])}/${parseInt(splitDate[2])}`;
        }
        case 'YYYYMMDD': {
            return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
        }
        case 'YYYYMM': {
            return `${date.substring(0, 4)}-${date.substring(4, 6)}`;
        }
        case 'DDMMYYYY': {
            return `${date.substring(2, 4)}/${date.substring(0, 2)}/${date.substring(4, 8)}`;
        }
    }
};
