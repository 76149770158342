import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Stack} from '@mui/material';
import {UnregisterCallback} from 'history';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDamRequestFieldsUpdated} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';

// interface ILeavePageModalProps {

// }

export const LeavePageModal = () => {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const unblockRef = useRef<UnregisterCallback>();

    const fieldsUpdated = useTypedSelector(getDamRequestFieldsUpdated());

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (fieldsUpdated) {
                setCurrentPath(location.pathname);
                handleShowModal();
                return false;
            }
            return;
        });

        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [fieldsUpdated]);

    function handleShowModal() {
        setShowPrompt(true);
    }
    function onCancel() {
        setShowPrompt(false);
    }
    function handleConfirm() {
        if (unblockRef) {
            unblockRef.current && unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath);
    }

    return (
        <Dialog
            fullWidth
            open={showPrompt}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{color: '#3C3C3C', letterSpacing: '0.18px'}}>
                Leave page without saving?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{color: '#3C3C3C', letterSpacing: '0.5px'}}>
                    Any unsaved changes will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <Stack spacing={2} direction="row" style={{float: 'right'}}>
                    <Button onClick={onCancel} color="primary" variant="outlined" autoFocus>
                        Stay on page
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="error" autoFocus>
                        Leave page
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default LeavePageModal;
