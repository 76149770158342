import React, {useState, useEffect} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Divider,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {RatingGroup} from './RatingGroup';
import {SURVEY_IMPROVEMENTS, FORM_MAX_TEXT, SURVEY_TYPE} from '~/constants';
import {ISurvey, ISurveyData} from '~/interfaces/clientUser';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {ClientSnackBar} from '../ClientSnackBar/ClientSnackBar';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {createSurvey} from '~/store/survey/survey.thunk';

import './Survey.scss';

export interface IMonthlySurveyProps {
    open: boolean;
    onHandleClose: () => void;
    userName: string;
}

export const MonthlySurvey = ({open, onHandleClose, userName}: IMonthlySurveyProps) => {
    const initialSurveyFields: ISurveyData = {
        UsefulnessRating: 0,
        ExperienceRating: 0,
        Feedback: '',
        ImprovementSelected: [],
        ImprovementOthers: '',
    };
    const [surveyFields, setSurveyFields] = useState(initialSurveyFields);
    const [hasError, setHasError] = useState(true);
    const dispatch = useAppDispatch();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setSurveyFields({
            ...surveyFields,
            [name]: value,
        });
    };

    const handleRatingChange = (e: React.ChangeEvent<{}>, rating: number | null) => {
        const target = e.target as HTMLTextAreaElement;
        setSurveyFields({
            ...surveyFields,
            [target.name]: rating,
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = surveyFields.ImprovementSelected.includes(e.target.value)
            ? surveyFields.ImprovementSelected.filter((selected) => selected !== e.target.value)
            : [...surveyFields.ImprovementSelected, e.target.value];
        setSurveyFields({
            ...surveyFields,
            [e.target.name]: selected,
        });
    };

    const isPositiveRating = () => {
        return surveyFields.ExperienceRating > 3;
    };

    const checkHasError = () => {
        const errors = {
            UsefulnessRating: false,
            ExperienceRating: false,
            ImprovementSelected: false,
            ImprovementOthers: false,
        };
        errors.ExperienceRating = !surveyFields.ExperienceRating;
        errors.UsefulnessRating = !surveyFields.UsefulnessRating;

        if (!isPositiveRating()) {
            errors.ImprovementSelected = !surveyFields.ImprovementSelected.length;
            errors.ImprovementOthers =
                surveyFields.ImprovementSelected.includes('OTHERS') && !surveyFields.ImprovementOthers;
        }
        const noError = Object.values(errors).every((err) => err === false);
        setHasError(!noError);
    };

    useEffect(() => {
        checkHasError();
    }, [surveyFields]);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const survey: ISurvey = {
            SurveyType: SURVEY_TYPE.MONTHLY,
        };
        if (isPositiveRating()) {
            survey.SurveyData = {
                ...surveyFields,
                ImprovementSelected: [],
                ImprovementOthers: '',
            };
        } else {
            survey.SurveyData = {
                ...surveyFields,
                Feedback: '',
            };
        }

        const surveyResponse = await dispatch(createSurvey(survey));
        if ('error' in surveyResponse) {
            openGrowl(surveyResponse.payload as string);
        } else {
            onHandleClose();
        }
    };

    const handleClose = () => {
        const survey: ISurvey = {
            SurveyType: SURVEY_TYPE.MONTHLY,
            IsDismissed: true,
        };
        dispatch(createSurvey(survey));
        onHandleClose();
    };

    const FollowUpFormFields = isPositiveRating() ? (
        <>
            <Typography styleName="surveyLabel">Feedback</Typography>
            <TextField
                value={surveyFields.Feedback}
                placeholder="Optional"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                multiline
                styleName="textBox"
                name="Feedback"
            />
            <FormHelperText>{FORM_MAX_TEXT - surveyFields.Feedback.length} characters remaining</FormHelperText>
        </>
    ) : (
        <>
            <Typography styleName="surveyLabel">Tell us what we can improve on</Typography>
            <FormGroup styleName="checkBoxContainer">
                {Object.entries(SURVEY_IMPROVEMENTS).map(([key, value]) => (
                    <FormControlLabel
                        key={key}
                        control={
                            <Checkbox
                                color="primary"
                                onChange={handleCheckboxChange}
                                name="ImprovementSelected"
                                value={key}
                                styleName="checkBox"
                                {...(surveyFields.ImprovementSelected.includes(key)
                                    ? {checked: true}
                                    : {checked: false})}
                            />
                        }
                        label={value}
                    />
                ))}
                <TextField
                    value={surveyFields.ImprovementOthers}
                    placeholder="Indicate reason*"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    styleName="textBox"
                    name="ImprovementOthers"
                />
                <FormHelperText>
                    {FORM_MAX_TEXT - surveyFields.ImprovementOthers.length} characters remaining
                </FormHelperText>
            </FormGroup>
        </>
    );

    return (
        <React.Fragment>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogActions>
                    <CloseIcon styleName="closeIcon" onClick={handleClose} data-testid="closeFeedback" />
                </DialogActions>
                <DialogContent>
                    <Typography>
                        Hi <b>{userName},</b>
                    </Typography>
                    <br />
                    <Typography>Thank you for using OneCV.</Typography>
                    <br />
                    <Typography>
                        We want to do better for you. Share with us your experience using OneCV and help us improve.
                    </Typography>
                    <Divider styleName="divider" />

                    <Typography styleName="surveyLabel">
                        How would you rate the usefulness of OneCV for your work?
                    </Typography>
                    <RatingGroup name="UsefulnessRating" onChange={handleRatingChange} />

                    <Typography styleName="surveyLabel">
                        How would you rate your overall experience using OneCV?
                    </Typography>
                    <RatingGroup name="ExperienceRating" onChange={handleRatingChange} />

                    {surveyFields.ExperienceRating > 0 && FollowUpFormFields}

                    <div styleName="submitButtonContainer">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            styleName="submitButton"
                            disabled={hasError}
                        >
                            SEND FEEDBACK
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <ClientSnackBar
                {...{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
            />
        </React.Fragment>
    );
};
