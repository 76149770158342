import {Grid, Typography} from '@mui/material';
import React from 'react';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {formatCurrency} from '~/utils/currencyUtils';
import {formatDateField, formatValue, objNoResults} from '~/utils/contentUtils';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {NoData} from '../CompletedSubDomain';

export type IFoundationSupportData = IFoundationSupport[];

interface IFoundationSupport {
    InterventionType: string;
    BenefitType: string;
    MonthlyDisbursement: string;
    AssistanceStartDate: string;
    AssistanceEndDate: string;
}

const dataToFields = (data: IFoundationSupport) => {
    const {InterventionType, BenefitType, MonthlyDisbursement, AssistanceStartDate, AssistanceEndDate} = data;

    return [
        {
            label: 'Assistance Type',
            value: <Typography variant="h6">{InterventionType}</Typography>,
            fullWidth: true,
        },
        {label: 'Benefit Type', value: BenefitType},
        {
            label: 'Monthly Disbursement',
            value: formatValue(MonthlyDisbursement, parseFloat, formatCurrency, (val: string) => `$${val}`),
        },
        {
            label: 'Assistance Start Date',
            value: formatDateField(AssistanceStartDate, true, true),
        },
        {
            label: 'Assistance End Date',
            value: formatDateField(AssistanceEndDate, true, true),
        },
    ];
};

export const FoundationSupport: React.FunctionComponent<{data: IFoundationSupportData}> = ({data}) => {
    const sortedData = data
        .sort(
            (caseA, caseB) =>
                new Date(caseB.AssistanceStartDate).getTime() - new Date(caseA.AssistanceStartDate).getTime(),
        )
        .map(dataToFields);

    if (objNoResults(data)) {
        return <NoData />;
    }

    return (
        <>
            <Grid data-testid="foundationHeader" container spacing={3} style={{marginBottom: 10}}>
                <Grid item xs={12} container alignItems="center" style={{color: 'var(--primary-grey)'}}>
                    <InfoIcon data-testid="headerIcon" style={{paddingRight: 10}} />
                    <Typography data-testid="headerText" variant="body1" display="inline">
                        History records retrieved are assistance received in the last 2 years.
                    </Typography>
                </Grid>
            </Grid>
            <MultiGridDisplay
                dataTestId="foundationSupport"
                dataFieldGroups={sortedData}
                displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
            />
        </>
    );
};
