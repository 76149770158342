import React from 'react';
import {Button} from '@mui/material';
import {AddRounded as AddIcon} from '@mui/icons-material';

interface IAddButtonPrimary {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    text: string;
    size?: 'small' | 'medium' | 'large' | undefined;
    width?: number;
    height?: number;
    id?: string;
}

export const AddButtonPrimary = ({
    onClick,
    disabled = false,
    text,
    id,
    size = 'small',
    width = 176,
    height = 36,
}: IAddButtonPrimary) => {
    return (
        <Button
            id={id}
            size={size}
            variant="contained"
            color="primary"
            onClick={onClick}
            style={{height: height, width: width}}
            disabled={disabled}
        >
            <AddIcon style={{marginRight: 8}} />
            {text}
        </Button>
    );
};
