import {config} from '~/config';
import {IDataAccessMatrix, IDataAccessMatrixDomain, IDataAccessMatrixField, IDataSource} from '~/interfaces/admin';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {httpRequest} from './middleware';

export const getDataSources = async (): Promise<IDataSource[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-sources`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const createDataSource = async (dataSource: IDataSource): Promise<IDataSource> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-source`,
        data: JSON.stringify(dataSource),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const deleteDataSource = async (dataSourceId?: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-source/${dataSourceId}`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
};

export const editDataSource = async (dataSource: IDataSource) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-source`,
        data: JSON.stringify(dataSource),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
};

export const getDataFields = async (): Promise<IDataAccessMatrix> => {
    const response = await httpRequest(
        {
            url: `${config.api.ACCESS_CONTROL_SERVICE}/data-dictionary-fields`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        },
        true,
    );
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const getDataFieldsByDomainId = async (domainId: number): Promise<IDataAccessMatrixDomain> => {
    const response = await httpRequest(
        {
            url: `${config.api.ACCESS_CONTROL_SERVICE}/data-dictionary-fields/domains/${domainId}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        },
        true,
    );
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const updateDataField = async (dataDictionaryField: IDataAccessMatrixField): Promise<IDataAccessMatrixField> => {
    const body = {
        Name: dataDictionaryField.Name,
        Description: dataDictionaryField.Description,
        DataSourceId: dataDictionaryField.DataSourceId,
    };
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-dictionary-field/${dataDictionaryField.Id}`,
        data: JSON.stringify(body),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return dataDictionaryField;
};

export const getSubdomainsWithNewFields = async (): Promise<number[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/subdomains/new-fields`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};
