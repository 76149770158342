import React, {useState} from 'react';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ClickAwayListener, TextField} from '@mui/material';
import {oneCVStartDay} from '~/constants';

export interface DateInputProps {
    label?: string;
    className?: string;
    value: unknown;
    dateFormat: string;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date | null;
    onChange: (date: unknown) => void;
    hasError?: (hasError: boolean) => void;
    allowFutureDate?: boolean;
    selectOnly?: boolean;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    smallSize?: boolean;
    error?: boolean;
    helperText?: string;
    selectByMonth?: boolean;
    storeReportWithErrors?: () => void;
    removeReportFromErrors?: () => void;
}

export const DateInput = ({
    label,
    className,
    value,
    dateFormat,
    disabled = false,
    minDate = new Date(oneCVStartDay),
    maxDate,
    onChange,
    hasError,
    allowFutureDate = false,
    selectOnly = false,
    open,
    setOpen,
    smallSize = false,
    error,
    helperText,
    selectByMonth = false,
    storeReportWithErrors = undefined,
    removeReportFromErrors = undefined,
}: DateInputProps) => {
    const [errMsg, setErrMsg] = useState('');
    const [showErr, setShowErr] = useState(false);

    const [openPicker, setOpenPicker] = useState(false);

    const handleChange = (value: unknown) => {
        const preProcessedDate = value as Date;
        if (selectByMonth) {
            const data = new Date(preProcessedDate.getFullYear(), preProcessedDate.getMonth());
            if ((maxDate && data > maxDate) || (minDate && data < minDate)) {
                return;
            }
            onChange(data);
        } else {
            onChange(preProcessedDate);
        }
        setShowErr(false);
        setErrMsg('');
        if (hasError) hasError(false);
        if (open && setOpen) setOpen(false);
    };

    const handleClickAway = () => {
        setOpenPicker(false);
    };

    const validateText = (value: string) => {
        if (!value) {
            return;
        }

        if (value.includes('/')) {
            const splitDate = value.split('/');
            if (
                splitDate[2].length < 4 ||
                splitDate[2].length > 4 ||
                splitDate[0].length > 2 ||
                splitDate[1].length > 2
            ) {
                setShowErr(true);
                setErrMsg('Please enter a valid date');
                if (hasError) hasError(true);
                return;
            }
            const validDateValue = new Date(parseInt(splitDate[2]), parseInt(splitDate[1]) - 1, parseInt(splitDate[0]));
            if (!allowFutureDate && validDateValue > new Date()) {
                setShowErr(true);
                setErrMsg('You cannot enter a future date');
                if (hasError) hasError(true);
            } else if (validDateValue < minDate) {
                setShowErr(true);
                setErrMsg('You cannot enter a earlier date');
                if (hasError) hasError(true);
            } else if (value) {
                setShowErr(false);
                setErrMsg('');
                if (hasError) hasError(false);
                onChange(validDateValue);
            } else {
                setShowErr(false);
                setErrMsg('');
                if (hasError) hasError(true);
            }
        } else {
            setShowErr(true);
            setErrMsg('Please enter a valid date');
            if (hasError) hasError(true);
            return;
        }
    };

    const validateMonthYearText = (value: Date | null) => {
        const preProcessedDate = value as Date;
        const formattedDate = new Date(preProcessedDate.getFullYear(), preProcessedDate.getMonth());

        if (!allowFutureDate && maxDate && formattedDate > maxDate) {
            setShowErr(true);
            setErrMsg('You cannot select a future date');
            storeReportWithErrors && storeReportWithErrors();
        } else if (!allowFutureDate && minDate && formattedDate < minDate) {
            setShowErr(true);
            setErrMsg('You cannot select a date before Aug 2022');
            storeReportWithErrors && storeReportWithErrors();
        } else {
            removeReportFromErrors && removeReportFromErrors();
            setShowErr(false);
            setErrMsg('');
        }
    };

    if (selectByMonth) {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    open={open}
                    disableFuture
                    openTo="year"
                    views={['year', 'month']}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onAccept={handleChange}
                    onChange={validateMonthYearText}
                    inputFormat={dateFormat}
                    InputAdornmentProps={{
                        onClick: () => {
                            setOpen && setOpen(true);
                        },
                    }}
                    OpenPickerButtonProps={{
                        style: {padding: '11px 12px 11px 0px'},
                    }}
                    renderInput={(params) => (
                        <TextField
                            onClick={() => {
                                setOpen && setOpen(true);
                            }}
                            style={{position: 'relative', marginBottom: '16px'}}
                            size={smallSize ? 'small' : 'medium'}
                            {...params}
                            className={className}
                            variant="outlined"
                            fullWidth
                            helperText={errMsg}
                            FormHelperTextProps={{
                                style: {
                                    margin: '0',
                                    paddingTop: '8px',
                                    color: '#D60025',
                                    whiteSpace: 'nowrap',
                                    position: 'absolute',
                                    bottom: '-20px',
                                },
                            }}
                            error={showErr}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {selectOnly ? (
                <DatePicker
                    open={open}
                    label=""
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onAccept={handleChange}
                    onChange={() => true}
                    inputFormat="dd/MM/yyyy"
                    InputAdornmentProps={{
                        onClick: () => {
                            setOpen && setOpen(true);
                        },
                    }}
                    OpenPickerButtonProps={{
                        style: {padding: '11px 12px 11px 0px'},
                    }}
                    renderInput={(params) => (
                        <TextField
                            onClick={() => {
                                setOpen && setOpen(true);
                            }}
                            size={smallSize ? 'small' : 'medium'}
                            {...params}
                            error={false}
                            className={className}
                            variant="outlined"
                            fullWidth
                            helperText={''}
                            FormHelperTextProps={{style: {margin: '0', paddingTop: '8px', color: '#D60025'}}}
                            placeholder="dd/mm/yyyy"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    )}
                />
            ) : (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <DatePicker
                            open={openPicker}
                            label={label}
                            value={value}
                            minDate={minDate}
                            maxDate={maxDate ? maxDate : undefined}
                            onChange={() => undefined}
                            inputFormat={dateFormat}
                            disabled={disabled}
                            disableMaskedInput
                            disableFuture={!allowFutureDate}
                            onAccept={(date: Date | null) => {
                                setOpenPicker(false);
                                handleChange(date);
                            }}
                            InputAdornmentProps={{
                                onClick: () => {
                                    setOpenPicker(true);
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={className}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errMsg || helperText}
                                    error={showErr || error}
                                    onBlur={(e) => validateText(e.target.value)}
                                    placeholder="dd/MM/yyyy"
                                    FormHelperTextProps={{style: {margin: '0', paddingTop: '8px', color: '#D60025'}}}
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            )}
        </LocalizationProvider>
    );
};
