import React from 'react';
import {Typography, Grid, Divider} from '@mui/material';
import styles from './Common.scss';
import {SubDomainStatus} from '~/interfaces/client';
import {ErrorSubDomain} from '../../ErrorSubDomain';
import {PendingSubDomain} from '../../PendingSubDomain';
import {NoData} from '../../CompletedSubDomain';
import {allNoAccess, allNoResults} from '~/utils/contentUtils';

export const SFTP_SSNET_HDB = 'SFTP_SSNET_HDB';
export const SFTP_DOS = 'SFTP_DOS';

export enum DividerPosition {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
}

export const Subsection = ({
    children,
    title,
    status = SubDomainStatus.ERROR,
    withDivider = true,
    fieldList,
    dividerPosition = DividerPosition.BOTTOM,
    'data-testid': dataTestId,
}: {
    children?: React.ReactNode;
    title: string;
    status?: SubDomainStatus;
    withDivider?: boolean;
    fieldList: string[];
    dividerPosition?: string;
    'data-testid'?: string;
}) => {
    return (
        <>
            {status === SubDomainStatus.COMPLETED ? (
                !allNoAccess(fieldList) &&
                fieldList.length > 0 && (
                    <>
                        {withDivider && dividerPosition == DividerPosition.TOP && (
                            <Grid data-testid="dividerTop" item xs={12}>
                                <Divider
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 20,
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid data-testid={`${dataTestId}_subsection`} item xs={12}>
                            <Typography data-testid="title" variant="h6">
                                {title}
                            </Typography>
                            <Grid data-testid="content" id={styles.subsectionContent}>
                                {status === SubDomainStatus.COMPLETED ? (
                                    <>{!allNoResults(fieldList) && children ? children : <NoData />}</>
                                ) : status === SubDomainStatus.PENDING ? (
                                    <PendingSubDomain data-testid="pendingSubdomain" includeHeader={false} />
                                ) : (
                                    <ErrorSubDomain data-testid="errorSubdomain" includeHeader={false} />
                                )}
                            </Grid>
                        </Grid>
                        {withDivider && dividerPosition == DividerPosition.BOTTOM && (
                            <Grid data-testid="dividerBottom" item xs={12}>
                                <Divider
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 20,
                                    }}
                                />
                            </Grid>
                        )}
                    </>
                )
            ) : (
                <Grid data-testid={`${dataTestId}_subsection`} item xs={12}>
                    <Typography data-testid="title" variant="h6">
                        {title}
                    </Typography>
                    <Grid data-testid="content" id={styles.subsectionContent}>
                        {status === SubDomainStatus.PENDING ? (
                            <PendingSubDomain data-testid="pendingSubdomain" includeHeader={false} />
                        ) : (
                            <ErrorSubDomain data-testid="errorSubdomain" includeHeader={false} />
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};
