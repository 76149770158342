import React, {useEffect, useRef, useState} from 'react';
import {Box, Chip, Divider, Grid, Typography, Paper, Tooltip, Popper, Fade} from '@mui/material';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import './ConsentAndAccessHeader.scss';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {ToolTipModalPopper} from './ToolTipModalPopper';
import {USER_FLAGS} from '~/constants';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {updateUserFlags} from '~/store/userFlags/userFlags.thunk';

export interface IConsentAndAccessHeaderProps {
    flagName: USER_FLAGS;
    title: string;
    subTitle: string;
    onDeleteSubDomain: (item: IRowData) => void;
    selectedSubDomains: IRowData[];
    skipConsentIndicator?: boolean;
}

export const ConsentAndAccessHeader = ({
    flagName,
    title,
    subTitle,
    onDeleteSubDomain,
    selectedSubDomains,
}: IConsentAndAccessHeaderProps) => {
    const dispatch = useAppDispatch();
    const inputEl = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const consentOrAccessTrigger = useSelector(getConsentOrAccessTrigger(flagName));
    const modalTrigger = useTypedSelector((state) => state.data.common.userFlags[flagName]);
    const [consentOrAccessTrigger, setConsentOrAccessTrigger] = useState(modalTrigger);
    const ref = React.createRef<HTMLDivElement>();

    const onClose = () => {
        if (!modalTrigger) {
            dispatch(updateUserFlags(flagName));
        }
        setConsentOrAccessTrigger(true);
    };

    useEffect(() => {
        setAnchorEl(inputEl.current);
    }, [inputEl]);

    function handleOpenToolTip(e: React.MouseEvent<HTMLElement>) {
        setAnchorEl(e.currentTarget);
        setConsentOrAccessTrigger(false);
    }
    return (
        <div styleName="consentAndAccessHeader">
            <Grid container>
                <Grid item>
                    <Typography variant="h4">{title}</Typography>
                </Grid>
                <Grid>
                    <Tooltip title="" styleName="tooltip" onClick={handleOpenToolTip} ref={inputEl}>
                        <InfoIcon styleName="infoLabel" />
                    </Tooltip>
                    <Popper
                        open={!consentOrAccessTrigger}
                        anchorEl={anchorEl}
                        placement="right-start"
                        transition
                        disablePortal
                        style={{zIndex: 10}}
                    >
                        {({TransitionProps}) => (
                            <Fade {...TransitionProps}>
                                <ToolTipModalPopper ref={ref} {...{displayType: title, onClose}} />
                            </Fade>
                        )}
                    </Popper>
                </Grid>
                <Grid item xs={12} styleName="divider">
                    <Divider />
                </Grid>
            </Grid>
            <Paper id="consentAndAccessHeader" elevation={2} style={{padding: '24px', marginTop: '32px'}} square>
                <Typography variant="subtitle1">{subTitle}</Typography>
                <Box component="div" styleName="selectedDomainContainer">
                    {selectedSubDomains.map((item) => (
                        <Chip
                            label={item.subDomainName}
                            onDelete={() => onDeleteSubDomain(item)}
                            key={item.subDomainName}
                            color="primary"
                            styleName="chip"
                        />
                    ))}
                </Box>
            </Paper>
        </div>
    );
};
