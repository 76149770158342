import React from 'react';
import {Divider, Grid, Typography} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {getTime} from 'date-fns';
import {ISubDomainFieldDisplay} from '~/interfaces/common';
import {CollapsibleCard} from '~/components/Common/Cards/CollapsibleCard';
import {SubDomainStatus} from '~/interfaces/client';
import {DataNotFound} from '../SubSectionStatusWrapper';
import {FIELD_VALUES, SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {PendingSubDomain} from '../PendingSubDomain';
import {ErrorSubDomain} from '../ErrorSubDomain';
import styles from './DSLSCases.scss';
import {isNoAccess, isNotEmpty, objNoAccess, objNoResults} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type IDSLSCasesData = IDSLSCase[];

interface IDSLSCase {
    CurrentStatus: string;
    Timeline: TimelineItem[];
    CaseNumber: string;
    CaseProfileLastUpdatedDate: string;
    CaseProfileLastUpdatedBy: string;
    AssignedServiceProvider: string;
    AssignedOfficerSPCaseworkerLSED: string;
    AssignedOfficerSPCaseworkerTherapist: string;
    AssignedOfficerSPCaseworkerSW: string;
    ServiceProviderTherapyTeam: string;
    ProgrammeFlaggedDate: string;
    PreschoolCentreName: string;
    PrimaryCaregiverName: string;
    PrimaryCaregiverRelationship: string;
    SecondaryCaregiverName: string;
    SecondaryCaregiverRelationship: string;
    RecommendedDSStatus: IRecommendedDSStatus;
    RecommendedLSStatus: IRecommendedLSStatus;
}

export type TimelineItem = Map<string, string>;

export interface IRecommendedStatus {
    DateOfOccurrence: string;
}

type IRecommendedLSStatus = IRecommendedStatus;
type IRecommendedDSStatus = IRecommendedStatus;

export const SectionContent = ({fields}: {fields: ISubDomainFieldDisplay[]}) => (
    <Grid container spacing={2}>
        <SimpleGridDisplay fields={fields} />
    </Grid>
);

const sectionHasContent = (content: ISubDomainFieldDisplay[]) =>
    content && content.filter((item) => item.value === FIELD_VALUES.NO_RESULTS).length !== content.length;

const getProgramme = (dslsCase: IDSLSCase) => {
    if (!objNoAccess(dslsCase.RecommendedDSStatus) && !objNoResults(dslsCase.RecommendedDSStatus))
        return 'Development Support';
    if (!objNoAccess(dslsCase.RecommendedLSStatus) && !objNoResults(dslsCase.RecommendedLSStatus))
        return 'Learning Support';
    if (objNoResults(dslsCase.RecommendedDSStatus) && objNoResults(dslsCase.RecommendedLSStatus)) {
        return FIELD_VALUES.NO_RESULTS;
    } else {
        return FIELD_VALUES.NO_ACCESS;
    }
};

const CaseContent = ({dslsCase, 'data-testid': dataTestId}: {dslsCase: IDSLSCase; 'data-testid'?: string}) => {
    const generic = [
        {
            label: 'Case Number',
            value: isNotEmpty(dslsCase.CaseNumber) ? (
                <Typography variant="h6">{dslsCase.CaseNumber}</Typography>
            ) : (
                dslsCase.CaseNumber
            ),
        },
        {
            label: 'Last Update',
            value:
                isNoAccess(dslsCase.CaseProfileLastUpdatedDate) && isNoAccess(dslsCase.CaseProfileLastUpdatedBy)
                    ? dslsCase.CaseProfileLastUpdatedDate
                    : isNotEmpty(dslsCase.CaseProfileLastUpdatedDate) || isNotEmpty(dslsCase.CaseProfileLastUpdatedBy)
                    ? `${dslsCase.CaseProfileLastUpdatedDate} | ${dslsCase.CaseProfileLastUpdatedBy}`
                    : dslsCase.CaseProfileLastUpdatedDate,
        },
        {
            label: 'Status',
            value: dslsCase.CurrentStatus === 'Started Enrolment' ? 'Pending Enrolment' : dslsCase.CurrentStatus,
        },
        {
            label: 'Programme',
            value: getProgramme(dslsCase),
        },
    ];

    const timeline = dslsCase.Timeline
        ? dslsCase.Timeline.map((statusAndDate: TimelineItem) => {
              const label = Object.keys(statusAndDate)[0];
              return {
                  label: label ? label : '',
                  value: label && Object.values(statusAndDate)[0],
              };
          })
        : [
              {
                  label: '',
                  value: FIELD_VALUES.NO_RESULTS,
              },
          ];

    const referral = [
        {
            label: 'Preschool',
            value: dslsCase.PreschoolCentreName,
        },
        {
            label: 'Programme Flagged Date',
            value: dslsCase.ProgrammeFlaggedDate,
        },
    ];

    const support = [
        {
            label: 'Service Provider',
            value: dslsCase.AssignedServiceProvider,
        },
        {
            label: 'Therapy Team',
            value: dslsCase.ServiceProviderTherapyTeam,
        },
        {
            label: 'Caseworker (Learning Support Educator)',
            value: dslsCase.AssignedOfficerSPCaseworkerLSED,
        },
        {
            label: 'Caseworker (Therapist)',
            value: dslsCase.AssignedOfficerSPCaseworkerTherapist,
        },
        {
            label: 'Caseworker (Social Worker)',
            value: dslsCase.AssignedOfficerSPCaseworkerSW,
        },
    ];

    const caregiver = [
        {
            label: 'Name of Primary Caregiver',
            value: dslsCase.PrimaryCaregiverName,
        },
        {
            label: 'Relationship of Primary Caregiver',
            value: dslsCase.PrimaryCaregiverRelationship,
        },
        {
            label: 'Name of Secondary Caregiver',
            value: dslsCase.SecondaryCaregiverName,
        },
        {
            label: 'Relationship of Secondary Caregiver',
            value: dslsCase.SecondaryCaregiverRelationship,
        },
    ];

    const CompulsorySection = (
        <>
            {sectionHasContent(generic) && (
                <Subsection
                    data-testid="sectionGeneric"
                    title=""
                    hasData={true}
                    status={SubDomainStatus.COMPLETED}
                    withDivider={false}
                >
                    <SectionContent fields={generic} />
                </Subsection>
            )}
        </>
    );

    return !objNoAccess(dslsCase) ? (
        objNoResults(dslsCase) ? (
            <NoData />
        ) : (
            <CollapsibleCard compulsorySection={CompulsorySection} dataTestId={dataTestId}>
                {sectionHasContent(timeline) && (
                    <Subsection
                        data-testid="sectionTimeline"
                        title="Timeline"
                        hasData={true}
                        status={SubDomainStatus.COMPLETED}
                    >
                        <SectionContent fields={timeline} />
                    </Subsection>
                )}
                {sectionHasContent(referral) && (
                    <Subsection
                        data-testid="sectionReferral"
                        title="Referral Details"
                        hasData={true}
                        status={SubDomainStatus.COMPLETED}
                    >
                        <SectionContent fields={referral} />
                    </Subsection>
                )}
                {sectionHasContent(support) && (
                    <Subsection
                        data-testid="sectionSupport"
                        title="Support Details"
                        hasData={true}
                        status={SubDomainStatus.COMPLETED}
                    >
                        <SectionContent fields={support} />
                    </Subsection>
                )}
                {sectionHasContent(caregiver) && (
                    <Subsection
                        data-testid="sectionCaregiver"
                        title="Caregiver Information"
                        hasData={true}
                        status={SubDomainStatus.COMPLETED}
                    >
                        <SectionContent fields={caregiver} />
                    </Subsection>
                )}
            </CollapsibleCard>
        )
    ) : null;
};

export const DSLSCases: React.FunctionComponent<{data: IDSLSCasesData}> = ({data}) => {
    const sortedCases = data.sort(
        (caseA, caseB) =>
            getTime(new Date(caseB.CaseProfileLastUpdatedDate)) - getTime(new Date(caseA.CaseProfileLastUpdatedDate)),
    );

    return (
        <>
            {sortedCases.map((dslsCase: IDSLSCase, key: number) => (
                <CaseContent data-testid={`case-content${key}`} key={key} dslsCase={dslsCase} />
            ))}
        </>
    );
};

// Temporarily added before migration to common subsection for no data/access
const Subsection = ({
    children,
    title,
    hasData,
    status = SubDomainStatus.ERROR,
    withDivider = true,
    'data-testid': dataTestId,
}: {
    children?: React.ReactNode;
    title: string;
    hasData?: boolean;
    status?: SubDomainStatus;
    withDivider?: boolean;
    'data-testid'?: string;
}) => {
    return (
        <>
            <Grid data-testid={dataTestId} item xs={12}>
                {withDivider && (
                    <Grid data-testid="divider" item xs={12}>
                        <Divider
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                            }}
                        />
                    </Grid>
                )}
                <Typography data-testid="title" variant="h6">
                    {title}
                </Typography>
                <Grid data-testid="content" id={styles.subsectionContent}>
                    {status === SubDomainStatus.COMPLETED ? (
                        <>
                            {hasData && children ? (
                                children
                            ) : (
                                <DataNotFound text={SUBDOMAIN_RESULT_MESSAGE.EMPTY} data-testid="nodata" />
                            )}
                        </>
                    ) : status === SubDomainStatus.PENDING ? (
                        <PendingSubDomain data-testid="pendingSubdomain" includeHeader={false} />
                    ) : (
                        <ErrorSubDomain data-testid="errorSubdomain" includeHeader={false} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
