import React, {StrictMode} from 'react';
import {Route, Switch} from 'react-router';
import {store, history} from '~/store';
import {persistStore} from 'redux-persist';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ConnectedRouter} from 'connected-react-router';
import {privateRoutes, publicRoutes} from '~/router';
import {IdleTimerContainer} from '~/components/Common/IdleTimerContainer/IdleTimerContainer';
import {Navbar} from '~/components/Navbar/Navbar';
import {Footer} from '~/components/Footer/Footer';
import {PrivateRoute} from '~/utils/privateRoute';
import {AuthenticationProvider} from '~/utils/contexts/authentication/authenticationProvider';
import {config} from '~/config';
import {Page} from './Page';
import {PageNotFound} from '~/pages/PageNotFound/PageNotFound';
import {ThemeProvider} from '@mui/material/styles';
import {appTheme} from '~/styles/themes/AppTheme';
import {HtmlHead} from './components/Common/HtmlHead/HtmlHead';
import {SystemMaintenance} from './pages/SystemMaintenance/SystemMaintenance';
import './styles.scss';
import {idleTimeout} from '~/constants';

// Reset scroll position upon navigating to new pages within app
history.listen(() => {
    window.scrollTo(0, 0);
});
export class AppContainer extends React.Component {
    public render() {
        const persistor = persistStore(store);
        return (
            <>
                <HtmlHead />
                <ThemeProvider theme={appTheme}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <StrictMode>
                                <AuthenticationProvider persistor={persistor}>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <Navbar />

                                        <IdleTimerContainer
                                            firstTimeoutInSecs={idleTimeout.FIRST_IDLE_TIMEOUT_IN_MINS * 60}
                                            secondTimeoutInSecs={idleTimeout.SECOND_IDLE_TIMEOUT_IN_MINS * 60}
                                        />
                                        <Page>
                                            {config.maintenance ? (
                                                <SystemMaintenance />
                                            ) : (
                                                <Switch>
                                                    {publicRoutes.map((route, id) => (
                                                        <Route key={id} {...route} />
                                                    ))}
                                                    {privateRoutes.map((route, id) => (
                                                        <PrivateRoute key={id} {...route} />
                                                    ))}
                                                    <Route component={PageNotFound} />
                                                </Switch>
                                            )}
                                        </Page>
                                        {!config.maintenance && <Footer />}
                                    </PersistGate>
                                </AuthenticationProvider>
                            </StrictMode>
                        </ConnectedRouter>
                    </Provider>
                </ThemeProvider>
            </>
        );
    }
}
