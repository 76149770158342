import {Box, Grid, TextField, Typography} from '@mui/material';
import React, {useState, useRef, useContext} from 'react';
import {RichTextEditor} from '~/components/Admin/MassEmail/RichTextEditor';
import {postMassEmail} from '~/services/apiServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {ACCESS_LEVELS_ROLE, ERROR_MESSAGE} from '~/constants';
import {EmailFooter} from '~/components/Admin/MassEmail/EmailFooter';
import {Attachment} from '~/components/Admin/MassEmail/Attachment';
import {AddRecipient} from '~/components/Admin/MassEmail/AddRecipient';
import {IRecipientGroup} from '~/interfaces/admin';
import {RecipientList} from '~/components/Admin/MassEmail/RecipientList';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {isLeadSystemAdmin, isSystemAdmin} from '~/utils/roleUtils';
import {useSelectOptions} from '~/components/Admin/MassEmail/useSelectOptions';
import ErrorIcon from '@mui/icons-material/Error';
import './MassEmail.scss';

const maxAttachmentSize = 10485760;
const errorMessage = 'File size is too big. Please ensure the total file size is kept below 10MB.';

export const MassEmail = () => {
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const headerRef = useRef<HTMLDivElement>(null);

    const auth = useContext(AuthenticationContext);
    const accessToken = auth.accessToken as IAccessToken;

    const {getAdminGroupType} = useSelectOptions(accessToken);

    const [recipientGroups, setRecipientGroups] = useState<IRecipientGroup[]>([]);
    const [subject, setSubject] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [embedImages, setEmbedImages] = useState<File[]>([]);
    const [error, setError] = useState<string>('');
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const [sendLoading, setSendLoading] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const handleSubjectChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSubject(e.target.value);
    };

    const handleContentChange = (htmlContent: string) => {
        setContent(htmlContent);
        detectRemoveImages(htmlContent);
    };

    const validateFiles = (files: File[]): boolean => {
        const embedSize = embedImages.reduce((accSize: number, file) => accSize + file.size, 0);
        const filesSize = files.reduce((accSize: number, file) => accSize + file.size, 0);
        const totalSize = embedSize + filesSize;
        if (totalSize >= maxAttachmentSize) {
            setError(errorMessage);
            return false;
        }
        setError('');
        return true;
    };

    const isNotDuplicateFile = (file: File, files: File[]): boolean =>
        !files.find((currFile) => currFile.name === file.name);

    const handleAttachFiles = (filesInput: File[]) => {
        const filesToAdd = filesInput.filter((file) => isNotDuplicateFile(file, files));
        const newFiles = [...files, ...filesToAdd];

        if (validateFiles(newFiles)) {
            setFiles(newFiles);
        }
    };

    const handleRemoveFile = (fileToRemove: File) => {
        const newFiles = files.filter((file) => file.name !== fileToRemove.name);
        setFiles(newFiles);
    };

    const handleEmbedImage = (file: File) => {
        const newFiles = [...embedImages, file];

        if (validateFiles(newFiles)) {
            setEmbedImages(newFiles);
        }
    };

    const detectRemoveImages = (htmlContent: string) => {
        const newEmbededImages = embedImages.filter((file) => htmlContent.includes(file.name));
        setEmbedImages(newEmbededImages);
    };

    const formatContent = (htmlContent: string) => {
        const lines = htmlContent.split('\n');
        const formatted = lines
            .map((line) => {
                if (line.includes('</img>')) {
                    const alignment = line.split('id="')[1].split('"')[0];
                    const src = line.split('src="')[1].split('"')[0];
                    return `
                    <table width="100%">
                        <tr>
                            <td valign="top" align="${alignment}">
                                <img src="${src}" align="${alignment}"/>
                            </td>
                        </tr>
                    </table>
            `;
                }
                return line;
            })
            .join('\n');

        return `
        <body>
            ${formatted}
        </body>`;
    };

    const handleSendEmail = (test: boolean) => {
        test ? setTestLoading(true) : setSendLoading(true);
        const data = new FormData();
        data.append('RecipientGroups', JSON.stringify(recipientGroups));
        data.append('Subject', subject);
        data.append('Content', formatContent(content));
        for (const file of files) {
            data.append('Attachments', file, file.name);
        }
        for (const image of embedImages) {
            data.append('Attachments', image, image.name);
        }

        postMassEmail(data, test)
            .then(() => {
                const message = test ? 'Test email successfully sent to your inbox' : 'Mass email successfully sent';
                openGrowl(message);
                test ? setTestLoading(false) : setSendLoading(false);
            })
            .catch(() => {
                openGrowl(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
                test ? setTestLoading(false) : setSendLoading(false);
            });
    };

    const isNotDuplicateRecipientGroup = (recipientGroup: IRecipientGroup): boolean =>
        !recipientGroups.find((group) => group.Id === recipientGroup.Id);

    const handleAddRecipientGroups = (recipientGroupsToAdd: IRecipientGroup[]) => {
        const toAdd = recipientGroupsToAdd.filter(isNotDuplicateRecipientGroup);
        const newRecipientGroups = [...recipientGroups, ...toAdd];
        setRecipientGroups(newRecipientGroups);
    };

    const handleDeleteRecipientGroup = (recipientGroup: IRecipientGroup) => {
        const newRecipientGroups = recipientGroups.filter((group) => group.Id !== recipientGroup.Id);
        setRecipientGroups(newRecipientGroups);
    };

    const buttonDisabled = () => recipientGroups.length === 0 || !subject || content === '<p><br></p>';

    const handleConfirmSendMassEmail = () => {
        handleSendEmail(false);
        setConfirmOpen(false);
    };

    const getConfirmationTitle = () => {
        // UserGroups: 'All' | 'Agency Administrators' | 'Ops Unit Administrators' | 'Team Administrators' | 'Users';
        const hasAdmins = recipientGroups.some(
            (group) => group.UserGroup.includes('Administrators') || group.UserGroup.includes('All'),
        );
        const hasUsers = recipientGroups.some(
            (group) => group.UserGroup.includes('Users') || group.UserGroup.includes('All'),
        );

        let userGroup = '';
        if (hasAdmins && hasUsers) {
            userGroup = 'users and administrators';
        } else if (hasUsers) {
            userGroup = 'users';
        } else if (hasAdmins) {
            userGroup = 'administrators';
        }
        return `You are sending a mass email to OneCV ${userGroup}.`;
    };

    const getConfirmationContent = () => {
        const userRole = ACCESS_LEVELS_ROLE[accessToken.Permissions.AccessLevel];
        const baseMessage = `Duplicate emails within the recipient groups will be removed. A copy of this email will also be sent to other ${userRole}s`;
        if (accessToken) {
            if (isLeadSystemAdmin(accessToken) || isSystemAdmin(accessToken)) {
                return `${baseMessage}.`;
            } else {
                return `${baseMessage} in your ${getAdminGroupType()}.`;
            }
        }
        return 'Duplicate emails within the recipient groups will be removed.';
    };

    return (
        <div styleName="massEmail">
            <Typography variant="h4" ref={headerRef}>
                Mass Email
            </Typography>

            <AddRecipient onAddRecipientGroups={handleAddRecipientGroups} />

            <RecipientList recipientGroups={recipientGroups} onDelete={handleDeleteRecipientGroup} limit={3} />

            <TextField
                variant="outlined"
                placeholder="Subject"
                styleName="subjectField"
                value={subject}
                onChange={handleSubjectChange}
            />

            <RichTextEditor
                onChange={handleContentChange}
                onAttachFiles={handleAttachFiles}
                onEmbedImage={handleEmbedImage}
            />
            <Grid container styleName="attachmentTextContainer">
                <Grid item xs={12}>
                    {files.map((file) => (
                        <Attachment key={file.name} file={file} onRemove={handleRemoveFile} />
                    ))}
                </Grid>
                <Grid item xs={7}>
                    <Box display="flex" alignItems="center" width={'fill'}>
                        {error ? (
                            <>
                                <ErrorIcon styleName="errorIcon" />
                                <Typography styleName="errorText">{error}</Typography>
                            </>
                        ) : (
                            <Typography></Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography styleName="fileIndicator">
                        The total size of all files attached should be less than 10MB.
                    </Typography>
                </Grid>
            </Grid>

            <ConfirmDialog
                onCancel={() => setConfirmOpen(false)}
                onConfirm={handleConfirmSendMassEmail}
                open={confirmOpen}
                title={getConfirmationTitle()}
                message={getConfirmationContent()}
                cancelOption="Cancel"
                confirmOption="Ok"
            />

            <EmailFooter
                buttonsDisabled={buttonDisabled()}
                primaryButtonText="SEND"
                secondaryButtonText="TEST"
                handlePrimary={() => setConfirmOpen(true)}
                handleSecondary={() => handleSendEmail(true)}
                primaryLoading={sendLoading}
                secondaryLoading={testLoading}
            />

            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={headerRef}
            />
        </div>
    );
};
