import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
    Checkbox,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useStore} from 'react-redux';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IDataAccessMatrixField, IDataFieldGroup, IRequestWindow} from '~/interfaces/admin';
import {DataRestrictions} from '~/services/dataAccessMatrixService';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {formatDateddmmmyyyy} from '~/utils/dateUtils';
import './SubdomainDataFieldsTable.scss';
export const subtextStyle = {fontSize: '0.8em', color: '#606060'};

export interface RowData {
    fieldId: number;
    name: string;
    newField: boolean;
    fieldGroup?: IDataFieldGroup;
    access?: boolean;
    status?: string;
    lastUpdatedBy?: string;
    lastUpdatedOn?: string;
    userRole?: string;
    remarks?: string | null;
}

function transformDate(date: string) {
    if (date === '-' || new Date(date).toISOString().endsWith('T00:00:00.000Z')) {
        return '';
    }
    return formatDateddmmmyyyy(date);
}

function evaluateNewField(createdDate: string, latestClosedWindowEndDate: string): boolean {
    if (createdDate === '') {
        return false;
    }
    const createdDateObj = new Date(createdDate);
    const latestClosedWindowEndDateObj = new Date(latestClosedWindowEndDate);
    return createdDateObj > latestClosedWindowEndDateObj;
}

/*
props:
    ops-unit-id: self explanatory
    dataFields: list of all datafields for the subdomain
    subDomainRequestStateData: request state of the fields, reasons for access
*/
const SubdomainDataFieldsTable = (props: {
    opsUnitId: number;
    subdomainId: number;
    dataFields: IDataAccessMatrixField[];
    subDomainRequestStateData: OpsUnitSubdomainRequest;
    activeWindow: boolean;
    handleAddFields: () => void;
    handleFieldsUpdated: () => void;
    opsUnitDataFields: {[key: number]: number};
    requestedFields: number[];
    restrictions: DataRestrictions;
    latestClosedWindow: IRequestWindow;
}) => {
    const dispatch = useAppDispatch();

    const {
        dataFields,
        subDomainRequestStateData,
        handleAddFields,
        handleFieldsUpdated,
        requestedFields,
        opsUnitDataFields,
        restrictions,
        latestClosedWindow,
    } = props;
    const [fieldsUnderReview, setFieldsUnderReview] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [rows, setRows] = useState<RowData[]>([]);
    const [groups, setGroups] = useState<Map<string, RowData[]>>(new Map<string, RowData[]>());
    const formState = useTypedSelector((state) => state.data.dam2.domainDataRequestState.FormMeta.State);
    const {accessToken} = useContext(AuthenticationContext);
    const isOA: boolean =
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministrator' ||
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministratorappuser' ||
        false;
    const store = useStore();
    const dataFieldIds = dataFields.map((field) => field.Id);
    const [isIntermediate, setIsIntermediate] = useState<boolean>(false);
    const [isCheck, setIsCheck] = useState<boolean>(false);
    const [dataFieldIdsToSelect, setDataFieldIdsToSelect] = useState<number[]>([]);

    useEffect(() => {
        const rowData = combineArrays(props.dataFields, props.subDomainRequestStateData.Fields, opsUnitDataFields);
        const {rows, groups} = createTableData(rowData);
        setRows(rows);
        setGroups(groups);
        let initialSelectedRows: number[] = [];
        if (formState.toLocaleLowerCase() === 'saved as draft') {
            const subdomainFieldIds = subDomainRequestStateData.Fields?.map((field) => field.FieldId) || [];
            const requestedFields: number[] = store.getState().data.dam2.domainDataRequestState.RequestedFields;
            if (requestedFields) {
                initialSelectedRows = requestedFields.filter((field) => subdomainFieldIds.includes(field)) || [];
            } else {
                initialSelectedRows = [];
            }
        } else {
            initialSelectedRows = rowData.filter((row) => row.access).map((row) => row.fieldId);
        }

        if (requestedFields) {
            dataFields.forEach((df) => {
                if (requestedFields.includes(df.Id) && !initialSelectedRows.includes(df.Id)) {
                    initialSelectedRows.push(df.Id);
                }
            });
        }
        const initFieldsUnderReview = props.subDomainRequestStateData.Fields.filter(
            (field) => field.State.toLowerCase() == 'sent for review',
        ).map((field) => {
            if (!initialSelectedRows.includes(field.FieldId)) {
                initialSelectedRows.push(field.FieldId);
            }
            return field.FieldId;
        });
        setFieldsUnderReview(initFieldsUnderReview);
        setSelectedRows([...initialSelectedRows]);
        const dataFieldIdsToSelect = dataFieldIds.filter((id) => restrictions?.Fields.indexOf(id) === -1);
        setDataFieldIdsToSelect(dataFieldIdsToSelect);
    }, [props.dataFields, props.subDomainRequestStateData.Fields]);

    useEffect(() => {
        dispatch(
            dataAccessMatrix2Actions.SET_SUBDOMAIN_DATA_REQUEST_FIELDS({
                subDomainId: props.subdomainId,
                fields: selectedRows,
            }),
        );
        if (selectedRows.length === 0) setIsCheck(false);
        if (selectedRows.length && selectedRows.length === dataFieldIdsToSelect.length) setIsCheck(true);
        if (selectedRows.length && selectedRows.length < dataFieldIdsToSelect.length) {
            setIsIntermediate(true);
        } else {
            setIsIntermediate(false);
        }
    }, [selectedRows, props.subDomainRequestStateData.SubdomainId]);

    const toggleCheckbox = (fieldId: number) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(fieldId)) {
                return prevSelectedRows.filter((id) => id !== fieldId);
            } else {
                handleAddFields();
                return [...prevSelectedRows, fieldId];
            }
        });
    };

    function handleClickHeaderCheckbox(): void {
        if (selectedRows.length === 0 || selectedRows.length < dataFieldIdsToSelect.length) {
            handleAddFields();
            handleFieldsUpdated();
            setSelectedRows([...dataFieldIdsToSelect]);
        } else {
            handleFieldsUpdated();
            setSelectedRows([...fieldsUnderReview]);
        }
    }

    function evaluateDraftStatus(row: RowData, requestedFields: number[], formState: string): string {
        const formStates = ['saved as draft'];
        if (
            !row.access &&
            requestedFields?.includes(row.fieldId) &&
            formStates.includes((formState || '').toLowerCase())
        ) {
            return 'Draft';
        }
        if (
            row.access &&
            !requestedFields?.includes(row.fieldId) &&
            formStates.includes((formState || '').toLowerCase())
        ) {
            return 'Draft';
        }
        return '';
    }

    const RenderRowData = (rows: RowData[]) => {
        const regex = /\\r\\n|\\r|\\n/;
        return rows.map((row) => {
            const remarks = row.remarks
                ? row.remarks.split(regex).map((str, index) => (
                      <p key={index} styleName="remarks">
                          {str}
                      </p>
                  ))
                : '-';
            return (
                <TableRow key={row.name} styleName={!row.access ? 'greyOutRow' : ''}>
                    <TableCell>
                        <Grid
                            sx={{display: 'flex', justifyContent: 'space-between'}}
                            styleName={row.fieldGroup && 'groupedTableRow'}
                        >
                            {row.name} {row.newField && <Chip label="New field" styleName="newFieldsChip" />}
                        </Grid>
                    </TableCell>
                    <TableCell align="center">
                        {row.access ? <CheckCircleIcon color="success" /> : <RemoveCircleIcon />}
                    </TableCell>
                    <TableCell>
                        {row.status} {row.status == 'Rejected' && <InfoIcon styleName="rejectedInfoIcon" />}
                        <br />
                        <span style={{...subtextStyle}}>{row.lastUpdatedOn}</span>
                    </TableCell>
                    <TableCell>
                        {row.lastUpdatedBy} <br /> <span style={{...subtextStyle}}>{row.userRole}</span>
                    </TableCell>
                    <TableCell styleName="td-remarks">{remarks}</TableCell>
                    <TableCell align="right">
                        <Typography
                            display={'inline'}
                            style={{
                                fontSize: '14px',
                                fontStyle: 'italic',
                                fontWeight: 400,
                                lineHeight: '20px',
                                letterSpacing: '0.25px',
                                textAlign: 'right',
                                color: '#979797',
                            }}
                        >
                            {evaluateDraftStatus(row, requestedFields, formState)}
                        </Typography>
                        <Checkbox
                            disabled={evaluateDisabledCheckbox(row, props.activeWindow)}
                            checked={evaluateCheckedBox(row, props.activeWindow)}
                            onClick={() => {
                                toggleCheckbox(row.fieldId);
                                handleFieldsUpdated();
                            }}
                        />
                    </TableCell>
                </TableRow>
            );
        });
    };

    const TableRowFieldGroup2 = (props: {groupName: string; rowData: RowData[]; activeWindow: boolean}) => {
        const [open, setOpen] = useState(true);

        return (
            <>
                <TableRow key={props.groupName}>
                    <TableCell>
                        <Grid sx={{display: 'flex', justifyContent: 'space-between'}}>{props.groupName}</Grid>
                    </TableCell>
                    <TableCell align="center">
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{p: 0}} colSpan={100}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table styleName="subdomainFieldTable" aria-label="simple table">
                                <colgroup>
                                    <col style={{width: '30%'}} />
                                    <col style={{width: '10%'}} />
                                    <col style={{width: '14%'}} />
                                    <col style={{width: '12%'}} />
                                    <col style={{width: '19%'}} />
                                    <col style={{width: '15%'}} />
                                </colgroup>
                                <TableBody>{RenderRowData(props.rowData)}</TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    // this function creates the table data, splitting the data into rows and groups
    const createTableData = (rowData: RowData[]) => {
        const groups = new Map<string, RowData[]>();
        const rows: RowData[] = [];

        rowData.forEach((rowData) => {
            if (rowData.lastUpdatedOn) {
                rowData.lastUpdatedOn = transformDate(rowData.lastUpdatedOn);
            }

            if (rowData.fieldGroup) {
                if (groups.has(rowData.fieldGroup.Name)) {
                    groups.get(rowData.fieldGroup.Name)?.push(rowData);
                } else {
                    groups.set(rowData.fieldGroup.Name, [rowData]);
                }
            }
            // else add to rows
            else {
                rows.push(rowData);
            }
        });

        return {rows, groups};
    };

    function combineArrays(
        dataFields: IDataAccessMatrixField[],
        stateFields: Field[],
        opsUnitDataFields: {[key: number]: number},
    ): RowData[] {
        const rowDataArray: RowData[] = [];
        for (const field of dataFields) {
            // create a row first
            const rowData: RowData = {
                fieldId: field.Id,
                name: field.Name,
                newField: evaluateNewField(field.CreatedAt || '', latestClosedWindow?.WindowEnd || '-'),
                fieldGroup: field.DataFieldGroup,
                remarks: field.Remarks || null,
            };

            if (stateFields) {
                const matchingField = stateFields?.find((stateField) => field.Id === stateField.FieldId);
                if (matchingField) {
                    const isInOudf = Object.keys(opsUnitDataFields).map(Number).includes(matchingField.FieldId);
                    if (isInOudf) {
                        // already in OUDF (API already filters for deleted_at value)
                        rowData.access = true;
                        rowData.status = matchingField.State;
                        rowData.lastUpdatedBy = matchingField.LastUpdatedBy.Name;
                        rowData.lastUpdatedOn = matchingField.LastUpdated;
                        rowData.userRole = matchingField.LastUpdatedBy.Role;

                        rowDataArray.push(rowData);
                    } else {
                        rowData.access = false;
                        rowData.status = stateFields.find((stateField) => stateField.FieldId == field.Id)?.State;
                        rowData.lastUpdatedBy = matchingField.LastUpdatedBy.Name;
                        rowData.lastUpdatedOn = matchingField.LastUpdated;
                        rowData.userRole = matchingField.LastUpdatedBy.Role;
                        rowDataArray.push(rowData);
                    }
                }
            } else {
                rowData.access = false;
                rowData.status = '-';
                rowData.lastUpdatedBy = '-';
                rowData.lastUpdatedOn = '';
                rowData.userRole = '-';
                rowDataArray.push(rowData);
            }
        }

        return rowDataArray.sort((a, b) => a.fieldId - b.fieldId);
    }

    function evaluateDisabledCheckbox(rowData: RowData, activeWindow: boolean): boolean {
        if (activeWindow) {
            return (
                !isOA ||
                rowData.status?.toLowerCase() === 'sent for review' ||
                restrictions.Fields.includes(rowData.fieldId) ||
                formState.toLowerCase() === 'submitted'
            );
        } else {
            return true;
        }
    }

    function evaluateCheckedBox(rowData: RowData, activeWindow: boolean): boolean {
        if (
            activeWindow &&
            (formState.toLowerCase() === 'submitted' || formState.toLowerCase() === 'sent for review')
        ) {
            return requestedFields?.includes(rowData.fieldId);
        } else {
            return selectedRows.includes(rowData.fieldId);
        }
    }

    return (
        <TableContainer sx={{borderRadius: 0}} component={Paper}>
            <Table styleName="subdomainFieldTable" aria-label="simple table">
                <colgroup>
                    <col style={{width: '30%'}} />
                    <col style={{width: '10%'}} />
                    <col style={{width: '14%'}} />
                    <col style={{width: '12%'}} />
                    <col style={{width: '19%'}} />
                    <col style={{width: '15%'}} />
                </colgroup>
                <TableHead style={{backgroundColor: '#e8eaf6', overflow: 'hidden'}}>
                    <TableRow>
                        <TableCell>Field Name</TableCell>
                        <TableCell>Access</TableCell>
                        <TableCell>
                            Request Status <br />
                            <span style={{...subtextStyle}}>Last updated on</span>
                        </TableCell>
                        <TableCell>
                            Last updated by <br /> <span style={{...subtextStyle}}>User role</span>
                        </TableCell>
                        <TableCell>Data field remarks</TableCell>
                        <TableCell align="right" sx={{maxWidth: '14%'}}>
                            Required
                            <Checkbox
                                indeterminate={isIntermediate}
                                checked={isCheck}
                                onClick={() => handleClickHeaderCheckbox()}
                                disabled={
                                    !props.activeWindow ||
                                    formState.toLowerCase() === 'submitted' ||
                                    dataFieldIdsToSelect.length === 0 ||
                                    !isOA
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from(groups.entries()).map((group) => {
                        const [key, value] = group;
                        return (
                            <TableRowFieldGroup2
                                key={key}
                                groupName={key}
                                rowData={value}
                                activeWindow={props.activeWindow}
                            />
                        );
                    })}
                    {RenderRowData(rows)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubdomainDataFieldsTable;
