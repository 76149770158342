import React from 'react';
import {Grid} from '@mui/material';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {SubDomainStatus} from '~/interfaces/client';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {ERROR_COPY} from '~/constants';
import {NoData} from '../CompletedSubDomain';
import {objNoResults} from '~/utils/contentUtils';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export interface ICPFISOA {
    Data: {
        HasInvestmentAccount: string;
        BankName: string;
    };
    ErrorMessage: string;
    Status: string;
}

export interface ICPFISSA {
    Data: {
        HasInvestmentAccount: string;
    };
    ErrorMessage: string;
    Status: string;
}

const getInvestmentIndicator = (indicator: string): string => {
    switch (indicator) {
        case 'Y':
            return 'Yes';
        case 'N':
            return 'No';
        default:
            return indicator;
    }
};
interface ICPFISOADetails {
    cpfisOa: ICPFISOA;
    cpfisSa: ICPFISSA;
}

export const CPFISDetails: React.FC<ICPFISOADetails> = ({cpfisOa, cpfisSa}) => {
    //Flow: Error
    const subdomainOaError: boolean =
        (!cpfisOa.Data && !cpfisOa.ErrorMessage) || cpfisOa.Status != SubDomainStatus.COMPLETED;
    const subdomainSaError: boolean =
        (!cpfisSa.Data && !cpfisSa.ErrorMessage) || cpfisSa.Status != SubDomainStatus.COMPLETED;

    if (subdomainOaError && subdomainSaError)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    //Flow: No Result
    const noResults =
        cpfisOa?.Status === SubDomainStatus.COMPLETED &&
        objNoResults(cpfisOa.Data) &&
        cpfisSa?.Status === SubDomainStatus.COMPLETED &&
        objNoResults(cpfisSa.Data);
    if (noResults) {
        return <NoData />;
    }

    //Flow: Success
    const oaMessage = subdomainOaError
        ? ERROR_COPY
        : cpfisOa.ErrorMessage
        ? `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${cpfisOa.ErrorMessage})`
        : getInvestmentIndicator(cpfisOa.Data.HasInvestmentAccount);

    const oaInvestmentBankName = subdomainOaError
        ? ERROR_COPY
        : cpfisOa.ErrorMessage
        ? `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${cpfisOa.ErrorMessage})`
        : cpfisOa.Data.BankName;

    const saMessage = subdomainSaError
        ? ERROR_COPY
        : cpfisSa.ErrorMessage
        ? `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${cpfisSa.ErrorMessage})`
        : getInvestmentIndicator(cpfisSa.Data.HasInvestmentAccount);

    const fields = [
        {
            label: 'Usage of OA for Investment',
            value: oaMessage,
        },
        {
            label: 'Investment Agent Bank for OA Investment',
            value: oaInvestmentBankName,
        },
        {
            label: 'Usage of SA for Investment',
            value: saMessage,
        },
    ];

    return (
        <Grid container spacing={3}>
            <SimpleGridDisplay fields={fields} />
        </Grid>
    );
};
