import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {formatCurrency} from '~/utils/currencyUtils';
import {SubDomainStatus} from '~/interfaces/client';
import {FIELD_VALUES, SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {allNoResults, extractAllValues, formatValue, isNoAccess} from '~/utils/contentUtils';
import style from './CPFAccountDetails.scss';
import {NoData} from '../CompletedSubDomain';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

enum ACCOUNT_TYPE {
    'OA' = 'Ordinary Amount (OA)',
    'SA' = 'Special Amount (SA)',
    'MA' = 'Medisave Amount (MA)',
    'RA' = 'Retirement Amount (RA)',
}

enum ACCOUNT_TYPE_LABEL_COLOR {
    'OA' = '#018576',
    'SA' = '#0D5051',
    'MA' = '#0D5051',
    'RA' = '#943301',
}

export type Account = {
    OrdinaryAccount: string;
    SpecialAccount: string;
    MedisaveAccount: string;
    RetirementAccount: string;
};

export interface IAccountBalances {
    Data: Account | Error;
    Status: string;
    ErrorMessage: string;
}

interface IAccountBalancesDetails {
    details: IAccountBalances;
}

export const AccountTypeLabel = ({accountType}: {accountType: ACCOUNT_TYPE}) => {
    switch (accountType) {
        case ACCOUNT_TYPE.OA:
            return <span style={{backgroundColor: ACCOUNT_TYPE_LABEL_COLOR.OA}}>OA</span>;
        case ACCOUNT_TYPE.SA:
            return <span style={{backgroundColor: ACCOUNT_TYPE_LABEL_COLOR.SA}}>SA</span>;
        case ACCOUNT_TYPE.MA:
            return <span style={{backgroundColor: ACCOUNT_TYPE_LABEL_COLOR.MA}}>MA</span>;
        case ACCOUNT_TYPE.RA:
            return <span style={{backgroundColor: ACCOUNT_TYPE_LABEL_COLOR.RA}}>RA</span>;
        default:
            return null;
    }
};

export const AccountBalancesDetails = ({details}: IAccountBalancesDetails) => {
    if ((!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    if (allNoResults(extractAllValues(details.Data)))
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                    Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                }`}
            />
        );

    const {OrdinaryAccount, SpecialAccount, MedisaveAccount, RetirementAccount} = details.Data as Account;

    const accountBalances = [
        {
            account: ACCOUNT_TYPE.OA,
            amount: formatValue(OrdinaryAccount, parseFloat, formatCurrency),
        },
        {
            account: ACCOUNT_TYPE.SA,
            amount: formatValue(SpecialAccount, parseFloat, formatCurrency),
        },
        {
            account: ACCOUNT_TYPE.MA,
            amount: formatValue(MedisaveAccount, parseFloat, formatCurrency),
        },
        {
            account: ACCOUNT_TYPE.RA,
            amount: formatValue(RetirementAccount, parseFloat, formatCurrency),
        },
    ].filter((elem) => !isNoAccess(elem.amount));

    return (
        <div id={style.custom2ColTable}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Account Type</TableCell>
                            <TableCell>Amount ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountBalances.map(({account, amount}, i) => {
                            if (amount === FIELD_VALUES.NO_ACCESS) return null;

                            return (
                                <TableRow key={i} data-testid="account_balance_detail">
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" styleName="accountLabel">
                                            <AccountTypeLabel {...{accountType: account}} />
                                            {account}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{amount}</Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
