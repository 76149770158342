import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    Paper,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Collapse,
} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {IDataAccessMatrixField, IDataFieldGroup} from '~/interfaces/admin';

import './DataAccessMatrixEdit.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    getFieldState,
    getGroupedFieldsCheckedState,
    getGroupedFieldsIndeterminateState,
} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {dataAccessMatrixActions} from '~/store/dataAccessMatrix/dataAccessMatrix.slice';

interface IDataAccessMatrixFieldGroup {
    DataFieldGroup: IDataFieldGroup;
    DataFields: IDataAccessMatrixField[];
}
interface IGroupedFieldsProps {
    groupedFields: IDataAccessMatrixFieldGroup;
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
}
interface IFieldsProps {
    rows: IDataAccessMatrixField[];
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
}

interface IFieldCellProps {
    field: IDataAccessMatrixField;
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
    isGroupedField?: boolean;
}

export const FieldCell = ({field, opsUnitId, domainId, subDomainId, isGroupedField = false}: IFieldCellProps) => {
    const isChecked = useTypedSelector(getFieldState(opsUnitId, domainId, subDomainId, field.Id));
    const dispatch = useAppDispatch();

    const checkBoxChangeHandler = () => {
        dispatch(
            dataAccessMatrixActions.SET_DATA_FIELD({
                opsUnitId,
                domainId,
                subDomainId,
                fieldId: field.Id,
                selected: !isChecked,
            }),
        );
    };

    return (
        <TableCell>
            <Typography component="span" variant="body1" style={{marginLeft: `${isGroupedField ? 30 : 0}px`}}>
                <Checkbox
                    id={field.Id.toString()}
                    color={'primary'}
                    disableRipple
                    styleName="fieldCheckbox"
                    onChange={checkBoxChangeHandler}
                    checked={isChecked}
                />
                {field.Name}
            </Typography>
        </TableCell>
    );
};

export const GroupedFields = ({groupedFields, opsUnitId, domainId, subDomainId}: IGroupedFieldsProps) => {
    const [expand, setExpand] = useState<boolean>(true);
    const [fieldIds, setFieldIds] = useState<number[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const groupedFieldIds = groupedFields.DataFields.map((field) => field.Id);
        setFieldIds(groupedFieldIds);
    }, []);

    const isChecked = useTypedSelector(getGroupedFieldsCheckedState(opsUnitId, domainId, subDomainId, fieldIds));
    const isIndeterminate = useTypedSelector(
        getGroupedFieldsIndeterminateState(opsUnitId, domainId, subDomainId, fieldIds),
    );

    const groupedFieldChangeHandler = () => {
        const payload = fieldIds.map((id) => ({
            opsUnitId,
            domainId,
            subDomainId,
            fieldId: id,
            selected: !isChecked,
        }));

        dispatch(dataAccessMatrixActions.SET_GROUPED_FIELDS(payload));
    };
    return (
        <>
            <TableRow>
                <TableCell onClick={() => setExpand(!expand)}>
                    <Typography component="span" variant="body1">
                        <Checkbox
                            color={'primary'}
                            disableRipple
                            styleName="fieldCheckbox"
                            checked={isChecked}
                            indeterminate={isIndeterminate}
                            onClick={(e) => e.stopPropagation()}
                            onChange={groupedFieldChangeHandler}
                        />
                        {groupedFields.DataFieldGroup.Name}
                        <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: '0', border: '0'}}>
                    <Collapse in={expand} timeout={500} unmountOnExit>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {groupedFields.DataFields.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <FieldCell
                                                    field={row}
                                                    opsUnitId={opsUnitId}
                                                    domainId={domainId}
                                                    subDomainId={subDomainId}
                                                    isGroupedField={true}
                                                />
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const FieldCellMemo = React.memo(FieldCell);
export const Fields = ({rows, opsUnitId, domainId, subDomainId}: IFieldsProps) => {
    const [groupedRows, setGroupedRows] = useState<(IDataAccessMatrixField | IDataAccessMatrixFieldGroup)[]>([]);

    useEffect(() => {
        const reducedRows = rows.reduce((acc, curr) => {
            if (!curr.DataFieldGroup) {
                acc.push(curr);
            } else {
                let grouped = false;
                acc.forEach((item) => {
                    if (
                        item.DataFieldGroup &&
                        item.DataFieldGroup?.Id === curr.DataFieldGroup?.Id &&
                        'DataFields' in item
                    ) {
                        item.DataFields = [...item.DataFields, curr];
                        grouped = true;
                    }
                });

                if (!grouped) {
                    acc.push({
                        DataFieldGroup: curr.DataFieldGroup,
                        DataFields: [curr],
                    });
                }
            }

            return acc;
        }, [] as (IDataAccessMatrixField | IDataAccessMatrixFieldGroup)[]);

        setGroupedRows(reducedRows);
    }, [rows]);
    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableBody>
                        {groupedRows.map((row, index) => {
                            return 'Name' in row ? (
                                <TableRow key={index}>
                                    <FieldCellMemo
                                        field={row}
                                        opsUnitId={opsUnitId}
                                        domainId={domainId}
                                        subDomainId={subDomainId}
                                    />
                                </TableRow>
                            ) : (
                                <GroupedFields
                                    key={index}
                                    groupedFields={row}
                                    opsUnitId={opsUnitId}
                                    domainId={domainId}
                                    subDomainId={subDomainId}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
