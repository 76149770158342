import React, {useState, useRef, memo, useEffect} from 'react';
import {TableRow, TableCell, Grid, Typography, IconButton} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {shallowEqual} from 'react-redux';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IDataAccessMatrixField} from '~/interfaces/admin';
import {assertNewLine} from '~/utils/contentUtils';
import {useOnScreen} from '~/utils/hooks/useOnScreen';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {updateDataField} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {getSubDomainDataFields} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {CreateDataDictionaryDialog} from '~/components/Admin/DataDictionary/CreateDataDictionaryDialog';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';

import './DataDictionary.scss';

interface IDataDictionaryTableRowProps {
    sortedRows: IDataAccessMatrixField[];
    editable?: boolean;
    mountAllFields?: boolean;
    openGrowl: (msg: string) => void;
}

export const DataDictionaryTableRows = ({
    sortedRows,
    editable,
    openGrowl,
    mountAllFields = false,
}: IDataDictionaryTableRowProps) => {
    const dispatch = useAppDispatch();
    const [isRenderFields, setIsRenderField] = useState<boolean>(mountAllFields);
    const [selected, setSelected] = useState<IDataAccessMatrixField>();
    const intersectionRef = useRef(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const {isOnScreen} = useOnScreen(intersectionRef);

    useEffect(() => {
        if (!mountAllFields) setIsRenderField(isOnScreen);
    }, [isOnScreen]);

    const handleClick = async (ddField: IDataAccessMatrixField) => {
        await setSelected(ddField);
        setShowModal(true);
    };

    const handleEdit = async (ddField: IDataAccessMatrixField) => {
        const thunkActionResult = await dispatch(updateDataField(ddField));

        if (thunkActionResult) {
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl('Definition updated successfully');
                setShowModal(false);
            }
        }
    };

    return (
        <>
            {!isRenderFields && <TableRow ref={intersectionRef} />}
            {isRenderFields ? (
                sortedRows.map((row, index) => {
                    return (
                        <TableRow key={`mainRow_${index}`}>
                            <TableCell style={{width: '180px', padding: '16px 24px'}}>
                                <Typography component="span" variant="body1">
                                    {assertNewLine(row.Name)}
                                </Typography>
                            </TableCell>
                            <TableCell style={{padding: '16px 0 16px 24px'}}>
                                <Typography component="span" variant="body1" style={{marginBottom: 0}}>
                                    {assertNewLine(row.Description)}
                                </Typography>
                                <Typography component="span" variant="caption" styleName="secondaryContent">
                                    {row.DataSource?.Name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {editable && (
                                    <IconButton
                                        onClick={() => handleClick(row)}
                                        style={{float: 'right', color: '#3949AB'}}
                                    >
                                        <EditRoundedIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })
            ) : (
                <TableRow style={{height: `${sortedRows.length * 25}px`, padding: '16px'}}>
                    <TableCell>
                        <Typography component="span" variant="caption" styleName="secondaryContent">
                            ...Rendering Table
                        </Typography>
                    </TableCell>
                </TableRow>
            )}

            {editable && (
                <>
                    <CreateDataDictionaryDialog
                        title="Edit Definition"
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        onSubmit={handleEdit}
                        editDataDictionary={selected}
                    />
                </>
            )}
        </>
    );
};

export interface IDataDictionaryTableProps {
    subDomainId: number;
    domainIndex: number;
    editable?: boolean;
    mountAllFields?: boolean;
}

export type IHeadRowData = Pick<IDataAccessMatrixField, 'Name' | 'Description'>;

export const DataDictionaryTable = ({
    subDomainId,
    domainIndex,
    editable,
    mountAllFields,
}: IDataDictionaryTableProps) => {
    const rows = useTypedSelector(getSubDomainDataFields(domainIndex, subDomainId), shallowEqual);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const growlRef = useRef(null);

    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {
            id: 'Name',
            align: 'left',
            disablePadding: false,
            label: (
                <Typography variant="body1" styleName="headerText">
                    Field Name
                </Typography>
            ),
        },
        {
            id: 'Description',
            align: 'left',
            disablePadding: false,
            label: (
                <Grid styleName="headerText">
                    <Typography variant="body1" style={{marginBottom: 0}}>
                        Description
                    </Typography>
                    <Typography variant="caption">Data Source (Agency)</Typography>
                </Grid>
            ),
        },
        {align: 'center', disablePadding: true, label: ''},
    ];

    const headerProps = {
        columns: headColumns,
    };

    const tableSortLabelIconStyle = {
        color: 'var(--primary-blue, #3949ab) !important',
    };

    const hocProps = {
        colSpan: 12,
        headerProps,
        tableSortLabelIconStyle,
        editable,
        mountAllFields,
        openGrowl,
    };

    const EnhancedTable = WithEnhancedTable<IDataDictionaryTableRowProps, IHeadRowData, IDataAccessMatrixField>(
        memo(DataDictionaryTableRows),
        'Name',
        rows,
        'asc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return (
        <>
            <EnhancedTable {...hocProps} />
            {/* Duplicate Snackbar for each subdomain rows block to prevent rendering the entire dictionary when growl is triggered */}
            {editable && (
                <AdminSnackbar
                    snackBarProps={{
                        anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                        key: growl?.key,
                        open: growl?.open,
                        onClose: closeGrowl,
                        autoHideDuration: growl.autoHideDuration,
                        message: growl.message.length > 0 ? growl.message : undefined,
                    }}
                    domainHeaderRef={growlRef}
                />
            )}
        </>
    );
};
