import {Theme, alpha} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

export const getStyles = makeStyles((theme: Theme) => ({
    link: {
        textDecoration: 'none',
        height: '28px',
    },
    menuButton: {
        padding: '12px 24px 12px 0',
    },
    menuList: {
        padding: '8px 16px',
    },
    switchAccountIcon: {
        padding: '0 0 0 8px,',
    },
    search: {
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        marginRight: theme.spacing(2),
        maxWidth: '380px',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchInput: {
        width: '100%',
    },

    grow: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    profileWrapper: {
        display: 'flex',
    },
    accountCircle: {
        alignSelf: 'center',
        fontSize: 32,
        marginRight: 10,
    },
    profileText: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    profileName: {
        flex: 1,
        fontSize: 14,
    },
    profileRole: {
        flex: 1,
        fontSize: 12,
    },
    sdHide: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    logo: {
        height: '28px',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));
