import * as agencyServices from '~/services/agencyServices';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {IParticipatingAgencies} from '~/interfaces/common';

export const setParticipatingAgencies = createAsyncThunk<IParticipatingAgencies | void, void>(
    '/participatingAgency/setParticipatingAgency',
    async (_, {dispatch}) => {
        dispatch(fetchStateActions.SET_PARTICIPATING_AGENCY_FETCH_STATE(API_FETCH_STATE.PENDING));
        try {
            const participatingAgencies = await agencyServices.getParticipatingAgencies();
            dispatch(fetchStateActions.SET_PARTICIPATING_AGENCY_FETCH_STATE(API_FETCH_STATE.SUCCESS));
            dispatch(hydrateFlagActions.UPDATE_FLAG(Flag.PARTICIPATING_AGENCY));
            return participatingAgencies;
        } catch (err) {
            dispatch(fetchStateActions.SET_PARTICIPATING_AGENCY_FETCH_STATE(API_FETCH_STATE.ERROR));
            throw err;
        }
    },
);
