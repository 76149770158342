import React from 'react';
import {Button} from '@mui/material';
import {useHistory} from 'react-router';
import notFound from '~/assets/images/notFound.svg';
import './PageNotFound.scss';

export const PageNotFound = () => {
    const history = useHistory();

    return (
        <div styleName="not-found-page">
            <img src={notFound} alt="Not Found" styleName="not-found-svg" />
            <h1 styleName="not-found-header">Uh-oh, page not found</h1>
            <p styleName="not-found-text">
                The page you’re looking for was moved or doesn’t exist. Please try another link.
            </p>
            <Button variant="outlined" color="primary" styleName="home-button" onClick={() => history.push('/')}>
                GO TO HOMEPAGE
            </Button>
        </div>
    );
};
