import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse, Divider, Drawer, List, Typography, ListItem} from '@mui/material';

import './Sidebar.scss';
import {
    KeyboardArrowUpRounded as KeyboardArrowUpIcon,
    KeyboardArrowDownRounded as KeyboardArrowDownIcon,
} from '@mui/icons-material';

export interface ILink {
    label: string;
    isSelected: boolean;
    path?: string;
    icon: JSX.Element;
    children?: {
        label: string;
        isSelected: boolean;
        path: string;
    }[];
}

export interface ISidebar {
    linksMap: ILink[];
}
const selectedStyleIf = (style: string, condition: boolean) => (condition ? `${style} selected` : style);

export const Sidebar = ({linksMap}: ISidebar) => {
    const selectedItem = linksMap.find((item) => item.isSelected);
    const [currentActive, setCurrentActive] = useState(selectedItem?.label);
    React.useEffect(() => {
        setCurrentActive(selectedItem?.label);
    }, [selectedItem]);

    const renderArrow = (name: string): JSX.Element =>
        name == currentActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    return (
        <Drawer variant="permanent" id="commonSidebar">
            <List>
                {linksMap.map((parent, key) => {
                    let labelStyle = parent?.children ? 'parentLabel' : 'label';
                    labelStyle = selectedStyleIf(labelStyle, parent.isSelected);

                    return (
                        <div key={`listItem-${key}`}>
                            <Link to={parent.children ? parent.children[0].path : parent.path ? parent.path : ''}>
                                <ListItem
                                    data-testid={`parentItem-${key}`}
                                    button
                                    disableRipple
                                    disableGutters
                                    styleName={selectedStyleIf('listItem', parent.label == currentActive)}
                                    onClick={() => parent.children && setCurrentActive(parent.label)}
                                >
                                    <div styleName="icon">{parent.icon}</div>
                                    <Typography styleName={labelStyle}>{parent.label}</Typography>
                                    {parent.children && <div styleName="arrow">{renderArrow(parent.label)}</div>}
                                </ListItem>
                            </Link>

                            {parent?.children && (
                                <Collapse data-testid={`collapse-${key}`} in={currentActive == parent.label}>
                                    <List styleName="nestedList">
                                        {parent?.children.map((child, childKey) => {
                                            const childLabelStyle = child.isSelected
                                                ? 'childLabel selected'
                                                : 'childLabel';
                                            return (
                                                <div key={childKey}>
                                                    <Link to={child.path}>
                                                        <ListItem
                                                            button
                                                            disableRipple
                                                            disableGutters
                                                            styleName="childListItem"
                                                        >
                                                            <Typography styleName={childLabelStyle}>
                                                                {child.label}
                                                            </Typography>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </List>
        </Drawer>
    );
};
