import React from 'react';
import {Button} from '@mui/material';
import {AddRounded as AddIcon} from '@mui/icons-material';

interface IReactivateButton {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    id?: string;
}

export const ReactivateButton = ({onClick, id}: IReactivateButton) => {
    return (
        <Button variant="text" color="primary" onClick={onClick} id={id}>
            <AddIcon style={{marginRight: 8}} />
            Reactivate
        </Button>
    );
};
