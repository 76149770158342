import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import * as services from '~/services/apiServices';
import {IResource} from '~/interfaces/common';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';

export const setResources = createAsyncThunk<IResource[], void>('/resources/setResources', async (_, {dispatch}) => {
    dispatch(fetchStateActions.SET_RESOURCES_FETCH_STATE(API_FETCH_STATE.PENDING));
    try {
        const resources = await services.getResources();
        dispatch(fetchStateActions.SET_RESOURCES_FETCH_STATE(API_FETCH_STATE.SUCCESS));
        dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.RESOURCES});
        return resources;
    } catch (err) {
        dispatch(fetchStateActions.SET_RESOURCES_FETCH_STATE(API_FETCH_STATE.ERROR));
        throw err;
    }
});
