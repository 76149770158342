import {Typography} from '@mui/material';
import React from 'react';
import systemMaintenanceCover from '~/assets/images/systemMaintenance.svg';
import './SystemMaintenance.scss';

export const SystemMaintenance = () => {
    return (
        <div styleName="maintenancePage">
            <img src={systemMaintenanceCover} alt="System Maintenance Cover" styleName="image" />
            <Typography variant="h4" styleName="title">
                We&apos;ll be back soon
            </Typography>
            <Typography variant="body1" styleName="text">
                OneCV is undergoing maintenance and will be back soon. Please check back in awhile.
            </Typography>
            <Typography variant="body1" styleName="text">
                Thank you for your patience.
            </Typography>
        </div>
    );
};
