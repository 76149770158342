import {config} from '~/config';
import {IClientConsentHistory, IUpdateClientConsentRequest, IUpdateClientConsentResponse} from '~/interfaces/admin';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {httpRequest} from './middleware';
import {CancelToken} from 'axios';
import {IConsentFileMeta, IConsentRequest} from '~/interfaces/client';
import fileDownload from 'js-file-download';

export const getClientConsentHistory = async (uin: string): Promise<IClientConsentHistory> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/client-consent-history/${uin}`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const updateClientConsent = async (
    action: string,
    payload: IUpdateClientConsentRequest,
): Promise<IUpdateClientConsentResponse> => {
    // update response interface on client-data-service
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/update-consent/${action}`,
        data: JSON.stringify(payload),
        method: 'POST',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const uploadClientConsentFile = async (
    file: File,
    name: string,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    cancelToken: CancelToken,
): Promise<IConsentFileMeta> => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);

    const response = await httpRequest({
        url: `${config.api.DATA_UPLOAD_DECRYPT_SERVICE}/uploadConsent/`,
        method: 'POST',
        data: formData,
        onUploadProgress: (pg) => setProgress((pg.loaded / pg.total) * 100),
        cancelToken,

        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getConsentFile = async (consentFormRequest: IConsentRequest, fileMeta: IConsentFileMeta) => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/download-consent-form/${fileMeta.Id}`,
        data: JSON.stringify(consentFormRequest),
        headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
        },
        method: 'POST',
        responseType: 'blob',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    const fileData = response.data;
    fileDownload(fileData, `${fileMeta.FileHash}.pdf`);
};
