import EventEmitter from 'eventemitter3';

export enum EVENT_TYPE {
    RESET_BOTTOM = 'RESET_BOTTOM',
    RESET_TABLE_PAGE = 'RESET_TABLE_PAGE',
}

const eventEmitter = new EventEmitter();

const Emitter = {
    on: (event: string, callback: () => void) => eventEmitter.on(event, callback),
    once: (event: string, callback: () => void) => eventEmitter.once(event, callback),
    off: (event: string, callback: () => void) => eventEmitter.off(event, callback),
    emit: (event: string, payload: any) => eventEmitter.emit(event, payload), // eslint-disable-line @typescript-eslint/no-explicit-any
};

Object.freeze(Emitter);

export default Emitter;
