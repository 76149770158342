import React from 'react';
import {Button, Grid} from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {IFamilyMember} from '~/interfaces/client';
import {Relationship} from '~/constants';
import './Person.scss';

export type TActionType = 'add' | 'remove' | 'none';

export interface IPersonProps {
    personInfo: IFamilyMember;
    onRemove?: (personInfo: IFamilyMember) => void;
    onAdd?: (personInfo: IFamilyMember) => void;
    actionType: TActionType;
    displayUIN?: boolean;
}

export const Person = ({personInfo, onRemove, onAdd, actionType, displayUIN = false}: IPersonProps) => {
    const handleRemove = () => onRemove && onRemove(personInfo);
    const handleAdd = () => onAdd && onAdd(personInfo);

    const renderActionButton = () => {
        switch (actionType) {
            case 'remove':
                return (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={handleRemove}
                        style={{float: 'right'}}
                    >
                        Remove
                    </Button>
                );
            case 'add':
                return (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleAdd}
                        style={{float: 'right'}}
                        data-testid="addPersonButton"
                    >
                        Add
                    </Button>
                );
            default:
                return null;
        }
    };

    return (
        <Grid container alignItems="center" styleName="person">
            <Grid item xs={1}>
                <PersonRoundedIcon color="primary" />
            </Grid>
            <Grid item xs={7}>
                <Grid container>
                    <Grid item xs={12} styleName="name">
                        {personInfo.Name}
                    </Grid>
                    {personInfo.Relationship !== Relationship.UNKNOWN && (
                        <Grid item xs={12} styleName="relationship">
                            {personInfo.Relationship}
                        </Grid>
                    )}
                    {displayUIN && personInfo.Name !== personInfo.UIN && (
                        <Grid item xs={12} styleName="relationship">
                            {personInfo.UIN}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={4}>
                {renderActionButton()}
            </Grid>
        </Grid>
    );
};
