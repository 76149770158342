import React from 'react';
import {Button} from '@mui/material';
import {EditRounded as EditIcon} from '@mui/icons-material';

interface IEditButton {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    id?: string;
}

export const EditButton = ({onClick, id}: IEditButton) => {
    return (
        <Button variant="text" color="primary" onClick={onClick} id={id}>
            <EditIcon style={{marginRight: 8}} />
            Edit
        </Button>
    );
};
