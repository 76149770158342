import React, {useContext, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Button, CircularProgress, Card, Snackbar, Typography, Grid} from '@mui/material';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE, ERROR_MESSAGE} from '~/constants';
import {useGrowl} from '~/utils/hooks/useGrowl';
import loginCover from '~/assets/images/loginCover.svg';
import {BrowserDetectModal} from '~/components/Common/BrowserDetect/BrowserDetectModal';
import {CustomAlert} from '~/components/Common/CustomAlert/CustomAlert';
import useBrowserInfo from '~/utils/hooks/useBrowserInfo';
import './LoginView.scss';

export interface ILoginView {
    loginButtonLink: string;
    loginButtonText: string;
    referer: string;
}

export const LoginView = ({loginButtonLink, loginButtonText, referer}: ILoginView) => {
    const {loginState} = useContext(AuthenticationContext);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const browserInfo = useBrowserInfo();

    const login = async () => {
        setIsLoggingIn(true);

        try {
            const res = await fetch(loginButtonLink, {
                method: 'GET',
            });
            const redirectUri = await res.text();
            window.location.href = redirectUri;
        } catch (_) {
            setIsLoggingIn(false);
            openGrowl(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    };

    return loginState == LOGIN_STATE.LOGGED_IN ? (
        <Redirect to={referer} />
    ) : (
        <>
            <BrowserDetectModal
                browserName={browserInfo.browserName}
                browserIncompatible={browserInfo.isIncompatible}
                browserOutdated={browserInfo.isOutdated}
                isMobile={browserInfo.isMobile}
            />
            <Grid container>
                <Grid item xl={6} xs={12} styleName="flexGrid">
                    <div styleName="cardContainer">
                        <Card styleName="card">
                            <Typography variant="h4" styleName="header" gutterBottom>
                                Login
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Sharing of One Client View account/login credentials is strictly prohibited and shall be
                                subject to disciplinary action.
                            </Typography>
                            <Typography variant="body2" paragraph>
                                This system may contain sensitive information and is for authorised use only.
                            </Typography>
                            <Button
                                color="primary"
                                id="loginButton"
                                variant="contained"
                                styleName="loginButton"
                                size="large"
                                onClick={login}
                            >
                                {isLoggingIn && <CircularProgress size={18} styleName="buttonLoader" />}
                                {loginButtonText}
                            </Button>
                        </Card>
                        <CustomAlert isDismissable={false}>
                            Please note that from 11 April 2021, you will be required to login to government digital
                            services for businesses (G2B) using Singpass instead of Corppass. For more information,
                            visit{' '}
                            <a href="https://go.gov.sg/corporate-login/" target="_blank" rel="noreferrer">
                                go.gov.sg/corporate-login
                            </a>
                        </CustomAlert>
                    </div>
                </Grid>

                <Grid item xl={6} styleName="flexGrid">
                    <img src={loginCover} alt="Login Cover" styleName="image" />
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};
