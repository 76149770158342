import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Link as MuiLink,
} from '@mui/material';
import {getDayDiffFromToday} from '~/utils/dateUtils';
import {Order, getComparator, stableSort} from '~/utils/tableUtils';
import {IStatus} from '~/interfaces/clientUser';
import {SubDomainStatus} from '~/interfaces/client';
import {KeyboardArrowUpRounded as KeyboardArrowUpIcon} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {MAIL_TO_LINK} from '~/constants';

export interface IPendingSubDomainData {
    subDomain: string;
    status: IStatus;
    expectedRetrivalDate: string | null;
}

export interface IPendingSubDomainsProps {
    rows: NullableArray<IPendingSubDomainData>;
    requestId: string;
}

export const PendingSubDomains = ({rows, requestId}: IPendingSubDomainsProps) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IPendingSubDomainData>('subDomain');

    const sortPendingSubDomainsHandler = (property: keyof IPendingSubDomainData) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const formatExpectedRetrivalDate = (expectedRetrivalDate: string | null, status: IStatus) => {
        if (status === SubDomainStatus.ERROR) {
            return (
                <Typography>
                    An error has occurred, please{' '}
                    <MuiLink
                        style={{textDecoration: 'underline'}}
                        href={MAIL_TO_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        contact us
                    </MuiLink>{' '}
                    and we will follow up with you shortly.
                </Typography>
            );
        }

        if (status === SubDomainStatus.COMPLETED) {
            return 'Data retrieved successfully.';
        }

        if (expectedRetrivalDate) {
            const dayDiff = getDayDiffFromToday(expectedRetrivalDate);

            if (dayDiff === 1) {
                return `${dayDiff} day`;
            } else if (dayDiff > 1) {
                return `${dayDiff} days`;
            }
        } else {
            return '1-2 days';
        }
    };

    return (
        <Table>
            <TableHead style={{backgroundColor: '#e8eaf6'}}>
                <TableRow>
                    <TableCell
                        style={{
                            borderBottom: 'none',
                            paddingLeft: '76px',
                            width: '30%',
                        }}
                        sortDirection={orderBy === 'subDomain' ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === 'subDomain'}
                            direction={orderBy === 'subDomain' ? order : 'asc'}
                            onClick={sortPendingSubDomainsHandler('subDomain')}
                            style={{color: '#3c3c3c', fontWeight: 'normal'}}
                            IconComponent={KeyboardArrowUpIcon}
                        >
                            Requested Sub-Domains
                        </TableSortLabel>
                    </TableCell>
                    <TableCell
                        style={{
                            borderBottom: 'none',
                        }}
                        sortDirection={orderBy === 'expectedRetrivalDate' ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === 'expectedRetrivalDate'}
                            direction={orderBy === 'expectedRetrivalDate' ? order : 'asc'}
                            onClick={sortPendingSubDomainsHandler('expectedRetrivalDate')}
                            style={{color: '#3c3c3c', fontWeight: 'normal'}}
                            IconComponent={KeyboardArrowUpIcon}
                        >
                            Retrieval Status
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{width: '100%'}}>
                {rows !== null &&
                    stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        return (
                            <TableRow key={`pendingSubDomain_${index}`}>
                                <TableCell style={{borderBottom: 'none', paddingLeft: '76px'}}>
                                    <Link to={`/client/${requestId}#${row.subDomain}`} style={{color: '#3c3c3c'}}>
                                        {row.subDomain}
                                    </Link>
                                </TableCell>
                                <TableCell style={{borderBottom: 'none', color: '#3c3c3c'}}>
                                    {formatExpectedRetrivalDate(row.expectedRetrivalDate, row.status)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};
