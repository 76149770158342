import React, {useState, useRef} from 'react';
import {Button, Card, CardContent, Collapse} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import './CollapsibleCard.scss';

export interface ITemplate {
    compulsorySection: React.ReactNode;
    children?: React.ReactNode;
    dataTestId?: string;
}

export const CollapsibleCard = ({compulsorySection, children, dataTestId}: ITemplate) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <Card styleName="card" data-testid={dataTestId}>
            <CardContent>
                <div ref={scrollRef}>{compulsorySection}</div>

                {children && (
                    <Collapse
                        data-testid="sectionCollapse"
                        in={isExpanded}
                        onExiting={() => {
                            scrollRef.current?.scrollIntoView({block: 'center'});
                        }}
                    >
                        {children}
                    </Collapse>
                )}
            </CardContent>
            {children && (
                <Button data-testid="buttonExpandable" styleName="readMore" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? (
                        <>
                            <ExpandLessIcon data-testid="readLess" styleName="icon" /> Read less
                        </>
                    ) : (
                        <>
                            <ExpandMoreIcon data-testid="readMore" styleName="icon" /> Read more
                        </>
                    )}
                </Button>
            )}
        </Card>
    );
};
