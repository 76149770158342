import {createSlice} from '@reduxjs/toolkit';
import {IDataAccessMatrixState} from '../dataReducers';
import {setDataAccessMatrix, updateDataField} from './dataAccessMatrix.thunk';
import {IDataAccessMatrix, IDataAccessMatrixField} from '~/interfaces/admin';

export const initialDataAccessMatrixState: IDataAccessMatrixState = {
    dictionary: [],
    opsUnits: {},
};

export const dataAccessMatrixSlice = createSlice({
    name: 'dataAccessMatrix',
    initialState: initialDataAccessMatrixState,
    reducers: {
        SET_OPS_UNIT_DATA_ACCESS_MATRIX(state, action) {
            return {
                ...state,
                opsUnits: {
                    ...state.opsUnits,
                    ...action.payload,
                },
            };
        },
        SET_DATA_FIELD(state, action) {
            state.opsUnits[action.payload.opsUnitId][action.payload.domainId][action.payload.subDomainId][
                action.payload.fieldId
            ] = action.payload.selected;
        },
        SET_GROUPED_FIELDS(state, action) {
            action.payload.forEach(
                (datafield: {
                    opsUnitId: number;
                    domainId: number;
                    subDomainId: number;
                    fieldId: number;
                    selected: boolean;
                }) => {
                    state.opsUnits[datafield.opsUnitId][datafield.domainId][datafield.subDomainId][datafield.fieldId] =
                        datafield.selected;
                },
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setDataAccessMatrix.fulfilled, (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    dictionary: action.payload,
                };
            }
        });
        builder.addCase(updateDataField.fulfilled, (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    dictionary: [...state.dictionary].reduce((acc, curr) => {
                        const updatedSubDomains = curr.SubDomains.map((subDomain) => {
                            const subDomainIdInPayload = subDomain.Id === action.payload.SubDomainId;
                            const dataFieldMatchPayload = subDomain.DataFields.some(
                                (dataField) => dataField.Id === action.payload.Id,
                            );

                            const updatedDataFields: IDataAccessMatrixField[] = subDomainIdInPayload
                                ? dataFieldMatchPayload
                                    ? subDomain.DataFields.map((dataField) =>
                                          dataField.Id === action.payload.Id ? action.payload : dataField,
                                      ) // Existing Field - Update
                                    : [...subDomain.DataFields, action.payload] // New Field - Append
                                : subDomain.DataFields.filter((dataField) => dataField.Id != action.payload.Id); // Remove Field

                            return {
                                ...subDomain,
                                DataFields: updatedDataFields,
                            };
                        });

                        acc.push({
                            ...curr,
                            SubDomains: updatedSubDomains,
                        });

                        return acc;
                    }, [] as IDataAccessMatrix),
                };
            }
        });
    },
});

export const dataAccessMatrixActions = dataAccessMatrixSlice.actions;
export default dataAccessMatrixSlice.reducer;
