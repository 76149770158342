import {combineReducers} from 'redux';
import {
    IDomain,
    IFetchStatus,
    IGroupedResource,
    IParticipatingAgencies,
    IUserFlags,
    IUserNotification,
} from '~/interfaces/common';
//common reducers
import agencyReducer, {IAgencyState} from './agency/agency.slice';
import clientReducer from './client/client.slice';
import clientUserReducer from './clientUser/clientUser.slice';
import dashboardReducer from './dashboard/dashboard.slice';
import dataAccessMatrixReducer from './dataAccessMatrix/dataAccessMatrix.slice';
import dataAccessMatrix2Reducer from './dataAccessMatrix/dataAccessMatrix2.slice';
import dataContributorReducer from './dataContributor/dataContributor.slice';
import dataSourcesReducer from './dataSource/dataSources.slice';
import domainReducer from './domain/domains.slice';
import fetchStateReducer from './fetchState/fetchState.slice';
import hydrateFlagReducer, {Flag} from './hydrateFlag/hydrateFlag.slice';
import opsUnitReducer from './opsUnit/opsUnit.slice';
import participatingAgencyReducer from './participatingAgency/participatingAgency.slice';
import releaseNotesReducer from './releaseNotes/releaseNote.slice';
import resourcesReducer from './resources/resources.slice';
import settingsReducer from './settings/settings.slice';
import surveyReducer from './survey/survey.slice';
import systemAdminsReducer from './systemAdmin/systemAdmin.slice';
import teamReducer from './team/team.slice';
import userReducer from './user/user.slice';
import userFlagsReducer from './userFlags/userFlags.slice';

import {IDataAccessMatrix, IDataContributor, IDataSource, IOpsUnit, ITeam, IUser} from '~/interfaces/admin';
import {IClientBasicInfo, IFamilyScreeningResults} from '~/interfaces/client';
import {IFavourites, IScreenHistories, ISubDomains, ISurveyTrigger} from '~/interfaces/clientUser';
import {IReleaseNote} from '~/interfaces/common';
import {IDashboardInfo} from '~/interfaces/dashboard';
import {ISortTableSetting} from '~/interfaces/settings';
import {ISystemAdminState} from './systemAdmin/systemAdmin.slice';
import userNotificationReducer from './userNotification/userNotification.slice';

export interface IFetchState {
    dataDictionaryFields: IFetchStatus;
    dataSources: IFetchStatus;
    dataContributors: IFetchStatus;
    participatingAgencies: IFetchStatus;
    systemAdmins: IFetchStatus;
    agencies: IFetchStatus;
    agency: IFetchStatus;
    domains: IFetchStatus;
    dashboard: IFetchStatus;
    opsUnit: IFetchStatus;
    opsUnits: IFetchStatus;
    resources: IFetchStatus;
    screenedClientInfo: IFetchStatus;
    team: IFetchStatus;
    teams: IFetchStatus;
    users: IFetchStatus;
    clientUser: IFetchStatus;
    damOpsUnit: IFetchStatus;
    damFields: IFetchStatus;
    releaseNotes: IFetchStatus;
}
export interface ISettingState {
    sortTableSettings: ISortTableSetting;
}
export interface IDashboardState {
    dashboardInfo: IDashboardInfo;
}
export interface IAdminDataState {
    dataSources: IDataSource[];
    dataContributors: IDataContributor[];
    systemAdmins: ISystemAdminState;
    agencies: IAgencyState;
    opsUnits: {[Id: number]: IOpsUnit; dam?: IOpsUnit[]};
    teams: {[Id: number]: ITeam};
    users: {[Id: number]: IUser};
}
export interface ISurveyState {
    surveyTrigger: ISurveyTrigger;
}
export interface IClientDataState {
    searchedClientInfo: IClientBasicInfo;
    screenedClients: {[screeningRequestId: string]: IFamilyScreeningResults};
}

export interface IClientUserDataState {
    dataAccess: ISubDomains;
    favourites: IFavourites;
    screenHistories: IScreenHistories;
}

export interface ICommonDataState {
    domains: IDomain[];
    participatingAgencies: IParticipatingAgencies;
    resources: IGroupedResource[];
    surveys: ISurveyState;
    userNotifications: IUserNotification[];
    userFlags: IUserFlags;
    releaseNotes: IReleaseNote[];
}
export interface IDataAccessMatrixState {
    dictionary: IDataAccessMatrix;
    opsUnits: {
        [opsUnitId: number]: {
            [domainId: number]: {
                [subDomainId: number]: {
                    [fieldId: number]: boolean;
                };
            };
        };
    };
}
export interface IDataFieldRequestStatus {
    dataFieldId: number;
    requestStatus: string;
    formId: number;
    referenceId: number;
    checked: boolean;
}
export interface IDataFieldAccessRejectedReasons {
    dataFieldIds: number[];
    rejectedAt: Date;
    rejectedBy: string;
    reason: string;
}
export interface IDataAccessMatrix2State {
    opsUnits: {
        [opsUnitId: number]: {
            dataFieldRequestStatuses: {
                [domainId: number]: {
                    [subDomainId: number]: {
                        [fieldId: number]: IDataFieldRequestStatus;
                    };
                };
            };
            subDomainReason: {
                [domainId: number]: {
                    [subDomainId: number]: {
                        approvedReasonForAccess: string;
                        updatedReasonForAccess?: {
                            formId: number;
                            referenceId: number;
                            reason: string;
                            status: string | null;
                        };
                    };
                };
            };
            accessRejectedReasons: {
                [domainId: number]: {
                    [subDomainId: number]: IDataFieldAccessRejectedReasons[];
                };
            };
            initialMatrix: {
                dataFieldRequestStatuses: {
                    [domainId: number]: {
                        [subDomainId: number]: {
                            [fieldId: number]: IDataFieldRequestStatus;
                        };
                    };
                };
                subDomainReason: {
                    [domainId: number]: {
                        [subDomainId: number]: {
                            approvedReasonForAccess: string;
                            updatedReasonForAccess?: {
                                formId: number;
                                referenceId: number;
                                reason: string;
                                status: string | null;
                            };
                        };
                    };
                };
            };
        };
    };
    domainDataRequestState: IDomainDataRequestState;
    subdomainDataRequestFields: {[subdomainId: number]: number[]};
    subdomainDataRequestReason: {[subdomainId: number]: string};
    fieldsUpdated: boolean;
    fieldsAdded: boolean;
}

export interface IDataState {
    fetch: IFetchState;
    settings: ISettingState;
    common: ICommonDataState;
    admin: IAdminDataState;
    flags: {[flag in Flag]: boolean};
    client: IClientDataState;
    clientUser: IClientUserDataState;
    dashboard: IDashboardState;
    dam: IDataAccessMatrixState;
    dam2: IDataAccessMatrix2State;
}

const AdminReducer = combineReducers({
    dataSources: dataSourcesReducer,
    dataContributors: dataContributorReducer,
    systemAdmins: systemAdminsReducer,
    agencies: agencyReducer,
    opsUnits: opsUnitReducer,
    teams: teamReducer,
    users: userReducer,
});
const CommonReducers = combineReducers({
    userNotifications: userNotificationReducer,
    domains: domainReducer,
    surveys: surveyReducer,
    resources: resourcesReducer,
    participatingAgencies: participatingAgencyReducer,
    userFlags: userFlagsReducer,
    releaseNotes: releaseNotesReducer,
});

export const DataReducer = combineReducers({
    common: CommonReducers,
    dashboard: dashboardReducer,
    fetch: fetchStateReducer,
    flags: hydrateFlagReducer,
    admin: AdminReducer,
    client: clientReducer,
    clientUser: clientUserReducer,
    settings: settingsReducer,
    dam: dataAccessMatrixReducer,
    dam2: dataAccessMatrix2Reducer,
});
