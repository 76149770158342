import {validateChecksum} from './validateChecksum';

export const checkCharCount = (text: string): boolean => {
    return text.length >= 1 && text.length <= 250 ? true : false;
};

export const checkCharCountPass = (password: string): boolean => {
    return password.length >= 12 && password.length <= 20 ? true : false;
};

export const checkNum = (text: string): boolean => {
    const regex = new RegExp('\\d');
    return regex.test(text);
};

export const checkCapital = (text: string): boolean => {
    const regex = new RegExp('[A-Z]');
    return regex.test(text);
};

export const checkSpecial = (text: string): boolean => {
    const regex = new RegExp('\\W');
    return regex.test(text);
};

export const getConsentIdError = (id: string): string => {
    if (id.length === 0) {
        return 'Enter a NRIC / FIN or Consent Serial Number';
    }
    // Checks if input is of format 0000000Z (due to typo) or A0000000Z (format correct but not valid)
    const nricFormatExp = new RegExp('^(([a-zA-Z]\\d{7}[a-zA-Z])|(\\d{7}[a-zA-Z]))$');
    if (nricFormatExp.test(id)) {
        return validateChecksum(id) ? '' : 'Enter a valid NRIC / FIN, e.g. S1234567D';
    }
    const serialNumberExp = new RegExp('^\\d+$');
    if (!serialNumberExp.test(id)) {
        return 'Consent Serial Number contains only numbers';
    }
    return '';
};

export const getNricError = (id: string): string => {
    return validateChecksum(id) ? '' : 'NRIC/FIN is invalid';
};
