import {Typography, Grid, Divider, Link} from '@mui/material';
import React from 'react';
import './SubDomainDisplay.scss';
import {ISubDomainNameProps} from '~/components/Client/SubDomainDisplay/PendingSubDomain';
import {COPIES, MAIL_TO_LINK} from '~/constants';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {BasicDemoMultipleHitsError} from './BasicDemographics/BasicDemographics';

const MULTIPLE_HITS_SUBDOMAINS = new Set(['DEMO', 'FAMILY', 'MARINFO']);

export const ErrorSubDomainText = (
    <Typography variant="body2">
        There was a hiccup in retrieving some information. Please{' '}
        <Link style={{textDecoration: 'underline'}} href={MAIL_TO_LINK} target="_blank" rel="noopener noreferrer">
            contact us
        </Link>{' '}
        and we will follow up with you shortly.
    </Typography>
);

export interface IErrorSubDomainTextProps {
    code: string;
}

export const ErrorSubDomainTextWithErrorCode = ({code = ''}: IErrorSubDomainTextProps) => {
    return (
        <Typography variant="body2">
            There was a hiccup in retrieving some information. Please{' '}
            <Link style={{textDecoration: 'underline'}} href={MAIL_TO_LINK} target="_blank" rel="noopener noreferrer">
                contact us
            </Link>{' '}
            and we will follow up with you shortly.{` (${code})`}
        </Typography>
    );
};

export const ErrorSubDomain = ({
    name,
    code,
    errorMessages,
    isSubSection = false,
    includeHeader = true,
}: ISubDomainNameProps) => {
    const generateErrorMessage = () => {
        if (code && MULTIPLE_HITS_SUBDOMAINS.has(code)) {
            if (errorMessages?.includes(COPIES.FSR_MULTIPLE_HITS_API_ERROR)) {
                return BasicDemoMultipleHitsError;
            }
        }
        return ErrorSubDomainText;
    };
    return (
        <>
            {includeHeader ? (
                <Grid container spacing={2}>
                    <Grid item id="header" xs={8}>
                        <Typography
                            variant={isSubSection ? 'h6' : 'h4'}
                            styleName={isSubSection ? 'subSectionHeader' : 'subDomainHeader'}
                        >
                            {name} (Unsuccessful)
                        </Typography>
                    </Grid>
                    {!isSubSection && (
                        <Grid item xs={12}>
                            <Divider styleName="divider" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ErrorMessage
                            message={generateErrorMessage()}
                            style={{paddingLeft: '8px', display: 'flex', alignItems: 'center'}}
                        />
                    </Grid>
                </Grid>
            ) : (
                <ErrorMessage message={generateErrorMessage()} style={{display: 'flex', alignItems: 'center'}} />
            )}
        </>
    );
};
