import React, {useRef, useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ACCESS_LEVELS} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {AddRounded as AddIcon} from '@mui/icons-material';
import {IRecipientGroup} from '~/interfaces/admin';
import {getAbbreviationFromBrackets} from '~/utils/nameUtils';
import {useSelectOptions} from './useSelectOptions';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import './AddRecipient.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {setAgencies} from '~/store/agency/agency.thunk';
import {setOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {setTeams, setTeam} from '~/store/team/team.thunk';
import {isOpsUnitAdmin, isTeamAdmin} from '~/utils/roleUtils';

export const useEntityStyles = makeStyles({
    autocomplete: {},
    optionSelected: {},
});

export const useUserGroupStyles = makeStyles({
    autocomplete: {},
    optionSelected: {},
});

export interface IAddRecipientProps {
    onAddRecipientGroups: (recipientGroups: IRecipientGroup[]) => void;
}

function usePrevious(value: string[]) {
    const ref = useRef<string[]>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export const AddRecipient = ({onAddRecipientGroups}: IAddRecipientProps) => {
    const dispatch = useAppDispatch();
    const entityStyles = useEntityStyles();
    const userGroupStyles = useUserGroupStyles();

    const auth = useContext(AuthenticationContext);
    const accessToken = auth.accessToken as IAccessToken;
    const accessLevel = accessToken?.Permissions.AccessLevel;
    const agencyId = Number(accessToken?.Permissions.AgencyId);
    const opsUnitId = Number(accessToken?.Permissions.OpsUnitId);
    const teamId = Number(accessToken?.Permissions.TeamId);

    const {
        getEntityType,
        getEntityLabel,
        allEntitiesSelected,
        allUserGroupsSelected,
        getSelectedGroupLabel,
        getSelectedEntityLabel,
        getSelectAllEntityLabel,
        getEntityOptions,
        getEntityIds,
        getUserOptions,
        getEntities,
    } = useSelectOptions(accessToken);

    const entities = getEntities();
    const entityOptions = getEntityOptions(entities);
    const isOpsUnitAdminWithOneTeam = isOpsUnitAdmin(accessToken) && entities.length == 1;
    const hideView = isTeamAdmin(accessToken) || isOpsUnitAdminWithOneTeam;
    const [selectedEntities, setSelectedEntities] = useState<string[]>(entityOptions);
    const [selectedUsers, setSelectedUsers] = useState<string[]>(getUserOptions());

    const prevEntity = usePrevious(entityOptions);

    useEffect(() => {
        switch (accessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                dispatch(setAgencies());
                break;
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                dispatch(setOpsUnits(agencyId));
                break;
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                dispatch(setTeams(opsUnitId));
                break;
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                dispatch(setTeams(opsUnitId));
                break;
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
                dispatch(setTeam(teamId));
                break;
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
                dispatch(setTeam(teamId));
                break;
            default:
        }
        if (hideView) {
            const team = entities[0];
            const taRecipientGroup: IRecipientGroup = {
                Id: getRecipientId(team.Name, ['Users']),
                EntityType: getEntityType(),
                EntityIds: [team.Id],
                UserGroup: 'Users',
                Description: getRecipientDescription(team.Name, 'Users'),
                Immutable: true,
            };
            onAddRecipientGroups([taRecipientGroup]);
        }
    }, []);

    useEffect(() => {
        if (JSON.stringify(prevEntity) !== JSON.stringify(entityOptions)) {
            setSelectedEntities(entityOptions);
        }
    }, [entityOptions]);

    const handleSelectUser = (selectedUsers: string[]) => {
        setSelectedUsers(selectedUsers);
    };

    const handleClearUsers = () => {
        setSelectedUsers([]);
    };

    const handleSelectAllUsers = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectUser(getUserOptions());
        } else {
            handleClearUsers();
        }
    };

    const handleSelectEntity = (selectedEntities: string[]) => {
        setSelectedEntities(selectedEntities);
    };

    const handleClearEntities = () => {
        setSelectedEntities([]);
    };

    const handleSelectAllEntities = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectEntity(entityOptions);
        } else {
            handleClearEntities();
        }
    };

    const getRecipientDescription = (selectedEntity: string, userGroup: string) => {
        const entityDesc =
            selectedEntity === getSelectAllEntityLabel()
                ? selectedEntity
                : getAbbreviationFromBrackets(selectedEntity) === ''
                ? selectedEntity
                : getAbbreviationFromBrackets(selectedEntity);
        return `${entityDesc} (${userGroup})`;
    };

    const getRecipientId = (selectedEntity: string, selectedUsers: string[]) => {
        return `${getEntityType()}-${selectedEntity}-${JSON.stringify(selectedUsers)}`;
    };

    const handleAddRecipient = () => {
        let recipientGroups: IRecipientGroup[] = [];
        const isAllEntitiesSelected = allEntitiesSelected(entityOptions, selectedEntities);
        const isAllUserGroupsSelected = allUserGroupsSelected(selectedUsers);
        if (isAllEntitiesSelected && isAllUserGroupsSelected) {
            const selectedGroup: IRecipientGroup = {
                Id: getRecipientId(getSelectAllEntityLabel(), selectedUsers),
                EntityType: getEntityType(),
                EntityIds: getEntityIds(entities, getSelectAllEntityLabel()),
                UserGroup: 'All Groups',
                Description: getRecipientDescription(getSelectAllEntityLabel(), 'All Groups'),
            };
            recipientGroups = [selectedGroup];
        } else if (isAllEntitiesSelected) {
            const selectedGroups = selectedUsers.map((userGroup) => {
                return {
                    Id: getRecipientId(getSelectAllEntityLabel(), [userGroup]),
                    EntityType: getEntityType(),
                    EntityIds: getEntityIds(entities, getSelectAllEntityLabel()),
                    UserGroup: userGroup,
                    Description: getRecipientDescription(getSelectAllEntityLabel(), userGroup),
                } as IRecipientGroup;
            });
            recipientGroups = [...recipientGroups, ...selectedGroups];
        } else if (isAllUserGroupsSelected) {
            const selectedGroups = selectedEntities.map((selectedEntity) => {
                return {
                    Id: getRecipientId(selectedEntity, selectedUsers),
                    EntityType: getEntityType(),
                    EntityIds: getEntityIds(entities, selectedEntity),
                    UserGroup: 'All Groups',
                    Description: getRecipientDescription(selectedEntity, 'All Groups'),
                } as IRecipientGroup;
            });
            recipientGroups = [...recipientGroups, ...selectedGroups];
        } else {
            selectedEntities.map((selectedEntity) => {
                const selectedGroups = selectedUsers.map((userGroup) => {
                    return {
                        Id: getRecipientId(selectedEntity, [userGroup]),
                        EntityType: getEntityType(),
                        EntityIds: getEntityIds(entities, selectedEntity),
                        UserGroup: userGroup,
                        Description: getRecipientDescription(selectedEntity, userGroup),
                    } as IRecipientGroup;
                });
                recipientGroups = [...recipientGroups, ...selectedGroups];
            });
        }

        onAddRecipientGroups(recipientGroups);
        setSelectedEntities([]);
        setSelectedUsers([]);
    };

    const isDisabled = () => {
        return selectedEntities.length === 0 || selectedUsers.length === 0;
    };

    return (
        <div styleName={`addRecipient ${hideView ? 'hideView' : ''}`}>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography>Select recipient groups</Typography>
                </Grid>
                <Grid item xs={3}>
                    <MultiSelectAutocomplete
                        data-testid="entity-input"
                        options={entityOptions}
                        selectedValues={selectedEntities}
                        label={getEntityLabel()}
                        selectAllLabel={getSelectAllEntityLabel()}
                        clearAllLabel={getSelectAllEntityLabel()}
                        tagRenderer={(value) => (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {getSelectedEntityLabel(entityOptions, value)}
                            </Typography>
                        )}
                        onToggleOption={handleSelectEntity}
                        onClearOptions={handleClearEntities}
                        onSelectAll={handleSelectAllEntities}
                        classes={entityStyles}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MultiSelectAutocomplete
                        data-testid="group-input"
                        options={getUserOptions()}
                        selectedValues={selectedUsers}
                        label="Select Group(s)"
                        selectAllLabel="All Groups"
                        clearAllLabel="All Groups"
                        tagRenderer={(value) => (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {getSelectedGroupLabel(value)}
                            </Typography>
                        )}
                        onToggleOption={handleSelectUser}
                        onClearOptions={handleClearUsers}
                        onSelectAll={handleSelectAllUsers}
                        classes={userGroupStyles}
                    />
                </Grid>
                <Grid>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleAddRecipient}
                        styleName="addRecipientButton"
                        disabled={isDisabled()}
                    >
                        <AddIcon />
                        Add Recipient
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
