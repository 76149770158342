import React, {useContext, useEffect} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {IAppState} from '~/store';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE} from '~/constants';
import {ProgressBar} from '~/components/Common/ProgressBar/ProgressBar';
import * as apiServices from '~/services/apiServices';

import './CorppassCallback.scss';

export const CorppassCallback = () => {
    const history = useHistory();
    const {setAccessToken, loginState, updateLoginState} = useContext(AuthenticationContext);
    const referer = useSelector((state: IAppState) => get(state, 'router.location.state.referer.pathname', '/'));

    useEffect(() => {
        (async () => {
            const url = new URL(window.location.href);
            const code = url.searchParams.get('code') || '';

            try {
                const token = await apiServices.authenticateCorppassUser(code);

                if (token) {
                    setAccessToken(token);
                    updateLoginState(LOGIN_STATE.LOGGED_IN);
                } else {
                    history.push('/unauthorized');
                }
            } catch (_) {
                history.push('/unauthorized');
            }
        })();
    }, []);

    if (loginState == LOGIN_STATE.LOGGED_IN) return <Redirect to={referer} />;

    return <ProgressBar {...{label: 'Signing In'}} />;
};
