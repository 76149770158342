import {IDataContributor, IMonthlyReviewResponse, IUser} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getDataContributors = async (): Promise<IDataContributor[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-contributors`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const createDataContributor = async (agencyId: number, user: IUser): Promise<IDataContributor> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/data-contributor`,
        data: JSON.stringify(user),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const deleteDataContributor = async (agencyId: number, userId: number, deletedReason: string): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/data-contributor/${userId}`,
        withCredentials: true,
        data: JSON.stringify({DeletedReason: deletedReason}),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getRequiresMonthlyDataContributorReview = async (): Promise<IMonthlyReviewResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-contributor-monthly-reviews`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createMonthlyDataContributorReview = async () => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/data-contributor-monthly-reviews`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};
