import React from 'react';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import './Attachment.scss';

export interface IAttachmentProps {
    file: File;
    onRemove: (file: File) => void;
}

export const Attachment = ({file, onRemove}: IAttachmentProps) => {
    return (
        <div styleName="attachment">
            <span styleName="name">{file.name}</span>
            <span styleName="size">{`(${file.size / 1000} KB)`}</span>
            <CloseIcon styleName="icon" onClick={() => onRemove(file)} />
        </div>
    );
};
