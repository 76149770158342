import React, {useState} from 'react';
import {Dialog, DialogContent, Typography, Grid, IconButton} from '@mui/material';
import {IClientBasicInfo, IFamilyMember} from '~/interfaces/client';
import {ClientSearchForm} from '../ClientSearchForm/ClientSearchForm';
import {Person} from './Person';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {getFamilyMember} from '~/services/clientServices';
import {BackButton} from '~/components/Common/Button/BackButton';
import {familyMembersPerScreeningLimit, Relationship} from '~/constants';
import './AddPersonDialog.scss';

export interface IAddPersonDialogProps {
    open: boolean;
    onClose: () => void;
    clientInfo: IClientBasicInfo;
    selectedFamilyMembers: IFamilyMember[];
    onRemove: (personInfo: IFamilyMember) => void;
    onAdd: (personInfo: IFamilyMember) => void;
    isDuplicate: (personInfo: IFamilyMember) => boolean;
}

export const AddPersonDialog = ({
    open,
    onClose,
    clientInfo,
    selectedFamilyMembers,
    onRemove,
    onAdd,
    isDuplicate,
}: IAddPersonDialogProps) => {
    const [searchResult, setSearchResult] = useState<IFamilyMember | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const client: IFamilyMember = {
        Name: clientInfo.Name,
        Relationship: Relationship.CLIENT,
        UIN: clientInfo.UIN,
    };

    const handleSearch = async (uin: string) => {
        setIsLoading(true);
        let result: IFamilyMember = {
            Name: uin,
            Relationship: Relationship.UNKNOWN,
            UIN: uin,
        };

        try {
            const familyMember = await getFamilyMember(uin);
            result = {...familyMember};
        } catch {}
        setError('');
        setSearchResult(result);
        setIsLoading(false);
    };

    const handleAdd = (personInfo: IFamilyMember) => {
        if (selectedFamilyMembers.length >= familyMembersPerScreeningLimit) {
            setError(
                `Unable to add due to limit of additional ${familyMembersPerScreeningLimit} persons per screening!`,
            );
        } else if (isDuplicate(personInfo)) {
            setError('Person with same NRIC/FIN already exists!');
        } else {
            onAdd(personInfo);
            setSearchResult(null);
            setError('');
        }
    };

    const RequestedPersonList = (
        <>
            <Grid item xs={12}>
                <Typography styleName="title">Requested Person(s)</Typography>
            </Grid>
            <Grid item xs={12}>
                <Person personInfo={client} actionType="none" />
                {selectedFamilyMembers.map((member) => (
                    <Person key={member.UIN} actionType="remove" personInfo={member} onRemove={onRemove} />
                ))}
            </Grid>
        </>
    );

    const SearchResult = searchResult && (
        <>
            <Grid item xs={12}>
                <Typography styleName="title">Search Results</Typography>
            </Grid>
            <Grid item xs={12}>
                <Person actionType="add" personInfo={searchResult} onAdd={handleAdd} />
            </Grid>
            <Grid item xs={12}>
                <Typography styleName="error">{error}</Typography>
            </Grid>
        </>
    );

    return (
        <Dialog
            open={open}
            aria-describedby="alert-dialog-description"
            styleName="addPersonDialog"
            data-testid="addPersonDialog"
        >
            <DialogContent styleName="container">
                <Grid container>
                    <Grid item xs={1}>
                        {searchResult && <BackButton onClick={() => setSearchResult(null)} />}
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" onClick={onClose} data-testid="closePersonDialogButton">
                            <CloseIcon styleName="closeButton" />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography styleName="title">Add Other Person(s)</Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 24}}>
                        <ClientSearchForm
                            disabled={isLoading}
                            onSubmit={handleSearch}
                            placeholder="Search other people by NRIC"
                        />
                    </Grid>

                    {searchResult ? SearchResult : RequestedPersonList}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
