import {useEffect} from 'react';

export const useSuppressWidget = () => {
    const disableWidget = (flag: boolean) => {
        if (window && typeof window.zE === 'function')
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    contactForm: {
                        suppress: flag,
                    },
                },
            });
    };

    useEffect(() => {
        disableWidget(true);
        return () => disableWidget(false);
    }, []);

    return;
};
