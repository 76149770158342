import {createAsyncThunk} from '@reduxjs/toolkit';
import * as systemAdminServices from '~/services/systemAdminServices';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {ISystemAdminResponse, IUser} from '~/interfaces/admin';

export const setSystemAdmins = createAsyncThunk<{}, void, {state: RootState}>(
    '/systemAdmin/setSystemAdmins',
    async (_, {dispatch, getState}) => {
        if (!getState().data.flags[Flag.SYSTEM_ADMINS]) {
            dispatch({type: fetchStateActions.SET_SYSTEMADMINS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const systemAdmins = await systemAdminServices.getSystemAdmins();
                dispatch({type: fetchStateActions.SET_SYSTEMADMINS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
                dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.SYSTEM_ADMINS});
                return systemAdmins;
            } catch (err) {
                dispatch({type: fetchStateActions.SET_SYSTEMADMINS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            }
        }
    },
);

export const createSystemAdmin = createAsyncThunk<ISystemAdminResponse, IUser, {state: RootState}>(
    '/systemAdmin/createSystemAdmin',
    async (systemAdmin, {rejectWithValue}) => {
        try {
            const createSystemAdminResponse = await systemAdminServices.createSystemAdmin(systemAdmin);
            if (createSystemAdminResponse.Error && createSystemAdminResponse.Error !== '') {
                return rejectWithValue(createSystemAdminResponse.Error);
            }
            return createSystemAdminResponse;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const updateSystemAdmin = createAsyncThunk<ISystemAdminResponse, IUser, {state: RootState}>(
    '/systemAdmin/updateSystemAdmin',
    async (systemAdmin, {rejectWithValue}) => {
        try {
            const updateSystemAdminResponse = await systemAdminServices.updateSystemAdmin(systemAdmin);
            if (updateSystemAdminResponse.Error && updateSystemAdminResponse.Error !== '') {
                return rejectWithValue(updateSystemAdminResponse.Error);
            }
            return updateSystemAdminResponse;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const activateSystemAdmin = createAsyncThunk<ISystemAdminResponse, {userId: number}, {state: RootState}>(
    '/systemAdmin/activateSystemAdmin',
    async ({userId}, {rejectWithValue}) => {
        try {
            const deleteSystemAdminResponse = await systemAdminServices.activateSystemAdmin(userId);
            if (deleteSystemAdminResponse.Error) {
                return rejectWithValue(deleteSystemAdminResponse.Error);
            }
            return {SystemAdminId: userId};
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const deleteSystemAdmin = createAsyncThunk<
    ISystemAdminResponse,
    {userId: number; deletedReason: string},
    {state: RootState}
>('/systemAdmin/deleteSystemAdmin', async ({userId, deletedReason}, {rejectWithValue}) => {
    try {
        const deleteSystemAdminResponse = await systemAdminServices.deleteSystemAdmin(userId, deletedReason);
        if (deleteSystemAdminResponse.Error) {
            return rejectWithValue(deleteSystemAdminResponse.Error);
        }
        return {SystemAdminId: 0};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});
