import React from 'react';
import {Box, Grid, Button, Typography} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {ISummaryFileMeta} from '~/interfaces/common';
import {format} from 'date-fns';
import GetAppIcon from '@mui/icons-material/GetApp';
import ReplayIcon from '@mui/icons-material/Replay';
import WarningIcon from '@mui/icons-material/Warning';
import {IFetchStatus} from '~/interfaces/common';
import './UploadHistory.scss';

interface IUploadHistoryProps {
    history: ISummaryFileMeta[];
    errorGettingHistory: boolean;
    clickDownloadFileHandler: (responseFilename: string, uploadRequestId: string) => void;
    rollbackHandler: (fileName: string, uploadRequestId: string) => void;
    isAdmin: boolean;
    historyApiStatus: IFetchStatus;
    enableRollback: boolean;
}

const ROLLEDBACK_ROW_STYLE = {background: '#F0F0F0'};
const ROLLEDBACK_CELL_STYLE = {color: '#979797'};

export const UploadHistory = ({
    history,
    errorGettingHistory,
    clickDownloadFileHandler,
    rollbackHandler,
    isAdmin,
    historyApiStatus,
    enableRollback,
}: IUploadHistoryProps) => {
    //Rollback only available on the latest active record
    const latestActiveRecord = history.find((upload) => !Boolean(upload.RollbackedAt));

    const handleRollback = (history: ISummaryFileMeta) => {
        rollbackHandler(history.FileName, history.UploadRequestId);
    };

    const handleDownload = (history: ISummaryFileMeta) => {
        clickDownloadFileHandler(history.ResponseFilename, history.UploadRequestId);
    };

    //E.g. 01 Jan 2022 01:00 PM
    const getDate = (dateString: string) => format(new Date(dateString), 'dd MMM yyyy hh:mm aa');

    const nameOfFileCell = (history: ISummaryFileMeta) => <Box>{history.FileName}</Box>;

    const dateUploadedCell = (history: ISummaryFileMeta) => (
        <Grid>
            <Typography variant="body1">{getDate(history.CreatedAt)}</Typography>
            <Typography variant="caption"> {history.UploadedBy} </Typography>
        </Grid>
    );

    const fileStatusCell = (history: ISummaryFileMeta) => (
        <Grid>
            {history.UploadRequestId === latestActiveRecord?.UploadRequestId
                ? isAdmin &&
                  enableRollback && (
                      <Button onClick={() => handleRollback(history)} styleName="buttonStyle">
                          <ReplayIcon styleName={'buttonIcon'} />
                          ROLL BACK
                      </Button>
                  )
                : history.RollbackedAt && (
                      <>
                          <Typography variant="body1">{getDate(history.RollbackedAt)}</Typography>
                          <Typography variant="caption"> {history.RollbackedBy} </Typography>
                      </>
                  )}
        </Grid>
    );

    const summaryReportCell = (history: ISummaryFileMeta) => (
        <Box styleName="centerAligned">
            {(!isAdmin || history.SaUploaded) && (
                <Button onClick={() => handleDownload(history)} styleName="buttonStyle">
                    <GetAppIcon styleName={'buttonIcon'} />
                    DOWNLOAD
                </Button>
            )}
        </Box>
    );

    const customRowStyle = (history: ISummaryFileMeta) => {
        return history.RollbackedAt ? ROLLEDBACK_ROW_STYLE : {};
    };

    const customCellStyle = (history: ISummaryFileMeta) => {
        return history.RollbackedAt ? ROLLEDBACK_CELL_STYLE : {};
    };

    const dateUploadHeader = (
        <Grid>
            <Typography variant="body1">Date Uploaded</Typography>
            <Typography variant="caption">Uploaded By</Typography>
        </Grid>
    );

    const fileStatusHeader = (
        <Grid>
            <Typography variant="body1">Date of Roll Back</Typography>
            <Typography variant="caption">Implemented By</Typography>
        </Grid>
    );

    const summaryReportHeader = (
        <Grid styleName="centerAligned">
            <Typography variant="body1">Summary Report</Typography>
        </Grid>
    );

    const ListModelContent = () => (
        <ListModel
            columns={[nameOfFileCell, dateUploadedCell, fileStatusCell, summaryReportCell]}
            headers={['Name of File', dateUploadHeader, fileStatusHeader, summaryReportHeader]}
            modelComparator={[() => 0, () => 0, () => 0, () => 0]}
            modelList={history}
            variant="paginated"
            customRowStyle={customRowStyle}
            customCellStyle={customCellStyle}
            sortable={false}
            data-testid="upload-history-table"
        />
    );

    const ListModelContentWithLoader = WithLoader<{}>(ListModelContent, historyApiStatus);

    return (
        <>
            {history.length > 0 && (
                <Box styleName="uploadHistoryTable">
                    <Grid item xs={12} styleName="reportTableStyle">
                        <ListModelContentWithLoader />
                    </Grid>
                </Box>
            )}
            {errorGettingHistory && (
                <Grid item styleName="showFlexCenter" data-testid="failure">
                    <WarningIcon styleName="warningIcon" />
                    <Typography styleName="historyErrorText">
                        Failed to retrieve past records. Please contact your system administrator.
                    </Typography>
                </Grid>
            )}
            {!errorGettingHistory && history.length === 0 && (
                <Grid item styleName="showFlexCenter" data-testid="no-records">
                    <Typography styleName="noRecordsText">No records found.</Typography>
                </Grid>
            )}
        </>
    );
};
