export const tableTheme: {} = {
    styleOverrides: {
        root: {
            fontWeight: 400,
            fontSize: 16,
        },
        head: {
            fontWeight: 400,
            fontSize: 16,
        },
    },
};
