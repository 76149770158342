import React, {useState, useEffect} from 'react';
import {Grid, Stepper, Step, StepButton} from '@mui/material';
import {IClientBasicInfo, IFamilyMember} from '~/interfaces/client';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {ClientInfoFooter} from '~/components/Client/ClientInfoFooter/ClientInfoFooter';
import {ACCESS_REASON, CONSENT_TYPE, COPIES} from '~/constants';
import {IAccessReasons} from '~/interfaces/clientUser';
import {FamilyConsents} from './Consent/FamilyConsents';
import {FamilyAccessReasons} from './AccessReason/FamilyAccessReasons';
import styles from './ConsentAndAccess.scss';
import {FullScreenDialog} from '~/components/Common/FullScreenDialog/FullScreenDialog';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {ChevronRight} from '@mui/icons-material';

export interface IConsentAndAccessProps {
    open: boolean;
    onClose: () => void;
    selectedFamilyMembers: IFamilyMember[];
    selectedSubDomains: IRowData[];
    basicInfo: IClientBasicInfo;
    onDeleteSubDomain: (subDomainId: number) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onProceed: (subDomainId?: any) => void;
    familyConsents: {[key: string]: CONSENT_TYPE};
    familyReasons: {[key: string]: IAccessReasons};
    onConsentsChange: (
        member: IFamilyMember,
        consentType: CONSENT_TYPE,
        consentId?: number,
        minorConsentDate?: Date,
    ) => void;
    onReasonsChange: (member: IFamilyMember, accessReason: IAccessReasons) => void;
    onApplyAllReasons: (accessReason: IAccessReasons) => void;
}

export const isReasonsInvalid = (reasons: IAccessReasons) =>
    reasons.Codes.length === 0 || (reasons.Codes.includes(ACCESS_REASON.OTHERS) && reasons.OtherReason === '');

export const ConsentAndAccess = ({
    open,
    onClose,
    selectedFamilyMembers,
    selectedSubDomains,
    onDeleteSubDomain,
    basicInfo,
    onProceed,
    familyConsents,
    familyReasons,
    onConsentsChange,
    onReasonsChange,
    onApplyAllReasons,
}: IConsentAndAccessProps) => {
    const legislativelyLockedFields = selectedSubDomains.filter((row) => row.isLegislativelyLocked);
    const stigmatisingFields = selectedSubDomains.filter((row) => row.isStigmatising);

    const handleDeleteTag = (item: IRowData) => {
        // subdomain with access reason is removed and consent page is completed
        if (item.isStigmatising && stigmatisingFields.length - 1 === 0 && legislativelyLockedFields.length > 0) {
            onProceed(item.subDomainId);
        } else {
            onDeleteSubDomain(item.subDomainId);
        }
    };

    const [steps, setSteps] = useState<string[]>([]);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState<boolean>(false);
    const [hasMinorDateError, setHasMinorDateError] = useState<boolean>(false);

    useEffect(() => {
        initSteps();
    }, [selectedSubDomains]);

    const initSteps = () => {
        const steps = [];
        const hasLegislativelyLocked = legislativelyLockedFields.length > 0;
        const hasStigmatising = stigmatisingFields.length > 0;

        const membersRequireConsent = selectedFamilyMembers.filter((member) => !member.Consent);
        const hasRequireConsent = membersRequireConsent.length > 0;

        hasLegislativelyLocked && hasRequireConsent && steps.push(COPIES.CONSENT_TITLE);
        hasStigmatising && steps.push(COPIES.ACCESS_REASON_TITLE);
        setSteps(steps);
    };

    const hasNextStep = () => activeStep < steps.length - 1;
    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    };
    const handlePrevStep = () => {
        setActiveStep(activeStep - 1);
    };

    const isButtonDisabled = () => {
        const stepTitle = steps[activeStep];
        switch (stepTitle) {
            case COPIES.CONSENT_TITLE:
                return Object.values(familyConsents).some((consent) => consent == CONSENT_TYPE.NOT_SELECTED);
            case COPIES.ACCESS_REASON_TITLE:
                return stigmatisingFields.length > 0 && Object.values(familyReasons).some(isReasonsInvalid);
            default:
                return true;
        }
    };

    const getStepContent = (step: number) => {
        const stepTitle = steps[step];
        switch (stepTitle) {
            case COPIES.CONSENT_TITLE:
                return (
                    <FamilyConsents
                        selectedFamilyMembers={selectedFamilyMembers}
                        legislativelyLockedFields={legislativelyLockedFields}
                        onDeleteSubDomain={handleDeleteTag}
                        onConsentTypeChange={onConsentsChange}
                        setHasMinorDateError={setHasMinorDateError}
                    />
                );
            case COPIES.ACCESS_REASON_TITLE:
                return (
                    <FamilyAccessReasons
                        selectedFamilyMembers={selectedFamilyMembers}
                        familyAccessReasons={familyReasons}
                        legislativelyLockedFields={stigmatisingFields}
                        onDeleteSubDomain={handleDeleteTag}
                        onAccessReasonsChange={onReasonsChange}
                        onApplyAllReasons={onApplyAllReasons}
                    />
                );
            default:
                return null;
        }
    };

    const handleConfirmationConfirm = () => {
        setActiveStep(0);
        setConfirmCancelOpen(false);
        onClose();
    };

    return (
        <FullScreenDialog
            id="consentDialog"
            open={open}
            onClose={() => setConfirmCancelOpen(true)}
            onBack={activeStep > 0 ? handlePrevStep : undefined}
            backText="BACK TO CONSENT"
        >
            <Grid container className={styles.consentAndAccess}>
                <Grid item xs={8}>
                    <Stepper nonLinear activeStep={activeStep} className={styles.stepper} connector={<ChevronRight />}>
                        {steps.map((label) => {
                            return (
                                <Step key={label}>
                                    <StepButton disabled>{label}</StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>

                <Grid item xs={12}>
                    {getStepContent(activeStep)}
                </Grid>

                <ConfirmDialog
                    onCancel={() => setConfirmCancelOpen(false)}
                    onConfirm={handleConfirmationConfirm}
                    open={confirmCancelOpen}
                    title="Discard Changes?"
                    message="Changes you have made so far will not be saved."
                    cancelOption="Cancel"
                    confirmOption="Discard"
                />

                <ClientInfoFooter
                    clientName={basicInfo.Name}
                    clientUIN={basicInfo.UIN}
                    clientConsent={basicInfo.ClientConsent}
                    buttonText={hasNextStep() ? 'NEXT' : 'VIEW INFORMATION'}
                    isButtonDisabled={isButtonDisabled() || hasMinorDateError}
                    isButtonHidden={false}
                    isFooterHidden={false}
                    isButtonLoading={false}
                    onButtonClick={hasNextStep() ? handleNextStep : onProceed}
                    legislativelyLockedFields={legislativelyLockedFields}
                    screenName={steps[activeStep]}
                    onDeleteSubDomain={onDeleteSubDomain}
                />
            </Grid>
        </FullScreenDialog>
    );
};
