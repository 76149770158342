import React from 'react';
import {Grid, Typography, Link as MuiLink} from '@mui/material';
import {dateFormatter} from '~/utils/dateUtils';
import './BasicDemographics.scss';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {StatusObject, renderIncompleteFieldByStatus} from '../SubDomainDisplay';
import {PendingSubDomain} from '../PendingSubDomain';
import {ErrorSubDomain, ErrorSubDomainText} from '../ErrorSubDomain';
import {config} from '~/config';
import {NoData} from '../CompletedSubDomain';
import {SubDomainStatus} from '~/interfaces/client';
import {COPIES, FIELD_VALUES, MAIL_TO_LINK} from '~/constants';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {allNoResults, extractAllValues, isNotEmpty, formatValue, formatMobileNumber} from '~/utils/contentUtils';

const SFTP_DOS = 'SFTP_DOS';
const FSR = 'FSR';

const fillFsrData = (value: string) => {
    return {
        Name: value,
        Sex: value,
        ResidencyStatus: value,
        Nationality: value,
        MaritalStatus: value,
        DateOfBirth: value,
        DateOfDeath: value,
    };
};

const fillCanvasData = (value: string) => {
    return {
        EthnicGroup: value,
        CountryOfBirth: value,
        Religion: value,
        Address: value,
        AddressType: value,
        AddressChangeDate: value,
    };
};

export interface IBasicDemographics {
    FSR: {
        Name: string;
        Sex: string;
        ResidencyStatus: string;
        Nationality: string;
        MaritalStatus: string;
        DateOfBirth: string;
        DateOfDeath: string;
        UIN: string;
    };
    CANVAS: {
        Address: string;
        AddressType: string;
        AddressChangeDate: string;
        EthnicGroup: string;
        CountryOfBirth: string;
        Religion: string;
    };
    SFTP_DOS: {
        MobileNumber: string;
    };
}

export const BasicDemoMultipleHitsError = (
    <Typography variant="body2">
        There was an error retrieving some data from the source. Please{' '}
        <MuiLink style={{textDecoration: 'underline'}} href={MAIL_TO_LINK} target="_blank" rel="noopener noreferrer">
            contact us
        </MuiLink>{' '}
        and we will follow up with you shortly.
    </Typography>
);

export const BasicDemographics: React.FunctionComponent<{
    data: IBasicDemographics;
    status: StatusObject;
    errorMessages?: string[];
}> = ({data, status, errorMessages}) => {
    const statusDOS = status[SFTP_DOS] && status[SFTP_DOS].toLowerCase();
    const statusFSR = status[FSR] && status[FSR].toLowerCase();
    const errorMessage =
        statusFSR === SubDomainStatus.ERROR || statusDOS === SubDomainStatus.ERROR
            ? errorMessages?.includes(COPIES.FSR_MULTIPLE_HITS_API_ERROR)
                ? BasicDemoMultipleHitsError
                : ErrorSubDomainText
            : '';

    let fsrData;
    if (status[FSR] === SubDomainStatus.ERROR && allNoResults(extractAllValues(data.FSR))) {
        fsrData = fillFsrData(FIELD_VALUES.UNSUCCESSFUL);
    } else {
        fsrData = Boolean(data.FSR) ? data.FSR : fillFsrData(FIELD_VALUES.NO_RESULTS);
    }

    let canvasData;
    if (!config.featureFlags.ENABLE_CANVAS) {
        canvasData = fillCanvasData(FIELD_VALUES.NO_ACCESS);
    } else {
        if (status[FSR] === SubDomainStatus.ERROR && allNoResults(extractAllValues(data.CANVAS))) {
            canvasData = fillCanvasData(FIELD_VALUES.UNSUCCESSFUL);
        } else {
            canvasData = Boolean(data.CANVAS) ? data.CANVAS : fillCanvasData(FIELD_VALUES.NO_RESULTS);
        }
    }

    let dosData;
    if (status[SFTP_DOS] === SubDomainStatus.ERROR && allNoResults(extractAllValues(data.SFTP_DOS))) {
        dosData = {MobileNumber: FIELD_VALUES.UNSUCCESSFUL};
    } else {
        dosData = Boolean(data.SFTP_DOS) ? data.SFTP_DOS : {MobileNumber: FIELD_VALUES.NO_RESULTS};
    }

    const {Name, Sex, Nationality, ResidencyStatus, DateOfBirth, MaritalStatus, DateOfDeath} = fsrData;
    const {EthnicGroup, CountryOfBirth, Religion, Address, AddressType, AddressChangeDate} = canvasData;
    const {MobileNumber} = dosData;

    const allFields = [...extractAllValues(fsrData), ...extractAllValues(canvasData), ...extractAllValues(dosData)];

    const noData =
        statusDOS === SubDomainStatus.COMPLETED && statusFSR === SubDomainStatus.COMPLETED && allNoResults(allFields);

    const fields = [
        {label: 'Sex', value: Sex},
        {label: 'Nationality', value: Nationality},
        {label: 'Residential Status', value: ResidencyStatus},
        {label: 'Ethnicity', value: EthnicGroup},
        {label: 'Country of Birth', value: CountryOfBirth},
        {
            label: 'Date of Birth',
            value: formatValue(DateOfBirth, dateFormatter),
        },
        {label: 'Religion', value: Religion},
        {label: 'Marital Status', value: MaritalStatus},
        {
            label: 'Date of Death',
            value: formatValue(DateOfDeath, dateFormatter),
        },
        {label: 'Address', value: Address},
        {label: 'Address Type', value: AddressType},
        {
            label: 'Address Change Date',
            value: formatValue(AddressChangeDate, dateFormatter),
        },
        {
            label: 'Mobile Number',
            value:
                statusDOS === 'completed'
                    ? formatMobileNumber(MobileNumber)
                    : renderIncompleteFieldByStatus(status[SFTP_DOS]),
        },
    ];

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <>
                    {errorMessage && <ErrorMessage message={errorMessage} style={{marginBottom: '16px'}} />}
                    <Grid container spacing={3}>
                        {statusDOS === 'pending' && (
                            <Grid item xs={12}>
                                <PendingSubDomain includeHeader={false} />
                            </Grid>
                        )}
                        {statusDOS === 'error' && (
                            <Grid item xs={12}>
                                <ErrorSubDomain includeHeader={false} />
                            </Grid>
                        )}
                        {isNotEmpty(Name) && (
                            <Grid item xs={12}>
                                <Typography variant="h6">{Name}</Typography>
                                {isNotEmpty(DateOfDeath) && (
                                    <Typography styleName="deceasedTag" variant="subtitle1">
                                        {COPIES.DECEASED}
                                    </Typography>
                                )}
                            </Grid>
                        )}
                        <SimpleGridDisplay fields={fields} />
                    </Grid>
                </>
            )}
        </>
    );
};
