import React, {useState, useContext} from 'react';
import {ACCESS_LEVELS} from '~/constants';
import {Grid} from '@mui/material';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {ActionCard} from '~/components/Common/Cards/ActionCard';
import {UserListConfirmDialog} from '~/pages/Admin/UserList/UserListConfirmDialog';
import {COPIES, MONTHLY_REVIEW_STATUS} from '~/constants';
import {getOngoingMonthlyReviewMessage} from '~/utils/contentUtils';
import {IMonthlyReviewResponse} from '~/interfaces/admin';
import {useLocation} from 'react-router';
import {formatDate} from '~/utils/dateUtils';
export interface IReviewBannerProps {
    monthlyReviewStatus: IMonthlyReviewResponse;
    handleReviewSubmit?: () => void;
    disabled?: boolean;
    messageCallToAction?: React.ReactNode;
}

export const ReviewBanner = ({
    monthlyReviewStatus,
    handleReviewSubmit = undefined,
    disabled,
    messageCallToAction = undefined,
}: IReviewBannerProps) => {
    const [reviewOpen, setReviewOpen] = useState<boolean>(false);
    const {accessToken} = useContext(AuthenticationContext);
    const location = useLocation().pathname;

    const setColour = (status: string): string => {
        switch (status) {
            case MONTHLY_REVIEW_STATUS.ONGOING:
                return '#3F91EF';
            case MONTHLY_REVIEW_STATUS.COMPLETED:
                return isAgencyAdmin ? '#3F91EF' : '#3C3C3C';
            case MONTHLY_REVIEW_STATUS.INCOMPLETE:
                return isAgencyAdmin ? '#3C3C3C' : '#FDAB30';
            default:
                return '';
        }
    };

    const isAgencyAdmin = accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR;

    return (
        <>
            <Grid item style={{marginBottom: 10}}>
                {monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.ONGOING ? (
                    <ActionCard
                        message={getOngoingMonthlyReviewMessage(
                            monthlyReviewStatus,
                            accessToken?.Permissions.AccessLevel,
                            location,
                        )}
                        color={setColour(monthlyReviewStatus.Status)}
                        onSubmit={!isAgencyAdmin ? () => setReviewOpen(true) : undefined}
                        buttonText="MARK AS REVIEWED"
                        disabled={disabled}
                    />
                ) : (
                    <ActionCard
                        message={getOngoingMonthlyReviewMessage(
                            monthlyReviewStatus,
                            accessToken?.Permissions.AccessLevel,
                            location,
                        )}
                        color={setColour(monthlyReviewStatus.Status)}
                        status={monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.COMPLETED}
                        showError={monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.INCOMPLETE}
                        messageCallToAction={messageCallToAction}
                    />
                )}
            </Grid>
            <UserListConfirmDialog
                open={reviewOpen}
                title={COPIES.MONTHLY_REVIEW_CONFIRMATION_TITLE}
                message={
                    COPIES.MONTHLY_REVIEW_CONFIRMATION_MESSAGE +
                    ' ' +
                    formatDate(
                        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                        true,
                        false,
                    )
                }
                cancelOption="CANCEL"
                confirmOption="SUBMIT"
                onCancel={() => setReviewOpen(false)}
                onConfirm={() => {
                    handleReviewSubmit && handleReviewSubmit();
                    setReviewOpen(false);
                }}
            />
        </>
    );
};
