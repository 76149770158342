import React from 'react';
import {Grid} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './LastFlatSaleDetails.scss';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {formatDate, getDate} from '~/utils/dateUtils';
import {currencyFormatter} from '~/utils/currencyUtils';
import {allNoAccess, formatValue, isNotEmpty} from '~/utils/contentUtils';

export interface ILastFlatSaleDetails extends ILastFlatSaleTable {
    LastFlatSaleDate: string;
    SolicitorCode: string;
}

interface ILastFlatSaleTable {
    FinalisedFlatSaleDate: string;
    SaleProceeds: string;
}

export const LastFlatSaleDetails: React.FunctionComponent<{data?: ILastFlatSaleDetails}> = ({data}) => {
    if (!data) return <></>;
    const finalisedFlatSaleDate = () =>
        isNotEmpty(data.FinalisedFlatSaleDate)
            ? formatDate(getDate(data.FinalisedFlatSaleDate), true)
            : data.FinalisedFlatSaleDate;
    const saleProceeds = (data: ILastFlatSaleTable) => <div>{formatValue(data.SaleProceeds, currencyFormatter)}</div>;
    const lastFlatSaleInformation = [
        {
            label: 'Last Flat Sale Date',
            value: isNotEmpty(data.LastFlatSaleDate)
                ? formatDate(getDate(data.LastFlatSaleDate), true, true)
                : data.LastFlatSaleDate,
        },
        {label: 'Solicitor Code', value: data.SolicitorCode},
    ];
    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.customTable}>
                <Grid data-testid="lastFlatSaleInformation" container spacing={3} id={styles.details}>
                    <SimpleGridDisplay fields={lastFlatSaleInformation} />
                </Grid>
                {!allNoAccess([data.FinalisedFlatSaleDate, data.SaleProceeds]) && (
                    <ListModel
                        columns={[finalisedFlatSaleDate, saleProceeds]}
                        headers={['Finalised Flat Sale Date', 'Sale Proceeds ($)']}
                        modelList={[
                            {Id: 1, FinalisedFlatSaleDate: data.FinalisedFlatSaleDate, SaleProceeds: data.SaleProceeds},
                        ]}
                        modelComparator={[() => 0, () => 0]}
                        variant="expanded"
                        sortable={false}
                    />
                )}
            </div>
        </Grid>
    );
};
