import {IDomain, ISubDomain} from '~/interfaces/common';
import {IAppState} from '~/store';
import {sortByPropertyAlphabetically} from '~/utils/arrayUtils';

export const getSubDomains = (state: IAppState) => {
    const domains = state.data.common.domains;

    return domains.reduce((accumulator: ISubDomain[], domain: IDomain) => {
        return [...accumulator, ...domain.SubDomains];
    }, []);
};

export const getSortedSubDomains = (state: IAppState) => {
    const domains = state.data.common.domains;
    const subDomains = domains.flatMap((domain) => domain.SubDomains);
    const sortedSubDomains = sortByPropertyAlphabetically(subDomains, 'Name');

    return sortedSubDomains;
};
