import {createSlice} from '@reduxjs/toolkit';
import {IUserNotification} from '~/interfaces/common';
import {setUserNotification, updateUserNotifications} from './userNotification.thunk';

export const initialUserNotificationState: IUserNotification[] = [];

export const userNotificationSlice = createSlice({
    name: 'userNotification',
    initialState: initialUserNotificationState,
    reducers: {
        UPDATE_USER_NOTIFICATIONS_LOCAL: (state) => {
            state.map((item) => {
                item.Read = true;
                return item;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUserNotification.fulfilled, (_, action) => {
                return action.payload;
            })
            .addCase(updateUserNotifications.fulfilled, (state) => {
                return state;
            });
    },
});

export const userNotificationActions = userNotificationSlice.actions;
export default userNotificationSlice.reducer;
