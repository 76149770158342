import {Dialog, Button, CircularProgress, Box, Typography} from '@mui/material';
import React from 'react';
import './UserListConfirmDialogButton.scss';
export interface IConfirmDialogProps {
    id?: string;
    isLoading?: boolean;
    onCancel: (event: object) => void;
    onConfirm: (event: object) => void;
    cancelOption: string;
    confirmOption: string;
    open: boolean;
    title: string;
    message: string | React.ReactNode;
}

export const UserListConfirmDialog = ({
    open,
    onCancel,
    onConfirm,
    id,
    cancelOption,
    confirmOption,
    title,
    message,
    isLoading,
}: IConfirmDialogProps) => {
    return (
        <Dialog
            PaperProps={{style: {width: '532px', borderRadius: '8px'}}}
            id={id}
            open={open}
            aria-describedby="alert-dialog-description"
        >
            <Box padding={3}>
                <Box marginBottom={2}>
                    <Typography styleName="confirmDialogTitle">{title}</Typography>
                </Box>
                <Box marginBottom={3}>
                    <Typography styleName="confirmDialogText">{message}</Typography>
                </Box>
                <Box display="flex" justifyContent="end">
                    <Button
                        onClick={onCancel}
                        color="primary"
                        variant="outlined"
                        id="ul-cancel-dialog-button"
                        disabled={isLoading}
                        styleName="confirmDialogButton"
                        style={{marginRight: '16px'}}
                    >
                        {cancelOption}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        color="primary"
                        variant="contained"
                        id="ul-confirm-dialog-button"
                        disabled={isLoading}
                        styleName="confirmDialogButton"
                    >
                        {isLoading ? <CircularProgress size={20} /> : confirmOption}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
