import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {formatCurrency} from '~/utils/currencyUtils';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {SubDomainStatus} from '~/interfaces/client';

import {IconMessage, ICON_TYPE} from '~/components/Common/IconMessage/IconMessage';
import {allNoAccessOrResults, extractAllValues, formatDateField, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export enum SUBDOMAIN_RESULT_MESSAGE {
    EMPTY = 'Request successful (No results found)',
}

export interface IHeaderColumn<T> {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    disablePadding: boolean;
    id?: T;
    label: string | React.ReactElement;
}

export type ITopupHistoryDetails = {
    Data: {
        FromMonth: string;
        ToMonth: string;
        TransactionList: ITopupHistoryTransaction[];
    };
    ErrorMessage: string;
    Status: string;
};

export type ITopupHistoryTransaction = {
    TransactionDate: string;
    GiverName: string;
    TotalAmount: string;
    RelationshipType: string;
};

interface ITopupHistoryDetailsProps {
    details: ITopupHistoryDetails;
}

type IHeadRowData = Pick<
    ITopupHistoryTransaction,
    'TransactionDate' | 'TotalAmount' | 'GiverName' | 'RelationshipType'
>;

interface ITopupHistoryTransactionRowRecord
    extends Pick<ITopupHistoryTransaction, 'GiverName' | 'TotalAmount' | 'RelationshipType'> {
    TransactionDate: Date | string;
}

interface ITopupHistoryTransactionRows {
    sortedRows: ITopupHistoryTransactionRowRecord[];
}

export const TopupHistoryRows = ({sortedRows}: ITopupHistoryTransactionRows) => {
    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <TableRow tabIndex={-1} key={index}>
                        <TableCell>{formatDateField(row.TransactionDate, false, false, false)}</TableCell>
                        <TableCell>{formatValue(row.TotalAmount, parseFloat, formatCurrency)}</TableCell>
                        <TableCell>{row.GiverName}</TableCell>
                        <TableCell>{row.RelationshipType}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const TopupHistoryDetails = ({details}: ITopupHistoryDetailsProps) => {
    if ((!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    if (allNoAccessOrResults(extractAllValues(details.Data))) {
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                    Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                }`}
            />
        );
    }

    type ContributionHeaderColumn = IHeaderColumn<keyof IHeadRowData>;

    const headColumns: ContributionHeaderColumn[] = [
        {id: 'TransactionDate', align: 'left', disablePadding: true, label: 'Transfer/Top-up Date'},
        {id: 'TotalAmount', align: 'left', disablePadding: false, label: 'Transfer/Top-up Amount ($)'},
        {id: 'GiverName', align: 'left', disablePadding: false, label: 'Transfer/Top-up Giver'},
        {id: 'RelationshipType', align: 'left', disablePadding: false, label: 'Relationship of Giver to Member'},
    ];

    const filteredHeadColumns: ContributionHeaderColumn[] = headColumns.filter(
        (head: ContributionHeaderColumn): Boolean =>
            (details.Data.TransactionList as ITopupHistoryTransaction[])[0][
                head.id as keyof ITopupHistoryTransaction
            ] !== '',
    );

    const data = (details.Data.TransactionList as ITopupHistoryTransaction[]).map((transaction) => {
        return {
            ...transaction,
            TransactionDate: transaction.TransactionDate,
        };
    });

    const headerProps = {
        columns: filteredHeadColumns,
        customStyle: {
            backgroundColor: '#5c6bc0',
            color: '#ffffff',
        },
    };

    const hocProps = {
        rows: data,
        colSpan: filteredHeadColumns.length,
        headerProps,
    };

    const props = {
        ...headerProps,
        ...hocProps,
    };

    const EnhancedTable = WithEnhancedTable<
        ITopupHistoryTransactionRows,
        IHeadRowData,
        ITopupHistoryTransactionRowRecord
    >(
        TopupHistoryRows,
        'TransactionDate',
        data,
        'desc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return (
        <>
            <IconMessage
                data-testid="retirementAccountMessage"
                style={{backgroundColor: '#E8EAF6'}}
                type={ICON_TYPE.PURPLE_INFO}
                message="If client has Retirement Account, transfers and top-ups are made to that account. Otherwise, they would be credited to Special Account."
            />
            <IconMessage
                data-testid="relationshipAccuracyMessage"
                style={{backgroundColor: '#E8EAF6', marginBottom: '24px'}}
                type={ICON_TYPE.PURPLE_INFO}
                message="The relationships stated in this table are based on the givers' input, and should not be taken as the source of truth."
            />
            <EnhancedTable {...props} />
        </>
    );
};
