import {Box} from '@mui/material';
import React from 'react';

import style from './ActiveIcon.scss';

export const ActiveIcon = () => {
    return (
        <Box component="div" className={style.activeWrapper} id="active-icon">
            Active
        </Box>
    );
};
