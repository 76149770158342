import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {currencyFormatter, formatCurrency} from '~/utils/currencyUtils';
import style from './CPFAccountDetails.scss';
import {SubDomainStatus} from '~/interfaces/client';
import {format} from 'date-fns';
import {transformInvalidDateString} from '~/utils/dateUtils';
import {IRspPayoutDetail} from './MonthlyWithdrawalDetails';
import {ExpandableRow} from './ExpandableRow';
import {
    allNoAccess,
    allNoResults,
    extractAllValues,
    formatDateField,
    formatValue,
    objNoResults,
} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {isNotEmpty} from '~/utils/contentUtils';
import {ERROR_COPY} from '~/constants';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export type RleWithdrawalDetail = {
    Data: {
        TotalMgsAmount: string;
        FirstTransDate: string;
    };
    ErrorMessage: string;
    Status: string;
};

export type IAgeFFWithdrawalAndHousingRefund = {
    Data: {
        AgeFiftyFiveWithdrawalTransactions: IAgeFFWithdrawal[];
        HousingRefund: {
            TotalHousingRefundAmount: string;
        };
    };
    ErrorMessage: string;
    Status: string;
};

export type IAgeFFWithdrawal = {
    TransactionAmount: string;
    TransactionDate: string;
};

export type IPledgeWithdrawalDetail = {
    Data: {
        TotalPledgeAmount: string;
    };
    ErrorMessage: string;
    Status: string;
};

export type IPEADetail = {
    Data: {
        TotalWithdrawnAmount: string;
    };
    ErrorMessage: string;
    Status: string;
};

interface ILumpsumWithdrawalDetails {
    apiDetails: {
        RleWithdrawal: RleWithdrawalDetail;
        rspPayout: IRspPayoutDetail;
        age55WithdrawalAndHousingRefund: IAgeFFWithdrawalAndHousingRefund;
        pledgeWithdrawal: IPledgeWithdrawalDetail;
        peaWithdrawal: IPEADetail;
    }; // & extends other api type in the future;
}

export const LumpsumWithdrawalDetails: React.FC<ILumpsumWithdrawalDetails> = ({apiDetails}) => {
    //Show No result only when all are completed and has no data
    //Mixture of Error and Complete should show individual field
    const noResults =
        apiDetails?.age55WithdrawalAndHousingRefund?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.RleWithdrawal?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.rspPayout?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.pledgeWithdrawal?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.peaWithdrawal?.Status === SubDomainStatus.COMPLETED &&
        objNoResults({
            TotalExemptionAmount: apiDetails.rspPayout.Data.TotalExemptionAmount,
            ...apiDetails.RleWithdrawal.Data,
            ...apiDetails.age55WithdrawalAndHousingRefund.Data,
            ...apiDetails.pledgeWithdrawal.Data,
            ...apiDetails.peaWithdrawal.Data,
        });

    const affHasResult =
        Boolean(apiDetails.age55WithdrawalAndHousingRefund) &&
        Boolean(apiDetails.age55WithdrawalAndHousingRefund.Data) &&
        Boolean(apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions) &&
        !allNoResults(
            extractAllValues(apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions),
        ) &&
        !allNoAccess(
            extractAllValues(apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions),
        ) &&
        isNotEmpty(
            apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions[0].TransactionAmount,
        );

    const data = [
        {
            name: 'Under Reduced Life Expectancy Scheme',
            value:
                !apiDetails.RleWithdrawal || apiDetails.RleWithdrawal.Status !== SubDomainStatus.COMPLETED
                    ? ERROR_COPY //Error
                    : apiDetails.RleWithdrawal.Data && isNotEmpty(apiDetails.RleWithdrawal.Data.TotalMgsAmount)
                    ? formatValue(apiDetails.RleWithdrawal.Data.TotalMgsAmount, currencyFormatter) //Has Value
                    : Boolean(apiDetails.RleWithdrawal.ErrorMessage)
                    ? `No results (${apiDetails.RleWithdrawal.ErrorMessage})` //No result with error code
                    : apiDetails.RleWithdrawal.Data.TotalMgsAmount, //No result, No access
            meta:
                apiDetails.RleWithdrawal && apiDetails.RleWithdrawal.Status === SubDomainStatus.COMPLETED
                    ? apiDetails.RleWithdrawal.Data && isNotEmpty(apiDetails.RleWithdrawal.Data.FirstTransDate)
                        ? `First Withdrawal: ${formatValue(
                              //Has Value
                              apiDetails.RleWithdrawal.Data.FirstTransDate,
                              (dataStr: string) =>
                                  format(
                                      new Date(
                                          dataStr.match(/^\d{4}\-(0[1-9]|1[012])$/g)
                                              ? dataStr
                                              : transformInvalidDateString(dataStr, 'YYYYMM'),
                                      ),
                                      'MMM yyyy',
                                  ),
                          )}`
                        : null
                    : null,
        },
        {
            name: 'Upon the client pledging his property',
            value:
                !apiDetails.pledgeWithdrawal || apiDetails.pledgeWithdrawal.Status !== SubDomainStatus.COMPLETED
                    ? ERROR_COPY //Error
                    : apiDetails.pledgeWithdrawal.Data && isNotEmpty(apiDetails.pledgeWithdrawal.Data.TotalPledgeAmount)
                    ? formatValue(apiDetails.pledgeWithdrawal.Data.TotalPledgeAmount, parseFloat, formatCurrency) //Has Value
                    : Boolean(apiDetails.pledgeWithdrawal.ErrorMessage)
                    ? `No results (${apiDetails.pledgeWithdrawal.ErrorMessage})` //No result with error code
                    : apiDetails.pledgeWithdrawal.Data.TotalPledgeAmount, //No result, No access
        },
        {
            name: 'Upon reaching client payment eligibility age',
            value:
                !apiDetails.peaWithdrawal || apiDetails.peaWithdrawal.Status !== SubDomainStatus.COMPLETED
                    ? ERROR_COPY //Error
                    : apiDetails.peaWithdrawal.Data && isNotEmpty(apiDetails.peaWithdrawal.Data.TotalWithdrawnAmount)
                    ? formatValue(apiDetails.peaWithdrawal.Data.TotalWithdrawnAmount, parseFloat, formatCurrency) //Has Value
                    : Boolean(apiDetails.peaWithdrawal.ErrorMessage)
                    ? `No results (${apiDetails.peaWithdrawal.ErrorMessage})` //No result with error code
                    : apiDetails.peaWithdrawal.Data.TotalWithdrawnAmount, //No result, No access
        },
        {
            name: 'Housing Refund including principle and interest',
            value:
                !apiDetails.age55WithdrawalAndHousingRefund ||
                apiDetails.age55WithdrawalAndHousingRefund.Status !== SubDomainStatus.COMPLETED
                    ? ERROR_COPY //Error
                    : apiDetails.age55WithdrawalAndHousingRefund.Data &&
                      apiDetails.age55WithdrawalAndHousingRefund.Data.HousingRefund &&
                      isNotEmpty(apiDetails.age55WithdrawalAndHousingRefund.Data.HousingRefund.TotalHousingRefundAmount)
                    ? formatValue(
                          //Has Value
                          apiDetails.age55WithdrawalAndHousingRefund.Data.HousingRefund.TotalHousingRefundAmount,
                          parseFloat,
                          formatCurrency,
                      )
                    : Boolean(apiDetails.age55WithdrawalAndHousingRefund.ErrorMessage)
                    ? `No results (${apiDetails.age55WithdrawalAndHousingRefund.ErrorMessage})` //No result with error code
                    : apiDetails.age55WithdrawalAndHousingRefund.Data.HousingRefund.TotalHousingRefundAmount, //No result, No access
        },
        {
            name: 'Client applying for RS exemption with pension/annuity from private insurer',
            value:
                !apiDetails.rspPayout || apiDetails.rspPayout.Status !== SubDomainStatus.COMPLETED
                    ? ERROR_COPY //Error
                    : apiDetails.rspPayout.Data && isNotEmpty(apiDetails.rspPayout.Data.TotalExemptionAmount)
                    ? formatValue(apiDetails.rspPayout.Data.TotalExemptionAmount, parseFloat, formatCurrency) //Has value
                    : Boolean(apiDetails.rspPayout.ErrorMessage)
                    ? `No results (${apiDetails.rspPayout.ErrorMessage})` //No result with error code
                    : apiDetails.rspPayout.Data.TotalExemptionAmount, //No result, No access
        },
    ];

    const getA55Total = (): number => {
        const monthlyWithdrawal =
            apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions.map((transaction) =>
                formatValue(transaction.TransactionAmount, parseFloat),
            );
        return monthlyWithdrawal.reduce((sum, additive) => sum + additive, 0);
    };

    const sortA55Transactions = (transactionA: IAgeFFWithdrawal, transactionB: IAgeFFWithdrawal) => {
        const tranDateA = new Date(transactionA.TransactionDate);
        const tranDateB = new Date(transactionB.TransactionDate);
        return tranDateB.valueOf() - tranDateA.valueOf();
    };

    if (
        apiDetails?.age55WithdrawalAndHousingRefund?.Status === SubDomainStatus.ERROR &&
        apiDetails?.RleWithdrawal?.Status === SubDomainStatus.ERROR &&
        apiDetails?.rspPayout?.Status === SubDomainStatus.ERROR &&
        apiDetails?.pledgeWithdrawal?.Status === SubDomainStatus.ERROR &&
        apiDetails?.peaWithdrawal?.Status === SubDomainStatus.ERROR
    ) {
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;
    }

    if (noResults) {
        return <NoData />;
    }

    return (
        <div id={style.custom4ColTable}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Total withdrawal amount for the past 12 months</TableCell>
                            <TableCell align="right">Amount ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ExpandableRow
                            expandable={Boolean(apiDetails.age55WithdrawalAndHousingRefund) && affHasResult}
                            description={'Upon reaching age 55'}
                            amount={
                                !apiDetails.age55WithdrawalAndHousingRefund ||
                                apiDetails.age55WithdrawalAndHousingRefund.Status !== SubDomainStatus.COMPLETED
                                    ? ERROR_COPY //Error
                                    : affHasResult
                                    ? formatValue(getA55Total(), parseFloat, formatCurrency) //Has Value
                                    : Boolean(apiDetails.age55WithdrawalAndHousingRefund.ErrorMessage)
                                    ? `No results (${apiDetails.age55WithdrawalAndHousingRefund.ErrorMessage})` //No result with error code
                                    : apiDetails.age55WithdrawalAndHousingRefund.Data
                                          .AgeFiftyFiveWithdrawalTransactions[0].TransactionAmount //No result
                            }
                            expandedRow={
                                affHasResult
                                    ? apiDetails.age55WithdrawalAndHousingRefund.Data.AgeFiftyFiveWithdrawalTransactions.slice()
                                          .sort(sortA55Transactions)
                                          .map((transaction) => {
                                              return {
                                                  name: formatDateField(
                                                      transaction.TransactionDate,
                                                      false,
                                                      false,
                                                      true,
                                                  ),
                                                  amount: transaction.TransactionAmount,
                                              };
                                          })
                                    : []
                            }
                        />
                        {data.map((d, i) => (
                            <TableRow key={i} data-testid="withdrawal_detail">
                                <TableCell />
                                <TableCell component="th" scope="row">
                                    <Typography variant="body1">{d.name}</Typography>
                                    {d.meta && (
                                        <Typography variant="body2" styleName="dateMetaText">
                                            {d.meta}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">{d.value}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
