import MUIRichTextEditor from 'mui-rte';
import React, {useState, useRef} from 'react';
import {AttachFileRounded as AttachFileIcon, ImageRounded as ImageIcon} from '@mui/icons-material';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/styles';
import {TMUIRichTextEditorRef} from 'mui-rte';
import {stateToHTML} from 'draft-js-export-html';
import {formatFileName, prependTimestampToFileName} from '~/utils/fileUtils';
import {EmbedImageDialog} from './EmbedImageDialog';
import './RichTextEditor.scss';

const editorTheme = createTheme();
Object.assign(editorTheme, {
    overrides: {
        MUIRichTextEditor: {
            toolbar: {
                border: '1px solid #979797',
                borderRadius: '4px 4px 0 0',
                backgroundColor: '#f0f0f0',
            },
            editorContainer: {
                paddingLeft: 20,
                paddingTop: 10,
                minHeight: '30vh',
            },
            editor: {
                borderBottom: '1px solid #979797',
                borderLeft: '1px solid #979797',
                borderRight: '1px solid #979797',
                borderRadius: '0 0 4px 4px',
                minHeight: '30vh',
                overflow: 'auto',
                paddingRight: 45,
            },
        },
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Image: React.FunctionComponent<{}> = ({blockProps}: any) => {
    const getImageStyle = (alignment: string) => {
        if (alignment === 'left') {
            return {display: 'block', marginLeft: 0, marginRight: 'auto', cursor: 'default'};
        } else if (alignment === 'right') {
            return {display: 'block', marginLeft: 'auto', marginRight: 0, cursor: 'default'};
        } else {
            return {display: 'block', marginLeft: 'auto', marginRight: 'auto', cursor: 'default'};
        }
    };
    return <img src={blockProps.url} style={getImageStyle(blockProps.alignment)} />;
};

interface IRichTextEditorProps {
    onChange: (htmlContent: string) => void;
    onAttachFiles: (files: File[]) => void;
    onEmbedImage: (files: File) => void;
}

export const RichTextEditor = ({onChange, onAttachFiles, onEmbedImage}: IRichTextEditorProps) => {
    const ref = useRef<TMUIRichTextEditorRef>(null);
    const attachRef = useRef<HTMLInputElement>(null);
    const [embedOpen, setEmbedOpen] = useState<boolean>(false);

    const handleEmbedImage = (file: File, alignment: string) => {
        const fileToEmbed = prependTimestampToFileName(formatFileName(file));
        ref.current?.insertAtomicBlockSync('atomic-image', {
            fileName: fileToEmbed.name,
            url: URL.createObjectURL(fileToEmbed),
            alignment,
        });
        onEmbedImage(fileToEmbed);
    };

    const handleAttachFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files && Object.values(e.target.files);
        files && onAttachFiles(files);
    };

    const handleChange = (state: Draft.EditorState) => {
        const options = {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            entityStyleFn: (entity: any) => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'atomic-image') {
                    const {fileName, alignment} = entity.getData();
                    return {
                        element: 'img',
                        attributes: {
                            src: `cid:${fileName}`,
                            id: alignment,
                        },
                    };
                }
            },
        };
        const htmlContent = stateToHTML(state.getCurrentContent(), options);
        onChange(htmlContent);
    };

    return (
        <div styleName="richTextEditor">
            <ThemeProvider theme={editorTheme}>
                <MUIRichTextEditor
                    ref={ref}
                    label="Content Body"
                    onChange={handleChange}
                    controls={[
                        'title',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'numberList',
                        'bulletList',
                        'link',
                        'attachment',
                        'embed-image',
                        'clear',
                        'undo',
                        'redo',
                    ]}
                    customControls={[
                        {
                            name: 'attachment',
                            icon: <AttachFileIcon styleName="toolbarIcon" />,
                            type: 'callback',
                            onClick: () => attachRef.current?.click(),
                        },
                        {
                            name: 'embed-image',
                            icon: <ImageIcon styleName="toolbarIcon" />,
                            type: 'callback',
                            onClick: () => setEmbedOpen(true),
                        },
                        {
                            name: 'atomic-image',
                            type: 'atomic',
                            atomicComponent: Image,
                        },
                    ]}
                />
            </ThemeProvider>

            <input
                accept="*"
                id="fileInput"
                type="file"
                multiple={true}
                onChange={handleAttachFiles}
                styleName="fileInput"
                ref={attachRef}
            />

            <EmbedImageDialog open={embedOpen} onClose={() => setEmbedOpen(false)} onSubmit={handleEmbedImage} />
        </div>
    );
};
