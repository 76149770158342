import React from 'react';
import {currencyFormatter} from '~/utils/currencyUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {Grid} from '@mui/material';
import {SubDomainStatus} from '~/interfaces/client';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {allNoResults, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';
import {IHousingPIData} from './HousingDetails';

interface LatestHousingRefundProps {
    details: IHousingPIData;
}

export const LatestHousingRefund: React.FC<LatestHousingRefundProps> = ({details}) => {
    if ((!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    //When the section no results
    if (allNoResults([details.Data.TotalRefundAmount, details.Data.RefundDate, details.Data.RefundAddress]))
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                    Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                }`}
            />
        );

    const displayedFields = [
        {
            label: 'Housing Refund Amount ($)',
            value: formatValue(details.Data.TotalRefundAmount, currencyFormatter),
        },
        {
            label: 'Refund Credited Date',
            value: details.Data.RefundDate,
        },
        {
            label: 'Address of Property Refunded',
            value: details.Data.RefundAddress,
        },
    ];

    return (
        <Grid container spacing={3}>
            <SimpleGridDisplay fields={displayedFields} />
        </Grid>
    );
};
