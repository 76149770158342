import React, {useState} from 'react';
import {InputAdornment, TextField, Typography} from '@mui/material';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import {searchUser} from '~/services/userServices';
import {IUserSearchParams, IUserSearchResults} from '~/interfaces/admin';
import {UserView} from '../UserView/UserView';
import {DataField} from '~/components/Common/DataField/DataField';
import {email} from '~/components/Common/InputField/ValidationRules';
import './SearchUser.scss';

export interface ISearchUserProps {
    onSearch: (searchResults: IUserSearchResults) => void;
}

export const SearchUser = ({onSearch}: ISearchUserProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchResult, setSearchResult] = useState<IUserSearchResults | undefined>(undefined);
    const [error, setError] = useState<string>('');

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') handleSearch();
    };

    const handleSearch = async () => {
        const validationErr = email(searchValue);
        if (validationErr) {
            setSearchResult(undefined);
            setError(validationErr);
            return;
        }

        const searchParams: IUserSearchParams = {
            Email: searchValue.toLowerCase(),
        };
        try {
            const result = await searchUser(searchParams);
            setError('');
            setSearchResult(result);
            onSearch(result);
        } catch (err) {
            const error = err as Error;
            setError(error.message);
        }
    };

    return (
        <div styleName="searchUser">
            <TextField
                label="Search by User Email"
                type="search"
                variant="outlined"
                value={searchValue}
                onChange={handleSearchChange}
                styleName="searchInput"
                onKeyDown={handleSearchKeyDown}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" onClick={handleSearch} styleName="searchButton">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {error && <Typography color="secondary">{error}</Typography>}
            {searchResult && (
                <div>
                    <DataField label="Agency" value={searchResult.Agency ? searchResult.Agency.Name : '-'} />
                    <UserView details={searchResult.User} userRole="User" isDisplayControls={false} />
                </div>
            )}
        </div>
    );
};
