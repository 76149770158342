import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@mui/material';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {EntityDetails} from '~/components/Admin/EntityDetails/EntityDetails';
import {EntityView} from '~/components/Admin/EntityView/EntityView';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {sameEmail, sameUserName} from '~/components/Common/InputField/ValidationRules';
import {DELETE_REASONS, ERROR_MESSAGE} from '~/constants';
import {ITeam} from '~/interfaces/admin';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import style from './TeamEdit.scss';

import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {deleteTeam} from '~/services/teamServices';
import {
    activateTeamAdmin,
    createTeam,
    createTeamAdmin,
    deleteTeamAdmin,
    updateTeamAdmin,
    updateTeamDetails,
} from '~/store/team/team.thunk';
import {isInternetUser} from '~/utils/userType';

interface CustomError extends Error {
    status: number;
    text: () => Promise<string>;
}

export const TeamEdit = () => {
    const dispatch = useAppDispatch();
    const {teamId, opsUnitId} = useParams<{teamId?: string; opsUnitId?: string}>();
    const teams = useTypedSelector((state) => state.data.admin.teams);
    const teamDetails = teams[Number(teamId)];

    const {accessToken} = useContext(AuthenticationContext);
    const agencyName = (accessToken as IAccessToken).AdditionalUserInfo.Agency;
    const opsUnitName = (accessToken as IAccessToken).AdditionalUserInfo.OpsUnit;

    const usersDetails = teamDetails?.Admins || [];
    const isToCreateTeam = !teamDetails;
    const [name, setName] = useState(teamDetails?.Name || '');
    const [description, setDescription] = useState(teamDetails?.Description || '');
    const [user1, setUser1] = useState({...usersDetails[0]});
    const [user2, setUser2] = useState({...usersDetails[1]});
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();
    const [isTeamHasError, setIsTeamHasError] = useState(false);
    const [isAdmin1HasError, setIsAdmin1HasError] = useState(false);
    const [isAdmin2HasError, setIsAdmin2HasError] = useState(false);
    const [hasError, setHasError] = useState(false);
    const disableButton = !allowSaveUsers(user1, user2) || name.length == 0 || description.length == 0;
    const backUrl = `/admin/ops-units/${opsUnitId}/teams`;

    const pathname = history.location.pathname;
    const scrollId = history.location.hash.split('#')[1];
    const scrollRef = useRef<HTMLDivElement>(null);

    const isOnlyOneAdmin = usersDetails && usersDetails.length == 1;
    const hasMoreThanOneTeam = Object.keys(teams).length > 1;
    const hideDeleteButton = hasMoreThanOneTeam && isOnlyOneAdmin;
    const [isCreateTeamDetails, setIsCreateTeamDetails] = useState<boolean>(isToCreateTeam);
    const [isEditTeamDetails, setIsEditTeamDetails] = useState<boolean>(false);
    const [isCreateUser1, setIsCreateUser1] = useState<boolean>(isToCreateTeam);
    const [isCreateUser2, setIsCreateUser2] = useState<boolean>(isToCreateTeam);
    const [isEditUser1, setIsEditUser1] = useState<boolean>(false);
    const [isEditUser2, setIsEditUser2] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteTeamDialogOpen, setConfirmDeleteTeamDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState({name: '', userId: 0});
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const isShowSubmitButton =
        isCreateUser1 || isCreateUser2 || isEditUser1 || isEditUser2 || isCreateTeamDetails || isEditTeamDetails;
    const isCreateTeam = isCreateTeamDetails && isCreateUser1 && isCreateUser2;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeAllEdits = () => {
        setIsCreateUser1(false);
        setIsCreateUser2(false);
        setIsEditUser1(false);
        setIsEditUser2(false);
        setIsCreateTeamDetails(false);
        setIsEditTeamDetails(false);
        setConfirmDeleteTeamDialogOpen(false);
    };

    const removeUrlFragment = () => {
        if (scrollId) {
            history.push(pathname);
        }
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        closeAllEdits();
        removeUrlFragment();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        const filteredAdmins = [user1, user2].filter((user) => user.Name);
        const updatedTeam = {
            Id: isCreateTeam ? undefined : Number(teamId),
            Name: name,
            Description: description,
            OpsUnitId: Number(opsUnitId),
            Admins: filteredAdmins,
        };
        try {
            if (isCreateTeam) {
                updatedTeam.Admins = prepareUserCreation(updatedTeam.Admins);
                const thunkActionResult = await dispatch(
                    createTeam({opsUnitId: Number(opsUnitId), team: updatedTeam as ITeam}),
                );
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    history.push(`/admin/ops-units/${opsUnitId}/teams/${thunkActionResult.payload.team.Id}`);
                }
                setIsLoading(false);
            } else if (isEditTeamDetails) {
                const thunkActionResult = await dispatch(updateTeamDetails({team: updatedTeam as ITeam}));
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    successfulSubmit();
                }
                setIsLoading(false);
            } else if (isCreateUser1) {
                updatedTeam.Admins = prepareUserCreation(updatedTeam.Admins);
                const thunkActionResult = await dispatch(
                    createTeamAdmin({teamId: Number(teamId), admin: updatedTeam.Admins[0]}),
                );
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    successfulSubmit();
                }

                setIsLoading(false);
            } else if (isCreateUser2) {
                updatedTeam.Admins = prepareUserCreation(updatedTeam.Admins);
                const thunkActionResult = await dispatch(
                    createTeamAdmin({teamId: Number(teamId), admin: updatedTeam.Admins[1]}),
                );
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    successfulSubmit();
                }

                setIsLoading(false);
            } else if (isEditUser1) {
                const thunkActionResult = await dispatch(
                    updateTeamAdmin({teamId: Number(teamId), adminDetails: updatedTeam.Admins[0]}),
                );
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    successfulSubmit();
                }

                setIsLoading(false);
            } else if (isEditUser2) {
                const thunkActionResult = await dispatch(
                    updateTeamAdmin({teamId: Number(teamId), adminDetails: updatedTeam.Admins[1]}),
                );
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    successfulSubmit();
                }

                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            const error = err as CustomError;
            switch (error.status) {
                case 409:
                    error.text().then((email: string) => {
                        openGrowl(`${email} has been already assigned as a user. Please enter a different email.`);
                    });
                default:
                    openGrowl(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
            }
            setIsLoading(false);
        }
    };

    const handleActivateUser = async (userId?: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);

        if (user) {
            const thunkActionResult = await dispatch(activateTeamAdmin({teamId: Number(teamId), admin: user}));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl(`${user.Name} has been reactivated`);
            }
        }
    };

    const handleDeleteUser = (userId: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);
        if (user) {
            setIsDeleteDialogOpen(true);
            setUserToBeDeleted({name: user.Name, userId: userId});
        }
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setConfirmDeleteTeamDialogOpen(false);
    };
    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteTeamAdmin({opsUnitId: Number(teamId), userId: Number(userToBeDeleted.userId), deletedReason}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            openGrowl(`${userToBeDeleted.name} has been deleted`);
        }
        setIsDeleting(false);
        closeAllEdits();
        removeUrlFragment();
        setUserToBeDeleted({name: '', userId: 0});
        closeDeleteDialog();
    };

    const handleEditTeam = () => {
        history.push(`${pathname}#details`);
        setIsEditTeamDetails(true);
    };

    const handleEditUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsEditUser1(true);
    };
    const handleEditUser2 = () => {
        history.push(`${pathname}#admin2`);
        setIsEditUser2(true);
    };

    const handleCreateUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsCreateUser1(true);
    };
    const handleCreateUser2 = () => {
        history.push(`${pathname}#admin2`);
        setIsCreateUser2(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };
    const handleTeamDelete = () => {
        setConfirmDeleteTeamDialogOpen(true);
    };

    const confirmTeamDelete = async (reason: string) => {
        try {
            setIsDeleting(true);
            await deleteTeam(Number(teamId), reason);
            closeAllEdits();
            openGrowl(`${teamDetails.Name} has been deleted successfully!`);
            history.push(backUrl);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        }
        setIsDeleting(false);
    };
    const confirmCancel = () => {
        if (isCreateTeam) {
            history.push(backUrl);
        }

        closeAllEdits();
        removeUrlFragment();

        const usersDetails = teamDetails?.Admins || [];
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
        setName(teamDetails?.Name || '');
        setDescription(teamDetails?.Description || '');
        closeConfirmDialog();
    };

    const IsSaveButtonDisabled = () => {
        if (hasError) {
            return true;
        }
        if (isCreateTeam) {
            return disableButton;
        } else {
            if (isCreateUser1 || isEditUser1 || isCreateUser2 || isEditUser2) {
                return !allowSaveUsers(user1, user2);
            } else if (isEditTeamDetails) {
                return name.length == 0 || description.length == 0;
            }
        }
        return true;
    };

    useEffect(() => {
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
    }, [teamDetails]);

    useEffect(() => {
        setHasError(isTeamHasError || isAdmin1HasError || isAdmin2HasError);
    }, [isTeamHasError, isAdmin1HasError, isAdmin2HasError]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [scrollId]);

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={IsSaveButtonDisabled()}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const DeleteTeamButton = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    id="delete-team-button"
                    data-testid="deleteTeamButton"
                    onClick={() => {
                        handleTeamDelete();
                    }}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Delete'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push(backUrl);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.teamEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Team Details
                        </Typography>
                    </Grid>
                    {!isShowSubmitButton && hasMoreThanOneTeam ? (
                        <Grid item ref={domainHeaderRef}>
                            {<DeleteTeamButton />}
                        </Grid>
                    ) : isShowSubmitButton ? (
                        <SubmitButtons />
                    ) : (
                        <></>
                    )}
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                            {`${agencyName} / ${opsUnitName}`}
                        </Typography>
                    </Grid>
                </Grid>
                <div ref={scrollId === 'details' ? scrollRef : null} />
                {isCreateTeamDetails || isEditTeamDetails ? (
                    <EntityDetails
                        name={name}
                        nameLabel="Team Name"
                        description={description}
                        descriptionLabel="Brief Description of Team"
                        setName={setName}
                        setDescription={setDescription}
                        disableInput={isLoading}
                        setHasError={setIsTeamHasError}
                    />
                ) : (
                    <EntityView
                        name={name}
                        nameLabel="Team Name"
                        description={description}
                        descriptionLabel="Brief Description of Team"
                        isDisplayControls={!isShowSubmitButton}
                        handleEditEntity={handleEditTeam}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin1' ? scrollRef : null} />
                {isCreateUser1 || isEditUser1 ? (
                    <UserDetails
                        heading="Team Admin 1"
                        details={user1}
                        setUser={setUser1}
                        fieldsToDisable={
                            isCreateUser1 ? [] : isInternetUser(user1) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setIsAdmin1HasError}
                    />
                ) : (
                    <UserView
                        heading="Team Admin 1"
                        details={user1}
                        userRole="Team Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser1}
                        isDisplayControls={!(isEditUser2 || isCreateUser2 || isCreateTeamDetails || isEditTeamDetails)}
                        hideDeleteButton={hideDeleteButton}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser1}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin2' ? scrollRef : null} />
                {isCreateUser2 || isEditUser2 ? (
                    <UserDetails
                        heading="Team Admin 2"
                        details={user2}
                        setUser={setUser2}
                        fieldsToDisable={
                            isCreateUser2 ? [] : isInternetUser(user2) ? ['userName'] : ['email', 'userName']
                        }
                        customError={{
                            email: sameEmail(user1.Email, user2.Email),
                            userName: sameUserName(user1.UserName, user2.UserName),
                        }}
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setIsAdmin2HasError}
                    />
                ) : (
                    <UserView
                        heading="Team Admin 2"
                        details={user2}
                        userRole="Team Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser2}
                        isDisplayControls={!(isEditUser1 || isCreateUser1 || isCreateTeamDetails || isEditTeamDetails)}
                        hideDeleteButton={hideDeleteButton}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser2}
                    />
                )}
                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={IsSaveButtonDisabled()}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <DeleteWithReasonDialog
                id="ConfirmDeleteTeamDialog"
                users={teamDetails?.Users?.length}
                title="Delete Team?"
                requireAcknowledgement={true}
                acknowledgementMessage={DELETE_REASONS['Team']}
                warningMessage="Existing Users will not be able to use OneCV anymore."
                buttonMessage="TEAM"
                open={isConfirmDeleteTeamDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmTeamDelete}
                isLoading={isDeleting}
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteTeamAdminDialog"
                title={`Delete Team Admin?`}
                buttonMessage="TEAM ADMIN"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteTeamAdminDialog"
                title={`Delete ${userToBeDeleted.name}?`}
                buttonMessage="ADMIN"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
            />
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
