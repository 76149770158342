import {Dialog, AppBar, Toolbar, IconButton, DialogContent, Typography} from '@mui/material';
import {CloseRounded as CloseIcon, ArrowBackRounded as ArrowBackIcon} from '@mui/icons-material';
import React from 'react';
import './FullScreenDialog.scss';

export interface IFullScreenDialogProps {
    id?: string;
    onClose: (event: object) => void;
    onBack?: () => void;
    backText?: string;
    open: boolean;
    children: React.ReactNode;
}

export const FullScreenDialog = ({open, onClose, onBack, backText = 'BACK', id, children}: IFullScreenDialogProps) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: '#f4f5fa',
                },
            }}
            id={id}
            open={open}
            onClose={onClose}
            aria-describedby="alert-dialog-description"
            fullScreen={true}
            styleName="fullScreenDialogue"
            disableAutoFocus={true}
            disableEnforceFocus={true}
        >
            <AppBar position="static" styleName="appBar">
                <Toolbar>
                    {onBack && (
                        <IconButton
                            styleName="leftIcon"
                            edge="start"
                            color="inherit"
                            onClick={onBack}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                            <Typography styleName="backText">{backText}</Typography>
                        </IconButton>
                    )}

                    <IconButton styleName="rightIcon" edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};
