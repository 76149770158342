import {ACCESS_LEVELS, MONTHLY_REVIEW_STATUS} from '~/constants';
import {IAdminRow} from '~/interfaces/admin';

const hasAdminId = (admin: IAdminRow) => {
    return admin.OpsUnitId || admin.Id;
};

export const checkAllReviewed = (admins: IAdminRow[]) => {
    return admins.filter((admin) => admin.Status != MONTHLY_REVIEW_STATUS.COMPLETED).length === 0;
};

export const customRowStyle = (adminRow: IAdminRow) => {
    return adminRow.OpsUnitId || adminRow.AgencyId ? {} : {background: '#F0F0F0'};
};

export const customCellStyle = (admin: IAdminRow) => {
    return admin.Name ? {width: '20%'} : {};
};

export const name = (admin: IAdminRow) => (hasAdminId(admin) ? admin.Name : 'Ops Unit Admin');

export const lastReviewDateAndName = (admin: IAdminRow): Date =>
    hasAdminId(admin) && admin.MostRecentReview ? new Date(admin.MostRecentReview) : new Date(0, 0, 0, 0, 0, 0);

export const currentReviewDate = (admin: IAdminRow): Date =>
    hasAdminId(admin) && admin.Status === 'COMPLETED' ? new Date() : new Date(0, 0, 0, 0, 0, 0);

export const nameAndDesignation =
    (index: number) =>
    (admin: IAdminRow): string =>
        admin && admin.Admins && admin.Admins[index] ? admin.Admins[index].Name + admin.Admins[index].Designation : '';

export const isAgencyAdmin = (accessLevel: string): boolean => accessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR;
