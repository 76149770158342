import React from 'react';
import {dateFormatter} from '~/utils/dateUtils';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {Typography} from '@mui/material';
import {allNoResults, extractAllValues, isNotEmpty, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type IMaritalInfoData = IMaritalInfo[];

export interface IMaritalInfo {
    DateOfEvent: string;
    EventCaseId: string;
    MaritalEvent: string;
    Name: string;
    UIN: string;
}

const maritalInfoToFields = (maritalInfo: IMaritalInfo) => {
    return [
        {
            label: '',
            value: isNotEmpty(maritalInfo.MaritalEvent) ? (
                <Typography variant="h6">{maritalInfo.MaritalEvent}</Typography>
            ) : (
                maritalInfo.MaritalEvent
            ),
            fullWidth: true,
        },
        {
            label: 'Date of Event',
            value: formatValue(maritalInfo.DateOfEvent, dateFormatter),
        },
        {label: 'Certificate Number / Case Number', value: maritalInfo.EventCaseId},
        {label: 'Spouse Name', value: maritalInfo.Name},
        {label: 'Spouse ID', value: maritalInfo.UIN},
    ];
};

export const MaritalInfo: React.FunctionComponent<{data: IMaritalInfoData}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    const fieldsToRender = data.map(maritalInfoToFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <>
                    <MultiGridDisplay
                        dataTestId="maritalInfo"
                        dataFieldGroups={fieldsToRender}
                        displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                    />
                </>
            )}
        </>
    );
};
