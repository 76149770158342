import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import React from 'react';

export interface IConfirmDialogProps {
    id?: string;
    isLoading?: boolean;
    onCancel: (event: object) => void;
    onConfirm: (event: object) => void;
    cancelOption: string;
    confirmOption: string;
    open: boolean;
    title: string;
    message: string;
}

export const ConfirmDialog = ({
    open,
    onCancel,
    onConfirm,
    id,
    cancelOption,
    confirmOption,
    title,
    message,
    isLoading,
}: IConfirmDialogProps) => {
    return (
        <Dialog id={id} open={open} aria-describedby="alert-dialog-description">
            <DialogTitle style={{width: 531}}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary" id="cancel-dialog-button" disabled={isLoading}>
                    {cancelOption}
                </Button>
                <Button onClick={onConfirm} color="primary" id="confirm-dialog-button" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} /> : confirmOption}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
