import React from 'react';
import {Grid, Typography} from '@mui/material';
import styles from './AssistanceDetails.scss';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {formatDate, getDate} from '~/utils/dateUtils';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {getAlphabeticalComparator, getNumericComparator} from '~/components/Common/ListModel/comparators';
import {COLORS} from '~/components/Common/Colors';
import {EASE} from '../Common/Schemes';
import {currencyFormatter} from '~/utils/currencyUtils';
import {allNoAccess, extractAllValues, formatValue, isNotEmpty} from '~/utils/contentUtils';

export interface IAssistanceDetails {
    AssistanceType: string;
    StartDate: string;
    EndDate: string;
}

export interface IHomeOwnershipSchemes {
    DirectApp: string;
    FreshStartHousing: string;
    HomeImprovementProgram: string;
    LeaseBuyBack: string;
    LeaseTakeHomeCashAmt: string;
}

interface IAssistanceTableData {
    Id?: number;
    Scheme: string;
    Indicator: string[];
    Amount?: string;
}

export const AssistanceDetails: React.FunctionComponent<{
    assistanceDetails?: IAssistanceDetails;
    homeOwnershipSchemes?: IHomeOwnershipSchemes;
}> = ({assistanceDetails, homeOwnershipSchemes}) => {
    if (!assistanceDetails && !homeOwnershipSchemes) return <></>;
    let formattedTableData: IAssistanceTableData[] = [];
    let assistanceDetailsFields: {
        label: string;
        value?: string | React.ReactElement;
        fullWidth?: boolean;
        styleName?: string;
    }[] = [];

    if (assistanceDetails) {
        assistanceDetailsFields = [
            {
                label: 'Assistance Type',
                value: isNotEmpty(assistanceDetails.AssistanceType) ? (
                    <Typography variant="h6" className={styles.content}>
                        {assistanceDetails.AssistanceType.toLowerCase()}
                    </Typography>
                ) : (
                    assistanceDetails.AssistanceType
                ),
                fullWidth: true,
            },
            {
                label: 'Start Date',
                value: isNotEmpty(assistanceDetails.StartDate)
                    ? formatDate(getDate(assistanceDetails.StartDate), true, true)
                    : assistanceDetails.StartDate,
            },
            {
                label: 'End Date',
                value: isNotEmpty(assistanceDetails.EndDate)
                    ? formatDate(getDate(assistanceDetails.EndDate), true, true)
                    : isNotEmpty(assistanceDetails.StartDate)
                    ? 'Ongoing'
                    : assistanceDetails.EndDate, // If no start date, user does not have access to logical group
            },
        ];
    }

    if (homeOwnershipSchemes) {
        formattedTableData = [
            {
                Id: 1,
                Scheme: EASE,
                Indicator: [homeOwnershipSchemes.DirectApp, homeOwnershipSchemes.HomeImprovementProgram],
            },
            {
                Id: 2,
                Scheme: 'Lease Buyback Scheme (LBS)',
                Indicator: [homeOwnershipSchemes.LeaseBuyBack],
                Amount: formatValue(homeOwnershipSchemes.LeaseTakeHomeCashAmt, currencyFormatter),
            },
            {
                Id: 3,
                Scheme: 'Fresh Start Housing Scheme (Fresh Start)',
                Indicator: [homeOwnershipSchemes.FreshStartHousing],
            },
        ];
    }

    const scheme = (info: IAssistanceTableData) => {
        if (info.Scheme === EASE) {
            return (
                <>
                    <Grid container styleName="easeTitle">
                        <Typography variant="body2">{EASE}</Typography>
                    </Grid>

                    <Typography variant="body2">
                        <i>- Direct Application</i>
                    </Typography>
                    <Typography variant="body2">
                        <i>- Home Improvement Program</i>
                    </Typography>
                </>
            );
        }
        return info.Scheme;
    };
    const schemeValue = (info: IAssistanceTableData) => info.Scheme;
    const indicator = (info: IAssistanceTableData) => {
        if (info.Scheme === EASE) {
            const directApp = info.Indicator[0];
            const homeImprovement = info.Indicator[1];
            return (
                <>
                    <Grid styleName="easeTitle">
                        <Typography variant="body2">&nbsp;</Typography>
                    </Grid>
                    <Typography
                        data-testid="directApp"
                        variant="body2"
                        style={{color: directApp == 'Yes' ? COLORS.GREEN : ''}}
                    >
                        {directApp}
                    </Typography>
                    <Typography
                        data-testid="homeImprovement"
                        variant="body2"
                        style={{color: homeImprovement == 'Yes' ? COLORS.GREEN : ''}}
                    >
                        {homeImprovement}
                    </Typography>
                </>
            );
        }
        return (
            <Typography variant="body2" style={{color: info.Indicator[0] == 'Yes' ? COLORS.GREEN : ''}}>
                {info.Indicator}
            </Typography>
        );
    };
    const indicatorValue = (info: IAssistanceTableData) => info.Indicator[0];
    const amount = (info: IAssistanceTableData) => {
        const amount = info.Amount ? info.Amount : 'NA';
        if (info.Scheme === EASE) {
            return (
                <div>
                    <Grid styleName="easeTitle">
                        <Typography variant="body2">&nbsp;</Typography>
                    </Grid>
                    <Typography variant="body2">{amount}</Typography>
                    <Typography variant="body2">{amount}</Typography>
                </div>
            );
        }
        return <div>{amount}</div>;
    };
    const amountValue = (info: IAssistanceTableData) => (info.Amount ? parseFloat(info.Amount) : 0);
    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.customTable}>
                {assistanceDetails && (
                    <Grid data-testid="assistanceDetails" container spacing={3} id={styles.details}>
                        <SimpleGridDisplay fields={assistanceDetailsFields} />
                    </Grid>
                )}

                {homeOwnershipSchemes && !allNoAccess(extractAllValues(homeOwnershipSchemes)) && (
                    <ListModel
                        columns={[scheme, indicator, amount]}
                        headers={['Schemes', 'Indicator', 'Amount ($)']}
                        modelList={formattedTableData}
                        modelComparator={[
                            getAlphabeticalComparator(schemeValue),
                            getAlphabeticalComparator(indicatorValue),
                            getNumericComparator(amountValue),
                        ]}
                        variant="expanded"
                    />
                )}
            </div>
        </Grid>
    );
};
