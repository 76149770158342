import React from 'react';
import {Grid} from '@mui/material';
import {DataField} from '../../Common/DataField/DataField';
import {EditButton} from '~/components/Common/Button/EditButton';

import style from './EntityView.scss';
import {formatDate} from '~/utils/dateUtils';

interface IEntityView {
    name?: string;
    nameLabel?: string;
    description?: string;
    descriptionLabel?: string;
    isDisplayControls: boolean;
    handleEditEntity: () => void;
    isAgency?: boolean;
    dateAdded?: string;
    agencyType?: string;
    dataContributor?: boolean;
}
export const EntityView = ({
    name,
    nameLabel = 'Name',
    description,
    descriptionLabel = 'Description',
    isDisplayControls,
    handleEditEntity,
    isAgency = false,
    dateAdded = undefined,
    agencyType = undefined,
    dataContributor = undefined,
}: IEntityView) => {
    const ControlButtons = () => {
        if (isDisplayControls) {
            return (
                <div className={style.buttonWrapper}>
                    <EditButton onClick={() => handleEditEntity()} id="edit-entity" />
                </div>
            );
        } else {
            return <div />;
        }
    };

    return (
        <div className={style.inputsWrapper}>
            <Grid container justifyContent="space-between" direction="row" spacing={4}>
                <Grid container item direction="column" alignItems="flex-start" xs={4} spacing={4}>
                    <Grid item>
                        <DataField label={nameLabel} value={name as string} />
                    </Grid>
                    <Grid item>
                        <DataField label={descriptionLabel} value={description as string} />
                    </Grid>
                    {isAgency ? (
                        <>
                            <Grid item>
                                <DataField
                                    label={'Date Added'}
                                    value={dateAdded ? formatDate(new Date(dateAdded)) : ''}
                                />
                            </Grid>
                            <Grid item>
                                <DataField
                                    label={'Agency Type'}
                                    value={
                                        (agencyType === 'NGE'
                                            ? 'Non-Government Entity (NGE)'
                                            : 'Public Sector Agency (PSA)') as string
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <DataField label={'Data Contributor'} value={dataContributor ? 'Yes' : 'No'} />
                            </Grid>
                        </>
                    ) : undefined}
                </Grid>
                <Grid item>
                    <ControlButtons />
                </Grid>
            </Grid>
        </div>
    );
};
