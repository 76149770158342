import React, {useState, useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, Divider, InputAdornment} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CheckCircleRounded as CheckCircleIcon} from '@mui/icons-material';
import {COPIES} from '~/constants';
import {InputField} from '~/components/Common/InputField/InputField';
import {checkCharCountPass, checkNum, checkCapital, checkSpecial} from '~/utils/inputUtils';
import './ReportPasswordDialogForm.scss';

export const useDialogStyles = makeStyles({
    actions: {
        display: 'block',
        textAlign: 'center',
        padding: '32px',
    },
    buttons: {
        width: '100%',
        maxWidth: '328px',
        padding: '14px 16px',
        backgroundColor: '#3949ab',
        color: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    inputs: {
        width: '100%',
        marginTop: 26,
    },
});

export interface IReportPasswordDialogFormProps {
    password: string;
    onPasswordChange: (password: string) => void;
    onSubmit: () => void;
    hasDivider?: boolean;
    title?: string;
    description?: string;
}

export const ReportPasswordDialogForm = ({
    password,
    onPasswordChange,
    onSubmit,
    hasDivider = false,
    title = '',
    description = '',
}: IReportPasswordDialogFormProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);

    const [passCharCount, setPassCharCount] = useState(false);
    const [passNum, setPassNum] = useState(false);
    const [passCapital, setPassCapital] = useState(false);
    const [passSpecial, setPassSpecial] = useState(false);
    const isAllPass = passCharCount && passNum && passCapital && passSpecial;

    const validationIconStyle = (isPass: boolean) => {
        const pass = isPass ? 'pass' : 'fail';
        return `validationIcon ${pass}`;
    };

    const dialogClasses = useDialogStyles();

    const getPasswordErrorMessage = (): string => {
        const prefix = 'Please enter a password that';

        const atLeastErrorMessages: string[] = [];

        if (!passNum) {
            atLeastErrorMessages.push(' one number');
        }
        if (!passCapital) {
            atLeastErrorMessages.push(' one capital letter');
        }
        if (!passSpecial) {
            atLeastErrorMessages.push(' one special character');
        }

        const charCountError = passCharCount ? '' : ' has 12-20 characters';
        const multipleError = passCharCount || atLeastErrorMessages.length === 0 ? '' : ' and';
        const containsAtLeast = atLeastErrorMessages.length === 0 ? '' : ' contains at least';

        let atLeastMessage = '';
        atLeastErrorMessages.forEach((errMessage, index) => {
            atLeastMessage += errMessage;

            // 2nd Last
            if (index === atLeastErrorMessages.length - 2) {
                atLeastMessage += ' and';
            } else if (index < atLeastErrorMessages.length - 2) {
                atLeastMessage += ',';
            }
        });

        return `${prefix}${charCountError}${multipleError}${containsAtLeast}${atLeastMessage}.`;
    };

    const handleChange = (value: string) => {
        onPasswordChange(value);
    };

    const handleDialogClose = () => {
        setShowError(false);
        setShowPassword(false);
    };

    const handlePasswordSubmit = () => {
        if (isAllPass) {
            onSubmit();
            handleDialogClose();
        } else {
            setShowError(true);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        setPassCharCount(checkCharCountPass(password));
        setPassNum(checkNum(password));
        setPassCapital(checkCapital(password));
        setPassSpecial(checkSpecial(password));
    }, [password]);

    return (
        <div styleName="reportPasswordDialogForm">
            <DialogContent>
                {hasDivider && <Divider styleName="dividerTop" />}
                {title && <DialogContentText styleName="dialog-title">{title}</DialogContentText>}
            </DialogContent>
            {description && (
                <DialogContent>
                    <DialogContentText styleName="content-text">{description}</DialogContentText>
                </DialogContent>
            )}
            <DialogContent>
                <DialogContentText styleName="content-text">
                    <CheckCircleIcon styleName={validationIconStyle(passCharCount)} id="charCountIcon" />
                    {COPIES.REPORT_PASSWORD_DIALOG_CHAR_COUNT}
                </DialogContentText>
                <DialogContentText styleName="content-text">
                    <CheckCircleIcon styleName={validationIconStyle(passNum)} id="numIcon" />
                    {COPIES.REPORT_PASSWORD_DIALOG_NUM}
                </DialogContentText>
                <DialogContentText styleName="content-text">
                    <CheckCircleIcon styleName={validationIconStyle(passCapital)} id="capitalIcon" />
                    {COPIES.REPORT_PASSWORD_DIALOG_CAPITAL}
                </DialogContentText>
                <DialogContentText styleName="content-text">
                    <CheckCircleIcon styleName={validationIconStyle(passSpecial)} id="specialIcon" />
                    {COPIES.REPORT_PASSWORD_DIALOG_SPECIAL}
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <InputField
                    type={showPassword ? 'text' : 'password'}
                    className={dialogClasses.inputs}
                    htmlFor={'password-input'}
                    onChange={handleChange}
                    value={password}
                    label={'Password'}
                    endAdornment={
                        <InputAdornment position="end" id="password-adornment">
                            <Button color="primary" onClick={toggleShowPassword}>
                                {' '}
                                {showPassword ? 'HIDE' : 'SHOW'}{' '}
                            </Button>
                        </InputAdornment>
                    }
                    customErrorMessage={showError && !isAllPass ? getPasswordErrorMessage() : undefined}
                />
            </DialogContent>

            {hasDivider && <Divider styleName="dividerBtm" />}
            <DialogActions className={dialogClasses.actions}>
                <Button
                    className={dialogClasses.buttons}
                    onClick={handlePasswordSubmit}
                    variant="contained"
                    color="primary"
                    id="download-pdf-button"
                >
                    {COPIES.REPORT_PASSWORD_DIALOG_DOWNLOAD}
                </Button>
            </DialogActions>
        </div>
    );
};
