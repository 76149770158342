import {TableFooter, TableRow, TablePagination} from '@mui/material';
import React from 'react';
import {TablePaginationActions} from '../../Pagination';

export interface IPaginatedListModelFooterProps {
    count: number;
    page: number;
    rowsPerPage: number;
    columnLength: number;
    onChangePage: (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const PaginatedListModelFooter = ({
    count,
    page,
    rowsPerPage,
    columnLength,
    onChangePage,
    onChangeRowsPerPage,
}: IPaginatedListModelFooterProps) => (
    <TableFooter>
        <TableRow>
            <TablePagination
                ActionsComponent={TablePaginationActions}
                colSpan={columnLength}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </TableRow>
    </TableFooter>
);
