import React, {useState, useEffect, useCallback} from 'react';
import {ReportPasswordDialogForm} from '~/components/Common/ReportDialogs/ReportPasswordDialogForm';
import {IFamilyScreeningOptions, SelectSubDomainsDialogForm} from './SelectSubDomainsDialogForm';
import {IDomain} from '~/interfaces/common';
import {Dialog, DialogActions, Grid, IconButton, Step, StepButton, Stepper, Typography} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {BackButton} from '~/components/Common/Button/BackButton';
import {COPIES, ReportType} from '~/constants';
import {getDomainsSubDomainsCodes} from './DomainCheckBoxList';
import './ClientReportDialog.scss';

export interface IClientReportDialogProps {
    open: boolean;
    onSubmit: (listOfReports: string[], password: string, selectedSubDomains: string[], singleReport: boolean) => void;
    onClose: () => void;
    domains: IDomain[];
    reset: boolean;
    setResetModal: (reset: boolean) => void;
    listOfFamilyScreening: IFamilyScreeningOptions[];
}

export const ClientReportDialog = ({
    open,
    onSubmit,
    onClose,
    domains,
    reset,
    setResetModal,
    listOfFamilyScreening,
}: IClientReportDialogProps) => {
    const defaultSubDomainCodes = getDomainsSubDomainsCodes(domains);
    const [selectedSubDomains, setSelectedSubDomains] = useState<string[]>(defaultSubDomainCodes);
    const [password, setPassword] = useState<string>('');
    const [activeStep, setActiveStep] = useState<number>(0);
    const [pushed, setPushed] = useState<boolean>(false);
    const steps = ['Report Customisation', 'Password Protect'];
    const [selectedScreenings, setSelectedScreenings] = useState<string[]>(
        listOfFamilyScreening.map((screening) => screening.screeningId),
    );
    const [reportType, setReportType] = useState<string>(ReportType.SINGLE_PDF);

    const handleBrowserBack = useCallback(() => {
        onClose();
    }, []);

    useEffect(() => {
        if (open) {
            !pushed && history.pushState(null, document.title, location.href);
            setPushed(true);
            window.addEventListener('popstate', handleBrowserBack);
        } else {
            window.removeEventListener('popstate', handleBrowserBack);
        }
    }, [open]);

    useEffect(() => {
        if (reset === true) {
            setResetModal(false);
            setPassword('');
            setSelectedSubDomains(defaultSubDomainCodes);
            setActiveStep(0);
        }
    }, [reset]);

    const handleSelectChange = (selected: string[]): void => {
        setSelectedSubDomains(selected);
    };

    const handleScreeningChange = (screenings: string[]): void => {
        setSelectedScreenings(screenings);
    };

    const handleReportTypeChange = (reportType: string): void => {
        if (Object.values(ReportType).some((value) => value === reportType)) {
            setReportType(reportType);
        }
    };

    const handlePreviousStep = () => {
        setActiveStep(0);
    };

    const handleNextStep = () => {
        setActiveStep(1);
    };

    const handlePasswordChange = (password: string): void => {
        setPassword(password);
    };

    const handleDownloadReport = (): void => {
        const singleReport = reportType === ReportType.SINGLE_PDF;
        onSubmit(selectedScreenings, password, selectedSubDomains, singleReport);
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <SelectSubDomainsDialogForm
                        domains={domains}
                        selectedSubDomains={selectedSubDomains}
                        onSelectSubDomains={handleSelectChange}
                        onSubmit={handleNextStep}
                        listOfFamilyScreening={listOfFamilyScreening}
                        selectedFamilyScreenings={selectedScreenings}
                        onSelectFamilyScreenings={handleScreeningChange}
                        reportType={reportType}
                        onReportTypeChange={handleReportTypeChange}
                    />
                );
            case 1:
                return (
                    <ReportPasswordDialogForm
                        password={password}
                        onPasswordChange={handlePasswordChange}
                        onSubmit={handleDownloadReport}
                        hasDivider={true}
                        title={COPIES.REPORT_PASSWORD_DIALOG_TITLE}
                        description={COPIES.REPORT_PASSWORD_DIALOG_BODY}
                    />
                );
            default:
                return <Typography>{COPIES.REPORT_CUSTOMISATION_STEP_ERROR}</Typography>;
        }
    };

    return (
        <div styleName="clientReportDialog">
            <Dialog open={open} aria-describedby="alert-dialog-description">
                <DialogActions>
                    <Grid container>
                        <Grid item xs={1} style={{paddingLeft: 24}}>
                            {activeStep === 1 && <BackButton onClick={handlePreviousStep} />}
                        </Grid>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" styleName="closeButton" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogActions>
                <Stepper nonLinear alternativeLabel activeStep={activeStep} style={{padding: 0}}>
                    {steps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepButton disabled>{label}</StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                {getStepContent(activeStep)}
            </Dialog>
        </div>
    );
};
