import {makeStyles} from '@mui/styles';

export const useResourceTypeAutocompleteStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        marginRight: '16px',
        paddingTop: '2px',
    },
    selectAllCheckBox: {},
    normalCheckBox: {},
});
