import React, {useEffect, useState} from 'react';
import {IFetchStatus} from '~/interfaces/common';
import {API_FETCH_STATE} from '~/constants';
import {ProgressBar} from '~/components/Common/ProgressBar/ProgressBar';

export const Error = () => <div>An unexpected error has occurred. Please try again later</div>;

export const WithLoader = <P extends {}>(Component: React.ComponentType<P>, fetchStatus: IFetchStatus) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(fetchStatus === API_FETCH_STATE.PENDING);
        setHasError(fetchStatus === API_FETCH_STATE.ERROR);
    }, [fetchStatus]);

    const WithLoaderComponent = ({...passThroughProps}: P) => {
        return isLoading ? <ProgressBar /> : hasError ? <Error /> : <Component {...passThroughProps} />;
    };

    return WithLoaderComponent;
};
