import React from 'react';
import {Drawer, List, ListItem, Typography, Grid, Divider, Collapse} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {GetAdminNavigation} from './AdminNavigation';
import './AdminSideBar.scss';
import {DropdownLinksList} from './DropdownLinksList';

interface IAdminSideBarProps {
    accessToken: IAccessToken;
}

export const AdminSideBar: React.FunctionComponent<IAdminSideBarProps> = (props) => {
    const history = useHistory();
    const pathname = history.location.pathname;

    return (
        <Drawer variant="permanent" id="adminSidebar">
            <List data-testid="listItem">
                {GetAdminNavigation(props.accessToken).map((parent, index) => {
                    if (parent?.dropdownChildren) {
                        return (
                            <DropdownLinksList
                                name={parent.name}
                                dropdownChildren={parent?.dropdownChildren}
                                key={index}
                            />
                        );
                    }

                    const isSelected = parent.isSelected(pathname);
                    const labelStyle = isSelected ? `selected labelSelected` : 'label';
                    const Icon = parent.icon ? parent.icon(isSelected) : <div />;

                    return (
                        <div key={index}>
                            <ListItem
                                data-testid={`item_${index}`}
                                disableGutters
                                styleName="listItem"
                                onClick={() => {
                                    history.push(parent.path);
                                }}
                            >
                                <Grid container>
                                    <Grid data-testid={`itemIcon_${index}`} item xs={2}>
                                        {Icon}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography data-testid={`itemName_${index}`} styleName={labelStyle}>
                                            {parent.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>

                            {parent?.children && (
                                <Collapse
                                    in={
                                        parent.isSelected(pathname) &&
                                        parent.isShowChildren &&
                                        parent.isShowChildren(pathname)
                                    }
                                >
                                    <Divider />
                                    <List data-testid={`children`}>
                                        {parent?.children.map((child, childKey) => {
                                            const childLabelStyle = child.isSelected(pathname, history.location.hash)
                                                ? 'childLabel selected'
                                                : 'childLabel';
                                            return (
                                                <div key={childKey}>
                                                    <ListItem
                                                        data-testid={`childrenListItem_${childKey}`}
                                                        disableGutters
                                                        styleName="listItem"
                                                        onClick={() => {
                                                            history.push(
                                                                child.path ? child.path : `${pathname}#${child.hash}`,
                                                            );
                                                        }}
                                                    >
                                                        <Typography styleName={childLabelStyle}>
                                                            {child.name}
                                                        </Typography>
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </List>
        </Drawer>
    );
};
