import React, {ChangeEvent, KeyboardEvent} from 'react';
import {ILoginUser} from '~/components/LoginForm/account';
import {FormControl, InputLabel, Select, Typography, TextField, SelectChangeEvent} from '@mui/material';
import './LoginForm.scss';

export enum MOCK_USER_TYPE {
    INTRANET = 'intranet',
    INTERNET = 'internet',
}

export interface ILoginFormProps {
    users: ILoginUser[];
    organisationType: string;
    userName?: string | null;
    userNameText?: string | null;
    onUserNameChange: (event: SelectChangeEvent<{name?: string; value: unknown}>) => void;
    onUserNameTextChange: (orgType: string, event: ChangeEvent<HTMLInputElement>) => void;
    onEnter: () => void;
}

export const LoginForm = (props: ILoginFormProps) => {
    const {users, organisationType, onUserNameChange, onUserNameTextChange, onEnter, userName, userNameText} = props;

    const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') onEnter();
    };

    return (
        <>
            <Typography gutterBottom component="h2" styleName="uppercase" variant="h5">
                {organisationType}
            </Typography>
            <Typography color="textSecondary" component="p" variant="body2">
                For {organisationType} users...
            </Typography>
            <FormControl styleName="select">
                <InputLabel htmlFor="selectUser">Users</InputLabel>
                <Select
                    native
                    variant="standard"
                    inputProps={{
                        id: 'selectUser',
                        name: 'user',
                    }}
                    value={userName as ''}
                    onChange={onUserNameChange}
                >
                    <option value=""></option>
                    {users.map(({userName}, index) => {
                        return (
                            <option key={index} value={userName}>
                                {userName}
                            </option>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl styleName="textbox-group" variant="outlined">
                <TextField
                    value={userNameText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onUserNameTextChange(organisationType, e)}
                    label="UserName"
                    variant="outlined"
                    onKeyPress={handleKeyPress}
                />
            </FormControl>
        </>
    );
};
