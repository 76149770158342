import React, {useContext, useEffect, useState, useRef} from 'react';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {Redirect, useHistory} from 'react-router';
import {EmptyDashboard} from '~/components/Admin/Dashboard/EmptyDashboard';
import {IDashboardInfo, IDashboardBasic} from '~/interfaces/dashboard';
import {DashboardBasic} from '~/components/Admin/Dashboard/DashboardBasic';
import {AdminManagement} from '~/components/Admin/AdminSideBar/AdminNavigation';
import {ACCESS_LEVELS, API_FETCH_STATE, ERROR_MESSAGE, LOADING_MESSAGE, MONTHLY_REVIEW_STATUS} from '~/constants';
import {History} from 'history';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {AnnouncementBanner, ANNOUNCEMENT_LOCATION} from '~/components/Common/AnnouncementBanner/AnnouncementBanner';
import {NotificationBanner} from '~/components/Common/NotificationBanner/NotificationBanner';
import './Dashboard.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setDashboard} from '~/store/dashboard/dashboard.thunk';
import {getDashboardFetchState} from '~/store/fetchState/fetchState.selector';
import {getRequiresMonthlyReview} from '~/services/userServices';
import {formatDateMonthName, dateFormatter} from '~/utils/dateUtils';
import {getRequiresMonthlyAdminReview} from '~/services/adminServices';
import {
    ICurrentRequestWindow,
    IDashboardAdminReviewBanner,
    IMonthlyReviewResponse,
    IRequestWindow,
    ITeam,
} from '~/interfaces/admin';
import {IFetchStatus} from '~/interfaces/common';
import {setTeams} from '~/store/team/team.thunk';
import {getTeams} from '~/store/team/team.selector';
import {forEach, size} from 'lodash';
import {getRequiresMonthlyDataContributorReview} from '~/services/dataContributorServices';
import {getCurrentRequestWindow} from '~/services/requestWindowServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {config} from '~/config';

interface IDashboardContentProps {
    accessToken: IAccessToken;
    dashboardInfo: IDashboardInfo;
    history: History;
    monthlyReview?: IDashboardAdminReviewBanner;
    currRequestWindow?: IRequestWindow;
}

export const shouldRenderDashboard = (accessLevel: ACCESS_LEVELS, dashboardBasic: IDashboardBasic) => {
    if (dashboardBasic) {
        const criterias = (info: string) => !isNaN(Number(info)) && Number(info) > 0;

        switch (accessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return criterias(dashboardBasic.AgencyInfo);
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return criterias(dashboardBasic.OpsUnitInfo);
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return criterias(dashboardBasic.TeamInfo);
            default:
                false;
        }
    }
    return false;
};

export const renderMonthlyReviewReminderBanner = (
    accessToken: IAccessToken,
    monthlyReview: IDashboardAdminReviewBanner | undefined,
) => {
    const lastDayOfReviewPeriod = new Date(new Date().getFullYear(), new Date().getMonth(), 21).toLocaleString(
        'default',
        {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        },
    );

    const isAgencyAdmin = accessToken.Permissions.AccessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR;
    const isLeadSystemAdmin = accessToken.Permissions.AccessLevel === ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR;
    const isSystemAdmin = accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR;

    const messageNode = (
        <>
            {monthlyReview?.Message}
            <b> {formatDateMonthName(lastDayOfReviewPeriod, isAgencyAdmin || isLeadSystemAdmin || isSystemAdmin)}</b>.
        </>
    );
    return monthlyReview && monthlyReview.Status === MONTHLY_REVIEW_STATUS.ONGOING ? (
        <NotificationBanner
            componentName="reviewBanner"
            messageNode={messageNode}
            linkLabel="TAKE ME THERE"
            link={monthlyReview.Link}
        />
    ) : (
        <></>
    );
};

export const renderRequestWindowOpenBanner = (accessToken: IAccessToken, requestWindow: IRequestWindow | undefined) => {
    const windowStartDateFormatted = requestWindow?.WindowStart && dateFormatter(requestWindow.WindowStart);
    const windowEndDateFormatted = requestWindow?.WindowEnd && dateFormatter(requestWindow.WindowEnd);

    const windowOpenMessage = `Data access request window is open from `;
    const linkToDAM = `/admin/data-access-management/ops-unit/${accessToken.Permissions.OpsUnitId}`;

    const messageNode = (
        <>
            {windowOpenMessage}
            <b>
                {windowStartDateFormatted}
                {' to '}
                {windowEndDateFormatted}
            </b>
            .
        </>
    );

    return requestWindow ? (
        <NotificationBanner
            componentName="requestWindowOpenBanner"
            messageNode={messageNode}
            linkLabel="UPDATE DATA ACCESS"
            link={linkToDAM}
        />
    ) : (
        <></>
    );
};

const DashboardContent: React.FunctionComponent<IDashboardContentProps> = ({
    accessToken,
    dashboardInfo,
    history,
    monthlyReview,
    currRequestWindow,
}) => {
    return (
        <>
            <AnnouncementBanner location={ANNOUNCEMENT_LOCATION.ADMIN_DASHBOARD} />
            {renderMonthlyReviewReminderBanner(accessToken, monthlyReview)}
            {renderRequestWindowOpenBanner(accessToken, currRequestWindow)}
            {shouldRenderDashboard(accessToken.Permissions.AccessLevel, dashboardInfo.BasicInfo) ? (
                <DashboardBasic
                    data-testid="dashboardBasic"
                    accessLevel={accessToken.Permissions.AccessLevel}
                    basicInfo={dashboardInfo.BasicInfo}
                />
            ) : (
                <EmptyDashboard
                    data-testid="emptyDashboard"
                    accessLevel={accessToken.Permissions.AccessLevel}
                    handleClick={() => {
                        if (accessToken) {
                            history.push(AdminManagement(accessToken).path);
                        }
                    }}
                />
            )}
        </>
    );
};

export const Dashboard: React.FunctionComponent<{}> = () => {
    const {accessToken} = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    const dashboardInfo = useTypedSelector((state) => state.data.dashboard.dashboardInfo);
    const fetchStatus = useTypedSelector(getDashboardFetchState);
    const history = useHistory();
    const [monthlyReview, setMonthlyReview] = useState<IDashboardAdminReviewBanner>();
    const [reviewFetchStatus, setReviewFetchStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const [currRequestWindow, setCurrRequestWindow] = useState<IRequestWindow>();
    const [windowFetchStatus, setWindowFetchStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const teams = Object.values(useTypedSelector(getTeams(Number(accessToken?.Permissions.OpsUnitId))));
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(setDashboard());
        dispatch(setTeams(Number(accessToken?.Permissions.OpsUnitId)));
    }, []);

    useEffect(() => {
        if (
            reviewFetchStatus === API_FETCH_STATE.SUCCESS &&
            fetchStatus === API_FETCH_STATE.SUCCESS &&
            windowFetchStatus !== API_FETCH_STATE.PENDING
        ) {
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        } else {
            setCombinedFetchStatus(API_FETCH_STATE.PENDING);
        }
    }, [reviewFetchStatus, fetchStatus, windowFetchStatus]);

    useEffect(() => {
        if (accessToken) {
            switch (accessToken.Permissions.AccessLevel) {
                case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                    getRequiresMonthlyDataContributorReview().then((resp: IMonthlyReviewResponse) => {
                        setMonthlyReview({
                            ...resp,
                            Message: `Review Data Contributors accounts by `,
                            Link: `/admin/data-contributors`,
                        });
                        setReviewFetchStatus(API_FETCH_STATE.SUCCESS);
                    });
                    break;
                case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
                    getRequiresMonthlyAdminReview().then((resp: IMonthlyReviewResponse) => {
                        setMonthlyReview({
                            ...resp,
                            Message: `Review System Admin accounts by `,
                            Link: `/admin/system-admins`,
                        });
                        setReviewFetchStatus(API_FETCH_STATE.SUCCESS);
                    });
                    break;
                case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                    getRequiresMonthlyReview().then((resp: IMonthlyReviewResponse) => {
                        setMonthlyReview({
                            ...resp,
                            Message: `Please approve the review of user accounts by `,
                            Link: `/admin/agencies/${accessToken.Permissions.AgencyId}/ops-units`,
                        });
                        setReviewFetchStatus(API_FETCH_STATE.SUCCESS);
                    });
                    break;
                case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                    getRequiresMonthlyReview().then((resp: IMonthlyReviewResponse) => {
                        if (resp && resp.Status === MONTHLY_REVIEW_STATUS.ONGOING && !leadToAdmin(teams)) {
                            setMonthlyReview({
                                ...resp,
                                Message: `Please complete your review of user accounts in`,
                                Link: `/admin/ops-units/${accessToken.Permissions.OpsUnitId}/users`,
                            });
                            setReviewFetchStatus(API_FETCH_STATE.SUCCESS);
                        } else {
                            getRequiresMonthlyAdminReview().then((resp) => {
                                setMonthlyReview({
                                    ...resp,
                                    Message: `Please complete your review of user accounts in`,
                                    Link: `/admin/ops-units/${accessToken.Permissions.OpsUnitId}/teams`,
                                });
                                setReviewFetchStatus(API_FETCH_STATE.SUCCESS);
                            });
                        }
                    });
                    break;
            }
        }
    }, [reviewFetchStatus]);

    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2) {
            setWindowFetchStatus(API_FETCH_STATE.SUCCESS);
            return;
        }
        if (accessToken) {
            switch (accessToken.Permissions.AccessLevel) {
                case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                    getCurrentRequestWindow()
                        .then((resp: ICurrentRequestWindow) => {
                            resp.CurrentWindowPeriod &&
                                setCurrRequestWindow({
                                    ...resp.CurrentWindowPeriod,
                                });

                            setWindowFetchStatus(API_FETCH_STATE.SUCCESS);
                        })
                        .catch(() => {
                            setWindowFetchStatus(API_FETCH_STATE.ERROR);
                            openGrowl(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
                        });
                    break;
                default:
                    setWindowFetchStatus(API_FETCH_STATE.SUCCESS);
            }
        }
    }, []);

    const leadToAdmin = (allTeams: ITeam[]) => {
        const teamsWithAdmin: Boolean[] = [];
        forEach(allTeams, function (value) {
            if (value.Admins && value.Admins?.length > 0) {
                teamsWithAdmin.push(true);
            } else {
                teamsWithAdmin.push(false);
            }
        });

        //if there are more than 1 team and some of them do not have admin go to admin page
        if (size(allTeams) > 1 && !teamsWithAdmin.every((value) => value === true)) {
            return true;
        }

        if (size(allTeams) === 1 && teamsWithAdmin.every((value) => value === true)) {
            return false;
        }

        //if there are more than 1 team and ALL of them do not have admin go to admin page --> return true
        return teamsWithAdmin.every((value) => value === true);
    };

    const DashboardContentWithLoader = WithAdminLoader<IDashboardContentProps>(
        DashboardContent,
        combinedFetchStatus,
        LOADING_MESSAGE.DATA,
    );

    return accessToken && dashboardInfo ? (
        <>
            <DashboardContentWithLoader
                accessToken={accessToken}
                history={history}
                dashboardInfo={dashboardInfo}
                monthlyReview={monthlyReview}
                currRequestWindow={currRequestWindow}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={headerRef}
            />
        </>
    ) : (
        <Redirect to={'/'} />
    );
};
