import {IOpsUnit, IUser, IOpsUnitResponse} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {IUpdateOpsUnitCanScreenClient} from '~/store/opsUnit/opsUnit.thunk';

export const getDamOpsUnits = async (): Promise<IOpsUnit[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam/ops-units`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getOpsUnits = async (agencyId: number): Promise<IOpsUnitResponse[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/ops-units`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getOpsUnit = async (opsUnitId: number): Promise<IOpsUnit> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createOpsUnit = async (agencyId: number, opsUnit: IOpsUnit): Promise<IOpsUnitResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/ops-unit`,
        data: JSON.stringify(opsUnit),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const createOpsUnitAdmin = async (opsUnitId: number, admin: IUser): Promise<IOpsUnitResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/ops-unit-admin`,
        data: JSON.stringify(admin),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateOpsUnitAdmin = async (opsUnitId: number, adminDetails: IUser) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/ops-unit-admin/${adminDetails.Id}`,
        data: JSON.stringify(adminDetails),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const deleteOpsUnitAdmin = async (
    opsUnitId: number,
    userId: number,
    deletedReason: string,
): Promise<IOpsUnitResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/ops-unit-admin/${userId}`,
        data: JSON.stringify({DeletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const activateOpsUnitAdmin = async (opsUnitId: number, userId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/ops-unit-admin/${userId}/reactivate`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const updateOpsUnitDetails = async (opsUnit: IOpsUnit): Promise<IOpsUnitResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnit.Id}`,
        data: JSON.stringify(opsUnit),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const updateOpsUnitCanScreenClient = async (updateOpsUnit: IUpdateOpsUnitCanScreenClient) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-unit/set-can-screen`,
        data: JSON.stringify(updateOpsUnit),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const deleteOpsUnit = async (opsUnitId: number, deletedReason: string) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}`,
        data: JSON.stringify({deletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getOpsUnitReasonForAccess = async (opsUnitId: string): Promise<string> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/reason-for-access/ops-units/${opsUnitId}`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data.Reason;
};
