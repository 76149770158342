import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Grid, Typography, TextField, InputAdornment, Stack, Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {cloneDeep, uniq} from 'lodash';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import * as services from '~/services/dataAccessMatrixService';
import {API_FETCH_STATE} from '~/constants';
import {IDataAccessMatrixDomain, IOpsUnitDataFieldRejectReason} from '~/interfaces/admin';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {BackButton} from '~/components/Common/Button/BackButton';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {Domain} from './Domain';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDomains} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {getDamFieldsFetchState, getDamOpsUnitFetchState} from '~/store/fetchState/fetchState.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {setDamOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {getDamOpsUnit, getDamOpsUnitExist} from '~/store/opsUnit/opsUnit.selector';

import '../Admin.scss';
import './RejectedAccessHistory.scss';
import listNotFound from '~/assets/images/listNotFound.svg';

const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

export const OpsUnitName = ({opsUnitId}: {opsUnitId: number}) => {
    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));

    return (
        <>
            <Typography variant="h5">Rejected Access History</Typography>
            <Stack direction="row" spacing={1}>
                <Typography variant="subtitle1">{opsUnit?.Name}</Typography>
            </Stack>
        </>
    );
};

export const RejectedAccessHistory = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [domainOptions, setDomainOptions] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const [filteredDomains, setFilteredDomains] = useState<IDataAccessMatrixDomain[]>([]);
    const [fetchOpsUnitRejectedReasonsStatus, setFetchOpsUnitRejectedReasonsStatus] = useState<API_FETCH_STATE>(
        API_FETCH_STATE.PENDING,
    );
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [opsUnitRejectedReasons, setOpsUnitRejectedReasons] = useState<IOpsUnitDataFieldRejectReason[]>([]);

    const domains = useTypedSelector(getDomains);

    const domainFilterClasses = useDomainFilterStyles();
    const damFieldsFetchState = useTypedSelector(getDamFieldsFetchState);
    const opsUnitFetchState = useTypedSelector(getDamOpsUnitFetchState);

    const opsUnitIdInt = isNaN(parseInt(opsUnitId)) ? -1 : parseInt(opsUnitId);
    const opsUnitExist = useTypedSelector(getDamOpsUnitExist(opsUnitIdInt));

    const getOpsUnitDataFieldsRejectAccessReasons = () => {
        const domainMatrix = domains.reduce(
            (matrix, domain) => {
                const rejectedReason: {
                    [subDomainId: number]: {
                        dataFieldIds: number[];
                        rejectedAt: Date;
                        rejectedBy: string;
                        reason: string;
                    }[];
                } = {};
                domain.SubDomains.forEach((subDomain) => {
                    const rejectedReasonsBySubdomain = opsUnitRejectedReasons?.filter(
                        (r) => r.SubdomainId === subDomain.Id,
                    );
                    if (rejectedReasonsBySubdomain?.length) {
                        rejectedReason[subDomain.Id] = [];
                        rejectedReasonsBySubdomain.forEach((r) => {
                            const reason = {
                                dataFieldIds: r.Fields?.map((f) => f.DataFieldId),
                                rejectedAt: r.RejectedOn,
                                rejectedBy: r.RejectionBy.UserName,
                                reason: r.Reason,
                            };
                            rejectedReason[subDomain.Id].push(reason);
                        });
                        matrix.accessRejectedReasons[domain.Id] = rejectedReason;
                    }
                });
                return matrix;
            },
            {accessRejectedReasons: {}} as {
                accessRejectedReasons: {
                    [domainId: number]: {
                        [subDomainId: number]: {
                            dataFieldIds: number[];
                            rejectedBy: string;
                            rejectedAt: Date;
                            reason: string;
                        }[];
                    };
                };
            },
        );

        return {
            [Number(opsUnitId)]: domainMatrix,
        };
    };

    useEffect(() => {
        if (
            damFieldsFetchState === API_FETCH_STATE.SUCCESS &&
            opsUnitFetchState === API_FETCH_STATE.SUCCESS &&
            fetchOpsUnitRejectedReasonsStatus === API_FETCH_STATE.SUCCESS
        ) {
            if (!opsUnitExist) {
                history.push('/admin/dashboard');
                return;
            }
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        }

        if (
            damFieldsFetchState === API_FETCH_STATE.ERROR ||
            opsUnitFetchState === API_FETCH_STATE.ERROR ||
            fetchOpsUnitRejectedReasonsStatus === API_FETCH_STATE.ERROR
        ) {
            setCombinedFetchStatus(API_FETCH_STATE.ERROR);
        }
    }, [damFieldsFetchState, opsUnitFetchState, fetchOpsUnitRejectedReasonsStatus]);

    useEffect(() => {
        dispatch(setDataAccessMatrix());
        dispatch(setDamOpsUnits());
        (async () => {
            try {
                const reasons = await services.getRejectAccessHistoryList(opsUnitIdInt);
                if (reasons) {
                    setOpsUnitRejectedReasons(reasons);
                }
                setFetchOpsUnitRejectedReasonsStatus(API_FETCH_STATE.SUCCESS);
            } catch (e) {
                setFetchOpsUnitRejectedReasonsStatus(API_FETCH_STATE.ERROR);
            }
        })();
    }, []);

    useEffect(() => {
        if (domains.length > 0 && combinedFetchStatus === API_FETCH_STATE.SUCCESS) {
            dispatch(
                dataAccessMatrix2Actions.SET_OPS_UNIT_DATA_ACCESS_MATRIX(getOpsUnitDataFieldsRejectAccessReasons()),
            );
            if (domainOptions.length === 0) {
                setDomainOptions(uniq(domains.map((row) => row.Name)));
            }
            applySearchFilter(searchValue);
        }
    }, [domains, combinedFetchStatus]);

    const applySearchFilter = (searchVal: string) => {
        const lowerCaseSearchValue = searchVal.toLowerCase();
        const filteredByDomains = cloneDeep(filterSelectedDomains(selectedDomains));

        const filteredDomains = filteredByDomains.filter((domain) => {
            domain.SubDomains = domain.SubDomains.filter((subDomain) => {
                subDomain.DataFields = subDomain.DataFields?.filter((field) => {
                    return (
                        (field.DataSource && field.DataSource.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1) ||
                        field.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                        subDomain.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1
                    );
                });
                return subDomain.DataFields && subDomain.DataFields?.length > 0;
            });
            return domain.SubDomains.length > 0;
        });
        setFilteredDomains(filteredDomains);
    };

    const filterSelectedDomains = (selectedDomains: string[]): IDataAccessMatrixDomain[] => {
        if (selectedDomains.length === 0) return domains;
        return domains.filter((domain) => selectedDomains.includes(domain.Name));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        applySearchFilter(e.target.value);
    };

    const handleSelectDomain = (selectedDomains: string[]) => {
        setSelectedDomains(selectedDomains);
        setFilteredDomains(filterSelectedDomains(selectedDomains));
    };

    const handleClearDomains = () => {
        handleSelectDomain([]);
    };

    const handleSelectAllDomains = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectDomain(domainOptions);
        } else {
            handleClearDomains();
        }
    };

    const DomainMemo = React.memo(Domain);
    const DomainContent = () => {
        return (
            <>
                {filteredDomains.map((domain) => {
                    return (
                        <DomainMemo
                            domain={domain}
                            onExpandAll={true}
                            key={domain.Id}
                            opsUnitId={parseInt(opsUnitId)}
                        />
                    );
                })}
            </>
        );
    };

    const DomainContentWithLoader = WithLoader<{}>(DomainContent, combinedFetchStatus);

    const NoReasons = () => {
        return (
            <Grid container style={{marginTop: '20px'}}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <img src={listNotFound} alt="Not Found" styleName="not-found-svg" />
                        <h1 styleName="not-found-header">No rejected access found</h1>
                        <p styleName="not-found-text">
                            Data fields that are rejected and the reasons for rejection will show up here.
                        </p>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    const NoReasonsWithLoader = WithLoader<{}>(NoReasons, combinedFetchStatus);

    return (
        <>
            <Grid container data-testid="damHeader" direction="row" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <BackButton
                        onClick={() => {
                            history.push(`/admin/dam2-data-access-matrix/ops-units/${opsUnitId}`);
                        }}
                        label="Back"
                    />
                </Grid>
                <Grid item xs={12}>
                    <OpsUnitName opsUnitId={Number(opsUnitId)} />
                </Grid>
                {opsUnitRejectedReasons.length !== 0 && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Search Subdomain or Field Name"
                                type="search"
                                variant="outlined"
                                value={searchValue}
                                onChange={handleSearchChange}
                                styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiSelectAutocomplete
                                options={domainOptions}
                                selectedValues={selectedDomains}
                                label="Select Domains"
                                tagRenderer={(value) => (
                                    <Typography variant="subtitle1" styleName="filterTag">
                                        {value.length} Domains Selected
                                    </Typography>
                                )}
                                onToggleOption={handleSelectDomain}
                                onClearOptions={handleClearDomains}
                                onSelectAll={handleSelectAllDomains}
                                classes={domainFilterClasses}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid styleName="reportTableStyle">
                {opsUnitRejectedReasons.length !== 0 && <DomainContentWithLoader />}
                {opsUnitRejectedReasons.length === 0 && <NoReasonsWithLoader />}
            </Grid>
        </>
    );
};
