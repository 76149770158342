import '../SubDomainDisplay.scss';
import React from 'react';
import {Grid, Typography} from '@mui/material';
import {ISubDomainDisplayProps} from '../SubDomainDisplay';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';

export const CareshieldEldershieldError = (props: ISubDomainDisplayProps) => {
    return (
        <Grid container spacing={2}>
            <Grid item id="header" xs={8}>
                <Typography variant={'h4'} styleName={'subDomainHeader'}>
                    {props.name} (Unsuccessful)
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ErrorMessage
                    message={`The data provider returned an error message: ${props.errorMessages?.join(', ')}. 
                                        You may want to try again later.`}
                    style={{paddingLeft: '8px', display: 'flex', alignItems: 'center'}}
                />
            </Grid>
        </Grid>
    );
};
