import './AgencyList.scss';
import React, {useState} from 'react';
import {Box, Collapse, IconButton, TableCell, TableRow} from '@mui/material';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import {IAgency} from '~/interfaces/admin';
import {OpsUnitRow} from './OpsUnitRow';

export interface IAgenciesProps {
    agency: IAgency;
    buttons: JSX.Element;
    setDisableSaveButton: (activeState: boolean) => void;
}
export const AgencyRow = ({agency, buttons, setDisableSaveButton}: IAgenciesProps) => {
    const [expandAgency, setExpandAgency] = useState(false);
    const opsUnits = agency.SysAdminOpsUnits;

    async function handleClick() {
        await setDisableSaveButton(false);
        setExpandAgency(!expandAgency);
    }

    const agencyAdminDetails = (index: number) => (agency: IAgency) => {
        if (!!agency && !!agency.Admins && agency.Admins[index]) {
            return (
                <Box component="div">
                    <Box component="div" styleName="nameWrapper">
                        {agency.Admins[index].Name}
                    </Box>
                    <Box component="div" styleName="designationWrapper">
                        {agency.Admins[index].Designation}
                    </Box>
                    {!agency.Admins[index].IsActive && <SuspendedIcon />}
                </Box>
            );
        } else {
            return '-';
        }
    };
    return (
        <>
            <TableRow styleName="rows">
                <TableCell align="left" styleName="data" style={{width: '40%'}}>
                    <div style={{display: 'flex'}}>
                        <IconButton
                            aria-label="expand agency row"
                            size="small"
                            onClick={handleClick}
                            disabled={opsUnits && opsUnits?.length > 0 ? false : true}
                        >
                            {expandAgency ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                        <div style={{paddingLeft: '10px'}}>{agency.Name}</div>
                    </div>
                </TableCell>
                <TableCell align="left">{agencyAdminDetails(0)(agency)}</TableCell>
                <TableCell align="left">{agencyAdminDetails(1)(agency)}</TableCell>
                <TableCell align="center">{buttons}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={5}>
                    <Collapse in={expandAgency} style={{paddingLeft: '0px'}}>
                        {opsUnits &&
                            opsUnits.map((opsUnit, index) => {
                                return <OpsUnitRow opsUnit={opsUnit} key={index} />;
                            })}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
