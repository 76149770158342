import {
    ACCESS_LEVELS,
    oneCVAPIProviderStartDay,
    oneCVMonthlyAdminReviewStartDay,
    oneCVStartDay,
    REPORT_TYPE,
} from '~/constants';

interface Entity {
    EntityType: string;
    EntityId: number;
}

export interface IReportBase {
    Type: REPORT_TYPE;
    Entity?: Entity;
    Name: string | JSX.Element;
    Description: string | JSX.Element;
    DateFrom?: Date | string;
    DateTo?: Date | string;
    SelectByMonth?: boolean;
    fixedDate?: boolean;
}

export interface IReportCustomisation {
    dateFrom?: string | Date;
}

const APIConsumerRequestsReport = ({
    dateFrom,
    dateTo,
}: {dateFrom?: string | Date; dateTo?: string | Date} = {}): IReportBase => ({
    Type: REPORT_TYPE.API_CONSUMER_REQUESTS_REPORT,
    Name: 'API Consumers',
    Description: 'Requests by API Consumers',
    DateFrom: typeof dateFrom === 'string' ? new Date(dateFrom) : dateFrom || new Date(oneCVAPIProviderStartDay),
    DateTo: typeof dateTo === 'string' ? new Date(dateTo) : dateTo || new Date(),
});

const UserScreeningReport = ({
    dateFrom,
    dateTo,
}: {dateFrom?: string | Date; dateTo?: string | Date} = {}): IReportBase => ({
    Type: REPORT_TYPE.SCREENING_BY_USERS,
    Name: 'Screenings by Users',
    Description: 'List of screenings performed by users',
    DateFrom: typeof dateFrom === 'string' ? new Date(dateFrom) : dateFrom || new Date(oneCVStartDay),
    DateTo: typeof dateTo === 'string' ? new Date(dateTo) : dateTo || new Date(),
});

const UserReport = (): IReportBase => ({
    Type: REPORT_TYPE.USER_REPORT,
    Name: 'User Report',
    Description: 'List of users',
});

const MonthlyReviewReport = (): IReportBase => ({
    Type: REPORT_TYPE.MONTHLY_REVIEW_REPORT,
    Name: '(Archived) Monthly User Reviews ',
    Description: 'List of user account reviews performed by OA/TA',
    fixedDate: true,
});

const MonthlyReviewAdminReport = (): IReportBase => ({
    Type: REPORT_TYPE.MONTHLY_REVIEW_ADMIN_REPORT,
    Name: 'Review of User Accounts',
    Description: 'View status of the monthly account reviews',
    DateFrom: new Date(oneCVMonthlyAdminReviewStartDay),
    DateTo: new Date(),
    SelectByMonth: true,
});

const SurveysResultsReport = (): IReportBase => ({
    Type: REPORT_TYPE.SURVEYS_RESULTS_REPORT,
    Name: 'Surveys Results',
    Description: 'View all survey results',
});

const IrregularActivitiesReport = (): IReportBase => ({
    Type: REPORT_TYPE.IRREGULAR_ACTIVITIES_REPORT,
    Name: 'Irregular Activities',
    Description: 'View all irregular activities',
});

const DataAccessMatrixReport = (): IReportBase => ({
    Type: REPORT_TYPE.DAM_REPORT,
    Name: 'DAM Report',
    Description: 'Data Access Matrix by Ops Units',
});

const ConsentAuditReport = (): IReportBase => ({
    Type: REPORT_TYPE.CONSENT_AUDIT_REPORT,
    Name: 'Consent Management',
    Description: 'List of Consent Records',
});

const DeletionReport = (): IReportBase => ({
    Type: REPORT_TYPE.DELETION_REPORT,
    Name: 'Deleted Agencies, Ops Units and Teams',
    Description: 'List of deleted Agencies, Ops Units and Teams',
});

export const GetReportsByAccess = (accessLevel: ACCESS_LEVELS | undefined): IReportBase[] => {
    switch (accessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
            return [
                UserReport(),
                UserScreeningReport(),
                MonthlyReviewReport(),
                MonthlyReviewAdminReport(),
                SurveysResultsReport(),
                IrregularActivitiesReport(),
                DataAccessMatrixReport(),
                ConsentAuditReport(),
                APIConsumerRequestsReport(),
                DeletionReport(),
            ];
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport(), IrregularActivitiesReport()];
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            return [UserReport(), UserScreeningReport(), IrregularActivitiesReport()];
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport(), IrregularActivitiesReport()];
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
            return [UserReport(), UserScreeningReport(), IrregularActivitiesReport()];
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport(), IrregularActivitiesReport()];
        default:
            return [];
    }
};

export const GetReportsByAccessCustomised = (
    accessLevel: ACCESS_LEVELS | undefined,
    {dateFrom}: IReportCustomisation = {},
): IReportBase[] => {
    switch (accessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
            return [
                UserReport(),
                UserScreeningReport({dateFrom}),
                MonthlyReviewReport(),
                MonthlyReviewAdminReport(),
                SurveysResultsReport(),
                IrregularActivitiesReport(),
                DataAccessMatrixReport(),
                ConsentAuditReport(),
                APIConsumerRequestsReport({dateFrom}),
                DeletionReport(),
            ];
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport({dateFrom}), IrregularActivitiesReport()];
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            return [UserReport(), UserScreeningReport({dateFrom}), IrregularActivitiesReport()];
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport({dateFrom}), IrregularActivitiesReport()];
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
            return [UserReport(), UserScreeningReport({dateFrom}), IrregularActivitiesReport()];
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
            return [UserReport(), UserScreeningReport({dateFrom}), IrregularActivitiesReport()];
        default:
            return [];
    }
};
