import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Grid, InputAdornment, TextField, Typography, Button, Tabs, Tab, Box, Snackbar, IconButton} from '@mui/material';
import {
    ArrowBackRounded as ArrowBackIcon,
    SearchRounded as SearchIcon,
    CloseRounded as CloseIcon,
} from '@mui/icons-material';
import _, {flatMap, some, uniq} from 'lodash';
import {ClientInfoFooter} from '~/components/Client/ClientInfoFooter/ClientInfoFooter';
import {ConsentAndAccess} from '~/pages/Client/ConsentAndAccess/ConsentAndAccess';
import {ScreenClientTable, IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {TabPanel} from '../../../components/Common/TabPanel';
import {SelectedChips} from './SelectedChips';
import {EmptyFavourite} from './EmptyFavourite';
import {IAccessReasons, IFamilyScreeningRequest} from '~/interfaces/clientUser';
import {
    API_FETCH_STATE,
    CONSENT_TYPE,
    COPIES,
    ERROR_MESSAGE,
    Relationship,
    USER_FLAGS,
    familyMembersPerScreeningLimit,
} from '~/constants';
import {initiateScreening} from '~/services/clientServices';
import {ProgressBar} from '~/components/Common/ProgressBar/ProgressBar';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {useAgencyAutocompleteStyles, useCategoryAutocompleteStyles} from './ScreenClient.styles';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import Emitter, {EVENT_TYPE} from '~/utils/events/emitter';
import {setFooterDisplay, unsetFooterDisplay} from '~/utils/forceFooterDisplay';
import {SelectFamilyMembers} from '~/components/Client/ScreenClient/SelectFamilyMembers';
import {IFamilyMember} from '~/interfaces/client';
import {DoNotShowAgainDialog} from '~/components/Common/DoNotShowAgainDialog/DoNotShowAgainDialog';
import {FamilyMembersConfirmationDialog} from '~/components/Client/ScreenClient/FamilyMembersConfirmationDialog';
import './ScreenClient.scss';
import {isAppUser} from '~/utils/roleUtils';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setDomains} from '~/store/domain/domains.thunk';
import {getDomainsFetchState} from '~/store/fetchState/fetchState.selector';
import {
    setClientUserDataAccess,
    setClientUserSubDomainFavourites,
    setNewClientUserSubDomainFavourites,
    deleteClientUserSubDomainFavourite,
} from '~/store/clientUser/clientUser.thunk';
import {updateUserFlags} from '~/store/userFlags/userFlags.thunk';

type IToggleKeys = 'isSelected' | 'isFavourite';
type TDialogTypes =
    | 'subDomainsConfirmation'
    | 'familyMembersConfirmation'
    | 'addPerson'
    | 'familyScreeningFeature'
    | 'none';

const toggleSelection = (rowsData: IRowData[], subDomainId: number, key: IToggleKeys) =>
    rowsData.map((row) => {
        if (row.subDomainId === subDomainId) {
            return {
                ...row,
                [key]: !row[key],
            };
        } else {
            return row;
        }
    });

export const ScreenClient = () => {
    const {accessToken} = useContext(AuthenticationContext);
    const [masterRows, setMasterRows] = useState<IRowData[]>([]);
    const [rows, setRows] = useState<IRowData[]>([]);
    const [agencyFilters, setAgencyFilters] = useState<string[]>([]);
    const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [tabValue, setTabValue] = useState<number>(0);
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [agencyOptions, setAgencyOptions] = useState<string[]>([]);
    const [consentDialog, setConsentDialog] = useState(false);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const basicInfo = useTypedSelector((state) => state.data.client.searchedClientInfo);
    const domains = useTypedSelector((state) => state.data.common.domains);
    const dataAccess = useTypedSelector((state) => state.data.clientUser.dataAccess.data);
    const favourites = useTypedSelector((state) => state.data.clientUser.favourites);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFamilyMembers, setSelectedFamilyMembers] = useState<IFamilyMember[]>([]);
    const [selectedFamilyMembersIncludingClient, setSelectedFamilyMembersIncludingClient] = useState<IFamilyMember[]>(
        [],
    );
    const [openDialog, setOpenDialog] = useState<TDialogTypes>('none');
    const shouldConfirmFamily = selectedFamilyMembers.some((m) => m.Relationship === Relationship.UNKNOWN);
    const selectedAllSubdomainTrigger = useTypedSelector(
        (state) => state.data.common.userFlags[USER_FLAGS.SELECT_SUBDOMAIN_REMINDER],
    );

    const agencyClasses = useAgencyAutocompleteStyles();
    const categoryClasses = useCategoryAutocompleteStyles();

    const userDataAccessFetchState = useTypedSelector((state) => state.data.clientUser.dataAccess.status);
    const domainsFetchState = useTypedSelector(getDomainsFetchState);

    const fetchStatus = consolidateFetchState([userDataAccessFetchState, domainsFetchState]);
    const favouriteRows = () => rows.filter((row) => row.isFavourite);

    const [familyConsents, setFamilyConsents] = useState<{[key: string]: CONSENT_TYPE}>({});
    const [familyConsentId, setFamilyConsentId] = useState<{[key: string]: number}>({});
    const [minorConsentDates, setMinorConsentDates] = useState<{[key: string]: Date}>({});
    const [familyReasons, setFamilyReasons] = useState<{[key: string]: IAccessReasons}>({});

    const handleConsentsChange = (
        member: IFamilyMember,
        consentType: CONSENT_TYPE,
        consentId: number | null = null,
        minorConsentDate: Date | null = null,
    ) => {
        const newConsents = {
            ...familyConsents,
            [member.UIN]: consentType,
        };
        setFamilyConsents(newConsents);
        if (consentId) {
            const newConsentIds = {
                ...familyConsentId,
                [member.UIN]: consentId,
            };
            setFamilyConsentId(newConsentIds);
        }
        if (minorConsentDate) {
            const newMinorConsentDates = {
                ...minorConsentDates,
                [member.UIN]: minorConsentDate,
            };
            setMinorConsentDates(newMinorConsentDates);
        }
    };

    const handleReasonsChange = (member: IFamilyMember, accessReason: IAccessReasons) => {
        const newReasons = {
            ...familyReasons,
            [member.UIN]: accessReason,
        };
        setFamilyReasons(newReasons);
    };

    const handleApplyAllReasons = (accessReason: IAccessReasons) => {
        const newReasons = _.mapValues(familyReasons, () => accessReason);
        setFamilyReasons(newReasons);
        openGrowl(COPIES.APPLY_ALL_REASONS_MESSAGE);
    };

    useEffect(() => {
        dispatch(setDomains());
        dispatch(setClientUserDataAccess());
        dispatch(setClientUserSubDomainFavourites());
    }, []);

    useEffect(() => {
        const newMasterRows = masterRows.map((row) => ({
            ...row,
            isFavourite: some(favourites, {SubDomainId: row.subDomainId}) && !row.isDisabled,
        }));

        const newRows = rows.map((row) => ({
            ...row,
            isFavourite: some(favourites, {SubDomainId: row.subDomainId}) && !row.isDisabled,
        }));
        setMasterRows(newMasterRows);
        setRows(newRows);
    }, [favourites]);

    useEffect(() => {
        if (domains.length > 0) {
            const results = flatMap(
                domains.map((domain) => {
                    return domain.SubDomains.map((subdomain) => ({
                        agencyName: subdomain.SourceAgency,
                        domainName: domain.Name,
                        subDomainName: subdomain.Name,
                        subDomainCode: subdomain.Code,
                        subDomainId: subdomain.Id,
                        isDisabled: isAppUser(accessToken) && !some(dataAccess, {SubDomainId: subdomain.Id}),
                        isSelected: false,
                        isFavourite: some(favourites, {SubDomainId: subdomain.Id}),
                        isStigmatising: subdomain.IsStigmatising,
                        isLegislativelyLocked: subdomain.IsLegislativelyLocked,
                        sourceSystemType: subdomain.SourceSystemType,
                    }));
                }),
            );

            // Set initial table rendering data
            setMasterRows(results);
            setRows(results);

            setAgencyOptions(uniq(results.map((row) => row.agencyName)));
            setCategoryOptions(uniq(results.map((row) => row.domainName)));
        }
    }, [domains, dataAccess]);

    useEffect(() => {
        const priClient: IFamilyMember = {
            Name: basicInfo.Name,
            UIN: basicInfo.UIN,
            Relationship: Relationship.CLIENT,
            Consent: basicInfo.ClientConsent,
            IsDeceased: basicInfo.IsDeceased,
        };
        setSelectedFamilyMembersIncludingClient([priClient, ...selectedFamilyMembers]);
    }, [selectedFamilyMembers]);

    useEffect(() => {
        const selectedRows = masterRows.filter((row) => row.isSelected);

        if (selectedRows.length === 0 && consentDialog) {
            setConsentDialog(false);
        }
        if (selectedRows.length > 0 && consentDialog) {
            const hasLegislativelyLockedFields = some(selectedRows, {isLegislativelyLocked: true});
            const hasStigmatisingFields = some(selectedRows, {isStigmatising: true});

            if (!(hasLegislativelyLockedFields || hasStigmatisingFields)) {
                handleInitiateScreening();
            }
        }
    }, [masterRows]);

    const initFamilyConsentAndReasons = () => {
        const initialFamilyConsent = selectedFamilyMembersIncludingClient.reduce((acc, member) => {
            const consentType = member.Consent ? member.Consent.ConsentType : CONSENT_TYPE.NOT_SELECTED;
            return {...acc, [member.UIN]: consentType};
        }, {});
        const initialFamilyReasons = selectedFamilyMembersIncludingClient.reduce((acc, member) => {
            return {...acc, [member.UIN]: {Codes: [], OtherReason: ''}};
        }, {});
        setFamilyConsents(initialFamilyConsent);
        setFamilyReasons(initialFamilyReasons);
    };

    useEffect(() => {
        initFamilyConsentAndReasons();
    }, [selectedFamilyMembersIncludingClient]);

    const toggleSelectionByKey = (subDomainId: number, key: IToggleKeys) => {
        const newMasterRowsData = toggleSelection(masterRows, subDomainId, key);
        setMasterRows(newMasterRowsData);
        const newRowsData = toggleSelection(rows, subDomainId, key);
        setRows(newRowsData);
    };

    const handleCheckboxClick = (subDomainId: number) => toggleSelectionByKey(subDomainId, 'isSelected');

    // TODO: Abstract growl implementation to a context provider
    const handleFavouriteClick = async (subDomainId: number, isFavourite: boolean) => {
        const rowDetails = masterRows.find((row) => row.subDomainId === subDomainId);
        if (isFavourite) {
            const favourite = favourites.find((fav) => fav.SubDomainId === subDomainId);

            if (favourite) {
                const thunkActionResult = await dispatch(deleteClientUserSubDomainFavourite(favourite.Id));
                if ('error' in thunkActionResult) {
                    openGrowl(thunkActionResult.payload as string);
                } else {
                    openGrowl(`Removed ${rowDetails?.subDomainName} from Favourites`);
                }
            }
        } else {
            const thunkActionResult = await dispatch(
                setNewClientUserSubDomainFavourites({Id: 0, SubDomainId: subDomainId}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
            } else {
                openGrowl(`Added ${rowDetails?.subDomainName} to Favourites`);
            }
        }
    };

    const handleClearAllSelected = () => {
        const newMasterRowsData = masterRows.map((row) => ({
            ...row,
            isSelected: false,
        }));

        const newRowsData = rows.map((row) => ({
            ...row,
            isSelected: false,
        }));

        setMasterRows(newMasterRowsData);
        setRows(newRowsData);
    };

    const handleAllCheckBoxClick = () => {
        const isFavourited = tabValue === 1;
        let tempRows = isFavourited ? rows.filter((row) => row.isFavourite) : rows;
        const selected = tempRows.filter((row) => row.isSelected);

        tempRows = tempRows.map((row) => ({
            ...row,
            isSelected: !row.isDisabled && selected.length <= 0,
        }));

        const newInitialRowsData = masterRows.map((masterRow) => {
            const matchedRow = tempRows.find((row) => row.subDomainId === masterRow.subDomainId);

            return matchedRow
                ? {
                      ...masterRow,
                      isSelected: matchedRow.isSelected,
                  }
                : masterRow;
        });

        const newRowsData = rows.map((row) => {
            const matchedRow = tempRows.find((tempRow) => tempRow.subDomainId === row.subDomainId);

            return matchedRow ? matchedRow : row;
        });

        setMasterRows(newInitialRowsData);
        setRows(newRowsData);
    };

    const shouldShowConsentReasonDialog = (hasLegislativelyLockedFields: boolean, hasStigmatisingFields: boolean) => {
        const membersRequireConsent = selectedFamilyMembersIncludingClient.filter((member) => !member.Consent);
        const requiresFamilyConsent = membersRequireConsent.length > 0;
        return ((hasLegislativelyLockedFields && requiresFamilyConsent) || hasStigmatisingFields) && !consentDialog;
    };

    const handleInitiateScreening = (subDomainIdExcluded = 0) => {
        setOpenDialog('none');
        const selectedRows = masterRows.filter((row) => row.isSelected && row.subDomainId !== subDomainIdExcluded);
        const hasLegislativelyLockedFields = some(selectedRows, {isLegislativelyLocked: true});
        const hasStigmatisingFields = some(selectedRows, {isStigmatising: true});
        const nonLegislativelyLockedFields = selectedRows.filter((row) => !row.isLegislativelyLocked);

        if (shouldShowConsentReasonDialog(hasLegislativelyLockedFields, hasStigmatisingFields)) {
            setConsentDialog(true);
        } else {
            setFooterDisplay(false); // Hide site footer on loading screen

            const screeningRequest: IFamilyScreeningRequest = {
                ScreeningRequests: selectedFamilyMembersIncludingClient.map((member) => {
                    const memberConsent = familyConsents[member.UIN];
                    const selectedSubDomainCodes =
                        hasLegislativelyLockedFields && memberConsent === CONSENT_TYPE.SKIP_CONSENT
                            ? nonLegislativelyLockedFields.map((row) => row.subDomainCode)
                            : selectedRows.map((row) => row.subDomainCode);

                    return {
                        SubDomains: selectedSubDomainCodes,
                        ClientName: member.Name,
                        ClientUIN: member.UIN,
                        Relationship: member.Relationship,
                        ConsentType: hasLegislativelyLockedFields
                            ? familyConsents[member.UIN]
                            : CONSENT_TYPE.SKIP_CONSENT,
                        ConsentFormId: hasLegislativelyLockedFields ? familyConsentId[member.UIN] : undefined,
                        MinorConsentDate: hasLegislativelyLockedFields ? minorConsentDates[member.UIN] : undefined,
                        AccessReasons: hasStigmatisingFields ? familyReasons[member.UIN] : {Codes: [], OtherReason: ''},
                        IsDeceased: member.IsDeceased,
                    };
                }),
            };
            setIsLoading(true);

            initiateScreening(screeningRequest)
                .then(({RequestId}) => {
                    if (!RequestId) {
                        throw Error();
                    }
                    unsetFooterDisplay();
                    history.replace(`/client/${RequestId}`);
                })
                .catch(() => {
                    unsetFooterDisplay();
                    setIsLoading(false);
                    openGrowl(COPIES.CLIENT_INFO_ERROR);
                });
        }
    };
    const handleConsentDialogClose = () => {
        initFamilyConsentAndReasons();
        setConsentDialog(false);
    };

    const handleTabChange = (_e: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        Emitter.emit(EVENT_TYPE.RESET_TABLE_PAGE, 0);
        const lowerCaseSearchValue = e.target.value.toLowerCase();

        const newData = masterRows.filter(
            (row) =>
                row.agencyName.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                row.domainName.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                row.subDomainName.toLowerCase().indexOf(lowerCaseSearchValue) > -1,
        );
        setRows(newData);
    };

    const handleAgencyFilter = (options: string[]) => {
        Emitter.emit(EVENT_TYPE.RESET_TABLE_PAGE, 0);
        setAgencyFilters(options);

        let newData = masterRows;
        if (options.length !== 0 && categoryFilters.length === 0) {
            newData = newData.filter((row) => options.includes(row.agencyName));
        }
        if (options.length === 0 && categoryFilters.length !== 0) {
            newData = newData.filter((row) => categoryFilters.includes(row.domainName));
        }
        if (options.length !== 0 && categoryFilters.length !== 0) {
            newData = newData.filter(
                (row) => categoryFilters.includes(row.domainName) && options.includes(row.agencyName),
            );
        }
        setRows(newData);
    };

    const handleCategoryFilter = (options: string[]) => {
        Emitter.emit(EVENT_TYPE.RESET_TABLE_PAGE, 0);
        setCategoryFilters(options);

        let newData = masterRows;
        if (agencyFilters.length !== 0 && options.length === 0) {
            newData = newData.filter((row) => agencyFilters.includes(row.agencyName));
        }
        if (agencyFilters.length === 0 && options.length !== 0) {
            newData = newData.filter((row) => options.includes(row.domainName));
        }
        if (agencyFilters.length !== 0 && options.length !== 0) {
            newData = newData.filter(
                (row) => options.includes(row.domainName) && agencyFilters.includes(row.agencyName),
            );
        }
        setRows(newData);
    };

    const clearCategoryOptions = () => {
        handleCategoryFilter([]);
    };

    const handleCategorySelectAll = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleCategoryFilter(categoryOptions);
        } else {
            clearCategoryOptions();
        }
    };

    const clearAgencyOptions = () => {
        handleAgencyFilter([]);
    };

    const handleAgencySelectAll = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleAgencyFilter(agencyOptions);
        } else {
            clearAgencyOptions();
        }
    };

    const getValidationError = () => {
        const selectedRows = masterRows.filter((row) => row.isSelected);
        if (selectedRows.length === 0) {
            return 'No Sub-Domain selected';
        }
        if (selectedFamilyMembersIncludingClient.length - 1 > familyMembersPerScreeningLimit) {
            return `Exceeded max number of added persons per screening: ${
                selectedFamilyMembersIncludingClient.length - 1
            }/${familyMembersPerScreeningLimit}`;
        }
        return '';
    };

    const handleOpenConfirmationDiaglogs = () => {
        const validationErr = getValidationError();
        if (validationErr) {
            openGrowl(validationErr);
        } else if (!selectedAllSubdomainTrigger) {
            setOpenDialog('subDomainsConfirmation');
        } else if (shouldConfirmFamily) {
            setOpenDialog('familyMembersConfirmation');
        } else {
            handleInitiateScreening();
        }
    };

    const handleDoNotShowThisMessageAgain = () => {
        dispatch(updateUserFlags(USER_FLAGS.SELECT_SUBDOMAIN_REMINDER));
    };

    const handleConfirmSubDomains = () => {
        if (shouldConfirmFamily) {
            setOpenDialog('familyMembersConfirmation');
        } else {
            handleInitiateScreening();
        }
    };

    if (isLoading) {
        return <ProgressBar label={COPIES.FETCHING_INFO} variant="client" />;
    }

    return (
        <>
            {fetchStatus === API_FETCH_STATE.SUCCESS ? (
                <>
                    <Box styleName="mainContainer">
                        <Button
                            component={Link}
                            to="/search/basic-information"
                            style={{
                                paddingLeft: 0,
                                marginBottom: '16px',
                                color: '#3949AB',
                            }}
                        >
                            <ArrowBackIcon styleName="backArrow" />
                            Back to basic information
                        </Button>
                        <Typography variant="h4">Request More Information</Typography>

                        <SelectFamilyMembers
                            openSearch={openDialog === 'addPerson'}
                            onOpenSearch={() => setOpenDialog('addPerson')}
                            onCloseSearch={() => setOpenDialog('none')}
                            clientInfo={basicInfo}
                            selectedFamilyMembers={selectedFamilyMembers}
                            onChangeSelected={setSelectedFamilyMembers}
                        />

                        <SelectedChips
                            deleteHandler={handleCheckboxClick}
                            subDomains={masterRows}
                            toggleAll={handleClearAllSelected}
                        />
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="simple tabs example"
                            variant="fullWidth"
                            styleName="tabsWrapper"
                        >
                            <Tab label="All sub-domains" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                            <Tab label="Favourited sub-domains" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        </Tabs>

                        <div styleName={'inputWrapper'}>
                            <TextField
                                size="small"
                                label="Search Agency, Domain or Sub-Domain"
                                type="search"
                                variant="outlined"
                                value={searchValue}
                                onChange={handleChange}
                                styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                id="subdomain-search"
                            />
                            <div styleName={'dropdownsWrapper'}>
                                <p styleName={'filterPara'}>Filter</p>
                                <MultiSelectAutocomplete
                                    options={agencyOptions}
                                    selectedValues={agencyFilters}
                                    label="Agency"
                                    tagRenderer={(value) => (
                                        <Typography variant="subtitle1" styleName="filterTag">
                                            {value.length} Agencies Selected
                                        </Typography>
                                    )}
                                    onToggleOption={handleAgencyFilter}
                                    onClearOptions={clearAgencyOptions}
                                    onSelectAll={handleAgencySelectAll}
                                    classes={agencyClasses}
                                />
                                <MultiSelectAutocomplete
                                    options={categoryOptions}
                                    selectedValues={categoryFilters}
                                    label="Domain"
                                    tagRenderer={(value) => (
                                        <Typography variant="subtitle1" styleName="filterTag">
                                            {value.length} Domains Selected
                                        </Typography>
                                    )}
                                    onToggleOption={handleCategoryFilter}
                                    onClearOptions={clearCategoryOptions}
                                    onSelectAll={handleCategorySelectAll}
                                    classes={categoryClasses}
                                />
                            </div>
                        </div>

                        <TabPanel value={tabValue} index={0}>
                            <Grid container styleName="gridWrapper">
                                <ScreenClientTable
                                    allCheckBoxClickHandler={handleAllCheckBoxClick}
                                    checkboxClickHandler={handleCheckboxClick}
                                    favouriteClickHandler={handleFavouriteClick}
                                    rows={rows}
                                />
                            </Grid>
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <Grid container styleName="gridWrapper">
                                {favouriteRows().length > 0 ? (
                                    <ScreenClientTable
                                        allCheckBoxClickHandler={handleAllCheckBoxClick}
                                        checkboxClickHandler={handleCheckboxClick}
                                        favouriteClickHandler={handleFavouriteClick}
                                        rows={favouriteRows()}
                                    />
                                ) : (
                                    <EmptyFavourite addFavouriteButtonHandler={setTabValue} />
                                )}
                            </Grid>
                        </TabPanel>
                    </Box>
                    <ConsentAndAccess
                        open={consentDialog}
                        onClose={handleConsentDialogClose}
                        selectedFamilyMembers={selectedFamilyMembersIncludingClient}
                        basicInfo={basicInfo}
                        selectedSubDomains={masterRows.filter(
                            (row) => (row.isStigmatising || row.isLegislativelyLocked) && row.isSelected,
                        )}
                        onDeleteSubDomain={handleCheckboxClick}
                        onProceed={handleInitiateScreening}
                        familyConsents={familyConsents}
                        familyReasons={familyReasons}
                        onConsentsChange={handleConsentsChange}
                        onReasonsChange={handleReasonsChange}
                        onApplyAllReasons={handleApplyAllReasons}
                    />
                    <ClientInfoFooter
                        clientName={basicInfo.Name}
                        clientUIN={basicInfo.UIN}
                        clientConsent={basicInfo.ClientConsent}
                        isButtonDisabled={false}
                        isButtonHidden={false}
                        isFooterHidden={false}
                        legislativelyLockedFields={masterRows.filter(
                            (row) => row.isLegislativelyLocked && row.isSelected,
                        )}
                        buttonText="VIEW INFORMATION"
                        onButtonClick={handleOpenConfirmationDiaglogs}
                        isButtonLoading={false}
                        screenName="select-domains"
                    />
                </>
            ) : (
                <ProgressBar />
            )}
            <DoNotShowAgainDialog
                open={openDialog === 'subDomainsConfirmation'}
                onSubmit={handleConfirmSubDomains}
                onClose={() => setOpenDialog('none')}
                handleDoNotShowThisMessageAgain={handleDoNotShowThisMessageAgain}
                dialogTitle={COPIES.ADD_SUBDOMAIN_TITLE}
                dialogContent={COPIES.ADD_SUBDOMAINS_CONFIRMATION}
                buttonText={COPIES.ADDED_ALL_TEXT}
            />
            <FamilyMembersConfirmationDialog
                open={openDialog === 'familyMembersConfirmation'}
                onSubmit={handleInitiateScreening}
                onClose={() => setOpenDialog('none')}
                onBack={() => setOpenDialog('addPerson')}
                selectedFamilyMembers={selectedFamilyMembers}
            />
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={12000}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                    bottom: '120px',
                }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};
