import React, {useEffect, useState} from 'react';
import {TimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TextField} from '@mui/material';
import {isValid, parse} from 'date-fns';

export interface TimeInputProps {
    value: Date;
    onChange: (time: Date) => void;
    className?: string;
    minTime?: Date;
    maxTime?: Date;
    setHasError?: (error: boolean) => void;
}

export const TimeInput = ({value, onChange, className, minTime, maxTime, setHasError}: TimeInputProps) => {
    const [errMsg, setErrMsg] = useState('');
    const [showErr, setShowErr] = useState(false);

    useEffect(() => {
        if (value && maxTime && value > maxTime) {
            setShowErr(true);
            setErrMsg('Start time must be no later than end time');
            setHasError && setHasError(true);
        } else {
            setShowErr(false);
            setErrMsg('');
            setHasError && setHasError(false);
        }
    }, [value, maxTime]);

    const handleTimeChange = (time: string, value: Date) => {
        if (time === '') {
            setShowErr(false);
            setErrMsg('');
            setHasError && setHasError(true);
            return;
        }
        if (isValid(parse(time, 'hh:mm a', new Date()))) {
            const newDateTime = parse(
                time,
                'hh:mm a',
                new Date(value.getFullYear(), value.getMonth(), value.getDate()),
            );
            onChange(newDateTime);
            setShowErr(false);
            setErrMsg('');
            setHasError && setHasError(false);
        } else {
            setShowErr(true);
            setErrMsg('Please enter a valid time');
            setHasError && setHasError(true);
        }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                data-testid="timePicker"
                disableOpenPicker
                value={value}
                onChange={() => undefined}
                maxTime={maxTime}
                minTime={minTime}
                renderInput={(params) => (
                    <TextField
                        FormHelperTextProps={{style: {margin: '0', paddingTop: '8px', color: '#D60025'}}}
                        className={className}
                        data-testid="startTextField"
                        variant="outlined"
                        onBlur={(e) => {
                            handleTimeChange(e.target.value, value);
                        }}
                        {...{
                            ...params,
                            inputProps: {...params.inputProps, value: params.inputProps?.value.toUpperCase()},
                        }}
                        helperText={errMsg}
                        error={showErr}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
