import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import * as agencyServices from '~/services/agencyServices';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import {Flag} from '../hydrateFlag/hydrateFlag.slice';
import {IAgency, IUser, ISysAdminAgency} from '~/interfaces/admin';
import {ISubDomain} from '~/interfaces/common';

export interface IDeleteAgencyAdmins {
    agencyId: number;
    agency: IAgency;
}

export interface ICreateAgencyAdmin {
    agencyId: number;
    agencyResponse: IAgency;
}

export interface IUpdateAgencyAdmin {
    agencyId: number;
    adminDetails: IUser;
}

export interface IActivateAgencyAdmin {
    agencyId: number;
    admin: IUser;
}

export interface IUpdateAgencyDataAccess {
    agencyId: number;
    agencySubDomains: ISubDomain[];
    DataDomainUpdatedAt: Date;
    DataDomainUpdatedByUser: IUser;
}

export const setSysAdminData = createAsyncThunk('/agency/setSysAdminData', async (_, {dispatch, rejectWithValue}) => {
    dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
    try {
        const sysAdminData = await agencyServices.getSysAdminData();

        const sysAdminDataObject: {[key: string]: ISysAdminAgency} = {};
        for (const agency of sysAdminData) sysAdminDataObject[agency.Id] = agency;

        dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
        return sysAdminDataObject;
    } catch (err) {
        dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
        return rejectWithValue('error getting Sys Admin Data');
    }
});
export const setAgencies = createAsyncThunk<{}, void, {state: RootState; rejectValue: string}>(
    '/agency/setAgencies',
    async (_, {dispatch, rejectWithValue}) => {
        dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
        try {
            const agencies = await agencyServices.getAgencies();
            const agenciesObject = agencies.reduce((obj, agency) => {
                return {
                    ...obj,
                    [agency.Id]: agency,
                };
            }, {});
            dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
            return agenciesObject;
        } catch (err) {
            dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            return rejectWithValue('error setting agencies');
        }
    },
);

export const setAgency = createAsyncThunk<IAgency | void, number, {state: RootState}>(
    '/agency/setAgency',
    async (agencyId, {dispatch, getState}) => {
        if (!getState().data.flags[Flag.AGENCY]) {
            dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const agency = await agencyServices.getAgency(agencyId);
                dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
                return agency;
            } catch (err) {
                dispatch({type: fetchStateActions.SET_AGENCIES_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
                throw err;
            }
        }
    },
);

export const createAgency = createAsyncThunk<IAgency, IAgency, {state: RootState}>(
    '/agency/createAgency',
    async (agency, {rejectWithValue}) => {
        try {
            const agencyResponse = await agencyServices.createAgency(agency);
            if (agencyResponse.Error) {
                return rejectWithValue(agencyResponse.Error);
            }
            return agencyResponse.Agency;
        } catch (error) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const updateAgencyDetails = createAsyncThunk<IAgency, IAgency, {state: RootState}>(
    '/agency/updateAgencyDetails',
    async (agency, {rejectWithValue}) => {
        try {
            const agencyResponse = await agencyServices.updateAgencyDetails(agency);
            if (agencyResponse.Error) {
                return rejectWithValue(agencyResponse.Error);
            }
            return agencyResponse.Agency;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const createAgencyAdmin = createAsyncThunk<
    ICreateAgencyAdmin,
    {agencyId: number; adminDetails: IUser[]},
    {state: RootState}
>('/agency/createAgencyAdmin', async ({agencyId, adminDetails}, {rejectWithValue}) => {
    try {
        const createAgencyAdminResponse = await agencyServices.createAgencyAdmin(agencyId, adminDetails);
        if (createAgencyAdminResponse.Error && createAgencyAdminResponse.Error !== '') {
            return rejectWithValue(createAgencyAdminResponse.Error);
        }
        return {agencyId: agencyId, agencyResponse: createAgencyAdminResponse.Agency};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const updateAgencyAdmin = createAsyncThunk<
    IUpdateAgencyAdmin,
    {agencyId: number; adminDetails: IUser},
    {state: RootState}
>('/agency/updateAgencyAdmin', async ({agencyId, adminDetails}, {rejectWithValue}) => {
    try {
        await agencyServices.updateAgencyAdmin(agencyId, adminDetails);
        return {agencyId: agencyId, adminDetails};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const deleteAgencyAdmin = createAsyncThunk<
    IDeleteAgencyAdmins,
    {agencyId: number; userId: number; deletedReason: string},
    {state: RootState}
>('/agency/deleteAgencyAdmin', async ({agencyId, userId, deletedReason}, {rejectWithValue}) => {
    try {
        const agencyResponse = await agencyServices.deleteAgencyAdmin(agencyId, userId, deletedReason);
        if (agencyResponse.Error) {
            return rejectWithValue(agencyResponse.Error);
        }
        return {agencyId, agency: agencyResponse.Agency};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const activateAgencyAdmin = createAsyncThunk<
    IActivateAgencyAdmin,
    {agencyId: number; admin: IUser},
    {rejectValue: string; state: RootState}
>('/agency/activateAgencyAdmin', async ({agencyId, admin}, {rejectWithValue}) => {
    if (admin.Id) {
        try {
            await agencyServices.activateAgencyAdmin(agencyId, admin.Id);
            return {agencyId, admin};
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    }
    return rejectWithValue('admin Id not found');
});

//TO UNCOMMENT WHEN DOMAINS SLICE IS DONE

// export const updateAgencyDataAccess = createAsyncThunk<
//     IUpdateAgencyDataAccess,
//     {agencyId: number; subDomains: number[]},
//     {state: RootState}
// >('agency/updateAgencyDataAccess', async ({agencyId, subDomains}, {getState}) => {
//     const agency = await agencyServices.updateAgencyDataAccess(agencyId, subDomains);
//     const domains = getState().data.common.domains as IDomain[];
//     const allSubDomains: ISubDomain[] = [];
//     domains.forEach((domain) => domain.SubDomains.forEach((subDomain) => allSubDomains.push(subDomain)));
//     const agencySubDomains = allSubDomains.filter((subDomain) => subDomains.includes(subDomain.Id));

//     return {
//         agencyId,
//         agencySubDomains,
//         DataDomainUpdatedAt: agency.DataDomainUpdatedAt as Date,
//         DataDomainUpdatedByUser: agency.DataDomainUpdatedByUser as IUser,
//     };
// });
