import React, {useEffect, useState} from 'react';
import {some} from 'lodash';
import {Redirect, useParams} from 'react-router-dom';
import {IDomain} from '~/interfaces/common';
import {IClientInfo, IScreeningReportRequest} from '~/interfaces/client';
import {API_FETCH_STATE, COPIES, NAVBAR_PADDING, Relationship, THRESHOLD_RATIO} from '~/constants';
import {Paper, Snackbar, Tab, Tabs, Typography} from '@mui/material';
import {ClientInfoView} from './ClientInfoView';
import {getScreenedClientReport} from '~/services/clientServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {ProgressBar} from '~/components/Common/ProgressBar/ProgressBar';
import {ClientReportDialog} from '~/components/Client/ReportDialogs/ClientReportDialog';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
// import {screeningResultsComparator} from '~/utils/arrayUtils';
import {TabPanel} from '~/components/Common/TabPanel';
import {formatLongName} from '~/utils/nameUtils';
import './ClientInfo.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setDomains} from '~/store/domain/domains.thunk';
import {setScreenedClientInfo} from '~/store/client/client.thunk';
import {fetchStateActions} from '~/store/fetchState/fetchState.slice';
import {getScreenedClientInfoFetchState} from '~/store/fetchState/fetchState.selector';

const scrollTo = (y: number, smooth: boolean) => window.scrollTo({top: y, behavior: smooth ? 'smooth' : 'auto'});

export const ClientInfo = () => {
    const {screeningRequestId}: {screeningRequestId?: string} = useParams();
    const [screenedDomains, setScreenedDomains] = useState<IDomain[]>([]);
    const [filteredDomains, setFilteredDomains] = useState<IDomain[]>([]);
    const [selectedDomainIndex, setSelectedDomainIndex] = useState<number>(0);
    const [visibleSubDomains, setVisibleSubDomains] = useState<number[]>([]);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
    const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [resetModal, setResetModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);

    const clientInfo = useTypedSelector((state) => state.data.client.screenedClients[String(screeningRequestId)]);
    // clientInfo && clientInfo.ScreeningResults.sort(screeningResultsComparator);
    const clientInfoFetchState = useTypedSelector(getScreenedClientInfoFetchState);
    const domains = useTypedSelector((state) => state.data.common.domains);
    const dispatch = useAppDispatch();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const subDomainRefs = [] as HTMLDivElement[];
    const selectedSubDomainIndex = Math.min(...visibleSubDomains);

    const listOfScreening =
        clientInfo &&
        clientInfo.ScreeningResults.filter(
            (screening) => screening.GeneralInfo.Relationship !== Relationship.CLIENT,
        ).map((resultClientInfo) => {
            return {
                name: resultClientInfo.GeneralInfo.ClientName,
                screeningId: resultClientInfo.ScreeningRequestId,
            };
        });

    useEffect(() => {
        dispatch(setDomains());
        dispatch(setScreenedClientInfo(String(screeningRequestId)));

        return function cleanup() {
            dispatch(fetchStateActions.SET_SCREENED_CLIENT_INFO_FETCH_STATE(API_FETCH_STATE.PENDING));
        };
    }, []);

    useEffect(() => {
        if (domains.length > 0 && clientInfo) {
            const screenedSubDomains = Object.keys(clientInfo.ScreeningResults[activeTab].SubDomainInfo);
            const newScreenedDomains = domains
                .filter((domain) => some(domain.SubDomains, (subDomain) => screenedSubDomains.includes(subDomain.Code)))
                .map((domain) => ({
                    ...domain,
                    SubDomains: domain.SubDomains.filter((subDomain) => screenedSubDomains.includes(subDomain.Code)),
                }));
            setScreenedDomains(newScreenedDomains);
            setFilteredDomains(newScreenedDomains);
        }
    }, [domains, clientInfo, activeTab]);

    useEffect(() => {
        const subDomainHandler = (entries: IntersectionObserverEntry[]) => {
            const add: number[] = [];
            const remove: number[] = [];
            entries.forEach((entry) => {
                const id = parseInt(entry.target.getAttribute('id') as string, 10);
                if (isNaN(id)) {
                    return;
                }

                if (entry.isIntersecting && entry.intersectionRatio > THRESHOLD_RATIO) {
                    add.push(id);
                } else {
                    remove.push(id);
                }
            });
            setVisibleSubDomains((visible) => visible.filter((id) => !remove.includes(id)).concat(add));
        };
        const subDomainObserver = new IntersectionObserver(subDomainHandler, {
            threshold: THRESHOLD_RATIO,
            rootMargin: `-${NAVBAR_PADDING}px 0px 0px 0px`,
        });
        subDomainRefs.forEach((ref) => subDomainObserver.observe(ref));

        return () => {
            subDomainObserver.disconnect();
            setVisibleSubDomains([]);
        };
    }, [selectedDomainIndex, filteredDomains]);

    const handleSelectDomain = (index: number) => {
        if (index === selectedDomainIndex) {
            scrollTo(0, true);
        } else {
            scrollTo(0, false);
            setSelectedDomainIndex(index);
        }
    };

    const handleSelectSubDomain = (index: number) => {
        scrollTo(subDomainRefs[index].getBoundingClientRect().top + window.scrollY - NAVBAR_PADDING, true);
    };

    const handleFilterChange = (searchVal: string) => {
        const lowerCaseSearchValue = searchVal.toLowerCase();

        const filterSubDomains = screenedDomains.map((domain) => {
            const newDomain = Object.assign({}, domain);
            const subDomains = newDomain.SubDomains.filter(
                (subDomain) => subDomain.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1,
            );
            newDomain.SubDomains = subDomains;
            return newDomain;
        });

        const filterDomains = filterSubDomains.filter((domain) => domain.SubDomains.length > 0);
        setFilteredDomains(filterDomains);
        setSelectedDomainIndex(0);
    };

    const redirectWhenError = () => {
        return clientInfoFetchState === API_FETCH_STATE.ERROR && <Redirect to="/" />;
    };

    const downloadReport = async (
        familyScreeningRequestId: string,
        selectedScreeningIds: string[],
        reportPassword: string,
        selectedSubDomains: string[],
        singleReport: boolean,
    ) => {
        setIsLoadingReport(true);
        const reportRequest: IScreeningReportRequest = {
            FamilyScreeningRequestId: familyScreeningRequestId,
            ScreeningRequestIds: selectedScreeningIds,
            Password: reportPassword,
            SelectedSubDomains: selectedSubDomains,
            SingleReport: singleReport,
        };

        try {
            screeningRequestId && (await getScreenedClientReport(reportRequest));
        } catch (e) {
            openGrowl(`Error downloading report: Please try again later`);
        }
        setIsLoadingReport(false);
    };

    const handleGenerateReport = () => {
        setReportModalOpen(true);
    };

    const handleGenerateReportClose = () => {
        setReportModalOpen(false);
        setConfirmDialogOpen(false);
        setResetModal(true);
    };

    const openCofirmDialog = () => {
        setReportModalOpen(false);
        setConfirmDialogOpen(true);
    };

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setReportModalOpen(true);
    };

    const handleSubmitReportRequest = (
        listOfReports: string[],
        reportPassword: string,
        selectedSubDomains: string[],
        singleReport: boolean,
    ) => {
        //Filter screening from original list to retain order from FSR
        const familyScreeningIds = clientInfo.ScreeningResults.filter(
            (screening) =>
                listOfReports.includes(screening.ScreeningRequestId) ||
                screening.GeneralInfo.Relationship === Relationship.CLIENT,
        ).map((resultClientInfo) => resultClientInfo.ScreeningRequestId);
        downloadReport(
            screeningRequestId as string,
            familyScreeningIds,
            reportPassword,
            selectedSubDomains,
            singleReport,
        );
        handleGenerateReportClose();
    };

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const renderTabLabel = (clientInfo: IClientInfo) => (
        <div styleName="tabLabel">
            <div>
                <Typography styleName="nameOverflow">{formatLongName(clientInfo.GeneralInfo.ClientName)}</Typography>
                {clientInfo.GeneralInfo.Relationship !== Relationship.UNKNOWN && (
                    <Typography variant="caption" styleName="relationship">
                        {clientInfo.GeneralInfo.Relationship}
                    </Typography>
                )}
            </div>
        </div>
    );

    return (
        <div styleName="clientInfo">
            {redirectWhenError()}
            {clientInfoFetchState === API_FETCH_STATE.SUCCESS ? (
                <>
                    <Paper styleName="tabsBar">
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            styleName="tabsWrapper"
                            aria-label="family screening result tabs"
                            variant="scrollable"
                        >
                            {clientInfo?.ScreeningResults?.map((resultClientInfo, index) => {
                                const tabId = `screening-result-tab-${resultClientInfo.GeneralInfo.ClientUIN}`;
                                return (
                                    <Tab
                                        label={renderTabLabel(resultClientInfo)}
                                        aria-controls={tabId}
                                        key={tabId}
                                        styleName={activeTab === index ? 'active' : ''}
                                    />
                                );
                            })}
                        </Tabs>
                    </Paper>

                    {clientInfo?.ScreeningResults?.map((resultClientInfo, index) => (
                        <TabPanel value={activeTab} index={index} key={index}>
                            <ClientInfoView
                                subDomainRefs={subDomainRefs}
                                screenedDomains={filteredDomains}
                                selectedDomainIndex={selectedDomainIndex}
                                selectedSubDomainIndex={selectedSubDomainIndex}
                                clientInfo={resultClientInfo}
                                isLoadingReport={isLoadingReport}
                                handleSelectDomain={handleSelectDomain}
                                handleSelectSubDomain={handleSelectSubDomain}
                                handleFilterChange={handleFilterChange}
                                handleGenerateReport={handleGenerateReport}
                            />
                            <Snackbar
                                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                                key={growl?.key}
                                open={growl.open}
                                onClose={closeGrowl}
                                autoHideDuration={growl.autoHideDuration}
                                message={growl.message.length > 0 ? growl.message : undefined}
                                ContentProps={{
                                    style: {
                                        textAlign: 'left',
                                        width: '100%',
                                    },
                                }}
                                style={{
                                    transform: 'inherit',
                                    left: '326px', //Width of sidebar
                                    paddingLeft: '32px',
                                    right: '10%',
                                }}
                            />
                            <ClientReportDialog
                                open={reportModalOpen}
                                onSubmit={handleSubmitReportRequest}
                                onClose={openCofirmDialog}
                                domains={screenedDomains}
                                reset={resetModal}
                                setResetModal={setResetModal}
                                listOfFamilyScreening={listOfScreening}
                            />
                            <ConfirmDialog
                                open={confirmDialogOpen}
                                onCancel={closeConfirmDialog}
                                onConfirm={handleGenerateReportClose}
                                cancelOption="Back"
                                confirmOption="Confirm"
                                title="Cancel Report Generation"
                                message="Changes will not be saved."
                            />
                        </TabPanel>
                    ))}
                </>
            ) : (
                <ProgressBar label={COPIES.FETCHING_INFO} variant="client" />
            )}
        </div>
    );
};
