import React, {useState} from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Typography,
    Select,
    MenuItem,
    Grid,
    Popper,
    PopperProps,
    SelectChangeEvent,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {COPIES, ReportType} from '~/constants';
import {IDomain} from '~/interfaces/common';
import {DomainCheckBoxList} from './DomainCheckBoxList';
import './SelectSubDomainsDialogForm.scss';
import {MultiSelectAutocomplete} from '../MultiSelectAutocomplete/MultiSelectAutocomplete';
import {get} from 'lodash';

export const useScreeningSelectionStyles = makeStyles({
    autocomplete: {
        width: '200px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

export interface IFamilyScreeningOptions {
    name: string;
    screeningId: string;
}

export interface ISelectSubDomainsDialogFormProps {
    domains: IDomain[];
    selectedSubDomains: string[];
    onSelectSubDomains: (selected: string[]) => void;
    onSubmit: () => void;
    listOfFamilyScreening: IFamilyScreeningOptions[];
    selectedFamilyScreenings: string[];
    onSelectFamilyScreenings: (selected: string[]) => void;
    reportType: string;
    onReportTypeChange: (reportType: string) => void;
}

export const SelectSubDomainsDialogForm = ({
    domains,
    selectedSubDomains,
    onSelectSubDomains,
    onSubmit,
    listOfFamilyScreening,
    selectedFamilyScreenings,
    onSelectFamilyScreenings,
    reportType,
    onReportTypeChange,
}: ISelectSubDomainsDialogFormProps) => {
    const screeningSelectionClasses = useScreeningSelectionStyles();
    const [error, setError] = useState('');
    const listOfScreeningID = listOfFamilyScreening.map((screening) => screening.screeningId);

    const handleSelectScreenings = (selectedFamilyScreenings: string[]) => {
        onSelectFamilyScreenings(selectedFamilyScreenings);
    };

    const handleClearScreenings = () => {
        onSelectFamilyScreenings([]);
    };

    const handleSelectAllScreenings = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectScreenings(listOfScreeningID);
        } else {
            handleClearScreenings();
        }
    };

    const handleReportTypeChange = (event: SelectChangeEvent<{name?: string; value: unknown}>) => {
        const reportType = get(event, 'target.value', null);
        onReportTypeChange(reportType);
    };

    const submit = () => {
        if (selectedSubDomains.length < 1) {
            setError(COPIES.REPORT_CUSTOMISATION_ERROR);
        } else {
            setError('');
            onSubmit();
        }
    };

    const popper = (props: PopperProps) => {
        return <Popper {...props} style={{minWidth: '200px', maxWidth: '300px'}} placement="bottom-start" />;
    };

    const customiseDisplay = (option: string) => {
        const displayName = listOfFamilyScreening.find((screening) => screening.screeningId === option);
        return displayName ? displayName.name : option;
    };

    return (
        <div styleName="selectSubDomainsDialogForm">
            <DialogContent>
                <Divider styleName="dividerTop" />
                <DialogContentText styleName="dialogTitle">
                    {listOfFamilyScreening.length !== 0
                        ? COPIES.REPORT_FAMILY_CUSTOMISATION_DIALOG_TITLE
                        : COPIES.REPORT_CUSTOMISATION_DIALOG_TITLE}
                </DialogContentText>
                <DialogContentText styleName="contentText">{COPIES.REPORT_CUSTOMISATION_DIALOG_BODY}</DialogContentText>
                {listOfFamilyScreening.length !== 0 && (
                    <Grid container spacing={1} styleName="gridContainer">
                        <Grid item>
                            <label styleName="contentText">Generate</label>
                        </Grid>
                        <Grid item>
                            <Select value={reportType as ''} onChange={handleReportTypeChange} styleName="selectTag">
                                <MenuItem value={''}>Select report type</MenuItem>
                                <MenuItem value={ReportType.SINGLE_PDF}>{ReportType.SINGLE_PDF}</MenuItem>
                                <MenuItem value={ReportType.SEPARATE_PDF}>{ReportType.SEPARATE_PDF}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <label styleName="contentText">for client and</label>
                        </Grid>
                        <Grid item>
                            <MultiSelectAutocomplete
                                options={listOfScreeningID}
                                selectedValues={selectedFamilyScreenings}
                                label="0 Family Member"
                                tagRenderer={(value) => (
                                    <Typography variant="subtitle1" styleName="screeningTag">
                                        {value.length} Family Member(s)
                                    </Typography>
                                )}
                                onToggleOption={handleSelectScreenings}
                                onClearOptions={handleClearScreenings}
                                onSelectAll={handleSelectAllScreenings}
                                classes={screeningSelectionClasses}
                                sort={false}
                                popper={popper}
                                customizeOptionDisplay={customiseDisplay}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogContent styleName="subDomains">
                <DomainCheckBoxList
                    domains={domains}
                    selectedSubDomains={selectedSubDomains}
                    onSelectSubDomains={onSelectSubDomains}
                />
                <Typography styleName="errorMsg">{error}</Typography>
            </DialogContent>

            <Divider styleName="dividerBtm" />
            <DialogActions styleName="dialogActions">
                <Button styleName="dialogButton" variant="contained" color="primary" onClick={submit}>
                    {COPIES.REPORT_CUSTOMISATION_DIALOG_BUTTON}
                </Button>
            </DialogActions>
        </div>
    );
};
