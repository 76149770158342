import React from 'react';
import {useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import {
    FirstPageRounded as FirstPageIcon,
    KeyboardArrowLeftRounded as KeyboardArrowLeft,
    KeyboardArrowRightRounded as KeyboardArrowRight,
    LastPageRounded as LastPageIcon,
} from '@mui/icons-material';

interface ITablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export const TablePaginationActions = (props: ITablePaginationActionsProps) => {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{flexShrink: 0, marginLeft: theme.spacing(2.5)}}>
            <IconButton aria-label="first page" disabled={page === 0} onClick={handleFirstPageButtonClick}>
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                aria-label="next page"
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleNextButtonClick}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                aria-label="last page"
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleLastPageButtonClick}
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
};
