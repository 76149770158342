import {createSlice, current} from '@reduxjs/toolkit';
import {IAgency, IUser} from '~/interfaces/admin';
import {
    setSysAdminData,
    setAgencies,
    setAgency,
    createAgency,
    updateAgencyDetails,
    createAgencyAdmin,
    updateAgencyAdmin,
    deleteAgencyAdmin,
    activateAgencyAdmin,
    // updateAgencyDataAccess,
} from './agency.thunk';

import {setOpsUnits, createOpsUnit} from '../opsUnit/opsUnit.thunk';

export interface IAgencyState {
    agencies: {[Id: number]: IAgency};
    loading: boolean;
}

export const initialAgencyState: IAgencyState = {
    agencies: {},
    loading: false,
};

export const agencySlice = createSlice({
    name: 'agency',
    initialState: initialAgencyState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setSysAdminData.fulfilled, (state, action) => {
                const agencyMap: {[agencyId: number]: IAgency} = {};
                if (action.payload) {
                    Object.values(action.payload).forEach((agency) => {
                        agencyMap[agency.Id] = {
                            ...agency,
                            SysAdminOpsUnits: agency.OpsUnits,
                            OpsUnits: agency.OpsUnits && agency.OpsUnits.map((opsunit) => opsunit.Id),
                        } as IAgency;
                    });
                }
                state.agencies = agencyMap;
            })
            .addCase(setAgencies.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies = action.payload;
                }
            })
            .addCase(setOpsUnits.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies[action.payload.agencyId] = {
                        ...state.agencies[action.payload.agencyId],
                        OpsUnits: Object.keys(action.payload.opsUnits).map(Number),
                    };
                }
            })
            .addCase(createAgency.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies[action.payload.Id] = action.payload;
                }
            })
            .addCase(updateAgencyDetails.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies[action.payload.Id] = {...action.payload};
                }
            })
            .addCase(createAgencyAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies[action.payload.agencyId].Admins = action.payload.agencyResponse.Admins;
                }
            })
            .addCase(updateAgencyAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentAdmins = current(state.agencies[action.payload.agencyId].Admins);
                    const editedAdmins = currentAdmins?.map((admin) => {
                        if (admin.Id === action.payload.adminDetails.Id) {
                            return action.payload.adminDetails;
                        }
                        return admin;
                    }) as IUser[];
                    state.agencies[action.payload.agencyId].Admins = editedAdmins;
                }
            })
            .addCase(deleteAgencyAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    state.agencies[action.payload.agencyId].Admins = action.payload.agency.Admins;
                }
            })
            .addCase(activateAgencyAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentAdmins = current(state.agencies[action.payload.agencyId].Admins);
                    const editedAdmins = currentAdmins?.map((admin) => {
                        if (admin.Id === action.payload.admin.Id) {
                            return {...action.payload.admin, IsActive: true};
                        }
                        return admin;
                    }) as IUser[];
                    state.agencies[action.payload.agencyId].Admins = editedAdmins;
                }
            })
            .addCase(setAgency.fulfilled, (state, action) => {
                if (action.payload) {
                    if (state.agencies[action.payload.Id]) {
                        state.agencies[action.payload.Id] = {
                            ...action.payload,
                            OpsUnits: [...(state.agencies[action.payload.Id].OpsUnits as number[])],
                        };
                    } else {
                        state.agencies[action.payload.Id] = {...action.payload};
                    }
                }
            })
            .addCase(createOpsUnit.fulfilled, (state, action) => {
                state.agencies[action.payload.AgencyId].OpsUnits?.push(action.payload.Id);
            });

        //TO UNCOMMENT WHEN DOMAINS SLICE IS CREATED
        // .addCase(updateAgencyDataAccess.fulfilled, (state, action) => {
        //     state.agenciesInfo[action.payload.agencyId] = {
        //         ...state.agenciesInfo[action.payload.agencyId],
        //         SubDomains: action.payload.agencySubDomains,
        //         DataDomainUpdatedAt: action.payload.DataDomainUpdatedAt,
        //         DataDomainUpdatedByUser: action.payload.DataDomainUpdatedByUser,
        //     };
        // });
    },
});

export const agencyActions = agencySlice.actions;
export default agencySlice.reducer;
