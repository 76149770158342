import React, {useContext} from 'react';
import {Checkbox, TableCell, TableRow} from '@mui/material';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {TABLE_TYPE, WithEnhancedTable} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {formatDate} from '~/utils/dateUtils';
import './ResourceTable.scss';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {DeleteRounded as DeleteIcon, EditRounded as EditIcon} from '@mui/icons-material';
import {ACCESS_LEVELS} from '~/constants';
import {config} from '~/config';

export interface IRowData {
    isSelected: boolean;
    id: number;
    name: string;
    description: string;
    lastUpdated: Date;
    fileSize: number;
    type: string;
}

export type IHeadRowData = Pick<IRowData, 'name' | 'description' | 'lastUpdated' | 'fileSize'>;

export interface IResourcesTableProps {
    allCheckBoxClickHandler: () => void;
    checkboxClickHandler: (fileId: number) => void;
    rows: IRowData[];
    deleteFileHandler: (fileId: number) => void;
    editFileHandler: (fileId: number) => void;
}

interface IResourcesTableRowsProps {
    checkboxClickHandler: (fileId: number) => void;
    deleteFileHandler: (fileId: number) => void;
    editFileHandler: (fileId: number) => void;
    sortedRows: IRowData[];
}

const ResourcesTableRows = ({
    sortedRows,
    checkboxClickHandler,
    deleteFileHandler,
    editFileHandler,
}: IResourcesTableRowsProps) => {
    const {accessToken} = useContext(AuthenticationContext);

    const systemAdminColumns = (fileId: number) => () =>
        !config.internetEnabled &&
        accessToken &&
        accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ? (
            <TableCell styleName="resourceActionCell">
                <EditIcon onClick={() => editFileHandler(fileId)} styleName="resourceActionIcon" />
                <DeleteIcon onClick={() => deleteFileHandler(fileId)} styleName="resourceActionIcon" />
            </TableCell>
        ) : null;

    return (
        <>
            {sortedRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <TableRow
                        role="checkbox"
                        aria-checked={row.isSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={row.isSelected}
                        styleName="tableRow"
                    >
                        <TableCell
                            padding="none"
                            style={{
                                padding: 16,
                            }}
                            styleName="w-5"
                        >
                            <Checkbox
                                checked={row.isSelected}
                                inputProps={{'aria-labelledby': labelId}}
                                color="primary"
                                onClick={() => checkboxClickHandler(row.id)}
                            />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" align="left" styleName="w-25">
                            {row.name}
                        </TableCell>
                        <TableCell align="left" styleName="w-30">
                            {row.description}
                        </TableCell>
                        <TableCell align="left" styleName="w-20">
                            {formatDate(new Date(row.lastUpdated), false, true)}
                        </TableCell>
                        <TableCell align="left" styleName="w-20">
                            {row.fileSize.toFixed(2)} MB
                        </TableCell>
                        {systemAdminColumns(row.id)()}
                    </TableRow>
                );
            })}
        </>
    );
};

export const ResourceTable = ({
    rows,
    checkboxClickHandler,
    allCheckBoxClickHandler,
    deleteFileHandler,
    editFileHandler,
}: IResourcesTableProps) => {
    const {accessToken} = useContext(AuthenticationContext);

    const selectedCount = (rowsData: IRowData[]) => {
        const selected = rowsData.filter((row) => row.isSelected);
        return selected.length;
    };

    const systemAdminHeaders: IHeaderColumn<keyof IHeadRowData>[] = [
        {align: 'inherit', disablePadding: true, label: 'Actions'},
    ];

    let headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {id: 'name', align: 'inherit', disablePadding: false, label: 'Resource Name'},
        {id: 'description', align: 'inherit', disablePadding: false, label: 'Description'},
        {id: 'lastUpdated', align: 'inherit', disablePadding: false, label: 'Last Updated'},
        {id: 'fileSize', align: 'inherit', disablePadding: false, label: 'File Size'},
    ];

    if (
        !config.internetEnabled &&
        accessToken &&
        accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR
    ) {
        headColumns = [...headColumns, ...systemAdminHeaders];
    }

    const headerProps = {
        showCheckbox: true,
        onSelectAllClick: allCheckBoxClickHandler,
        numSelected: selectedCount(rows),
        columns: headColumns,
    };

    const hocProps = {
        rows,
        colSpan: 5,
        headerProps,
    };

    const passThroughProps = {
        checkboxClickHandler,
        deleteFileHandler,
        editFileHandler,
    };

    const props = {
        ...hocProps,
        ...passThroughProps,
    };

    const EnhancedTable = WithEnhancedTable<IResourcesTableRowsProps, IHeadRowData, IRowData>(
        ResourcesTableRows,
        'name',
        rows,
        'asc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return <EnhancedTable {...props} />;
};
