import React from 'react';
import {Link} from 'react-router-dom';
import {Button, ClickAwayListener, IconButton} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';

import './FeatureModalPopper.scss';

export interface IFeatureModalPopperProps {
    onClose: () => void;
}

export const FeatureModalPopper = React.forwardRef<HTMLDivElement, IFeatureModalPopperProps>(({onClose}, ref) => {
    return (
        <div ref={ref}>
            <ClickAwayListener onClickAway={onClose}>
                <div styleName="featureModalPopper">
                    <div styleName="topRow">
                        <span styleName="newBadge">NEW</span>
                        <span styleName="featureTitle">Family Screening Feature</span>
                        <IconButton aria-label="close" onClick={onClose} styleName="closeButton">
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div styleName="featureContent">
                        You can now select and add family members to the screening you’re performing.
                    </div>

                    <div styleName="seeFeatureButton">
                        <Link to="/guides-and-toolkits" target="_blank" rel="noopener noreferrer">
                            <Button color="primary" variant="contained">
                                see how it works
                            </Button>
                        </Link>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
});
