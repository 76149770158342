import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {ISummaryFileMeta, ISummaryFileRequest} from '~/interfaces/common';
import fileDownload from 'js-file-download';
import {CancelToken} from 'axios';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {getAbbreviationFromBrackets} from '~/utils/nameUtils';

interface CustomError extends Error {
    response: {
        data: {
            text: () => string | undefined;
        };
    };
}

export const uploadDataFile = async (
    file: File,
    name: string,
    dataContributor: string,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    cancelToken: CancelToken,
): Promise<ISummaryFileMeta> => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    const agencyAbbr = getAbbreviationFromBrackets(dataContributor);
    const response = await httpRequest({
        url: `${config.api.DATA_UPLOAD_DECRYPT_SERVICE}/uploadData/${agencyAbbr}`,
        method: 'POST',
        data: formData,
        onUploadProgress: (pg) => setProgress((pg.loaded / pg.total) * 100),
        cancelToken,

        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getUploadHistory = async (): Promise<ISummaryFileMeta[]> => {
    const response = await httpRequest({
        url: `${config.api.DATA_UPLOAD_SERVICE}/upload-histories`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const downloadSummaryFile = async (fileRequest: ISummaryFileRequest) => {
    try {
        const response = await httpRequest({
            url: `${config.api.DATA_UPLOAD_SERVICE}/download`,
            method: 'POST',
            data: JSON.stringify(fileRequest),
            headers: {
                'Content-Type': 'application/zip',
                Accept: 'application/zip',
            },
            responseType: 'blob',
        });

        if (!isResponseOk(response.status)) {
            throw new Error();
        }

        const fileData = response.data;
        const fileName = fileRequest.filename + '.zip';
        fileDownload(fileData, fileName);
    } catch (err) {
        const error = err as CustomError;
        if (error.response) {
            const errorMsg = await error.response.data.text();
            throw new Error(errorMsg);
        } else {
            throw new Error(error.message);
        }
    }
};

export const rollbackUpload = async (UploadRequestId: string) => {
    try {
        const response = await httpRequest({
            url: `${config.api.DATA_UPLOAD_SERVICE}/rollback-upload/${UploadRequestId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/zip',
                Accept: 'application/zip',
            },
        });

        if (!isResponseOk(response.status)) {
            throw new Error();
        }
    } catch (err) {
        const error = err as CustomError;
        if (error.response) {
            const errorMsg = await error.response.data.text();
            throw new Error(errorMsg);
        } else {
            throw new Error(error.message);
        }
    }
};
