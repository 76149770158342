import React, {useState, useEffect} from 'react';
import {Grid, Typography} from '@mui/material';
import {IDataAccessMatrixDomain} from '~/interfaces/admin';
import {ExpandAllButton} from '~/components/Common/Button/ExpandAllButton';
import {SubDomain} from './SubDomain';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getAccessRejectedReasonsByDomainState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';

import './RejectedAccessHistory.scss';

export interface IDomainProps {
    domain: IDataAccessMatrixDomain;
    onExpandAll: boolean;
    opsUnitId: number;
}

export interface IDomainHeaderProps {
    domain: IDataAccessMatrixDomain;
    expandAll: boolean;
    setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DomainHeader = ({domain, expandAll, setExpandAll}: IDomainHeaderProps) => {
    const handleButtonClick = () => {
        setExpandAll(!expandAll);
    };

    return (
        <>
            <Grid item xs={8}>
                <Typography variant="h5" styleName="domainName" id="domainName">
                    {domain.Name}
                </Typography>
            </Grid>
            <Grid item xs={4} id="dataDictionaryExpandAll">
                <ExpandAllButton onClick={handleButtonClick} expandAll={expandAll} />
            </Grid>
        </>
    );
};

const SubDomainMemo = React.memo(SubDomain);
export const Domain = ({domain, onExpandAll, opsUnitId}: IDomainProps) => {
    const [expandAll, setExpandAll] = useState(true);
    const accessRejectedReasonsStateByDomain = useTypedSelector(
        getAccessRejectedReasonsByDomainState(opsUnitId, domain.Id),
    );

    useEffect(() => {
        setExpandAll(onExpandAll);
    }, [onExpandAll]);

    if (accessRejectedReasonsStateByDomain) {
        return (
            <>
                <Grid container style={{marginBottom: '20px'}}>
                    <DomainHeader expandAll={expandAll} setExpandAll={setExpandAll} domain={domain} />
                </Grid>
                {domain.SubDomains.map((subDomain) => {
                    return (
                        <SubDomainMemo
                            subDomain={subDomain}
                            expandAll={expandAll}
                            key={subDomain.Id}
                            opsUnitId={opsUnitId}
                            domainId={domain.Id}
                        />
                    );
                })}
            </>
        );
    }
    return <></>;
};
