import {Collapse, Divider, Drawer, List, ListItem, Typography, TextField, InputAdornment, Box} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {IDomain} from '~/interfaces/common';
import {sortByPropertyAlphabetically} from '~/utils/arrayUtils';
import {KeyboardArrowDownRounded as KeyboardArrowDownIcon, SearchRounded as SearchIcon} from '@mui/icons-material';
import {
    DemographicsIcon,
    FinancialAssistanceIcon,
    SocialSupportIcon,
    HousingIcon,
    EducationIcon,
    IncomeIcon,
    MedicalHealthIcon,
    EmploymentIcon,
    OthersIcon,
} from '~/components/Common/Icons';
import './ClientInfoSidebar.scss';
import {useHistory} from 'react-router';

export interface IClientInfoSidebarProps {
    domains: IDomain[];
    onFilterChange: (searchVal: string) => void;
    onSelectDomain: (index: number) => void;
    onSelectSubDomain: (index: number) => void;
    selectedDomainIndex: number;
    selectedSubDomainIndex: number;
    footerExists?: boolean;
}

const selectedStyleIf = (style: string, condition: boolean) => (condition ? `${style} selected` : style);

const getWrapperStyle = (style: string, condition: boolean) => `${style} ${condition ? 'with-footer' : 'no-footer'}`;

const domainIcons: {[key: string]: JSX.Element} = {
    DEMOGRAPHICS: <DemographicsIcon />,
    'FINANCIAL ASSISTANCE': <FinancialAssistanceIcon />,
    'SOCIAL SUPPORT': <SocialSupportIcon />,
    HOUSING: <HousingIcon />,
    EDUCATION: <EducationIcon />,
    'SOURCE OF INCOME/SAVINGS': <IncomeIcon />,
    'MEDICAL/HEALTH': <MedicalHealthIcon />,
    EMPLOYMENT: <EmploymentIcon />,
    OTHERS: <OthersIcon />,
};

export const ClientInfoSidebar = ({
    domains,
    onFilterChange,
    onSelectDomain,
    onSelectSubDomain,
    selectedDomainIndex,
    selectedSubDomainIndex,
    footerExists = false,
}: IClientInfoSidebarProps) => {
    const history = useHistory();
    const [searchValue, setSearchValue] = useState('');

    const getSearchValueFromURL = () => {
        return history.location.hash ? decodeURIComponent(history.location.hash.split('#')[1]) : '';
    };

    useEffect(() => {
        const valueFromURL = getSearchValueFromURL();
        setSearchValue(valueFromURL);
        onFilterChange(valueFromURL);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        onFilterChange(e.target.value);
    };

    return (
        <Drawer variant="permanent" id="sidebar">
            <div styleName={getWrapperStyle('drawer-wrapper', footerExists)}>
                <Typography variant="subtitle2" styleName="drawerHeader">
                    Requested Information
                </Typography>
                <TextField
                    label="Search Sub-Domain"
                    type="search"
                    variant="outlined"
                    size="small"
                    value={searchValue}
                    onChange={handleChange}
                    styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <List styleName="sidebarList">
                    {sortByPropertyAlphabetically(domains, 'Name').map((domain, domainIndex) => (
                        <React.Fragment key={domain.Name}>
                            <ListItem
                                disableRipple
                                disableGutters
                                button
                                styleName={selectedStyleIf('listItem', domainIndex === selectedDomainIndex)}
                                onClick={() => onSelectDomain(domainIndex)}
                                data-testid={domain.Name}
                            >
                                <Box display="flex" alignItems="center">
                                    {domainIcons[domain.Name.toUpperCase()]}
                                    <Typography styleName="domainName">{domain.Name}</Typography>
                                </Box>
                                {domainIndex === selectedDomainIndex ? null : <KeyboardArrowDownIcon />}
                            </ListItem>

                            <Collapse in={domainIndex === selectedDomainIndex}>
                                <List>
                                    {sortByPropertyAlphabetically(domain.SubDomains, 'Name').map(
                                        (subDomain, subDomainIndex) => (
                                            <React.Fragment key={subDomain.Name}>
                                                <ListItem
                                                    disableRipple
                                                    disableGutters
                                                    button
                                                    styleName={selectedStyleIf(
                                                        'listItem',
                                                        domainIndex === selectedDomainIndex &&
                                                            subDomainIndex === selectedSubDomainIndex,
                                                    )}
                                                    onClick={() => onSelectSubDomain(subDomainIndex)}
                                                    data-testid={subDomain.Name}
                                                >
                                                    <Typography styleName="subDomainName">{subDomain.Name}</Typography>
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ),
                                    )}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </div>
        </Drawer>
    );
};
