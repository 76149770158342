import React from 'react';
import {Chip} from '@mui/material';
import {Warning, CheckCircle} from '@mui/icons-material';
import style from './RequestStatus.scss';

const DAM2ReqStatusNoChange = 'No changes';
const DAM2ReqStatusDraft = 'Saved as draft';
const DAM2ReqStatusSubmitted = 'Submitted';
const DAM2ReqStatusReview = 'Sent for review';
const DAM2ReqStatusCompleted = 'Completed';

interface IRequestStatus {
    label?: string;
}

export const RequestStatus = (params: IRequestStatus): JSX.Element => {
    const {label} = params;
    if (label === DAM2ReqStatusNoChange) {
        return <Chip className={`${style.noChange} ${style.chip}`} label="No changes" />;
    }
    if (label === DAM2ReqStatusDraft) {
        return <Chip className={`${style.draft} ${style.chip}`} label="Saved as draft" icon={<Warning />} />;
    }
    if (label === DAM2ReqStatusSubmitted) {
        return <Chip className={`${style.submitted} ${style.chip}`} label="Submitted" />;
    }
    if (label === DAM2ReqStatusReview) {
        return <Chip className={`${style.review} ${style.chip}`} label="Sent for review" />;
    }
    if (label === DAM2ReqStatusCompleted) {
        return <Chip className={`${style.completed} ${style.chip}`} label="Completed" icon={<CheckCircle />} />;
    }
    return <></>;
};
