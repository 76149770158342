import React, {SetStateAction} from 'react';
import {Grid} from '@mui/material';
import {Consent} from './Consent';
import {IFamilyMember} from '~/interfaces/client';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {CONSENT_TYPE, COPIES, USER_FLAGS} from '~/constants';
import {ConsentAndAccessHeader} from '../ConsentAndAccessHeader';
import './FamilyConsents.scss';

export interface IFamilyConsentsProps {
    selectedFamilyMembers: IFamilyMember[];
    legislativelyLockedFields: IRowData[];
    onDeleteSubDomain: (subDomain: IRowData) => void;
    onConsentTypeChange: (
        member: IFamilyMember,
        consentType: CONSENT_TYPE,
        consentId?: number,
        minorConsentDate?: Date,
    ) => void;
    setHasMinorDateError: React.Dispatch<SetStateAction<boolean>>;
}

export const FamilyConsents = ({
    selectedFamilyMembers,
    legislativelyLockedFields,
    onDeleteSubDomain,
    onConsentTypeChange,
    setHasMinorDateError,
}: IFamilyConsentsProps) => {
    const handleConsentTypeChange = (
        familyMember: IFamilyMember,
        consent: CONSENT_TYPE,
        consentId?: number,
        minorConsentDate?: Date,
    ) => {
        onConsentTypeChange(familyMember, consent, consentId, minorConsentDate);
    };

    return (
        <Grid styleName="familyConsents">
            <ConsentAndAccessHeader
                flagName={USER_FLAGS.CLIENT_CONSENT}
                title={COPIES.CONSENT_TITLE}
                subTitle={COPIES.CONSENT_SUBTITLE}
                onDeleteSubDomain={onDeleteSubDomain}
                selectedSubDomains={legislativelyLockedFields}
            />

            {selectedFamilyMembers.map((member, index) => (
                <Consent
                    familyMember={member}
                    key={index}
                    items={legislativelyLockedFields}
                    onConsentTypeChange={handleConsentTypeChange}
                    setHasMinorDateError={setHasMinorDateError}
                />
            ))}
        </Grid>
    );
};
