import {CheckCircle, Warning} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, Grid, IconButton, Snackbar, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {uniq} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useStore} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import LeavePageModal from '~/components/Admin/DataAccessMatrix2/LeavePageModal';

import SubdomainDataAccessManagement from '~/components/Admin/DataAccessMatrix2/SubdomainDataAccessManagement';
import {Error} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {AdminProgressBar} from '~/components/Admin/WithAdminLoader/AdminProgressBar';
import {InfoIcon} from '~/components/Common/Icons';
import {Banner, SERVERITY_TYPE} from '~/components/Common/Banner/Banner';
import {ConfirmationModal} from '~/components/ConfirmationModal/ConfirmationModal';
import {SubmissionModal} from '~/components/DataRequestSubmissionModal/SubmissionModal';
import {API_FETCH_STATE} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    ICurrentRequestWindow,
    IDataAccessMatrixSubDomain,
    IDomainDataRequest,
    IDomainReqStatus,
    ISubdomainDataRequest,
    ISubdomainLatestStatus,
    IRequestWindow,
} from '~/interfaces/admin';
import {getSubdomainsWithNewFields} from '~/services/adminServices';
import {
    getOpsUnitDataAccessMatrixMap,
    getOpsUnitDataRequestStateByDomain,
    getOpsUnitDomainRequestStatus,
    getOpsUnitSubdomainLatestStatus,
    submitDomainDataRequest,
    withdrawDomainDataRequest,
    getOpsUnitDataRestrictionsByDomain,
    DataRestrictions,
} from '~/services/dataAccessMatrixService';
import {getDataFieldsByDomainId} from '~/services/dataDictionaryServices';
import {getCurrentRequestWindow, getLatestClosedWindow} from '~/services/requestWindowServices';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {useGrowl} from '~/utils/hooks/useGrowl';

export const DataAccessRequestDomainPage = () => {
    const {opsUnitId, domainId} = useParams<{opsUnitId: string; domainId: string}>();
    const [domainName, setDomainName] = useState<string>('');
    const [subdomains, setSubdomains] = useState<IDataAccessMatrixSubDomain[]>([{}] as IDataAccessMatrixSubDomain[]);
    const [subdomainsWithNewFields, setSubdomainsWithNewFields] = useState<number[]>([]);
    const [opsUnitDomainRequestStatus, setOpsUnitDomainRequestStatus] = useState<IDomainReqStatus>(
        {} as IDomainReqStatus,
    );
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const [opsUnitDataAccessFieldIdMap, setOpsUnitDataAccessFieldIdMap] = useState<{[key: number]: number}>({});
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [subdomainLatestUpdate, setSubdomainLatestUpdate] = useState<Map<number, ISubdomainLatestStatus>>(new Map());
    const [requestState, setRequestState] = useState<IDomainDataRequestState>({} as IDomainDataRequestState);
    const [activeWindow, setActiveWindow] = useState<boolean>(false);
    const [currentWindow, setCurrentWindow] = useState<ICurrentRequestWindow>({} as ICurrentRequestWindow);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalTextBody, setModalTextBody] = useState<string>('');
    const [modalConfirmBtnText, setModalConfirmBtnText] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [modalFunction, setModalFunction] = useState<() => void>(() => {});
    const [restrictions, setRestrictions] = useState<DataRestrictions>({} as DataRestrictions);
    const [latestClosedWindow, setLatestClosedWindow] = useState<IRequestWindow>({} as IRequestWindow);

    const dispatch = useAppDispatch();
    const {accessToken} = useContext(AuthenticationContext);
    const history = useHistory();

    const isOA: boolean =
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministrator' ||
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministratorappuser' ||
        false;

    const enableLeavePageModal: boolean = combinedFetchStatus === API_FETCH_STATE.SUCCESS && isOA;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentWindowPromise = getCurrentRequestWindow();
                const opsUnitDataAccessMatrixMapPromise = getOpsUnitDataAccessMatrixMap(parseInt(opsUnitId));
                const opsUnitDomainRequestStatusPromise = getOpsUnitDomainRequestStatus(opsUnitId);
                const opsUnitDataRequestStateByDomainPromise = getOpsUnitDataRequestStateByDomain(
                    parseInt(opsUnitId),
                    parseInt(domainId),
                );
                const dataFieldsByDomainIdPromise = getDataFieldsByDomainId(parseInt(domainId));
                const subdomainsWithNewFieldsPromise = getSubdomainsWithNewFields();
                const opsUnitSubdomainLatestStatusPromise = getOpsUnitSubdomainLatestStatus(opsUnitId);

                const dataRestrictionsPromise = getOpsUnitDataRestrictionsByDomain(
                    parseInt(opsUnitId),
                    parseInt(domainId),
                );
                const latestWindowPromise = getLatestClosedWindow();

                const [
                    currentWindow,
                    opsUnitDataAccessMatrixMap,
                    opsUnitDomainRequestStatus,
                    opsUnitDataRequestStateByDomain,
                    dataFieldsByDomainId,
                    subdomainsWithNewFields,
                    opsUnitSubdomainLatestStatus,
                    dataRestrictions,
                    latestWindow,
                ] = await Promise.all([
                    currentWindowPromise,
                    opsUnitDataAccessMatrixMapPromise,
                    opsUnitDomainRequestStatusPromise,
                    opsUnitDataRequestStateByDomainPromise,
                    dataFieldsByDomainIdPromise,
                    subdomainsWithNewFieldsPromise,
                    opsUnitSubdomainLatestStatusPromise,
                    dataRestrictionsPromise,
                    latestWindowPromise,
                ]);
                if (!ignore) {
                    if (currentWindow) {
                        setCurrentWindow(currentWindow);
                        currentWindow.CurrentWindowPeriod?.Status == 'open'
                            ? setActiveWindow(true)
                            : setActiveWindow(false);
                    }
                    if (opsUnitDataAccessMatrixMap) setOpsUnitDataAccessFieldIdMap(opsUnitDataAccessMatrixMap);
                    if (opsUnitDomainRequestStatus) {
                        // get the domain request status for the current domain
                        const filteredResp = opsUnitDomainRequestStatus.filter(
                            (domain) => domain.DomainId == parseInt(domainId),
                        );
                        if (filteredResp.length !== 0) {
                            setOpsUnitDomainRequestStatus(filteredResp[0]);
                        } else {
                            setOpsUnitDomainRequestStatus({} as IDomainReqStatus);
                        }
                    }
                    if (opsUnitDataRequestStateByDomain) {
                        dispatch(
                            dataAccessMatrix2Actions.SET_DOMAIN_DATA_REQUEST_STATE({
                                domainDataRequestState: opsUnitDataRequestStateByDomain,
                            }),
                        );
                        setRequestState(opsUnitDataRequestStateByDomain);
                    }
                    if (dataFieldsByDomainId) {
                        const {Name: name, SubDomains: subs} = dataFieldsByDomainId;
                        setSubdomains(
                            subs.filter((subdomain) => subdomain.DataFields.length > 0).sort((a, b) => a.Id - b.Id),
                        );
                        setDomainName(name);
                    }
                    if (subdomainsWithNewFields) setSubdomainsWithNewFields(subdomainsWithNewFields);
                    if (opsUnitSubdomainLatestStatus) {
                        const subdomainLatestUpdateMap = new Map(
                            opsUnitSubdomainLatestStatus.map((obj) => {
                                return [obj.SubdomainId, obj];
                            }),
                        );
                        setSubdomainLatestUpdate(subdomainLatestUpdateMap);
                    }
                    if (dataRestrictions) setRestrictions(dataRestrictions);
                    if (latestWindow) setLatestClosedWindow(latestWindow);
                    setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
                }
            } catch (error) {
                setCombinedFetchStatus(API_FETCH_STATE.ERROR);
            }
        };
        let ignore = false;
        fetchData();
        return () => {
            ignore = true;
        };
    }, []);

    const updateCallback = () => {
        refreshDomainDataRequestInitialState();
    };

    const getLastUpdatedData = (subdomainId: number) => {
        if (subdomainLatestUpdate.has(subdomainId)) {
            return subdomainLatestUpdate.get(subdomainId);
        }
        return {} as ISubdomainLatestStatus;
    };

    const refreshDomainDataRequestInitialState = async () => {
        setCombinedFetchStatus(API_FETCH_STATE.PENDING);
        try {
            const requestStatePromise = getOpsUnitDataRequestStateByDomain(parseInt(opsUnitId), parseInt(domainId));
            const subdomainLatestUpdatePromise = getOpsUnitSubdomainLatestStatus(opsUnitId);
            const opsUnitDomainRequestStatusPromise = getOpsUnitDomainRequestStatus(opsUnitId);
            const [requestStateResponse, subdomainLatestUpdateResponse, opsUnitDomainRequestStatusResponse] =
                await Promise.all([
                    requestStatePromise,
                    subdomainLatestUpdatePromise,
                    opsUnitDomainRequestStatusPromise,
                ]);
            dispatch(
                dataAccessMatrix2Actions.SET_DOMAIN_DATA_REQUEST_STATE({domainDataRequestState: requestStateResponse}),
            );
            setRequestState(requestStateResponse);
            const subdomainLatestUpdateMap = new Map(
                subdomainLatestUpdateResponse.map((obj) => {
                    return [obj.SubdomainId, obj];
                }),
            );
            setSubdomainLatestUpdate(subdomainLatestUpdateMap);
            const filteredResp = opsUnitDomainRequestStatusResponse.filter(
                (domain) => domain.DomainId == parseInt(domainId),
            );
            if (filteredResp.length !== 0) {
                setOpsUnitDomainRequestStatus(filteredResp[0]);
            } else {
                setOpsUnitDomainRequestStatus({} as IDomainReqStatus);
            }
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        } catch (error) {
            setCombinedFetchStatus(API_FETCH_STATE.ERROR);
        }
    };

    const handleClickDiscard = () => {
        setModalTitle('Discard Draft?');
        setModalConfirmBtnText('Discard');
        setModalTextBody('All changes made in this request window will be discarded.');
        setModalFunction(() => handleConfirmDiscard);
        setOpenConfirmationModal(true);
    };

    const handleCancelDiscard = () => {
        setOpenConfirmationModal(false);
    };

    const handleConfirmDiscard = () => {
        const formId = requestState.FormMeta.FormId;

        const reqBody: IDomainDataRequest = {
            WindowPeriodId: 0,
            FormId: formId,
            SubdomainDataRequest: [],
        };

        submitDomainDataRequest(parseInt(opsUnitId), parseInt(domainId), 'discard', reqBody)
            .then(() => {
                refreshDomainDataRequestInitialState();
                setOpenConfirmationModal(false);
                openGrowl('All changes discarded successfully');
            })
            .catch(() => {
                openGrowl('Error discarding draft.');
            });
        // post the request to the backend with OAFormAction as 'discard'
    };

    const renderBanner = () => {
        if (
            !opsUnitDomainRequestStatus.Status ||
            opsUnitDomainRequestStatus.Status.toLowerCase() === 'no changes' ||
            opsUnitDomainRequestStatus.Status.toLowerCase() === 'completed' ||
            !isOA
        ) {
            return <></>;
        }
        if (opsUnitDomainRequestStatus.Status.toLowerCase() === 'saved as draft') {
            const message = `This draft is pending submission. Please submit your request for data access by 
            ${
                currentWindow.CurrentWindowPeriod?.WindowEnd != undefined
                    ? formatDate(new Date(currentWindow.CurrentWindowPeriod?.WindowEnd), true, false, false)
                    : ''
            }`;
            return (
                <Banner
                    message={message}
                    severity={SERVERITY_TYPE.WARNING}
                    action={
                        <Button style={{padding: '4px 0 0 0'}} onClick={handleClickDiscard}>
                            discard draft
                        </Button>
                    }
                    icon={<Warning />}
                ></Banner>
            );
        }
        if (opsUnitDomainRequestStatus.Status.toLowerCase() === 'submitted') {
            const message =
                'Your request has been submitted successfully, and will be sent to the Data Contributing Agency once the window closes.';
            return <Banner message={message} severity={SERVERITY_TYPE.SUCCESS} icon={<CheckCircle />}></Banner>;
        }
        if (opsUnitDomainRequestStatus.Status.toLowerCase() === 'sent for review') {
            const message = 'Request has been sent to the Data Contributing Agency for review.';
            return <Banner message={message} severity={SERVERITY_TYPE.INFO} icon={<InfoIcon />}></Banner>;
        }
        return <></>;
    };

    const formMeta =
        requestState && requestState.FormMeta
            ? requestState.FormMeta
            : {FormId: 0, LastUpdatedBy: null, LastUpdated: null};
    const displayFormHeader = formMeta.FormId == 0 ? false : true;
    const lastUpdatedByUser = formMeta.LastUpdatedBy
        ? formMeta.LastUpdatedBy.Name
            ? formMeta.LastUpdatedBy.Name
            : '-'
        : '-';
    const lastUpdated = formMeta.LastUpdated
        ? formMeta.LastUpdated
            ? formatDate(new Date(formMeta.LastUpdated)) + ' at ' + formatTime(new Date(formMeta.LastUpdated))
            : '-'
        : '-';
    const action = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <>
            {combinedFetchStatus === API_FETCH_STATE.PENDING && <AdminProgressBar />}
            {combinedFetchStatus === API_FETCH_STATE.ERROR && <Error />}
            {combinedFetchStatus === API_FETCH_STATE.SUCCESS && (
                <>
                    <Grid item xs={12} container display={'flex'}>
                        <Grid xs={6} item>
                            <Button
                                variant="text"
                                sx={{pl: 0}}
                                onClick={() => history.push(`/admin/data-access-management/ops-unit/${opsUnitId}`)}
                            >
                                <KeyboardBackspaceIcon /> Back
                            </Button>
                        </Grid>
                        <Grid
                            xs={6}
                            item
                            marginLeft={'auto'}
                            justifyContent={'flex-end'}
                            display={activeWindow ? 'flex' : 'none'}
                        >
                            {isOA && (
                                <DraftAndSubmitButton
                                    opsUnitId={parseInt(opsUnitId)}
                                    domainId={parseInt(domainId)}
                                    subdomains={subdomains}
                                    windowPeriodId={currentWindow.CurrentWindowPeriod?.Id || 0}
                                    triggerUpdate={updateCallback}
                                    openGrowl={openGrowl}
                                />
                            )}
                            {isOA && (
                                <WithdrawButton
                                    windowPeriodId={currentWindow.CurrentWindowPeriod?.Id || 0}
                                    triggerUpdate={updateCallback}
                                    openGrowl={openGrowl}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} data-testid="data-access-domain-name" style={{marginTop: '15px'}}>
                        <Typography variant="h5" gutterBottom>
                            Data Access for {domainName}
                            {displayFormHeader && (
                                <Typography gutterBottom>
                                    {/* Form last updated on 20 January 2023 at 8.41 am by John Doe */}
                                    Form last updated on {lastUpdated} by {lastUpdatedByUser}
                                </Typography>
                            )}
                        </Typography>
                    </Grid>

                    {isOA && renderBanner()}

                    <ConfirmationModal
                        title={modalTitle}
                        textBody={modalTextBody}
                        confirmBtnText={modalConfirmBtnText}
                        cancelBtnTxt={'Cancel'}
                        open={openConfirmationModal}
                        onCancel={handleCancelDiscard}
                        onConfirm={modalFunction}
                    ></ConfirmationModal>

                    {enableLeavePageModal && <LeavePageModal />}

                    {subdomains
                        .map((sub) => {
                            return (
                                <SubdomainDataAccessManagement
                                    key={sub.Id}
                                    opsUnitId={parseInt(opsUnitId)}
                                    domainData={sub}
                                    subDomainRequestStateData={extractOpsUnitSubdomainRequestStateBySubdomainId(
                                        requestState,
                                        sub.Id,
                                    )}
                                    opsUnitDataFields={opsUnitDataAccessFieldIdMap}
                                    requestedFields={requestState.RequestedFields || []}
                                    lastUpdated={getLastUpdatedData(sub.Id)}
                                    newFields={evaluateNewFields(sub.Id, subdomainsWithNewFields)}
                                    activeWindow={activeWindow}
                                    domainId={parseInt(domainId)}
                                    restrictions={restrictions}
                                    latestClosedWindow={latestClosedWindow}
                                />
                            );
                        })
                        .sort()}
                </>
            )}
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                action={action}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                    bottom: 90,
                }}
            />
        </>
    );
};

// this function is used to extract the request state for a specific subdomain
function extractOpsUnitSubdomainRequestStateBySubdomainId(
    data: IDomainDataRequestState,
    subdomainId: number,
): OpsUnitSubdomainRequest {
    let matchingRequestState;
    if (data.OpsUnitSubdomainRequestState) {
        matchingRequestState = Array.from(data.OpsUnitSubdomainRequestState).find(
            (request) => request.SubdomainId === subdomainId,
        );
    }
    return matchingRequestState === undefined ? ({} as OpsUnitSubdomainRequest) : matchingRequestState;
}

function extractOpsUnitSubdomainRequestFieldIds(data: OpsUnitSubdomainRequest[]): {[subdomainId: number]: number[]} {
    const subdomainFieldMap: {[subdomainId: number]: number[]} = {};
    if (data) {
        data.forEach((request) => {
            const subdomainId: number = request.SubdomainId;
            const fieldIds: number[] = request.Fields.filter(
                (field) => field.State.toLowerCase() === 'approved' || field.State.toLowerCase() === 'sent for review',
            ).map((field) => field.FieldId);

            if (!subdomainFieldMap[subdomainId]) {
                subdomainFieldMap[subdomainId] = [];
            }

            subdomainFieldMap[subdomainId].push(...fieldIds);
        });
    }

    return subdomainFieldMap;
}

function extractOpsUnitSubdomainReasonForAccess(data: OpsUnitSubdomainRequest[]): {[subdomainId: number]: string} {
    const subdomainReasonMap: {[subdomainId: number]: string} = {};

    if (data) {
        data.forEach((request) => {
            const subdomainId: number = request.SubdomainId;
            const reason: string = request.ReasonForAccess.Accepted;

            subdomainReasonMap[subdomainId] = reason;
        });
    }

    return subdomainReasonMap;
}

function extractOpsUnitPendingSubdomainReasonForAccess(data: OpsUnitSubdomainRequest[]): {
    [subdomainId: number]: string;
} {
    const subdomainReasonMap: {[subdomainId: number]: string} = {};

    if (data) {
        data.forEach((request) => {
            const subdomainId: number = request.SubdomainId;
            const reason: string = request.ReasonForAccess.Pending;

            subdomainReasonMap[subdomainId] = reason;
        });
    }

    return subdomainReasonMap;
}

function calculateChangeCounts(newIds: number[], initialIds: number[]): {addedCount: number; removedCount: number} {
    let addedCount = 0;
    let removedCount = 0;
    if (initialIds) {
        addedCount = newIds.filter((id) => !initialIds.includes(id)).length;
        removedCount = initialIds.filter((id) => !newIds.includes(id)).length;
    } else {
        addedCount = newIds.length;
    }

    return {addedCount, removedCount};
}
// this function is used to evaluate if a subdomain has new fields
function evaluateNewFields(subdomainId: number, subdomainsWithNewFields: number[]) {
    return subdomainsWithNewFields.includes(subdomainId);
}

export const DraftAndSubmitButton = (props: {
    opsUnitId: number;
    domainId: number;
    subdomains: IDataAccessMatrixSubDomain[];
    windowPeriodId: number;
    triggerUpdate: () => void;
    openGrowl: (message: string) => void;
}) => {
    const store = useStore();
    const dispatch = useAppDispatch();
    const formState = store.getState().data.dam2.domainDataRequestState.FormMeta.State;
    const {opsUnitId, domainId, windowPeriodId, subdomains, triggerUpdate, openGrowl} = props;

    const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
    const [subdomainRequestSummary, setSubdomainRequestSummary] = useState<ISubdomainDataRequest[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [confirmationFunction, setConfirmationFunction] = useState<() => void>(() => {});
    const fieldsUpdated = useTypedSelector((state) => state.data.dam2.fieldsUpdated);
    const {accessToken} = useContext(AuthenticationContext);
    const isOA: boolean =
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministrator' ||
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministratorappuser' ||
        false;
    useEffect(() => {
        dispatch(dataAccessMatrix2Actions.SET_FIELDS_UPDATED({fieldsUpdated: false}));
        dispatch(dataAccessMatrix2Actions.SET_FIELDS_ADDED({fieldsAdded: false}));
    }, []);

    const handleDraft = () => {
        const {windowPeriodId, formId, combinedData} = prepareRequest();
        const errors = checkFormErrors(combinedData);
        if (errors.length) {
            openGrowl(`Error: ${errors.join(' ')}`);
            return;
        }
        const finalSubdomainDataObj = combinedData.filter(
            (data) =>
                !(
                    data.Fields.length === 0 &&
                    data.SubdomainRequestSummary.FieldsRemoved === 0 &&
                    data.SubdomainRequestSummary.FieldsRequested === 0
                ),
        );
        const reqBody: IDomainDataRequest = {
            WindowPeriodId: windowPeriodId || 0,
            FormId: formId || 0,
            SubdomainDataRequest: finalSubdomainDataObj,
        };
        setOpenSubmissionModal(false);
        submitDomainDataRequest(opsUnitId, domainId, 'draft', reqBody)
            .then(() => {
                openGrowl('Saved as draft successfully');
                triggerUpdate();
            })
            .catch(() => {
                openGrowl('Error saving as draft.');
            });
    };

    const handleSubmit = (request: {
        windowPeriodId: number;
        formId: number;
        combinedData: ISubdomainDataRequest[];
        noChangeInReasonWhenGotAddedFields: boolean;
    }) => {
        const {windowPeriodId, formId, combinedData, noChangeInReasonWhenGotAddedFields} = request;
        const finalSubdomainDataObj = combinedData.filter(
            (data) =>
                !(
                    data.Fields.length === 0 &&
                    data.SubdomainRequestSummary.FieldsRemoved === 0 &&
                    data.SubdomainRequestSummary.FieldsRequested === 0
                ),
        );
        setOpenSubmissionModal(false);
        if (noChangeInReasonWhenGotAddedFields && formState.toLowerCase() !== 'saved as draft') {
            openGrowl('Error submitting request, you must have a change in reason when adding fields.');
        } else {
            const reqBody: IDomainDataRequest = {
                WindowPeriodId: windowPeriodId || 0,
                FormId: formId,
                SubdomainDataRequest: finalSubdomainDataObj,
            };

            submitDomainDataRequest(opsUnitId, domainId, 'submit', reqBody)
                .then(() => {
                    openGrowl('Request submitted successfully');
                    setOpenSubmissionModal(false);
                    triggerUpdate();
                })
                .catch(() => {
                    openGrowl('Error submitting as request.');
                });
        }
    };

    function prepareRequest(): {
        windowPeriodId: number;
        formId: number;
        combinedData: ISubdomainDataRequest[];
        noChangeInReasonWhenGotAddedFields: boolean;
    } {
        const formId = store.getState().data.dam2.domainDataRequestState.FormMeta.FormId;
        const OpsUnitSubdomainRequestState: OpsUnitSubdomainRequest[] =
            store.getState().data.dam2.domainDataRequestState.OpsUnitSubdomainRequestState;
        const subdomainFieldMap: {[subdomainId: number]: number[]} =
            store.getState().data.dam2.subdomainDataRequestFields;
        const subdomainReasonForAccessMap: {[subdomainId: number]: string} =
            store.getState().data.dam2.subdomainDataRequestReason;

        const initialIdMap = extractOpsUnitSubdomainRequestFieldIds(OpsUnitSubdomainRequestState);
        const initialReasonMap = extractOpsUnitSubdomainReasonForAccess(OpsUnitSubdomainRequestState);
        const pendingReasonMap = extractOpsUnitPendingSubdomainReasonForAccess(OpsUnitSubdomainRequestState);
        let noChangeInReasonWhenGotAddedFields = false;

        const combinedData: ISubdomainDataRequest[] = [];
        Object.keys(subdomainFieldMap).forEach((subdomainId) => {
            const counts = calculateChangeCounts(
                subdomainFieldMap[parseInt(subdomainId)],
                initialIdMap[parseInt(subdomainId)],
            );
            const subDomain = subdomains.find((sub) => sub.Id === parseInt(subdomainId));
            if (subDomain) {
                const subdomainName = subDomain ? subDomain.Name : '';
                if (
                    initialReasonMap[parseInt(subdomainId)] === subdomainReasonForAccessMap[parseInt(subdomainId)] &&
                    counts.addedCount > 0
                ) {
                    noChangeInReasonWhenGotAddedFields = true;
                }

                const subSummary = {
                    SubdomainName: subdomainName,
                    ReasonUpdated:
                        !(
                            // current accepted reason == new reason being submitted (not allowed)
                            (
                                (initialReasonMap[parseInt(subdomainId)] || '') ===
                                subdomainReasonForAccessMap[parseInt(subdomainId)]
                            )
                        ) && // no new fields added, pending reason being submitted == current pending reason ( allowed )
                        !(
                            counts.addedCount === 0 &&
                            subdomainReasonForAccessMap[parseInt(subdomainId)] ===
                                pendingReasonMap[parseInt(subdomainId)]
                        ),
                    FieldsRemoved: counts.removedCount,
                    FieldsRequested: counts.addedCount,
                };
                const data = {
                    SubdomainId: parseInt(subdomainId),
                    ReasonForAccess: subdomainReasonForAccessMap[parseInt(subdomainId)] || '',
                    Fields: subdomainFieldMap[parseInt(subdomainId)],
                    SubdomainRequestSummary: subSummary,
                };
                combinedData.push(data);
            }
        });

        return {windowPeriodId, formId, combinedData, noChangeInReasonWhenGotAddedFields};
    }

    function checkFormErrors(combinedData: ISubdomainDataRequest[]): string[] {
        const errors = [];
        const dataChanged = combinedData?.find(
            (data) =>
                data.SubdomainRequestSummary.FieldsRemoved > 0 ||
                data.SubdomainRequestSummary.FieldsRequested > 0 ||
                data.SubdomainRequestSummary.ReasonUpdated,
        );
        if (!dataChanged) {
            errors.push('No changes in data');
            return errors;
        }
        for (const data of combinedData) {
            const {FieldsRequested, ReasonUpdated} = data?.SubdomainRequestSummary || {
                FieldsRemoved: 0,
                FieldsRequested: 0,
                ReasonUpdated: false,
            };
            if (FieldsRequested > 0 && !data?.ReasonForAccess) {
                errors.push(
                    'Reason(s) for accessing subdomain cannot be empty when requesting for new data fields. Please provide reason(s) for accessing subdomain and try again.',
                );
            } else if (FieldsRequested > 0 && !ReasonUpdated) {
                errors.push('You must have a change in reason when adding fields.');
            }
            if (FieldsRequested === 0 && ReasonUpdated) {
                errors.push(
                    'Reason(s) for accessing subdomain can only be updated when requesting for new data fields. Please enter the previous reason(s) or discard your draft to revert back to the original form.',
                );
            }
        }
        return uniq(errors);
    }

    const handleSubmitRequest = () => {
        const request = prepareRequest();
        const {combinedData} = request;
        const errors = checkFormErrors(combinedData);
        if (errors.length) {
            openGrowl(`Error: ${errors.join(' ')}`);
            return;
        }
        setOpenSubmissionModal(true);
        setSubdomainRequestSummary(combinedData);
        setConfirmationFunction(() => () => handleSubmit(request));
        return;
    };

    const renderButtons = () => {
        if (formState.toLowerCase() == 'submitted' || !isOA) {
            return <></>;
        } else {
            return (
                <>
                    <Button
                        disabled={!fieldsUpdated}
                        variant="outlined"
                        onClick={handleDraft}
                        style={{marginRight: '10px'}}
                    >
                        {' '}
                        save as draft{' '}
                    </Button>
                    <Button
                        disabled={!fieldsUpdated && formState.toLowerCase() != 'saved as draft'}
                        variant="contained"
                        onClick={handleSubmitRequest}
                    >
                        {' '}
                        submit request{' '}
                    </Button>
                </>
            );
        }
    };

    return (
        <>
            {renderButtons()}
            <SubmissionModal
                onCancel={() => {
                    setOpenSubmissionModal(false);
                    setSubdomainRequestSummary([]);
                }}
                onConfirm={confirmationFunction}
                open={openSubmissionModal}
                subdomainRequestSummary={subdomainRequestSummary}
            />
        </>
    );
};

export const WithdrawButton = (props: {
    windowPeriodId: number;
    triggerUpdate: () => void;
    openGrowl: (message: string) => void;
}) => {
    const store = useStore();
    const {triggerUpdate, openGrowl} = props;
    const formState = store.getState().data.dam2.domainDataRequestState.FormMeta.State;
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalTextBody, setModalTextBody] = useState<string>('');
    const [modalConfirmBtnText, setModalConfirmBtnText] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [confirmationFunction, setConfirmationFunction] = useState<() => void>(() => {});
    const {accessToken} = useContext(AuthenticationContext);
    const isOA: boolean =
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministrator' ||
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministratorappuser' ||
        false;

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    const handleConfirmWithdraw = () => {
        const formId = store.getState().data.dam2.domainDataRequestState.FormMeta.FormId;
        setOpenConfirmationModal(false);
        withdrawDomainDataRequest(formId)
            .then(() => {
                openGrowl('Submission withdrawn successfully');
                triggerUpdate();
            })
            .catch(() => {
                openGrowl('Error withdrawing submission');
            });
    };

    const renderWithdrawButton = () => {
        if (formState.toLowerCase() == 'submitted') {
            return (
                <Button
                    variant="outlined"
                    hidden
                    onClick={() => {
                        setModalTitle('Withdraw submission?');
                        setModalConfirmBtnText('Withdraw');
                        setModalTextBody('All changes submitted in this request window will be discarded.');
                        setConfirmationFunction(() => handleConfirmWithdraw);
                        setOpenConfirmationModal(true);
                    }}
                    startIcon={<DeleteIcon />}
                >
                    {' '}
                    withdraw submission{' '}
                </Button>
            );
        }
    };

    return (
        <>
            <ConfirmationModal
                title={modalTitle}
                textBody={modalTextBody}
                confirmBtnText={modalConfirmBtnText}
                cancelBtnTxt={'Cancel'}
                open={openConfirmationModal}
                onCancel={() => handleCloseConfirmationModal()}
                onConfirm={() => confirmationFunction()}
            ></ConfirmationModal>

            {isOA && renderWithdrawButton()}
        </>
    );
};
