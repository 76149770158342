import {ITeam, IUser, ITeamResponse} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {IUpdateTeamsCanScreenClient} from '~/store/team/team.thunk';

export const getTeams = async (opsUnitId: number): Promise<ITeamResponse[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/teams`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getTeam = async (teamId: number): Promise<ITeam> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createTeam = async (opsUnitId: number, team: ITeam): Promise<ITeamResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/team`,
        data: JSON.stringify(team),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createTeamAdmin = async (teamId: number, admin: IUser): Promise<ITeamResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/team-admin`,
        data: JSON.stringify(admin),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateTeamAdmin = async (teamId: number, adminDetails: IUser) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/team-admin/${adminDetails.Id}`,
        data: JSON.stringify(adminDetails),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const deleteTeamAdmin = async (
    teamId: number,
    userId: number,
    deletedReason: string,
): Promise<ITeamResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/team-admin/${userId}`,
        data: JSON.stringify({DeletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const activateTeamAdmin = async (teamId: number, userId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/team-admin/${userId}/reactivate`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const updateTeamDetails = async (team: ITeam): Promise<ITeamResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${team.Id}`,
        data: JSON.stringify(team),
        method: 'POST',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const updateTeamsCanScreenClient = async (updateTeams: IUpdateTeamsCanScreenClient) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/team/set-can-screen`,
        data: JSON.stringify(updateTeams),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const deleteTeam = async (teamId: number, deletedReason: string) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}`,
        data: JSON.stringify({deletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};
