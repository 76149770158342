import React from 'react';
import {Container, LinearProgress, Typography} from '@mui/material';
import loading from '~/assets/images/loading.svg';
import './ProgressBar.scss';

export type ProgressBarVariant = 'default' | 'client';
export interface IProgressBar {
    label?: string;
    variant?: ProgressBarVariant;
}

export const ProgressBar = ({label, variant = 'default'}: IProgressBar) => {
    if (variant === 'client') {
        return (
            <Container styleName="container">
                <img src={loading} alt="Client Loading Cover" styleName="image" />
                <LinearProgress variant="indeterminate" styleName="progressBar client" />
                {label && (
                    <Typography variant="subtitle1" align="center" styleName="progressBarLabel">
                        {label}
                    </Typography>
                )}
            </Container>
        );
    } else {
        return (
            <Container styleName="container">
                {label && (
                    <Typography variant="subtitle1" align="center" styleName="progressBarLabel">
                        {label}
                    </Typography>
                )}

                <LinearProgress variant="indeterminate" styleName="progressBar" />
            </Container>
        );
    }
};
