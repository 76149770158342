import React from 'react';
import './AdminDetails.scss';

import {Box} from '@mui/material';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {IAdminRow} from '~/interfaces/admin';
import UserIndicatorTag from '~/components/Admin/UserIndicatorTag/UserIndicatorTag';

export const AdminDetails =
    (index: number, displayOAIndex = false) =>
    (admin: IAdminRow) => {
        const getDesignationText = () => {
            if (!admin.Admins) {
                return;
            }

            // If OA and want custom designation
            if (!admin.OpsUnitId && displayOAIndex) {
                return `Ops Unit Admin ${index + 1}`;
            } else {
                return admin.Admins[index].Designation;
            }
        };

        if (!!admin && !!admin.Admins && admin.Admins[index]) {
            return (
                <Box component="div">
                    <Box component="div" styleName="nameWrapper">
                        {admin.Admins[index].Name}
                    </Box>
                    <Box component="div" styleName="designationWrapper">
                        {getDesignationText()}
                    </Box>
                    <Box display="flex">
                        {admin.CanScreenClient && <UserIndicatorTag />}
                        {!admin.Admins[index].IsActive && <SuspendedIcon />}
                    </Box>
                </Box>
            );
        } else {
            return <>-</>;
        }
    };
