import {Typography, Grid, Divider} from '@mui/material';
import React from 'react';
import {MockDataComponent} from './MockDataComponent';
import {subDomainDataMap, ISubDomainDataComponents} from './subDomainData';
import {dateFormatter} from '~/utils/dateUtils';
import {ISubDomainDisplayProps} from '~/components/Client/SubDomainDisplay/SubDomainDisplay';
import {isEmpty} from 'lodash';
import {SubDomainStatus} from '~/interfaces/client';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import './SubDomainDisplay.scss';
import {SuccessfulNoResultMessage} from '~/components/Common/IconMessage/IconMessage';

export const NoData = ({message}: {message?: string | null}) => (
    <SuccessfulNoResultMessage message={message ? message : SUBDOMAIN_RESULT_MESSAGE.EMPTY} style={{padding: '0px'}} />
);

export const CompletedSubDomain = ({
    name,
    code,
    data,
    status,
    lastUpdatedAt,
    source,
    errorMessages,
}: ISubDomainDisplayProps) => {
    // For debugging purposes, return a placeholder component when the correct component does not exist yet
    const DataComponent = Object.keys(subDomainDataMap).includes(code)
        ? (subDomainDataMap[code as keyof ISubDomainDataComponents] as React.FC<{
              data: typeof data;
              status: typeof status;
              source: typeof source;
              errorMessages: typeof errorMessages;
          }>)
        : MockDataComponent;

    const Component =
        Object.values(status).every((_status) => _status === SubDomainStatus.COMPLETED) && isEmpty(data)
            ? NoData
            : DataComponent;
    return (
        <Grid container spacing={2}>
            <Grid item id="header" xs={12}>
                <Typography variant="h4" styleName="subDomainHeader">
                    {name}
                </Typography>
                {lastUpdatedAt && (
                    <div
                        data-testid="informationAccurateDate"
                        styleName="informationAccurateHeader"
                    >{`Information accurate as at ${dateFormatter(lastUpdatedAt)}`}</div>
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider styleName="divider" />
            </Grid>
            <Grid item xs={12}>
                <Component data={data} status={status} source={source} errorMessages={errorMessages} />
            </Grid>
        </Grid>
    );
};
