import React, {useState} from 'react';
import {Checkbox, Collapse, Grid, IconButton} from '@mui/material';
import {IDomain} from '~/interfaces/common';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import './DomainCheckBoxItem.scss';

export const getSubDomainsCodes = (domain: IDomain) => {
    return domain.SubDomains.flatMap((subDomain) => [subDomain.Code]);
};

export interface IDomainCheckBoxItem {
    domain: IDomain;
    selectedSubDomains: string[];
    onSelectSubDomains: (selected: string[]) => void;
}
export const DomainCheckBoxItem = ({domain, selectedSubDomains, onSelectSubDomains}: IDomainCheckBoxItem) => {
    const [expand, setExpand] = useState<boolean>(false);
    const allSubDomainsCodes = getSubDomainsCodes(domain);

    const handleExpand = () => {
        setExpand(!expand);
    };

    const handleSelectDomain = (_e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        const newList = selectedSubDomains.filter((subDomain) => !allSubDomainsCodes.includes(subDomain));
        if (isChecked) {
            onSelectSubDomains([...newList, ...allSubDomainsCodes]);
        } else {
            onSelectSubDomains(newList);
        }
    };

    const isDomainSelected = () => {
        return allSubDomainsCodes.every((subDomainCode) => selectedSubDomains.includes(subDomainCode));
    };

    const handleSelectSubDomain = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        const selectedValue = e.target.value;
        const newList = isChecked
            ? [...selectedSubDomains, selectedValue]
            : selectedSubDomains.filter((code) => code !== selectedValue);
        onSelectSubDomains(newList);
    };

    const isSubDomainSelected = (code: string) => {
        return selectedSubDomains.includes(code);
    };

    const domainLabelStyle = (checked: boolean) => {
        return `domainCheckBoxLabel ${checked ? 'checked' : 'unChecked'}`;
    };

    const subDomainLabelStyle = (checked: boolean) => {
        return `subDomainCheckBoxLabel ${checked ? 'checked' : 'unChecked'}`;
    };

    const domainChecked = isDomainSelected();

    return (
        <div styleName="domainCheckBoxItem">
            <Grid container>
                <Grid item xs={6} styleName="domainCheckBox">
                    <label styleName={domainLabelStyle(domainChecked)}>
                        <Checkbox
                            onChange={handleSelectDomain}
                            name={domain.Name}
                            color="primary"
                            value={domain.Name}
                            checked={domainChecked}
                        />
                        {domain.Name}
                    </label>
                </Grid>
                <Grid item xs={5} onClick={handleExpand} styleName="expand">
                    <IconButton styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                </Grid>
            </Grid>

            <Collapse in={expand} timeout={500} unmountOnExit>
                {domain.SubDomains.map((subDomain) => {
                    const checked = isSubDomainSelected(subDomain.Code);
                    return (
                        <label styleName={subDomainLabelStyle(checked)} key={subDomain.Code}>
                            <Checkbox
                                onChange={handleSelectSubDomain}
                                name={subDomain.Name}
                                color="primary"
                                value={subDomain.Code}
                                checked={checked}
                            />
                            {subDomain.Name}
                        </label>
                    );
                })}
            </Collapse>
        </div>
    );
};
