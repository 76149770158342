import React, {useState} from 'react';
import {Checkbox, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, Box} from '@mui/material';
import {IReport} from '~/pages/Admin/Reports/Report';
import styles from './ReportTable.scss';
import {DateRangePicker, IDateRange} from './DateRangePicker';

export const ReportTable: React.FunctionComponent<{
    reports: IReport[];
    setReports: React.Dispatch<React.SetStateAction<IReport[]>>;
}> = ({reports, setReports}) => {
    const [isSelectAll, setSelectAll] = useState(false);
    const [reportsWithErrors, setReportsWithErrors] = useState([] as Array<number>);

    const storeReportWithErrors = (value: number) => {
        setReportsWithErrors([...reportsWithErrors, value]);
        setReportCheckboxToUnselected(value);
    };

    const removeReportFromErrors = (value: number) => {
        const updatedReportsWithErrors = reportsWithErrors.filter((reportId) => reportId !== value);
        setReportsWithErrors(updatedReportsWithErrors);
    };

    const setAllSelectedReports = (isSelected: boolean) => {
        const reportsToSet = reports.map((report) => {
            if (reportsWithErrors.includes(report.Id)) {
                return {...report, Selected: false};
            } else {
                return {...report, Selected: isSelected};
            }
        });
        setReports(reportsToSet);
    };

    const reportIsSelected = (id: number) => {
        const report = reports.find((r) => r.Id === id);
        return report ? report.Selected : false;
    };

    const toggleSelectAll = (toggledReports: IReport[]) => {
        const unselectedReport = toggledReports.find((report) => report.Selected === false);
        setSelectAll(unselectedReport === undefined);
    };

    const toggleReportCheckbox = (id: number) => {
        const toggledReports = reports.map((report) => {
            if (report.Id === id) {
                return {...report, Selected: !report.Selected};
            }
            return report;
        });
        setReports(toggledReports);
        toggleSelectAll(toggledReports);
    };

    const setReportCheckboxToUnselected = (id: number) => {
        const toggledReports = reports.map((report) => {
            if (report.Id === id) {
                return {...report, Selected: false};
            }
            return report;
        });
        setReports(toggledReports);
    };

    const countSelectedReports = () => {
        const selectedReports = reports.filter((report) => report.Selected);
        return selectedReports.length === reports.length;
    };

    const checkbox = (data: IReport) => (
        <Checkbox
            color={'default'}
            id={`checkbox_${data.Id}`}
            data-testid={`checkbox_${data.Id}`}
            checked={reportIsSelected(data.Id)}
            onChange={() => toggleReportCheckbox(data.Id)}
            disabled={reportsWithErrors.includes(data.Id)}
        />
    );

    const selectAllCheckbox = () => (
        <Checkbox
            color={'default'}
            id={`checkbox_all`}
            data-testid="checkbox_all"
            checked={countSelectedReports()}
            onChange={() => {
                setSelectAll(!isSelectAll);
                setAllSelectedReports(!isSelectAll);
            }}
        />
    );

    const onDateChange = ({Id, DateFrom, DateTo}: IDateRange) => {
        const reportsToSet = reports.map((report) => {
            if (report.Id === Id) {
                report.DateFrom = DateFrom;
                report.DateTo = DateTo;
            }
            return report;
        });
        setReports(reportsToSet);
    };

    const reportList = reports.map((report) => ({
        ...report,
        Name: (
            <Box display="flex">
                <Box>{checkbox(report)}</Box>
                <Box>{report.Name}</Box>
            </Box>
        ),
    }));

    return (
        <div id={styles.customTable}>
            <TableContainer component={Paper}>
                <Table data-testid="report-table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '28%'}}>
                                <div key="reportType" data-testid="reportType">
                                    {selectAllCheckbox()}Report Type
                                </div>
                            </TableCell>
                            <TableCell style={{width: '32%'}}>Description</TableCell>
                            <TableCell>Date Range</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportList.map((report) => {
                            return (
                                <TableRow key={report.Id}>
                                    <TableCell>{report.Name}</TableCell>
                                    <TableCell>{report.Description}</TableCell>
                                    <TableCell>
                                        <DateRangePicker
                                            data={report}
                                            onDateChange={onDateChange}
                                            storeReportWithErrors={storeReportWithErrors}
                                            removeReportFromErrors={removeReportFromErrors}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
