import {pick} from 'lodash';
import {IAdminRow, IOpsUnit} from '~/interfaces/admin';
import {IAppState} from '~/store';

export const getOpsUnits = (agencyId?: number) => (state: IAppState) => {
    if (!agencyId) return {};
    const agency = state.data.admin.agencies.agencies[agencyId];
    return !!agency?.OpsUnits ? pick(state.data.admin.opsUnits, agency?.OpsUnits) : {};
};

export const getDamOpsUnit =
    (opsUnitId: number) =>
    (state: IAppState): IOpsUnit | undefined =>
        state.data.admin.opsUnits?.dam?.find((opsUnit) => opsUnit.Id === opsUnitId);

export const getOpsUnitAdminList =
    (opsUnitId: number) =>
    (state: IAppState): IAdminRow | undefined => {
        const opsUnit = state.data.admin.opsUnits[Number(opsUnitId)];
        return !!opsUnit ? {Id: opsUnit.Id, Name: opsUnit.Name, Admins: opsUnit.Admins} : undefined;
    };

export const getDamOpsUnitExist =
    (opsUnitId: number) =>
    (state: IAppState): boolean | undefined =>
        state.data.admin.opsUnits?.dam?.find((opsUnit) => opsUnit.Id === opsUnitId) ? true : false;
