import React from 'react';
import {
    EnhancedTableExpandableRow,
    ICustomHeaderColumn,
} from '~/components/Common/Table/EnhancedTable/EnhancedTableExpandableRow';
import {Grid, Table, TableBody, TableHead, TableRow, Typography, TableCell} from '@mui/material';
import './BusinessOwnerships.scss';
import {allNoAccess, allNoResults, extractAllValues, formatValue, NotAuthorizedMessage} from '~/utils/contentUtils';
import {formatDate} from '~/utils/dateUtils';
import {NoData} from '../CompletedSubDomain';
import {FIELD_VALUES} from '~/constants';

export interface IBusinessOwnershipData {
    NumberOfNonshareableRecords: number;
    BusinessOwnerships: IBusinessOwnershipRecord[];
}

export interface IBusinessOwnershipRecord {
    Id?: number;
    PositionAppointmentDate: string;
    PositionWithdrawnWithdrawalDate: string;
    IndicatorShareholderBoardmember: string;
    UEN: string;
    EntityName: string;
    EntityType: string;
    PrimaryActivityDescription: string;
    SecondaryActivityDescription: string;
    RegistrationDate: string;
    DeregistrationDate: string;
    EntityBusinessCommencementDate: string;
}

export interface IRowData<T> {
    id: number;
    model: T;
    expandableContent?: React.ReactElement;
    entityName: string;
    entityType: string;
    dateOfBusinessCommencement: string;
}

const formatDateValue = (date: string) => formatDate(new Date(date));

export const dataWithExpandableContent = <T extends IBusinessOwnershipRecord>(data: T[]) =>
    data.map(
        (record: T, index: number): IRowData<T> => ({
            id: index,
            model: record,
            expandableContent: expendableContent(record),
            entityName: record.EntityName,
            entityType: record.EntityType,
            dateOfBusinessCommencement: record.EntityBusinessCommencementDate,
        }),
    );

const TableHeader = ({content}: {content: string}) => (
    <TableCell style={{color: '#606060', paddingLeft: '24px'}}>{content}</TableCell>
);
const TableContent = ({content}: {content: string}) => <TableCell style={{padding: '24px'}}>{content}</TableCell>;

export const expendableContent = (record: IBusinessOwnershipRecord): React.ReactElement => (
    <div>
        {!allNoAccess([record.PrimaryActivityDescription, record.SecondaryActivityDescription]) && (
            <Table data-testid="activityDescription">
                <colgroup>
                    <col style={{width: '50%'}} />
                    <col style={{width: '50%'}} />
                </colgroup>
                <TableHead data-testid="activityDescriptionHeader" style={{backgroundColor: '#e8eaf6'}}>
                    <TableHeader content="Primary Activity Description" />
                    <TableHeader content="Secondary Activity Description" />
                </TableHead>
                <TableBody>
                    <TableRow data-testid="activityDescriptionContent">
                        <TableContent content={record.PrimaryActivityDescription} />
                        <TableContent content={record.SecondaryActivityDescription} />
                    </TableRow>
                </TableBody>
            </Table>
        )}
        {!allNoAccess([
            record.IndicatorShareholderBoardmember,
            record.PositionAppointmentDate,
            record.PositionWithdrawnWithdrawalDate,
        ]) && (
            <Table data-testid="positionInformation">
                <TableHead data-testid="positionInformationHeader" style={{backgroundColor: '#e8eaf6'}}>
                    <TableHeader content="Shareholder / Active Board Member" />
                    <TableHeader content="Position Appointment Date" />
                    <TableHeader content="Position Withdrawn Date" />
                </TableHead>
                <TableBody>
                    <TableRow data-testid="positionInformationContent">
                        <TableContent content={record.IndicatorShareholderBoardmember} />
                        <TableContent content={formatValue(record.PositionAppointmentDate, formatDateValue)} />
                        <TableContent content={formatValue(record.PositionWithdrawnWithdrawalDate, formatDateValue)} />
                    </TableRow>
                </TableBody>
            </Table>
        )}
    </div>
);

export const BusinessOwnershipTable = <T extends IBusinessOwnershipRecord>({data}: {data: T[]}) => {
    type IHeadRowData = Pick<IRowData<T>, 'id' | 'entityName' | 'entityType' | 'dateOfBusinessCommencement'>;

    const headColumns: ICustomHeaderColumn<T, keyof IHeadRowData>[] = [
        {
            id: 'entityName',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Entity Name</Typography>
                    <Typography variant="caption">Unique Entity Number (UEN)</Typography>
                </Grid>
            ),
            // eslint-disable-next-line react/display-name
            column: (model: T) => (
                <Grid>
                    <Typography variant="body1">{model.EntityName}</Typography>
                    <Typography styleName="fieldName">{model.UEN}</Typography>
                </Grid>
            ),
        },
        {
            id: 'entityType',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Entity Type</Typography>
                    <Typography variant="caption">Date of Registration</Typography>
                </Grid>
            ),
            // eslint-disable-next-line react/display-name
            column: (model: T) => (
                <Grid>
                    <Typography variant="body1">{model.EntityType}</Typography>
                    <Typography styleName="fieldName">
                        {formatValue(model.RegistrationDate, formatDateValue)}
                    </Typography>
                </Grid>
            ),
        },
        {
            id: 'dateOfBusinessCommencement',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Date of Business Commencement</Typography>
                    <Typography variant="caption">Date of De-registration</Typography>
                </Grid>
            ),
            // eslint-disable-next-line react/display-name
            column: (model: T) => (
                <Grid>
                    <Typography variant="body1">
                        {formatValue(model.EntityBusinessCommencementDate, formatDateValue)}
                    </Typography>
                    <Typography styleName="fieldName">
                        {formatValue(model.DeregistrationDate, formatDateValue)}
                    </Typography>
                </Grid>
            ),
        },
    ];

    return (
        <div data-testid="businessOwnership">
            {EnhancedTableExpandableRow<IHeadRowData, IRowData<T>, T>(
                headColumns,
                dataWithExpandableContent(data),
                true,
                false,
                'id',
            )}
        </div>
    );
};

export const BusinessOwnerships: React.FunctionComponent<{data: IBusinessOwnershipData}> = ({data}) => {
    const hasRecordsToDisplay = !allNoResults(extractAllValues(data.BusinessOwnerships));

    if (data.NumberOfNonshareableRecords == 0 && !hasRecordsToDisplay) {
        return <NoData />;
    }

    const getTime = (date: string) => (date === FIELD_VALUES.NO_RESULTS ? -1 : new Date(date).getTime());

    const sortedRecords = data.BusinessOwnerships.slice().sort((recordA, recordB) =>
        getTime(recordB.EntityBusinessCommencementDate) > getTime(recordA.EntityBusinessCommencementDate) ? 1 : -1,
    );

    return (
        <>
            {data.NumberOfNonshareableRecords > 0 && (
                <NotAuthorizedMessage data-testid="notAuthorizedMessage" hasRecordsToDisplay={hasRecordsToDisplay} />
            )}
            {hasRecordsToDisplay && (
                <BusinessOwnershipTable data-testid="businessOwnershipTable" data={sortedRecords} />
            )}
        </>
    );
};
