import {createSlice} from '@reduxjs/toolkit';
import {omit} from 'lodash';
import {IOpsUnit} from '~/interfaces/admin';
import {
    setOpsUnits,
    createOpsUnit,
    updateOpsUnit,
    createOpsUnitAdmin,
    updateOpsUnitAdmin,
    deleteOpsUnitAdmin,
    activateOpsUnitAdmin,
    setOpsUnit,
    setDamOpsUnits,
} from './opsUnit.thunk';

import {createUser} from '../user/user.thunk';

import {setTeams, createTeam} from '~/store/team/team.thunk';
import {setUsersFromOpsUnit} from '~/store/user/user.thunk';

export const initialOpsUnitsState: {[opsUnitId: number]: IOpsUnit; dam?: IOpsUnit[]} = {};

export const opsUnitSlice = createSlice({
    name: 'opsUnit',
    initialState: initialOpsUnitsState,
    reducers: {
        UPDATE_DAM_OPS_UNIT(state, action) {
            const updatedDam = state.dam?.map((damOpsUnit) => {
                if (damOpsUnit.Id === action.payload.Id) {
                    return action.payload;
                } else {
                    return damOpsUnit;
                }
            });
            state.dam = updatedDam;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setOpsUnits.fulfilled, (state, action) => {
                const obj: {[opsUnitId: number]: IOpsUnit} = {};
                if (action.payload) {
                    for (const opsUnitId in action.payload.opsUnits) {
                        obj[opsUnitId] =
                            state[opsUnitId] && state[opsUnitId].Teams
                                ? {
                                      ...action.payload.opsUnits[opsUnitId],
                                      Teams: [...(state[opsUnitId].Teams as number[])],
                                  }
                                : {...action.payload.opsUnits[opsUnitId]};
                    }
                    return {...obj};
                }
            })
            .addCase(createOpsUnit.fulfilled, (state, action) => {
                state[action.payload.Id] = action.payload;
            })
            .addCase(updateOpsUnit.fulfilled, (state, action) => {
                state[action.payload.Id] = action.payload;
            })
            .addCase(createOpsUnitAdmin.fulfilled, (state, action) => {
                state[action.payload.opsUnitId] = action.payload.opsUnit;
            })
            .addCase(updateOpsUnitAdmin.fulfilled, (state, action) => {
                const currentAdmins = state[action.payload.opsUnitId].Admins || [];
                const editedAdmins = currentAdmins.map((admin) =>
                    admin.Id === action.payload.adminDetails.Id
                        ? {
                              ...action.payload.adminDetails,
                          }
                        : admin,
                );
                state[action.payload.opsUnitId].Admins = editedAdmins;
            })
            .addCase(deleteOpsUnitAdmin.fulfilled, (state, action) => {
                state[action.payload.opsUnitId] = action.payload.opsUnit;
            })
            .addCase(activateOpsUnitAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    const admins = state[action.payload.opsUnitId].Admins || [];
                    const editedAdmins = admins.map((admin) =>
                        admin.Id === action.payload.admin.Id
                            ? {
                                  ...action.payload.admin,
                              }
                            : admin,
                    );
                    state[action.payload.opsUnitId].Admins = editedAdmins;
                }
            })
            .addCase(setOpsUnit.fulfilled, (state, action) => {
                state[action.payload.Id] = {...state[action.payload.Id], ...omit(action.payload, 'Teams')};
            })
            .addCase(setTeams.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.opsUnitId] = {
                        ...state[action.payload.opsUnitId],
                        Teams: Object.keys(action.payload.team).map(Number),
                    };
                }
            })
            .addCase(createTeam.fulfilled, (state, action) => {
                state[action.payload.opsUnitId] = {
                    ...state[action.payload.opsUnitId],
                    Teams: [...(state[action.payload.opsUnitId].Teams as number[]), action.payload.team.Id],
                };
            })
            .addCase(setUsersFromOpsUnit.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.opsUnitId] = {
                        ...state[action.payload.opsUnitId],
                        Users: Object.keys(action.payload.usersObject).map(Number),
                    };
                }
            })
            .addCase(createUser.fulfilled, (state, action) => {
                if (action.payload.opsUnitId) {
                    const currentUsers = state[action.payload.opsUnitId].Users;
                    const newUsers = currentUsers
                        ? ([...currentUsers, action.payload.userDetails.Id] as number[])
                        : ([action.payload.userDetails.Id] as number[]);
                    state[action.payload.opsUnitId] = {...state[action.payload.opsUnitId], Users: newUsers};
                }
            })
            .addCase(setDamOpsUnits.fulfilled, (state, action) => {
                if (action.payload) {
                    return {...state, dam: action.payload};
                }
            });
    },
});

export const opsUnitActions = opsUnitSlice.actions;
export default opsUnitSlice.reducer;
