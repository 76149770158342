import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {IUser, emptyUser} from '~/interfaces/admin';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import style from './SystemAdminEdit.scss';

import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {systemAdminsActions} from '~/store/systemAdmin/systemAdmin.slice';
import {
    activateSystemAdmin,
    createSystemAdmin,
    deleteSystemAdmin,
    updateSystemAdmin,
} from '~/store/systemAdmin/systemAdmin.thunk';
import {isInternetUser} from '~/utils/userType';

export const SystemAdminEdit = () => {
    const dispatch = useAppDispatch();
    const {sysAdminId} = useParams<{sysAdminId?: string}>();
    const systemAdmin = useTypedSelector((state) =>
        state.data.admin.systemAdmins.systemAdmins.find((systemAdmin) => systemAdmin.Id == sysAdminId),
    ) as IUser;
    const numAdmins = useTypedSelector((state) => state.data.admin.systemAdmins.systemAdmins.length);

    const isCreateSystemAdmin = !systemAdmin;
    const initialState = systemAdmin ?? emptyUser();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [user, setUser] = useState<IUser>(initialState);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();

    const disableButton = !allowSaveUsers(user);

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(disableButton);
    const [hasError, setHasError] = useState(false);
    const backUrl = '/admin/system-admins';

    const pathname = history.location.pathname;

    const [isCreateUser, setIsCreateUser] = useState<boolean>(isCreateSystemAdmin);
    const [isEditUser, setIsEditUser] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState({name: '', userId: 0});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

    const isShowSubmitButton = isCreateUser || isEditUser;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    useEffect(() => {
        if (systemAdmin) {
            setUser(systemAdmin);
        }
    }, [systemAdmin]);

    useEffect(() => {
        setIsButtonDisabled(disableButton || hasError);
    }, [user, hasError]);

    const closeAllEdits = () => {
        setIsCreateUser(false);
        setIsEditUser(false);
    };

    const removeUrlFragment = () => {
        history.push(pathname);
    };

    const successfulSubmit = () => {
        closeAllEdits();
        removeUrlFragment();
    };

    const handleEditUser = () => {
        history.push(`${pathname}#edit`);
        setIsEditUser(true);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        if (isCreateUser) {
            await handleCreateUser(user);
        } else {
            await handleUpdateUser(user);
        }
    };

    /**
     * Dispatch thunk to create single user.
     */
    const handleCreateUser = async (user: IUser) => {
        const systemAdminToAdd = prepareUserCreation([user])[0];
        const createSystemAdminResult = await dispatch(createSystemAdmin(systemAdminToAdd));
        if ('error' in createSystemAdminResult) {
            openGrowl(createSystemAdminResult.payload as string);
        } else {
            successfulSubmit();
            dispatch(
                systemAdminsActions.addSystemAdmin({
                    ...user,
                    Id: createSystemAdminResult.payload.SystemAdminId,
                    CreatedAt: createSystemAdminResult.payload.UpdatedAt,
                    UpdatedByUser: createSystemAdminResult.payload.UpdatedByUser,
                }),
            );
            history.push(`/admin/system-admins/${createSystemAdminResult.payload.SystemAdminId}`);
            openGrowl(`Changes successfully saved`);
        }
        setIsLoading(false);
    };

    /**
     * Dispatch thunk to edit single user.
     */
    const handleUpdateUser = async (user: IUser) => {
        const updateSystemAdminResult = await dispatch(updateSystemAdmin(user));
        if ('error' in updateSystemAdminResult) {
            openGrowl(updateSystemAdminResult.payload as string);
        } else {
            successfulSubmit();
            dispatch(
                systemAdminsActions.updateSystemAdmin({
                    ...user,
                    UpdatedAt: updateSystemAdminResult.payload.UpdatedAt,
                    UpdatedByUser: updateSystemAdminResult.payload.UpdatedByUser,
                }),
            );
            openGrowl(`Changes successfully saved`);
        }
        setIsLoading(false);
    };

    const handleActivateUser = async (userId?: number) => {
        if (userId && user.Id === userId) {
            const thunkActionResult = await dispatch(activateSystemAdmin({userId: userId}));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl(`${user.Name} has been reactivated`);
                dispatch(systemAdminsActions.activateSystemAdmin({userId: userId}));
            }
        }
    };

    const handleDeleteUser = (userId: number) => {
        if (user.Id === userId) {
            setIsDeleteDialogOpen(true);
            setUserToBeDeleted({name: user.Name, userId: userId});
        }
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteSystemAdmin({userId: Number(userToBeDeleted.userId), deletedReason}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            dispatch(systemAdminsActions.deleteSystemAdmin({userId: Number(userToBeDeleted.userId)}));
            successfulSubmit();
            openGrowl(`${userToBeDeleted.name} has been deleted`);
            history.push(backUrl);
        }
        setIsDeleting(false);
        closeDeleteDialog();
        closeAllEdits();
        setUserToBeDeleted({name: '', userId: 0});
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };
    const confirmCancel = () => {
        closeAllEdits();
        if (isCreateSystemAdmin) {
            history.push(backUrl);
        } else {
            removeUrlFragment();
        }

        setUser(systemAdmin);
        closeConfirmDialog();
    };

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push(backUrl);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.systemAdminEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            System Admin Details
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        {isShowSubmitButton && <SubmitButtons />}
                    </Grid>
                </Grid>
                {isCreateUser || isEditUser ? (
                    <UserDetails
                        details={user}
                        setUser={setUser}
                        fieldsToDisable={
                            isCreateUser ? [] : isInternetUser(user) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setHasError}
                    />
                ) : (
                    <UserView
                        details={user}
                        userRole="System Admin"
                        handleEditUser={handleEditUser}
                        handleActivateUser={handleActivateUser}
                        isDisplayControls={!isShowSubmitButton}
                        hideDeleteButton={numAdmins === 1}
                        handleDeleteUser={handleDeleteUser}
                    />
                )}
                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteSystemAdminDialog"
                title={`Delete System Admin?`}
                buttonMessage="SYSTEM ADMIN"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
