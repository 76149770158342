import React from 'react';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {allNoResults, extractAllValues, formatDateField, formatMobileNumber, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';

export interface ITransitionalShelter {
    OtpReferenceNumber: string;
    CaseLocation: string;
    StartDate: string;
    EndDate: string;
    CaseStatus: string;
    CaseworkerName: string;
    CaseworkerContact: string;
    DischargeAccommodation: string;
    CaseClosureReason: string;
    OutcomePlanMembers: string;
}

export type ITransitionalShelterData = ITransitionalShelter[];

const tsToFields = (transitionalShelter: ITransitionalShelter) => {
    const {
        OtpReferenceNumber,
        CaseLocation,
        StartDate,
        EndDate,
        CaseStatus,
        CaseworkerName,
        DischargeAccommodation,
        CaseClosureReason,
        CaseworkerContact,
        OutcomePlanMembers,
    } = transitionalShelter;
    const caseStatusStyle = CaseStatus === 'Open' ? 'activeCase' : '';
    const caseDetails = [
        {label: 'Case Reference Number', value: OtpReferenceNumber},
        {label: 'Name of Transitional Shelter', value: CaseLocation},
        {label: 'Case Status', value: CaseStatus, styleName: caseStatusStyle},
        {label: 'Date of Admission', value: formatDateField(StartDate, true, true)},
        {label: 'Name of Caseworker', value: CaseworkerName},
        {label: 'Contact Number of Caseworker', value: formatMobileNumber(CaseworkerContact)},
    ];
    const shelterMembers = {
        label: `Shelter Members (${isNotEmpty(OutcomePlanMembers) ? OutcomePlanMembers.split(' / ').length : 0})`,
        value: OutcomePlanMembers,
    };
    const dischargeDetails = [
        {label: 'Discharge Date', value: formatDateField(EndDate, true, true)},
        {label: 'Discharge Type', value: CaseClosureReason},
        {label: 'Discharge Accommodation', value: DischargeAccommodation},
    ];
    return CaseStatus === 'Open'
        ? [...caseDetails, shelterMembers]
        : [...caseDetails, ...dischargeDetails, shelterMembers];
};

const sortOrder: {[key: string]: number} = {Open: 1, Closed: 2};

export const TransitionalShelter: React.FunctionComponent<{data: ITransitionalShelterData}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    const sortedTS = noData
        ? data.map(tsToFields)
        : data
              .sort((caseA, caseB) => {
                  return sortOrder[caseA.CaseStatus] - sortOrder[caseB.CaseStatus];
              })
              .sort((caseA, caseB) => {
                  return caseA.CaseStatus == caseB.CaseStatus
                      ? new Date(caseB.StartDate).getTime() - new Date(caseA.StartDate).getTime()
                      : 0;
              })
              .map(tsToFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <MultiGridDisplay
                    dataTestId="transitionalShelter"
                    dataFieldGroups={sortedTS}
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
