import {Divider, Typography} from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {NotificationItem} from '~/components/Common/Notification/NotificationItem';
import emptyNotifications from '~/assets/images/emptyNotifications.svg';
import {useHistory} from 'react-router-dom';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setUserNotification, updateUserNotifications} from '~/store/userNotification/userNotification.thunk';
import {userNotificationActions} from '~/store/userNotification/userNotification.slice';

import './Notifications.scss';

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const userNotifications = useTypedSelector((state) => state.data.common.userNotifications);
    const scrollRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const scrollId = parseInt(history.location.hash.split('#')[1], 10);

    useEffect(() => {
        dispatch(setUserNotification());
        dispatch(updateUserNotifications());
    }, []);

    useEffect(
        () => () => {
            // eslint-disable-next-line no-console
            dispatch(userNotificationActions.UPDATE_USER_NOTIFICATIONS_LOCAL());
        },
        [],
    );

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [scrollId]);

    return (
        <div styleName="notifications">
            <Typography styleName="title">Notifications</Typography>
            <Divider />
            {userNotifications.length === 0 ? (
                <div styleName="emptyState">
                    <img src={emptyNotifications} alt="Empty Notifications" styleName="image" />
                    <Typography styleName="title">You&apos;re all caught up, there are no notifications</Typography>
                </div>
            ) : (
                <>
                    {userNotifications.map((notification, index) => (
                        <div
                            ref={notification.Notification.Id === scrollId ? scrollRef : null}
                            key={notification.Notification.Id}
                        >
                            <NotificationItem
                                notification={notification}
                                type="page"
                                divider={index < userNotifications.length - 1}
                            />
                        </div>
                    ))}
                    <Divider />
                </>
            )}
        </div>
    );
};
