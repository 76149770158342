import React from 'react';
import {Grid, Typography} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import './Common.scss';
import {formatDate, getDate} from '~/utils/dateUtils';
import {isNotEmpty} from '~/utils/contentUtils';
import {FIELD_VALUES} from '~/constants';

export interface IHdbAddress {
    BlockNumber: string;
    StreetName: string;
    UnitNumber: string;
    BuildingName: string;
    PostalCode: string;
    DwellingType: string;
}

export const getLower = (value: string) => {
    if (isNotEmpty(value)) {
        return value.toLowerCase();
    }
    return value;
};

export const HdbAddress: React.FunctionComponent<{data?: IHdbAddress; startDateOfRentalTenancy?: string}> = ({
    data,
    startDateOfRentalTenancy,
}) => {
    if (!data) return <></>;

    const houseInformation = [
        {
            //Default to no access to hide field
            label: 'Start Date of Rental Tenancy',
            value: startDateOfRentalTenancy ? (
                isNotEmpty(startDateOfRentalTenancy) ? (
                    <Typography data-testid="startDateOfRentalTenancy" variant="h6">
                        {formatDate(getDate(startDateOfRentalTenancy), true, true)}
                    </Typography>
                ) : (
                    startDateOfRentalTenancy
                )
            ) : (
                FIELD_VALUES.NO_ACCESS
            ),
            fullWidth: true,
            styleName: startDateOfRentalTenancy && isNotEmpty(startDateOfRentalTenancy) ? 'capitalize' : '',
        },
        {
            label: 'Block / House Number',
            value: data.BlockNumber,
            styleName: isNotEmpty(data.BlockNumber) ? 'capitalize' : '',
        },
        {
            label: 'Street Name',
            value: getLower(data.StreetName),
            styleName: isNotEmpty(data.StreetName) ? 'capitalize' : '',
        },
        {
            label: 'Unit Number',
            value: getLower(data.UnitNumber),
            styleName: isNotEmpty(data.UnitNumber) ? 'capitalize' : '',
        },
        {
            label: 'Building Name',
            value: getLower(data.BuildingName),
            styleName: isNotEmpty(data.BuildingName) ? 'capitalize' : '',
        },
        {
            label: 'Postal Code',
            value: getLower(data.PostalCode),
            styleName: isNotEmpty(data.PostalCode) ? 'capitalize' : '',
        },
        {
            label: 'Dwelling Type',
            value: getLower(data.DwellingType),
            styleName: isNotEmpty(data.DwellingType) ? 'capitalize' : '',
        },
    ];

    return (
        <Grid data-testid="hdbAddress" container spacing={3}>
            <SimpleGridDisplay fields={houseInformation} />
        </Grid>
    );
};
