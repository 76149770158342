import {RouteProps} from 'react-router-dom';
import {AgencyEdit} from '~/pages/Admin/AgencyEdit/AgencyEdit';
import {AgencyList} from '~/pages/Admin/AgencyList/AgencyList';
import {Dashboard} from '~/pages/Admin/Dashboard/Dashboard';
import {DataAccessMatrix} from '~/pages/Admin/DataAccessMatrix/DataAccessMatrix';
import {DataAccessMatrixEdit} from '~/pages/Admin/DataAccessMatrixEdit/DataAccessMatrixEdit';
import {OpsUnitEdit} from '~/pages/Admin/OpsUnitEdit/OpsUnitEdit';
import {OpsUnitList} from '~/pages/Admin/OpsUnitList/OpsUnitList';
import {OpsUnitUserEdit} from '~/pages/Admin/OpsUnitUserEdit/OpsUnitUserEdit';
import {OpsUnitUserList} from '~/pages/Admin/OpsUnitUserList/OpsUnitUserList';
import {Report} from '~/pages/Admin/Reports/Report';
import {TeamEdit} from '~/pages/Admin/TeamEdit/TeamEdit';
import {TeamList} from '~/pages/Admin/TeamList/TeamList';
import {UserEdit} from '~/pages/Admin/UserEdit/UserEdit';
import {UserList} from '~/pages/Admin/UserList/UserList';
import {BasicInfo} from '~/pages/Client/BasicInfo/BasicInfo';
import {ClientInfo} from '~/pages/Client/ClientInfo/ClientInfo';
import {ConsentAndAccess} from '~/pages/Client/ConsentAndAccess/ConsentAndAccess';
import {ScreenClient} from '~/pages/Client/ScreenClient/ScreenClient';
import {CorppassCallback} from '~/pages/CorppassCallback/CorppassCallback';
import {SiteResources} from '~/pages/FooterResources/SiteResources';
import {Home} from '~/pages/Home/Home';
import {Login} from '~/pages/Login/Login';
import {DAM2DataAccessMatrixEdit} from '~/pages/Admin/DAM2DataAccessMatrixEdit/DAM2DataAccessMatrixEdit';
import {RejectedAccessHistory} from '~/pages/Admin/RejectedAccessHistory/RejectedAccessHistory';
import {Logout} from '~/pages/Logout/Logout';
import {ScreeningHistory} from '~/pages/ScreeningHistory/ScreeningHistory';
import {Search} from '~/pages/Search/Search';
import {TermsAndConditions} from '~/pages/TermsAndConditions/TermsAndConditions';
import {AnnouncementViewWrapper} from './components/Admin/AnnouncementView/AnnouncementView';
import {ACCESS_LEVELS} from './constants';
import {Announcement} from './pages/Admin/Announcement/Announcement';
import {ConsentEdit} from './pages/Admin/Consent/ConsentEdit';
import {DataAccessDataField} from './pages/Admin/DataAccessDataField/DataAccessDataField';
import {DataAccessManagement} from './pages/Admin/DataAccessManagement/DataAccessManagement';
import {OpsUnits} from './pages/Admin/DataAccessManagement/OpsUnits/OpsUnits';
import {DataAccessRequestDomainPage} from './pages/Admin/DataAccessMatrix2/DataAccessRequestDomainPage';
import {DataAccessRequestHistory} from './pages/Admin/DataAccessRequestHistory/DataAccessRequestHistory';
import {DataAccessRequestWindow} from './pages/Admin/DataAccessRequestWindow/DataAccessRequestWindow';
import {UATViewableDataAccessRequestWindow} from './pages/Admin/DataAccessRequestWindow/UATViewableDataAccessRequestWindow';
import {DataAccessRequestWindowEdit} from './pages/Admin/DataAccessRequestWindowEdit/DataAccessRequestWindowEdit';
import {DataContributorCreate} from './pages/Admin/DataContributorCreate/DataContributorCreate';
import {DataContributorEdit} from './pages/Admin/DataContributorEdit/DataContributorEdit';
import {DataContributorList} from './pages/Admin/DataContributorList/DataContributorList';
import {DataUpload} from './pages/Admin/DataUpload/DataUpload';
import {MassEmail} from './pages/Admin/MassEmail/MassEmail';
import {SystemAdminEdit} from './pages/Admin/SystemAdminEdit/SystemAdminEdit';

import {DataFieldManagement} from './pages/Admin/DataFieldManagement/DataFieldManagement';
import {SystemAdminList} from './pages/Admin/SystemAdminList/SystemAdminList';
import {Notifications} from './pages/Notifications/Notifications';
import {Unauthorized} from './pages/Unauthorized/Unauthorized';

// Use to control page level rendering of pages with non-standard layout
// Make sure the style is defined in styles.scss
export const routeParameters = [
    {path: '/participating-agencies', style: 'globalContainer--showSidebar'},
    {path: '/data-dictionary', style: 'globalContainer--showSidebar'},
    {path: '/getting-started', style: 'globalContainer--showSidebar'},
    {path: '/guides-and-toolkits', style: 'globalContainer--showSidebar'},
    {path: '/release-notes', style: 'globalContainer--showSidebar'},
    {path: '/release-notes/edit/:releaseNoteId', style: 'globalContainer--showSidebar'},
    {path: '/release-notes/create', style: 'globalContainer--showSidebar'},
    {path: '/privacy-and-terms-of-use', style: 'globalContainer--showSidebar'},
    {path: '/website-terms-of-use', style: 'globalContainer--showSidebar'},
    {path: '/undertaking-to-safeguard-confidentiality-of-personal-data', style: 'globalContainer--showSidebar'},
    {path: '/client/:screeningRequestId', style: 'globalContainer--hideFooter'},
];

export interface IRouteProps extends RouteProps {
    checkFeatureFlag?: string;
    showAdminSidebar?: boolean;
    accessRequired?: ACCESS_LEVELS[];
    hideIfFeatureEnable?: string;
}

export const publicRoutes: IRouteProps[] = [
    {component: Login, exact: true, path: '/login'},
    {component: Logout, exact: true, path: '/logout'},
    {component: Unauthorized, exact: true, path: '/unauthorized'},
    {component: ConsentAndAccess, exact: true, path: '/consent'},
    {component: CorppassCallback, exact: true, path: '/corppass-callback'},
    {component: SiteResources, exact: true, path: '/privacy-and-terms-of-use'},
    {component: SiteResources, exact: true, path: '/website-terms-of-use'},
    {component: SiteResources, exact: true, path: '/undertaking-to-safeguard-confidentiality-of-personal-data'},
    // {component: SiteResources, exact: true, path: '/data-dictionary'},
    {component: SiteResources, exact: true, path: '/participating-agencies'},
    {component: SiteResources, exact: true, path: '/release-notes'},
];

// Change this in the future when wanting to allow
// different combination of admins and/or users to
// access certain routes
interface IPrivateRouteAccess {
    [key: string]: ACCESS_LEVELS[];
}

export const groupAccess: IPrivateRouteAccess = {
    ALL_ACCESS: [
        ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.AGENCY_ADMINISTRATOR,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER,
        ACCESS_LEVELS.DATA_UPLOADER,
        ACCESS_LEVELS.DATA_UPLOADER_APP_USER,
        ACCESS_LEVELS.APP_USER,
    ],
    SYSTEM_ADMIN_ACCESS: [ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    ADMIN_ACCESS: [
        ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.AGENCY_ADMINISTRATOR,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER,
    ],
    APP_USER_ACCESS: [
        ACCESS_LEVELS.APP_USER,
        ACCESS_LEVELS.DATA_UPLOADER_APP_USER,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER,
    ],
    NON_ADMIN_ACCESS: [ACCESS_LEVELS.APP_USER, ACCESS_LEVELS.DATA_UPLOADER_APP_USER],
    DATA_UPLOADER_ACCESS: [
        ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.SYSTEM_ADMINISTRATOR,
        ACCESS_LEVELS.AGENCY_ADMINISTRATOR,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR,
        ACCESS_LEVELS.DATA_UPLOADER,
        ACCESS_LEVELS.DATA_UPLOADER_APP_USER,
        ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER,
        ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER,
    ],
};

export const privateRoutes: IRouteProps[] = [
    {component: Notifications, exact: true, path: '/notifications', accessRequired: groupAccess.ALL_ACCESS},
    {
        component: TermsAndConditions,
        exact: true,
        path: '/terms-and-conditions',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {component: Home, exact: true, path: '/'},

    {
        component: Search,
        exact: true,
        path: '/search',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: BasicInfo,
        exact: true,
        path: '/search/basic-information',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: ScreenClient,
        exact: true,
        path: '/search/screen-client',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: ClientInfo,
        exact: true,
        path: '/client/:screeningRequestId',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: Dashboard,
        exact: true,
        path: '/admin/dashboard',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: SystemAdminList,
        exact: true,
        path: '/admin/system-admins',
        accessRequired: [ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR],
        showAdminSidebar: true,
    },
    {
        component: SystemAdminEdit,
        exact: true,
        path: '/admin/system-admins/:sysAdminId',
        accessRequired: [ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR],
        showAdminSidebar: true,
    },
    {
        component: OpsUnitList,
        exact: true,
        path: '/admin/agencies/:agencyId/ops-units',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: OpsUnitEdit,
        exact: true,
        path: '/admin/agencies/:agencyId/ops-units/create',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: OpsUnitEdit,
        exact: true,
        path: '/admin/agencies/:agencyId/ops-units/:opsUnitId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: AgencyEdit,
        exact: true,
        path: '/admin/agencies/:agencyId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: AgencyList,
        exact: true,
        path: '/admin/agencies',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: TeamEdit,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/teams/create',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: TeamEdit,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/teams/:teamId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: TeamList,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/teams',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: OpsUnitUserEdit,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/users/create',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: OpsUnitUserEdit,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/users/:userId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: OpsUnitUserList,
        exact: true,
        path: '/admin/ops-units/:opsUnitId/users',
        accessRequired: [ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER],
        showAdminSidebar: true,
    },
    {
        component: UserEdit,
        exact: true,
        path: '/admin/teams/:teamId/users/create',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: UserEdit,
        exact: true,
        path: '/admin/teams/:teamId/users/:userId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: UserList,
        exact: true,
        path: '/admin/teams/:teamId/users',
        accessRequired: [ACCESS_LEVELS.TEAM_ADMINISTRATOR, ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER],
        showAdminSidebar: true,
    },
    {
        component: TeamEdit,
        exact: true,
        path: '/admin/teams/:teamId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: UserEdit,
        exact: true,
        path: '/admin/users/:userId',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: ScreeningHistory,
        exact: true,
        path: '/my-clients',
        accessRequired: [...groupAccess.APP_USER_ACCESS, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: Report,
        exact: true,
        path: '/reports',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: MassEmail,
        exact: true,
        path: '/admin/mass-email',
        accessRequired: groupAccess.ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: ConsentEdit,
        exact: true,
        path: '/admin/client-consent-history',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: Announcement,
        exact: true,
        path: '/admin/announcements',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: AnnouncementViewWrapper,
        exact: true,
        path: '/admin/announcements/:id',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: SiteResources,
        exact: true,
        path: '/guides-and-toolkits',
        accessRequired: groupAccess.ALL_ACCESS,
    },
    {
        component: SiteResources,
        exact: true,
        path: '/data-dictionary',
        accessRequired: groupAccess.ALL_ACCESS,
    },
    {
        component: SiteResources,
        exact: true,
        path: '/getting-started',
        accessRequired: groupAccess.ALL_ACCESS,
    },
    {
        component: SiteResources,
        exact: true,
        path: '/release-notes/edit/:releaseNoteId',
        accessRequired: [ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: SiteResources,
        exact: true,
        path: '/release-notes/create',
        accessRequired: [ACCESS_LEVELS.SYSTEM_ADMINISTRATOR],
    },
    {
        component: DataUpload,
        exact: true,
        path: '/data-upload',
        accessRequired: groupAccess.DATA_UPLOADER_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: DataContributorList,
        exact: true,
        path: '/admin/data-contributors/',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: DataContributorCreate,
        exact: true,
        path: '/admin/data-contributors/create',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: DataContributorEdit,
        exact: true,
        path: '/admin/data-contributors/edit/:dataContributorId',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: DataAccessMatrix,
        exact: true,
        path: '/admin/data-access-matrix',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
    },
    {
        component: DataAccessMatrixEdit,
        exact: true,
        path: '/admin/data-access-matrix/ops-units/:opsUnitId',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        hideIfFeatureEnable: 'ENABLE_DAM2',
    },
    {
        component: DAM2DataAccessMatrixEdit,
        exact: true,
        path: '/admin/dam2-data-access-matrix/ops-units/:opsUnitId',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: RejectedAccessHistory,
        exact: true,
        path: '/admin/dam2-data-access-matrix/ops-units/:opsUnitId/reject',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: OpsUnits,
        exact: true,
        path: '/admin/data-access-management/ops-units',
        showAdminSidebar: true,
    },
    {
        component: DataAccessManagement,
        exact: true,
        path: '/admin/data-access-management/ops-unit/:opsUnitId',
        showAdminSidebar: true,
    },
    {
        component: DataAccessDataField,
        exact: true,
        path: '/admin/data-access-management/ops-unit/:opsUnitId/domain/:domainId',
        showAdminSidebar: true,
    },
    {
        component: DataAccessRequestWindow,
        exact: true,
        path: '/admin/data-access-request-window',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: DataAccessRequestWindowEdit,
        exact: true,
        path: '/admin/data-access-request-window/create',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: UATViewableDataAccessRequestWindow,
        exact: true,
        path: '/admin/data-access-request-window/simulate',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: DataAccessRequestDomainPage,
        exact: true,
        path: '/admin/v2/data-access-management/ops-unit/:opsUnitId/domain/:domainId',
        accessRequired: groupAccess.OPSUNIT_ADMINISTRATOR,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: DataAccessRequestHistory,
        exact: true,
        path: '/admin/v2/data-access-management/ops-unit/:opsUnitId/domain/:domainId/subdomain/:subdomainId/data-access-request-history',
        accessRequired: groupAccess.OPSUNIT_ADMINISTRATOR,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2',
    },
    {
        component: DataFieldManagement,
        exact: true,
        path: '/admin/data-field-management',
        accessRequired: groupAccess.SYSTEM_ADMIN_ACCESS,
        showAdminSidebar: true,
        checkFeatureFlag: 'ENABLE_DAM2,ENABLE_DAM2_DFM',
    },
];
