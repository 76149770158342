import React from 'react';
import {Grid} from '@mui/material';
import '../Common/Common.scss';
import {getLower} from './HdbAddress';
import {formatCurrency} from '~/utils/currencyUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {isNotEmpty} from '~/utils/contentUtils';
import {IconMessage, ICON_TYPE} from '~/components/Common/IconMessage/IconMessage';

export interface IAnnualValue {
    BlockNumber: string;
    PostalCode: string;
    StreetName: string;
    UnitNumber: string;
    AnnualValueOfProperty: string;
}

export const AnnualValue: React.FunctionComponent<{
    data?: IAnnualValue;
}> = ({data}) => {
    if (!data) return <></>;

    const annualValueOfProperty = () => {
        if (isNotEmpty(data.AnnualValueOfProperty)) {
            const numberValue = +data.AnnualValueOfProperty;
            if (isNaN(numberValue)) {
                return data.AnnualValueOfProperty;
            }
            return `$${formatCurrency(numberValue)}`;
        }
        return data.AnnualValueOfProperty;
    };

    const currentFlatDetails = [
        {label: 'Block / House Number', value: data.BlockNumber},
        {label: 'Street Name', value: getLower(data.StreetName)},
        {label: 'Unit Number', value: getLower(data.UnitNumber)},
        {label: 'Postal Code', value: getLower(data.PostalCode)},
        {label: 'Annual Value of Property', value: annualValueOfProperty()},
    ];

    return (
        <>
            <IconMessage
                data-testid="iconMessage"
                style={{backgroundColor: '#E8EAF6', marginBottom: '24px'}}
                type={ICON_TYPE.BLUE_INFO}
                message="This information shows the details of a property owned by the client, or the property of an address registered with ICA."
            />
            <Grid data-testid="annualValue" container spacing={3} styleName="content">
                <SimpleGridDisplay fields={currentFlatDetails} />
            </Grid>
        </>
    );
};
