import React from 'react';
import {Divider, Grid, Typography} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {ISubDomainFieldDisplay} from '~/interfaces/common';
import {CollapsibleCard} from '~/components/Common/Cards/CollapsibleCard';
import {SubDomainStatus} from '~/interfaces/client';
import {DataNotFound} from '../SubSectionStatusWrapper';
import {FIELD_VALUES, SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {PendingSubDomain} from '../PendingSubDomain';
import {ErrorSubDomain} from '../ErrorSubDomain';
import styles from './Wsg.scss';
import {isNotEmpty, objNoAccess, objNoResults, objNoAccessAndNoResults} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {COLORS} from '~/components/Common/Colors';
import {referralDateComparator, screeningResultsStatusDateComparator} from '~/utils/arrayUtils';

export interface IWSG {
    WsgStockScreening: IWsgStockScreening;
    WsgPlacementScreening: IWsgPlacementScreening;
    WsgNonPlacementScreening: IWsgNonPlacementScreening;
    WsgReferralScreenings: IWsgReferralScreening[];
}
export interface IWsgStockScreening {
    CaseReferenceNumber: string;
    CaseOpenDate: string;
    CaseStatus: string;
    CaseOICName: string;
    CaseOICTouchpoint: string;
    JobReadinessLevel: string;
    EmploymentStatus: string;
    UnemployedSince: string;
    LatestMonthlySalary: string;
    Remarks: string;
}

export interface IWsgPlacementScreening {
    CaseReferenceNumber: string;
    CaseOpenDate: string;
    CaseStatus: string;
    CaseOICName: string;
    CaseOICTouchpoint: string;
    Remarks: string;
    EmployerUENOfPlacedJob: string;
    EmployerNameOfPlacedJob: string;
    EmploymentTypeOfPlacedJob: string;
    MonthlySalaryOfPlacedJob: string;
    SSICCodeOfPlacedJob: string;
    SSICDescriptionOfPlacedJob: string;
    OccupationListOfPlacedJob: string;
    SSOCCodeOfPlacedJob: string;
    PlacedJobTitle: string;
    EmploymentStartDate: string;
}

export interface IWsgNonPlacementScreening {
    CaseReferenceNumber: string;
    CaseOpenDate: string;
    CaseStatus: string;
    CaseOICName: string;
    CaseOICTouchpoint: string;
    JobReferralNumber: string;
    CaseClosureReason: string;
    CaseCloseDate: string;
}

export interface IWsgReferralScreening {
    CaseReferenceNumber: string;
    CaseOpenDate: string;
    CaseStatus: string;
    CaseOICName: string;
    CaseOICTouchpoint: string;
    JobReferralNumber: string;
    JobReferralDate: string;
    EmployerUENOfReferredJob: string;
    EmployerNameOfReferredJob: string;
    MonthlySalaryOfReferredJob: string;
    SSICCodeOfReferredJob: string;
    SSICDescriptionOfReferredJob: string;
    OccupationListOfReferredJob: string;
    SSOCCodeOfReferredJob: string;
    JobReferralJobTitle: string;
    JobReferralStatus: string;
    JobReferralUnsuccessfulReason: string;
    JobReferralCreatedBy: string;
    JobReferralCreatedTouchpoint: string;
    JobReferralRemarks: string;
}

export const WsgTypes = {
    stock: 'Stock',
    placement: 'Placement',
    nonplacement: 'Non-Placement',
    referral: 'Referral',
};

const formatRemarks = (remarksArray: string[]) => {
    return (
        <ul style={{padding: '0px'}}>
            {remarksArray.map((remarks, index) => {
                return <li key={index}>{remarks}</li>;
            })}
        </ul>
    );
};

export const SectionContent = ({fields}: {fields: ISubDomainFieldDisplay[]}) => (
    <Grid container spacing={2}>
        <SimpleGridDisplay fields={fields} />
    </Grid>
);

const sectionHasContent = (content: ISubDomainFieldDisplay[]) => {
    return content && content.filter((item) => item.value === FIELD_VALUES.NO_RESULTS).length !== content.length;
};

const CaseContent = ({wsgCase, 'data-testid': dataTestId}: {wsgCase: IWSG; 'data-testid'?: string}) => {
    const wsgStock = wsgCase.WsgStockScreening;
    const statusColor = wsgStock.CaseStatus === 'Open' ? COLORS.GREEN : COLORS.BLACK;
    const wsgStockRemarks = wsgStock.Remarks.split(/\r?\n/)
        .map((element) => element.trim())
        .filter((element) => element !== '');
    const generic = [
        {
            label: 'Case Reference Number',
            value: isNotEmpty(wsgStock.CaseReferenceNumber) ? (
                <Typography data-testid="case-ref-title" variant="h6">
                    {wsgStock.CaseReferenceNumber}
                </Typography>
            ) : (
                wsgStock.CaseReferenceNumber
            ),
        },
        {label: '', value: ''},
        {
            label: 'Case Open Date',
            value: wsgStock.CaseOpenDate,
        },
        {
            label: 'Case Status',
            value: isNotEmpty(wsgStock.CaseStatus) ? (
                <p style={{display: 'inline', color: statusColor}}>{wsgStock.CaseStatus}</p>
            ) : (
                wsgStock.CaseStatus
            ),
        },
        {
            label: `Case OIC's Name`,
            value: wsgStock.CaseOICName,
        },
        {
            label: `Case OIC's Touchpoint`,
            value: wsgStock.CaseOICTouchpoint,
        },
        {
            label: 'Job Readiness Level',
            value: wsgStock.JobReadinessLevel,
        },
        {
            label: 'Employment Status',
            value: wsgStock.EmploymentStatus,
        },
        {
            label: 'Unemployed Since',
            value: wsgStock.UnemployedSince,
        },
        {
            label: 'Latest Monthly Salary (SGD)',
            value: wsgStock.LatestMonthlySalary,
        },
        {
            label: 'Remarks',
            value: wsgStockRemarks.length <= 1 ? wsgStock.Remarks : formatRemarks(wsgStockRemarks),
            fullWidth: true,
        },
    ];

    let closedCasePlacementDetails = [
        {
            label: '',
            value: FIELD_VALUES.NO_RESULTS as string,
        },
    ];
    let closedCaseNonPlacementDetails = [
        {
            label: '',
            value: FIELD_VALUES.NO_RESULTS as string,
        },
    ];

    if (!objNoAccessAndNoResults(wsgCase.WsgPlacementScreening)) {
        const wsgPlacement = wsgCase.WsgPlacementScreening;
        closedCasePlacementDetails = [
            {
                label: 'Employer UEN Of Placed Job',
                value: wsgPlacement.EmployerUENOfPlacedJob,
            },
            {
                label: 'Employer Name Of Placed Job',
                value: wsgPlacement.EmployerNameOfPlacedJob,
            },
            {
                label: 'Employer Type Of Placed Job',
                value: wsgPlacement.EmploymentTypeOfPlacedJob,
            },
            {
                label: 'Monthly Salary Of Placed Job',
                value: wsgPlacement.MonthlySalaryOfPlacedJob,
            },
            {
                label: 'SSIC Code Of Placed Job',
                value: wsgPlacement.SSICCodeOfPlacedJob,
            },
            {
                label: 'SSIC Description Of Placed Job',
                value: wsgPlacement.SSICDescriptionOfPlacedJob,
            },
            {
                label: 'Occupation List Of Placed Job',
                value: wsgPlacement.OccupationListOfPlacedJob,
            },
            {
                label: 'SSOC Code Of Placed Job',
                value: wsgPlacement.SSOCCodeOfPlacedJob,
            },
            {
                label: 'Placed Job Title',
                value: wsgPlacement.PlacedJobTitle,
            },
            {
                label: 'Employment Start Date',
                value: wsgPlacement.EmploymentStartDate,
            },
        ];
    }
    if (!objNoAccessAndNoResults(wsgCase.WsgNonPlacementScreening)) {
        const wsgNonPlacement = wsgCase.WsgNonPlacementScreening;
        closedCaseNonPlacementDetails = [
            {
                label: 'Job Referral Number',
                value: wsgNonPlacement.JobReferralNumber,
            },
            {
                label: 'Case Closure Reason',
                value: wsgNonPlacement.CaseClosureReason,
            },
            {
                label: 'Case Closure Date',
                value: wsgNonPlacement.CaseCloseDate,
            },
        ];
    }

    const processReferralDetails = (wsgCase: IWsgReferralScreening) => {
        return [
            {
                label: 'Job Referral Number',
                value: wsgCase.JobReferralNumber,
            },
            {
                label: 'Job Referral Date',
                value: wsgCase.JobReferralDate,
            },
            {
                label: 'Employer UEN Of Referred Job',
                value: wsgCase.EmployerUENOfReferredJob,
            },
            {
                label: 'Employer Name Of Referred Job',
                value: wsgCase.EmployerNameOfReferredJob,
            },
            {
                label: 'Monthly Salary Of Referred Job',
                value: wsgCase.MonthlySalaryOfReferredJob,
            },
            {
                label: 'SSIC Code Of Referred Job',
                value: wsgCase.SSICCodeOfReferredJob,
            },
            {
                label: 'SSIC Description Of Referred Job',
                value: wsgCase.SSICDescriptionOfReferredJob,
            },
            {
                label: 'Occupation List Of Referred Job',
                value: wsgCase.OccupationListOfReferredJob,
            },
            {
                label: 'SSOC Code Of Referred Job',
                value: wsgCase.SSOCCodeOfReferredJob,
            },
            {
                label: 'Job Referral Job Title',
                value: wsgCase.JobReferralJobTitle,
            },
            {
                label: 'Job Referral Status',
                value: wsgCase.JobReferralStatus,
            },
            {
                label: 'Job Referral Unsuccessful Reason',
                value: wsgCase.JobReferralUnsuccessfulReason,
            },
            {
                label: 'Job Referral Created By',
                value: wsgCase.JobReferralCreatedBy,
            },
            {
                label: 'Job Referral Created Touchpoint',
                value: wsgCase.JobReferralCreatedTouchpoint,
            },
            {
                label: 'Job Referral Remarks',
                value: wsgCase.JobReferralRemarks,
            },
        ];
    };

    const CompulsorySection = (
        <>
            {sectionHasContent(generic) && (
                <Subsection
                    data-testid="sectionGeneric"
                    title=""
                    hasData={true}
                    status={SubDomainStatus.COMPLETED}
                    withDivider={false}
                >
                    <SectionContent fields={generic} />
                </Subsection>
            )}
        </>
    );

    return (
        <CollapsibleCard compulsorySection={CompulsorySection} dataTestId={dataTestId}>
            {(sectionHasContent(closedCaseNonPlacementDetails) ||
                sectionHasContent(closedCasePlacementDetails) ||
                !objNoAccessAndNoResults(wsgCase.WsgReferralScreenings)) && (
                <>
                    {sectionHasContent(closedCasePlacementDetails) && (
                        <Subsection
                            data-testid="sectionClosedCasePlacementDetail"
                            title={`Placement Details`}
                            hasData={true}
                            status={SubDomainStatus.COMPLETED}
                        >
                            <SectionContent fields={closedCasePlacementDetails} />
                        </Subsection>
                    )}
                    {sectionHasContent(closedCaseNonPlacementDetails) && (
                        <Subsection
                            data-testid="sectionClosedCaseNonPlacementDetail"
                            title={`Non-Placement Details`}
                            hasData={true}
                            status={SubDomainStatus.COMPLETED}
                        >
                            <SectionContent fields={closedCaseNonPlacementDetails} />
                        </Subsection>
                    )}
                    {!objNoAccessAndNoResults(wsgCase.WsgReferralScreenings)
                        ? wsgCase.WsgReferralScreenings.slice()
                              .sort(referralDateComparator)
                              .map((referralDetail, key) => (
                                  <Subsection
                                      data-testid={`sectionReferralDetail-${key}`}
                                      title={`Job Referral Details ${key + 1}`}
                                      hasData={true}
                                      status={SubDomainStatus.COMPLETED}
                                      key={key}
                                  >
                                      <SectionContent fields={processReferralDetails(referralDetail)} />
                                  </Subsection>
                              ))
                        : null}
                </>
            )}
        </CollapsibleCard>
    );
};

export const WsgCases: React.FunctionComponent<{data: IWSG[]}> = ({data}) => {
    return !objNoAccess(data) ? (
        objNoResults(data) ? (
            <NoData />
        ) : (
            <>
                {data.sort(screeningResultsStatusDateComparator).map((wsgCase, id) => {
                    return (
                        <div key={id}>
                            {objNoResults(wsgCase) ? null : (
                                <CaseContent
                                    data-testid={`case-content-${wsgCase.WsgStockScreening.CaseReferenceNumber}`}
                                    wsgCase={wsgCase}
                                />
                            )}
                        </div>
                    );
                })}
            </>
        )
    ) : null;
};

export const Subsection = ({
    children,
    title,
    hasData,
    status = SubDomainStatus.ERROR,
    withDivider = true,
    'data-testid': dataTestId,
}: {
    children?: React.ReactNode;
    title: string;
    hasData?: boolean;
    status?: SubDomainStatus;
    withDivider?: boolean;
    'data-testid'?: string;
}) => {
    return (
        <>
            <Grid data-testid={dataTestId} item xs={12}>
                {withDivider && (
                    <Grid data-testid="divider" item xs={12}>
                        <Divider
                            style={{
                                marginTop: 10,
                                marginBottom: 20,
                            }}
                        />
                    </Grid>
                )}
                <Typography data-testid="title" variant="h6">
                    {title}
                </Typography>
                <Grid data-testid="content" id={styles.subsectionContent}>
                    {status === SubDomainStatus.COMPLETED ? (
                        <>
                            {hasData && children ? (
                                children
                            ) : (
                                <DataNotFound text={SUBDOMAIN_RESULT_MESSAGE.EMPTY} data-testid="nodata" />
                            )}
                        </>
                    ) : status === SubDomainStatus.PENDING ? (
                        <PendingSubDomain data-testid="pendingSubdomain" includeHeader={false} />
                    ) : (
                        <ErrorSubDomain data-testid="errorSubdomain" includeHeader={false} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
