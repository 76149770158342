import React from 'react';
import {Button} from '@mui/material';
import {ArrowBackRounded as ArrowBackIcon} from '@mui/icons-material';

interface IBackButton {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label?: string;
}

export const BackButton = ({onClick, label = 'Back'}: IBackButton) => {
    return (
        <Button type="submit" variant="text" color="primary" onClick={onClick} style={{paddingLeft: 0}}>
            <ArrowBackIcon style={{marginRight: 15}} />
            {label}
        </Button>
    );
};
