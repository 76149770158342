import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Typography, Grid, Box, IconButton} from '@mui/material';
import {IMonthlyReviewResponse, IUser} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import {LOADING_MESSAGE, MONTHLY_REVIEW_STATUS} from '~/constants';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';

import {useGrowl} from '~/utils/hooks/useGrowl';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getSystemAdminFetchState} from '~/store/fetchState/fetchState.selector';

import './SystemAdminList.scss';
import {setSystemAdmins} from '~/store/systemAdmin/systemAdmin.thunk';
import {TableButton} from '~/components/Common/Button/TableButton';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {formatDateMonthName} from '~/utils/dateUtils';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {format} from 'date-fns';
import {createMonthlyAdminReview, getRequiresMonthlyAdminReview} from '~/services/adminServices';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {CheckCircle, Close} from '@mui/icons-material';

export const SystemAdminList = () => {
    const systemAdmins = Object.values(useTypedSelector((state) => state.data.admin.systemAdmins.systemAdmins));
    const dispatch = useAppDispatch();

    const history = useHistory();
    const systemAdminFetchState = useTypedSelector(getSystemAdminFetchState);
    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const fetchStatus = consolidateFetchState([systemAdminFetchState]);
    const handleEdit = (systemAdminId: number) => {
        history.push(`/admin/system-admins/${systemAdminId}`);
    };

    const handleClick = () => {
        history.push(`/admin/system-admins/create`);
    };

    const handleReviewSubmit = () => {
        createMonthlyAdminReview()
            .then(() => {
                openGrowl('Your review has been submitted successfully');
                getRequiresMonthlyAdminReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    useEffect(() => {
        dispatch(setSystemAdmins());
        getRequiresMonthlyAdminReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const cellContent = (mainText: string, subtitle: string, isActive?: boolean) => (
        <Box component="div">
            <Box component="div" styleName="nameWrapper">
                {mainText}
            </Box>
            <Box component="div" styleName="designationWrapper">
                {subtitle}
            </Box>
            {isActive !== undefined && !isActive && <SuspendedIcon />}
        </Box>
    );

    const getSystemAdminListContent = () => {
        const domainHeaderRef = useRef<HTMLDivElement>(null);
        const [searchValue, setSearchValue] = useState('');
        const filteredSystemAdmins = systemAdmins.filter(
            (systemAdmin) => systemAdmin.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
        );

        const systemAdminName = (systemAdmin: IUser) => systemAdmin.Name;
        const lastUpdatedByName = (systemAdmin: IUser) =>
            systemAdmin.UpdatedByUser ? systemAdmin.UpdatedByUser.Name : '';
        const lastUpdatedByDateValue = (systemAdmin: IUser) =>
            systemAdmin.UpdatedAt ? systemAdmin.UpdatedAt : new Date();

        const systemAdminDetails = (systemAdmin: IUser) =>
            cellContent(systemAdmin.Name, systemAdmin.Designation, systemAdmin.IsActive);
        const lastUpdatedBy = (systemAdmin: IUser) =>
            systemAdmin.UpdatedByUser
                ? cellContent(systemAdmin.UpdatedByUser.Name, systemAdmin.UpdatedByUser.Designation)
                : '-';
        const lastUpdatedAt = (systemAdmin: IUser) =>
            systemAdmin.UpdatedAt
                ? cellContent(
                      formatDateMonthName(`${systemAdmin.UpdatedAt}`),
                      format(new Date(`${systemAdmin.UpdatedAt}`), 'h:mmaa'),
                  )
                : cellContent(
                      formatDateMonthName(`${systemAdmin.CreatedAt}`),
                      format(new Date(`${systemAdmin.CreatedAt}`), 'h:mmaa'),
                  );
        const systemAdminEditButton = (systemAdmin: IUser) => (
            <TableButton text="Edit" onClick={() => handleEdit(systemAdmin.Id as number)} />
        );

        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            SA Management
                        </Typography>
                    </Grid>
                    {monthlyReviewStatus &&
                        monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW &&
                        systemAdmins.length > 0 && (
                            <ReviewBanner
                                monthlyReviewStatus={monthlyReviewStatus}
                                handleReviewSubmit={handleReviewSubmit}
                            />
                        )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="systemadmins-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search System Admin"
                                type="search"
                                style={{width: '300px'}}
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary
                                text="Add System Admin"
                                id="add-system-admin-button"
                                width={240}
                                onClick={handleClick}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {systemAdmins.length > 0 ? (
                            <ListModel
                                columns={[systemAdminDetails, lastUpdatedBy, lastUpdatedAt, systemAdminEditButton]}
                                headers={['System Admin', 'Last Updated By', 'Date Updated', '']}
                                modelList={filteredSystemAdmins}
                                modelComparator={[
                                    getAlphabeticalComparator(systemAdminName),
                                    getAlphabeticalComparator(lastUpdatedByName),
                                    getDateComparator(lastUpdatedByDateValue),
                                    () => 0,
                                ]}
                                variant="paginated"
                                hover
                            />
                        ) : (
                            <EmptyList entity="System Admin" handleClick={handleClick} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: null,
                            message:
                                growl.message.length > 0 ? (
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" alignItems="center" marginRight={1.75}>
                                            <CheckCircle />
                                        </Box>

                                        {growl.message}
                                    </Box>
                                ) : undefined,
                            action: (
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>
            </>
        );
    };

    const SystemAdminListWithAdminLoader = WithAdminLoader<{}>(
        getSystemAdminListContent,
        fetchStatus,
        LOADING_MESSAGE.DATA,
    );
    return <SystemAdminListWithAdminLoader />;
};
