import React from 'react';
import {Grid, Typography, Divider} from '@mui/material';
import './RehadAndProtection.scss';
import {formatDate} from '~/utils/dateUtils';
import {allNoResults, extractAllValues, isNotEmpty, renderIfHasAccess} from '~/utils/contentUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {NoData} from '../CompletedSubDomain';

export type IRehabAndProtectionData = IRehabAndProtectionCase[];

interface IRehabAndProtectionCase {
    CaseOfficer: string;
    CaseStatus: string;
    CaseType: string;
    StartDate: string;
    EndDate: string;
    CurrentStatus: string;
    HasFamilyViolence: string;
    ServiceProvider: string;
    ParticipantRole: string;
    Relationship: string;
}

export const RehabAndProtection = ({data}: {data: IRehabAndProtectionData}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    const date = (date: string) => {
        const dateParts = date.split('/');
        return formatDate(new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`), true, true);
    };
    return (
        <>
            {data.map((rpgCase, index) => {
                const fields = [
                    {label: 'Service Provider', value: rpgCase.ServiceProvider},
                    {label: 'Case Status', value: rpgCase.CaseStatus},
                    {
                        label: 'Start Date',
                        value: isNotEmpty(rpgCase.StartDate) ? date(rpgCase.StartDate) : rpgCase.StartDate,
                    },
                    {
                        label: 'End Date',
                        value: isNotEmpty(rpgCase.EndDate) ? date(rpgCase.EndDate) : rpgCase.EndDate,
                    },
                    {label: 'Name of Handling Officer', value: rpgCase.CaseOfficer},
                    {
                        label: 'Indicator of Family Violence',
                        value:
                            rpgCase.HasFamilyViolence === 'true'
                                ? 'Yes'
                                : rpgCase.HasFamilyViolence === 'false'
                                ? 'No'
                                : rpgCase.HasFamilyViolence,
                    },
                    {label: 'Current Status', value: rpgCase.CurrentStatus},
                    {label: 'Case Participant Role', value: rpgCase.ParticipantRole},
                    {label: 'Role Played', value: rpgCase.Relationship},
                ];

                return (
                    <React.Fragment key={index}>
                        {noData ? (
                            <NoData />
                        ) : (
                            <Grid data-testid={`rehab${index}`} key={index} container spacing={3}>
                                <Grid item xs={12}>
                                    {renderIfHasAccess(
                                        rpgCase.CaseType,
                                        <>
                                            <Typography variant="body2" styleName="label">
                                                Programme / Case Type
                                            </Typography>
                                            <Typography variant="h6">{rpgCase.CaseType}</Typography>
                                        </>,
                                    )}
                                </Grid>
                                <SimpleGridDisplay key={index} fields={fields} />
                                {index + 1 < data.length && (
                                    <Grid item xs={12}>
                                        <Divider
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 20,
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};
