import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE} from '~/constants';
import * as apiServices from '~/services/apiServices';
import {RootState} from '../index';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {IDomain} from '~/interfaces/common';

export const setDomains = createAsyncThunk<IDomain[] | void, void, {state: RootState}>(
    '/domains/setDomains',
    async (_, {getState, dispatch}) => {
        if (!getState().data.flags[Flag.DOMAIN]) {
            dispatch({type: fetchStateActions.SET_DOMAINS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const domains = await apiServices.getDomains();
                dispatch({type: fetchStateActions.SET_DOMAINS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
                dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.DOMAIN});
                return domains;
            } catch (err) {
                dispatch({type: fetchStateActions.SET_DOMAINS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
                throw err;
            }
        }
    },
);
