import React from 'react';
import {Button, Typography, Grid} from '@mui/material';

import './EmptyList.scss';
import addEntity from '~/assets/images/addEntity.svg';

interface IEmptyList {
    entity: string;
    handleClick: () => void;
}

export const EmptyList = ({entity, handleClick}: IEmptyList) => {
    let subtitle;
    if (entity == 'System Admin') {
        subtitle = `You will be able to view the list of created System Admins`;
    } else if (entity == 'Agency') {
        subtitle = `You will be able to view the list of created Agencies and their assigned admins`;
    } else if (entity == 'User') {
        subtitle = `You will be able to view the list of created users`;
    } else {
        subtitle = `You will be able to view the list of created ${entity}s and their assigned admins`;
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
                <img src={addEntity} alt={`Add ${entity}`} onClick={handleClick} />
            </Grid>
            <Grid item>
                <Typography component="h1" variant="h4">
                    {`Add your first ${entity}`}
                </Typography>
            </Grid>
            <Grid item>
                <Typography component="h1" variant="subtitle1">
                    {subtitle}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={handleClick} styleName="addEntityButton">
                    {`Add ${entity}`}
                </Button>
            </Grid>
        </Grid>
    );
};
