import React from 'react';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {Grid} from '@mui/material';
import {extractAllValues, allNoResults} from '~/utils/contentUtils';
import {NoData} from '../../CompletedSubDomain';

export interface IPrivateDwellingData {
    PrivateResidentialPropertyInd: string;
}

export const PrivateDwelling: React.FunctionComponent<{data: IPrivateDwellingData}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    const privateDwelling = [
        {
            label: 'Indicator of Ownership of Private Residential Property',
            value: data.PrivateResidentialPropertyInd,
        },
    ];

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <Grid data-testid="privateDwelling" container spacing={3}>
                    <SimpleGridDisplay fields={privateDwelling} />
                </Grid>
            )}
        </>
    );
};
