import {useState, useEffect} from 'react';
import Emitter, {EVENT_TYPE} from '~/utils/events/emitter';
import {useUpdateWigetSetting} from '~/utils/hooks/useUpdateWidgetSetting';
import {config} from '~/config';
import {isPageAtBottom} from '~/utils/pageUtils';
import {useLocation} from 'react-router-dom';

export default function usePageBottom() {
    const [bottom, setBottom] = useState<boolean>(false);
    const {updateWidgetVerticalOffset} = useUpdateWigetSetting();
    const location = useLocation();
    const isClientInfoView = location.pathname.substring(1, 7) === 'client';

    useEffect(() => {
        const handleScroll = () => {
            setBottom(
                window.innerHeight + document.documentElement.scrollTop + 15 > document.documentElement.offsetHeight,
            );

            if (config.internetEnabled && !isClientInfoView) {
                isPageAtBottom() ? updateWidgetVerticalOffset('170px') : updateWidgetVerticalOffset('75px');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function resetBottom() {
            setBottom(false);
        }
        Emitter.on(EVENT_TYPE.RESET_BOTTOM, resetBottom);
        return () => {
            Emitter.off(EVENT_TYPE.RESET_BOTTOM, resetBottom);
        };
    });

    return {isPageBottom: bottom};
}
