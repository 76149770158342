import React, {useState, useEffect, useRef} from 'react';
import {Checkbox, List, ListItem, Typography, Collapse} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {useOnScreen} from '~/utils/hooks/useOnScreen';

import {Fields} from './Fields';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    getSubDomainMatrixCheckedState,
    getSubDomainMatrixIndeterminateState,
} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {dataAccessMatrixActions} from '~/store/dataAccessMatrix/dataAccessMatrix.slice';

import './DataAccessMatrixEdit.scss';

export interface ISubDomainProps {
    subDomain: IDataAccessMatrixSubDomain;
    expandAll: boolean;
    opsUnitId: number;
    domainId: number;
}

export interface ISubDomainHeaderProps {
    subDomain: IDataAccessMatrixSubDomain;
    expand: boolean;
    setExpand: React.Dispatch<React.SetStateAction<boolean>>;
    opsUnitId: number;
    domainId: number;
}

export const SubDomainHeader = ({subDomain, expand, setExpand, opsUnitId, domainId}: ISubDomainHeaderProps) => {
    const dispatch = useAppDispatch();
    const handleClickSubDomain = () => {
        setExpand(!expand);
    };

    const isChecked = useTypedSelector(getSubDomainMatrixCheckedState(opsUnitId, domainId, subDomain.Id));
    const isIndeterminate = useTypedSelector(getSubDomainMatrixIndeterminateState(opsUnitId, domainId, subDomain.Id));

    const subDomainCheckBoxChangeHandler = () => {
        const payload = subDomain.DataFields.map(({Id}) => ({
            opsUnitId,
            domainId,
            subDomainId: subDomain.Id,
            fieldId: Id,
            selected: !isChecked,
        }));

        dispatch(dataAccessMatrixActions.SET_GROUPED_FIELDS(payload));
    };

    return (
        <ListItem button onClick={handleClickSubDomain} styleName="expandHeader">
            <Typography variant="body1" styleName="expandTitle">
                <Checkbox
                    color={'primary'}
                    disableRipple
                    styleName="subDomainCheckbox"
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={subDomainCheckBoxChangeHandler}
                />
                {subDomain.Name}
                <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
            </Typography>
        </ListItem>
    );
};

const FieldsMemo = React.memo(Fields);
export const SubDomain = ({subDomain, expandAll, opsUnitId, domainId}: ISubDomainProps) => {
    const intersectionRef = useRef(null);
    const {isOnScreen} = useOnScreen(intersectionRef);

    const [expand, setExpand] = useState(true);

    useEffect(() => {
        setExpand(expandAll);
    }, [expandAll]);

    return (
        <List style={{padding: '0'}} ref={intersectionRef}>
            <SubDomainHeader
                subDomain={subDomain}
                expand={expand}
                setExpand={setExpand}
                opsUnitId={opsUnitId}
                domainId={domainId}
            />

            <Collapse in={expand} timeout={500}>
                {isOnScreen && subDomain.DataFields && (
                    <FieldsMemo
                        rows={subDomain.DataFields}
                        opsUnitId={opsUnitId}
                        domainId={domainId}
                        subDomainId={subDomain.Id}
                    />
                )}
            </Collapse>
        </List>
    );
};
