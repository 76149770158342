import {Edit, FileDownloadOutlined, SearchRounded as SearchIcon} from '@mui/icons-material';
import {Box, Button, Chip, Grid, InputAdornment, Typography, List, ListItem} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {uniq} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DomainHeader} from '~/components/Admin/DataManagement/DomainHeader/DomainHeader';
import {RequestStatus} from '~/components/Admin/RequestStatus/RequestStatus';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {BackButton} from '~/components/Common/Button/BackButton';
import {InputField} from '~/components/Common/InputField/InputField';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import {config} from '~/config';
import {ACCESS_LEVELS, API_FETCH_STATE, COPIES, LOADING_MESSAGE, REPORT_TYPE} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    ICurrentRequestWindow,
    IDataAccessMatrixDomain,
    IDomainReqStatus,
    IRequestWindow,
    ISubdomainLatestStatus,
} from '~/interfaces/admin';
import {IFetchStatus} from '~/interfaces/common';
import {getSubdomainsWithNewFields} from '~/services/adminServices';
import {getOpsUnitDomainRequestStatus, getOpsUnitSubdomainLatestStatus} from '~/services/dataAccessMatrixService';
import {getOpsUnitReasonForAccess} from '~/services/opsUnitServices';
import {getReports} from '~/services/reportServices';
import {getCurrentRequestWindow} from '~/services/requestWindowServices';
import {getDomains} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {getDamFieldsFetchState} from '~/store/fetchState/fetchState.selector';
import {getOpsUnits} from '~/store/opsUnit/opsUnit.selector';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {formatDateMonthName} from '~/utils/dateUtils';
import {nextLineToParagraphs} from '~/utils/nextLineToParagraphUtils';
import btnStyle from '../../../components/Common/Button/Button.scss';
import {IReportRequest} from '../Reports/Report';
import style from './DataAccessManagement.scss';
import {RequestWindowInfoBanner} from './RequestWindowInfoBanner/RequestWindowInfoBanner';

const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customCellStyle = (_domain: ISubdomainRow, index?: number | undefined) => {
    // 40% is the width of the first row and 20% is for the remaining columns
    const widths = ['40%', '20%'];
    if (index !== undefined) {
        return {width: widths[index]};
    }
    return {};
};

const customCellStyle2 = (wids: [string, string]) => (_domain: ISubdomainRow, index?: number | undefined) => {
    if (index !== undefined) {
        return {width: wids[index]};
    }
    return {};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customerHeaderCellStyle = (__data: React.ReactNode) => ({
    backgroundColor: '#3949ab',
    color: '#ffffff',
    paddingLeft: '32px',
});

export interface ISubdomainRow {
    Id: number;
    Name: string;
    LastUpdatedBy?: string;
    LastUpdaetdAt?: string;
}

export const DataAccessManagement = () => {
    const {accessToken} = useContext(AuthenticationContext);
    const isAgencyAdmin = accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR;
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const opsUnits = Object.values(useTypedSelector(getOpsUnits(accessToken?.Permissions.AgencyId)));
    const [opsUnit] = opsUnits.filter((unit) => unit.Id === Number(opsUnitId));
    const [disableBtn, setDisableBtn] = useState(false);
    const dispatch = useAppDispatch();
    const domains: IDataAccessMatrixDomain[] = useTypedSelector(getDomains);
    const handleDownloadReport = () => {
        setDisableBtn(true);
        setPasswordModalOpen(true);
    };
    const isOpsAdmin =
        accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR ||
        accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER;

    //Declaring all the use states for API calls
    const damFieldsFetchStatus = useTypedSelector(getDamFieldsFetchState);
    const [requestWindowInfoStatus, setRequestWindowInfoStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const [currRequestWindow, setCurrRequestWindow] = useState<IRequestWindow>();
    const [subdomainsWithNew, setSubdomainsWithNew] = useState<number[]>([]);
    const [subdomainsWithNewStatus, setSubdomainsWithNewStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const [opsUnitReason, setOpsUnitReason] = useState<string>('');
    const [opsUnitReasonStatus, setOpsUnitReasonStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const [subdomainLatestUpdate, setSubdomainLatestUpdate] = useState<Map<number, ISubdomainLatestStatus>>(new Map());
    const [subdomainLatestUpdateStatus, setSubdomainLatestUpdateStatus] = useState<IFetchStatus>(
        API_FETCH_STATE.PENDING,
    );
    const [requestState, setRequestState] = useState<Map<number, IDomainReqStatus>>(new Map());
    const [requestStateStatus, setRequestStateStatus] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);

    //Delcaring combinedFetchStatus that tracks all the API calls collectively
    const statuses = [
        damFieldsFetchStatus,
        requestWindowInfoStatus,
        subdomainsWithNewStatus,
        opsUnitReasonStatus,
        subdomainLatestUpdateStatus,
        requestStateStatus,
    ];
    function getCombinedStatus(statuses: IFetchStatus[]) {
        if (statuses.some((e) => e === API_FETCH_STATE.ERROR)) {
            return API_FETCH_STATE.ERROR;
        } else if (statuses.every((e) => e === API_FETCH_STATE.SUCCESS)) {
            return API_FETCH_STATE.SUCCESS;
        }
        return API_FETCH_STATE.PENDING;
    }
    const combinedFetchStatus = getCombinedStatus(statuses);

    //API call to get current request window on first load
    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2) {
            setRequestWindowInfoStatus(API_FETCH_STATE.SUCCESS);
            return;
        }

        if (!isOpsAdmin) {
            setRequestWindowInfoStatus(API_FETCH_STATE.SUCCESS);
            return;
        }
        getCurrentRequestWindow()
            .then((resp: ICurrentRequestWindow) => {
                resp.CurrentWindowPeriod &&
                    setCurrRequestWindow({
                        ...resp.CurrentWindowPeriod,
                    });

                setRequestWindowInfoStatus(API_FETCH_STATE.SUCCESS);
            })
            .catch(() => {
                setRequestWindowInfoStatus(API_FETCH_STATE.ERROR);
            });
    }, []);

    //API calls to get:
    //i. Subdomain with New Fields (rendering of new field chip)
    //ii. Get OpsUnit reason for accessing OneCV
    ///iii, Get OpsUnit last updated by/at for each subdomain
    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2) {
            setSubdomainsWithNewStatus(API_FETCH_STATE.SUCCESS);
            setOpsUnitReasonStatus(API_FETCH_STATE.SUCCESS);
            setSubdomainLatestUpdateStatus(API_FETCH_STATE.SUCCESS);
            setRequestStateStatus(API_FETCH_STATE.SUCCESS);
            return;
        }

        getSubdomainsWithNewFields()
            .then((resp: number[]) => {
                setSubdomainsWithNew(resp);
                setSubdomainsWithNewStatus(API_FETCH_STATE.SUCCESS);
            })
            .catch(() => {
                setSubdomainsWithNewStatus(API_FETCH_STATE.ERROR);
            });

        getOpsUnitReasonForAccess(opsUnitId)
            .then((resp: string) => {
                setOpsUnitReason(resp);
                setOpsUnitReasonStatus(API_FETCH_STATE.SUCCESS);
            })
            .catch(() => {
                setOpsUnitReasonStatus(API_FETCH_STATE.ERROR);
            });

        getOpsUnitSubdomainLatestStatus(opsUnitId)
            .then((resp: ISubdomainLatestStatus[]) => {
                const subdomainLatestUpdateMap = new Map(
                    resp.map((obj) => {
                        return [obj.SubdomainId, obj];
                    }),
                );

                setSubdomainLatestUpdate(subdomainLatestUpdateMap);
                setSubdomainLatestUpdateStatus(API_FETCH_STATE.SUCCESS);
            })
            .catch(() => {
                setSubdomainLatestUpdateStatus(API_FETCH_STATE.ERROR);
            });

        getOpsUnitDomainRequestStatus(opsUnitId)
            .then((resp: IDomainReqStatus[]) => {
                const domainReqStatusMap = new Map(
                    resp.map((obj) => {
                        return [obj.DomainId, obj];
                    }),
                );

                setRequestState(domainReqStatusMap);
                setRequestStateStatus(API_FETCH_STATE.SUCCESS);
            })
            .catch(() => {
                setRequestStateStatus(API_FETCH_STATE.ERROR);
            });
    }, []);

    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2_VIEW) {
            history.back();
        }
        dispatch(setDataAccessMatrix());
    }, []);

    const handlePasswordModalClose = () => {
        setPasswordModalOpen(false);
        setDisableBtn(false);
    };

    const handlePasswordSubmit = async (password: string) => {
        const reportRequest: IReportRequest = {
            Reports: [
                {
                    Id: 0,
                    Type: REPORT_TYPE.DAM_REPORT_OA,
                    Entity: {EntityId: parseInt(opsUnitId), EntityType: 'OpsUnit'},
                    Name: 'DAM Report',
                    Description: 'Data Access Matrix by Ops Units',
                    Selected: true,
                },
            ],
            Password: password,
        };
        await getReports(reportRequest);
    };

    // Main logic to render the DAM page
    // Nested as a function in order to pass it as an argument to `WithAdminLoader`
    const getDamContent = () => {
        const [searchValue, setSearchValue] = useState('');
        const [filteredDomains, setFilteredDomains] = useState<IDataAccessMatrixDomain[]>(domains);
        const [domainOptions, setDomainOptions] = useState<string[]>([]);
        const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
        const domainFilterClasses = useDomainFilterStyles();

        //On first refresh, get all the domains to be used for the filter component
        useEffect(() => {
            if (domains.length > 0) {
                if (domainOptions.length === 0) {
                    setDomainOptions(uniq(domains.map((row) => row.Name)));
                }
            }
        }, []);

        //helper useEffect for searchValue updating
        useEffect(() => {
            const newDomains = domains.filter((domain) => {
                return (
                    domain.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    domain.SubDomains.some((subdomain) => {
                        return subdomain.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
                    })
                );
            });
            setFilteredDomains(newDomains);
        }, [searchValue]);

        //helper functitons for fitlering
        const filterSelectedDomains = (selectedDomains: string[]): IDataAccessMatrixDomain[] => {
            if (selectedDomains.length === 0) return domains;
            return domains.filter((domain) => {
                return selectedDomains.includes(domain.Name);
            });
        };

        const handleSelectDomain = (selectedDomains: string[]) => {
            setSelectedDomains(selectedDomains);
            setFilteredDomains(filterSelectedDomains(selectedDomains));
        };

        const handleClearDomains = () => {
            handleSelectDomain([]);
        };

        const handleSelectAllDomains = (isSelectAll: boolean) => {
            if (isSelectAll) {
                handleSelectDomain(domainOptions);
            } else {
                handleClearDomains();
            }
        };

        // functions to get cell details for each columns
        const getRowName = (data: ISubdomainRow) => <div className={style.row}>{data.Name}</div>;
        const hasNewField = (data: ISubdomainRow) => {
            return subdomainsWithNew.includes(data.Id) ? (
                <Chip label="New fields" className={style.newFieldsChip} />
            ) : (
                ''
            );
        };
        const getLastUpdatedDate = (data: ISubdomainRow) => {
            const subdomainId = data.Id;
            if (subdomainLatestUpdate.has(subdomainId)) {
                const dateTimeString = subdomainLatestUpdate.get(subdomainId)?.LastUpdatedAt;
                if (dateTimeString !== undefined) {
                    return <div className={style.row}>{formatDateMonthName(dateTimeString)}</div>;
                }
            }
            return <div className={style.row}>-</div>;
        };
        const getLastUpdatedBy = (data: ISubdomainRow) => {
            const subdomainId = data.Id;
            if (subdomainLatestUpdate.has(subdomainId)) {
                return <div className={style.row}>{subdomainLatestUpdate.get(subdomainId)?.LastUpdatedBy}</div>;
            }
            return <div className={style.row}>-</div>;
        };
        const reasonForAccess = nextLineToParagraphs(opsUnitReason || '');
        return (
            <Grid container alignItems="center" styleName="dataAccessManagement">
                {isAgencyAdmin && (
                    <Grid item xs={12} className={style.backBtn}>
                        <BackButton
                            onClick={() => {
                                history.back();
                            }}
                            label="Back tO LIST OF OPS UNITS"
                        />
                    </Grid>
                )}

                <Grid item xs={8}>
                    <Typography variant="h4">Data Access Management</Typography>
                </Grid>
                <Grid item xs={4} className={style.flushToRight}>
                    <Button
                        size="large"
                        color="primary"
                        data-testid="downloadReport"
                        className={disableBtn ? btnStyle.disabled : btnStyle.normal}
                        disabled={disableBtn}
                        onClick={handleDownloadReport}
                    >
                        <FileDownloadOutlined className={style.fileDownloadSvg} />
                        <span>DOWNLOAD REPORT</span>
                    </Button>
                </Grid>
                <Grid item>
                    <List style={{paddingTop: '0'}}>
                        <ListItem disableGutters>
                            <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                Ops Unit Name
                            </Typography>
                            <Typography component={'div'} variant="body1" style={{whiteSpace: 'pre-wrap'}}>
                                {accessToken?.AdditionalUserInfo.OpsUnit || opsUnit.Name}
                            </Typography>
                        </ListItem>
                    </List>
                    <List style={{paddingTop: '0'}}>
                        <ListItem disableGutters>
                            <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                Reason(s) for accessing One Client View
                            </Typography>
                            <Typography component={'div'} variant="body1" style={{whiteSpace: 'pre-wrap'}}>
                                {reasonForAccess}
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid xs={12} item>
                    <hr className={style.line}></hr>
                    <RequestWindowInfoBanner
                        currentRequestWindowEndDate={
                            currRequestWindow ? formatDateMonthName(currRequestWindow.WindowEnd) : null
                        }
                    />
                </Grid>
                {config.featureFlags.ENABLE_DAM2 && (
                    <Grid xs={12} item className={style.searchContainer}>
                        <Grid item xs={6} className={style.searchItem}>
                            <InputField
                                htmlFor="domainSearch"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search Domain or Subdomain"
                                type="text"
                                styleName="searchInput"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                isDense={true}
                            />
                        </Grid>
                        <Grid item xs={6} className={style.searchItem}>
                            <Box className={style.flushToRight}>
                                <span styleName="filterLabel">Filter</span>
                                <MultiSelectAutocomplete
                                    data-testid="filterSearch"
                                    options={domainOptions}
                                    selectedValues={selectedDomains}
                                    label="Domain"
                                    tagRenderer={(value) => (
                                        <Typography variant="subtitle1" styleName="filterTag">
                                            {value.length} Domains Selected
                                        </Typography>
                                    )}
                                    onToggleOption={handleSelectDomain}
                                    onClearOptions={handleClearDomains}
                                    onSelectAll={handleSelectAllDomains}
                                    classes={domainFilterClasses}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Grid xs={12} item>
                    {filteredDomains.map((domain, id) => {
                        const domainData: ISubdomainRow[] = domain.SubDomains.filter(
                            (subdomain) => subdomain.DataFields.length > 0,
                        ).map((subDomain) => ({
                            Name: subDomain.Name,
                            Id: subDomain.Id,
                        }));
                        const btnElement = currRequestWindow ? (
                            <div data-testid="updateAccessBtn" className={style.accessButton}>
                                <Edit className={style.editSvg} />
                                UPDATE DATA ACCESS
                            </div>
                        ) : (
                            <div data-testid="viewAccessBtn" className={style.accessButton}>
                                VIEW DATA ACCESS
                            </div>
                        );
                        const reqStatusLabel = requestState.has(domain.Id)
                            ? requestState.get(domain.Id)?.Status
                            : 'No changes';

                        return (
                            <div key={id}>
                                <DomainHeader
                                    domainId={domain.Id}
                                    opsUnitId={parseInt(opsUnitId)}
                                    title={domain.Name}
                                    btnName={btnElement}
                                    showKeyboardArrowRight={!currRequestWindow}
                                    requestStatus={
                                        config.featureFlags.ENABLE_DAM2 ? (
                                            <RequestStatus label={reqStatusLabel} />
                                        ) : undefined
                                    }
                                />
                                <ListModel
                                    columns={
                                        config.featureFlags.ENABLE_DAM2
                                            ? config.featureFlags.ENABLE_DAM2_OA_SDL_UPDATEINFO
                                                ? [getRowName, hasNewField, getLastUpdatedDate, getLastUpdatedBy]
                                                : [getRowName, hasNewField]
                                            : [getRowName]
                                    }
                                    headers={
                                        config.featureFlags.ENABLE_DAM2
                                            ? config.featureFlags.ENABLE_DAM2_OA_SDL_UPDATEINFO
                                                ? ['Subdomain', '', 'Last updated date', 'Last updated by']
                                                : ['Subdomain', '']
                                            : ['Subdomain']
                                    }
                                    customHeaderCellStyle={customerHeaderCellStyle}
                                    customCellStyle={
                                        config.featureFlags.ENABLE_DAM2
                                            ? config.featureFlags.ENABLE_DAM2_OA_SDL_UPDATEINFO
                                                ? customCellStyle2(['40%', '20%'])
                                                : customCellStyle2(['85%', '15%'])
                                            : customCellStyle2(['40%', '20%'])
                                    }
                                    modelList={domainData}
                                    rowsPerPage={domainData.length}
                                    defaultSortOrder="asc"
                                    variant="expanded"
                                    sortable={false}
                                />
                            </div>
                        );
                    })}
                    <ReportPasswordDialog
                        open={passwordModalOpen}
                        onSubmit={handlePasswordSubmit}
                        onClose={handlePasswordModalClose}
                        title={COPIES.REPORT_PASSWORD_DIALOG_TITLE}
                        description={COPIES.REPORT_PASSWORD_DIALOG_BODY}
                    />
                </Grid>
            </Grid>
        );
    };
    const DamContentWithLoader = WithAdminLoader<{}>(getDamContent, combinedFetchStatus, LOADING_MESSAGE.DATA);

    return <DamContentWithLoader />;
};
