import React, {useEffect, useState, useRef, useContext} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box, Typography, Grid, IconButton, Link} from '@mui/material';
import {CheckCircle, Close} from '@mui/icons-material';
import {IUser, IAdminRow, IMonthlyReviewResponse} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {LOADING_MESSAGE, MONTHLY_REVIEW_STATUS, LEARN_MORE_LINK, SUPPORT_EMAIL} from '~/constants';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';
import {UserListConfirmDialog} from '~/pages/Admin/UserList/UserListConfirmDialog';

import {useGrowl} from '~/utils/hooks/useGrowl';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setAgency} from '~/store/agency/agency.thunk';
import {setOpsUnits, refreshOpsUnits} from '~/store/opsUnit/opsUnit.thunk';
import {getOpsUnits} from '~/store/opsUnit/opsUnit.selector';
import {getAgencyAdminList} from '~/store/agency/agency.selector';
import {getOpsUnitsFetchState, getAgenciesFetchState} from '~/store/fetchState/fetchState.selector';
import {getRequiresMonthlyAdminReview, createMonthlyAdminReview, createOpsUnitApproval} from '~/services/adminServices';

import './OpsUnitList.scss';
import {
    name,
    checkAllReviewed,
    customRowStyle,
    customCellStyle,
    currentReviewDate,
    lastReviewDateAndName,
    nameAndDesignation,
} from '../AdminHelper';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {AdminDetails} from '~/components/Admin/AdminReview/AdminDetails';
import {RecentReview} from '~/components/Admin/AdminReview/RecentReview';
import {ReviewStatus} from '~/components/Admin/AdminReview/ReviewStatus';
import {AdminControls} from '~/components/Admin/AdminReview/AdminControls';
import {getEmailDirectAdminLink} from '~/utils/contentUtils';

export const OpsUnitList = () => {
    const {agencyId} = useParams<{agencyId?: string}>();
    const {accessToken} = useContext(AuthenticationContext);
    const opsUnits = Object.values(useTypedSelector(getOpsUnits(Number(agencyId))));
    const agency = useTypedSelector(getAgencyAdminList(Number(agencyId)));
    const dispatch = useAppDispatch();

    const history = useHistory();
    const agencyDetails = useTypedSelector((state) => state.data.admin.agencies.agencies[Number(agencyId)]);
    const agencyName = agencyDetails ? agencyDetails.Name : undefined;
    const agencyFetchState = useTypedSelector(getAgenciesFetchState);
    const opsUnitsFetchState = useTypedSelector(getOpsUnitsFetchState);

    const fetchStatus = consolidateFetchState([agencyFetchState, opsUnitsFetchState]);
    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const [reviewOpen, setReviewOpen] = useState<boolean>(false);

    const hasOpsUnit = opsUnits.length > 0;

    const handleEdit = (opsUnitId: number) => {
        history.push(`/admin/agencies/${agencyId}/ops-units/${opsUnitId}`);
    };

    const handleClick = () => {
        history.push(`/admin/agencies/${agencyId}/ops-units/create`);
    };

    const handleApprove = (opsUnit: IAdminRow) => {
        createOpsUnitApproval(opsUnit.Id)
            .then(() => {
                openGrowl(`Approval for '${opsUnit.Name}' successfully recorded.`);
                dispatch(refreshOpsUnits(Number(agencyId)));
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const handleReviewSubmit = (message: string) => {
        createMonthlyAdminReview()
            .then(() => {
                openGrowl(message);
                getRequiresMonthlyAdminReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const handleEmail = () => {
        if (agency) {
            const formatEmailRecipients = agency.Admins?.map((admin: IUser) => {
                const adminIsNotCurrentLoggedInAdmin = admin.Id !== accessToken?.Permissions.UserId;
                if (adminIsNotCurrentLoggedInAdmin) {
                    return admin.Email;
                }
            }).join(',');
            return getEmailDirectAdminLink(
                SUPPORT_EMAIL,
                accessToken?.Permissions.AccessLevel,
                agency.Name,
                formatEmailRecipients,
            );
        }
    };

    useEffect(() => {
        dispatch(setAgency(Number(agencyId)));
        dispatch(setOpsUnits(Number(agencyId)));
        getRequiresMonthlyAdminReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const getOpsUnitListContent = () => {
        const domainHeaderRef = useRef<HTMLDivElement>(null);
        const [searchValue, setSearchValue] = useState('');
        const filteredOpsUnits = opsUnits.filter(
            (opsUnit) => opsUnit.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
        );

        const allAdminRows = agency ? [agency, ...filteredOpsUnits] : filteredOpsUnits;
        const agencyAdminsInfo = allAdminRows[0]?.Admins;
        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Admin Management
                        </Typography>
                        <Typography component="h1" variant="h5">
                            {agencyName}
                        </Typography>
                    </Grid>
                    {hasOpsUnit && monthlyReviewStatus && monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW && (
                        <ReviewBanner
                            monthlyReviewStatus={monthlyReviewStatus}
                            disabled={!checkAllReviewed(opsUnits)}
                            messageCallToAction={
                                monthlyReviewStatus.Status === MONTHLY_REVIEW_STATUS.INCOMPLETE && (
                                    <Link
                                        display="inline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={LEARN_MORE_LINK}
                                    >
                                        Learn more {'>'}
                                    </Link>
                                )
                            }
                        />
                    )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="opsUnit-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search Ops Unit Name"
                                type="search"
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary text="Add Ops Unit" id="add-ops-unit-button" onClick={handleClick} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {hasOpsUnit ? (
                            <ListModel
                                columns={[
                                    name,
                                    AdminDetails(0),
                                    AdminDetails(1),
                                    RecentReview,
                                    ReviewStatus(monthlyReviewStatus, setReviewOpen, handleApprove),
                                    AdminControls(handleEdit, handleEmail),
                                ]}
                                headers={[
                                    'Ops Unit Name',
                                    'Admin 1',
                                    'Admin 2',
                                    'Last review of user accounts',
                                    'Approval of review of user accounts',
                                    '',
                                ]}
                                modelList={allAdminRows}
                                modelComparator={[
                                    getAlphabeticalComparator(name),
                                    getAlphabeticalComparator(nameAndDesignation(1)),
                                    getAlphabeticalComparator(nameAndDesignation(2)),
                                    getDateComparator(lastReviewDateAndName),
                                    getDateComparator(currentReviewDate),
                                    () => 0,
                                ]}
                                customRowStyle={customRowStyle}
                                customCellStyle={customCellStyle}
                                variant="paginated"
                                hover
                                numberOfFixedRows={1}
                            />
                        ) : (
                            <EmptyList entity="Ops Unit" handleClick={handleClick} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: null,
                            message:
                                growl.message.length > 0 ? (
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" alignItems="center" marginRight={1.75}>
                                            <CheckCircle />
                                        </Box>
                                        {growl.message}
                                    </Box>
                                ) : undefined,
                            action: (
                                <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                    <Close fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>

                <UserListConfirmDialog
                    open={reviewOpen}
                    title="Please acknowledge that the following Agency Admin(s) are active and authorised."
                    message={
                        <>
                            <Typography>
                                To remove or update the Agency Admin(s) for your organisation, please{' '}
                                <Link href={handleEmail()} target="_blank" rel="noopener noreferrer">
                                    email system admin
                                </Link>
                                {'.'}
                            </Typography>
                            {agencyAdminsInfo &&
                                agencyAdminsInfo.map((admin, index) => (
                                    <Box
                                        key={`agency-admin-${index}`}
                                        borderRadius={5}
                                        style={{
                                            marginTop: '16px',
                                            backgroundColor: '#FAFAFA',
                                            border: '1px solid #DEDEDE',
                                            padding: '16px',
                                        }}
                                    >
                                        <Typography style={{fontSize: '16px', fontWeight: 400, lineHeight: '24px'}}>
                                            {admin.Name}
                                        </Typography>
                                        <Typography style={{fontSize: '12px', fontWeight: 400, lineHeight: '16px'}}>
                                            {admin.Email}
                                        </Typography>
                                    </Box>
                                ))}
                        </>
                    }
                    cancelOption="CANCEL"
                    confirmOption="MARK AS ACTIVE"
                    onCancel={() => setReviewOpen(false)}
                    onConfirm={() => {
                        handleReviewSubmit('Agency Admin(s) marked as active successfully.');
                        setReviewOpen(false);
                    }}
                />
            </>
        );
    };

    const OpsUnitListWithAdminLoader = WithAdminLoader<{}>(getOpsUnitListContent, fetchStatus, LOADING_MESSAGE.DATA);
    return <OpsUnitListWithAdminLoader />;
};
