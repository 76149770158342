import {createSlice} from '@reduxjs/toolkit';
import {ISortTableSetting} from '~/interfaces/settings';

export const initialSettingsState = {
    sortTableSettings: {} as ISortTableSetting,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialSettingsState,
    reducers: {
        SET_SORT_TABLE_SETTING: (state, action) => {
            state.sortTableSettings = action.payload;
        },
    },
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice.reducer;
