import React from 'react';
import {formatCurrency} from '~/utils/currencyUtils';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {dateFormatter, transformInvalidDateString} from '~/utils/dateUtils';
import {Grid, Tooltip} from '@mui/material';
import {withStyles} from '@mui/styles';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {allNoResults, extractAllValues, formatValue, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export interface IDependantsSupportScheme {
    DPSApiDetails: DPSApiDetails;
}
export interface DPSApiDetails {
    DPSAmount: string;
    DPSDate: string;
    DPSProviderName: string;
    DPSTag: string;
    DPSCoverRiskEndDate: string;
}

export const DependantsSupportScheme: React.FunctionComponent<{
    data: IDependantsSupportScheme;
    errorMessages?: string[];
}> = ({data, errorMessages}) => {
    const {DPSApiDetails} = data;
    const allFields = extractAllValues(DPSApiDetails);
    const formatAmount = (amount: string) => formatCurrency(parseFloat(amount), 2, 'currency');
    const parseYNField = (ynField: string) => (ynField === 'Y' ? 'Yes' : ynField === 'N' ? 'No' : ynField);
    const transformDate = (date: string) => {
        return dateFormatter(
            date.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/g)
                ? date
                : transformInvalidDateString(date, 'YYYYMMDD'),
        );
    };
    const backdatedClaim = DPSApiDetails
        ? new Date(transformDate(DPSApiDetails.DPSDate)) > new Date('1 April 2021') &&
          new Date('1 April 2021') > new Date(transformDate(DPSApiDetails.DPSCoverRiskEndDate))
        : null;
    const backdatedClaimTip =
        'If the client makes a back-dated claim after 1 April 2021, the Cover Risk End Date would be before 1 April 2021. The Sum Assured shown here may reflect the enhanced scheme amount of up to $70,000, instead of the original Sum Assured of up to $46,000 that the client is entitled to.';
    const StyledTooltip = withStyles({
        tooltip: {
            fontSize: '12px',
            fontWeight: 'normal',
            minWidth: '700px',
        },
    })(Tooltip);

    return (
        <>
            {allNoResults(allFields) ? (
                errorMessages && errorMessages.length !== 0 && isNotEmpty(errorMessages[0]) ? (
                    <NoData message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${errorMessages[0]})`} />
                ) : (
                    <NoData />
                )
            ) : (
                <>
                    {DPSApiDetails.DPSProviderName == 'Unsuccessful' && (
                        <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />
                    )}
                    <Grid container spacing={3} data-testid="dpsSectionAPI">
                        <SimpleGridDisplay
                            fields={[
                                {label: 'Covered under DPS', value: parseYNField(DPSApiDetails.DPSTag)},
                                {
                                    label: 'Name of DPS Provider',
                                    value: DPSApiDetails.DPSProviderName,
                                },
                                {
                                    label: 'Sum Assured',
                                    value: (
                                        <>
                                            {formatValue(DPSApiDetails.DPSAmount, formatAmount)}
                                            {backdatedClaim && (
                                                <StyledTooltip title={backdatedClaimTip} placement="right-start">
                                                    <InfoIcon
                                                        style={{
                                                            color: '#606060',
                                                            height: '24px',
                                                            width: '24px',
                                                            verticalAlign: 'middle',
                                                            marginLeft: '2px',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </StyledTooltip>
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    label: 'Claim Payment Date',
                                    value: formatValue(DPSApiDetails.DPSDate, transformDate),
                                },
                                {
                                    label: 'Cover Risk End Date',
                                    value: formatValue(DPSApiDetails.DPSCoverRiskEndDate, transformDate),
                                },
                            ]}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};
