import React from 'react';
import {Grid, Typography} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './RentalDetails.scss';
import {getYearMonthFormat} from '~/utils/dateUtils';
import {getAlphabeticalComparator, getNumericComparator} from '~/components/Common/ListModel/comparators';
import {currencyFormatter} from '~/utils/currencyUtils';
import {formatValue, isNotEmpty} from '~/utils/contentUtils';

export interface IRentalDetails {
    CurrentMonthlyPayment: string;
    AccumulatedAmount: string;
    MonthsAccumulated: string;
}

interface IRentalTableData {
    Id?: number;
    RentalPayment: string;
    Amount: string;
}

export const LABEL_ACCUMULATED_AMOUNT = 'Household Accumulated Amount of Rental Arrears';

export const RentalDetails: React.FunctionComponent<{data?: IRentalDetails}> = ({data}) => {
    if (!data) return <></>;
    const formattedTableData = [
        {
            Id: 1,
            RentalPayment: 'Current Monthly Rental Payment Amount',
            Amount: formatValue(data.CurrentMonthlyPayment, currencyFormatter),
        },
        {
            Id: 2,
            RentalPayment: LABEL_ACCUMULATED_AMOUNT,
            Amount: formatValue(data.AccumulatedAmount, currencyFormatter),
        },
    ];
    const rentalPayment = (info: IRentalTableData) => {
        if (info.RentalPayment == LABEL_ACCUMULATED_AMOUNT) {
            return (
                <>
                    <Typography variant="body2">{LABEL_ACCUMULATED_AMOUNT}</Typography>
                    {isNotEmpty(data.MonthsAccumulated) && (
                        <Typography variant="caption" className={styles.caption}>{`Accumulated for ${getYearMonthFormat(
                            data.MonthsAccumulated,
                        )}`}</Typography>
                    )}
                </>
            );
        }
        return info.RentalPayment;
    };
    const amount = (info: IRentalTableData) => <div>{info.Amount}</div>;
    const rentalPaymentValue = (info: IRentalTableData) => info.RentalPayment;
    const amountValue = (info: IRentalTableData): number => +info.Amount;

    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.customTable}>
                <ListModel
                    columns={[rentalPayment, amount]}
                    headers={['Rental Payment', 'Amount ($)']}
                    modelList={formattedTableData}
                    modelComparator={[getAlphabeticalComparator(rentalPaymentValue), getNumericComparator(amountValue)]}
                    variant="expanded"
                />
            </div>
        </Grid>
    );
};
