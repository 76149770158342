import {CheckCircle, RemoveCircle} from '@mui/icons-material';
import {Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BackButton} from '~/components/Common/Button/BackButton';
import {ListHeader} from '~/components/Common/ListHeader/ListHeader';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {config} from '~/config';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IDataAccessMatrixField, IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {getOpsUnitDataAccessMatrixMap} from '~/services/dataAccessMatrixService';
import {getDomain, getDomainName} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import style from './DataAccessDataField.scss';

export const DataAccessDataField = () => {
    const {domainId} = useParams<{domainId: string}>();
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const [subDomains, setSubDomains] = useState<IDataAccessMatrixSubDomain[]>([]);
    const [dataFieldAccessMap, setDataFieldAccessMap] = useState<{[key: number]: number}>({});
    const domainName = useTypedSelector(getDomainName(parseInt(domainId)));
    const rows = useTypedSelector(getDomain(parseInt(domainId)));

    const fieldAccess = (data: IDataAccessMatrixField) =>
        dataFieldAccessMap[data.Id] != null ? (
            <CheckCircle className={style.iconGreen} color="success" fontSize="medium" />
        ) : (
            <RemoveCircle className={style.iconGray} color="action" fontSize="medium" />
        );

    const accessHeader = <div className={style.header}>Access</div>;

    const fieldName = (data: IDataAccessMatrixField) => <>{data.Name}</>;

    const customCellStyle = (data: IDataAccessMatrixField, index?: number) => {
        const style: React.CSSProperties =
            dataFieldAccessMap[data.Id] != null ? {background: '#FFFFFF'} : {background: '#F5F5F5'};
        if (index === 0) style.width = '10%';
        return style;
    };

    useEffect(() => {
        if (!config.featureFlags.ENABLE_DAM2_VIEW) {
            history.back();
        }
        (async () => {
            if (opsUnitId) {
                const map = await getOpsUnitDataAccessMatrixMap(Number(opsUnitId));
                setDataFieldAccessMap(map);
                setSubDomains(rows);
            }
        })();
    }, []);

    return (
        <Grid container alignItems="center">
            <BackButton
                onClick={() => {
                    history.back();
                }}
            />
            <Grid className={style.gridDataAccess} item xs={12}>
                <Typography variant="h4">Data Access for {domainName}</Typography>
            </Grid>
            <Grid item xs={12}>
                {subDomains.map((subDomain, index) => {
                    return (
                        <div key={index} className={style.subDomain}>
                            <ListHeader header={subDomain.Name} />
                            <ListModel
                                columns={[fieldAccess, fieldName]}
                                headers={[accessHeader, 'Field Name']}
                                modelList={subDomain.DataFields}
                                rowsPerPage={subDomain.DataFields.length}
                                customCellStyle={customCellStyle}
                                defaultSortOrder="asc"
                                variant="expanded"
                                sortable={false}
                            />
                        </div>
                    );
                })}
            </Grid>
        </Grid>
    );
};
