import React from 'react';
import {Typography} from '@mui/material';
import {SubDomainStatus} from '~/interfaces/client';
import {PendingSubDomain} from './PendingSubDomain';
import {ErrorSubDomain} from './ErrorSubDomain';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {camelCase} from 'lodash';

interface ISubSectionStatusWrapper {
    children: React.ReactNode;
    name: string;
    status: SubDomainStatus;
    hasData?: boolean;
    text?: string;
}

export const DataNotFound = ({text = SUBDOMAIN_RESULT_MESSAGE.EMPTY}: {text?: string}) => (
    <Typography variant="body2">{text}</Typography>
);

export const SubSectionStatusWrapper = ({children, status, name, hasData = true, text}: ISubSectionStatusWrapper) => (
    <div data-testid={`subsection_${camelCase(name)}`}>
        {status === SubDomainStatus.COMPLETED && (
            <>
                <Typography variant="h6" gutterBottom>
                    {name}
                </Typography>
                {hasData ? children : <DataNotFound text={text} data-testid="no_data" />}
            </>
        )}
        {status === SubDomainStatus.PENDING && <PendingSubDomain name={name} isSubSection />}
        {(status === SubDomainStatus.ERROR || !status) && <ErrorSubDomain name={name} isSubSection />}
    </div>
);
