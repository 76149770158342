import React from 'react';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './DrivingLicenses.scss';
import {formatDate} from '~/utils/dateUtils';
import {allNoResults, extractAllValues, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {FIELD_VALUES} from '~/constants';

export type IDrivingLicensesData = IDrivingLicense[];

interface IDrivingLicense {
    Id?: number;
    Type: string;
    Class: string;
    IssuanceDate: string;
    Status: string;
}

const SequenceOfStatuses = ['Valid', 'Expired', 'Invalid'];

export const DrivingLicenses: React.FunctionComponent<{data: IDrivingLicensesData}> = ({data}) => {
    if (allNoResults(extractAllValues(data))) {
        return <NoData />;
    }

    const getTime = (issuanceDate: string) =>
        issuanceDate === FIELD_VALUES.NO_RESULTS ? -1 : new Date(issuanceDate).getTime();

    const sortedData = data
        .sort((license1, license2) =>
            SequenceOfStatuses.indexOf(license2.Status) < SequenceOfStatuses.indexOf(license1.Status) ? 1 : -1,
        )
        .sort((license1, license2) =>
            license1.Status === license2.Status ? getTime(license2.IssuanceDate) - getTime(license1.IssuanceDate) : 0,
        );

    const type = (license: IDrivingLicense) => license.Type;
    const classOfLicense = (license: IDrivingLicense) => license.Class;

    const issuanceDate = (license: IDrivingLicense) =>
        formatValue(license.IssuanceDate, (date: string) => formatDate(new Date(date)));
    const status = (license: IDrivingLicense) => license.Status;

    const issuanceDateValue = (license: IDrivingLicense) => new Date(license.IssuanceDate);

    return (
        <div id={styles.table}>
            <ListModel
                columns={[type, classOfLicense, issuanceDate, status]}
                headers={['Type of License', 'Class of License', 'Issuance Date of License', 'Status of License']}
                modelList={sortedData}
                modelComparator={[
                    getAlphabeticalComparator(type),
                    getAlphabeticalComparator(classOfLicense),
                    getDateComparator(issuanceDateValue),
                    getAlphabeticalComparator(status),
                ]}
                variant="expanded"
                dataTestId="drivingLicense"
            />
        </div>
    );
};
