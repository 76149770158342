import React from 'react';
import {Chip, Container, Typography} from '@mui/material';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';

interface IProps {
    deleteHandler: (subDomainId: number) => void;
    toggleAll: () => void;
    subDomains: IRowData[];
}

export const SelectedChips = ({deleteHandler, subDomains, toggleAll}: IProps) => {
    const handleSelectToggle = (subDomainId: number) => {
        deleteHandler(subDomainId);
    };

    const selectedSubDomainNum = () => {
        const selected = subDomains.filter((row) => row.isSelected);
        return selected.length;
    };
    return (
        <>
            {selectedSubDomainNum() > 0 && (
                <Container style={{padding: '16px 0 0'}}>
                    <Typography variant="h6" style={{margin: '0 0 8px'}}>
                        ({selectedSubDomainNum()}) Sub-Domains Selected
                    </Typography>
                    {subDomains.map(
                        (subDomain) =>
                            subDomain.isSelected && (
                                <Chip
                                    label={subDomain.subDomainName}
                                    onDelete={() => handleSelectToggle(subDomain.subDomainId)}
                                    onClick={() => handleSelectToggle(subDomain.subDomainId)}
                                    key={subDomain.subDomainId}
                                    color="primary"
                                    style={{
                                        marginLeft: '4px',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                />
                            ),
                    )}
                    <Chip
                        label={'Clear all'}
                        onDelete={toggleAll}
                        onClick={toggleAll}
                        key={'clear all'}
                        color="default"
                        variant="outlined"
                        style={{
                            marginLeft: '4px',
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}
                    />
                </Container>
            )}
        </>
    );
};
