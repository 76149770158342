import {config} from '~/config';
import {OPS_UNIT_TYPE} from '~/constants';
import {
    IDomainDataRequest,
    IDomainReqStatus,
    IOpsUnitDam2UpdateRequest,
    IOpsUnitDamFields,
    IOpsUnitDataAccess,
    IOpsUnitDataFieldRejectReason,
    IOpsUnitDataFieldRejectRequest,
    IRequestWindowLog,
    ISubdomainLatestStatus,
} from '~/interfaces/admin';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {httpRequest} from './middleware';

export interface DataRestrictions {
    OpsUnitType: string;
    Fields: number[];
    Remarks: string;
}

export const getOpsUnitDataAccessMatrix = async (opsUnitId: number): Promise<number[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam/data-access/${opsUnitId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const getOpsUnitDataAccessMatrixMap = async (opsUnitId: number): Promise<{[key: number]: number}> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam/data-access-map/${opsUnitId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data;
};

export const getOpsUnitDataRestrictionsByDomain = async (
    opsUnitId: number,
    domainId: number,
): Promise<DataRestrictions> => {
    const response = await httpRequest(
        {
            url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-restrictions/ops-units/${opsUnitId}/domains/${domainId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            method: 'GET',
        },
        true,
    );
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const updateOpsUnitDataAccessMatrix = async (opsUnitId: number, dataSource: IOpsUnitDamFields) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam/data-access/${opsUnitId}`,
        data: JSON.stringify(dataSource),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const updateOpsUnitType = async (opsUnitId: string, opsUnitType: OPS_UNIT_TYPE) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam/ops-unit-type/${opsUnitId}`,
        data: JSON.stringify({opsUnitType}),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const updateOpsUnitDetails = async (
    opsUnitId: string,
    opsUnitType: OPS_UNIT_TYPE,
    optsUnitReasonForAccess: string,
) => {
    interface IPayLoad {
        OpsUnitType: OPS_UNIT_TYPE;
        ReasonForOneCVAccess?: string;
    }
    const payLoad: IPayLoad = {
        OpsUnitType: opsUnitType,
    };
    if (optsUnitReasonForAccess) {
        payLoad.ReasonForOneCVAccess = optsUnitReasonForAccess;
    }

    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-acess/${opsUnitId}/info`,
        data: JSON.stringify({...payLoad}),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const updateOpsUnitDataAccessMatrix2 = async (opsUnitId: number, dataSource: IOpsUnitDam2UpdateRequest) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-access/${opsUnitId}`,
        data: JSON.stringify(dataSource),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data.SubdomainMetas;
};

export const getOpsUnitSubdomainLatestStatus = async (opsUnitId: string): Promise<ISubdomainLatestStatus[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/subdomain-latest-status/ops-units/${opsUnitId}`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getOpsUnitDomainRequestStatus = async (opsUnitId: string): Promise<IDomainReqStatus[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/domains-request-status/ops-units/${opsUnitId}`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getOpsUnitDataRequestStateByDomain = async (
    opsUnitId: number,
    domainId: number,
): Promise<IDomainDataRequestState> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-request-state/${opsUnitId}/domains/${domainId}`,
        method: 'GET',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getSubdomainsByDomainId = async (domainId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/domains/${domainId}`,
        method: 'GET',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};
export const getDam2OpsUnitDataAccessMatrix = async (opsUnitId: number): Promise<IOpsUnitDataAccess[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-access/${opsUnitId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }
    return response.data.SubdomainMetas;
};

export const getRejectAccessHistoryList = async (opsUnitId: number): Promise<IOpsUnitDataFieldRejectReason[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-access/${opsUnitId}/reject`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data.Rejection;
};

export const updateOpsUnitDataFieldRequestReject = async (
    opsUnitId: number,
    dataSource: IOpsUnitDataFieldRejectRequest,
) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-access/${opsUnitId}/reject`,
        data: JSON.stringify(dataSource),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const withdrawDomainDataRequest = async (formId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/withdraw-domain-data-request/${formId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const submitDomainDataRequest = async (
    opsUnitId: number,
    domainId: number,
    oaFormAction: string,
    formData: IDomainDataRequest,
) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/domain-data-request/${opsUnitId}/${domainId}/${oaFormAction}`,
        data: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getRejectAccessHistoryListBySubdomain = async (
    opsUnitId: number,
    subdomainId: number,
): Promise<IOpsUnitDataFieldRejectReason[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/data-access/${opsUnitId}/subdomains/${subdomainId}/reject`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data.Rejection;
};

export const getRequestWindowLogs = async (opsUnitId: number, subdomainId: number): Promise<IRequestWindowLog[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/domain-data-request/${opsUnitId}/subdomains/${subdomainId}/log`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data.Windows;
};
