import React from 'react';
import {TableRow, TableCell, Typography, List, ListItem} from '@mui/material';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {IReleaseNoteSection} from '~/interfaces/common';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {assertNewLine} from '~/utils/contentUtils';
import './ReleaseNoteTable.scss';

interface IReleaseNoteTableRowProps {
    sortedRows: IReleaseNoteSection[];
}
export const ReleaseNoteTableRows = ({sortedRows}: IReleaseNoteTableRowProps) => {
    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <React.Fragment key={index}>
                        <TableRow key={`mainRow_${index}`}>
                            <TableCell>
                                <Typography component="span" variant="body1" styleName="roleHeaderWrapper">
                                    {row.User ? assertNewLine(row.User) : 'All'}
                                </Typography>
                            </TableCell>
                            <TableCell styleName="descriptionTableCell">
                                <List styleName="list">
                                    {row.ContentList &&
                                        row.ContentList.length > 0 &&
                                        row.ContentList.map((contentPoint) => (
                                            <ListItem key={contentPoint} className="contentPoint">
                                                {contentPoint}
                                            </ListItem>
                                        ))}
                                </List>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export interface IReleaseNoteTableProps {
    rows: IReleaseNoteSection[];
}

export type IHeadRowData = {
    User?: string;
    Content: string;
};

export const ReleaseNoteTable = ({rows}: IReleaseNoteTableProps) => {
    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {
            id: 'User',
            align: 'left',
            disablePadding: false,
            label: (
                <Typography variant="body1" styleName="roleHeaderText">
                    Role
                </Typography>
            ),
        },
        {
            id: 'Content',
            align: 'left',
            disablePadding: false,
            label: (
                <Typography variant="body1" styleName="descriptionHeaderText">
                    Description
                </Typography>
            ),
        },
    ];

    const headerProps = {
        columns: headColumns,
    };

    const tableSortLabelIconStyle = {
        color: 'var(--primary-blue, #3949ab)',
    };

    const customStyle = {
        backgroundColor: 'rgb(233, 235, 246)',
    };

    const hocProps = {
        colSpan: 12,
        customStyle,
        headerProps,
        tableSortLabelIconStyle,
    };

    const props = {
        ...hocProps,
    };

    const formattedRows = rows.map((row) => {
        return row.User ? row : {...row, User: 'All'};
    });

    const EnhancedTable = WithEnhancedTable<IReleaseNoteTableRowProps, IHeadRowData, IReleaseNoteSection>(
        ReleaseNoteTableRows,
        'User',
        formattedRows,
        'asc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return <EnhancedTable {...props} />;
};
