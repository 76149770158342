import React from 'react';
import {Paper, Table, TableContainer, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import './RejectedAccessHistory.scss';
import {useTypedSelector} from '~/hooks/useTypedSelector';

import {IDataFieldAccessRejectedReasons} from '~/store/dataReducers';
import {getDataFieldNameState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {format} from 'date-fns';
import {startCase} from 'lodash';

interface IFieldsProps {
    reasons: IDataFieldAccessRejectedReasons[];
    domainId: number;
    subDomainId: number;
}

export const FieldsHeader = () => {
    return (
        <TableRow styleName="subDomainHeaderRow">
            <TableCell style={{width: '38%'}} styleName="subDomainHeader">
                Data Field(s)
            </TableCell>
            <TableCell style={{width: '22%'}} styleName="subDomainHeader">
                <Typography component="span" styleName="rejectedByHeader">
                    Rejected by
                </Typography>
                <br />
                <Typography component="span" styleName="rejectedDateHeader">
                    Date of rejection
                </Typography>
            </TableCell>
            <TableCell style={{width: '40%'}} styleName="subDomainHeader">
                Reason for Rejection
            </TableCell>
        </TableRow>
    );
};

export const Fields = ({reasons, domainId, subDomainId}: IFieldsProps) => {
    if (reasons?.length) {
        return (
            <>
                <Paper style={{borderRadius: '0'}}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <FieldsHeader />
                                {reasons.map((reason, index) => {
                                    const fieldNames = reason.dataFieldIds?.map((d) =>
                                        useTypedSelector(getDataFieldNameState(domainId, subDomainId, d)),
                                    );
                                    const rejectedTime = format(new Date(reason.rejectedAt), 'dd MMM yyyy h.mmaa');
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{width: '38%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    {fieldNames?.join(', ')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '22%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    {startCase(reason.rejectedBy)}
                                                </Typography>
                                                <br />
                                                <Typography component="span" styleName="rejectedDateField">
                                                    {rejectedTime}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '40%'}}>
                                                <Typography
                                                    component="span"
                                                    styleName="tableCellFont"
                                                    style={{whiteSpace: 'pre-wrap'}}
                                                >
                                                    {reason.reason}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </>
        );
    }
    return null;
};
