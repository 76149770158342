import {config} from '~/config';
import {
    IClientBasicInfo,
    IConsentAndSearchHistory,
    IScreeningReportRequest,
    IFamilyMember,
    IFamilyScreeningResults,
} from '~/interfaces/client';
import {httpRequest} from './middleware';
import {IInitiateScreeningResponse, IFamilyScreeningRequest} from '~/interfaces/clientUser';
import fileDownload from 'js-file-download';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getClientBasicInfo = async (uin: string): Promise<IClientBasicInfo> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/basic-info/${uin}`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getConsentAndSearchHistory = async (uin: string): Promise<IConsentAndSearchHistory> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/consent-and-search-history/${uin}`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const initiateScreening = async (payload: IFamilyScreeningRequest): Promise<IInitiateScreeningResponse> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/initiate-screening`,
        data: JSON.stringify(payload),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getScreeningResults = async (familyScreeningRequestId: string): Promise<IFamilyScreeningResults> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/screening-results/${familyScreeningRequestId}`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getScreenedClientReport = async (reportRequest: IScreeningReportRequest) => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/download-report`,
        data: JSON.stringify(reportRequest),
        headers: {
            Accept: 'application/pdf',
        },
        method: 'POST',
        responseType: 'blob',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    const fileData = response.data;
    const fileName = `${reportRequest.FamilyScreeningRequestId}.${reportRequest.SingleReport ? 'pdf' : 'zip'}`;

    fileDownload(fileData, fileName);
};

export const getFamilyMember = async (uin: string): Promise<IFamilyMember> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/family-member/${uin}`,
    });

    if (response.status !== 200) {
        throw new Error();
    }
    return response.data;
};
