import React from 'react';
import {Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button} from '@mui/material';

export interface ILeavePageDialog {
    id: string;
    open?: boolean;
    message: string;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm: (event: object) => void;
    onCancel?: (event: object) => void;
}

export const LeavePageDialog = ({
    id,
    open = false,
    title = '',
    message,
    confirmText = 'Ok',
    cancelText = 'Cancel',
    onConfirm,
    onCancel,
}: ILeavePageDialog) => {
    return (
        <Dialog id={id} open={open} onClose={onCancel} aria-describedby="alert-dialog-description">
            {title != '' && (
                <DialogTitle data-testid="dialogTitle" style={{width: 531}}>
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText data-testid="dialogMessage">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onCancel && (
                    <Button data-testid="cancelBtn" onClick={onCancel} color="primary">
                        {cancelText}
                    </Button>
                )}
                <Button data-testid="confirmBtn" onClick={onConfirm} color="primary">
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
