import {createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from '../index';
import {IUserNotification} from '~/interfaces/common';
import * as userServices from '~/services/userServices';

export const setUserNotification = createAsyncThunk<IUserNotification[], void, {state: RootState; rejectValue: string}>(
    'userNotification/setUserNotification',
    async (_, {rejectWithValue}) => {
        try {
            const userNotifications = await userServices.getUserNotifications();
            return userNotifications;
        } catch (error) {
            return rejectWithValue('Error in setting user notifications');
        }
    },
);

export const updateUserNotifications = createAsyncThunk(
    'userNotification/updateUserNotifications',
    async (_, {rejectWithValue}) => {
        try {
            await userServices.updateUserNotifications();
        } catch (error) {
            throw rejectWithValue(error);
        }
    },
);
