import {Box, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {ModalWrapper} from '~/components/Common/ModalWrapper/ModalWrapper';
import dataResponsibly from '~/assets/images/dataResponsibly.svg';
import './AdvisoryNoteModal.scss';
import {WarningMessage} from '~/components/Common/IconMessage/IconMessage';
import {ADVISORY_NOTE_1, ADVISORY_NOTE_2} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {updateAcknowledgeAdvisoryDate} from '~/services/userServices';

export const AdvisoryNoteModal = () => {
    const {acknowledgedToAdvisoryNote, accessToken, updateAcknowledgeToAdvisory, setAccessToken} =
        useContext(AuthenticationContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(!acknowledgedToAdvisoryNote);
    }, [acknowledgedToAdvisoryNote]);

    const acknowledgeAdvisory = async (): Promise<void> => {
        if (accessToken != null) {
            //update token if success, if API fails, update flag
            updateAcknowledgeAdvisoryDate()
                .then(setAccessToken)
                .catch(() => {
                    updateAcknowledgeToAdvisory(true);
                });
        }
    };

    return (
        <ModalWrapper title="Advisory Note" open={showModal} onClick={acknowledgeAdvisory} buttonLabel="I Acknowledge">
            <img src={dataResponsibly} alt="Data Responsibly" styleName="advisoryNoteImage" />
            <Box styleName="AdvisoryNoteContainer">
                <WarningMessage
                    message={'Handle data with care'}
                    variant="h5"
                    iconStyle={{marginTop: '5px'}}
                    style={{padding: '0 0 8px 0', margin: 0}}
                    fontStyle={{fontWeight: 500}}
                />
                <Typography styleName="AdvisoryNoteText">{ADVISORY_NOTE_1}</Typography>
            </Box>
            <Box styleName="AdvisoryNoteContainer">
                <WarningMessage
                    message={'Perform your own verification'}
                    variant="h5"
                    iconStyle={{marginTop: '5px'}}
                    style={{padding: '0 0 8px 0', margin: 0}}
                    fontStyle={{fontWeight: 500}}
                />
                <Typography styleName="AdvisoryNoteText">{ADVISORY_NOTE_2}</Typography>
            </Box>
        </ModalWrapper>
    );
};
