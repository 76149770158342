import React from 'react';
import {Grid, Typography, Divider} from '@mui/material';
import {dateFormatter} from '~/utils/dateUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {Relationship} from '~/constants';
import './FamilyMembers.scss';
import {allNoResults, extractAllValues, isNotEmpty, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type IFamilyMembersData = IFamilyMember[];
interface IFamilyMember {
    Relationship: Relationship;
    Name: string;
    UIN: string;
    DateOfBirth: string;
    Nationality: string;
    ResidencyStatus: string;
    DateOfDeath: string;
    StartDateOfRelationship: string;
    EndDateOfRelationship: string;
}

export const FamilyMembers: React.FunctionComponent<{data: IFamilyMembersData}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <>
                    {data.map((member, index) => {
                        const {
                            DateOfBirth,
                            StartDateOfRelationship,
                            Nationality,
                            ResidencyStatus,
                            EndDateOfRelationship,
                            DateOfDeath,
                            UIN,
                            Name,
                            Relationship,
                        } = member;
                        const fields = [
                            {
                                label: 'Date of Birth',
                                value: formatValue(DateOfBirth, dateFormatter),
                            },
                            {
                                label: 'Start of Relationship',
                                value: formatValue(StartDateOfRelationship, dateFormatter),
                            },
                            {label: 'Nationality', value: Nationality},
                            {label: 'Residential Status', value: ResidencyStatus},
                            {
                                label: 'End of Relationship',
                                value: formatValue(EndDateOfRelationship, dateFormatter),
                            },
                            {
                                label: 'Date of Death',
                                value: formatValue(DateOfDeath, dateFormatter),
                            },
                        ];

                        return (
                            <Grid key={UIN} container spacing={3} data-testid={`familyMember${index}`}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        styleName={isNotEmpty(DateOfDeath) ? 'deceasedMember' : ''}
                                    >
                                        {`${Name} | ${UIN}`}
                                    </Typography>
                                    <Typography styleName="relationship" variant="subtitle1">
                                        {Relationship}
                                    </Typography>
                                </Grid>
                                <SimpleGridDisplay key={index} fields={fields} />
                                {index + 1 < data.length && (
                                    <Grid item xs={12}>
                                        <Divider
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 20,
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
                </>
            )}
        </>
    );
};
