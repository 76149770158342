import {config} from '~/config';
import {httpRequest} from './middleware';
import {IDashboardInfo} from '~/interfaces/dashboard';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getDashboardInfo = async (): Promise<IDashboardInfo> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dashboard`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};
