import React from 'react';
import {IFamilyMember} from '~/interfaces/client';
import {Button, Dialog, DialogContent, Divider, Grid, IconButton, Typography} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {Relationship} from '~/constants';
import {Person} from './Person';
import './FamilyMembersConfirmationDialog.scss';

export interface IFamilyMembersConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onBack: () => void;
    selectedFamilyMembers: IFamilyMember[];
}

export const FamilyMembersConfirmationDialog = ({
    open,
    onClose,
    onSubmit,
    onBack,
    selectedFamilyMembers,
}: IFamilyMembersConfirmationDialogProps) => {
    const unknownMembers = selectedFamilyMembers.filter((member) => member.Relationship === Relationship.UNKNOWN);

    const RequestedPersonList = (
        <>
            <Grid item xs={12}>
                <Typography styleName="requestedPerson">Requested Person(s)</Typography>
            </Grid>
            <Grid item xs={12}>
                {unknownMembers.map((member) => (
                    <Person key={member.UIN} actionType="none" personInfo={member} displayUIN />
                ))}
            </Grid>
        </>
    );

    return (
        <Dialog open={open} aria-describedby="alert-dialog-description" styleName="familyMembersConfirmationDialog">
            <DialogContent styleName="container">
                <Grid container>
                    <Grid item xs={11}></Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" onClick={onClose} styleName="closeButton">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Typography styleName="title">{`We noticed that you're screening for ${unknownMembers.length} other person(s).`}</Typography>
                <Typography styleName="content">
                    Please ensure that you have selected the right person(s) before proceeding.
                </Typography>

                {RequestedPersonList}

                <Divider />

                <Grid container justifyContent="center">
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        id="confirmSelectionButton"
                        styleName="confirmButton"
                    >
                        Yes, that&apos;s right
                    </Button>
                    <Button
                        onClick={onBack}
                        variant="contained"
                        color="primary"
                        id="confirmSelectionButton"
                        styleName="backToEditButton"
                    >
                        Edit List
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
