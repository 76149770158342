import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {FIELD_VALUES} from '~/constants';

export interface IClosedIntervention {
    CaseClosedDate: string;
    CaseClosureReason: string;
}

const mapCaseClosureReason = (caseClosureReason: string) => {
    switch (caseClosureReason.toLowerCase()) {
        case 'superseded':
            return 'Superseded [Auto-Closure]';
        default:
            return caseClosureReason;
    }
};

export const ClosedIntervention = ({CaseClosedDate, CaseClosureReason}: IClosedIntervention) => {
    return (
        <Table>
            <TableHead style={{backgroundColor: '#e8eaf6'}}>
                <TableRow>
                    <TableCell
                        data-testid={`headerClosedDate`}
                        style={{borderBottom: 'none', color: '#3c3c3c', fontWeight: 'normal', padding: '24px'}}
                    >
                        Closure Date
                    </TableCell>
                    <TableCell
                        data-testid={`headerClosureReason`}
                        style={{borderBottom: 'none', color: '#3c3c3c', fontWeight: 'normal', padding: '24px'}}
                    >
                        Reason(s) for Closure
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{width: '100%'}}>
                <TableRow>
                    <TableCell style={{borderBottom: 'none', width: '20%', padding: '24px'}}>
                        <Typography data-testid={`caseClosedDate`} variant="body1">
                            {CaseClosedDate !== FIELD_VALUES.NO_RESULTS ? CaseClosedDate : 'NA'}
                        </Typography>
                    </TableCell>
                    <TableCell style={{borderBottom: 'none', padding: '24px'}}>
                        <Typography data-testid={`caseClosureReason`} variant="body1">
                            {mapCaseClosureReason(CaseClosureReason)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
