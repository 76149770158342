import React from 'react';
import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {KeyboardArrowDownRounded as KeyboardArrowDownIcon} from '@mui/icons-material';
import {Order} from '~/utils/tableUtils';
import {isNil} from 'lodash';

import './TableHeader.scss';

export interface IHeaderColumn<T> {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    disablePadding: boolean;
    id?: T;
    label: string | React.ReactElement;
}

export interface ITableHeaderProps<T> {
    columns: IHeaderColumn<keyof T>[];
    numSelected?: number;
    order: Order;
    orderBy: keyof T;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    rowCount?: number;
    showCheckbox?: boolean;
    customStyle?: React.CSSProperties;
    tableSortLabelIconStyle?: Omit<React.CSSProperties, 'MozForceBrokenImageIcon'>;
}
export const TableHeader = <T extends object>({
    columns,
    numSelected,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    rowCount,
    showCheckbox,
    customStyle,
    tableSortLabelIconStyle,
}: ITableHeaderProps<T>) => {
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const useStyles = makeStyles({
        icon: {
            color: 'inherit !important', //default
            ...tableSortLabelIconStyle,
        },
    });
    const tableSortLabelClasses = useStyles();

    const style = {
        backgroundColor: '#d1d6ef',
        ...customStyle,
    };

    return (
        <TableHead style={style} styleName="tableHeader">
            <TableRow>
                {showCheckbox && !isNil(numSelected) && !isNil(rowCount) && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all'}}
                        />
                    </TableCell>
                )}
                {columns.map((col, index) =>
                    col.id !== undefined ? (
                        <TableCell
                            key={`headerCell_${index}`}
                            align={col.align}
                            padding={col.disablePadding ? 'none' : undefined}
                            sortDirection={orderBy === col.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === col.id}
                                direction={orderBy === col.id ? order : 'asc'}
                                onClick={createSortHandler(col.id)}
                                style={{...customStyle}}
                                IconComponent={KeyboardArrowDownIcon}
                                classes={{
                                    icon: tableSortLabelClasses.icon,
                                }}
                            >
                                {col.label}
                                {orderBy === col.id ? (
                                    <Box component="span" display="none">
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell key={`headerCell_${index}`} align={col.align} style={{...customStyle}}>
                            {col.label}
                        </TableCell>
                    ),
                )}
            </TableRow>
        </TableHead>
    );
};
