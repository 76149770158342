import React, {useContext, useEffect, useState} from 'react';
import {Snackbar, SnackbarProps} from '@mui/material';
import {NAVBAR_PADDING, USER_TYPE} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {isPageAtBottom} from '~/utils/pageUtils';

import './AdminSnackbar.scss';

export interface IAdminSnackbarProps {
    domainHeaderRef: React.RefObject<HTMLDivElement>;
    snackBarProps: SnackbarProps;
    children?: JSX.Element;
}

export const AdminSnackbar = ({domainHeaderRef, snackBarProps, children}: IAdminSnackbarProps) => {
    const [isFooterHidden, setIsFooterHidden] = useState<boolean>(true);
    const [isFooterOffsetBottom, setIsFooterOffsetBottom] = useState<boolean>(false);
    const {accessToken} = useContext(AuthenticationContext);
    const isIntranetUser = accessToken?.UserType === USER_TYPE.intranet;
    const offsetStyle = (style: string) => {
        if (isFooterOffsetBottom && !isFooterHidden) {
            const offsetStyle = isIntranetUser
                ? 'page-bottom-with-footer-offset-intranet'
                : 'page-bottom-with-footer-offset';
            return `${style} ${offsetStyle}`;
        }
        if (isFooterOffsetBottom) {
            const offsetStyle = isIntranetUser ? 'page-bottom-offset-intranet' : 'page-bottom-offset';
            return `${style} ${offsetStyle}`;
        }
        if (!isFooterHidden) {
            return `${style} footer-offset`;
        }
        return style;
    };

    // Passing of ref object from SubmitView to Submit(parent) causes the parent component to rerender on scroll,
    // and reset states of child components causing expanded nested table to collapse unexpectedly on scroll
    // So the ref is passed from SubmitView to SubmitFooter(children) to prevent the rerender
    useEffect(() => {
        const domainHeaderHandler = ([entry]: IntersectionObserverEntry[]) => setIsFooterHidden(entry.isIntersecting);
        const domainHeaderObserver = new IntersectionObserver(domainHeaderHandler, {
            threshold: 0,
            rootMargin: `-${NAVBAR_PADDING}px 0px 0px 0px`,
        });
        if (domainHeaderRef?.current) {
            domainHeaderObserver.observe(domainHeaderRef?.current);
            return () => {
                domainHeaderObserver.disconnect();
            };
        }
    }, [domainHeaderRef?.current]);

    useEffect(() => {
        const handleScroll = () => {
            if (isPageAtBottom()) {
                setIsFooterOffsetBottom(true);
            } else {
                setIsFooterOffsetBottom(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Snackbar
            {...snackBarProps}
            ContentProps={{
                style: {
                    textAlign: 'left',
                    width: '100%',
                    background: '#111111',
                },
            }}
            styleName={offsetStyle('adminSnackbar')}
        >
            {children}
        </Snackbar>
    );
};
