import {createSlice} from '@reduxjs/toolkit';
import {IDataContributor} from '~/interfaces/admin';
import {
    setDataContributors,
    createDataContributor,
    updateDataContributor,
    deleteDataContributor,
    activateDataContributor,
} from './dataContributor.thunk';

export const initialDataContributorState: IDataContributor[] = [];

export const dataContributorSlice = createSlice({
    name: 'dataContributors',
    initialState: initialDataContributorState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setDataContributors.fulfilled, (_, action) => {
                if (action.payload) {
                    return action.payload;
                }
            })
            .addCase(createDataContributor.fulfilled, (state, action) => {
                if (action.payload) {
                    state.push(action.payload);
                }
            });
        builder.addCase(updateDataContributor.fulfilled, (state, action) => {
            if (action.payload) {
                return state.map((dataContributor) =>
                    dataContributor.Id === action.payload.Id ? {...action.payload} : dataContributor,
                );
            }
        });
        builder.addCase(deleteDataContributor.fulfilled, (state, action) => {
            if (action.payload) {
                return state.filter((dataContributor) => dataContributor.Id !== action.payload.Id);
            }
        });
        builder.addCase(activateDataContributor.fulfilled, (state, action) => {
            if (action.payload) {
                return state.map((dataContributor) =>
                    dataContributor.Id === action.payload.Id
                        ? {
                              ...action.payload,
                              User: {
                                  ...action.payload.User,
                                  IsActive: true,
                              },
                          }
                        : dataContributor,
                );
            }
        });
    },
});

export const dataContributorActions = dataContributorSlice.actions;
export default dataContributorSlice.reducer;
