import React from 'react';
import {Divider, Typography} from '@mui/material';

import './SiteResources.scss';

export const WebsiteTou = () => {
    return (
        <>
            <Typography variant="h4">Terms of Use</Typography>
            <Divider />

            <Typography variant="h5">1. Terms of Agreement</Typography>
            <br />
            <Typography variant="body1">
                Thank you for using One Client View. Public agencies and participating non-public agencies use One
                Client View to use, contribute or share personal information under prevailing data sharing provisions to
                support the provision and administration of social services and schemes in Singapore.
            </Typography>

            <Typography variant="body1">
                By accessing and using any part of this digital service, you shall be deemed to have accepted to be
                bound by these Terms of Use. If you do not agree to these Terms of Use, please do not use this digital
                service.
            </Typography>

            <Typography variant="body1">
                These Terms of Use may be changed from time to time. All changes will be posted on this page, and your
                use of this digital service after such changes have been posted will constitute your agreement to the
                modified Terms of Use and all of the changes.
            </Typography>

            <Typography variant="h5">2. Access to One Client View</Typography>
            <br />
            <Typography variant="body1">
                Access to One Client View is granted to participating public agencies and non-public agencies on a
                need-to-know basis to support the provision and administration of social services and schemes in
                Singapore.
            </Typography>

            <Typography variant="body1">
                We shall be entitled to suspend, terminate, or vary the digital service or its operating hours at any
                time without prior notice at our sole discretion without giving any reason and without prejudice to our
                rights against you.
            </Typography>

            <Typography variant="body1">
                We shall also have the right to terminate your access to the digital service at any time without prior
                notice, should you or your agency breach these terms and/or the Data Sharing Agreement, or use the
                digital service or the information retrieved from the digital for any unlawful purpose.
            </Typography>

            <Typography variant="h5">3. Relying on Information</Typography>
            <br />
            <Typography variant="body1">
                We do not guarantee the accuracy, correctness, adequacy, fullness, reliability, timeliness for any
                particular purpose of any statement, opinion, representation, or other information in the digital
                service. Any information provided does not constitute professional or legal advice. Before relying on
                the digital service, you should perform your own checks whenever possible or obtain professional advice
                relevant to your individual circumstances.
            </Typography>

            <Typography variant="h5">4. Security</Typography>
            <br />
            <Typography variant="body1">
                Where appropriate, we use available technology to protect the security of communications made through
                the digital service. However, we shall not be responsible or liable for the security, authenticity,
                integrity or confidentiality of any transactions and other communications made through the digital
                service. Internet communications may be susceptible to interference or interception by third parties. We
                make no warranties that the digital service is free of infection by computer viruses or other
                unauthorised software.
            </Typography>

            <Typography variant="body1">
                You should take appropriate steps to keep your information, software, and equipment secure. This
                includes clearing your Internet browser cookies and cache before and after using any services on the
                digital service. You should keep your passwords confidential. You shall immediately notify the
                Government in confidence if you know or have reason to suspect that the security of your password has
                been compromised.
            </Typography>

            <Typography variant="h5">5. Intellectual Property</Typography>
            <br />
            <Typography variant="body1">
                This digital service is owned and operated by the Government of Singapore. The contents of the digital
                service, including source code, pages, documents and online graphics, audio and video in the digital
                service are protected by law. The intellectual property rights in the materials are owned by or licensed
                to us. In using the digital service, you shall not infringe any intellectual property rights or
                interests of the Government or any other party.
            </Typography>

            <Typography variant="body1">
                Apart from any fair dealings for the purposes of private study, research, or review, as permitted in
                law, no part of the digital service may be reproduced or reused for any commercial purposes whatsoever
                without our prior written permission.
            </Typography>

            <Typography variant="h5">6. Use of the Digital Service</Typography>
            <br />
            <Typography variant="body1">
                You agree that any use of or access to the digital service through your CorpPass account or other
                authorised account shall be deemed to be used or accessed by you or such person authorised by you.
            </Typography>

            <Typography variant="body1">
                You agree that access to the digital service is for the following purposes only: (a) verifying an
                individual and his family members’ identity and family relationships, for the Services or Scheme; (b)
                determining if an individual and his family members qualify or meet the eligibility criteria for the
                Services or Scheme; and (c) providing the Services or Scheme by a Public Agency or Participating
                Organisation;
            </Typography>

            <Typography variant="body1">
                You agree that you will not use the digital service or the information from the digital service for any
                purpose that is unlawful or prohibited by the Terms or within the Data Sharing Agreement signed with
                your agency. You will indemnify the Government for all losses sustained, incurred or suffered by the
                Government arising out of or in connection with any breach of these Terms by you, or any deliberate
                misconduct or unlawful act by you or any of your directors, officers, personnel, employees, servants or
                agents.
            </Typography>

            <Typography variant="body1">
                You agree that you will access the digital service via secured hardware devices based on prevailing data
                security instructions provided by the Government.
            </Typography>

            <Typography variant="body1">
                You agree that the Government is not responsible for preserving the confidentiality of any data or
                information that you choose to disclose in your use of the digital service.
            </Typography>

            <Typography variant="body1">
                You shall be responsible for all losses arising out of or in connection with your use of the digital
                service, including any unauthorised use of your CorpPass account or other authorised account by any
                person without your authority, knowledge, or consent. The Government shall not be liable for any such
                losses.
            </Typography>

            <Typography variant="h5">7. General Disclaimer and Limitation of Liability</Typography>
            <br />
            <Typography variant="body1" styleName="inlineList">
                The information from the digital service are provided on an &quot;as is&quot; basis without warranties
                of any kind. Without prejudice to the other provisions of these Terms, we shall not be liable for any
                losses arising out of or in connection with:
            </Typography>

            <ol styleName="lowerAlphaDoubleBracketListStyle">
                <li>your use of, or visit to, the digital service;</li>
                <li>
                    your failure to follow the instructions, procedures and directions for using the digital service;
                </li>
                <li>your reliance on any statement, opinion, representation or information in the digital service;</li>
                <li>
                    any delay in operation or transmission, communications failure, internet access difficulties or
                    malfunctions in equipment or software (including malfunctions of any computer, terminal connection
                    line, data processing system, transmission link or any other equipment whether or not belonging to
                    us);
                </li>
                <li>any computer virus or other malicious, destructive or corrupting code;</li>
                <li>the conduct or the views of any person who accesses or uses the digital service; and</li>
                <li>any loss of security or information.</li>
            </ol>

            <Typography variant="h5">8. Governing Law</Typography>
            <br />
            <Typography variant="body1">
                These Terms of Use shall be governed and construed in accordance with laws of the Republic of Singapore.
            </Typography>

            <Typography variant="h5">9. Variation</Typography>
            <br />
            <Typography variant="body1">
                We may revise these Terms at any time by updating this page and will provide notification thereof on the
                digital service. You should visit this page from time to time and review the then current Terms because
                they are binding on you. We may modify or discontinue any information or features that form part of the
                digital service at any time, with or without notice to you, and without liability.
            </Typography>
        </>
    );
};
