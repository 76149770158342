import React, {useState} from 'react';
import {DialogActions, Dialog, Typography, Button, ButtonGroup, DialogContent} from '@mui/material';
import {ALLOWED_IMAGE_MIME_TYPES} from '~/constants';
import {FormatAlignCenter, FormatAlignLeft, FormatAlignRight} from '@mui/icons-material';
import {Attachment} from './Attachment';
import './EmbedImageDialog.scss';

// Set Max File Size to 4 MB
const isValidFileSize = (file: File) => file && file.size < 4 * 1024 * 1024;

// Allowed File Types: Excel, Jpeg, PDF, Powerpoint, Word Doc
const isValidFileType = (file: File) => ALLOWED_IMAGE_MIME_TYPES.includes(file.type);

export interface IEmbedImageDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (file: File, alignment: string) => void;
}

export const EmbedImageDialog: React.FC<IEmbedImageDialogProps> = ({open, onClose, onSubmit}) => {
    const [validationError, setValidationError] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [alignment, setAlignment] = useState<string>('left');

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event || !event.target || !event.target.files) return;
        const file = event.target.files[0];

        if (!isValidFileSize(file)) {
            setValidationError('The uploaded file exceeds 4mb. Please try again.');
            return;
        }

        if (!isValidFileType(file)) {
            setValidationError('Invalid file type. Please try again.');
            return;
        }

        setSelectedFile(file);
        setValidationError('');
    };

    const handleClose = () => {
        setSelectedFile(null);
        setAlignment('left');
        setValidationError('');
        onClose();
    };

    const formCompleted = () => !!selectedFile && !!alignment && !validationError;

    const handleSubmit = () => {
        if (!formCompleted()) return;
        selectedFile && onSubmit(selectedFile, alignment);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
            styleName="embedImageDialog"
        >
            <DialogContent>
                <Typography styleName="title">Upload Image</Typography>

                <div styleName="uploadFile">
                    {!selectedFile ? (
                        <>
                            <div styleName="files">
                                <input
                                    id="embedFileInput"
                                    type="file"
                                    name="file"
                                    onChange={onFileChange}
                                    accept={[...ALLOWED_IMAGE_MIME_TYPES].join(',')}
                                    multiple={false}
                                />
                                <span styleName="upload-smallPrint">
                                    We accept jpeg, gif, png, svg file formats with max file size of 4MB.
                                </span>
                                <label htmlFor="file">Browse</label>
                            </div>
                            {validationError && <span styleName="upload-errorMessage">{validationError}</span>}
                        </>
                    ) : (
                        <Attachment file={selectedFile} onRemove={() => setSelectedFile(null)} />
                    )}
                </div>

                <Typography styleName="title">Set Alignment</Typography>
                <ButtonGroup fullWidth styleName="buttonGroup">
                    <Button
                        color="primary"
                        variant={alignment === 'left' ? 'contained' : 'outlined'}
                        size="small"
                        onClick={() => setAlignment('left')}
                    >
                        <FormatAlignLeft />
                    </Button>
                    <Button
                        color="primary"
                        variant={alignment === 'center' ? 'contained' : 'outlined'}
                        size="small"
                        onClick={() => setAlignment('center')}
                    >
                        <FormatAlignCenter />
                    </Button>
                    <Button
                        color="primary"
                        variant={alignment === 'right' ? 'contained' : 'outlined'}
                        size="small"
                        onClick={() => setAlignment('right')}
                    >
                        <FormatAlignRight />
                    </Button>
                </ButtonGroup>
                <Typography styleName="alignmentDesc">{`Image will be ${alignment} aligned.`}</Typography>

                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="text">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!formCompleted()}>
                        Upload
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
