import React from 'react';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {ISubDomainData} from './subDomainData';

export const MockDataComponent: React.FC<{data: ISubDomainData}> = () => {
    return (
        <ListModel
            headers={['Test 1', 'Test 2', 'Test 3']}
            columns={[() => 'The data display', () => 'component for this subdomain', () => 'does not exist yet.']}
            modelList={[{Id: 1}, {Id: 2}, {Id: 3}]}
            variant="paginated"
            modelComparator={[() => 0, () => 0, () => 0]}
        />
    );
};
