import React from 'react';
import {TableRow, TableCell, IconButton} from '@mui/material';
import {EditRounded, DeleteRounded} from '@mui/icons-material';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {IParticipatingAgency} from '~/interfaces/common';
import {formatDate} from '~/utils/dateUtils';

interface IAgencyTableRowProps {
    sortedRows: IParticipatingAgency[];
    editable?: boolean;
    editFunc?: (row: IParticipatingAgency) => void;
    deleteFunc?: (row: IParticipatingAgency) => void;
}

export const AgencyTableRows = ({sortedRows, editable, editFunc, deleteFunc}: IAgencyTableRowProps) => {
    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <React.Fragment key={index}>
                        <TableRow key={`mainRow_${index}`}>
                            <TableCell>{row.Name}</TableCell>
                            <TableCell>{formatDate(new Date(row.DateAdded))}</TableCell>
                            {editable && editFunc && deleteFunc && (
                                <TableCell>
                                    <>
                                        <IconButton
                                            onClick={() => editFunc(row)}
                                            style={{float: 'left', color: '#3949AB', marginLeft: '-10px'}}
                                        >
                                            <EditRounded />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => deleteFunc(row)}
                                            style={{float: 'left', color: '#3949AB'}}
                                        >
                                            <DeleteRounded />
                                        </IconButton>
                                    </>
                                </TableCell>
                            )}
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export interface IAgencyTableProps {
    rows: IParticipatingAgency[];
    editable?: boolean;
    editFunc?: (row: IParticipatingAgency) => void;
    deleteFunc?: (row: IParticipatingAgency) => void;
}

export type IHeadRowData = Pick<IParticipatingAgency, 'Name' | 'DateAdded'>;

export const AgencyTable = ({rows, editable, editFunc, deleteFunc}: IAgencyTableProps) => {
    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {id: 'Name', align: 'left', disablePadding: false, label: 'Agency Name'},
        {id: 'DateAdded', align: 'left', disablePadding: false, label: 'Date Added'},
    ];

    if (editable) {
        headColumns.push({align: 'left', disablePadding: false, label: 'Actions'});
    }

    const headerProps = {
        columns: headColumns,
    };

    const tableSortLabelIconStyle = {
        color: 'var(--primary-blue, #3949ab) !important',
    };

    const hocProps = {
        colSpan: 12,
        headerProps,
        tableSortLabelIconStyle,
        editable,
        editFunc,
        deleteFunc,
    };

    const EnhancedTable = WithEnhancedTable<IAgencyTableRowProps, IHeadRowData, IParticipatingAgency>(
        AgencyTableRows,
        'Name',
        rows,
        'asc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );

    return <EnhancedTable {...hocProps} />;
};
