import React, {useState, useEffect} from 'react';
import {Grid, Typography} from '@mui/material';
import {IDataAccessMatrixDomain} from '~/interfaces/admin';
import {ExpandAllButton} from '~/components/Common/Button/ExpandAllButton';
import {SubDomainDataDictionary} from './SubDomainDataDictionary';

import './DataDictionary.scss';

export interface IDomainDataDictionaryProps {
    domain: IDataAccessMatrixDomain;
    domainIndex: number;
    onExpandAll: boolean;
    mountAllFields?: boolean;
}

export const DomainDataDictionary = ({
    domain,
    domainIndex,
    onExpandAll,
    mountAllFields = false,
}: IDomainDataDictionaryProps) => {
    const [expandAll, setExpandAll] = useState(true);
    const handleButtonClick = () => {
        setExpandAll(!expandAll);
    };
    useEffect(() => {
        setExpandAll(onExpandAll);
    }, [onExpandAll]);

    return (
        <div key={domain.Id} styleName="dataDictionary" id="dataDictionaryDomain">
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant="h5" styleName="domainName" id="domainName">
                        {domain.Name}
                    </Typography>
                </Grid>
                <Grid item xs={4} id="dataDictionaryExpandAll">
                    <ExpandAllButton onClick={handleButtonClick} expandAll={expandAll} />
                </Grid>
            </Grid>

            {domain.SubDomains.map((subDomain) => {
                return (
                    <SubDomainDataDictionary
                        subDomain={subDomain}
                        expandAll={expandAll}
                        key={subDomain.Id}
                        mountAllFields={mountAllFields}
                        domainIndex={domainIndex}
                    />
                );
            })}
        </div>
    );
};
