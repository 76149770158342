import React, {useEffect, useState} from 'react';
import {capitalize} from 'lodash';
import {
    Checkbox,
    Paper,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Collapse,
    Stack,
    Link,
    Snackbar,
} from '@mui/material';
import {
    ExpandLessRounded as ExpandLessIcon,
    ExpandMoreRounded as ExpandMoreIcon,
    ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
} from '@mui/icons-material';
import {IDataAccessMatrixField, IDataFieldGroup} from '~/interfaces/admin';

import './DataAccessMatrixEdit.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    getFieldState,
    getGroupedFieldsCheckedState,
    getGroupedFieldsIndeterminateState,
    getSubDomainMatrixCheckedState,
    getSubDomainMatrixIndeterminateState,
    getSubDomainName,
} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {RejectAccessDataFieldModal} from './RejectAccessDataFieldModal';

import {IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {useGrowl} from '~/utils/hooks/useGrowl';

interface IDataAccessMatrixFieldGroup {
    DataFieldGroup: IDataFieldGroup;
    DataFields: IDataAccessMatrixField[];
}
interface IGroupedFieldsProps {
    groupedFields: IDataAccessMatrixFieldGroup;
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
    openGrowl: (message: string) => void;
}
interface IFieldsProps {
    rows: IDataAccessMatrixField[];
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
    subDomain: IDataAccessMatrixSubDomain;
}

interface IFieldCellProps {
    field: IDataAccessMatrixField;
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
    isGroupedField?: boolean;
}

interface IManageFieldCellProps {
    field: IDataAccessMatrixField;
    opsUnitId: number;
    domainId: number;
    subDomainId: number;
    openGrowl: (message: string) => void;
}

interface ISubDomainHeaderProps {
    subDomain: IDataAccessMatrixSubDomain;
    opsUnitId: number;
    domainId: number;
}

export const FieldCell = ({field, opsUnitId, domainId, subDomainId, isGroupedField = false}: IFieldCellProps) => {
    const dataFieldRequest = useTypedSelector(getFieldState(opsUnitId, domainId, subDomainId, field.Id));
    const isChecked = dataFieldRequest.checked;
    const dispatch = useAppDispatch();

    const checkBoxChangeHandler = () => {
        dispatch(
            dataAccessMatrix2Actions.SET_DATA_FIELD({
                opsUnitId,
                domainId,
                subDomainId,
                fieldId: field.Id,
                selected: {
                    ...dataFieldRequest,
                    checked: !isChecked,
                },
            }),
        );
    };

    return (
        <TableCell style={{width: '50%'}}>
            <Stack direction="row" style={{marginLeft: `${isGroupedField ? 60 : 30}px`}}>
                <Checkbox
                    id={field.Id.toString()}
                    color={'primary'}
                    disableRipple
                    styleName="fieldCheckbox"
                    onChange={checkBoxChangeHandler}
                    checked={isChecked}
                />
                <Typography styleName="fieldCell" component="span" variant="body1">
                    {field.Name}
                </Typography>
            </Stack>
        </TableCell>
    );
};

export const ManageCell = ({field, opsUnitId, domainId, subDomainId, openGrowl}: IManageFieldCellProps) => {
    const dataFieldRequest = useTypedSelector(getFieldState(opsUnitId, domainId, subDomainId, field.Id));
    const subDomainName = useTypedSelector(getSubDomainName(domainId, subDomainId));
    const [isEditingReasonForRejection, setIsEditingReasonForRejection] = useState<boolean>(false);

    const modal = dataFieldRequest.requestStatus.toLocaleLowerCase() === 'sent for review' && (
        <RejectAccessDataFieldModal
            open={isEditingReasonForRejection}
            onClose={() => setIsEditingReasonForRejection(false)}
            selectedDataFieldForRejection={dataFieldRequest.dataFieldId}
            subDomainName={subDomainName}
            subDomainId={subDomainId}
            opsUnitId={opsUnitId}
            domainId={domainId}
            openGrowl={openGrowl}
        />
    );

    if (dataFieldRequest.requestStatus.toLocaleLowerCase() === 'sent for review') {
        return (
            <TableCell style={{width: '25%'}}>
                <Link
                    onClick={() => setIsEditingReasonForRejection(true)}
                    color="primary"
                    underline="none"
                    style={{
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        wordSpacing: '2px',
                        lineHeight: 1.75,
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        fontSize: '14px',
                    }}
                >
                    Reject access
                    <ArrowForwardIosOutlinedIcon style={{fontSize: '12px', marginLeft: '8px'}} />
                </Link>
                {modal}
            </TableCell>
        );
    } else {
        return <TableCell style={{width: '25%'}}></TableCell>;
    }
};

export const RequestStatusCell = ({field, opsUnitId, domainId, subDomainId}: IFieldCellProps) => {
    const dataFieldRequest = useTypedSelector(getFieldState(opsUnitId, domainId, subDomainId, field.Id));

    return (
        <TableCell style={{width: '25%'}}>
            <Typography styleName="fieldCell">{capitalize(dataFieldRequest.requestStatus || '')}</Typography>
        </TableCell>
    );
};

export const GroupedFields = ({groupedFields, opsUnitId, domainId, subDomainId, openGrowl}: IGroupedFieldsProps) => {
    const [expand, setExpand] = useState<boolean>(true);
    const [fieldIds, setFieldIds] = useState<number[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const groupedFieldIds = groupedFields.DataFields.map((field) => field.Id);
        setFieldIds(groupedFieldIds);
    }, []);

    const isChecked = useTypedSelector(getGroupedFieldsCheckedState(opsUnitId, domainId, subDomainId, fieldIds));
    const isIndeterminate = useTypedSelector(
        getGroupedFieldsIndeterminateState(opsUnitId, domainId, subDomainId, fieldIds),
    );

    const groupedFieldChangeHandler = () => {
        const payload = fieldIds.map((id) => ({
            opsUnitId,
            domainId,
            subDomainId,
            fieldId: id,
            selected: !isChecked,
        }));

        dispatch(dataAccessMatrix2Actions.SET_GROUPED_FIELDS(payload));
    };
    return (
        <>
            <TableRow>
                <TableCell onClick={() => setExpand(!expand)} colSpan={3}>
                    <Typography component="span" variant="body1" style={{marginLeft: '30px'}}>
                        <Checkbox
                            color={'primary'}
                            disableRipple
                            styleName="fieldCheckbox"
                            checked={isChecked}
                            indeterminate={isIndeterminate}
                            onClick={(e) => e.stopPropagation()}
                            onChange={groupedFieldChangeHandler}
                        />
                        <span styleName="fieldCell">{groupedFields.DataFieldGroup.Name}</span>
                        <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: '0', border: '0'}} colSpan={3}>
                    <Collapse in={expand} timeout={500} unmountOnExit>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {groupedFields.DataFields.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <FieldCell
                                                    field={row}
                                                    opsUnitId={opsUnitId}
                                                    domainId={domainId}
                                                    subDomainId={subDomainId}
                                                    isGroupedField={true}
                                                />
                                                <RequestStatusCell
                                                    field={row}
                                                    opsUnitId={opsUnitId}
                                                    domainId={domainId}
                                                    subDomainId={subDomainId}
                                                />
                                                <ManageCell
                                                    field={row}
                                                    opsUnitId={opsUnitId}
                                                    domainId={domainId}
                                                    subDomainId={subDomainId}
                                                    openGrowl={openGrowl}
                                                />
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export const FieldsHeader = ({subDomain, opsUnitId, domainId}: ISubDomainHeaderProps) => {
    const dispatch = useAppDispatch();

    const isChecked = useTypedSelector(getSubDomainMatrixCheckedState(opsUnitId, domainId, subDomain.Id));
    const isIndeterminate = useTypedSelector(getSubDomainMatrixIndeterminateState(opsUnitId, domainId, subDomain.Id));

    const subDomainCheckBoxChangeHandler = () => {
        const payload = subDomain.DataFields.map(({Id}) => ({
            opsUnitId,
            domainId,
            subDomainId: subDomain.Id,
            fieldId: Id,
            selected: !isChecked,
        }));

        dispatch(dataAccessMatrix2Actions.SET_GROUPED_FIELDS(payload));
    };
    return (
        <TableRow styleName="subDomainReasonHeaderRow">
            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '50%'}}>
                <Checkbox
                    color={'primary'}
                    disableRipple
                    styleName="subDomainCheckbox"
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={subDomainCheckBoxChangeHandler}
                />
                Approve access
            </TableCell>
            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '25%'}}>
                Request status
            </TableCell>
            <TableCell styleName="subDomainReasonHeaderCell" style={{width: '25%'}}>
                Manage
            </TableCell>
        </TableRow>
    );
};

const FieldCellMemo = React.memo(FieldCell);

export const Fields = ({rows, opsUnitId, domainId, subDomainId, subDomain}: IFieldsProps) => {
    const [groupedRows, setGroupedRows] = useState<(IDataAccessMatrixField | IDataAccessMatrixFieldGroup)[]>([]);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    useEffect(() => {
        const reducedRows = rows.reduce((acc, curr) => {
            if (!curr.DataFieldGroup) {
                acc.push(curr);
            } else {
                let grouped = false;
                acc.forEach((item) => {
                    if (
                        item.DataFieldGroup &&
                        item.DataFieldGroup?.Id === curr.DataFieldGroup?.Id &&
                        'DataFields' in item
                    ) {
                        item.DataFields = [...item.DataFields, curr];
                        grouped = true;
                    }
                });

                if (!grouped) {
                    acc.push({
                        DataFieldGroup: curr.DataFieldGroup,
                        DataFields: [curr],
                    });
                }
            }

            return acc;
        }, [] as (IDataAccessMatrixField | IDataAccessMatrixFieldGroup)[]);
        setGroupedRows(reducedRows);
    }, [rows]);

    return (
        <>
            <Paper style={{borderRadius: '0'}}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <FieldsHeader subDomain={subDomain} opsUnitId={opsUnitId} domainId={domainId} />
                            {groupedRows.map((row, index) => {
                                return 'Name' in row ? (
                                    <TableRow key={row.Id}>
                                        <FieldCellMemo
                                            field={row}
                                            opsUnitId={opsUnitId}
                                            domainId={domainId}
                                            subDomainId={subDomainId}
                                        />
                                        <RequestStatusCell
                                            field={row}
                                            opsUnitId={opsUnitId}
                                            domainId={domainId}
                                            subDomainId={subDomainId}
                                        />
                                        <ManageCell
                                            field={row}
                                            opsUnitId={opsUnitId}
                                            domainId={domainId}
                                            subDomainId={subDomainId}
                                            openGrowl={openGrowl}
                                        />
                                    </TableRow>
                                ) : (
                                    <GroupedFields
                                        key={index}
                                        groupedFields={row}
                                        opsUnitId={opsUnitId}
                                        domainId={domainId}
                                        subDomainId={subDomainId}
                                        openGrowl={openGrowl}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                    bottom: 90,
                }}
            />
        </>
    );
};
