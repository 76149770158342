import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box, Typography, Grid, Link, IconButton} from '@mui/material';
import {CheckCircle, Close} from '@mui/icons-material';
import {IAdminRow, IMonthlyReviewResponse, ITeam} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {COPIES, LEARN_MORE_LINK, LOADING_MESSAGE, MONTHLY_REVIEW_STATUS} from '~/constants';
import {getAbbreviationFromBrackets} from '~/utils/nameUtils';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {TableButton} from '~/components/Common/Button/TableButton';
import {InfoIcon} from '~/components/Common/Icons';
import {KeyboardArrowRightRounded as KeyboardArrowRight} from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';
import {getEmailDirectAdminLink} from '~/utils/contentUtils';

import {useGrowl} from '~/utils/hooks/useGrowl';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setOpsUnit} from '~/store/opsUnit/opsUnit.thunk';
import {getTeams} from '~/store/team/team.selector';
import {setTeams} from '~/store/team/team.thunk';
import {getOpsUnitFetchState, getTeamsFetchState} from '~/store/fetchState/fetchState.selector';
import {createMonthlyAdminReview, getRequiresMonthlyAdminReview} from '~/services/adminServices';

import './TeamList.scss';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {formatDate} from '~/utils/dateUtils';
import {forEach, size} from 'lodash';
import {AdminDetails} from '~/components/Admin/AdminReview/AdminDetails';

export const TeamList = () => {
    const {opsUnitId} = useParams<{opsUnitId?: string}>();
    const {accessToken} = useContext(AuthenticationContext);
    const teams = Object.values(useTypedSelector(getTeams(Number(opsUnitId))));
    const dispatch = useAppDispatch();
    const opsUnitDetails = useTypedSelector((state) => state.data.admin.opsUnits[Number(opsUnitId)]);

    const history = useHistory();
    const opsUnitFetchState = useTypedSelector(getOpsUnitFetchState);
    const teamsFetchState = useTypedSelector(getTeamsFetchState);
    const fetchStatus = consolidateFetchState([opsUnitFetchState, teamsFetchState]);
    const [teamWithNoAdmin, setTeamWithNoAdmin] = useState<ITeam | undefined>();
    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    let agencyName = (accessToken as IAccessToken).AdditionalUserInfo.Agency || '';
    agencyName = getAbbreviationFromBrackets(agencyName) !== '' ? getAbbreviationFromBrackets(agencyName) : agencyName;
    const opsUnitName = (accessToken as IAccessToken).AdditionalUserInfo.OpsUnit;

    const handleEdit = (teamId: number) => {
        history.push(`/admin/ops-units/${opsUnitId}/teams/${teamId}`);
    };

    const handleClick = () => {
        history.push(`/admin/ops-units/${opsUnitId}/teams/create`);
    };

    const handleReviewSubmit = () => {
        createMonthlyAdminReview()
            .then(() => {
                openGrowl(COPIES.SUBMIT_MONTHLY_REVIEW_MESSAGE.replace('zz', 'Agency Admin(s)'));
                getRequiresMonthlyAdminReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const checkAllTeamReviewed = (teams: IAdminRow[]) => {
        return teams.every(
            (team) => team.Status === MONTHLY_REVIEW_STATUS.COMPLETED || team.Status === MONTHLY_REVIEW_STATUS.NEW,
        );
    };
    const getDirectAdminEmails = () => {
        if (opsUnitDetails.Agency && opsUnitDetails.Agency.Admins) {
            const formatAAEmailRecipients = opsUnitDetails.Agency?.Admins?.map((admin) => admin.Email).join(',');
            return getEmailDirectAdminLink(
                formatAAEmailRecipients,
                accessToken?.Permissions.AccessLevel,
                opsUnitDetails.Name,
            );
        }
    };

    useEffect(() => {
        dispatch(setOpsUnit(Number(opsUnitId)));
        dispatch(setTeams(Number(opsUnitId)));
        getRequiresMonthlyAdminReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const teamName = (team: IAdminRow) => (team.OpsUnitId ? team.Name : 'Ops Unit Admin');

    const teamLastReviewDetails = (team: IAdminRow) =>
        team.OpsUnitId && team.MostRecentReview ? (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {team.MostRecentReview != '0001-01-01T00:00:00Z'
                        ? formatDate(new Date(team.MostRecentReview), false)
                        : '-'}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {team.RecentReviewBy}
                </Box>
            </Box>
        ) : (
            '-'
        );
    const teamLastReviewDateAndName = (team: IAdminRow): Date =>
        team.OpsUnitId && team.MostRecentReview ? new Date(team.MostRecentReview) : new Date(0, 0, 0, 0, 0, 0);

    const teamCurrentReviewStatus = (team: IAdminRow) => {
        if (team.OpsUnitId) {
            if (team.Status === MONTHLY_REVIEW_STATUS.COMPLETED) {
                return (
                    <Box component="div" styleName="reviewWrapper" id="statusOfReview">
                        Completed in{' '}
                        {new Date().toLocaleDateString('en-US', {
                            month: 'short',
                            year: 'numeric',
                        })}
                    </Box>
                );
            } else if (team.Status === MONTHLY_REVIEW_STATUS.ONGOING) {
                return (
                    <Box component="div" styleName="reviewStatusWrapper" id="statusOfReview">
                        Pending Review
                    </Box>
                );
            } else if (team.Status === MONTHLY_REVIEW_STATUS.NEW && team.CreatedAt) {
                const startReviewDate = new Date(
                    new Date(team.CreatedAt).setMonth(new Date(team.CreatedAt).getMonth() + 1),
                );
                return (
                    <Box component="div" styleName="reviewStatusWrapper" id="statusOfReview">
                        {`Starts in ${startReviewDate.toLocaleString('en-US', {month: 'short', year: 'numeric'})}`}
                    </Box>
                );
            } else {
                return (
                    <Box component="div" styleName="reviewStatusWrapper" id="statusOfReview">
                        No review recorded
                    </Box>
                );
            }
        }
        return '-';
    };
    const teamCurrentReviewDate = (team: IAdminRow): Date =>
        team.OpsUnitId && team.Status === 'COMPLETED' ? new Date() : new Date(0, 0, 0, 0, 0, 0);

    const teamAdminControls = (team: IAdminRow) =>
        team.Admins?.length == 0 && team.OpsUnitId ? (
            <TableButton text="Add" onClick={() => handleEdit(team.Id as number)} />
        ) : team.OpsUnitId ? (
            <TableButton text="Edit" onClick={() => handleEdit(team.Id as number)} />
        ) : (
            <Link href={getDirectAdminEmails()} target="_blank" rel="noopener noreferrer">
                <Box display="flex" alignItems="center">
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '16px',
                            letterSpacing: '1.25px',
                            color: '#3949AB',
                        }}
                        variant="body2"
                    >
                        EMAIL{<br />}AGENCY{<br />}ADMIN
                    </Typography>
                    <NavigateNextIcon fontSize="small" />
                </Box>
            </Link>
        );

    const adminNameAndDesignation =
        (index: number) =>
        (team: IAdminRow): string =>
            team && team.Admins && team.Admins[index] ? team.Admins[index].Name + team.Admins[index].Designation : '';

    const hasTeamWithNoAdmin = () => {
        if (teams.length > 0) {
            const teamsWithNoAdmin = teams.filter((team) => team.Admins?.length == 0);

            if (teamsWithNoAdmin.length > 0) {
                setTeamWithNoAdmin(teamsWithNoAdmin[0]);
            }

            return teamsWithNoAdmin.length > 0;
        }

        return false;
    };
    const customRowStyle = (adminRow: IAdminRow) => {
        return adminRow.OpsUnitId ? {} : {background: '#F0F0F0'};
    };
    const customCellStyle = (adminRow: IAdminRow) => {
        return adminRow.Name || 'Ops Unit Admin' ? {width: '20%'} : {};
    };

    const shouldShowBanner = () => {
        const teamsWithAdmin: Boolean[] = [];
        forEach(teams, function (value) {
            if (value.Admins && value.Admins?.length > 0) {
                teamsWithAdmin.push(true);
            } else {
                teamsWithAdmin.push(false);
            }
        });
        if (size(teams) > 1 && !teamsWithAdmin.every((value) => value)) {
            return true;
        }
        return teamsWithAdmin.every((value) => value);
    };

    const disableButton = () => {
        const teamsWithAdmin: Boolean[] = [];
        forEach(teams, function (value) {
            if (value.Admins && value.Admins?.length > 0 && value.Admins?.some((admin) => admin.IsActive === true)) {
                teamsWithAdmin.push(true);
            } else {
                teamsWithAdmin.push(false);
            }
        });
        return size(teams) > 1 && !teamsWithAdmin.every((value) => value);
    };

    const getTeamsListContent = () => {
        const domainHeaderRef = useRef<HTMLDivElement>(null);
        const [searchValue, setSearchValue] = useState('');
        const filteredTeams = teams.filter((team) => team.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        const allAdminRows = opsUnitDetails ? [opsUnitDetails, ...filteredTeams] : filteredTeams;

        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Admin Management
                        </Typography>
                        <Typography component="h1" variant="h5">
                            {`${agencyName} / ${opsUnitName}`}
                        </Typography>
                    </Grid>
                    {shouldShowBanner() &&
                        monthlyReviewStatus &&
                        monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW &&
                        teams.length > 0 && (
                            <ReviewBanner
                                monthlyReviewStatus={monthlyReviewStatus}
                                handleReviewSubmit={handleReviewSubmit}
                                disabled={!checkAllTeamReviewed(teams) || disableButton()}
                                messageCallToAction={
                                    <Link
                                        display="inline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={LEARN_MORE_LINK}
                                    >
                                        Learn more {'>'}
                                    </Link>
                                }
                            />
                        )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="team-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search Team Name"
                                type="search"
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary
                                text="Add Team"
                                onClick={handleClick}
                                id="add-team-button"
                                disabled={hasTeamWithNoAdmin()}
                            />
                        </Grid>
                    </Grid>

                    {teamWithNoAdmin && (
                        <Grid item>
                            <Box styleName="missingTeamAdminMessage" id="teamWithNoAdminInfo">
                                <InfoIcon />
                                <span>
                                    To add another team, please add a Team Admin to the existing team ‘
                                    {teamWithNoAdmin && teamWithNoAdmin?.Name}’.
                                </span>
                                <Link
                                    href={`/admin/ops-units/${opsUnitId}/teams/${teamWithNoAdmin?.Id}`}
                                    style={{letterSpacing: '1.25px'}}
                                >
                                    Add Team Admin
                                    <KeyboardArrowRight />
                                </Link>
                            </Box>
                        </Grid>
                    )}

                    <Grid item>
                        {teams.length > 0 ? (
                            <ListModel
                                columns={[
                                    teamName,
                                    AdminDetails(0, true),
                                    AdminDetails(1, true),
                                    teamLastReviewDetails,
                                    teamCurrentReviewStatus,
                                    teamAdminControls,
                                ]}
                                headers={[
                                    'Team Name',
                                    'Admin 1',
                                    'Admin 2',
                                    'Last review of user accounts',
                                    'Status of review of user accounts',
                                    '',
                                ]}
                                modelList={allAdminRows}
                                modelComparator={[
                                    getAlphabeticalComparator(teamName),
                                    getAlphabeticalComparator(adminNameAndDesignation(1)),
                                    getAlphabeticalComparator(adminNameAndDesignation(2)),
                                    getDateComparator(teamLastReviewDateAndName),
                                    getDateComparator(teamCurrentReviewDate),
                                    () => 0,
                                ]}
                                customRowStyle={customRowStyle}
                                customCellStyle={customCellStyle}
                                variant="paginated"
                                hover
                                numberOfFixedRows={1}
                            />
                        ) : (
                            <EmptyList entity="Team" handleClick={handleClick} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: null,
                            message:
                                growl.message.length > 0 ? (
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" alignItems="center" marginRight={1.75}>
                                            <CheckCircle />
                                        </Box>

                                        {growl.message}
                                    </Box>
                                ) : undefined,
                            action: (
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>
            </>
        );
    };

    const TeamsListWithAdminLoader = WithAdminLoader<{}>(getTeamsListContent, fetchStatus, LOADING_MESSAGE.DATA);

    return <TeamsListWithAdminLoader />;
};
