import React from 'react';
import {Grid} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {formatValue} from '~/utils/contentUtils';
import {dateFormatter} from '~/utils/dateUtils';
import {StatusObject} from '../SubDomainDisplay';
import {NoData} from '../CompletedSubDomain';
import {SubDomainStatus} from '~/interfaces/client';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {SUBDOMAIN_RESULT_MESSAGE, COPIES} from '~/constants';
import {ErrorSubDomain} from '../ErrorSubDomain';

// export interface ILongTermVisitorPass {
export interface ICHAS {
    CHAS: {
        DateOfIssue: string;
        DateOfExpiry: string;
        Indicator: string;
        CardType: string;
        ProcessingStatusCode: string;
    };
}

const CHASNAME = 'CHAS';
const CHAS_SUCCESSFUL_PROCESSING_CODE = 'S601000';

export const CHAS: React.FunctionComponent<{
    data: ICHAS;
    status: StatusObject;
}> = ({data, status}) => {
    let processingCode = '';
    const noData: boolean = CHAS_SUCCESSFUL_PROCESSING_CODE !== data.CHAS.ProcessingStatusCode;
    if (noData) {
        processingCode = data.CHAS.ProcessingStatusCode;
    }

    // let authError = errorMessages.includes("IDG_MALFORMED_RESPONSE") || errorMessages.includes("IDG_UNAUTHORIZED")|| errorMessages.includes("IDG_TIMEOUT")
    let isError = false;

    if (status[CHASNAME] == SubDomainStatus.ERROR) {
        isError = true;
        //display default error and return
    }

    const fields = [
        {
            label: 'CHAS Indicator',
            value: data.CHAS.Indicator,
        },
        {
            label: 'CHAS Card Validity Start Date',
            value: formatValue(data.CHAS.DateOfIssue, dateFormatter),
        },
        {
            label: 'CHAS Card Validity End Date',
            value: formatValue(data.CHAS.DateOfExpiry, dateFormatter),
        },
        {
            label: 'CHAS Type',
            value: data.CHAS.CardType,
        },
    ];
    if (noData) {
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${Boolean(processingCode) ? `(${processingCode})` : ''}`}
            />
        );
    }
    if (isError) {
        return (
            <>
                <ErrorMessage message={COPIES.CLIENT_INFO_ERROR} style={{marginBottom: '16px'}} />
                <Grid item xs={12}>
                    <ErrorSubDomain includeHeader={false} />
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid container spacing={3}>
                <SimpleGridDisplay fields={fields} />
            </Grid>
        </>
    );
};
