import * as React from 'react';
import {ClickAwayListener, Grid} from '@mui/material';
import {IAnnouncement} from '~/interfaces/admin';
import {useState} from 'react';

import {DateInput} from '~/components/Common/DateInput/DateInput';
import {TimeInput} from '~/components/Common/TimeInput/TimeInput';

export interface IDateTimeRangePickerProps {
    announcement: IAnnouncement;
    setAnnouncement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
    setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const updateTime = (date: Date, time: Date): Date => {
    const currentDate = date && new Date(date);
    time.setFullYear(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    return time;
};

export const DateTimeRangePicker: React.FunctionComponent<IDateTimeRangePickerProps> = ({
    announcement,
    setAnnouncement,
    setHasError,
}) => {
    const defaultDate = new Date(Date.now());
    defaultDate.setDate(defaultDate.getDate() + 1);

    const [startDate, setStartDate] = useState(announcement.startDate ? new Date(announcement.startDate) : new Date());
    const [endDate, setEndDate] = useState(announcement.endDate ? new Date(announcement.endDate) : defaultDate);

    const [openDateFrom, setOpenDateFrom] = useState(false);
    const [openDateTo, setOpenDateTo] = useState(false);

    const handleDateFromClickAway = () => {
        setOpenDateFrom(false);
    };
    const handleDateToClickAway = () => {
        setOpenDateTo(false);
    };

    const handleStartDateChange = (startDate: Date) => {
        setAnnouncement({...announcement, startDate: startDate});
        setStartDate(startDate);
    };
    const handleEndDateChange = (endDate: Date) => {
        setAnnouncement({...announcement, endDate: endDate});
        setEndDate(endDate);
    };

    const handleStartTimeChange = (startTime: Date) => {
        const newDate = announcement.startDate && updateTime(announcement.startDate, startTime);
        setAnnouncement({...announcement, startDate: newDate});
        setStartDate(startTime);
    };
    const handleEndTimeChange = (endTime: Date) => {
        const newDate = announcement.endDate && updateTime(announcement.endDate, endTime);
        setAnnouncement({...announcement, endDate: newDate});
        setEndDate(endTime);
    };

    return (
        <>
            <Grid container alignItems="baseline" style={{paddingLeft: '12px', paddingTop: '16px'}}>
                <Grid item xs={2}>
                    Starts
                </Grid>
                <ClickAwayListener onClickAway={handleDateFromClickAway}>
                    <Grid item style={{paddingLeft: '26px'}}>
                        <DateInput
                            label=""
                            value={startDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(v) => handleStartDateChange(v as unknown as Date)}
                            selectOnly={true}
                            open={openDateFrom}
                            setOpen={setOpenDateFrom}
                        />
                    </Grid>
                </ClickAwayListener>
                <Grid item xs={3} style={{paddingLeft: '16px'}}>
                    <TimeInput
                        data-testid="startTimeInput"
                        value={startDate}
                        maxTime={endDate}
                        onChange={handleStartTimeChange}
                        setHasError={setHasError}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="baseline" style={{paddingLeft: '12px', paddingTop: '16px'}}>
                <Grid item xs={2}>
                    Ends
                </Grid>
                <ClickAwayListener onClickAway={handleDateToClickAway}>
                    <Grid item style={{paddingLeft: '26px'}}>
                        <DateInput
                            label=""
                            value={endDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(v) => handleEndDateChange(v as unknown as Date)}
                            selectOnly={true}
                            open={openDateTo}
                            setOpen={setOpenDateTo}
                        />
                    </Grid>
                </ClickAwayListener>
                <Grid item xs={3} style={{paddingLeft: '16px'}}>
                    <TimeInput data-testid="endTimeInput" value={endDate} onChange={handleEndTimeChange} />
                </Grid>
            </Grid>
        </>
    );
};
