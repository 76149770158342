import {Card, CardContent, Divider, Grid, Typography} from '@mui/material';
import React, {useRef} from 'react';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {COPIES, INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH, NO_RESULT_COPY} from '~/constants';
import styles from './MedicalExpensesAndAssistance.scss';

import {CollapsibleCard} from '~/components/Common/Cards/CollapsibleCard';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {SuccessMessage, UnsuccessfulMessage} from '~/components/Common/IconMessage/IconMessage';
import {
    allNoAccess,
    allNoResults,
    allNoResultsOrNull,
    extractAllValues,
    formatDateField,
    formatValue,
    isNoAccess,
    isNotEmpty,
} from '~/utils/contentUtils';
import {formatCurrency} from '~/utils/currencyUtils';
import {NoData} from '../CompletedSubDomain';
import {StatusObject} from './../SubDomainDisplay';

export type IMedicalExpensesAndAssistanceData = {
    HasMedifundInLast12Months: string;
    ExpenseRecords: IExpenseRecord[];
};

enum DATA_SOURCE {
    API = 'MOH',
    SFT = '',
}
interface IExpenseRecord {
    ClinicName: string;
    BillDate: string;
    GDFAS: IFinancialAssistance[];
    DFAS: IFinancialAssistance[];
    FAGovt: string;
    FANonGovt: string;
    SelfPaid: string;
    SelfOutstanding: string;
    SelfWriteoff: string;
    AdmissionDate: string;
    AdmissionType: string;
    DischargeDate: string;
    ServiceType: string;
}

interface IFinancialAssistance {
    Id?: number;
    Amount: string;
    Type: string;
}

export const prependDollar = (value: string): string => {
    return `$${value}`;
};

const CompulsorySection = (expenseRecord: IExpenseRecord, index: number) => {
    const {ClinicName, BillDate, AdmissionDate, AdmissionType, DischargeDate, ServiceType} = expenseRecord;
    const fields = [
        {
            label: 'Name of Institution',
            value: isNoAccess(ClinicName) ? (
                ClinicName
            ) : (
                <Typography variant="h6" data-testid={`clinic_name_${index}`}>
                    {ClinicName}
                </Typography>
            ),
            fullWidth: false,
        },
        {label: 'Date of Admission', value: formatDateField(AdmissionDate, true, true)},
        {label: 'Date of Bill', value: formatDateField(BillDate, true, true)},
        {label: 'Date of Discharge', value: formatDateField(DischargeDate, true, true)},
        {label: 'Service Type', value: ServiceType},
        {label: 'Admission Type', value: AdmissionType},
    ];

    return (
        <Grid container spacing={2} style={{paddingTop: '10px'}}>
            <SimpleGridDisplay fields={fields} />
        </Grid>
    );
};

const expenseRecordToComponent = (expenseRecord: IExpenseRecord, index: number) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const {DFAS, GDFAS, SelfPaid, SelfOutstanding, SelfWriteoff} = expenseRecord;

    const fieldsToCheck = extractAllValues(DFAS).concat(
        extractAllValues(GDFAS),
        extractAllValues([SelfPaid]),
        extractAllValues([SelfOutstanding]),
        extractAllValues([SelfWriteoff]),
    );

    if (allNoAccess(fieldsToCheck)) {
        return (
            <Card styleName={'card'} data-testid={index}>
                <CardContent>
                    <div ref={scrollRef}>{CompulsorySection(expenseRecord, index)}</div>
                </CardContent>
            </Card>
        );
    }

    // eslint-disable-next-line react/jsx-key
    return (
        <CollapsibleCard
            key={`medical_expenses_item_${index}`}
            compulsorySection={CompulsorySection(expenseRecord, index)}
            data-testid={`medical_expenses_item_${index}`}
        >
            <MainSection expenseRecord={expenseRecord} data-testid="mainSection" />
        </CollapsibleCard>
    );
};

export const MainSection = ({expenseRecord}: {expenseRecord: IExpenseRecord}) => {
    return (
        <Grid container spacing={2}>
            <Grid data-testid="divider" item xs={12}>
                <Divider
                    style={{
                        marginTop: 24,
                        marginBottom: 10,
                    }}
                />
            </Grid>
            <Grid data-testid="medicalExpensesPaylentsSection" item xs={12}>
                <PaymentTypeComponent expenseRecord={expenseRecord}></PaymentTypeComponent>
            </Grid>
        </Grid>
    );
};

export const PaymentTypeComponent: React.FC<{expenseRecord: IExpenseRecord}> = ({expenseRecord}) => {
    const {GDFAS, DFAS, SelfPaid, SelfOutstanding, SelfWriteoff} = expenseRecord;
    const fields = [];

    const gdfas = FormatFinancialAssistance(
        GDFAS,
        'Discretionary Financial Assistance (Government)',
        fields.length + 1,
    );
    if (gdfas != null) {
        fields.push(gdfas);
    }

    const dfas = FormatFinancialAssistance(
        DFAS,
        'Discretionary Financial Assistance (Non-Government)',
        fields.length + 1,
    );
    if (dfas != null) {
        fields.push(dfas);
    }

    const displayValues = [`\n`];
    if (!isNoAccess(SelfPaid)) {
        displayValues.push(prependDollar(SelfPaid));
    } else {
        displayValues.push(SelfPaid);
    }

    if (!isNoAccess(SelfOutstanding)) {
        displayValues.push(prependDollar(SelfOutstanding));
    } else {
        displayValues.push(SelfOutstanding);
    }

    if (!isNoAccess(SelfWriteoff)) {
        displayValues.push(prependDollar(SelfWriteoff));
    } else {
        displayValues.push(SelfWriteoff);
    }

    if (!allNoAccess([SelfOutstanding, SelfWriteoff, SelfPaid])) {
        fields.push({
            Id: fields.length + 1,
            label: `Self-Payer\n\nPaid\nOutstanding\nBad Debt Write-Off`,
            value: displayValues.join(`\n`),
        });
    }

    return (
        <div id={styles.customTable} style={{marginTop: '24px'}}>
            <ListModel
                customRowStyle={(): React.CSSProperties => ({whiteSpace: 'pre', color: 'red'})}
                columns={[(i) => boldSectionHeaders(i.label), (i) => i.value]}
                headers={['Payment Type', 'Amount ($)']}
                modelList={fields}
                variant="expanded"
                defaultSortIndex={2}
                defaultSortOrder="desc"
                dataTestId="paymentTypes"
            />
        </div>
    );
};

const boldSectionHeaders = (label: string) => {
    const text = label.split(/\n+/);
    const header = text[0];
    return (
        <>
            <b>{header}</b>
            <br></br>
            <br></br>
            {text.slice(1).join('\n')}
        </>
    );
};

const FormatFinancialAssistance = (financialAssistance: IFinancialAssistance[], title: string, id: number) => {
    const tempLabel = [title.concat(`\n`)];
    const tempValue = [`\n`];
    if (allNoAccess(extractAllValues(financialAssistance))) {
        return;
    }
    if (allNoResultsOrNull(extractAllValues(financialAssistance))) {
        return {Id: id, label: tempLabel.join(`\n`), value: NO_RESULT_COPY};
    }
    financialAssistance.map((fa) => {
        tempLabel.push(fa.Type);
        tempValue.push(prependDollar(fa.Amount));
    });

    return {Id: id, label: tempLabel.join(`\n`), value: tempValue.join(`\n`)};
};

export const MedicalExpensesAndAssistance = ({
    data,
    status,
}: {
    data: IMedicalExpensesAndAssistanceData;
    status: StatusObject;
}) => {
    let sortedExpenseRecords: IExpenseRecord[] = [];
    if (data.ExpenseRecords) {
        sortedExpenseRecords = data.ExpenseRecords.slice().sort((a, b) => {
            return new Date(b.BillDate).getTime() - new Date(a.BillDate).getTime();
        });
    }

    if (status[DATA_SOURCE.API]) {
        return MedicalExpensesAndAssistanceV2({
            expenseRecords: sortedExpenseRecords,
            hasMedifundInLast12Months: data.HasMedifundInLast12Months,
        });
    }
    return MedicalExpensesAndAssistanceV1({data});
};

// for MedicalExpensesAndAssistanceV1
const expenseRecordToFields = (expenseRecord: IExpenseRecord, index: number) => {
    const {
        ClinicName,
        BillDate,
        FAGovt,
        FANonGovt,
        SelfPaid,
        SelfOutstanding,
        SelfWriteoff,
        AdmissionDate,
        DischargeDate,
        ServiceType,
    } = expenseRecord;

    return [
        {
            label: 'Name of Health Institution',
            value: isNoAccess(ClinicName) ? (
                ClinicName
            ) : (
                <Typography variant="h6" data-testid={`clinic_name_${index}`}>
                    {ClinicName}
                </Typography>
            ),
            fullWidth: true,
        },
        {label: 'Date of Bill', value: formatDateField(BillDate, true, true)},
        {label: 'Service Type', value: ServiceType},
        {label: 'Date of Admission', value: formatDateField(AdmissionDate, true, true)},
        {label: 'Date of Discharge', value: formatDateField(DischargeDate, true, true)},
        {label: 'Financial Assistance (Government)', value: `${formatValue(FAGovt, formatCurrency, prependDollar)}`},
        {
            label: 'Financial Assistance (Non-Government)',
            value: `${formatValue(FANonGovt, parseFloat, formatCurrency, prependDollar)}`,
        },
        {label: 'Self-Payer (Paid)', value: `${formatValue(SelfPaid, parseFloat, formatCurrency, prependDollar)}`},
        {label: 'Self-Payer (Outstanding)', value: `${formatValue(SelfOutstanding, formatCurrency, prependDollar)}`},
        {
            label: 'Self-Payer (Bad Debt Writeoff)',
            value: `${formatValue(SelfWriteoff, parseFloat, formatCurrency, prependDollar)}`,
        },
    ];
};
export const MedicalExpensesAndAssistanceV1 = ({data}: {data: IMedicalExpensesAndAssistanceData}) => {
    let sortedExpenseRecords: IExpenseRecord[] = [];
    if (data.ExpenseRecords) {
        sortedExpenseRecords = data.ExpenseRecords.slice().sort((a, b) => {
            return new Date(b.BillDate).getTime() - new Date(a.BillDate).getTime();
        });
    }
    const fieldsToRender = sortedExpenseRecords.map(expenseRecordToFields);

    const noExpenseRecordResults = allNoResults(extractAllValues(data.ExpenseRecords));
    const noData = noExpenseRecordResults && !isNotEmpty(data.HasMedifundInLast12Months);

    return noData ? (
        <NoData />
    ) : (
        <>
            {data.HasMedifundInLast12Months &&
                isNotEmpty(data.HasMedifundInLast12Months) &&
                (data.HasMedifundInLast12Months === 'Y' ? (
                    <SuccessMessage message={COPIES.HAS_MEDIFUND_ASSISTANCE} data-testid="hasMedifundAssistance" />
                ) : (
                    <UnsuccessfulMessage message={COPIES.NO_MEDIFUND_ASSISTANCE} data-testid="hasMedifundAssistance" />
                ))}
            {noExpenseRecordResults ? (
                <Typography id="body" variant="body2" data-testid="no_medical_expense">
                    {COPIES.NO_MEDICAL_EXPENSE_RECORDS}
                </Typography>
            ) : (
                <MultiGridDisplay
                    dataFieldGroups={fieldsToRender}
                    dataTestId="medicalExpensesAndAssistance"
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
const MedicalExpensesAndAssistanceV2 = ({
    expenseRecords,
    hasMedifundInLast12Months,
}: {
    expenseRecords: IExpenseRecord[];
    hasMedifundInLast12Months: string;
}) => {
    const noExpenseRecordResults = allNoResults(extractAllValues(expenseRecords));
    const noData = noExpenseRecordResults && !isNotEmpty(hasMedifundInLast12Months);

    return noData ? (
        <NoData />
    ) : (
        <>
            {hasMedifundInLast12Months &&
                isNotEmpty(hasMedifundInLast12Months) &&
                (hasMedifundInLast12Months === 'Y' ? (
                    <SuccessMessage message={COPIES.HAS_MEDIFUND_ASSISTANCE} data-testid="hasMedifundAssistance" />
                ) : (
                    <UnsuccessfulMessage message={COPIES.NO_MEDIFUND_ASSISTANCE} data-testid="hasMedifundAssistance" />
                ))}
            {noExpenseRecordResults ? (
                <Typography id="body" variant="body2" data-testid="no_medical_expense">
                    {COPIES.NO_MEDICAL_EXPENSE_RECORDS}
                </Typography>
            ) : (
                expenseRecords.map(expenseRecordToComponent)
            )}
        </>
    );
};
