import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';

import {fetchInternetVideo, fetchSubs, fetchTimestamps, fetchVideo} from '~/services/onboardingServices';
import {CircularProgress, Container, Divider, Typography} from '@mui/material';

import './GettingStarted.scss';
import {Link} from 'react-router-dom';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {config} from '~/config';

export interface IOnboardingTimestamp {
    seconds: number;
    sectionTitle: string;
}

interface VideoURLs {
    videoUrl: string;
    subsUrl: string;
}

const padSingleDigit = (num: string) => {
    return num.length === 1 ? `0${num}` : num;
};

const formatTimestamp = (seconds: number) => {
    const minuteString = padSingleDigit(`${Math.floor(seconds / 60)}`);
    const secondString = padSingleDigit(`${seconds % 60}`);
    return `${minuteString}:${secondString}`;
};

export const GettingStarted = () => {
    const player = useRef<ReactPlayer>(null);
    const [videoUrls, setVideoUrls] = useState<VideoURLs>();
    const [videoError, setVideoError] = useState<boolean>(false);
    const [timestamps, setTimestamps] = useState<IOnboardingTimestamp[]>([]);
    const [timestampError, setTimestampError] = useState<boolean>(false);

    const handleSection = (seconds: number) => {
        if (player.current) {
            player.current.seekTo(seconds, 'seconds');
        }
    };

    const getSubsUrl = (subs: string) => {
        const subsBlob = new Blob([subs], {
            type: 'text/vtt;charset=utf-8',
        });
        return URL.createObjectURL(subsBlob);
    };

    useEffect(() => {
        if (config.internetEnabled) {
            Promise.all([fetchInternetVideo(), fetchSubs()])
                .then((values) => {
                    const videoUrls: VideoURLs = {
                        videoUrl: values[0],
                        subsUrl: getSubsUrl(values[1]),
                    };
                    setVideoUrls(videoUrls);
                })
                .catch(() => {
                    setVideoError(true);
                });
        } else {
            Promise.all([fetchVideo(), fetchSubs()])
                .then((values) => {
                    const videoUrls: VideoURLs = {
                        videoUrl: URL.createObjectURL(values[0] as Blob),
                        subsUrl: getSubsUrl(values[1]),
                    };
                    setVideoUrls(videoUrls);
                })
                .catch(() => {
                    setVideoError(true);
                });
        }

        fetchTimestamps()
            .then((timestampData) => {
                setTimestamps(timestampData);
            })
            .catch(() => {
                setTimestampError(true);
            });
    }, []);

    return (
        <Container styleName="getting-started">
            <Typography variant="h4">Getting Started</Typography>
            <Divider />

            <Typography variant="h5">Welcome to One Client View!</Typography>
            <Typography variant="body1" styleName="onboarding-body">
                One Client View (OneCV) is a system that pulls data across different help agencies into one location. In
                brief, this gives officers like yourself, a more complete view of your clients, in hopes of providing
                better assistance and service to them!
            </Typography>

            <Typography variant="h6">How to use One Client View</Typography>
            <Typography variant="body1" styleName="onboarding-body">
                Watch a tutorial video on the key features of One Client View, and learn how you can use the platform to
                support your work. We&apos;re improving the platform all the time so don&apos;t worry if yours looks
                slightly different from the one shown in the video.
            </Typography>
            {!videoError ? (
                videoUrls ? (
                    <ReactPlayer
                        ref={player}
                        url={videoUrls.videoUrl}
                        width="640px"
                        height="360px"
                        controls
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    disablePictureinPicture: 'true',
                                },
                                tracks: [
                                    {
                                        kind: 'subtitles',
                                        src: videoUrls.subsUrl,
                                        srcLang: 'English',
                                        default: true,
                                        label: 'English',
                                    },
                                ],
                            },
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            ) : (
                <ErrorMessage message="The video has failed to load. Please contact your system administrator." />
            )}
            <br />
            {videoUrls && !timestampError && timestamps.length > 0 && (
                <div data-testid="timestampSection" styleName="timestamp-section">
                    <p styleName="timestamp-header">
                        Navigate to a specific part of the video by clicking on the link below:
                    </p>

                    {timestamps.map((timestamp, index) => {
                        return (
                            <p
                                styleName="timestamp"
                                onClick={() => {
                                    handleSection(timestamp.seconds);
                                }}
                                key={index}
                            >
                                {`Part ${index + 1}: ${timestamp.sectionTitle} (${formatTimestamp(timestamp.seconds)})`}
                            </p>
                        );
                    })}
                </div>
            )}
            <br />
            <Typography variant="body1">
                For more resources, look under{' '}
                <Link style={{color: '#3949ab'}} to="/guides-and-toolkits">
                    Guides and Toolkits
                </Link>
            </Typography>
        </Container>
    );
};
