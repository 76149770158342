import React from 'react';
import {ActionCard} from '~/components/Common/Cards/ActionCard';
import style from './RequestWindowInfoBanner.scss';

export const RequestWindowInfoBanner = ({
    currentRequestWindowEndDate,
}: {
    currentRequestWindowEndDate: string | null;
}) => {
    return (
        <>
            {currentRequestWindowEndDate && (
                <div className={style.bannerContainer} data-testid={'requestWindowInfoBanner'}>
                    <ActionCard
                        {...{
                            message: {
                                title: `Request for access to data fields by ${currentRequestWindowEndDate}`,
                                body: `
                            1. Go to the relevant domain and click 'Update Data Access'.\n
                            2. Provide reason(s) for accessing the required subdomains to help Data Contributing Agencies assess your request.\n
                            3. Select the data fields required and remove those that are no longer required by your ops unit.\n
                            4. Your request will be sent for review once the request window closes on ${currentRequestWindowEndDate}.`,
                            },
                            color: '#3F91EF',
                        }}
                    ></ActionCard>
                </div>
            )}
        </>
    );
};
