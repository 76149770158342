import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Box, Typography, IconButton, Grid, CircularProgress, ClickAwayListener} from '@mui/material';
import {DateInput} from '~/components/Common/DateInput/DateInput';
import './DataAccessRequestWindowEdit.scss';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {createNewRequestWindow} from '~/services/requestWindowServices';
import {IWindowCreationRequest} from '~/interfaces/admin';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import CloseIcon from '@mui/icons-material/Close';

const addDays = (date: Date, days: number): Date => {
    date.setDate(date.getDate() + days);
    return date;
};

const setTimeEarliest = (date: Date): Date => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
};

const setTimeLatest = (date: Date): Date => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date;
};

export const DataAccessRequestWindowEdit = () => {
    const [startDate, setStartDate] = useState<Date>(setTimeEarliest(addDays(new Date(), 1)));
    const [endDate, setEndDate] = useState<Date>(setTimeLatest(addDays(new Date(), 2)));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [cancelDialogueOpen, setCancelDialogueOpen] = useState<boolean>(false);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const domainHeaderRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const backUrl = '/admin/data-access-request-window';
    const [openDateStart, setOpenDateStart] = useState<boolean>(false);
    const [openDateEnd, setOpenDateEnd] = useState<boolean>(false);

    useEffect(() => {
        if (endDate < startDate) {
            setCanSubmit(false);
            return;
        }
        if (startDate < new Date()) {
            setCanSubmit(false);
            return;
        }
        setCanSubmit(true);
    }, [startDate, endDate]);

    const handleStartDateChange = (date: unknown): void => {
        const newDate = new Date(date as string);
        setTimeEarliest(newDate);
        setStartDate(newDate);
    };

    const handleEndDateChange = (date: unknown): void => {
        const newDate = new Date(date as string);
        setTimeLatest(newDate);
        setEndDate(newDate);
    };

    const handleDateStartClickAway = () => {
        setOpenDateStart(false);
    };

    const handleDateEndClickAway = () => {
        setOpenDateEnd(false);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        const payload: IWindowCreationRequest = {
            WindowStart: startDate.toISOString(),
            WindowEnd: endDate.toISOString(),
        };
        createNewRequestWindow(payload)
            .then(() => {
                // openGrowl('Request window scheduled successfully');
                history.push({
                    pathname: backUrl,
                    state: {
                        toastMsg: 'Request window scheduled successfully',
                    },
                });
            })
            .catch((err) => {
                openGrowl(err.message);
                setIsLoading(false);
            });
    };

    const SubmitButtons = () => {
        return (
            <div className="buttonWrappers">
                <Button
                    onClick={() => {
                        setCancelDialogueOpen(true);
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    data-testid="cancel-button"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    id="submit-button"
                    data-testid="submit-button"
                >
                    {isLoading ? <CircularProgress color="inherit" size={10} /> : 'Save'}
                </Button>
            </div>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between" styleName="windowEditForm">
                <Grid item>
                    <Box component="div" className="backButtonWrapper">
                        <div data-testid="back-button">
                            <BackButton
                                onClick={() => {
                                    setCancelDialogueOpen(true);
                                }}
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item>
                    <SubmitButtons />
                </Grid>

                <Grid item xs={12}>
                    <Typography styleName="pageTitle" component="h1" variant="h4" data-testid="page-title">
                        Request Window Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography styleName="inputTitle">Window Period</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div styleName="effectiveDateWrapper">
                        <ClickAwayListener onClickAway={handleDateStartClickAway}>
                            <div data-testid="start-datepicker">
                                <DateInput
                                    value={startDate}
                                    onChange={(date) => handleStartDateChange(date)}
                                    minDate={setTimeEarliest(addDays(new Date(), 1))}
                                    selectOnly={true}
                                    open={openDateStart}
                                    setOpen={setOpenDateStart}
                                    smallSize={true}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </ClickAwayListener>
                        <div style={{padding: '8px', paddingTop: '10px'}}>to</div>
                        <ClickAwayListener onClickAway={handleDateEndClickAway}>
                            <div data-testid="end-datepicker">
                                <DateInput
                                    value={endDate}
                                    onChange={(date) => handleEndDateChange(date)}
                                    minDate={startDate}
                                    selectOnly={true}
                                    open={openDateEnd}
                                    setOpen={setOpenDateEnd}
                                    smallSize={true}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </ClickAwayListener>
                    </div>
                </Grid>
            </Grid>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={() => setCancelDialogueOpen(false)}
                onConfirm={() => history.push(backUrl)}
                open={cancelDialogueOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                    action: (
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                <CloseIcon sx={{color: '#E8EAF6'}} fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    ),
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
