import React from 'react';

import {IAdminRow} from '~/interfaces/admin';

import {Box, Link, Typography} from '@mui/material';
import {TableButton} from '~/components/Common/Button/TableButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const AdminControls =
    (handleEdit: (opsUnitId: number) => void, handleEmail: () => string | undefined) => (adminRow: IAdminRow) => {
        const opsUnitId = adminRow.OpsUnitId || adminRow.AgencyId;
        return !adminRow.Admins && opsUnitId ? (
            <TableButton text="Add" onClick={() => handleEdit(adminRow.Id as number)} />
        ) : opsUnitId ? (
            <TableButton text="Edit" onClick={() => handleEdit(adminRow.Id as number)} />
        ) : (
            <Link href={handleEmail()} target="_blank" rel="noopener noreferrer">
                <Box display="flex" alignItems="center">
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '16px',
                            letterSpacing: '1.25px',
                            color: '#3949AB',
                            width: '60px',
                        }}
                    >
                        EMAIL{<br />}SYSTEM{<br />}ADMIN
                    </Typography>
                    <NavigateNextIcon fontSize="small" />
                </Box>
            </Link>
        );
    };

export default AdminControls;
