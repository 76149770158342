import React, {useEffect, useState} from 'react';
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Typography,
    TextField,
    MenuItem,
    Box,
    Checkbox,
    ListItemIcon,
    ListItemText,
    Stack,
} from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {IDataAccessMatrixField} from '~/interfaces/admin';

import {useTypedSelector} from '~/hooks/useTypedSelector';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {getDomains} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {getSentForReviewFieldsState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {updateOpsUnitDataFieldRequestReject} from '~/services/dataAccessMatrixService';

export interface IRejectAccessDataFieldModalDiaglog {
    open: boolean;
    onClose: () => void;
    selectedDataFieldForRejection: number;
    subDomainName: string;
    subDomainId: number;
    domainId: number;
    opsUnitId: number;
    openGrowl: (message: string) => void;
}

export const RejectAccessDataFieldModal = ({
    open,
    onClose,
    selectedDataFieldForRejection,
    subDomainName,
    subDomainId,
    domainId,
    opsUnitId,
    openGrowl,
}: IRejectAccessDataFieldModalDiaglog) => {
    const [reasonForRejection, setReasonForRejection] = useState('');
    const [dataFields, setDataFields] = useState<number[]>([]);
    const [disableRequest, setDisableRequest] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const domains = useTypedSelector(getDomains);
    const sentForReviewFields = useTypedSelector(getSentForReviewFieldsState(opsUnitId, domainId, subDomainId));
    const sentForReviewFieldsIds = sentForReviewFields.map((f) => f.dataFieldId);

    useEffect(() => {
        setDataFields([selectedDataFieldForRejection]);
    }, [selectedDataFieldForRejection]);

    useEffect(() => {
        if (dataFields.length && reasonForRejection.length) {
            setDisableRequest(false);
        } else {
            setDisableRequest(true);
        }
    }, [reasonForRejection, dataFields]);

    const selectedDataFieldsForRejection = domains.reduce((sdf, domain) => {
        domain.SubDomains.forEach((subDomain) => {
            if (subDomain.Id === subDomainId) {
                subDomain.DataFields.forEach((df) => {
                    if (sentForReviewFieldsIds.includes(df.Id)) {
                        sdf.push(df);
                    }
                });
            }
        });
        return sdf;
    }, [] as IDataAccessMatrixField[]);

    const isAllSelected =
        selectedDataFieldsForRejection.length > 0 && dataFields.length === selectedDataFieldsForRejection.length;

    const handleSave = async () => {
        try {
            const referenceIds =
                sentForReviewFields.filter((f) => dataFields.includes(f.dataFieldId))?.map((d) => d.referenceId) || [];
            const dataSource = {
                Reason: reasonForRejection,
                SubdomainId: subDomainId,
                FormReferences: referenceIds,
            };
            const result = await updateOpsUnitDataFieldRequestReject(opsUnitId, dataSource);
            if (result?.length) {
                openGrowl('Data access updated successfully.');
                dispatch(
                    dataAccessMatrix2Actions.SET_REJECT_DATA_FIELDS({
                        opsUnitId,
                        domainId,
                        subDomainId,
                        dataFields: dataFields,
                    }),
                );
                onClose();
            }
        } catch (e) {
            const error = e as Error;
            onClose();
            openGrowl(error.message);
        }
    };

    const handleClose = () => {
        onClose();
        setDataFields([selectedDataFieldForRejection]);
        setReasonForRejection('');
    };

    const MAX_CHRACTERS_COUNT = 500;
    const remainingCharactersCount = MAX_CHRACTERS_COUNT - (reasonForRejection || '').length;
    const remainingCharactersForReason = remainingCharactersCount < 0 ? 0 : remainingCharactersCount;

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let reason = event.target.value;
        if (event.target.value.toString().length > MAX_CHRACTERS_COUNT) {
            reason = reason.substring(0, MAX_CHRACTERS_COUNT);
        }
        setReasonForRejection(reason);
    };

    const handleChange = (event: SelectChangeEvent<typeof dataFields>) => {
        const {value} = event.target;
        if (value[value.length - 1] === 'all') {
            setDataFields(
                dataFields.length === selectedDataFieldsForRejection.length
                    ? []
                    : selectedDataFieldsForRejection.map((df) => df.Id),
            );
            return;
        }
        const dataFieldsArray = value as number[];
        setDataFields(dataFieldsArray);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-description">
            <Grid container>
                <Grid item xs={11}>
                    <DialogTitle>Reject access to data field</DialogTitle>
                </Grid>
                <Grid item xs={1}>
                    <DialogActions>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                </Grid>
                <Grid item xs={12}>
                    <DialogContent style={{paddingTop: '0'}}>
                        <Box>The reason for rejection will be applied to all selected data fields.</Box>
                        <Box mt={2}>
                            <Typography mb={1}>Select subdomain</Typography>
                            <TextField value={subDomainName} disabled fullWidth />
                        </Box>
                        <Box mt={2}>
                            <Typography mb={1}>Select data field(s)</Typography>
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                multiple
                                value={dataFields}
                                onChange={handleChange}
                                renderValue={(selected) => {
                                    if (selected.length) {
                                        return `${selected.length} selected`;
                                    }
                                }}
                                MenuProps={MenuProps}
                                fullWidth
                            >
                                <MenuItem value="all" style={{padding: '10px 4px'}}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={isAllSelected}
                                            indeterminate={
                                                dataFields.length > 0 &&
                                                dataFields.length < selectedDataFieldsForRejection.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={isAllSelected ? 'Clear All' : 'Select All'}
                                        style={{fontSize: '14px'}}
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <Box style={{width: '100%'}}>
                                        <hr style={{borderTop: '1px solid #979797', marginTop: '6px'}}></hr>
                                    </Box>
                                </MenuItem>
                                {selectedDataFieldsForRejection.map((dataField) => {
                                    return (
                                        <MenuItem key={dataField.Id} value={dataField.Id} style={{padding: '10px 4px'}}>
                                            <ListItemIcon>
                                                <Checkbox checked={dataFields.indexOf(dataField.Id) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={dataField.Name} style={{fontSize: '14px'}} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                        <Box mt={4}>
                            <TextField
                                fullWidth
                                label="Reason(s) for rejection"
                                value={reasonForRejection}
                                onChange={handleReasonChange}
                                multiline
                                rows={4}
                                InputLabelProps={{shrink: true}}
                                placeholder="Enter reason"
                            />
                        </Box>
                        <Box style={{float: 'right'}}>
                            <Typography variant="body1" mt={2} style={{color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px'}}>
                                {`${remainingCharactersForReason} characters remaining`}
                            </Typography>
                        </Box>
                    </DialogContent>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item xs={12}>
                    <DialogContent style={{float: 'right'}}>
                        <Stack spacing={2} direction="row">
                            <Button onClick={handleClose} color="primary" variant="outlined">
                                Cancel
                            </Button>
                            <Button color="error" variant="contained" disabled={disableRequest} onClick={handleSave}>
                                Reject Request
                            </Button>
                        </Stack>
                    </DialogContent>
                </Grid>
            </Grid>
        </Dialog>
    );
};
