import React from 'react';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface IAddButtonSecondary {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size?: 'small' | 'medium' | 'large' | undefined;
    className?: string;
    styleName?: string;
    text: string;
    id?: string;
}

export const AddButtonSecondary = ({onClick, className, styleName, size = 'medium', text, id}: IAddButtonSecondary) => {
    return (
        <Button
            className={className}
            styleName={styleName}
            size={size}
            variant="text"
            color="primary"
            onClick={onClick}
            id={id}
        >
            <AddIcon style={{marginRight: 8}} />
            {text}
        </Button>
    );
};
