import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from '@mui/material';
import React, {ReactNode, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {ExpandedListModelFooter} from './Footers/ExpandedListModelFooter';
import {PaginatedListModelFooter} from './Footers/PaginatedListModelFooter';
import {KeyboardArrowDownRounded as KeyboardArrowDownIcon} from '@mui/icons-material';
import Emitter, {EVENT_TYPE} from '~/utils/events/emitter';
export interface IBaseModel {
    Id?: number;
}

interface IModelProps<IModel extends IBaseModel> {
    headers: ReactNode[];
    hideHeader?: boolean;
    columns: ((data: IModel) => ReactNode)[];
    variant: 'paginated' | 'expanded';
    modelList: IModel[];
    modelComparator?: ((a: IModel, b: IModel) => number)[];
    modelPageUrl?: (data: IModel) => string;
    defaultSortIndex?: number;
    defaultSortOrder?: 'asc' | 'desc';
    numbering?: boolean;
    sortable?: boolean;
    hover?: boolean;
    rowsPerPage?: number;
    dataTestId?: string;
    numberOfFixedRows?: number;
    customRowStyle?: (data: IModel) => React.CSSProperties;
    customCellStyle?: (data: IModel, index?: number) => React.CSSProperties;
    customHeaderCellStyle?: (data: React.ReactNode, index?: number) => React.CSSProperties;
    disableSortColumns?: ReactNode[];
}

export const ListModel = <IModel extends IBaseModel>(props: IModelProps<IModel>) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const initialRowsPerPage = props.rowsPerPage || 10;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
    const [sortByIndex, setSortByIndex] = React.useState(props.defaultSortIndex || 0);
    const [sortAscending, setSortAscending] = React.useState(props.defaultSortOrder !== 'desc');
    const [modelList, setModelList] = React.useState(props.modelList);

    useEffect(() => {
        setModelList(props.modelList);
    }, [props.modelList]);
    useEffect(() => {
        setRowsPerPage(initialRowsPerPage);
    }, [initialRowsPerPage]);
    const emptyRows =
        props.variant === 'paginated'
            ? rowsPerPage - Math.min(rowsPerPage, props.modelList.length - page * rowsPerPage)
            : Number(props.modelList.length === 0);

    if (
        props.headers.length != props.columns.length ||
        (props.sortable && props.modelComparator && props.columns.length != props.modelComparator.length)
    ) {
        return <div />;
    }

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, initialRowsPerPage));
        setPage(0);
    };

    const handleExpandTable = () => {
        setRowsPerPage(props.modelList.length);
        Emitter.emit(EVENT_TYPE.RESET_BOTTOM, '');
    };

    const handleCollapseTable = () => {
        setRowsPerPage(initialRowsPerPage);
        scrollRef.current?.scrollIntoView({behavior: 'auto', block: 'center', inline: 'nearest'});
        Emitter.emit(EVENT_TYPE.RESET_BOTTOM, '');
    };

    const handleSort = (id: number) => {
        if (sortByIndex === id) {
            setSortAscending(!sortAscending);
        } else {
            setSortByIndex(id);
            setSortAscending(true);
        }
        props.numberOfFixedRows && props.numberOfFixedRows > 0
            ? setModelList([
                  ...props.modelList.slice(0, props.numberOfFixedRows),
                  ...props.modelList
                      .slice(props.numberOfFixedRows)
                      .sort(
                          (a: IModel, b: IModel) =>
                              props.modelComparator![sortByIndex](a, b) * (sortAscending ? 1 : -1),
                      ),
              ])
            : modelList.sort(
                  (a: IModel, b: IModel) => props.modelComparator![sortByIndex](a, b) * (sortAscending ? 1 : -1),
              );
    };

    return (
        <div data-testid={props.dataTestId}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {props.numbering && <TableCell />}
                            {!props.hideHeader &&
                                props.headers.map((header, i) => (
                                    <TableCell
                                        style={props.customHeaderCellStyle && props.customHeaderCellStyle(header, 0)}
                                        key={i}
                                    >
                                        {props.sortable === false ||
                                        (props.disableSortColumns && props.disableSortColumns.indexOf(header) > -1) ? (
                                            header
                                        ) : (
                                            <TableSortLabel
                                                active={sortByIndex === i}
                                                direction={sortAscending ? 'asc' : 'desc'}
                                                onClick={() => handleSort(i)}
                                                disabled={!header}
                                                IconComponent={KeyboardArrowDownIcon}
                                            >
                                                {header}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? modelList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : modelList
                        ).map((model, index) => (
                            <TableRow
                                key={index}
                                hover={props.hover}
                                style={props.customRowStyle && props.customRowStyle(model)}
                            >
                                {props.numbering && <TableCell>{('0' + (index + 1)).slice(-2)}</TableCell>}
                                <TableCell
                                    component="th"
                                    scope="row"
                                    ref={index === 0 ? scrollRef : null}
                                    style={props.customCellStyle && props.customCellStyle(model, 0)}
                                >
                                    {props.modelPageUrl ? (
                                        <Link to={props.modelPageUrl(model)}>{props.columns[0](model)}</Link>
                                    ) : (
                                        props.columns[0](model)
                                    )}
                                </TableCell>
                                {props.columns.slice(1).map((column, i) => (
                                    <TableCell key={i} style={props.customCellStyle && props.customCellStyle(model, 1)}>
                                        {column(model)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    {props.variant === 'paginated' ? (
                        <PaginatedListModelFooter
                            count={props.modelList.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            columnLength={props.columns.length}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    ) : (
                        <ExpandedListModelFooter
                            columnLength={props.columns.length}
                            rowsPerPage={rowsPerPage}
                            count={props.modelList.length}
                            initialRowsPerPage={initialRowsPerPage}
                            onExpand={handleExpandTable}
                            onCollapse={handleCollapseTable}
                        />
                    )}
                </Table>
            </TableContainer>
        </div>
    );
};
