import React, {useState} from 'react';
import {FormControl, InputLabel, OutlinedInput, FormHelperText} from '@mui/material';

interface IValidateCallbacksType {
    [key: string]: (newFieldValue: {[x: string]: () => string | true}) => void;
}

type ValidationsType = (field: string, callbacks?: IValidateCallbacksType) => string | undefined;

interface IInputField {
    className?: string;
    styleName?: string;
    htmlFor: string;
    value: string;
    onChange: (value: string) => void;
    label?: string;
    validations?: ValidationsType[];
    type?: string;
    isDisable?: boolean;
    maxLength?: number;
    displayCharCounter?: boolean;
    customErrorMessage?: string;
    multiline?: boolean;
    rows?: number;
    placeholder?: string;
    endAdornment?: React.ReactNode;
    style?: React.CSSProperties;
    onEventChange?: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    isDense?: boolean;
    disabledReason?: string;
}

export const InputField = ({
    className,
    styleName,
    htmlFor,
    value,
    onChange,
    label,
    validations,
    type,
    isDisable,
    maxLength,
    displayCharCounter,
    customErrorMessage,
    multiline,
    rows,
    placeholder,
    endAdornment,
    style,
    onEventChange,
    isDense = false,
    disabledReason = 'This field has been disabled',
}: IInputField) => {
    const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);
    const [isPristine, setIsPristine] = useState<boolean>(true);

    const handleValidation = (value: string) => {
        if (validations) {
            const validationErrors = validations
                .map((validation) => validation(value))
                .filter((error) => error !== undefined);
            setValidationMessage(validationErrors[0]);
        }
    };

    const handleBlur = () => {
        if (isPristine && validations) {
            setIsPristine(false);
            handleValidation(value);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onEventChange ? onEventChange(e) : onChange && onChange(value);
        if (!isPristine) {
            handleValidation(value);
        }
    };

    return (
        <FormControl
            className={className}
            styleName={styleName}
            variant="outlined"
            error={Boolean(validationMessage || customErrorMessage)}
        >
            <InputLabel margin={isDense ? 'dense' : undefined} htmlFor={htmlFor}>
                {label}
            </InputLabel>
            <OutlinedInput
                id={htmlFor}
                value={value}
                onChange={handleChange}
                label={label}
                onBlur={handleBlur}
                type={type}
                disabled={isDisable}
                inputProps={{maxLength}}
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                endAdornment={endAdornment}
                style={style}
            />
            <FormHelperText style={{textAlign: 'left', marginLeft: '0px'}}>{validationMessage}</FormHelperText>
            <FormHelperText style={{textAlign: 'left', marginLeft: '0px'}}>{customErrorMessage}</FormHelperText>
            {displayCharCounter && maxLength && (
                <FormHelperText style={{textAlign: 'right', marginRight: '0px'}}>
                    {maxLength - value.length} characters remaining
                </FormHelperText>
            )}
            {isDisable && (
                <FormHelperText style={{textAlign: 'left', marginLeft: '0px'}} data-testid="inputFieldDisabledReason">
                    {disabledReason}
                </FormHelperText>
            )}
        </FormControl>
    );
};
