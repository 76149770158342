import {FormControl, Grid} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {InputField} from '~/components/Common/InputField/InputField';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {nextLineToParagraphs} from '~/utils/nextLineToParagraphUtils';

import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getFormState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import './SubdomainInfo.scss';

export type ISubdomainFormInfo = {
    subdomainId: number;
    lastUpdatedInfo: string;
    currentReason: string;
    remarks?: string;
    guidelines?: string;
    updateReason?: string;
};

const SubdomainInfo = (props: {subdomainInfo: ISubdomainFormInfo; activeWindow: boolean; canEditReason: boolean}) => {
    const {subdomainInfo, activeWindow, canEditReason} = props;
    const {accessToken} = useContext(AuthenticationContext);
    const isOA: boolean =
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministrator' ||
        accessToken?.Permissions.AccessLevel.toLowerCase() == 'opsunitadministratorappuser' ||
        false;

    const [pendingReason, setPendingReason] = useState<string>(subdomainInfo.updateReason || '');
    const formMetaState = useTypedSelector(getFormState());
    const formState = formMetaState.toLowerCase();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (subdomainInfo.subdomainId) {
            dispatch(
                dataAccessMatrix2Actions.SET_SUBDOMAIN_DATA_REQUEST_REASON_FOR_ACCESS({
                    subDomainId: subdomainInfo.subdomainId,
                    reasonForAccess: pendingReason,
                }),
            );
        }
    }, [pendingReason]);

    return (
        <div styleName="subdomainInfoSection">
            {/* 
            note: comment out for now. backend logic to be fixed in phase 2 
            and this section restored once done

            <Grid container item xs={12} styleName="lastUpdatedGrid">
                {subdomainInfo.lastUpdatedInfo}
            </Grid> */}

            <Grid styleName="subdomainRemarksGrid" container item xs={12}>
                <Grid item xs={12} style={{fontWeight: 'bold'}}>
                    Subdomain Remarks:
                    <br />
                </Grid>

                <Grid item xs={12} sx={{whiteSpace: 'pre-line'}}>
                    {subdomainInfo.remarks ? nextLineToParagraphs(subdomainInfo.remarks) : '-'}
                </Grid>
            </Grid>
            <Grid container item xs={12} styleName="instructionGrid">
                <Grid item xs={12} style={{fontWeight: 'bold'}}>
                    Provide reasons to request for the selected data fields in this subdomain:
                </Grid>

                <Grid item xs={12}>
                    {subdomainInfo.guidelines ? nextLineToParagraphs(subdomainInfo.guidelines) : '-'}
                </Grid>
            </Grid>

            <Grid container item xs={12} gap={1} styleName="approvedReasonGrid">
                <Grid item xs={12} style={{fontWeight: 'bold'}}>
                    Approved reason(s) for accessing subdomain:
                </Grid>

                <Grid item xs={12} style={{whiteSpace: 'pre-wrap'}}>
                    {subdomainInfo.currentReason ? nextLineToParagraphs(subdomainInfo.currentReason) : '-'}
                </Grid>
            </Grid>

            <Grid item xs={12} styleName="updatedReasonGrid">
                <FormControl fullWidth>
                    <InputField
                        placeholder="Who is assessing this data, for what purpose, and how frequently will it be used?"
                        isDisable={!activeWindow || !canEditReason || !isOA || formState === 'submitted'}
                        htmlFor={''}
                        multiline
                        value={pendingReason}
                        label={'Updated reason(s) for accessing subdomain (Subject to approval)'}
                        displayCharCounter={activeWindow && canEditReason && formState !== 'submitted'}
                        maxLength={1000}
                        onChange={(e) => {
                            setPendingReason(e);
                        }}
                    />
                </FormControl>
            </Grid>
        </div>
    );
};

export default SubdomainInfo;
