import React, {ReactNode, useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {Search} from '~/pages/Search/Search';
import {TermsAndConditions} from '~/pages/TermsAndConditions/TermsAndConditions';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE} from '~/constants';
import {AdminView} from '~/components/Admin/AdminView/AdminView';
import {groupAccess, IRouteProps} from '~/router';
import {config, IFeatureFlags} from '~/config';
import {isAppUser, isSystemAdmin} from '~/utils/roleUtils';

export const PrivateRoute = ({
    component: Component,
    checkFeatureFlag,
    accessRequired = groupAccess.ALL_ACCESS,
    showAdminSidebar = false,
    hideIfFeatureEnable,
    ...rest
}: IRouteProps) => {
    const {accessToken, loginState} = useContext(AuthenticationContext);
    const userShouldUseTermsAndConditions = accessToken && isAppUser(accessToken);
    const userHasAccessRequired = accessToken && accessRequired.indexOf(accessToken.Permissions.AccessLevel) > -1;
    const adminShouldUseTermsAndCondition = accessToken && isSystemAdmin(accessToken);
    const checkFlags = (checkFeatureFlag || '').split(',');
    const allowFeatures = checkFlags.every((flag) => config.featureFlags[flag as keyof IFeatureFlags]);
    const hideFeature = hideIfFeatureEnable ? config.featureFlags[hideIfFeatureEnable as keyof IFeatureFlags] : false;

    return (
        <Route
            {...rest}
            render={(props): ReactNode => {
                if (
                    accessToken &&
                    loginState == LOGIN_STATE.LOGGED_IN &&
                    Component &&
                    (!checkFeatureFlag || allowFeatures) &&
                    !hideFeature
                ) {
                    if (
                        userShouldUseTermsAndConditions &&
                        !accessToken.AgreedToTermsAndConditions &&
                        Component != TermsAndConditions
                    ) {
                        return <Redirect to={{pathname: '/terms-and-conditions'}} />;
                    } else if (!userHasAccessRequired) {
                        return <Redirect to={{pathname: '/'}} />;
                    }

                    if (
                        adminShouldUseTermsAndCondition &&
                        !accessToken.AgreedToTermsAndConditions &&
                        Component === Search
                    ) {
                        return <Redirect to={{pathname: '/terms-and-conditions'}} />;
                    } else if (!userHasAccessRequired) {
                        return <Redirect to={{pathname: '/search'}} />;
                    }

                    return showAdminSidebar ? (
                        <AdminView accessToken={accessToken}>
                            <Component {...props} />
                        </AdminView>
                    ) : (
                        <Component {...props} />
                    );
                } else if (
                    loginState === LOGIN_STATE.LOGGED_OUT_EXPIRED ||
                    loginState === LOGIN_STATE.LOGGED_OUT_INACTIVE ||
                    loginState === LOGIN_STATE.LOGGED_OUT_MANUAL
                ) {
                    return <Redirect to={{pathname: '/logout'}} />;
                }

                return <Redirect to={{pathname: '/login'}} />;
            }}
        />
    );
};
