import React from 'react';
import {AdminSideBar} from '../AdminSideBar/AdminSideBar';
import './AdminView.scss';
import {Container} from '@mui/material';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {Redirect} from 'react-router';

interface IAdminViewProps {
    accessToken?: IAccessToken;
    children: NonNullable<React.ReactNode>;
}

export const AdminView: React.FunctionComponent<IAdminViewProps> = (props) => {
    return props.accessToken ? (
        <>
            <AdminSideBar data-testid="adminSideBar" accessToken={props.accessToken} />
            <Container data-testid="adminContent" disableGutters id="adminContent">
                {props.children}
            </Container>
        </>
    ) : (
        <Redirect to={`/`} />
    );
};
