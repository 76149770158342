export const getFileSizeInKb = (size: number): string => {
    const sizeInMb = (size / 1024).toFixed(2);
    return ` (${sizeInMb}KB)`;
};

export const getCurrentTime = (): string => {
    const dateTime = new Date();
    let formatted = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(dateTime);
    formatted = formatted.replace('00', '12');
    return formatted.replace(',', ' at');
};

export const getTimeFromApiDate = (dateString: string): string => {
    // Example dateString: 2021-08-02T08:04:51.215051Z
    const dateTime = Date.parse(dateString);
    let formatted = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(dateTime);
    formatted = formatted.replace('00', '12');
    return formatted.replace(',', ' at');
};

// export const getTimeFromFilename = (filename: string): string => {
//     // filename will be of this format: CDAC_01 Jul 2021_120204
//     const split = filename.split('_');
//     if (split.length != 3) {
//         return getCurrentTime();
//     }
//     let hh = split[2].substring(0, 2);
//     const mm = split[2].substring(2, 4);
//     let ampm = 'AM';
//     const hhAsNum = parseInt(hh);
//     if (hhAsNum == 0) {
//         hh = '12';
//     } else if (hhAsNum == 12) {
//         ampm = 'PM';
//     } else if (hhAsNum > 12) {
//         ampm = 'PM';
//         hh = (hhAsNum - 12).toString();
//         if (hh.length == 1) {
//             hh = '0' + hh; // pad with 0
//         }
//     }
//     const formatted = `${split[1]} at ${hh}:${mm}${ampm}`;
//     return formatted;
// };
