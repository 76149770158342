const btnSmallPadding = {
    padding: '7px 10px',
};

const btnMediumPadding = {
    padding: '12px 20px',
};

const btnLargePadding = {
    padding: '18px 30px',
};

export const buttonTheme: {} = {
    styleOverrides: {
        root: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.43,
            letterSpacing: 1.25,
            textAlign: 'center',
            ...btnMediumPadding,
        },

        sizeSmall: {
            fontSize: 15,
            ...btnSmallPadding,
        },
        sizeLarge: {
            fontSize: 16,
            ...btnLargePadding,
        },
        outlined: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.43,
            letterSpacing: 1.25,
            textAlign: 'center',
            ...btnMediumPadding,
        },
        outlinedSizeSmall: {
            ...btnSmallPadding,
        },
        outlinedSizeLarge: {
            ...btnLargePadding,
        },
        text: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.43,
            letterSpacing: 1.25,
            textAlign: 'center',
            ...btnMediumPadding,
        },
        textSizeSmall: {
            ...btnSmallPadding,
        },
        textSizeLarge: {
            ...btnLargePadding,
        },
    },
};
