import React from 'react';
import {Typography} from '@mui/material';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {ISubDomainFieldDisplay} from '~/interfaces/common';
import {formatDateField, formatValue, objNoAccess, objNoResults} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type ICovidSchemesData = ICovidSchemes[];

interface ICovidSchemes {
    ApplicationReferenceNo: string;
    SchemeType: string;
    ApplicationStatus: string;
    SubmittedDate: string;
    CaseLocationName: string;
    ApplicationCaseworkerName: string;
    InterventionReferenceNo: string;
    InterventionStatus: string;
    InterventionCreatedDate: string;
    InterventionApprovedDate: string;
    CaseClosureReason: string;
    InterventionCaseworkerName: string;
    InterventionLocationName: string;
    ApproveRejectDate: string;
    AssistanceAmount: string;
    MonthlyDisbursement: string;
    AssistanceStartDate: string;
    AssistanceEndDate: string;
}

const CRGT = 'COVID-19 Recovery Grant - Temporary (CRG-T)';
const CRG = 'COVID-19 Recovery Grant (CRG)';
const CSG = 'COVID-19 Support Grant (CSG)';
const TRF = 'Temporary Relief Fund (TRF)';
const TCF = 'The Courage Fund (TCF)';

const sortOrder = [CRGT, CRG, CSG, TRF, TCF];

const schemeToFields = (scheme: ICovidSchemes) => {
    const {
        ApplicationReferenceNo,
        SchemeType,
        ApplicationStatus,
        SubmittedDate,
        CaseLocationName,
        ApplicationCaseworkerName,
        InterventionReferenceNo,
        InterventionStatus,
        InterventionCreatedDate,
        InterventionApprovedDate,
        CaseClosureReason,
        InterventionCaseworkerName,
        InterventionLocationName,
        ApproveRejectDate,
        AssistanceAmount,
        MonthlyDisbursement,
        AssistanceStartDate,
        AssistanceEndDate,
    } = scheme;

    let applicationDetails = [
        {
            label: 'Scheme Type',
            value: <Typography variant="h6">{SchemeType}</Typography>,
            fullWidth: true,
        },
        {
            label: 'Application Reference Number',
            value: ApplicationReferenceNo,
        },
        {
            label: 'Application Status',
            value: ApplicationStatus,
        },
        {
            label: 'Submitted Date',
            value: formatDateField(SubmittedDate, true, true),
        },
        {
            label: 'Case Location',
            value: CaseLocationName,
        },
        {
            label: 'Caseworker',
            value: ApplicationCaseworkerName,
        },
    ];

    applicationDetails =
        SchemeType === TCF && (ApplicationStatus === 'Approved' || ApplicationStatus === 'Rejected')
            ? [
                  ...applicationDetails,
                  {
                      label: 'Approved or Rejected Date',
                      value: formatDateField(ApproveRejectDate, true, true),
                  },
              ]
            : applicationDetails;

    applicationDetails =
        SchemeType === TRF || SchemeType === TCF
            ? [
                  ...applicationDetails,
                  {
                      label: 'Assistance Amount',
                      value: formatValue(AssistanceAmount, (val: string) => `$${val}`),
                  },
              ]
            : applicationDetails;

    // Only CSG, CRG, CRGT has intervention details
    // From biz, use "Assigned with PDC" status to determine if there is intervention
    if ((SchemeType === CSG || SchemeType === CRG || SchemeType === CRGT) && ApplicationStatus === 'Assigned') {
        let interventionDetails: ISubDomainFieldDisplay[] = [
            {
                label: 'Reference Number',
                value: InterventionReferenceNo,
            },
            {
                label: 'Intervention Status',
                value: InterventionStatus,
            },
            {
                label: 'Created Date',
                value: formatDateField(InterventionCreatedDate, true, true),
            },
            {
                label: 'Approval or Closure Date',
                value: formatDateField(InterventionApprovedDate, true, true),
            },
            {
                label: 'Closure Reason',
                value: CaseClosureReason,
            },
            {
                label: 'Caseworker',
                value: InterventionCaseworkerName,
            },
            {
                label: 'Case Location',
                value: InterventionLocationName,
            },
            {
                label: 'Monthly Disbursement',
                value: formatValue(MonthlyDisbursement, (val: string) => `$${val}`),
            },
            {
                label: 'Assistance Start Date',
                value: formatDateField(AssistanceStartDate, true, true),
            },
            {
                label: 'Assistance End Date',
                value: formatDateField(AssistanceEndDate, true, true),
            },
        ];

        if (interventionDetailsNoAccess(interventionDetails)) {
            interventionDetails = [];
        } else if (interventionDetailsNoResults(interventionDetails)) {
            interventionDetails = [
                {
                    label: '',
                    value: (
                        <>
                            <Typography variant="h6" style={{fontSize: '18px', marginBottom: '12px'}}>
                                Intervention Details
                            </Typography>
                            <NoData />
                        </>
                    ),
                    fullWidth: true,
                },
            ];
        } else {
            interventionDetails = [
                {
                    label: '',
                    value: (
                        <Typography variant="h6" style={{fontSize: '18px'}}>
                            Intervention Details
                        </Typography>
                    ),
                    fullWidth: true,
                },
                ...interventionDetails,
            ];
        }

        return [...applicationDetails, ...interventionDetails];
    }

    return applicationDetails;
};

const interventionDetailsNoResults = (fields: ISubDomainFieldDisplay[]) =>
    objNoResults(fields.map((field) => field.value));

const interventionDetailsNoAccess = (fields: ISubDomainFieldDisplay[]) =>
    objNoAccess(fields.map((field) => field.value));

export const CovidSchemes: React.FunctionComponent<{data: ICovidSchemesData}> = ({data}) => {
    const sortedData = data
        .sort((a, b) => sortOrder.indexOf(a.SchemeType) - sortOrder.indexOf(b.SchemeType))
        .map(schemeToFields);

    if (objNoResults(data)) {
        return <NoData />;
    }

    return (
        <MultiGridDisplay
            dataTestId="covidSchemes"
            dataFieldGroups={sortedData}
            displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
        />
    );
};
