import {IWSG, IWsgReferralScreening} from '~/components/Client/SubDomainDisplay/WSG/Wsg';
import {RelationshipOrder, CaseStatusOrder} from '~/constants';
import {IAgency} from '~/interfaces/admin';
import {IClientInfo} from '~/interfaces/client';
import {ICurrentAnnouncement, IReleaseNote} from '~/interfaces/common';

export const sortByPropertyAlphabetically = <T>(arr: T[], propertyName: keyof T) =>
    arr.sort((a, b) => {
        const valA = a[propertyName];
        const valB = b[propertyName];
        if (typeof valA === 'string' && typeof valB === 'string') {
            return valA.toUpperCase() < valB.toUpperCase() ? -1 : valA.toUpperCase() > valB.toUpperCase() ? 1 : 0;
        }
        return valA < valB ? -1 : valA > valB ? 1 : 0;
    });

export const screeningResultsComparator = (resultA: IClientInfo, resultB: IClientInfo) => {
    const a = resultA.GeneralInfo.Relationship;
    const b = resultB.GeneralInfo.Relationship;
    return RelationshipOrder.indexOf(a) - RelationshipOrder.indexOf(b);
};

export const referralDateComparator = (resultA: IWsgReferralScreening, resultB: IWsgReferralScreening) => {
    const a = Date.parse(resultA.JobReferralDate);
    const b = Date.parse(resultB.JobReferralDate);
    return a > b ? -1 : a < b ? 1 : 0;
};

export const screeningResultsStatusDateComparator = (resultA: IWSG, resultB: IWSG) => {
    const a = Date.parse(resultA.WsgStockScreening.CaseOpenDate);
    const b = Date.parse(resultB.WsgStockScreening.CaseOpenDate);
    if (resultA.WsgStockScreening.CaseStatus === resultB.WsgStockScreening.CaseStatus) {
        return a > b ? -1 : a < b ? 1 : 0;
    }
    return (
        CaseStatusOrder.indexOf(resultA.WsgStockScreening.CaseStatus) -
        CaseStatusOrder.indexOf(resultB.WsgStockScreening.CaseStatus)
    );
};

export const sortAgencyNameAlphabetically = (resultA: IAgency, resultB: IAgency) =>
    resultA.Name.toUpperCase() < resultB.Name.toUpperCase()
        ? -1
        : resultA.Name.toUpperCase() > resultB.Name.toUpperCase()
        ? 1
        : 0;

export const sortReleaseNoteByVersionNumber = (noteA: IReleaseNote, noteB: IReleaseNote) => {
    return noteB.VersionNumber.localeCompare(noteA.VersionNumber, undefined, {numeric: true});
};

export const sortByLastUpdatedBy = (announcementA: ICurrentAnnouncement, announcementB: ICurrentAnnouncement) => {
    const a = Date.parse(announcementA.updatedAt);
    const b = Date.parse(announcementB.updatedAt);

    return a > b ? -1 : a < b ? 1 : 0;
};
