import React, {useEffect, useState} from 'react';
import Carousel, {CarouselSlideRenderControlProps} from 'nuka-carousel';
import {NavigateBefore, NavigateNext} from '@mui/icons-material';
import {getCurrentAnnouncements} from '~/services/apiServices';
import {ICurrentAnnouncement} from '~/interfaces/common';

import './AnnouncementBanner.scss';
import {sortByLastUpdatedBy} from '~/utils/arrayUtils';

const AUTOPLAY_INTERVAL = 7000;

export enum ANNOUNCEMENT_LOCATION {
    ADMIN_DASHBOARD = 'adminDashboard',
    USER_SEARCH = 'userSearch',
    USER_LOGIN = 'userLogin',
}

interface IAnnouncementBannerProps {
    location: ANNOUNCEMENT_LOCATION;
}

export const AnnouncementBanner = ({location}: IAnnouncementBannerProps) => {
    const [announcements, setAnnouncements] = useState<ICurrentAnnouncement[]>([]);

    const fetchData = async () => {
        const m = await getCurrentAnnouncements();
        if (m.length > 0) {
            const sortedScheduledEmail = m.slice(1, m.length).sort(sortByLastUpdatedBy);
            setAnnouncements([m[0], ...sortedScheduledEmail]);
        } else {
            setAnnouncements(m);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return announcements.length > 0 ? (
        <AnnouncementBannerContent location={location} announcements={announcements} />
    ) : null;
};

interface IAnnouncementBannerContentProps {
    announcements: ICurrentAnnouncement[];
    location: ANNOUNCEMENT_LOCATION;
}

export const AnnouncementBannerContent = ({announcements, location}: IAnnouncementBannerContentProps) => {
    const isSingleAnnouncement = announcements.length == 1;

    const renderArrowButton = (props: CarouselSlideRenderControlProps) => {
        return (
            <div styleName="announcementArrows" data-testid={'previousNextArrows'}>
                <NavigateBefore styleName="directionArrows" onClick={props.previousSlide} />
                <NavigateNext styleName="directionArrows" onClick={props.nextSlide} />
            </div>
        );
    };

    const carouselSettings = {
        autoplay: true,
        autoplayInterval: AUTOPLAY_INTERVAL,
        dragging: false,
        wrapAround: true,
        renderTopRightControls: !isSingleAnnouncement ? renderArrowButton : null,
        renderCenterRightControls: null,
        renderCenterLeftControls: null,
        renderBottomCenterControls: null,
    };

    const renderAnnouncement = (announcement: ICurrentAnnouncement, index: number) => {
        return (
            <div styleName={`banner`} key={`announcement-${index}`} data-testid={`announcement-${index}`}>
                <span
                    style={!isSingleAnnouncement ? {maxWidth: '93%'} : {maxWidth: '100%'}}
                    data-testid={'announcementContent'}
                >
                    <span styleName="announcementNumber">
                        {!isSingleAnnouncement
                            ? `Announcement ${index + 1} of ${announcements.length}: `
                            : `Announcement: `}
                    </span>
                    {announcement ? announcement.message : ''}
                </span>
            </div>
        );
    };

    return (
        <div styleName="announcementBanner">
            <div styleName={location}>
                {isSingleAnnouncement ? (
                    renderAnnouncement(announcements[0], 0)
                ) : (
                    <Carousel {...carouselSettings}>
                        {announcements.map((announcement, index) => {
                            return renderAnnouncement(announcement, index);
                        })}
                    </Carousel>
                )}
            </div>
            <div styleName="bannerMargin"></div>
        </div>
    );
};
