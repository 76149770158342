import React from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';

export const MissingIntervention = () => {
    return (
        <Table>
            <TableBody style={{width: '100%'}}>
                <TableRow>
                    <TableCell style={{borderBottom: 'none', width: '100%'}}>
                        <Typography variant="body1">No data available</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
