import {createSlice} from '@reduxjs/toolkit';
import {IDomain} from '~/interfaces/common';
import {setDomains} from './domains.thunk';

export const initialDomainsState: IDomain[] = [];

export const domainsSlice = createSlice({
    name: 'domains',
    initialState: initialDomainsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDomains.fulfilled, (_, action) => {
            if (action.payload) {
                return action.payload;
            }
        });
    },
});

export const domainsActions = domainsSlice.actions;
export default domainsSlice.reducer;
