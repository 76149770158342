import {Button, Grid, Typography, IconButton} from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import {capitalize} from 'lodash';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {API_FETCH_STATE, LOADING_MESSAGE} from '~/constants';
import {IRequestWindow} from '~/interfaces/admin';
import {IFetchStatus} from '~/interfaces/common';
import {
    cancelRequestWindow,
    deleteRequestWindow,
    getAllRequestWindows,
    getWindowPeriodReport,
} from '~/services/requestWindowServices';
import {formatDate} from '~/utils/dateUtils';
import btnStyle from '../../../components/Common/Button/Button.scss';
import style from './DataAccessRequestWindow.scss';
import {useHistory} from 'react-router-dom';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import CloseIcon from '@mui/icons-material/Close';
import {DataAccessActionButton} from './DataAccessActionButton';

type Props = {
    location: {
        state?: {
            toastMsg?: string;
        };
    };
    altManageColumn?: (
        setRequestWindows: React.Dispatch<React.SetStateAction<IRequestWindow[]>>,
        openGrowl: (message: string) => void,
        data: IRequestWindow,
    ) => JSX.Element;
};

let columnCount = 0;
const customCellStyle = () => {
    const widths = ['12%', '26%', '17%', '17%', '12%', '16%'];
    const noOfColumns = widths.length;
    const widthIndex = columnCount;
    columnCount++;
    if (columnCount === noOfColumns) columnCount = 0;
    return {width: widths[widthIndex]};
};

export const DataAccessRequestWindow = (props: Props) => {
    const [requestWindows, setRequestWindows] = useState<IRequestWindow[]>([]);
    const [fetchState, setFetchState] = useState<IFetchStatus>(API_FETCH_STATE.PENDING);
    const history = useHistory();
    // const location = p.Location;
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const domainHeaderRef = useRef<HTMLDivElement>(null);

    const getFormattedDateStringFromISOString = (dateString: string) => {
        return formatDate(new Date(dateString), false, false, false);
    };

    useEffect(() => {
        if (props.location?.state === undefined) {
            return;
        }
        const locationState = props.location.state;
        if (locationState?.toastMsg !== undefined) {
            openGrowl(locationState.toastMsg);

            // reset the toastMsg so it will not display again on page reload
            history.replace({
                state: {
                    toastMsg: undefined,
                },
            });
        }
    }, []);

    useEffect(() => {
        getAllRequestWindows()
            .then((response) => {
                setRequestWindows(response);
            })
            .then(() => setFetchState(API_FETCH_STATE.SUCCESS))
            .catch((err) => {
                setFetchState(API_FETCH_STATE.ERROR);
                openGrowl(err.message);
            });
    }, []);

    const confirmDeleteHandler = (id: number) => {
        deleteRequestWindow(id)
            .then(getAllRequestWindows)
            .then((response) => {
                setRequestWindows(response);
            })
            .then(() => openGrowl('Request window deleted successfully'))
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const confirmCancelHandler = (id: number) => {
        cancelRequestWindow(id)
            .then(getAllRequestWindows)
            .then((response) => {
                setRequestWindows(response);
            })
            .then(() => openGrowl('Request window cancelled successfully'))
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const status = (data: IRequestWindow) => (
        <div
            data-testid={'status-block'}
            className={
                style.capitalized +
                ' ' +
                (data.Status === 'open' ? style.textGreen : data.Status === 'scheduled' ? style.textRed : '')
            }
        >
            {data.Status}
        </div>
    );

    const requestWindow = (data: IRequestWindow) => (
        <div className={style.wrap} data-testid={'window-block'}>
            {getFormattedDateStringFromISOString(data.WindowStart as string) +
                ' to ' +
                getFormattedDateStringFromISOString(data.WindowEnd as string)}
        </div>
    );

    const createdByCreatedOn = (data: IRequestWindow) => (
        <div className={style.wrap} data-testid={'author-block'}>
            {data.CreatedBy}
            <br />
            <Typography variant="body2" className={style.wrap}>
                {getFormattedDateStringFromISOString(data.CreatedAt as string)}
            </Typography>
        </div>
    );

    const cancelledByCancelledOn = (data: IRequestWindow) => (
        <span data-testid={'canceller-block'}>
            {data.Status === 'cancelled' ? (
                <div className={style.wrap}>
                    {data.LastUpdatedBy}
                    <br />
                    <Typography variant="body2" className={style.wrap}>
                        {getFormattedDateStringFromISOString(data.LastUpdatedAt as string)}
                    </Typography>
                </div>
            ) : (
                <div>-</div>
            )}
        </span>
    );

    const manage = (data: IRequestWindow) => (
        <div data-testid={'manage-block'}>
            {data.Status === 'open' ? (
                <DataAccessActionButton
                    buttonText="CANCEL"
                    confirmButtonHandler={() => confirmCancelHandler(data.Id as number)}
                    modalTitle="Cancel request window?"
                    modalContent={`All changes made in this request window will be discarded. 
                        System Administrators are reminded to inform Agency Administrators 
                        and Ops Unit Administrators of the cancellation via email.`}
                    cancelButtonText="KEEP WINDOW"
                    confirmButtonText="CANCEL WINDOW"
                />
            ) : data.Status === 'scheduled' ? (
                <DataAccessActionButton
                    buttonText="DELETE"
                    confirmButtonHandler={() => confirmDeleteHandler(data.Id as number)}
                    modalTitle="Delete scheduled request window?"
                    cancelButtonText="CANCEL"
                    confirmButtonText="DELETE"
                />
            ) : (
                '-'
            )}
        </div>
    );

    const consolidatedReport = (data: IRequestWindow) => {
        const downloadReport = async (windowId: number) => {
            try {
                const response = await getWindowPeriodReport(windowId);
                if (response && response.Url) {
                    window.location.href = decodeURI(response.Url);
                    openGrowl('Report downloaded successfully.');
                }
            } catch (err) {
                const error = err as Error;
                openGrowl(capitalize(error.message || ''));
            }
        };

        return (
            <div data-testid={'report-block'}>
                {data.Status === 'closed' ? (
                    <Button
                        size="medium"
                        className={style.actionBtn + ' ' + style.smallBtn}
                        onClick={() => downloadReport(data.Id)}
                    >
                        <span>DOWNLOAD</span>
                    </Button>
                ) : data.Status === 'open' || data.Status === 'scheduled' ? (
                    <Typography color="textSecondary">Available after window closes</Typography>
                ) : (
                    '-'
                )}
            </div>
        );
    };

    const DamContent = (
        <>
            <Grid container alignItems="center">
                <Grid item xs={12} className={style.pageHeader}>
                    <Typography variant="h4">Data Access Request Window</Typography>
                    <Button
                        size="medium"
                        color="primary"
                        className={btnStyle.normal}
                        onClick={() => {
                            history.push('/admin/data-access-request-window/create');
                        }}
                        data-testid={'add-new-request-window-btn'}
                    >
                        <span>ADD NEW REQUEST WINDOW</span>
                    </Button>
                </Grid>
                <Grid item xs={12} style={{padding: 14}} />
                <Grid xs={12} item>
                    <ListModel
                        columns={[
                            status,
                            requestWindow,
                            createdByCreatedOn,
                            cancelledByCancelledOn,
                            props.altManageColumn
                                ? (data: IRequestWindow) =>
                                      (
                                          props.altManageColumn as (
                                              setRequestWindows: React.Dispatch<React.SetStateAction<IRequestWindow[]>>,
                                              openGrowl: (message: string) => void,
                                              data: IRequestWindow,
                                          ) => JSX.Element
                                      )(setRequestWindows, openGrowl, data)
                                : manage,
                            consolidatedReport,
                        ]}
                        headers={[
                            'Status',
                            <div key={'Request Window'} className={style.wrap}>
                                Request Window
                            </div>,
                            <div key={'Author'} className={style.wrap}>
                                Created by <br />
                                <Typography variant="body2">Created on</Typography>
                            </div>,
                            <div key={'Canceller'} className={style.wrap}>
                                Cancelled by <br />
                                <Typography variant="body2">Cancelled on</Typography>
                            </div>,
                            'Manage',
                            'Consolidated Report',
                        ]}
                        customHeaderCellStyle={() => ({
                            backgroundColor: '#3949ab',
                            color: '#ffffff',
                        })}
                        customCellStyle={customCellStyle}
                        modelList={requestWindows}
                        defaultSortOrder="asc"
                        variant="expanded"
                        sortable={false}
                    />
                </Grid>
            </Grid>
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                    action: (
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                <CloseIcon sx={{color: '#E8EAF6'}} fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    ),
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );

    const DamContentWithLoader = WithAdminLoader<{}>(() => DamContent, fetchState, LOADING_MESSAGE.DATA);

    return <DamContentWithLoader />;
};
