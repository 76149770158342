import React from 'react';
import {Checkbox} from '@mui/material';
import {IDomain} from '~/interfaces/common';
import {DomainCheckBoxItem} from './DomainCheckBoxItem';
import {COPIES} from '~/constants';
import {getSubDomainsCodes} from './DomainCheckBoxItem';
import './DomainCheckBoxList.scss';

export const getDomainsSubDomainsCodes = (domains: IDomain[]) => {
    return domains.flatMap((domain) => [...getSubDomainsCodes(domain)]);
};

export interface IDomainsCheckBoxGroupProps {
    domains: IDomain[];
    selectedSubDomains: string[];
    onSelectSubDomains: (selected: string[]) => void;
}

export const DomainCheckBoxList = ({domains, selectedSubDomains, onSelectSubDomains}: IDomainsCheckBoxGroupProps) => {
    const subDomainCodes = getDomainsSubDomainsCodes(domains);
    const isAllDomainsSelected = () => {
        return selectedSubDomains.length === subDomainCodes.length;
    };

    const handleSelectAll = (_e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        if (isChecked) {
            onSelectSubDomains(subDomainCodes);
        } else {
            onSelectSubDomains([]);
        }
    };

    const allDomainsLabelStyle = (checked: boolean) => {
        return `allDomainsCheckBoxLabel ${checked ? 'checked' : 'unChecked'}`;
    };

    const allDomainsChecked = isAllDomainsSelected();

    return (
        <div styleName="domainCheckBoxList" key="domainCheckBoxList">
            <label styleName={allDomainsLabelStyle(allDomainsChecked)}>
                <Checkbox onChange={handleSelectAll} color="primary" checked={isAllDomainsSelected()} />
                {COPIES.REPORT_CUSTOMISATION_CHECK_ALL}
            </label>
            {domains.map((domain, i) => (
                <DomainCheckBoxItem
                    key={i}
                    domain={domain}
                    selectedSubDomains={selectedSubDomains}
                    onSelectSubDomains={onSelectSubDomains}
                />
            ))}
        </div>
    );
};
