import {Button} from '@mui/material';
import React, {useEffect} from 'react';
import {config} from '~/config';
import {IRequestWindow} from '~/interfaces/admin';
import {getAllRequestWindows} from '~/services/requestWindowServices';
import {closeRequestWindow, openRequestWindow} from '~/services/simulatedRequestWindowServices';
import {DataAccessRequestWindow} from './DataAccessRequestWindow';
import style from './DataAccessRequestWindow.scss';
import {useHistory} from 'react-router-dom';
import {ENVIRONMENT_NAMES} from '~/constants';

type Props = {
    location: {
        state?: {
            toastMsg?: string;
        };
    };
};

export const UATViewableDataAccessRequestWindow = (props: Props) => {
    const history = useHistory();

    const altManageColumn = (
        setRequestWindows: React.Dispatch<React.SetStateAction<IRequestWindow[]>>,
        openGrowl: (message: string) => void,
        data: IRequestWindow,
    ) => {
        const openButtonHandler = (id: number) => {
            openRequestWindow(id)
                .then(getAllRequestWindows)
                .then((response) => {
                    setRequestWindows(response);
                })
                .then(() => openGrowl('Request window opened successfully'))
                .catch((err) => {
                    openGrowl(err.message);
                });
        };

        const closeButtonHandler = (id: number) => {
            closeRequestWindow(id)
                .then(getAllRequestWindows)
                .then((response) => {
                    setRequestWindows(response);
                })
                .then(() => openGrowl('Request window closed successfully'))
                .catch((err) => {
                    openGrowl(err.message);
                });
        };

        return (
            <div data-testid={'manage-block'}>
                {data.Status === 'open' ? (
                    <Button
                        size="medium"
                        color="primary"
                        className={style.actionBtn + ' ' + style.smallBtn}
                        onClick={() => {
                            closeButtonHandler(data.Id as number);
                        }}
                    >
                        <span>CLOSE</span>
                    </Button>
                ) : data.Status === 'scheduled' ? (
                    <Button
                        size="medium"
                        color="primary"
                        className={style.actionBtn + ' ' + style.smallBtn}
                        onClick={() => {
                            openButtonHandler(data.Id as number);
                        }}
                    >
                        <span>OPEN</span>
                    </Button>
                ) : (
                    '-'
                )}
            </div>
        );
    };
    useEffect(() => {
        if (config.environment == (ENVIRONMENT_NAMES.UAT as string)) {
            // UAT environment
            return;
        } else if (config.environment == (ENVIRONMENT_NAMES.QA as string)) {
            // QA environment
            return;
        } else if (config.environment == (ENVIRONMENT_NAMES.LOCAL as string)) {
            // local environment
            return;
        }

        // prod environment

        history.goBack();
    }, []);

    return <DataAccessRequestWindow location={props.location} altManageColumn={altManageColumn} />;
};
