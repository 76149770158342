import React, {useContext, useState} from 'react';
import {ReportTable} from '~/components/Admin/Report/ReportTable';
import {Grid, Typography, Button} from '@mui/material';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {GetReportsByAccessCustomised, IReportBase} from './ReportAccess';
import {Redirect} from 'react-router';
import {getReports} from '~/services/reportServices';
import CircularProgress from '@mui/material/CircularProgress';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import '../Admin.scss';
import {COPIES} from '~/constants';

export interface IReport extends IReportBase {
    Id: number;
    Selected: boolean;
}

export interface IReportRequest {
    Reports: IReport[];
    Password: string;
}

const setTimeEarliest = (date: Date): Date => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};

const setTimeLatest = (date: Date): Date => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
};

export const Report: React.FunctionComponent<{}> = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const {accessToken} = useContext(AuthenticationContext);
    const accessLevel = accessToken?.Permissions.AccessLevel;
    const reportsByAccess = GetReportsByAccessCustomised(accessLevel, {
        dateFrom: setTimeEarliest(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    });
    const [reports, setReports] = React.useState<IReport[]>(
        reportsByAccess.map((report, id) => ({Id: id, ...report, Selected: false})),
    );

    const selectedReports = reports.filter((report) => report.Selected);
    const buttonIsDisabled = selectedReports.length == 0;

    const formatDateISO = (date: Date) => {
        return date.toISOString();
    };

    const formattedReport = selectedReports.map((report) => {
        if (report.DateTo && report.DateFrom) {
            // Subjected to screening_by_users and api_consumer_requests reports & monthly_review_reports
            return {
                ...report,
                DateFrom: formatDateISO(report.DateFrom as Date),
                DateTo: formatDateISO(setTimeLatest(report.DateTo as Date)),
            };
        }
        return report;
    });

    const fetchReports = (report: IReport[], password: string) => {
        const reportRequest: IReportRequest = {
            Reports: report,
            Password: password,
        };
        setIsLoading(true);
        getReports(reportRequest)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleGenerateReport = () => {
        setPasswordModalOpen(true);
    };

    const handlePasswordModalClose = () => {
        setPasswordModalOpen(false);
    };

    const handlePasswordSubmit = (password: string) => {
        fetchReports(formattedReport, password);
    };

    return reportsByAccess.length > 0 ? (
        <>
            <Grid
                container
                data-testid="reportHeader"
                styleName="adminPageHeader"
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={3}
            >
                <Grid item xs={8}>
                    <Typography variant="h4">Reports</Typography>
                </Grid>
                <Grid item id="downloadReport" styleName="downloadReportBtn" xs={4}>
                    <Button
                        size="large"
                        color="primary"
                        data-testid="downloadReport"
                        disabled={buttonIsDisabled || isLoading}
                        styleName={buttonIsDisabled || isLoading ? 'normal disabled' : 'normal'}
                        onClick={() => handleGenerateReport()}
                    >
                        <span>DOWNLOAD REPORT</span> {isLoading && <CircularProgress size={24} color="inherit" />}
                    </Button>
                </Grid>
            </Grid>
            <ReportTable data-testid="reportTable" reports={reports} setReports={setReports} />
            <ReportPasswordDialog
                open={passwordModalOpen}
                onSubmit={handlePasswordSubmit}
                onClose={handlePasswordModalClose}
                title={COPIES.REPORT_PASSWORD_DIALOG_TITLE}
                description={COPIES.REPORT_PASSWORD_DIALOG_BODY}
            />
        </>
    ) : (
        <Redirect to={`/`} />
    );
};
