import React from 'react';
import {Button} from '@mui/material';
import './ResourcesToolTipPopper.scss';

export interface IResourcesToolTipPopper {
    onGotIt: () => void;
}

export const ResourcesToolTipPopper: React.FC<IResourcesToolTipPopper> = ({onGotIt}) => {
    return (
        <div styleName="resourcesToolTipModalPopper">
            <div styleName="displayMsg">You can find the tutorial video under ‘Resources’!</div>
            <Button color="primary" variant="contained" styleName="gotItButton" onClick={onGotIt}>
                GOT IT
            </Button>
        </div>
    );
};
