import {IAppState} from '~/store';
import {pick} from 'lodash';

export const getUsersFromOpsUnit = (opsUnitId: number) => (state: IAppState) => {
    const opsUnit = state.data.admin.opsUnits[opsUnitId];
    return !!opsUnit?.Users ? pick(state.data.admin.users, opsUnit?.Users) : {};
};

export const getUsers = (teamId: number) => (state: IAppState) => {
    const team = state.data.admin.teams[teamId];
    return !!team?.Users ? pick(state.data.admin.users, team?.Users) : {};
};
