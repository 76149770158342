import {Box} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {TableButton} from '~/components/Common/Button/TableButton';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {IAnnouncement} from '~/interfaces/admin';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {ActiveIcon} from '../ActiveIcon/ActiveIcon';
import {InactiveIcon} from '../InactiveIcon/InactiveIcon';

import './ScheduledAnnouncementTable.scss';

interface IScheduledAnnouncementTableProps {
    announcements: IAnnouncement[];
}

export const ScheduledAnnouncementTable = ({announcements}: IScheduledAnnouncementTableProps) => {
    const history = useHistory();
    const name = (data: IAnnouncement) => data.name;

    const startDate = (data: IAnnouncement) => {
        return data.startDate ? (
            <Box component="div">
                <Box component="div" styleName="rowText">
                    {formatDate(new Date(data.startDate), false)}
                </Box>
                <Box component="div" styleName="rowSubtext">
                    {formatTime(new Date(data.startDate), true, true, true, true, false)}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };

    const endDate = (data: IAnnouncement) => {
        return data.endDate ? (
            <Box component="div">
                <Box component="div" styleName="rowText">
                    {formatDate(new Date(data.endDate), false)}
                </Box>
                <Box component="div" styleName="rowSubtext">
                    {formatTime(new Date(data.endDate), true, true, true, true, false)}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };

    const status = (data: IAnnouncement) => (data.isActive ? <ActiveIcon /> : <InactiveIcon />);

    const lastUpdated = (data: IAnnouncement) => {
        return data.updatedAt ? (
            <Box component="div">
                <Box component="div" styleName="rowText">
                    {data.updatedAt
                        ? `${formatDate(new Date(data.updatedAt), false)} ${formatTime(
                              new Date(data.updatedAt),
                              true,
                              true,
                              true,
                              true,
                              false,
                          )}`
                        : ''}
                </Box>
                <Box component="div" styleName="rowSubtext">
                    {data.updatedBy}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };

    const handleView = (id: number) => {
        history.push(`/admin/announcements/${id}`);
    };

    const viewButton = (data: IAnnouncement) => {
        return <TableButton text="View" onClick={() => handleView(data.Id as number)} />;
    };

    return (
        <div styleName="customTable">
            <ListModel
                columns={[name, startDate, endDate, status, lastUpdated, viewButton]}
                headers={['Announcement Name', 'Start Date', 'End Date', 'Status', 'Last Updated', '']}
                modelList={announcements}
                modelComparator={[() => 0, () => 0, () => 0, () => 0, () => 0, () => 0]}
                variant="expanded"
                sortable={false}
            />
        </div>
    );
};
