import React from 'react';
import {formatCurrency} from '~/utils/currencyUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {Grid} from '@mui/material';
import {SubDomainStatus} from '~/interfaces/client';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {allNoResults, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export interface IHousingPIData {
    Data: {
        PrincipalAmount: string;
        AccruedInterest: string;
        TotalRefundAmount: string;
        RefundDate: string;
        RefundAddress: string;
    };
    ErrorMessage: string;
    Status: string;
}
interface HousingPIDetailsProps {
    details: IHousingPIData;
}

export const HousingPIDetails: React.FC<HousingPIDetailsProps> = ({details}) => {
    if (details.ErrorMessage && details.ErrorMessage === '#2001') {
        return (
            <>
                <NoData
                    message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                        Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                    }`}
                />
                <p data-testid={'2001-error-message'}>
                    This member did not use his/her CPF savings for his/her property.
                </p>
            </>
        );
    }

    if ((!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    if (allNoResults([details.Data.PrincipalAmount, details.Data.AccruedInterest]))
        return (
            <NoData
                message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} ${
                    Boolean(details.ErrorMessage) ? `(${details.ErrorMessage})` : ''
                }`}
            />
        );

    const displayedFields = [
        {
            label: 'Total Principal Amount Withdrawn',
            value: formatValue(details.Data.PrincipalAmount, parseFloat, formatCurrency),
        },
        {
            label: 'Accrued Interest',
            value: formatValue(details.Data.AccruedInterest, parseFloat, formatCurrency),
        },
    ];

    return (
        <Grid container spacing={3}>
            <SimpleGridDisplay fields={displayedFields} />
        </Grid>
    );
};
