import {TableFooter, TableRow, TableCell, Button} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import React from 'react';
import './ExpandedListModelFooter.scss';

export interface IExpandedListModelFooterProps {
    onExpand: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onCollapse: (e: React.MouseEvent<HTMLButtonElement>) => void;
    columnLength: number;
    rowsPerPage: number;
    count: number;
    initialRowsPerPage: number;
}

export const ExpandedListModelFooter = ({
    onExpand,
    onCollapse,
    columnLength,
    rowsPerPage,
    count,
    initialRowsPerPage,
}: IExpandedListModelFooterProps) =>
    count > initialRowsPerPage ? (
        <TableFooter>
            <TableRow styleName="tableFooterRowExpanded">
                <TableCell colSpan={columnLength}>
                    {rowsPerPage < count ? (
                        <Button styleName="readMore" onClick={onExpand}>
                            <ExpandMoreIcon styleName="icon" />
                            Read More
                        </Button>
                    ) : (
                        <Button styleName="readMore" onClick={onCollapse}>
                            <ExpandLessIcon styleName="icon" />
                            Read Less
                        </Button>
                    )}
                </TableCell>
            </TableRow>
        </TableFooter>
    ) : null;
