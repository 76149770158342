import React from 'react';
import {Grid, Box, Button, Typography} from '@mui/material';
import {BackButton} from '../../../components/Common/Button/BackButton';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/Error';
import './DataUploadResponse.scss';

interface IDataUploadProps {
    goBackMainPage: () => void;
    setPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    dataAgency: string;
    filename: string;
    numberOfErrors: number;
    allRecordsFailed: boolean;
}

export const DataUploadResponse = ({
    goBackMainPage,
    setPasswordModalOpen,
    filename,
    dataAgency,
    numberOfErrors,
    allRecordsFailed,
}: IDataUploadProps) => {
    const onClickDownload = () => {
        goBackMainPage();
        setPasswordModalOpen(true);
    };

    return (
        <>
            <Box component="div" styleName="backButtonWrapper">
                <BackButton onClick={goBackMainPage} />
            </Box>
            <Grid container styleName="pageRowWrapper">
                <Grid container direction="column" styleName="pageColumnWrapper">
                    {allRecordsFailed && (
                        <Box styleName="showBox" data-testid="all-failure">
                            <ErrorIcon style={{fontSize: 80}} styleName="failureIcon" />
                            <Typography styleName="responseTitle">Records Not Uploaded</Typography>
                            <Typography styleName="responseText">
                                It looks like there were errors with all records in &quot;
                                <span styleName="responseFilename">{filename}</span>&quot;. {'\n\n'}
                                Please download the Summary Report to review the errors. Once corrected, these records
                                should be re-uploaded separately.
                            </Typography>
                        </Box>
                    )}
                    {!allRecordsFailed && numberOfErrors == 0 && (
                        <Box styleName="showBox" data-testid="success">
                            <CheckCircleRoundedIcon style={{fontSize: 80}} styleName="checkIcon" />
                            <Typography styleName="responseTitle">File Successfully Uploaded</Typography>
                            <Typography styleName="responseText">
                                All data in &quot;<span styleName="responseFilename">{filename}</span>&quot; has been
                                saved and will be displayed in the {dataAgency} sub-domain. Download a Summary Report of
                                your upload now or review it later in &apos;Summary History&apos;.
                            </Typography>
                        </Box>
                    )}
                    {!allRecordsFailed && numberOfErrors > 0 && (
                        <Box styleName="showBox" data-testid="partial-failure">
                            <ErrorIcon style={{fontSize: 80}} styleName="errorIcon" />
                            <Typography styleName="responseTitle">File Partially Uploaded</Typography>
                            <Typography styleName="responseText">
                                It looks like there were {numberOfErrors} errors in &quot;
                                <span styleName="responseFilename">{filename}</span>&quot;. Only successful records have
                                been saved to our system and will be displayed in the {dataAgency} sub-domain. {'\n\n'}
                                Please download the Summary Report to review the errors. Once corrected, these records
                                should be re-uploaded separately.
                            </Typography>
                        </Box>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClickDownload}
                        style={{paddingLeft: 60, paddingRight: 60}}
                    >
                        DOWNLOAD SUMMARY
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
