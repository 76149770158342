import React from 'react';
import {Button} from '@mui/material';

interface ITableButton {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    text: string;
    id?: string;
}

export const TableButton = ({onClick, text, id}: ITableButton) => {
    return (
        <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={onClick}
            id={id}
            style={{
                width: 74,
                height: 32,
            }}
        >
            {text}
        </Button>
    );
};
