import {Box, Button, Grid} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {AnnouncementEdit} from '~/components/Admin/AnnouncementEdit/AnnouncementEdit';
import {AddButtonSecondary} from '~/components/Common/Button/AddButtonSecondary';
import {BackButton} from '~/components/Common/Button/BackButton';
import {EditButton} from '~/components/Common/Button/EditButton';
import {ConfirmDialog, IConfirmDialogProps} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {emptyAnnouncement, IAnnouncement} from '~/interfaces/admin';
import {getAnnouncement, updateAnnouncement} from '~/services/announcementServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import styles from './AnnouncementView.scss';

const defaultConfirmProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {},
    open: false,
    title: '',
    message: '',
    cancelOption: '',
    confirmOption: '',
} as IConfirmDialogProps;

export const AnnouncementViewWrapper = () => {
    const {id} = useParams<{id: string}>();
    const history = useHistory();
    return (
        <Grid container direction="column">
            <Grid item>
                <BackButton
                    onClick={() => {
                        history.push('/admin/announcements');
                    }}
                />
            </Grid>
            <Grid item>
                <AnnouncementView id={parseInt(id)} isDefault={false} />
            </Grid>
        </Grid>
    );
};

interface IAnnouncementViewProps {
    id: number;
    isDefault: boolean;
}

export const AnnouncementView = ({id, isDefault}: IAnnouncementViewProps) => {
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const headerRef = useRef<HTMLDivElement>(null);

    const [announcement, setAnnouncement] = useState<IAnnouncement>(emptyAnnouncement);
    const [isEditAnnouncement, setIsEditAnnouncement] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<boolean>(false);

    const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(defaultConfirmProps);

    const [hasError, setHasError] = useState<boolean>(false);

    const fetchAnnouncement = async () => {
        try {
            const announcement = await getAnnouncement(id);
            setAnnouncement(announcement);
        } catch (err) {
            const error = err as Error;
            setFetchError(true);
            openGrowl(error.message);
        }
    };

    useEffect(() => {
        fetchAnnouncement();
    }, []);

    const handleConfirm = async (newAnnouncement: IAnnouncement) => {
        try {
            await updateAnnouncement(newAnnouncement.Id, newAnnouncement);
            fetchAnnouncement();
            setIsEditAnnouncement(false);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        }
        setConfirmDialogProps({...confirmDialogProps, open: false});
    };

    const handleCancel = () => {
        setIsEditAnnouncement(false);
        fetchAnnouncement();
        setConfirmDialogProps({...confirmDialogProps, open: false});
    };

    const handleEdit = () => {
        setIsEditAnnouncement(true);
    };

    const handleSubmit = () => {
        if (announcement.message.length == 0) {
            setConfirmDialogProps({
                ...confirmDialogProps,
                onCancel: () => {
                    setConfirmDialogProps({...confirmDialogProps, open: false});
                },
                onConfirm: () => {
                    handleConfirm({...announcement});
                },
                open: true,
                title: 'No Message?',
                message: 'Announcement will not be displayed on OneCV',
                cancelOption: 'Cancel',
                confirmOption: 'Save',
            });
        } else {
            setConfirmDialogProps({
                ...confirmDialogProps,
                onCancel: () => {
                    setConfirmDialogProps({...confirmDialogProps, open: false});
                },
                onConfirm: () => {
                    handleConfirm({...announcement});
                },
                open: true,
                title: 'Save Announcement?',
                message: isDefault
                    ? 'Any changes made to the announcement will be displayed when there are no scheduled announcements'
                    : 'Any changes made to the announcement will be displayed during the scheduled time',
                cancelOption: 'Cancel',
                confirmOption: 'Save',
            });
        }
    };

    const ControlButtons = () => {
        if (isEditAnnouncement) {
            return (
                <div className={styles.buttonWrappers}>
                    <Button
                        onClick={handleCancel}
                        type="button"
                        color="primary"
                        variant="text"
                        data-testid="cancelButton"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        type="button"
                        variant="contained"
                        color="primary"
                        id="submit-button"
                        disabled={hasError}
                    >
                        Save
                    </Button>
                </div>
            );
        }
        if (announcement.message === '') {
            return <AddButtonSecondary onClick={handleEdit} text="Add Announcement" />;
        }
        return <EditButton onClick={handleEdit} />;
    };

    if (fetchError) {
        return <div>An error has occured while fetching announcement</div>;
    }

    return (
        <>
            <Box className={styles.announcementSection}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={8}>
                        <AnnouncementEdit
                            announcement={announcement}
                            setAnnouncement={setAnnouncement}
                            isEdit={isEditAnnouncement}
                            isDefault={isDefault}
                            setHasError={setHasError}
                        />
                    </Grid>
                    <Grid item>
                        <ControlButtons />
                    </Grid>
                </Grid>
            </Box>

            <ConfirmDialog {...confirmDialogProps} />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={headerRef}
            />
        </>
    );
};
