import axios, {Method, AxiosRequestConfig, AxiosResponse} from 'axios';

interface CustomError extends Error {
    response: {
        data: string;
        status: number;
    };
}

const handleUnauthorized = (statusCode: number) => {
    if (statusCode === 401) {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/logout?redirect=true`;
        window.location.replace(redirectUrl);
    }
};

export const httpRequest = async (config: AxiosRequestConfig, requiredAuth = true): Promise<AxiosResponse> => {
    const token = localStorage.getItem('token');

    // Default Config
    let contentType = 'application/json';
    let method: Method | string = 'GET';

    if (config.headers && config.headers['Content-Type']) {
        contentType = config.headers['Content-Type'] as string;
    }

    if (config.method) method = config.method;

    let updatedConfig: AxiosRequestConfig = {};

    if (!requiredAuth) {
        updatedConfig = {
            ...config,
            headers: {
                ...config?.headers,
                'Content-Type': contentType,
            },
            method,
        };
    } else {
        updatedConfig = {
            ...config,
            withCredentials: true,
            headers: {
                ...config?.headers,
                Authorization: `Bearer ${token}`,
                'Content-Type': contentType,
            },
            method,
        };
    }

    try {
        return await axios.request(updatedConfig);
    } catch (err) {
        const error = err as CustomError;
        if (error.response) {
            // Request made and server responded;
            const errorStatus = error.response.status;
            handleUnauthorized(errorStatus);

            if (error.response.data && typeof error.response.data == 'string') {
                // Throw error with string as data response
                throw new Error(error.response.data);
            } else {
                // Catch and simply rethrow error without any change. Access the data obj from error.response.data
                // For 400s/500s
                throw error;
            }
        } else {
            // For both 1) request was made and no response received (i.e. error.request exists)
            // And 2) scenario where request failed to setup. (i.e. error.response and error.request does not exist)
            throw new Error(error.message);
        }
    }
};
