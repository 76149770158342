import {IDataAccessMatrixDomain, IDataAccessMatrixField, IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {IAppState} from '~/store';
import {IDataFieldRequestStatus} from '~/store/dataReducers';

export const getSubDomainName = (domainId: number, subDomainId: number) => (state: IAppState) =>
    state.data.dam.dictionary
        .find((data: IDataAccessMatrixDomain) => data.Id === domainId)
        ?.SubDomains.find((data: IDataAccessMatrixSubDomain) => data.Id === subDomainId)?.Name || '';

export const getSubDomainReason = (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
    const reason = state.data.dam2.opsUnits[opsUnitId].subDomainReason[domainId][subDomainId];
    return reason;
};

export const getSelectedFieldIds = (opsUnitId: number) => (state: IAppState) => {
    const {dataFieldRequestStatuses} = state.data.dam2.opsUnits[opsUnitId] || {};

    const ids = [];

    for (const domain in dataFieldRequestStatuses) {
        for (const subDomain in dataFieldRequestStatuses[domain]) {
            for (const field in dataFieldRequestStatuses[domain][subDomain]) {
                if (
                    dataFieldRequestStatuses[domain][subDomain][field] &&
                    dataFieldRequestStatuses[domain][subDomain][field].checked
                ) {
                    ids.push(Number(field));
                }
            }
        }
    }

    return ids;
};

export const getFieldState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldId: number) => (state: IAppState) =>
        state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId][fieldId];

export const getGroupedFieldsCheckedState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldIds: number[]) => (state: IAppState) => {
        const selectedFields = [];

        fieldIds.forEach((id) => {
            const dataFieldRequestStatus =
                state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId][id];
            if (dataFieldRequestStatus && dataFieldRequestStatus.checked) {
                selectedFields.push(id);
            }
        });

        return selectedFields.length === fieldIds.length && selectedFields.length > 0;
    };

export const getGroupedFieldsIndeterminateState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldIds: number[]) => (state: IAppState) => {
        const selectedFields = [];

        fieldIds.forEach((id) => {
            const dataFieldRequestStatus =
                state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId][id];
            if (dataFieldRequestStatus && dataFieldRequestStatus.checked) {
                selectedFields.push(id);
            }
        });

        return selectedFields.length < fieldIds.length && selectedFields.length > 0;
    };

export const getDomainMatrixCheckedState = (opsUnitId: number, domainId: number) => (state: IAppState) => {
    const domainFields = state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId];
    const fields: IDataFieldRequestStatus[] = [];
    Object.values(domainFields).forEach((df) => fields.push(...Object.values(df)));
    return fields.every((f) => f.checked);
};

export const getDomainMatrixisIndeterminateState = (opsUnitId: number, domainId: number) => (state: IAppState) => {
    const domainFields = state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId];
    const fields: IDataFieldRequestStatus[] = [];
    Object.values(domainFields).forEach((df) => fields.push(...Object.values(df)));
    return fields.some((f) => f.checked) && fields.some((f) => !f.checked);
};

export const getSubDomainMatrixCheckedState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const subDomainFields = state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId];
        const fields = Object.values(subDomainFields);

        return fields.every((f) => f.checked);
    };

export const getSubDomainMatrixIndeterminateState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const subDomainFields = state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId];
        const fields = Object.values(subDomainFields);

        return fields.some((f) => f.checked) && fields.some((f) => !f.checked);
    };

export const getAccessRejectedReasonsByDomainState = (opsUnitId: number, domainId: number) => (state: IAppState) => {
    return state.data.dam2.opsUnits[opsUnitId].accessRejectedReasons[domainId];
};

export const getAccessRejectedReasonsState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const accessRejectedReasons =
            state.data.dam2.opsUnits[opsUnitId].accessRejectedReasons[domainId]?.[subDomainId];
        return accessRejectedReasons || [];
    };

export const getDataFieldNameState =
    (domainId: number, subDomainId: number, dataFieldId: number) => (state: IAppState) => {
        const dataFieldName =
            state.data.dam.dictionary
                .find((data: IDataAccessMatrixDomain) => data.Id === domainId)
                ?.SubDomains.find((data: IDataAccessMatrixSubDomain) => data.Id === subDomainId)
                ?.DataFields.find((data: IDataAccessMatrixField) => data.Id === dataFieldId)?.Name || '';
        return dataFieldName;
    };

export const getSentForReviewFieldsState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const dataFieldsBySubdomain =
            state.data.dam2.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId];
        const dataFields = dataFieldsBySubdomain ? Object.values(dataFieldsBySubdomain) : [];
        return dataFields.filter((df) => df.requestStatus?.toLocaleLowerCase() === 'sent for review');
    };

export const getDomainDataRequestState = () => (state: IAppState) => {
    return state.data.dam2.domainDataRequestState;
};
export const getSubDomainAccessReasonStatus = (opsUnitId: number) => (state: IAppState) => {
    const {subDomainReason} = state.data.dam2.opsUnits[opsUnitId] || {};

    const approvedIds = [];
    const rejectedIds = [];

    for (const domain in subDomainReason) {
        for (const subDomain in subDomainReason[domain]) {
            const {updatedReasonForAccess} = subDomainReason[domain][subDomain];
            if (updatedReasonForAccess && updatedReasonForAccess.status === 'approve') {
                approvedIds.push(updatedReasonForAccess.referenceId);
            }
            if (updatedReasonForAccess && updatedReasonForAccess.status === 'reject') {
                rejectedIds.push(updatedReasonForAccess.referenceId);
            }
        }
    }

    return {approvedIds, rejectedIds};
};

export const getDataFieldRequestStatuesState = (opsUnitId: number) => (state: IAppState) => {
    return state.data.dam2.opsUnits[opsUnitId]?.dataFieldRequestStatuses;
};

export const getSubDomainReasonState = (opsUnitId: number) => (state: IAppState) => {
    return state.data.dam2.opsUnits[opsUnitId]?.subDomainReason;
};

export const getInitialMatrixState = (opsUnitId: number) => (state: IAppState) => {
    return state.data.dam2.opsUnits[opsUnitId]?.initialMatrix || {};
};

export const getDamRequestFieldsUpdated = () => (state: IAppState) => {
    return state.data.dam2.fieldsUpdated;
};

export const getFormState = () => (state: IAppState) => {
    return state.data.dam2.domainDataRequestState?.FormMeta?.State || '';
};
