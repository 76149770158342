import {useEffect, useState} from 'react';
import {browserName, fullBrowserVersion, isMobile} from 'react-device-detect';
import {config} from '~/config';
import {versionCompare} from '../versionUtils';

export default function useBrowserInfo() {
    const [isOutdated, setIsOutdated] = useState(false);
    const [isIncompatible, setIsIncompatible] = useState(false);

    useEffect(() => {
        const key = browserName.toLowerCase();

        const isCompatible = config.compatibleBrowserVersions && !!config.compatibleBrowserVersions[key];
        const isOutdated =
            config.compatibleBrowserVersions &&
            versionCompare(fullBrowserVersion, config.compatibleBrowserVersions[key]) < 0
                ? true
                : false;

        setIsOutdated(isOutdated);
        setIsIncompatible(!isCompatible);
    }, []);

    return {browserName, fullBrowserVersion, isIncompatible, isOutdated, isMobile};
}
