import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {Autocomplete, TextField, Tooltip, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import {get} from 'lodash';
import React, {useEffect} from 'react';
import {ReactivateButton} from '~/components/Common/Button/ReactivateButton';
import {InputField} from '~/components/Common/InputField/InputField';
import {DEFAULT_SHORT_FIELD_MAX_LENGTH, USER_TYPE} from '~/constants';
import {IAgency, ITeam, IUser} from '~/interfaces/admin';
import {validateChecksum} from '~/utils/validateChecksum';
import {
    checkMaxLength,
    email as emailFormat,
    fixedLength,
    preventNRICInsertion,
    required,
    singaporeMobileNumber,
    singaporeNric,
} from '../../Common/InputField/ValidationRules';
import style from './UserDetails.scss';

interface IUserDetails {
    heading?: string;
    details?: IUser;
    setUser: React.Dispatch<React.SetStateAction<IUser>>;
    fieldsToDisable?: string[];
    handleActivateUser?: (userId?: number) => void;
    customError?: {[key: string]: string | undefined};
    isOaUserMgmt?: boolean;
    teams?: ITeam[];
    disableInput?: boolean;
    agencies?: IAgency[];
    setAgency?: React.Dispatch<React.SetStateAction<IAgency | undefined>>;
    setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UserDetails = ({
    heading,
    details,
    setUser,
    fieldsToDisable,
    handleActivateUser,
    customError,
    isOaUserMgmt = false,
    teams,
    disableInput,
    agencies,
    setAgency,
    setHasError,
}: IUserDetails) => {
    const name = get(details, 'Name', '');
    const division = get(details, 'Division', '');
    const designation = get(details, 'Designation', '');
    const email = get(details, 'Email', '');
    const userName = get(details, 'UserName', '');
    const partialNric: string = get(details, 'PartialNric', '');
    const officeNumber = get(details, 'OfficeNumber', '');
    const mobileNumber = get(details, 'MobileNumber', '');
    const isActive = get(details, 'IsActive', true);
    const teamId = get(details, 'TeamId', undefined);
    const userType = get(details, 'UserType', '');

    const id = heading?.replace(/\s/g, '-').toLowerCase(); // remove spaces and convert to lower case for valid id

    const shouldDisableField = (field: string) => {
        return disableInput || (fieldsToDisable && fieldsToDisable.includes(field));
    };

    const activateUser = () => {
        handleActivateUser && handleActivateUser(details?.Id);
    };

    const customErrorMessage = (field: string) => {
        return customError && customError[field];
    };

    const StyledTooltip = withStyles({
        tooltip: {
            fontSize: '12px',
            fontWeight: 'normal',
            maxWidth: 'none',
        },
    })(Tooltip);

    const currentTeam = teams?.filter((team) => team.Id === Number(teamId))[0];

    const formatUserName = (value: string) => {
        return shouldDisableField('userName') ? `*****${partialNric.toUpperCase()}` : value;
    };

    useEffect(() => {
        if (setHasError) {
            if (
                validateChecksum(name) ||
                validateChecksum(division) ||
                validateChecksum(designation) ||
                (details &&
                    ((details?.Name && details?.Name.length > DEFAULT_SHORT_FIELD_MAX_LENGTH) ||
                        (details?.Division && details?.Division.length > DEFAULT_SHORT_FIELD_MAX_LENGTH) ||
                        (details?.Designation && details?.Designation.length > DEFAULT_SHORT_FIELD_MAX_LENGTH) ||
                        (details?.Email && details?.Email.length > DEFAULT_SHORT_FIELD_MAX_LENGTH)))
            ) {
                setHasError(true);
            } else {
                setHasError(false);
            }
        }
    }, [details, name, division, designation]);

    return (
        <div className={style.inputsWrapper}>
            {heading && (
                <Typography component="h2" variant="h6">
                    {heading}
                </Typography>
            )}
            {!isActive && (
                <div className={style.disabledWrapper}>
                    <ReactivateButton onClick={activateUser} />
                </div>
            )}
            {isOaUserMgmt && teams && (
                <Autocomplete
                    className={style.formInput}
                    id="team-select"
                    options={teams}
                    getOptionLabel={(option) => option.Name}
                    onChange={(_: React.ChangeEvent<{}>, team: ITeam | null) =>
                        setUser({...details, TeamId: team?.Id} as IUser)
                    }
                    value={currentTeam}
                    disableClearable={true}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select Team" />}
                />
            )}

            {agencies && (
                <Autocomplete
                    className={style.formInput}
                    id="agency-select"
                    options={agencies}
                    getOptionLabel={(option) => option.Name}
                    onChange={(_: React.ChangeEvent<{}>, agency: IAgency | null) =>
                        setAgency && agency && setAgency(agency)
                    }
                    disableClearable={true}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select Agency" />}
                    disabled={shouldDisableField('agency')}
                />
            )}

            <InputField
                className={style.formInput}
                htmlFor={`name-input-${id}`}
                value={name}
                onChange={(value) => setUser({...details, Name: value} as IUser)}
                label="Name"
                isDisable={shouldDisableField('name')}
                validations={[required('name'), preventNRICInsertion]}
                customErrorMessage={
                    customErrorMessage('name') || checkMaxLength(name, 'Name', DEFAULT_SHORT_FIELD_MAX_LENGTH)
                }
            />
            <InputField
                className={style.formInput}
                htmlFor={`division-input-${id}`}
                value={division}
                onChange={(value) => setUser({...details, Division: value} as IUser)}
                label="Division"
                isDisable={shouldDisableField('division')}
                validations={[required('division'), preventNRICInsertion]}
                customErrorMessage={
                    customErrorMessage('division') ||
                    checkMaxLength(division, 'Division', DEFAULT_SHORT_FIELD_MAX_LENGTH)
                }
            />
            <InputField
                className={style.formInput}
                htmlFor={`designation-input-${id}`}
                value={designation}
                onChange={(value) => setUser({...details, Designation: value} as IUser)}
                label="Designation"
                isDisable={shouldDisableField('designation')}
                validations={[required('designation'), preventNRICInsertion]}
                customErrorMessage={
                    customErrorMessage('designation') ||
                    checkMaxLength(designation, 'Designation', DEFAULT_SHORT_FIELD_MAX_LENGTH)
                }
            />
            <InputField
                className={style.formInput}
                htmlFor={`email-input-${id}`}
                value={email}
                onChange={(value) => setUser({...details, Email: value} as IUser)}
                label="Email"
                isDisable={shouldDisableField('email')}
                validations={[required('email'), emailFormat]}
                customErrorMessage={
                    checkMaxLength(email, 'Email', DEFAULT_SHORT_FIELD_MAX_LENGTH) || customErrorMessage('email')
                }
                disabledReason={
                    shouldDisableField('email')
                        ? 'This field has been disabled for this particular user type'
                        : 'This field has been disabled'
                }
            />
            <InputField
                className={style.formInput}
                htmlFor={`office-number-input-${id}`}
                value={officeNumber}
                onChange={(value) => setUser({...details, OfficeNumber: value.replace(/[^0-9]+/g, '')} as IUser)}
                label="Office Number"
                validations={[fixedLength(8)]}
                isDisable={shouldDisableField('officeNumber')}
                customErrorMessage={customErrorMessage('officeNumber')}
                maxLength={8}
            />
            <InputField
                className={style.formInput}
                htmlFor={`mobile-number-input-${id}`}
                value={mobileNumber}
                onChange={(value) => setUser({...details, MobileNumber: value.replace(/[^0-9]+/g, '')} as IUser)}
                label="Mobile Number (Optional)"
                validations={[singaporeMobileNumber, fixedLength(8)]}
                isDisable={shouldDisableField('mobileNumber')}
                customErrorMessage={customErrorMessage('mobileNumber')}
                maxLength={8}
            />
            {userType !== USER_TYPE.intranet && (
                // This will be default open when creating an user because userType is default ''
                <div className={style.userNameWrapper}>
                    <Typography component="h2" variant="subtitle2">
                        For non-government entity user only:
                    </Typography>
                    <InputField
                        className={style.formInput}
                        htmlFor={`corpPass-Id-input-${id}`}
                        value={formatUserName(userName)}
                        onChange={(value) => setUser({...details, UserName: value} as IUser)}
                        label="NRIC/FIN"
                        validations={[singaporeNric]}
                        isDisable={shouldDisableField('userName')}
                        customErrorMessage={customErrorMessage('userName')}
                    />
                    <StyledTooltip
                        title="NRIC/FIN is required if the user is from a non-government entity."
                        placement="bottom-start"
                    >
                        <InfoIcon className={style.userNameToolTip} />
                    </StyledTooltip>
                </div>
            )}
        </div>
    );
};
