import React from 'react';
import {FamilyMembers, IFamilyMembersData} from './FamilyMembers/FamilyMembers';
import {WelfareHomes, IWelfareHomeData} from './WelfareHomes/WelfareHomes';
import {MaritalInfo, IMaritalInfoData} from './MaritalInfo/MaritalInfo';
import {BasicDemographics, IBasicDemographics} from './BasicDemographics/BasicDemographics';
import {
    MandatoryCounsellingProgramme,
    IMandatoryCounsellingProgrammeData,
} from './MandatoryCounsellingProgramme/MandatoryCounsellingProgramme';
import {ComcareAssistance} from './ComcareAssistance/ComcareAssistance';
import {IncarcerationRecord, IIncarcerationRecordData} from './IncarcerationRecord/IncarcerationRecord';
import {Income, IIncomeData} from '~/components/Client/SubDomainDisplay/Income/Income';
import {
    RehabAndProtection,
    IRehabAndProtectionData,
} from '~/components/Client/SubDomainDisplay/RehabAndProtection/RehabAndProtection';
import {FosterChildren, IFosterChildrenData} from '~/components/Client/SubDomainDisplay/FosterChildren/FosterChildren';
import {
    ITransitionalShelterData,
    TransitionalShelter,
} from '~/components/Client/SubDomainDisplay/TransitionalShelters/TransitionalShelters';
import {CPFAccountDetails, ICPFAccountDetails} from './CPFAccountDetails/CPFAccountDetails';
import {SilverSupportScheme, ISilverSupportSchemeData} from './SilverSupportScheme/SilverSupportScheme';
import {DependantsSupportScheme, IDependantsSupportScheme} from './DependantsSupportScheme/DependantsSupportScheme';
import {StatusObject} from './SubDomainDisplay';
import {IHomeOwnershipData, HomeOwnership} from './Housing/HomeOwnership/HomeOwnership';
import {IRentalHousingData, RentalHousing} from './Housing/RentalHousing/RentalHousing';
import {ILongTermVisitorPass, LongTermVisitorPass} from './LongTermVisitorPass/LongTermVisitorPass';
import {ISchoolingDetailsData, SchoolingRecords} from './SchoolingRecords/SchoolingRecords';
import {IPrivateDwellingData, PrivateDwelling} from './Housing/RentalHousing/PrivateDwelling';
import {IFamilyServiceCentreData, FamilyServiceCentre} from './FamilyServiceCentre/FamilyServiceCentre';
import {
    IMedicalExpensesAndAssistanceData,
    MedicalExpensesAndAssistance,
} from './MedicalExpensesAndAssistance/MedicalExpensesAndAssistance';
import {DSLSCases, IDSLSCasesData} from './DSLS/DSLSCases';
import {IMUISAssistanceData, MUISAssistance} from './MUIS Assistance/MUISAssistance';
import {IMindefNsfAssistanceData, MindefNsfAssistance} from './MindefNsfAssistance/MindefNsfAssistance';
import {ICDACAssistanceData, CDACAssistance} from './CdacAssistance/CdacAssistance';
import {CovidSchemes, ICovidSchemesData} from './CovidSchemes/CovidSchemes';
import {IComcareData} from './ComcareAssistance/interfaces';
import {FoundationSupport, IFoundationSupportData} from './FoundationSupport/FoundationSupport';
import {ICrisisShelterData, CrisisShelter} from './CrisisShelters/CrisisShelter';
import {IChildDevelopmentData, ChildDevelopment} from './ChildDevelopment/ChildDevelopment';
import {
    DivorceSupportSpecialistAgency,
    IDivorceSupportSpecialistAgencyData,
} from './DivorceSupportSpecialistAgency/DivorceSupportSpecialistAgency';
import {EarlyRiskMarriage, IEarlyRiskMarriageData} from './EarlyRiskMarriage/EarlyRiskMarriage';
import {ISCFAData, SCFA} from './SCFA/SCFA';
import {HopeScheme, IHopeSchemesData} from './HopeScheme/HopeScheme';
import {DrivingLicenses, IDrivingLicensesData} from './DrivingLicenses/DrivingLicenses';
import {IVehicleOwnershipsData, VehicleOwnerships} from './VehicleOwnerships/VehicleOwnerships';
import {BusinessOwnerships, IBusinessOwnershipData} from './BusinessOwnerships/BusinessOwnerships';
import {WsgCases, IWSG} from './WSG/Wsg';
import {
    IPerCapitaHouseholdIncomeData,
    PerCapitaHouseholdIncome,
} from './PerCapitaHouseholdIncome/PerCapitaHouseholdIncome';

import {ICHAS, CHAS} from '~/components/Client/SubDomainDisplay/CHAS/CHAS';
import {CareshieldEldershield, ICareshieldEldershield} from './CareshieldEldershield/CareshieldEldershield';

export interface ISubDomainDataComponents {
    FAMILY: React.FC<{data: IFamilyMembersData}>;
    WELFARE: React.FC<{data: IWelfareHomeData}>;
    MARINFO: React.FC<{data: IMaritalInfoData}>;
    DEMO: React.FC<{data: IBasicDemographics; status: StatusObject; errorMessages?: string[]}>;
    MCP: React.FC<{data: IMandatoryCounsellingProgrammeData}>;
    COVID: React.FC<{data: ICovidSchemesData}>;
    COMCARE: React.FC<{data: IComcareData}>;
    FOUNDATION: React.FC<{data: IFoundationSupportData}>;
    HOPE: React.FC<{data: IHopeSchemesData}>;
    INCARCERATION: React.FC<{data: IIncarcerationRecordData}>;
    INCOME: React.FC<{data: IIncomeData}>;
    TS: React.FC<{data: ITransitionalShelterData}>;
    FSC: React.FC<{data: IFamilyServiceCentreData}>;
    CS: React.FC<{data: ICrisisShelterData}>;
    CD: React.FC<{data: IChildDevelopmentData}>;
    DSSA: React.FC<{data: IDivorceSupportSpecialistAgencyData}>;
    ERM: React.FC<{data: IEarlyRiskMarriageData}>;
    REHAB: React.FC<{data: IRehabAndProtectionData}>;
    FOSTERCHILDREN: React.FC<{data: IFosterChildrenData}>;
    CPF: React.FC<{data: ICPFAccountDetails; status: StatusObject}>;
    SILVERSUPPORTSCHEME: React.FC<{data: ISilverSupportSchemeData; errorMessages?: string[]}>;
    DEPENDANTSPROTECTIONSCHEME: React.FC<{data: IDependantsSupportScheme}>;
    HOMEOWNERSHIP: React.FC<{data: IHomeOwnershipData; status: StatusObject}>;
    RENTALHOUSING: React.FC<{data: IRentalHousingData; status: StatusObject}>;
    LTVP: React.FC<{data: ILongTermVisitorPass}>;
    SCHOOLING: React.FC<{data: ISchoolingDetailsData; status: StatusObject}>;
    PRIVATEDWELLING: React.FC<{data: IPrivateDwellingData}>;
    MEDICALEXPENSES: React.FC<{data: IMedicalExpensesAndAssistanceData; status: StatusObject}>;
    DSLS: React.FC<{data: IDSLSCasesData}>;
    MUIS: React.FC<{data: IMUISAssistanceData}>;
    MINDEF: React.FC<{data: IMindefNsfAssistanceData}>;
    CDAC: React.FC<{data: ICDACAssistanceData}>;
    PCHI: React.FC<{data: IPerCapitaHouseholdIncomeData}>;
    SCFA: React.FC<{data: ISCFAData}>;
    DRIVINGLICENSE: React.FC<{data: IDrivingLicensesData}>;
    VEHICLEOWNERSHIP: React.FC<{data: IVehicleOwnershipsData}>;
    BUSINESSOWNERSHIP: React.FC<{data: IBusinessOwnershipData}>;
    WSG: React.FC<{data: IWSG[]}>;
    CHAS: React.FC<{data: ICHAS; status: StatusObject}>;
    CARESHIELDLIFEANDELDERSHIELDLIFE: React.FC<{data: ICareshieldEldershield; status: StatusObject}>;
}

export const subDomainDataMap: ISubDomainDataComponents = {
    FAMILY: FamilyMembers,
    WELFARE: WelfareHomes,
    MARINFO: MaritalInfo,
    DEMO: BasicDemographics,
    MCP: MandatoryCounsellingProgramme,
    COMCARE: ComcareAssistance,
    COVID: CovidSchemes,
    FOUNDATION: FoundationSupport,
    HOPE: HopeScheme,
    INCARCERATION: IncarcerationRecord,
    INCOME: Income,
    REHAB: RehabAndProtection,
    FOSTERCHILDREN: FosterChildren,
    TS: TransitionalShelter,
    FSC: FamilyServiceCentre,
    CS: CrisisShelter,
    CD: ChildDevelopment,
    DSSA: DivorceSupportSpecialistAgency,
    ERM: EarlyRiskMarriage,
    CPF: CPFAccountDetails,
    SILVERSUPPORTSCHEME: SilverSupportScheme,
    DEPENDANTSPROTECTIONSCHEME: DependantsSupportScheme,
    HOMEOWNERSHIP: HomeOwnership,
    RENTALHOUSING: RentalHousing,
    LTVP: LongTermVisitorPass,
    SCHOOLING: SchoolingRecords,
    PRIVATEDWELLING: PrivateDwelling,
    MEDICALEXPENSES: MedicalExpensesAndAssistance,
    DSLS: DSLSCases,
    MUIS: MUISAssistance,
    MINDEF: MindefNsfAssistance,
    CDAC: CDACAssistance,
    PCHI: PerCapitaHouseholdIncome,
    SCFA: SCFA,
    DRIVINGLICENSE: DrivingLicenses,
    VEHICLEOWNERSHIP: VehicleOwnerships,
    BUSINESSOWNERSHIP: BusinessOwnerships,
    WSG: WsgCases,
    CHAS: CHAS,
    CARESHIELDLIFEANDELDERSHIELDLIFE: CareshieldEldershield,
};

// Union of every subdomain data interface
export type ISubDomainData =
    | IFamilyMembersData
    | IWelfareHomeData
    | IMaritalInfoData
    | IMandatoryCounsellingProgrammeData
    | IBasicDemographics
    | IComcareData
    | IMUISAssistanceData
    | IIncarcerationRecordData
    | IIncomeData
    | IRehabAndProtectionData
    | IFosterChildrenData
    | ITransitionalShelterData
    | IFamilyServiceCentreData
    | ICrisisShelterData
    | IChildDevelopmentData
    | IDivorceSupportSpecialistAgencyData
    | IEarlyRiskMarriageData
    | ICPFAccountDetails
    | ISilverSupportSchemeData
    | IDependantsSupportScheme
    | IHomeOwnershipData
    | IRentalHousingData
    | ILongTermVisitorPass
    | ISchoolingDetailsData
    | IPrivateDwellingData
    | IMedicalExpensesAndAssistanceData
    | IDSLSCasesData
    | IMindefNsfAssistanceData
    | ICDACAssistanceData
    | IPerCapitaHouseholdIncomeData
    | ISCFAData
    | IDrivingLicensesData
    | IVehicleOwnershipsData
    | IBusinessOwnershipData
    | ICHAS
    | null;
