import React, {useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {xor} from 'lodash';
import {IReleaseNote} from '~/interfaces/common';
import {useHistory} from 'react-router-dom';
import {useAppDispatch} from '~/hooks/useAppDispatch';

import {
    WhatsNewIcon,
    WhatsImprovedIcon,
    WhatsFixedIcon,
    WhatsRemovedIcon,
    WhatsChangedIcon,
} from '~/components/Common/Icons';
import {ReleaseNoteDetail} from './ReleaseNoteDetail';
import {ExpandAllButton} from '~/components/Common/Button/ExpandAllButton';
import {EditButton} from '../Button/EditButton';
import {DeleteButton} from '../Button/DeleteButton';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {deleteReleaseNote} from '~/store/releaseNotes/releaseNote.thunk';
import {formatDateMonthName} from '~/utils/dateUtils';
import {getReleaseNotes} from '~/store/releaseNotes/releaseNote.thunk';

import './ReleaseNoteItem.scss';

export interface IReleaseNoteItem {
    releaseNote: IReleaseNote;
    onExpandAll: boolean;
    isLoggedInAsAdmin: boolean;
    setGrowl: (message: string) => void;
}

export const ReleaseNoteItem = ({releaseNote, onExpandAll, isLoggedInAsAdmin, setGrowl}: IReleaseNoteItem) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [releaseNoteToBeDeleted, setReleaseNoteToBeDeleted] = useState({releaseNoteId: 0, versionNumber: ''});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const updatedDate = releaseNote.UpdatedAt ? releaseNote.UpdatedAt : releaseNote.CreatedAt;

    const releaseNoteSections = Object.keys(releaseNote).filter((key) => key.includes('Whats'));

    const [expandList, setExpandList] = useState<string[]>(onExpandAll ? releaseNoteSections : []);
    const handleExpandAll = () =>
        setExpandList(expandList.length < releaseNoteSections.length ? releaseNoteSections : []);

    const handleOnExpand = (title: string) => setExpandList(xor(expandList, [title]));
    const handleEdit = (releaseNoteId: number) => {
        history.push(`/release-notes/edit/${releaseNoteId}`);
    };

    const handleDeleteReleaseNote = (releaseNoteId: number, versionNumber: string) => {
        setIsDeleteDialogOpen(true);
        setReleaseNoteToBeDeleted({releaseNoteId: releaseNoteId, versionNumber: versionNumber});
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const confirmDelete = async () => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteReleaseNote({releaseNoteId: Number(releaseNoteToBeDeleted.releaseNoteId)}),
        );
        if ('error' in thunkActionResult) {
            setGrowl(thunkActionResult.payload as string);
        } else {
            dispatch(getReleaseNotes());
            setGrowl(`Release note deleted successfully.`);
        }
        setIsDeleting(false);
        closeDeleteDialog();
    };

    return (
        <div styleName="releaseNoteItem">
            <Grid container>
                <Grid container direction="row" alignItems="flex-end">
                    <Typography variant="h4" color="primary" styleName="title">
                        OneCV {releaseNote.VersionNumber}
                    </Typography>
                    {isLoggedInAsAdmin && (
                        <>
                            <EditButton id="edit-button" onClick={() => handleEdit(releaseNote.Id)} />
                            <DeleteButton
                                id="delete-button"
                                onClick={() => handleDeleteReleaseNote(releaseNote.Id, releaseNote.VersionNumber)}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    <Typography styleName="releaseDate">
                        Released on {formatDateMonthName(releaseNote.ReleaseDate)}
                        {isLoggedInAsAdmin ? (
                            <>{` | Last updated on ${formatDateMonthName(updatedDate as string)} by ${
                                releaseNote.UpdatedByUser
                            }`}</>
                        ) : (
                            <>{` | Last updated on ${formatDateMonthName(updatedDate as string)}`}</>
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <ExpandAllButton
                        styleName="expandButton"
                        onClick={handleExpandAll}
                        expandAll={releaseNoteSections.length === expandList.length}
                    />
                </Grid>
            </Grid>

            {releaseNote.WhatsNew && (
                <ReleaseNoteDetail
                    releaseNoteSection={releaseNote.WhatsNew}
                    title={"What's New"}
                    expand={expandList.includes('WhatsNew')}
                    icon={<WhatsNewIcon height={24} width={24} />}
                    onExpand={() => handleOnExpand('WhatsNew')}
                />
            )}

            {releaseNote.WhatsImproved && (
                <ReleaseNoteDetail
                    releaseNoteSection={releaseNote.WhatsImproved}
                    title={"What's Improved"}
                    expand={expandList.includes('WhatsImproved')}
                    icon={<WhatsImprovedIcon height={24} width={24} />}
                    onExpand={() => handleOnExpand('WhatsImproved')}
                />
            )}

            {releaseNote.WhatsFixed && (
                <ReleaseNoteDetail
                    releaseNoteSection={releaseNote.WhatsFixed}
                    title={"What's Fixed"}
                    expand={expandList.includes('WhatsFixed')}
                    icon={<WhatsFixedIcon height={24} width={24} />}
                    onExpand={() => handleOnExpand('WhatsFixed')}
                />
            )}

            {releaseNote.WhatsRemoved && (
                <ReleaseNoteDetail
                    releaseNoteSection={releaseNote.WhatsRemoved}
                    title={"What's Removed"}
                    expand={expandList.includes('WhatsRemoved')}
                    icon={<WhatsRemovedIcon height={24} width={24} />}
                    onExpand={() => handleOnExpand('WhatsRemoved')}
                />
            )}

            {releaseNote.WhatsChanged && (
                <ReleaseNoteDetail
                    releaseNoteSection={releaseNote.WhatsChanged}
                    title={"What's Changed"}
                    expand={expandList.includes('WhatsChanged')}
                    icon={<WhatsChangedIcon height={24} width={24} />}
                    onExpand={() => handleOnExpand('WhatsChanged')}
                />
            )}

            <ConfirmDialog
                id="DeleteDialog"
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                open={isDeleteDialogOpen}
                title={`Delete 'OneCV ${releaseNoteToBeDeleted.versionNumber}'?`}
                message="This release note will be deleted for all users."
                cancelOption="KEEP RELEASE NOTE"
                confirmOption="DELETE"
                isLoading={isDeleting}
            />
        </div>
    );
};
