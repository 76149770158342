import {KeyboardArrowRight, SearchRounded as SearchIcon} from '@mui/icons-material';
import {Box, Grid, InputAdornment, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {uniq} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {API_FETCH_STATE, LOADING_MESSAGE} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IDomain} from '~/interfaces/common';
import {setDomains} from '~/store/domain/domains.thunk';
import {getDomainsFetchState} from '~/store/fetchState/fetchState.selector';
import {formatDateMonthName} from '~/utils/dateUtils';
import style from './DataFieldManagement.scss';

const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customCellStyle = (_domain: IDataFieldManagementSubdomainRow, index?: number | undefined) => {
    // 40% is the width of the first row and 20% is for the remaining columns
    const widths = ['40%', '20%'];
    if (index !== undefined) {
        return {width: widths[index]};
    }
    return {};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customerHeaderCellStyle = (__data: React.ReactNode) => ({
    backgroundColor: '#3949ab',
    color: '#ffffff',
    paddingLeft: '32px',
});

interface IDataFieldManagementSubdomainRow {
    Id: number;
    Name: string;
    LastUpdatedBy?: string;
    LastUpdatedAt?: string;
}

export const DataFieldManagement = () => {
    const dispatch = useAppDispatch();
    const domains: IDomain[] = useTypedSelector((state) => state.data.common.domains);

    //Declaring all the use states for API calls
    const domainsFetchStatus = useTypedSelector(getDomainsFetchState);

    //Delcaring combinedFetchStatus that tracks all the API calls collectively
    const combinedFetchStatus =
        domainsFetchStatus === API_FETCH_STATE.SUCCESS
            ? API_FETCH_STATE.SUCCESS
            : domainsFetchStatus === API_FETCH_STATE.ERROR
            ? API_FETCH_STATE.ERROR
            : API_FETCH_STATE.PENDING;

    useEffect(() => {
        dispatch(setDomains());
    }, []);

    // Main logic to render the Data Field Management page
    // Nested as a function in order to pass it as an argument to `WithAdminLoader`
    const getPageContent = () => {
        const [searchValue, setSearchValue] = useState('');
        const [filteredDomains, setFilteredDomains] = useState<IDomain[]>(domains);
        const [domainOptions, setDomainOptions] = useState<string[]>([]);
        const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
        const domainFilterClasses = useDomainFilterStyles();

        const history = useHistory();
        //On first refresh, get all the domains to be used for the filter component
        useEffect(() => {
            if (domains.length > 0) {
                if (domainOptions.length === 0) {
                    setDomainOptions(uniq(domains.map((row) => row.Name)));
                }
            }
        }, []);

        //helper useEffect for searchValue updating
        useEffect(() => {
            const newDomains = domains.filter((domain) => {
                return (
                    domain.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    domain.SubDomains.some((subdomain) => {
                        return subdomain.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
                    })
                );
            });
            setFilteredDomains(newDomains);
        }, [searchValue]);

        //helper functions for filtering
        const filterSelectedDomains = (selectedDomains: string[]): IDomain[] => {
            if (selectedDomains.length === 0) return domains;
            return domains.filter((domain) => {
                return selectedDomains.includes(domain.Name);
            });
        };

        const handleSelectDomain = (selectedDomains: string[]) => {
            setSelectedDomains(selectedDomains);
            setFilteredDomains(filterSelectedDomains(selectedDomains));
        };

        const handleClearDomains = () => {
            handleSelectDomain([]);
        };

        const handleSelectAllDomains = (isSelectAll: boolean) => {
            if (isSelectAll) {
                handleSelectDomain(domainOptions);
            } else {
                handleClearDomains();
            }
        };

        // functions to get cell details for each columns
        const getRowName = (data: IDataFieldManagementSubdomainRow) => (
            <div className={style.row} data-testid={`subdomain-name-${data.Id}`}>
                {data.Name}
            </div>
        );

        const getLastUpdatedDate = (data: IDataFieldManagementSubdomainRow) => {
            return (
                <div className={style.row} data-testid={`last-updated-date-${data.Id}`}>
                    {data?.LastUpdatedAt !== undefined ? formatDateMonthName(data?.LastUpdatedAt) : '-'}
                </div>
            );
        };
        const getLastUpdatedBy = (data: IDataFieldManagementSubdomainRow) => {
            return (
                <div className={style.row} data-testid={`last-updated-by-${data.Id}`}>
                    {data?.LastUpdatedBy !== undefined ? data?.LastUpdatedBy : '-'}
                </div>
            );
        };

        const getSettingsPageLink = (data: IDataFieldManagementSubdomainRow) => {
            return (
                <div
                    data-testid={`settings-page-link-${data.Id}`}
                    className={style.settingsBtn}
                    onClick={() => history.push(`/admin/data-field-management/${data.Id}`)}
                >
                    SETTINGS <KeyboardArrowRight className={style.arrow} />
                </div>
            );
        };

        return (
            <Grid container alignItems="center" className={style.dataFieldManagement}>
                <Grid item xs={8}>
                    <Typography variant="h4" className={style.pageTitle}>
                        Data Field Management
                    </Typography>
                </Grid>

                <Grid xs={12} item className={style.searchContainer}>
                    <Grid item xs={6} className={style.searchItem}>
                        <TextField
                            size="small"
                            label="Search Domain or Subdomain"
                            type="search"
                            variant="outlined"
                            value={searchValue}
                            data-testid="search-input"
                            onChange={(e) => setSearchValue(e.target.value)}
                            className={style.searchInput}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={style.searchItem}>
                        <Box className={style.flushToRight}>
                            <span className={style.filterLabel}>Filter</span>
                            <MultiSelectAutocomplete
                                data-testid="filterSearch"
                                options={domainOptions}
                                selectedValues={selectedDomains}
                                label="Domain"
                                tagRenderer={(value) => (
                                    <Typography variant="subtitle1" className={style.filterTag}>
                                        {value.length} Domains Selected
                                    </Typography>
                                )}
                                onToggleOption={handleSelectDomain}
                                onClearOptions={handleClearDomains}
                                onSelectAll={handleSelectAllDomains}
                                classes={domainFilterClasses}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid xs={12} item>
                    {filteredDomains.map((domain, id) => {
                        const domainData: IDataFieldManagementSubdomainRow[] = domain.SubDomains.map((subDomain) => ({
                            Name: subDomain.Name,
                            Id: subDomain.Id,
                            LastUpdatedBy: subDomain.LastUpdatedBy,
                            LastUpdatedAt: subDomain.LastUpdatedAt,
                        }));

                        return (
                            <div key={id} data-testid={`domain-${domain.Id}`}>
                                <div className={style.titleContainer} data-testid="domain-table">
                                    <span className={style.title}>{domain.Name}</span>
                                </div>
                                <ListModel
                                    columns={[getRowName, getLastUpdatedDate, getLastUpdatedBy, getSettingsPageLink]}
                                    headers={['Subdomain', 'Last updated date', 'Last updated by', 'Manage']}
                                    customHeaderCellStyle={customerHeaderCellStyle}
                                    customCellStyle={customCellStyle}
                                    modelList={domainData}
                                    rowsPerPage={domainData.length}
                                    defaultSortOrder="asc"
                                    variant="expanded"
                                    sortable={false}
                                />
                            </div>
                        );
                    })}
                </Grid>
            </Grid>
        );
    };
    const DataFieldManagementContentWithLoader = WithAdminLoader<{}>(
        getPageContent,
        combinedFetchStatus,
        LOADING_MESSAGE.DATA,
    );

    return <DataFieldManagementContentWithLoader />;
};
