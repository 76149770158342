import React from 'react';
import {Typography, Grid, Box} from '@mui/material';
import {IAgency, IUser} from '~/interfaces/admin';
import {get, isEmpty} from 'lodash';
import {DataField} from '../../Common/DataField/DataField';
import style from './UserView.scss';
import {SuspendedIcon} from '../SuspendedIcon/SuspendedIcon';
import {USER_TYPE} from '~/constants';
import {AddButtonSecondary} from '~/components/Common/Button/AddButtonSecondary';
import {DeleteButton} from '~/components/Common/Button/DeleteButton';
import {EditButton} from '~/components/Common/Button/EditButton';
import {ReactivateButton} from '~/components/Common/Button/ReactivateButton';

interface IUserView {
    heading?: string;
    details?: IUser;
    userRole: string;
    isDisplayControls?: boolean;
    hideDeleteButton?: boolean;
    teamName?: string;
    agency?: IAgency;
    handleActivateUser?: (userId?: number) => void;
    handleEditUser?: () => void;
    handleDeleteUser?: (userId: number) => void;
    handleCreateUser?: () => void;
}

export const UserView = ({
    heading,
    details,
    userRole,
    isDisplayControls = true,
    hideDeleteButton = false,
    teamName,
    agency,
    handleActivateUser,
    handleEditUser,
    handleDeleteUser,
    handleCreateUser = () => {
        return;
    },
}: IUserView) => {
    const name = get(details, 'Name', '');
    const division = get(details, 'Division', '');
    const designation = get(details, 'Designation', '');
    const email = get(details, 'Email', '');
    const officeNumber = get(details, 'OfficeNumber', '');
    const mobileNumber = get(details, 'MobileNumber', '');
    const isActive = get(details, 'IsActive', true);
    const isNoUserDetails = isEmpty(details);
    const userId = get(details, 'Id', '');
    const userType = get(details, 'UserType', '');
    const partialNric = get(details, 'PartialNric', '');

    const id = heading?.replace(/\s/g, '-').toLowerCase(); // remove spaces and convert to lower case for valid id

    const activateUser = () => {
        handleActivateUser && handleActivateUser(Number(userId));
    };

    const deleteUser = () => {
        handleDeleteUser && handleDeleteUser(Number(userId));
    };

    const editUser = () => {
        handleEditUser && handleEditUser();
    };

    const formatUserName = (value: string) => {
        return `*****${value.toUpperCase()}`;
    };

    const ControlButtons = () => {
        if (isDisplayControls) {
            if (isNoUserDetails) {
                return (
                    <div className={style.buttonWrapper}>
                        <AddButtonSecondary
                            onClick={() => {
                                handleCreateUser();
                            }}
                            text={`Add ${userRole}`}
                            id={`add-button-${id}`}
                        />
                    </div>
                );
            } else if (!isActive) {
                if (hideDeleteButton) {
                    return (
                        <div className={style.buttonWrapper}>
                            <ReactivateButton onClick={activateUser} id={`reactivate-button-${id}`} />
                        </div>
                    );
                } else {
                    return (
                        <div className={style.buttonWrapper}>
                            <DeleteButton onClick={deleteUser} id={`delete-button-${id}`} />
                            <ReactivateButton onClick={activateUser} id={`reactivate-button-${id}`} />
                        </div>
                    );
                }
            } else if (hideDeleteButton) {
                return (
                    <div className={style.buttonWrapper}>
                        <EditButton onClick={editUser} id={`edit-button-${id}`} />
                    </div>
                );
            } else {
                return (
                    <div className={style.buttonWrapper}>
                        <EditButton onClick={editUser} id={`edit-button-${id}`} />
                        <DeleteButton onClick={deleteUser} id={`delete-button-${id}`} />
                    </div>
                );
            }
        } else {
            return <div />;
        }
    };

    return (
        <div className={style.userView}>
            <Grid container justifyContent="space-between" spacing={1} styleName="actionHeader">
                <Grid container item xs={8} spacing={5} alignItems="center">
                    {heading && (
                        <Grid item styleName="heading">
                            <Typography component="h2" variant="h6">
                                {heading}
                            </Typography>
                        </Grid>
                    )}
                    {!isActive && (
                        <Grid item>
                            <SuspendedIcon />
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <ControlButtons />
                </Grid>
            </Grid>
            {isNoUserDetails ? (
                <Typography variant="body1">No details added yet.</Typography>
            ) : (
                <Box component="div">
                    {teamName && (
                        <Grid container item direction="column" spacing={4}>
                            <Grid item>
                                <DataField label="Team Name" value={teamName} />
                            </Grid>
                        </Grid>
                    )}
                    {agency && (
                        <Grid container item direction="column" spacing={4}>
                            <Grid item>
                                <DataField label="Agency" value={agency.Name} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container direction="row" spacing={4}>
                        <Grid container item direction="column" alignItems="flex-start" xs={4} spacing={4}>
                            <Grid item>
                                <DataField label="Name" value={name} />
                            </Grid>
                            <Grid item>
                                <DataField label="Designation" value={designation} />
                            </Grid>
                            <Grid item>
                                <DataField label="Office Number" value={officeNumber || '-'} />
                            </Grid>
                            {userType == USER_TYPE.internet && partialNric && (
                                <Grid item>
                                    <DataField label="NRIC/FIN" value={formatUserName(partialNric)} />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container direction="column" item xs={4} spacing={4}>
                            <Grid item>
                                <DataField label="Division/Department" value={division} />
                            </Grid>
                            <Grid item>
                                <DataField label="Email" value={email} />
                            </Grid>
                            <Grid item>
                                <DataField label="Mobile Number" value={mobileNumber || '-'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
};
