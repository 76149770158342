import React, {useContext} from 'react';
import unauthorized from '~/assets/images/unauthorized.svg';
import {Redirect} from 'react-router';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE} from '~/constants';

import './Unauthorized.scss';

export const Unauthorized = () => {
    const {loginState} = useContext(AuthenticationContext);

    if (loginState === LOGIN_STATE.LOGGED_IN) {
        return <Redirect to={`/`} />;
    }
    return (
        <div styleName="unauthorized-page">
            <img src={unauthorized} alt="No account" styleName="unauthorized-svg" />
            <h1 styleName="unauthorized-header">No account found</h1>
            <p styleName="unauthorized-text">
                It seems like your account has not been created.{'\n'}Please contact your administrator.
            </p>
        </div>
    );
};
