import React, {useState} from 'react';
import {
    Typography,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Collapse,
    IconButton,
} from '@mui/material';
import {format} from 'date-fns';
import {startCase, orderBy, capitalize} from 'lodash';

import {IRequestWindowLog} from '~/interfaces/admin';
import {KeyboardArrowUp, KeyboardArrowDown} from '@mui/icons-material';

import '../Admin.scss';
import './DataAccessRequestHistory.scss';

interface IRequestWindowLogProps {
    windowLogs: IRequestWindowLog[];
}
export const RequestWindowLog = ({windowLogs}: IRequestWindowLogProps) => {
    const WindowLog = ({Period, Status, SubdomainRequestSummaries}: IRequestWindowLog) => {
        const [open, setOpen] = useState(false);

        const filteredSubdomainRequestSummaries = SubdomainRequestSummaries?.filter(
            (sum) => (sum.State || '').toLowerCase() !== 'drafted',
        );

        const sortedSubdomainRequestSummaries = orderBy(filteredSubdomainRequestSummaries || [], ['ActionOn'], ['asc']);

        const subdomainRequestRows = sortedSubdomainRequestSummaries?.map((summary, index) => {
            const remarks = [];
            let user = summary.ActionBy?.User || '';
            const requestDateTime = format(new Date(summary.ActionOn), 'dd MMM yyyy h:mmaa');
            const requestDateArray = requestDateTime.split(' ');
            const requestDate = requestDateArray?.length
                ? `${requestDateArray[0]} ${requestDateArray[1]} ${requestDateArray[2]}`
                : '';
            const requestTime = requestDateArray?.length ? requestDateArray[3] : '';
            const requestStatus = summary.State?.toLocaleLowerCase();
            switch (requestStatus) {
                case 'submitted':
                    const reasonUpdated = 'Updated ‘Reason(s) for accessing subdomain’';
                    const requestSubmitted = `Submitted request for ${summary?.Actions?.FieldsAdded} data field${
                        summary?.Actions?.FieldsAdded > 1 ? 's' : ''
                    }.`;
                    const removedAccess = `Removed access to ${summary?.Actions?.FieldsRemoved} data field${
                        summary?.Actions?.FieldsRemoved > 1 ? 's' : ''
                    }.`;
                    if (summary?.Actions?.ReasonUpdated) remarks.push(reasonUpdated);
                    if (summary?.Actions?.FieldsAdded) remarks.push(requestSubmitted);
                    if (summary?.Actions?.FieldsRemoved) remarks.push(removedAccess);
                    break;
                case 'cancelled':
                case 'withdrawn':
                    const discarded = 'All changes discarded.';
                    remarks.push(discarded);
                    break;
                case 'sent for review':
                    const notice =
                        'Any removal of access to data field is effective immediately. Any updates to your data request will be sent via email.';
                    remarks.push(notice);
                    user = 'System-Generated';
                    break;
            }
            return (
                <TableRow styleName="beigeTableRow" key={index}>
                    <TableCell style={{width: '7%'}}></TableCell>
                    <TableCell style={{width: '18%'}}>
                        <Typography component="span" styleName="tableCellFont">
                            {capitalize(requestStatus || '')}
                        </Typography>
                    </TableCell>
                    <TableCell style={{width: '20%'}}>
                        <Typography component="span" styleName="tableCellFont">
                            {requestDate}
                        </Typography>
                        <br />
                        <Typography component="div" styleName="timeField">
                            {requestTime}
                        </Typography>
                    </TableCell>
                    <TableCell style={{width: '20%'}}>
                        <Typography component="span" styleName="tableCellFont">
                            {user}
                        </Typography>
                        <br />
                        <Typography component="div" styleName="roleField">
                            {summary.ActionBy?.Role}
                        </Typography>
                    </TableCell>
                    <TableCell style={{width: '35%'}}>
                        <Typography
                            component="span"
                            styleName="tableCellFont"
                            dangerouslySetInnerHTML={{__html: remarks.join('<br>')}}
                        />
                    </TableCell>
                </TableRow>
            );
        });

        return (
            <>
                <TableRow>
                    <TableCell style={{width: '7%'}}>
                        {filteredSubdomainRequestSummaries?.length > 0 && (
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell
                        style={{width: '18%', verticalAlign: 'middle!important'}}
                        styleName="middleAlign"
                        component="th"
                        scope="row"
                    >
                        <Typography
                            component="span"
                            styleName="tableCellFont"
                            style={{color: Status.toLowerCase() === 'open' ? '#007B4F' : ''}}
                        >
                            {startCase(Status || '')}
                        </Typography>
                    </TableCell>
                    <TableCell style={{width: '75%', verticalAlign: 'middle!important'}} styleName="middleAlign">
                        <Typography component="span" styleName="tableCellFont">
                            {format(new Date(Period.Start), 'dd MMM yyyy')} to{' '}
                            {format(new Date(Period.End), 'dd MMM yyyy')}
                        </Typography>
                    </TableCell>
                </TableRow>
                {filteredSubdomainRequestSummaries?.length > 0 && (
                    <TableRow>
                        <TableCell style={{padding: 0, borderBottom: 'none'}} colSpan={3}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Table>
                                    <TableBody>
                                        <TableRow styleName="subTableHeaderRow">
                                            <TableCell style={{width: '7%'}}></TableCell>
                                            <TableCell style={{width: '18%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    Status
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '20%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    Date
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '20%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    Action by
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{width: '35%'}}>
                                                <Typography component="span" styleName="tableCellFont">
                                                    Remarks
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        {subdomainRequestRows}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    };

    const sortByStatus = (log: IRequestWindowLog) => {
        if (log.Status === 'open') return 1;
        if (log.Status === 'closed') return 2;
        if (log.Status === 'cancelled') return 3;
    };

    const sortedWindowLogs = orderBy(
        windowLogs || [],
        ['Period.Start', 'Period.End', (log) => sortByStatus(log)],
        ['desc', 'desc', 'asc'],
    );

    const requestWindowLogs = sortedWindowLogs?.map((windowLog, index) => {
        return (
            <WindowLog
                key={index}
                Period={windowLog.Period}
                Status={windowLog.Status}
                SubdomainRequestSummaries={windowLog.SubdomainRequestSummaries}
            />
        );
    });

    return (
        <>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow styleName="tableHeaderRow">
                                <TableCell style={{width: '7%'}} styleName="tableHeader"></TableCell>
                                <TableCell style={{width: '18%'}} styleName="tableHeader">
                                    Request Window
                                </TableCell>
                                <TableCell style={{width: '75%'}} styleName="tableHeader">
                                    Dates
                                </TableCell>
                            </TableRow>
                            {requestWindowLogs}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};
