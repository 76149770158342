import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import * as dataContributorServices from '~/services/dataContributorServices';
import {IDataContributor, IUser} from '~/interfaces/admin';
import * as userServices from '~/services/userServices';

export interface ICreateDataContributor {
    agencyId: number;
    user: IUser;
}

export const setDataContributors = createAsyncThunk<IDataContributor[] | void, void, {state: RootState}>(
    '/dataContributor/setDataContributors',
    async (_, {getState, dispatch}) => {
        if (!getState().data.flags[Flag.DATA_CONTRIBUTORS]) {
            dispatch({type: fetchStateActions.SET_DATACONTRIBUTORS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const dataContributors = await dataContributorServices.getDataContributors();
                dispatch({
                    type: fetchStateActions.SET_DATACONTRIBUTORS_FETCH_STATE.type,
                    payload: API_FETCH_STATE.SUCCESS,
                });
                dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.DATA_CONTRIBUTORS});
                return dataContributors;
            } catch (err) {
                dispatch({
                    type: fetchStateActions.SET_DATACONTRIBUTORS_FETCH_STATE.type,
                    payload: API_FETCH_STATE.ERROR,
                });
                throw err;
            }
        }
    },
);

export const createDataContributor = createAsyncThunk<
    IDataContributor,
    {agencyId: number; user: IUser},
    {state: RootState}
>('/dataContributor/createDataContributor', async ({agencyId, user}, {rejectWithValue}) => {
    try {
        const resp = await dataContributorServices.createDataContributor(agencyId, user);
        return resp;
    } catch (err) {
        return rejectWithValue((err as Error).message);
    }
});

export const updateDataContributor = createAsyncThunk<
    IDataContributor,
    {updatedDataContributor: IDataContributor},
    {state: RootState}
>('/dataContributor/updateDataContributor', async ({updatedDataContributor}, {rejectWithValue}) => {
    try {
        const userResponse = await userServices.updateUser(updatedDataContributor.User);
        if (userResponse.Error) {
            return rejectWithValue(userResponse.Error);
        }
        return updatedDataContributor;
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const deleteDataContributor = createAsyncThunk<
    IDataContributor,
    {dataContributor: IDataContributor; deletedReason: string},
    {state: RootState}
>('/dataContributor/deleteDataContributor', async ({dataContributor, deletedReason}, {rejectWithValue}) => {
    try {
        await dataContributorServices.deleteDataContributor(
            dataContributor.Agency.Id,
            dataContributor.User.Id as number,
            deletedReason,
        );
        return dataContributor;
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const activateDataContributor = createAsyncThunk<
    IDataContributor,
    {dataContributor: IDataContributor},
    {state: RootState}
>('/dataContributor/activateDataContributor', async ({dataContributor}, {rejectWithValue}) => {
    try {
        await userServices.activateUser(dataContributor.User.Id as number);
        return dataContributor;
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});
