import {AuthenticationContext} from './authenticationContext';
import React, {ReactElement, ReactNode, useState, useEffect} from 'react';
import {ACCESS_LEVELS, USER_TYPE, LOGIN_STATE} from '~/constants';
import {jwtDecode} from '~/utils/contexts/authentication/jwtDecode';
import {Persistor} from 'redux-persist';
import {useHistory} from 'react-router-dom';

import {logout} from '~/store/logout.slice';
import {useAppDispatch} from '~/hooks/useAppDispatch';
export interface IAccessToken {
    UserName: string;
    UserType: USER_TYPE;
    Permissions: {
        AgencyId?: number;
        OpsUnitId?: number;
        TeamId?: number;
        UserId?: number;
        AccessLevel: ACCESS_LEVELS;
        SubDomains: number[] | null;
        DataUploadAllowedFor: string[];
        CanScreenClient: boolean;
    };
    AdditionalUserInfo: {
        Name: string;
        Agency?: string;
        OpsUnit?: string;
        Team?: string;
        Designation?: string;
        OpsUnitType?: string;
    };
    AgreedToTermsAndConditions: boolean;
    AcknowledgedToAdvisoryNote: boolean;
    exp: number;
}

export interface IAuthenticationContextProps {
    accessToken: IAccessToken | null;
    loginState: LOGIN_STATE | null;
    acknowledgedToAdvisoryNote: boolean | null;
    setAccessToken: (data?: string) => void;
    updateLoginState: (data: LOGIN_STATE) => void;
    updateAcknowledgeToAdvisory: (data: boolean) => void;
    persistor?: Persistor;
}

interface IProps {
    persistor?: Persistor;
    children: ReactNode;
}

export const AuthenticationProvider = ({persistor, children}: IProps): ReactElement => {
    const prevAccessToken = window.localStorage.getItem('token');
    const prevLoginState =
        !!prevAccessToken && jwtDecode(prevAccessToken).exp * 1000 >= Date.now() ? LOGIN_STATE.LOGGED_IN : null;
    const prevAcknowledgedState = !!prevAccessToken && jwtDecode(prevAccessToken).AcknowledgedToAdvisoryNote;

    const [accessToken, setContextAccessToken] = useState<string | null>(prevAccessToken);
    const [loginState, setLoginState] = useState<LOGIN_STATE | null>(prevLoginState);
    const [acknowledgedToAdvisoryNote, setAcknowledgedToAdvisoryNote] = useState<boolean | null>(prevAcknowledgedState);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const setAccessToken = async (token?: string) => {
        if (!token) {
            if (persistor) {
                persistor.pause();
                await persistor.flush();
                await persistor.purge();
            }
            localStorage.removeItem('token');
        } else {
            if (persistor) {
                persistor.persist();
            }
            localStorage.setItem('token', token);
            setAcknowledgedToAdvisoryNote(jwtDecode(token).AcknowledgedToAdvisoryNote);
        }

        setContextAccessToken(token || null);
    };

    const updateAcknowledgeToAdvisory = (acknowledge: boolean) => {
        setAcknowledgedToAdvisoryNote(acknowledge);
    };

    const updateLoginState = (state: LOGIN_STATE) => {
        if (state && state != LOGIN_STATE.LOGGED_IN) {
            setAccessToken();
            dispatch(logout());
            history.push('/logout');
        }
        setLoginState(state);
    };

    useEffect(() => {
        if (accessToken) {
            const timeToExpiry = jwtDecode(accessToken).exp * 1000 - Date.now();
            const logoutTimeout = setTimeout(
                () => updateLoginState(LOGIN_STATE.LOGGED_OUT_EXPIRED),
                Math.max(timeToExpiry, 0),
            );
            return () => clearTimeout(logoutTimeout);
        }
    }, [accessToken]);

    const authenticationContextValue: IAuthenticationContextProps = {
        accessToken: !!accessToken && jwtDecode(accessToken),
        loginState,
        acknowledgedToAdvisoryNote,
        setAccessToken,
        updateLoginState,
        updateAcknowledgeToAdvisory,
        persistor,
    };

    return (
        <AuthenticationContext.Provider value={authenticationContextValue}>{children}</AuthenticationContext.Provider>
    );
};
