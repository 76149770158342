import {IDataAccessMatrixDomain} from '~/interfaces/admin';
import {IAppState} from '~/store';

export const getAdmin = (state: IAppState) => state.data.admin;

export const getDomains = (state: IAppState) => state.data.dam.dictionary;

export const getDomainName = (domainIndex: number) => (state: IAppState) =>
    state.data.dam.dictionary.filter((data: IDataAccessMatrixDomain) => data.Id == domainIndex)[0]?.Name || '';

export const getDomain = (domainIndex: number) => (state: IAppState) =>
    state.data.dam.dictionary.filter((data: IDataAccessMatrixDomain) => data.Id == domainIndex)[0]?.SubDomains || [];

export const getSubDomainDataFields = (domainIndex: number, subDomainIndex: number) => (state: IAppState) => {
    const subDomains = state.data.dam.dictionary[domainIndex].SubDomains;

    const subDomain = subDomains.find((sb) => sb.Id === subDomainIndex);

    return subDomain?.DataFields || [];
};

export const getSelectedFieldIds = (opsUnitId: number) => (state: IAppState) => {
    const opsUnitFields = state.data.dam.opsUnits[opsUnitId];

    const ids = [];

    for (const domain in opsUnitFields) {
        for (const subDomain in opsUnitFields[domain]) {
            for (const field in opsUnitFields[domain][subDomain]) {
                if (opsUnitFields[domain][subDomain][field]) {
                    ids.push(Number(field));
                }
            }
        }
    }

    return ids;
};

export const getFieldState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldId: number) => (state: IAppState) =>
        state.data.dam.opsUnits[opsUnitId][domainId][subDomainId][fieldId];

export const getGroupedFieldsCheckedState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldIds: number[]) => (state: IAppState) => {
        const selectedFields = [];

        fieldIds.forEach((id) => {
            if (state.data.dam.opsUnits[opsUnitId][domainId][subDomainId][id]) {
                selectedFields.push(id);
            }
        });

        return selectedFields.length === fieldIds.length && selectedFields.length > 0;
    };

export const getGroupedFieldsIndeterminateState =
    (opsUnitId: number, domainId: number, subDomainId: number, fieldIds: number[]) => (state: IAppState) => {
        const selectedFields = [];

        fieldIds.forEach((id) => {
            if (state.data.dam.opsUnits[opsUnitId][domainId][subDomainId][id]) {
                selectedFields.push(id);
            }
        });

        return selectedFields.length < fieldIds.length && selectedFields.length > 0;
    };

const getFlattenFields = (domains: {[key: number]: {[key: number]: boolean}}): {[key: number]: boolean} => {
    let flattenFields = {};

    for (const key in domains) {
        flattenFields = {...flattenFields, ...domains[key]};
    }
    return flattenFields;
};

export const getDomainMatrixCheckedState = (opsUnitId: number, domainId: number) => (state: IAppState) => {
    const flattenFields = getFlattenFields(state.data.dam.opsUnits[opsUnitId][domainId]);

    return !Object.values(flattenFields).includes(false);
};

export const getDomainMatrixisIndeterminateState = (opsUnitId: number, domainId: number) => (state: IAppState) => {
    const flattenFields = getFlattenFields(state.data.dam.opsUnits[opsUnitId][domainId]);

    return Object.values(flattenFields).includes(true) && Object.values(flattenFields).includes(false);
};

export const getSubDomainMatrixCheckedState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const fields = state.data.dam.opsUnits[opsUnitId][domainId][subDomainId];

        return !Object.values(fields).includes(false);
    };

export const getSubDomainMatrixIndeterminateState =
    (opsUnitId: number, domainId: number, subDomainId: number) => (state: IAppState) => {
        const fields = state.data.dam.opsUnits[opsUnitId][domainId][subDomainId];

        return Object.values(fields).includes(true) && Object.values(fields).includes(false);
    };
