import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import * as service from '~/services/dataDictionaryServices';
import {IDataAccessMatrix, IDataAccessMatrixField} from '~/interfaces/admin';
import {config} from '~/config';

export const setDataAccessMatrix = createAsyncThunk<IDataAccessMatrix | void, void, {state: RootState}>(
    '/dataAccessMatrix/setDataAccessMatrix',
    async (_, {getState, dispatch}) => {
        if (!getState().data.flags[Flag.DAM_FIELDS]) {
            dispatch({type: fetchStateActions.SET_DAM_FIELDS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const damMatrix = await service.getDataFields();
                if (config.featureFlags.ENABLE_DAM2) {
                    const subdomainsWithNewFields = await service.getSubdomainsWithNewFields();
                    damMatrix.forEach((dm) => {
                        dm.SubDomains.forEach((sd) => {
                            sd.HasNewFields = subdomainsWithNewFields.includes(sd.Id);
                        });
                    });
                } else {
                    damMatrix.forEach((dm) => {
                        dm.SubDomains.forEach((sd) => {
                            sd.HasNewFields = false;
                        });
                    });
                }
                dispatch(fetchStateActions.SET_DAM_FIELDS_FETCH_STATE(API_FETCH_STATE.SUCCESS));
                dispatch(hydrateFlagActions.UPDATE_FLAG(Flag.DAM_FIELDS));
                return damMatrix;
            } catch (err) {
                dispatch(fetchStateActions.SET_DAM_FIELDS_FETCH_STATE(API_FETCH_STATE.ERROR));
                throw err;
            }
        }
    },
);

export const updateDataField = createAsyncThunk<IDataAccessMatrixField, IDataAccessMatrixField, {state: RootState}>(
    '/dataAccessMatrix/updateDataField',
    async (dataField, {rejectWithValue}) => {
        try {
            const res = await service.updateDataField(dataField);
            return res;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);
