import React from 'react';
import {Button} from '@mui/material';
import {ExpandAllIcon, CollapseAllIcon} from '~/components/Common/Icons';

interface IExpandAllButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    expandAll: boolean;
    id?: string;
    className?: string;
    styleName?: string;
}

export const ExpandAllButton = ({onClick, expandAll, id, className, styleName}: IExpandAllButtonProps) => {
    return (
        <Button
            size="small"
            variant="text"
            color="primary"
            onClick={onClick}
            data-testid={id}
            style={{
                float: 'right',
                marginTop: 30,
                fontSize: 12,
                height: 36,
            }}
            styleName={styleName}
            className={className}
        >
            {!expandAll ? (
                <>
                    <ExpandAllIcon />
                    <span style={{marginLeft: 7}}>Expand All Rows</span>
                </>
            ) : (
                <>
                    <CollapseAllIcon />
                    <span style={{marginLeft: 7}}>Collapse All Rows</span>
                </>
            )}
        </Button>
    );
};
