import {IAppState} from '~/store';

export const getDataContributorsFetchState = (state: IAppState) => state.data.fetch.dataContributors;
export const getParticipatingAgenciesFetchState = (state: IAppState) => state.data.fetch.participatingAgencies;
export const getSystemAdminFetchState = (state: IAppState) => state.data.fetch.systemAdmins;
export const getAgenciesFetchState = (state: IAppState) => state.data.fetch.agencies;
export const getAgencyFetchState = (state: IAppState) => state.data.fetch.agency;
export const getDomainsFetchState = (state: IAppState) => state.data.fetch.domains;
export const getDashboardFetchState = (state: IAppState) => state.data.fetch.dashboard;
export const getOpsUnitFetchState = (state: IAppState) => state.data.fetch.opsUnit;
export const getOpsUnitsFetchState = (state: IAppState) => state.data.fetch.opsUnits;
export const getScreenedClientInfoFetchState = (state: IAppState) => state.data.fetch.screenedClientInfo;
export const getTeamFetchState = (state: IAppState) => state.data.fetch.team;
export const getTeamsFetchState = (state: IAppState) => state.data.fetch.teams;
export const getUsersFetchState = (state: IAppState) => state.data.fetch.users;
export const getDamOpsUnitFetchState = (state: IAppState) => state.data.fetch.damOpsUnit;
export const getDamFieldsFetchState = (state: IAppState) => state.data.fetch.damFields;
export const getResourcesFetchState = (state: IAppState) => state.data.fetch.resources;
export const getReleaseNoteFetchState = (state: IAppState) => state.data.fetch.releaseNotes;
