import {Box, Button, Grid, Modal, Typography} from '@mui/material';
import React from 'react';
import './ModalWrapper.scss';

interface IModalWrapperProps {
    title: string;
    open: boolean;
    onClick: () => void;
    buttonLabel: string;
    children: React.ReactNode;
}

export const ModalWrapper = ({title, open, onClick, buttonLabel, children}: IModalWrapperProps) => {
    return (
        <Modal open={open} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box styleName="modalBody">
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h5" styleName="modalTitle">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>{children}</Grid>
                    <Grid item>
                        <Button
                            type="button"
                            styleName="modalConfirmButton"
                            variant="text"
                            data-testid="modalConfirmButton"
                            fullWidth={true}
                            onClick={onClick}
                        >
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};
