import React, {useContext} from 'react';
import {Container} from '@mui/material';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE, LOGOUT_MESSAGE} from '~/constants';
import {Redirect, useHistory} from 'react-router-dom';
import {ILogoutView, LogoutView} from '~/pages/Logout/LogoutView';

export const Logout = () => {
    const history = useHistory();
    const {loginState, updateLoginState} = useContext(AuthenticationContext);
    const singleSessionRedirect = history.location.search.split('?redirect=')[1] === 'true';
    singleSessionRedirect && updateLoginState(LOGIN_STATE.LOGGED_OUT_REDIRECT);

    const getLogoutMessage = (loginState: LOGIN_STATE | null) => {
        switch (loginState) {
            case LOGIN_STATE.LOGGED_OUT_INACTIVE:
                return LOGOUT_MESSAGE.INACTIVE;
            case LOGIN_STATE.LOGGED_OUT_EXPIRED:
                return LOGOUT_MESSAGE.INACTIVE;
            case LOGIN_STATE.LOGGED_OUT_MANUAL:
                return LOGOUT_MESSAGE.MANUAL;
            case LOGIN_STATE.LOGGED_OUT_REDIRECT:
                return LOGOUT_MESSAGE.REDIRECT;
            default:
                return LOGOUT_MESSAGE.MANUAL;
        }
    };

    const logoutViewProps: ILogoutView = {
        logoutMessage: getLogoutMessage(loginState),
    };

    return loginState === LOGIN_STATE.LOGGED_IN && !singleSessionRedirect ? (
        <Redirect to="/" />
    ) : (
        <Container fixed>
            <LogoutView {...logoutViewProps} />
        </Container>
    );
};
