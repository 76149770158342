/* eslint-disable react/display-name */
import {Grid} from '@mui/material';
import React from 'react';
import {allNoAccess, extractAllValues} from '~/utils/contentUtils';
import {StatusObject} from '../SubDomainDisplay';
import {SubSectionStatusWrapper} from '../SubSectionStatusWrapper';
import {AccountBalancesDetails, IAccountBalances} from './AccountBalancesDetails';
import {ContributionDetails as ContributionDetailsComponent, IContributionData} from './ContributionDetails';
import style from './CPFAccountDetails.scss';
import {HousingPIDetails, IHousingPIData} from './HousingDetails';
import {CPFISDetails, ICPFISOA, ICPFISSA} from './InvestmentDetails';
import {LatestHousingRefund} from './LatestHousingRefund';
import {
    IAgeFFWithdrawalAndHousingRefund,
    IPEADetail,
    IPledgeWithdrawalDetail,
    LumpsumWithdrawalDetails,
    RleWithdrawalDetail,
} from './LumpsumWithdrawalDetails';
import {
    IRspPayoutDetail,
    LifeSchemeDetail,
    MonthlyWithdrawalDetails,
    RlePayoutDetail,
} from './MonthlyWithdrawalDetails';
import {ITopupHistoryDetails, TopupHistoryDetails} from './TopupHistoryDetails';

enum SOURCES {
    CPFB = 'CPFB',
    SSNET = 'SSNET',
}

export interface ICPFAccountDetails {
    AccountBalance: IAccountBalances;
    ContributionHistory: IContributionData;
    HousingPI: IHousingPIData;
    CPFISOA: ICPFISOA;
    CPFISSA: ICPFISSA;
    RlePayout: RlePayoutDetail;
    RleWithdrawal: RleWithdrawalDetail;
    RspPayout: IRspPayoutDetail;
    AFFWithdrawalAndHousingRefund: IAgeFFWithdrawalAndHousingRefund;
    LifeScheme: LifeSchemeDetail;
    PledgeWithdrawal: IPledgeWithdrawalDetail;
    CPFPLWDetail: IPEADetail;
    TopupHistory: ITopupHistoryDetails;
}

export const CPFAccountDetails: React.FunctionComponent<{data: ICPFAccountDetails; status: StatusObject}> = ({
    data,
    status,
}) => {
    const {
        AccountBalance,
        ContributionHistory,
        HousingPI,
        CPFISOA,
        CPFISSA,
        RlePayout,
        RleWithdrawal,
        RspPayout,
        AFFWithdrawalAndHousingRefund,
        LifeScheme,
        PledgeWithdrawal,
        CPFPLWDetail,
        TopupHistory,
    } = data;

    const showAccountBalance =
        AccountBalance &&
        (AccountBalance.ErrorMessage || AccountBalance.Data) &&
        !allNoAccess(extractAllValues(AccountBalance.Data));

    const showContributionHistory =
        ContributionHistory &&
        (ContributionHistory.ErrorMessage || ContributionHistory.Data) &&
        !allNoAccess(extractAllValues(ContributionHistory.Data));

    const showTopupHistory = TopupHistory && !allNoAccess(extractAllValues([TopupHistory.Data.TransactionList]));
    const showHousingPI = !allNoAccess(
        extractAllValues([HousingPI.Data.PrincipalAmount, HousingPI.Data.AccruedInterest]),
    );
    const showLatestHousingRefund = !allNoAccess([
        HousingPI.Data.TotalRefundAmount,
        HousingPI.Data.RefundDate,
        HousingPI.Data.RefundAddress,
    ]);

    //Hide CPF Investment section if there are no access
    const showCPFIS = !allNoAccess(extractAllValues(CPFISOA?.Data)) || !allNoAccess(extractAllValues(CPFISSA?.Data));

    const showLumpsumWithdrawal = !allNoAccess(
        extractAllValues({
            RleWithdrawal: RleWithdrawal.Data,
            TotalExemptionAmount: RspPayout.Data.TotalExemptionAmount,
            age55WithdrawalAndHousingRefund: AFFWithdrawalAndHousingRefund.Data,
            pledgeWithdrawal: PledgeWithdrawal.Data,
            peaWithdrawal: CPFPLWDetail.Data,
        }),
    );

    const showMonthlyWithdrawal = !allNoAccess(
        extractAllValues({
            RlePayout: RlePayout.Data,
            LifeScheme: LifeScheme.Data,
            RspPayout: RspPayout.Data,
        }),
    );

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} className={style.containerGrid}>
                    {showAccountBalance && (
                        <SubSectionStatusWrapper name="Account Balances" status={status[SOURCES.CPFB]}>
                            <AccountBalancesDetails details={AccountBalance} />
                        </SubSectionStatusWrapper>
                    )}

                    {showContributionHistory && (
                        <SubSectionStatusWrapper
                            name="CPF Contribution Details for the last 15 months (excluding current month)"
                            status={status[SOURCES.CPFB]}
                        >
                            <ContributionDetailsComponent details={ContributionHistory} />
                        </SubSectionStatusWrapper>
                    )}

                    {showTopupHistory && (
                        <SubSectionStatusWrapper
                            name="Retirement Account / Special Account Transfers and Top-ups for last 15 months"
                            status={status[SOURCES.CPFB]}
                        >
                            <TopupHistoryDetails details={TopupHistory} />
                        </SubSectionStatusWrapper>
                    )}

                    {showHousingPI && (
                        <SubSectionStatusWrapper name="CPF Housing Payment Information" status={status[SOURCES.CPFB]}>
                            <HousingPIDetails details={HousingPI} />
                        </SubSectionStatusWrapper>
                    )}
                    {showLatestHousingRefund && (
                        <SubSectionStatusWrapper
                            name="Latest Housing Refund upon sale of flat"
                            status={status[SOURCES.CPFB]}
                        >
                            <LatestHousingRefund details={HousingPI} />
                        </SubSectionStatusWrapper>
                    )}

                    {showLumpsumWithdrawal && (
                        <SubSectionStatusWrapper
                            name="CPF Withdrawal for the last 12 months"
                            status={status[SOURCES.CPFB]}
                        >
                            <LumpsumWithdrawalDetails
                                apiDetails={{
                                    RleWithdrawal: RleWithdrawal,
                                    rspPayout: RspPayout,
                                    age55WithdrawalAndHousingRefund: AFFWithdrawalAndHousingRefund,
                                    pledgeWithdrawal: PledgeWithdrawal,
                                    peaWithdrawal: CPFPLWDetail,
                                }}
                            />
                        </SubSectionStatusWrapper>
                    )}

                    {showMonthlyWithdrawal && (
                        <SubSectionStatusWrapper name="CPF Schemes Payout" status={status[SOURCES.CPFB]}>
                            <MonthlyWithdrawalDetails
                                apiDetails={{
                                    RlePayout: RlePayout,
                                    LifeScheme: LifeScheme,
                                    RspPayout: RspPayout,
                                }}
                            />
                        </SubSectionStatusWrapper>
                    )}

                    {showCPFIS && (
                        <SubSectionStatusWrapper name="CPF Investment Details" status={status[SOURCES.CPFB]}>
                            <CPFISDetails cpfisOa={CPFISOA} cpfisSa={CPFISSA} />
                        </SubSectionStatusWrapper>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
