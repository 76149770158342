import React, {useState} from 'react';
import {Dialog, DialogActions, Grid, IconButton} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {ReportPasswordDialogForm} from './ReportPasswordDialogForm';

export interface IReportPasswordDialogProps {
    open: boolean;
    onSubmit: (password: string) => void;
    onClose: () => void;
    title?: string;
    description?: string;
}

export const ReportPasswordDialog = ({open, onSubmit, onClose, title, description}: IReportPasswordDialogProps) => {
    const [password, setPassword] = useState<string>('');

    const handleCloseModal = () => {
        setPassword('');
        onClose();
    };

    const handleDownloadReport = (): void => {
        onSubmit(password);
        handleCloseModal();
    };

    return (
        <Dialog open={open} aria-describedby="alert-dialog-description">
            <Grid container>
                <Grid item xs={11}></Grid>
                <Grid item xs={1}>
                    <DialogActions>
                        <IconButton aria-label="close" onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                </Grid>

                <Grid item xs={12}>
                    <ReportPasswordDialogForm
                        onSubmit={handleDownloadReport}
                        password={password}
                        onPasswordChange={(pw) => setPassword(pw)}
                        title={title}
                        description={description}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};
