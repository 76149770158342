import {createSlice} from '@reduxjs/toolkit';

export const logoutSlice = createSlice({
    name: 'logout',
    initialState: {},
    reducers: {
        logout: () => {
            //
        },
    },
});

export const {logout} = logoutSlice.actions;
export default logoutSlice.reducer;
