import React, {useState, useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Typography, Button, Grid, Box, CircularProgress} from '@mui/material';
import {allowSaveUsers} from '../sharedFunctions';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {useGrowl} from '~/utils/hooks/useGrowl';
import style from './DataContributorEdit.scss';
import {emptyUser, IDataContributor, IUser} from '~/interfaces/admin';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {BackButton} from '../../../components/Common/Button/BackButton';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    updateDataContributor,
    deleteDataContributor,
    activateDataContributor,
} from '~/store/dataContributor/dataContributor.thunk';
import {setAgencies} from '~/store/agency/agency.thunk';
import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';

export const DataContributorEdit = () => {
    const dispatch = useAppDispatch();
    const {dataContributorId} = useParams<{dataContributorId: string}>();
    const dataContributor = useTypedSelector((state) => state.data.admin.dataContributors).filter(
        (dataContributor) => dataContributor.Id === Number(dataContributorId),
    )[0];

    const [user, setUser] = useState<IUser>(dataContributor?.User || emptyUser);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();

    const [isEditUser, setIsEditUser] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);
    const isButtonDisabled = !allowSaveUsers(user) || hasError;

    useEffect(() => {
        dispatch(setAgencies());
    }, []);

    const closeAllEdits = () => {
        setIsEditUser(false);
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        closeAllEdits();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        const updatedDataContributor: IDataContributor = {
            ...dataContributor,
            User: {...user},
        };
        const thunkActionResult = await dispatch(
            updateDataContributor({updatedDataContributor: updatedDataContributor}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            successfulSubmit();
        }
        setIsLoading(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleActivateUser = async (_?: number) => {
        const thunkActionResult = await dispatch(activateDataContributor({dataContributor: dataContributor}));
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            openGrowl(`${user.Name} has been reactivated`);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDeleteUser = (_: number) => {
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteDataContributor({dataContributor: dataContributor, deletedReason}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            history.push('/admin/data-contributors');
        }

        setIsDeleting(false);
    };

    const handleEditUser = () => {
        setIsEditUser(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };

    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };

    const confirmCancel = () => {
        closeAllEdits();
        dataContributor?.User && setUser(dataContributor?.User);
        closeConfirmDialog();
    };

    useEffect(() => {
        dataContributor?.User && setUser(dataContributor?.User);
    }, [dataContributor?.User]);

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <div styleName="dataContributorEdit">
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push('/admin/data-contributors');
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.dataContributorEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            User Details
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        {isEditUser && <SubmitButtons />}
                    </Grid>
                </Grid>
                {isEditUser ? (
                    <UserDetails
                        heading={'data contributor'}
                        details={user}
                        setUser={setUser}
                        fieldsToDisable={['email', 'userName', 'agency']}
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setHasError}
                    />
                ) : (
                    <UserView
                        details={user}
                        userRole="User"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser}
                        handleDeleteUser={handleDeleteUser}
                        agency={dataContributor?.Agency}
                    />
                )}
                {isEditUser && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteDataContributor"
                title={`Delete Data Contributor?`}
                buttonMessage="DATA CONTRIBUTOR"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </div>
    );
};
