import React from 'react';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {format} from 'date-fns';
import {formatCurrency} from '~/utils/currencyUtils';
import {SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import style from './SilverSupportScheme.scss';
import {stableSort, getComparator} from '~/utils/tableUtils';
import {allNoResults, formatValue, extractAllValues, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

interface ISSSDetail {
    SSSPayoutDate: string;
    SSSPayoutAmount: string;
}
interface ISilverSupportScheme {
    SSSApiDetails: ISSSDetail[];
}

export type ISilverSupportSchemeData = ISilverSupportScheme;

export const SilverSupportScheme: React.FunctionComponent<{
    data: ISilverSupportSchemeData;
    errorMessages?: string[];
}> = ({data, errorMessages}) => {
    const {SSSApiDetails} = data;
    const noData = allNoResults(extractAllValues(SSSApiDetails));

    const dateFormat = (date: string) =>
        date.length === 8 ? date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8) : date;
    const formatDateValue = (date: string) => format(new Date(dateFormat(date)), 'dd MMM yyyy');
    const formatAmount = (amount: string) => formatCurrency(parseFloat(amount), 2);

    return noData ? (
        errorMessages && errorMessages.length !== 0 && isNotEmpty(errorMessages[0]) ? (
            <NoData message={`${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${errorMessages[0]})`} />
        ) : (
            <NoData />
        )
    ) : (
        <Grid container spacing={3} data-testid="sssSection">
            <Grid item xs={12}>
                <div id={style.custom2ColTable}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Payout Date</TableCell>
                                    <TableCell>Payout Amount ($)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(SSSApiDetails, getComparator('desc', 'SSSPayoutDate')).map(
                                    ({SSSPayoutDate, SSSPayoutAmount}, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">
                                                    {formatValue(SSSPayoutDate, formatDateValue)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1">
                                                    {formatValue(SSSPayoutAmount, formatAmount)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
        </Grid>
    );
};
