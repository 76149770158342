import React, {useState, useEffect} from 'react';
import {
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableCell,
    TableRow,
    Typography,
    TableHead,
    TableBody,
} from '@mui/material';
import {Clear as ClearIcon} from '@mui/icons-material';
import {IReleaseNoteSection} from '~/interfaces/common';
import {AddButtonSecondary} from '../Button/AddButtonSecondary';
import {cloneDeep} from 'lodash';
import './ReleaseNoteDetailEdit.scss';
import {InputField} from '~/components/Common/InputField/InputField';
import {checkMaxLength} from '~/components/Common/InputField/ValidationRules';
import {DEFAULT_SHORT_FIELD_MAX_LENGTH} from '~/constants';
export interface IReleaseNoteDetailEditProps {
    releaseNoteSection: IReleaseNoteSection[];
    title: string;
    icon?: JSX.Element;
    setReleaseNoteSection: (releaseNoteSection: IReleaseNoteSection[]) => void;
    setHasError: (error: boolean) => void;
}

enum UpdateKeys {
    CONTENT_LIST = 'ContentList',
    USER = 'User',
}

export const ReleaseNoteDetailEdit = ({
    releaseNoteSection,
    title,
    icon,
    setReleaseNoteSection,
    setHasError,
}: IReleaseNoteDetailEditProps) => {
    const [cursor, setCursor] = useState(0);

    useEffect(() => {
        const textAreaSelection = document.getElementById('content') as HTMLInputElement;
        textAreaSelection && textAreaSelection.setSelectionRange(cursor, cursor);
    }, [cursor]);

    const handleAddRow = () => {
        const newRow: IReleaseNoteSection = {
            User: '',
            ContentList: [],
        };
        const updatedReleaseNoteSection = releaseNoteSection ? [...releaseNoteSection, newRow] : [newRow];
        setReleaseNoteSection(updatedReleaseNoteSection);
    };

    const handleDeleteRow = (noteSectionIndex: Number) => {
        releaseNoteSection &&
            setReleaseNoteSection(releaseNoteSection.filter((_, index) => index !== noteSectionIndex));
    };

    const formatArrayDescriptionToString = (description: string[]) => {
        if (description && description.length >= 2) {
            return description.map((line) => '\u2022 ' + line).join('\n');
        } else {
            return '\u2022 ' + (description[0] ? description[0] : '');
        }
    };

    const formatStringDescriptionToArray = (description: string) => {
        return description.split('\n').map((line) => line.replace('\u2022 ', ''));
    };

    const handleChangeInSection = (
        index: number,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        keyValue: UpdateKeys,
    ) => {
        const newReleaseSection = cloneDeep(releaseNoteSection);
        if (keyValue === UpdateKeys.CONTENT_LIST) {
            let newDescription = event.target.value;
            const beforeCursorChar = (event.target.selectionStart && event.target.selectionStart - 1) || 0;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const keyboardInput = event.nativeEvent as any;
            if (
                keyboardInput.inputType === 'deleteContentBackward' &&
                newDescription.charAt(beforeCursorChar) === `\u2022`
            ) {
                if (event.target.selectionStart === 1 && newDescription.split('\n')[0] === `\u2022`) {
                    newDescription = `${newDescription.substring(2)}`;
                    setCursor(2);
                } else {
                    newDescription = `${newDescription.substring(0, beforeCursorChar - 1)}${newDescription.substring(
                        beforeCursorChar + 1,
                    )}`;
                    setCursor(beforeCursorChar - 1);
                }
            } else if (keyboardInput.inputType === 'insertLineBreak') {
                event.target.selectionStart && setCursor(event.target.selectionStart + 2);
            }
            const descriptionContent = formatStringDescriptionToArray(newDescription);
            newReleaseSection[index].ContentList = descriptionContent;
        } else if (keyValue === UpdateKeys.USER) {
            newReleaseSection[index].User = event.target.value;
            event.target.value.length > DEFAULT_SHORT_FIELD_MAX_LENGTH ? setHasError(true) : setHasError(false);
        }
        setReleaseNoteSection(newReleaseSection);
    };

    return (
        <>
            <List>
                <ListItem styleName="expandHeader">
                    <div styleName="sectionTitleContainer">
                        <span styleName="sectionTitle">
                            {icon}
                            <Typography variant="body1" styleName="expandTitle">
                                {title}
                            </Typography>
                        </span>
                        <AddButtonSecondary onClick={handleAddRow} styleName="addButton" size="small" text="ADD ROW" />
                    </div>
                </ListItem>
                {releaseNoteSection.length > 0 && (
                    <Paper data-testid="release_note_detail_edit_table">
                        <Table>
                            <TableHead styleName="releaseNoteHeader">
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Role</TableCell>
                                    <TableCell styleName="descriptionTableCell">Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {releaseNoteSection.map(({User, ContentList}, index) => {
                                    return (
                                        <TableRow key={index} data-testid={`release_note_detail_edit_row_${index}`}>
                                            <TableCell styleName="deleteButtonWrapper">
                                                <Button
                                                    styleName="deleteButton"
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleDeleteRow && handleDeleteRow(index);
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <InputField
                                                    key="roleInput"
                                                    styleName="roleInput"
                                                    htmlFor="role-input"
                                                    onChange={() => void 0}
                                                    onEventChange={(e) => {
                                                        handleChangeInSection(index, e, UpdateKeys.USER);
                                                    }}
                                                    value={User || ''}
                                                    customErrorMessage={checkMaxLength(
                                                        User || '',
                                                        'Role',
                                                        DEFAULT_SHORT_FIELD_MAX_LENGTH,
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <InputField
                                                    key="descriptionInput"
                                                    styleName="descriptionInput"
                                                    htmlFor="description-input"
                                                    onChange={() => void 0}
                                                    onEventChange={(e) => {
                                                        handleChangeInSection(index, e, UpdateKeys.CONTENT_LIST);
                                                    }}
                                                    value={formatArrayDescriptionToString(ContentList)}
                                                    multiline
                                                    maxLength={1000 + 2}
                                                    displayCharCounter
                                                    data-testid="descriptionInputField"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                )}
            </List>
        </>
    );
};
