import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {get} from 'lodash';
import {Button, Card, CardContent, Container, Grid, Typography, SelectChangeEvent} from '@mui/material';
import * as apiServices from '~/services/apiServices';
import {ILoginUser, IUserResponse} from '~/components/LoginForm/account';
import {SimpleDialog} from '~/components/Common/SimpleDialog';
import {LoginForm, MOCK_USER_TYPE} from '~/components/LoginForm/LoginForm';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE, USER_TYPE} from '~/constants';
import {config} from '~/config';

import './DevLoginView.scss';

export interface IDevLoginView {
    referer: string;
}

export const DevLoginView = ({referer}: IDevLoginView) => {
    const {setAccessToken, loginState, updateLoginState} = useContext(AuthenticationContext);

    const [isError, setIsError] = useState<boolean>(false);
    const [userName, setUserName] = useState<string | null>();
    const [userNameIntranetText, setUserNameIntranetText] = useState<string>();
    const [userNameInternetText, setUserNameInternetText] = useState<string>();
    const [users, setUsers] = useState<ILoginUser[]>([]);
    const [userType, setUserType] = useState<USER_TYPE>(USER_TYPE.intranet);
    const [inactiveDialog, setInactiveDialog] = useState(false);
    const [autoLogoutDialog, setAutoLogoutDialog] = useState(false);

    const spLogin = async () => {
        try {
            const res = await fetch(`${config.api.AUTH_SERVICE}/corppass-login`, {
                method: 'GET',
            });
            const redirectUri = await res.text();
            window.location.href = redirectUri;
        } catch (_) {
            setIsError(true);
        }
    };

    const handleInactiveDialogClose = () => {
        setInactiveDialog(false);
    };

    const handleAutoLogoutDialogClose = () => {
        setAutoLogoutDialog(false);
    };

    // Username change events
    // Don't use callback if we are not setting a dependencies
    const onUserNameChange = (event: SelectChangeEvent<{name?: string; value: unknown}>): void => {
        const username = get(event, 'target.value', null);
        setUserName(username);
        setUserType(
            users.find((user) => user.userName === username)?.type === MOCK_USER_TYPE.INTERNET
                ? USER_TYPE.internet
                : USER_TYPE.intranet,
        );
        setUserNameIntranetText('');
        setUserNameInternetText('');
    };

    const onUserNameTextChange = (orgType: string, e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        if (orgType == USER_TYPE.internet) {
            setUserType(USER_TYPE.internet);
            setUserNameInternetText(value);
            setUserNameIntranetText('');
        } else {
            setUserType(USER_TYPE.intranet);
            setUserNameIntranetText(value);
            setUserNameInternetText('');
        }
        setUserName(null);
    };

    const getUsers = async (): Promise<void> => {
        try {
            const {ad, corppass} = await apiServices.getMockUsers();
            const corppassUsersMap = corppass.map((user: IUserResponse) => ({
                entityId: user.EntityId,
                type: MOCK_USER_TYPE.INTERNET,
                userName: user.UserName,
            }));
            const adUsersMap = ad.map((user: Pick<IUserResponse, 'UserName'>) => ({
                type: MOCK_USER_TYPE.INTRANET,
                userName: user.UserName,
            }));

            setUsers([...corppassUsersMap, ...adUsersMap]);
        } catch (_) {
            setIsError(true);
        }
    };

    const authenticateUser = async (): Promise<void> => {
        try {
            let userNameStr: string = userName || '';

            if (!userName && !userNameIntranetText && !userNameInternetText) return;
            if (userNameIntranetText != undefined && userNameIntranetText != '') {
                userNameStr = userNameIntranetText;
            } else if (userNameInternetText != undefined && userNameInternetText != '') {
                userNameStr = userNameInternetText;
            }
            const payload = {
                userName: userNameStr,
                userType: userType,
            };

            const token = await apiServices.authenticateUser(payload);

            if (token) {
                await setAccessToken(token);
                updateLoginState(LOGIN_STATE.LOGGED_IN);
                setIsError(false);
            } else {
                setInactiveDialog(true);
            }
        } catch (err) {
            setIsError(true);
        }
    };

    useEffect(() => {
        if (loginState == LOGIN_STATE.LOGGED_OUT_EXPIRED || loginState == LOGIN_STATE.LOGGED_OUT_INACTIVE) {
            setAutoLogoutDialog(true);
        }
        if (loginState != LOGIN_STATE.LOGGED_IN) {
            getUsers();
        }
    }, []);

    return loginState == LOGIN_STATE.LOGGED_IN && !isError ? (
        <Redirect to={referer} />
    ) : (
        <Container
            styleName="container-margin"
            style={{
                marginTop: '-20px',
            }}
        >
            <Grid container spacing={4}>
                <Grid container item xs={12} style={{justifyContent: 'space-between'}}>
                    <Typography component="h5" variant="h4">
                        Mock Login
                    </Typography>
                    <Button color="primary" variant="contained" onClick={spLogin} style={{float: 'right'}}>
                        Login via SingPass
                    </Button>
                </Grid>
                <Grid container item xs={12}>
                    <Typography color="textSecondary" component="p" variant="body2">
                        Please use this mock login to login to the system. Only available in dev environment.
                    </Typography>
                </Grid>
                <Grid container item xs={6}>
                    <Card styleName="card">
                        <CardContent styleName="cardContent">
                            <LoginForm
                                organisationType={USER_TYPE.intranet}
                                userName={userName}
                                userNameText={userNameIntranetText}
                                users={users.filter((user) => user.type === MOCK_USER_TYPE.INTRANET)}
                                onUserNameChange={onUserNameChange}
                                onUserNameTextChange={onUserNameTextChange}
                                onEnter={authenticateUser}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={6}>
                    <Card styleName="card">
                        <CardContent styleName="cardContent">
                            <LoginForm
                                organisationType={USER_TYPE.internet}
                                userName={userName}
                                userNameText={userNameInternetText}
                                users={users.filter((user) => user.type === MOCK_USER_TYPE.INTERNET)}
                                onUserNameChange={onUserNameChange}
                                onUserNameTextChange={onUserNameTextChange}
                                onEnter={authenticateUser}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container item justifyContent="center" xs={12}>
                    <Button color="primary" id="loginButton" variant="contained" onClick={authenticateUser}>
                        Login
                    </Button>
                </Grid>
            </Grid>
            <SimpleDialog
                message="Account is inactive. Please contact administrator for re-activation."
                open={inactiveDialog}
                onClose={handleInactiveDialogClose}
            />
            <SimpleDialog
                id="autoLogoutDialog"
                message="You have been automatically logged out."
                open={autoLogoutDialog}
                onClose={handleAutoLogoutDialogClose}
            />
        </Container>
    );
};
