import React, {useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Paper} from '@mui/material';
import {USER_TYPE} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {useUpdateWigetSetting} from '~/utils/hooks/useUpdateWidgetSetting';
import {config} from '~/config';

import './SubmitFooter.scss';
import {isPageAtBottom} from '~/utils/pageUtils';

export interface ISubmitFooterProps {
    isButtonDisabled: boolean;
    handleCancel: () => void;
    handleSubmit?: () => void;
    domainHeaderRef?: React.RefObject<HTMLDivElement>;
    isLoading?: boolean;
}

export const SubmitFooter = ({
    isButtonDisabled,
    handleCancel,
    handleSubmit,
    domainHeaderRef,
    isLoading,
}: ISubmitFooterProps) => {
    const {updateWidgetVerticalOffset} = useUpdateWigetSetting();
    const [isFooterHidden, setIsFooterHidden] = useState<boolean>(true);
    const [isFooterOffsetBottom, setIsFooterOffsetBottom] = useState<boolean>(false);
    const {accessToken} = useContext(AuthenticationContext);
    const isIntranetUser = accessToken?.UserType === USER_TYPE.intranet;
    const offsetStyle = (style: string) => {
        return isFooterOffsetBottom ? `${style} ${isIntranetUser ? 'offset-intranet' : 'offset'}` : style;
    };

    // Passing of ref object from SubmitView to Submit(parent) causes the parent component to rerender on scroll,
    // and reset states of child components causing expanded nested table to collapse unexpectedly on scroll
    // So the ref is passed from SubmitView to SubmitFooter(children) to prevent the rerender
    useEffect(() => {
        const domainHeaderHandler = ([entry]: IntersectionObserverEntry[]) => setIsFooterHidden(entry.isIntersecting);
        const domainHeaderObserver = new IntersectionObserver(domainHeaderHandler, {
            threshold: 0,
            rootMargin: `0px 0px 0px 0px`,
        });

        if (domainHeaderRef?.current) {
            domainHeaderObserver.observe(domainHeaderRef?.current);
        }

        return () => {
            domainHeaderObserver.disconnect();
        };
    }, [domainHeaderRef?.current]);

    useEffect(() => {
        const handleScroll = () => {
            isPageAtBottom() ? setIsFooterOffsetBottom(true) : setIsFooterOffsetBottom(false);

            if (config.internetEnabled) {
                isPageAtBottom() ? updateWidgetVerticalOffset('175px') : updateWidgetVerticalOffset('75px');
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {!isFooterHidden && (
                <Paper id="submitFooter" elevation={2} styleName={offsetStyle('submitFooter')} square>
                    <Grid container justifyContent="flex-end" styleName="submitWrapper">
                        <Grid item styleName="footerButton">
                            <Button
                                onClick={() => {
                                    handleCancel();
                                }}
                                type="button"
                                color="primary"
                                variant="text"
                                size="large"
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={isButtonDisabled}
                                onClick={handleSubmit}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    );
};
