import {Box} from '@mui/material';
import React from 'react';

import style from './InactiveIcon.scss';

interface IInactiveIcon {
    message?: string;
}

export const InactiveIcon = ({message}: IInactiveIcon) => {
    return (
        <Box component="div" className={style.inactiveWrapper} id="inactive-icon">
            {message ? message : 'Inactive'}
        </Box>
    );
};
