import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {IAnnouncement, IGetAnnouncementResponse} from '~/interfaces/admin';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getAllAnnouncements = async (): Promise<IGetAnnouncementResponse> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/announcements`,
        withCredentials: true,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getAnnouncement = async (announcmentid: number): Promise<IAnnouncement> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/announcements/${announcmentid}`,
        withCredentials: true,
    });
    if (response.status !== 200) {
        throw new Error();
    }
    return response.data;
};

export const updateAnnouncement = async (announcementId: number, announcement: IAnnouncement) => {
    const announcementJSON = JSON.stringify(announcement);

    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/announcements/${announcementId}`,
        withCredentials: true,
        data: announcementJSON,
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};
