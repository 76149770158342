export const versionCompare = (v1: string, v2: string) => {
    if (!v1 || !v2) {
        return -1;
    }
    const v1parts = v1.split('.').map((val) => parseInt(val));
    const v2parts = v2.split('.').map((val) => parseInt(val));

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }

        if (v1parts[i] == v2parts[i]) {
            continue;
        } else if (v1parts[i] > v2parts[i]) {
            return 1;
        } else {
            return -1;
        }
    }

    if (v1parts.length < v2parts.length) {
        return -1;
    }

    return 0;
};
