import React, {useContext, useRef} from 'react';
import {useHistory} from 'react-router';
import {Button, Grid, Typography} from '@mui/material';
import {IDomain} from '~/interfaces/common';
import {IClientInfo, SubDomainStatus} from '~/interfaces/client';
import {ClientInfoFooter} from '~/components/Client/ClientInfoFooter/ClientInfoFooter';
import {ClientInfoSidebar} from '~/components/Client/ClientInfoSidebar/ClientInfoSidebar';
import {SubDomainDisplay, StatusObject} from '~/components/Client/SubDomainDisplay/SubDomainDisplay';
import {getLatestDate} from '~/utils/dateUtils';
import _, {isArray, isEmpty} from 'lodash';
import {ISubDomainData} from '~/components/Client/SubDomainDisplay/subDomainData';
import CircularProgress from '@mui/material/CircularProgress';
import {ACCESS_LEVELS, OPS_UNIT_TYPE, Relationship} from '~/constants';
import emptyScreeningResult from '~/assets/images/emptyScreeningResult.svg';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import './ClientInfoView.scss';

export interface IClientInfoView {
    subDomainRefs: HTMLDivElement[];
    screenedDomains: IDomain[];
    selectedDomainIndex?: number;
    selectedSubDomainIndex: number;
    clientInfo: IClientInfo;
    isLoadingReport: boolean;
    handleSelectDomain: (index: number) => void;
    handleSelectSubDomain: (index: number) => void;
    handleGenerateReport: () => void;
    handleFilterChange: (searchVal: string) => void;
}

export const ClientInfoView = ({
    clientInfo,
    screenedDomains,
    selectedDomainIndex,
    selectedSubDomainIndex,
    subDomainRefs,
    isLoadingReport,
    handleFilterChange,
    handleGenerateReport,
    handleSelectDomain,
    handleSelectSubDomain,
}: IClientInfoView) => {
    const {accessToken} = useContext(AuthenticationContext);
    const canGenerateReport =
        accessToken &&
        (accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.PSA ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.NGE_BIP);

    const domainHeaderRef = useRef<HTMLDivElement>(null);
    const footerExists = !!clientInfo;
    const history = useHistory();

    const handleBackToSearch = () => {
        history.push('/search');
    };

    return (
        <>
            <ClientInfoSidebar
                domains={screenedDomains}
                onFilterChange={handleFilterChange}
                onSelectDomain={handleSelectDomain}
                onSelectSubDomain={handleSelectSubDomain}
                selectedDomainIndex={Number(selectedDomainIndex)}
                selectedSubDomainIndex={Number(selectedSubDomainIndex)}
                footerExists={footerExists}
            />
            {_.isEmpty(clientInfo.SubDomainInfo) ? (
                <Grid container id="domainData" styleName="emptyState">
                    <Grid item xs={12}>
                        <img src={emptyScreeningResult} alt="Empty Screening Result" styleName="image" />
                        <Typography styleName="text">
                            No requested Sub-Domains for this{' '}
                            {clientInfo.GeneralInfo.Relationship === Relationship.CLIENT ? 'client' : 'family member'}
                        </Typography>
                        <Button onClick={handleBackToSearch} variant="outlined" color="primary">
                            MAKE A NEW SCREENING
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                selectedDomainIndex != undefined &&
                screenedDomains.length > selectedDomainIndex && (
                    <Grid container id="domainData">
                        <Grid item xs={8} id="domainHeader" ref={domainHeaderRef}>
                            <Typography styleName="domainHeader" variant="h4">
                                {screenedDomains[selectedDomainIndex].Name}
                            </Typography>
                        </Grid>
                        {canGenerateReport && (
                            <Grid item id="generateReportButton" styleName="generateReportButton" xs={4}>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={handleGenerateReport}
                                    disabled={isLoadingReport}
                                >
                                    <span>GENERATE REPORT</span>
                                    {isLoadingReport && <CircularProgress size={24} color="inherit" />}
                                </Button>
                            </Grid>
                        )}

                        {screenedDomains[selectedDomainIndex].SubDomains.map((subDomain, index) => {
                            const subDomainInfo = clientInfo.SubDomainInfo[subDomain.Code];
                            if (subDomainInfo) {
                                const lastUpdatedAt = getLatestDate(subDomainInfo.map((info) => info.LastUpdatedAt));
                                const status = subDomainInfo.reduce((accStatus: StatusObject, info) => {
                                    if (accStatus[info.Source] !== SubDomainStatus.COMPLETED) {
                                        accStatus[info.Source] = info.Status;
                                    }
                                    return accStatus;
                                }, {});

                                const isLastSubDomain = () =>
                                    screenedDomains[selectedDomainIndex].SubDomains.length === index + 1;

                                const acc = !isEmpty(subDomainInfo) && isArray(subDomainInfo[0].Data) ? [] : {};

                                const data = subDomainInfo.reduce((obj, info) => {
                                    return isArray(obj) && isArray(info.Data)
                                        ? [...obj, ...info.Data]
                                        : {...obj, ...info.Data};
                                }, acc) as ISubDomainData;

                                const errorMessages = subDomainInfo.reduce((accErrorMessages: string[], info) => {
                                    return info.ErrorMessages
                                        ? accErrorMessages.concat(info.ErrorMessages)
                                        : accErrorMessages;
                                }, []);

                                return (
                                    <Grid
                                        key={subDomain.Code}
                                        item
                                        xs={12}
                                        id={String(index)}
                                        ref={(ref) => ref && subDomainRefs.push(ref)}
                                    >
                                        <SubDomainDisplay
                                            name={subDomain.Name}
                                            code={subDomain.Code}
                                            status={status}
                                            data={data || []}
                                            lastUpdatedAt={lastUpdatedAt}
                                            errorMessages={errorMessages}
                                            isLastSubDomain={isLastSubDomain()}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                )
            )}
            {clientInfo && (
                <ClientInfoFooter
                    clientName={clientInfo.GeneralInfo.ClientName}
                    clientUIN={clientInfo.GeneralInfo.ClientUIN}
                    clientConsent={clientInfo.GeneralInfo.ClientConsent}
                    isButtonDisabled={false}
                    isButtonHidden={true}
                    isFooterHidden={false}
                    buttonText="GENERATE REPORT"
                    onButtonClick={handleGenerateReport}
                    headerRef={domainHeaderRef}
                    isButtonLoading={isLoadingReport}
                />
            )}
        </>
    );
};
