import React, {useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography} from '@mui/material';
import {KeyboardArrowDownRounded as KeyboardArrowDownIcon} from '@mui/icons-material';
import {Order, getComparator, stableSort} from '~/utils/tableUtils';
import {
    getAmount,
    getDepositAmount,
    getTotalSugRecAmount,
    getTotalSugToBePaid,
    TAssistanceType,
    TBenefitInfoType,
} from './interfaces';
import './ComcareAssistanceTable.scss';
import {getAssistancePeriod} from './ComcareAssistanceTable';

export interface IActiveInterventionProps<T> {
    rows: T[];
    type: TAssistanceType;
}

export const ActiveIntervention = <T extends TBenefitInfoType>({rows, type}: IActiveInterventionProps<T>) => {
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof T>('AssistanceStartDate');

    const sortPendingSubDomainsHandler = (property: keyof T) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const CustomTableSortLabel = ({orderByField, label}: {orderByField: string; label: string}) => (
        <TableSortLabel
            active={orderBy === orderByField}
            direction={orderBy === orderByField ? order : 'asc'}
            onClick={sortPendingSubDomainsHandler(orderByField as keyof T)}
            style={{color: '#3c3c3c', fontWeight: 'normal'}}
            IconComponent={KeyboardArrowDownIcon}
        >
            {label}
        </TableSortLabel>
    );

    const CustomTableCellField = ({text}: {text: string}) => (
        <TableCell style={{borderBottom: 'none', padding: '24px'}}>
            <Typography variant="body1">{text}</Typography>
        </TableCell>
    );

    const AmountCellField = ({benefitInfo}: {benefitInfo: T}) => {
        switch (benefitInfo.BenefitType.toUpperCase()) {
            case 'SCFA MONTHLY GRANT':
                return (
                    <TableCell style={{borderBottom: 'none', padding: '24px'}}>
                        <Typography variant="body1">{getAmount(benefitInfo)}</Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Monthly
                        </Typography>
                    </TableCell>
                );
            case 'SCFA START-UP GRANT (SUG) RECOMMENDATION DETAILS':
                return (
                    <TableCell style={{borderBottom: 'none', padding: '24px'}}>
                        <Typography variant="body1">{getDepositAmount(benefitInfo)}</Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Deposit
                        </Typography>

                        <Typography variant="body1" style={{marginTop: 32}}>
                            {getTotalSugRecAmount(benefitInfo)}
                        </Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Total SUG Recommended Amount
                        </Typography>

                        <Typography variant="body1" style={{marginTop: 32}}>
                            {getTotalSugToBePaid(benefitInfo)}
                        </Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Total SUG to be Paid to SCC
                        </Typography>
                    </TableCell>
                );
            case 'SCFA HOLIDAY CHARGES':
                return (
                    <TableCell style={{borderBottom: 'none', padding: '24px'}}>
                        <Typography variant="body1">{getAmount(benefitInfo)}</Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Total Amount
                        </Typography>
                    </TableCell>
                );
            case 'SCFA ONE-TIME TOP-UP':
                return (
                    <TableCell style={{borderBottom: 'none', padding: '24px'}}>
                        <Typography variant="body1">{getAmount(benefitInfo)}</Typography>
                        <Typography variant="caption" styleName="fieldName">
                            Total Amount
                        </Typography>
                    </TableCell>
                );
            default:
                return <CustomTableCellField text={getAmount(benefitInfo)} />;
        }
    };

    const getHeader = () => {
        switch (type) {
            case 'comcare':
                return [
                    {orderBy: 'AssistanceStartDate', label: 'Assistance Period'},
                    {orderBy: 'BenefitType', label: 'Benefit Type'},
                    {orderBy: 'BeneficiaryNames', label: 'Beneficiaries'},
                    {orderBy: 'MonthlyDisbursement', label: 'Monthly Disbursement ($)'},
                ];
            case 'comcare2':
                return [
                    {orderBy: 'AssistanceStartDate', label: 'Assistance Period'},
                    {orderBy: 'BenefitType', label: 'Benefit Type'},
                    {orderBy: 'BeneficiaryNames', label: 'Beneficiaries'},
                    {orderBy: 'Amount', label: 'Amount ($)'},
                ];
        }
    };

    return (
        <Table>
            <TableHead style={{backgroundColor: '#e8eaf6'}}>
                <TableRow>
                    {getHeader().map((header, index) => {
                        return (
                            <TableCell
                                data-testid={`header_${index}`}
                                key={`header_${index}`}
                                style={{
                                    borderBottom: 'none',
                                    padding: '24px',
                                }}
                                sortDirection={orderBy === header.orderBy ? order : false}
                            >
                                <CustomTableSortLabel
                                    data-testid={`label_${index}`}
                                    orderByField={header.orderBy}
                                    label={header.label}
                                />
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody style={{width: '100%'}}>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    return (
                        <TableRow key={`benefitInfo_${index}`}>
                            <CustomTableCellField
                                data-testid={`assistancePeriod_${index}`}
                                text={getAssistancePeriod(row.AssistanceStartDate, row.AssistanceEndDate)}
                            />
                            <CustomTableCellField data-testid={`benefitType_${index}`} text={row.BenefitType} />
                            <CustomTableCellField
                                data-testid={`beneficiaryNames_${index}`}
                                text={row.BeneficiaryNames}
                            />
                            <AmountCellField data-testid={`amount_${index}`} benefitInfo={row} />
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
