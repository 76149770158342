import React, {useContext} from 'react';
import {ACCESS_LEVELS} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {Redirect} from 'react-router-dom';

const getHomePage = (a: Pick<IAccessToken, 'Permissions'>) => {
    switch (a.Permissions.AccessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            return '/admin/dashboard';
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
            return `/admin/teams/${a.Permissions.TeamId}/users`;
        case ACCESS_LEVELS.DATA_UPLOADER:
            return '/data-upload';
        default:
            return '/search';
    }
};

export const Home = () => {
    const {accessToken} = useContext(AuthenticationContext);

    return <Redirect to={getHomePage(accessToken as IAccessToken)} />;
};
