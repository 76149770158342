import {useState} from 'react';

export interface ISnackBar {
    open: boolean;
    message: string;
    autoHideDuration: number;
    key?: number;
}

export const useGrowl = () => {
    // Pat requested to have a default autohide duration of 5s
    const [growl, setGrowl] = useState<ISnackBar>({open: false, message: '', autoHideDuration: 5000});
    const closeGrowl = () => {
        setGrowl({
            ...growl,
            open: false,
        });
    };
    const openGrowl = (message: string) => {
        if (!message) return;
        setGrowl({
            ...growl,
            open: true,
            key: new Date().getTime(),
            message,
        });
    };

    return {growl, openGrowl, closeGrowl};
};
