import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {UnregisterCallback} from 'history';
import {Dialog, Button, Stack, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {isEqual} from 'lodash';

import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    getDataFieldRequestStatuesState,
    getSubDomainReasonState,
    getInitialMatrixState,
} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';

export interface IRouterLeavingGuardModalDialog {
    opsUnitId: number;
}

export const RouterLeavingGuardModalDialog = ({opsUnitId}: IRouterLeavingGuardModalDialog) => {
    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const dataFieldRequestStatuses = useTypedSelector(getDataFieldRequestStatuesState(opsUnitId));
    const subDomainReason = useTypedSelector(getSubDomainReasonState(opsUnitId));
    const initialMatrix = useTypedSelector(getInitialMatrixState(opsUnitId));

    const isDirty =
        !isEqual(dataFieldRequestStatuses, initialMatrix.dataFieldRequestStatuses) ||
        !isEqual(subDomainReason, initialMatrix.subDomainReason);

    const unblockRef = useRef<UnregisterCallback>();

    function handleShowModal() {
        setShowPrompt(true);
    }

    function onCancel() {
        setShowPrompt(false);
    }

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (isDirty) {
                setCurrentPath(location.pathname);
                handleShowModal();
                return false;
            }
            return;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [isDirty]);

    function handleConfirm() {
        if (unblockRef) {
            unblockRef.current && unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath);
    }

    return (
        <Dialog
            fullWidth
            open={showPrompt}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{color: '#3C3C3C', letterSpacing: '0.18px'}}>
                Leave page without saving?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{color: '#3C3C3C', letterSpacing: '0.5px'}}>
                    Any unsaved changes will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <Stack spacing={2} direction="row" style={{float: 'right'}}>
                    <Button onClick={onCancel} color="primary" variant="outlined" autoFocus>
                        Stay on page
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="error" autoFocus>
                        Leave page
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
