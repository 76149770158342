import {createAsyncThunk} from '@reduxjs/toolkit';
import * as opsUnitServices from '~/services/opsUnitServices';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {IOpsUnit, IUser} from '~/interfaces/admin';

export interface ISetOpsUnits {
    agencyId: number;
    opsUnits: {[Id: number]: IOpsUnit};
}

export const setOpsUnits = createAsyncThunk<ISetOpsUnits | void, number, {state: RootState}>(
    '/opsUnit/setOpsUnits',
    async (agencyId, {dispatch}) => {
        dispatch({type: fetchStateActions.SET_OPSUNITS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
        try {
            const opsUnits = await opsUnitServices.getOpsUnits(agencyId);
            const opsUnitsObject = opsUnits.reduce((obj, opsUnit) => {
                return {
                    ...obj,
                    [opsUnit.OpsUnit.Id as number]: {...opsUnit.OpsUnit, ...opsUnit.CurrentOpsUnitReviews},
                };
            }, {});
            dispatch({type: fetchStateActions.SET_OPSUNITS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
            dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.OPS_UNIT});
            return {agencyId: agencyId, opsUnits: opsUnitsObject};
        } catch (err) {
            dispatch({type: fetchStateActions.SET_OPSUNITS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
        }
    },
);

export const refreshOpsUnits = createAsyncThunk<ISetOpsUnits | void, number, {state: RootState}>(
    '/opsUnit/setOpsUnits',
    async (agencyId, {dispatch}) => {
        try {
            const opsUnits = await opsUnitServices.getOpsUnits(agencyId);
            const opsUnitsObject = opsUnits.reduce((obj, opsUnit) => {
                return {
                    ...obj,
                    [opsUnit.OpsUnit.Id as number]: {...opsUnit.OpsUnit, ...opsUnit.CurrentOpsUnitReviews},
                };
            }, {});
            dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.OPS_UNIT});
            return {agencyId: agencyId, opsUnits: opsUnitsObject};
        } catch (err) {
            dispatch({type: fetchStateActions.SET_OPSUNITS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
        }
    },
);
export const setOpsUnit = createAsyncThunk<IOpsUnit, number, {state: RootState}>(
    '/opsUnit/setOpsUnit',
    async (opsUnitId, {dispatch, rejectWithValue}) => {
        try {
            const opsUnit = await opsUnitServices.getOpsUnit(opsUnitId);
            dispatch({type: fetchStateActions.SET_OPSUNIT_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
            dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.OPS_UNIT});
            return opsUnit;
        } catch (err) {
            dispatch({type: fetchStateActions.SET_OPSUNIT_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            return rejectWithValue(err);
        }
    },
);

export const createOpsUnit = createAsyncThunk<IOpsUnit, {agencyId: number; opsUnit: IOpsUnit}, {state: RootState}>(
    '/opsUnit/createOpsUnit',
    async ({agencyId, opsUnit}, {rejectWithValue}) => {
        try {
            const opsUnitResponse = await opsUnitServices.createOpsUnit(agencyId, opsUnit);
            if (opsUnitResponse.Error) {
                return rejectWithValue(opsUnitResponse.Error);
            }
            return opsUnitResponse.OpsUnit;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateOpsUnit = createAsyncThunk<IOpsUnit, {updatedOpsUnit: IOpsUnit}, {state: RootState}>(
    '/opsUnit/updateOpsUnit',
    async ({updatedOpsUnit}, {rejectWithValue}) => {
        try {
            const opsUnitResponse = await opsUnitServices.updateOpsUnitDetails(updatedOpsUnit);
            if (opsUnitResponse.Error) {
                return rejectWithValue(opsUnitResponse.Error);
            }
            return opsUnitResponse.OpsUnit;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const createOpsUnitAdmin = createAsyncThunk<
    {opsUnitId: number; opsUnit: IOpsUnit},
    {opsUnitId: number; admin: IUser},
    {state: RootState}
>('/opsUnit/createOpsUnitAdmin', async ({opsUnitId, admin}, {rejectWithValue}) => {
    try {
        const opsUnitResponse = await opsUnitServices.createOpsUnitAdmin(opsUnitId, admin);
        if (opsUnitResponse.Error) {
            return rejectWithValue(opsUnitResponse.Error);
        }
        return {opsUnitId: opsUnitId, opsUnit: opsUnitResponse.OpsUnit};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const updateOpsUnitAdmin = createAsyncThunk<
    {opsUnitId: number; adminDetails: IUser},
    {opsUnitId: number; adminDetails: IUser},
    {state: RootState}
>('/opsUnit/updateOpsUnitAdmin', async ({opsUnitId, adminDetails}, {rejectWithValue}) => {
    try {
        await opsUnitServices.updateOpsUnitAdmin(opsUnitId, adminDetails);
        return {opsUnitId: opsUnitId, adminDetails: adminDetails};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const deleteOpsUnitAdmin = createAsyncThunk<
    {opsUnitId: number; opsUnit: IOpsUnit},
    {opsUnitId: number; userId: number; deletedReason: string},
    {state: RootState}
>('/opsUnit/deleteOpsUnitAdmin', async ({opsUnitId, userId, deletedReason}, {rejectWithValue}) => {
    try {
        const opsUnitResponse = await opsUnitServices.deleteOpsUnitAdmin(opsUnitId, userId, deletedReason);
        if (opsUnitResponse.Error) {
            return rejectWithValue(opsUnitResponse.Error);
        }
        return {opsUnitId: opsUnitId, opsUnit: opsUnitResponse.OpsUnit};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const activateOpsUnitAdmin = createAsyncThunk<
    {opsUnitId: number; admin: IUser},
    {opsUnitId: number; admin: IUser},
    {state: RootState; rejectValue: string}
>('/opsUnit/activateOpsUnitAdmin', async ({opsUnitId, admin}, {rejectWithValue}) => {
    if (admin.Id) {
        try {
            await opsUnitServices.activateOpsUnitAdmin(opsUnitId, admin.Id);
            return {opsUnitId: opsUnitId, admin: admin};
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    }
    return rejectWithValue('admin Id not found');
});

export const setDamOpsUnits = createAsyncThunk<IOpsUnit[] | void, void, {state: RootState}>(
    '/opsUnit/setDamOpsUnits',
    async (_, {dispatch, getState}) => {
        if (!getState().data.flags[Flag.DAM_OPS_UNIT]) {
            dispatch({type: fetchStateActions.SET_DAM_OPS_UNITS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const opsUnits = await opsUnitServices.getDamOpsUnits();
                dispatch({
                    type: fetchStateActions.SET_DAM_OPS_UNITS_FETCH_STATE.type,
                    payload: API_FETCH_STATE.SUCCESS,
                });
                dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.DAM_OPS_UNIT});
                return opsUnits;
            } catch (err) {
                dispatch({type: fetchStateActions.SET_DAM_OPS_UNITS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            }
        }
    },
);

export interface IUpdateOpsUnitCanScreenClient {
    [Id: number]: boolean;
}
export const updateOpsUnitCanScreenClient = createAsyncThunk<boolean, IUpdateOpsUnitCanScreenClient>(
    '.opsUnit/updateOpsUnitCanScreenClient',
    async (updatedOpsUnit) => {
        try {
            await opsUnitServices.updateOpsUnitCanScreenClient(updatedOpsUnit);
            return true;
        } catch (err) {
            return false;
        }
    },
);
