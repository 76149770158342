import React from 'react';
import {Grid, Typography} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './Common.scss';
import {isNotEmpty} from '~/utils/contentUtils';

export interface IHouseholdMember {
    Id: number;
    MemberName: string;
    Relationship: Relationship;
    YearOfBirth: string;
    IdNumber: string;
}

// Info not ready
// const DEFAULT_ID_NUMBER = 'NA';

export enum Relationship {
    RTL = 'Registered Tenant/Lessee',
    SPOUSE = 'Spouse',
    OTHER_SPOUSE = 'Other Spouse',
    FIANCE_FIANCEE = 'Fiance/Fiancee',
    SON_DAU = 'Son/Daughter',
    SON_DAU_LAW = 'Son/Daughter-in-law',
    PARENT = 'Parent',
    PARENT_LAW = 'Parent-in-law',
    BRO_SIS = 'Brother/Sister',
    BRO_SIS_LAW = 'Brother/Sister-in-law',
    GRANDPARENT = 'Grandparent',
    GRANDPARENT_LAW = 'Grandparent-in-law',
    GSON_GDAU = 'Grandson/Granddaughter',
    GSON_GDAU_LAW = 'Grandson/Granddaughter-in-law',
    UNCLE_AUNT = 'Uncle/Aunt',
    UNCLE_AUNT_LAW = 'Uncle/Aunt-in-law',
    COUSIN = 'Cousin',
    NEPHEW_NIECE = 'Nephew/Niece',
    RELATIVE = 'Relative',
    UNRELATED = 'Unrelated',
    UNKNOWN = 'Unknown',
}
export const relationshipOrder = [
    Relationship.RTL,
    Relationship.SPOUSE,
    Relationship.OTHER_SPOUSE,
    Relationship.FIANCE_FIANCEE,
    Relationship.SON_DAU,
    Relationship.SON_DAU_LAW,
    Relationship.PARENT,
    Relationship.PARENT_LAW,
    Relationship.BRO_SIS,
    Relationship.BRO_SIS_LAW,
    Relationship.GRANDPARENT,
    Relationship.GRANDPARENT_LAW,
    Relationship.GSON_GDAU,
    Relationship.GSON_GDAU_LAW,
    Relationship.UNCLE_AUNT,
    Relationship.UNCLE_AUNT_LAW,
    Relationship.COUSIN,
    Relationship.NEPHEW_NIECE,
    Relationship.RELATIVE,
    Relationship.UNRELATED,
    Relationship.UNKNOWN,
];

export const HouseholdMembers: React.FunctionComponent<{data?: IHouseholdMember[]}> = ({data}) => {
    if (!data) return <></>;

    const sortedData = data.slice().sort((memberA: IHouseholdMember, memberB: IHouseholdMember) => {
        return relationshipOrder.indexOf(memberA.Relationship) - relationshipOrder.indexOf(memberB.Relationship);
    });

    const memberName = (member: IHouseholdMember) => (
        <Typography variant="body2" styleName="content">
            {isNotEmpty(member.MemberName) ? member.MemberName.toLowerCase() : member.MemberName}
        </Typography>
    );
    const relationship = (member: IHouseholdMember): Relationship => member.Relationship;
    const yearOfBirth = (member: IHouseholdMember) => member.YearOfBirth;
    // Info not ready
    // const idNumber = (member: IHouseholdMember) => {
    //     if (!member.IdNumber) {
    //         return DEFAULT_ID_NUMBER;
    //     }
    //     return member.IdNumber;
    // };

    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.table}>
                <ListModel
                    columns={[memberName, relationship, yearOfBirth]}
                    headers={['Household Member', 'Relationship', 'Year of Birth']}
                    modelList={sortedData}
                    modelComparator={[() => 0, () => 0, () => 0]}
                    variant="expanded"
                    sortable={false}
                    numbering={true}
                />
            </div>
        </Grid>
    );
};
