import React, {useState, useEffect} from 'react';
import {CONSENT_ACTIONS, CONSENT_STATUS, COPIES, ONECV_LAUNCH_DATE} from '~/constants';
import {Button, Box, DialogContent, DialogActions, DialogContentText, Typography} from '@mui/material';
import {DateInput} from '~/components/Common/DateInput/DateInput';
import {InputField} from '~/components/Common/InputField/InputField';
import {checkCharCount} from '~/utils/inputUtils';
import './ConsentUpdateDialogForm.scss';
import {UploadConsent} from '~/pages/Client/ConsentAndAccess/Consent/UploadConsent';

export interface IConsentUpdateDialogFormProps {
    status: CONSENT_STATUS;
    action: string;
    remarks: string;
    effectiveDate: Date;
    consentFormId: number;
    hasDivider?: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    onRemarksChange: (remarks: string) => void;
    onEffectiveDateChange: (effectiveDate: Date) => void;
    onConsentFormIdChange: (id: number) => void;
}

export const ConsentUpdateDialogForm = ({
    status,
    action,
    remarks,
    effectiveDate,
    consentFormId,
    onCancel,
    onSubmit,
    onRemarksChange,
    onEffectiveDateChange,
    onConsentFormIdChange,
}: IConsentUpdateDialogFormProps) => {
    const [canSubmit, setCanSubmit] = useState(false);
    const handleUpdateConsentSubmit = () => {
        canSubmit && onSubmit();
    };

    useEffect(() => {
        const date = new Date(effectiveDate);
        setCanSubmit(!isNaN(date.valueOf()) && checkCharCount(remarks) && isUploadComplete);
    }, [remarks, effectiveDate, consentFormId]);

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [selectedFilename, setSelectedFilename] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const isUpload = Boolean(action === 'revoke' || action === 'update');
    const isUploadComplete = Boolean((isUpload && consentFormId) || !isUpload);

    const resetFile = () => {
        const element = document.getElementById('file') as HTMLInputElement;
        if (element) element.value = '';
        setSelectedFile(null);
        setSelectedFilename('');
        onConsentFormIdChange(0);
        setErrorMessage('');
    };
    const title = () => {
        switch (action) {
            case CONSENT_ACTIONS.REVOKE:
            case CONSENT_ACTIONS.DELETE:
                return action + ' consent for this client?';
            case CONSENT_ACTIONS.UPDATE:
                if (status === CONSENT_STATUS.REVOKED_CONSENT) {
                    return action + ' withdrawal form?';
                }
                return action + ' consent form?';
        }
    };

    const description = () => {
        switch (action) {
            case CONSENT_ACTIONS.REVOKE:
            case CONSENT_ACTIONS.DELETE:
                return COPIES.CONSENT_REVOKE_DESCRIPTIONS;
            case CONSENT_ACTIONS.UPDATE:
                if (status === CONSENT_STATUS.REVOKED_CONSENT) {
                    return COPIES.CONSENT_UPDATE_DESCRIPTIONS;
                }
                return COPIES.CONSENT_UPDATE_ACTIVE_DESCRIPTIONS;
        }
    };

    return (
        <div styleName="ConsentUpdateDialogForm">
            <DialogContent>
                <DialogContentText styleName="titleText">{title()}</DialogContentText>
                <DialogContentText styleName="descriptionText">{description()}</DialogContentText>
            </DialogContent>
            <DialogContent>
                <div styleName="effectiveDateWrapper">
                    <DateInput
                        label="Effective Date"
                        value={effectiveDate}
                        onChange={(date) => {
                            onEffectiveDateChange(new Date(date as string));
                        }}
                        dateFormat="dd/MM/yyyy"
                        minDate={ONECV_LAUNCH_DATE}
                        maxDate={new Date()}
                    />
                </div>
                <InputField
                    styleName="remarksInput"
                    displayCharCounter
                    htmlFor={'remarksInput'}
                    label={'Remarks'}
                    maxLength={250}
                    multiline
                    rows={3}
                    onChange={onRemarksChange}
                    value={remarks}
                />
                {isUpload && (
                    <Box style={{paddingTop: '16px'}}>
                        <Typography variant="subtitle2" styleName="formSubtitle">
                            {action === 'revoke' && 'Withdrawal form'}
                            {action === 'update' && status === CONSENT_STATUS.REVOKED_CONSENT && 'Withdrawal form'}
                            {action === 'update' && status === CONSENT_STATUS.ACTIVE_CONSENT && 'Consent form'}
                        </Typography>
                        <Box styleName="uploadConsentWrapper">
                            <UploadConsent
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                selectedFilename={selectedFilename}
                                setSelectedFilename={setSelectedFilename}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                resetFile={resetFile}
                                setFileId={onConsentFormIdChange}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>

            <DialogActions styleName="actionWrapper">
                <Button onClick={onCancel} variant="outlined" color="primary" id="cancel-update-button">
                    CANCEL
                </Button>
                <Button
                    styleName="submitButton"
                    onClick={handleUpdateConsentSubmit}
                    id="submit-update-button"
                    disabled={!canSubmit}
                >
                    {action + ' CONSENT'}
                </Button>
            </DialogActions>
        </div>
    );
};
