import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Typography, Grid, Link} from '@mui/material';
import {IDataContributor, IMonthlyReviewResponse} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {getAlphabeticalComparator} from '~/components/Common/ListModel/comparators';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {LOADING_MESSAGE, MONTHLY_REVIEW_STATUS, LEARN_MORE_LINK} from '~/constants';
import {TableButton} from '~/components/Common/Button/TableButton';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {useGrowl} from '~/utils/hooks/useGrowl';
import './DataContributorList.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setDataContributors} from '~/store/dataContributor/dataContributor.thunk';
import {getDataContributorsFetchState} from '~/store/fetchState/fetchState.selector';
import {
    createMonthlyDataContributorReview,
    getRequiresMonthlyDataContributorReview,
} from '~/services/dataContributorServices';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';

export const DataContributorList = () => {
    const users = useTypedSelector((state) => state.data.admin.dataContributors);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const fetchStatus = useTypedSelector(getDataContributorsFetchState);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();

    useEffect(() => {
        dispatch(setDataContributors());
        getRequiresMonthlyDataContributorReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const handleEdit = (userId: number) => {
        history.push(`/admin/data-contributors/edit/${userId}`);
    };

    const handleCreate = () => {
        history.push(`/admin/data-contributors/create`);
    };

    const handleReviewSubmit = () => {
        createMonthlyDataContributorReview()
            .then(() => {
                openGrowl('Your review has been submitted successfully');
                getRequiresMonthlyDataContributorReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const userDetails = (contributor: IDataContributor) => {
        return (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {contributor.User.Name}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {contributor.User.Designation}
                </Box>
                {!contributor.User.IsActive && <SuspendedIcon />}
            </Box>
        );
    };

    const agencyDetails = (contributor: IDataContributor) => contributor.Agency.Name;

    const userControls = (contributor: IDataContributor) => {
        return <TableButton text="Edit" onClick={() => handleEdit(contributor.Id as number)} />;
    };

    const userNameAndDesignation = (contributor: IDataContributor) =>
        contributor.User.Name + contributor.User.Designation;

    const getUsertListContent = () => {
        const [searchValue, setSearchValue] = useState('');
        const filteredUsers =
            users &&
            users.filter(
                (user) =>
                    user.User.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    user.Agency.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
            );
        const domainHeaderRef = useRef<HTMLDivElement>(null);
        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Data Contributor Management
                        </Typography>
                    </Grid>
                    {monthlyReviewStatus &&
                        monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW &&
                        users.length > 0 && (
                            <ReviewBanner
                                monthlyReviewStatus={monthlyReviewStatus}
                                handleReviewSubmit={handleReviewSubmit}
                                messageCallToAction={
                                    <Link
                                        display="inline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={LEARN_MORE_LINK}
                                    >
                                        Learn more {'>'}
                                    </Link>
                                }
                            />
                        )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="user-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search User Name or Agency Name"
                                type="search"
                                style={{width: 300}}
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary text="Add User" onClick={handleCreate} id="add-user-button" />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {users && users.length > 0 ? (
                            <ListModel
                                columns={[userDetails, agencyDetails, userControls]}
                                headers={['User Name', 'Agency', '']}
                                modelList={filteredUsers}
                                modelComparator={[
                                    getAlphabeticalComparator(userNameAndDesignation),
                                    getAlphabeticalComparator(userNameAndDesignation),
                                    () => 0,
                                ]}
                                variant="paginated"
                                hover
                            />
                        ) : (
                            <EmptyList entity="User" handleClick={handleCreate} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: growl.autoHideDuration,
                            message: growl.message.length > 0 ? growl.message : undefined,
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>
            </>
        );
    };

    const UserListWithAdminLoader = WithAdminLoader<{}>(getUsertListContent, fetchStatus, LOADING_MESSAGE.DATA);
    return <UserListWithAdminLoader />;
};
