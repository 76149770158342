import {Button, Grid, Typography, Tooltip, Popper} from '@mui/material';
import React, {useEffect, useState, useContext} from 'react';
import {useHistory} from 'react-router';
import {ClientSearchForm} from '~/components/Client/ClientSearchForm/ClientSearchForm';
import {getClientBasicInfo, getConsentAndSearchHistory} from '~/services/clientServices';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {withStyles} from '@mui/styles';
import searchClient1 from '~/assets/images/searchClient1.svg';
import searchClient2 from '~/assets/images/searchClient2.svg';
import searchClient3 from '~/assets/images/searchClient3.svg';
import {MonthlySurvey} from '~/components/Client/Survey/MonthlySurvey';
import {AnnouncementBanner, ANNOUNCEMENT_LOCATION} from '~/components/Common/AnnouncementBanner/AnnouncementBanner';
import './Search.scss';
import {useDialogStyles} from '~/pages/Client/ScreenClient/ScreenClient.styles';
import {CustomButtonsDialog} from '~/components/Common/CustomButtonsDialog/CustomButtonsDialog';
import {COPIES, USER_TYPE, USER_FLAGS} from '~/constants';
import {IClientBasicInfo, IClientBasicInfoErrors, IRecentSearchEntry} from '~/interfaces/client';
import {OnboardingModal} from '~/components/Client/OnboardingModal/OnboardingModal';
import {ResourcesToolTipPopper} from './ResourcesToolTipPopper';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setSurveyTrigger} from '~/store/survey/survey.thunk';
import {clientActions} from '~/store/client/client.slice';
import {setUserFlags, updateUserFlags} from '~/store/userFlags/userFlags.thunk';

export const Search = () => {
    const {accessToken} = useContext(AuthenticationContext);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const surveyTrigger = useTypedSelector((state) => state.data.common.surveys.surveyTrigger);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [clientUin, setClientUin] = useState('');
    const firstTimeOnboardingTrigger = useTypedSelector(
        (state) => state.data.common.userFlags[USER_FLAGS.FIRST_TIME_ONBOARDING],
    );
    const [showResourceToolTip, setShowResourceToolTip] = useState(false);

    const resourcesLinkRef = React.useRef<HTMLDivElement>();

    const dialogClasses = useDialogStyles();

    const showConfirmDialog = (uin: string) => {
        setClientUin(uin);
        setConfirmDialogOpen(true);
    };

    const onSubmit = async (uin: string) => {
        setIsLoading(true);
        const clientInfo = {
            RecentSearches: [] as IRecentSearchEntry[],
        } as IClientBasicInfo;

        let basicInfo;
        try {
            basicInfo = await getClientBasicInfo(uin);
        } catch (err) {
            const error = err as Error;
            basicInfo = {
                Errors: {
                    GENERAL: error.message,
                } as IClientBasicInfoErrors,
            };
        }
        const consentAndSearchHistory = await getConsentAndSearchHistory(uin);

        await dispatch(
            clientActions.setSearchedClientInfo({...clientInfo, ...basicInfo, ...consentAndSearchHistory, UIN: uin}),
        );
        history.push('/search/basic-information');
    };

    const getRandomImage = () => {
        const imageMap: {[key: number]: string} = {
            0: searchClient1,
            1: searchClient2,
            2: searchClient3,
        };

        const randomInt = Math.floor(Math.random() * 3);
        return imageMap[randomInt];
    };

    const handleSkip = () => {
        dispatch(updateUserFlags(USER_FLAGS.FIRST_TIME_ONBOARDING));
        setShowResourceToolTip(true);
    };
    const handleResourcesGotIt = () => {
        setShowResourceToolTip(false);
    };
    const handleGetStarted = () => {
        dispatch(updateUserFlags(USER_FLAGS.FIRST_TIME_ONBOARDING));
        setShowResourceToolTip(true);
    };

    useEffect(() => {
        setImage(getRandomImage());
        dispatch(clientActions.clearSearchedClientInfo());
        dispatch(setSurveyTrigger());
        dispatch(setUserFlags());

        const el = document.querySelector('#resourcesLink');
        if (el) {
            resourcesLinkRef.current = el as HTMLDivElement;
        }
    }, []);

    useEffect(() => {
        surveyTrigger && firstTimeOnboardingTrigger && setModalOpen(surveyTrigger.MonthlySurvey);
    }, [surveyTrigger]);

    useEffect(() => {
        !confirmDialogOpen && setConfirmDialogOpen(false);
    }, [confirmDialogOpen]);

    const StyledTooltip = withStyles({
        tooltip: {
            fontSize: '12px',
            fontWeight: 'normal',
        },
    })(Tooltip);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handlerFn = accessToken?.UserType === USER_TYPE.internet ? showConfirmDialog : onSubmit;

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirm = () => {
        handleConfirmDialogClose();
        onSubmit(clientUin);
    };
    return (
        <>
            <Grid container alignItems="center" justifyContent="center">
                <AnnouncementBanner location={ANNOUNCEMENT_LOCATION.USER_SEARCH} />
                <Grid item md={5} xs={12}>
                    <Typography variant="h4" styleName="searchClient">
                        Search Client
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" styleName="subLabel">
                        What is your client&apos;s NRIC/FIN?
                        <StyledTooltip title="Try 'S1234567D'" placement="right">
                            <InfoIcon styleName="infoLabel" />
                        </StyledTooltip>
                    </Typography>

                    <ClientSearchForm disabled={isLoading} onSubmit={handlerFn} />
                </Grid>
                <Grid item md={12} xs={12} style={{textAlign: 'center'}}>
                    <img src={image} alt="searchClient1" styleName="image" data-testid="coverImage" />
                </Grid>
            </Grid>
            {accessToken?.Permissions.UserId && accessToken?.AdditionalUserInfo.Name && (
                <>
                    <OnboardingModal
                        openModal={firstTimeOnboardingTrigger === false}
                        onHandleSkip={handleSkip}
                        onHandleGetStarted={handleGetStarted}
                    />
                    <MonthlySurvey
                        open={modalOpen}
                        onHandleClose={handleModalClose}
                        userName={accessToken.AdditionalUserInfo.Name}
                    />
                </>
            )}
            <CustomButtonsDialog
                classes={dialogClasses}
                open={confirmDialogOpen}
                title={clientUin}
                onClose={handleConfirmDialogClose}
                contentText={COPIES.SEARCH_CONFIRMATION_MESSAGE}
            >
                <Button
                    onClick={() => handleConfirm()}
                    variant="contained"
                    color="primary"
                    className={dialogClasses.buttons}
                    id="confirmSelectionButton"
                >
                    {COPIES.SEARCH_CONFIRMATION_BUTTON_TEXT}
                </Button>
            </CustomButtonsDialog>
            {resourcesLinkRef.current && (
                <Popper
                    open={showResourceToolTip}
                    anchorEl={resourcesLinkRef.current}
                    keepMounted
                    placement="top-start"
                    sx={{
                        zIndex: 101,
                        marginBottom: '16px',
                    }}
                >
                    <ResourcesToolTipPopper onGotIt={handleResourcesGotIt} />
                    <div styleName="arrow-down" />
                </Popper>
            )}
        </>
    );
};
