import React from 'react';
import {Typography, Paper, TableContainer, Table, TableBody, TableRow, TableCell} from '@mui/material';
import {format} from 'date-fns';
import {startCase} from 'lodash';

import {IOpsUnitDataFieldRejectReason} from '~/interfaces/admin';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDataFieldNameState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';

import '../Admin.scss';
import './DataAccessRequestHistory.scss';

interface IRejectedAccessHistoryProps {
    domainId: number;
    subDomainId: number;
    reasons: IOpsUnitDataFieldRejectReason[];
}

export const RejectedAccessHistory = ({domainId, subDomainId, reasons}: IRejectedAccessHistoryProps) => {
    return (
        <>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow styleName="subTableHeaderRow">
                                <TableCell style={{width: '38%'}}>
                                    <Typography component="span" styleName="tableCellFont">
                                        Data Field(s)
                                    </Typography>
                                </TableCell>
                                <TableCell style={{width: '22%'}}>
                                    <Typography component="span" styleName="rejectedByHeader">
                                        Rejected by
                                    </Typography>
                                    <br />
                                    <Typography component="span" styleName="rejectedDateHeader">
                                        Date of rejection
                                    </Typography>
                                </TableCell>
                                <TableCell style={{width: '40%'}}>
                                    <Typography component="span" styleName="tableCellFont">
                                        Reason for Rejection
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {reasons.map((reason, index) => {
                                const fieldNames = reason.Fields?.map((d) =>
                                    useTypedSelector(getDataFieldNameState(domainId, subDomainId, d.DataFieldId)),
                                );
                                const rejectedTime = format(new Date(reason.RejectedOn), 'dd MMM yyyy h.mmaa');
                                return (
                                    <TableRow key={index}>
                                        <TableCell style={{width: '38%'}}>
                                            <Typography component="span" styleName="tableCellFont">
                                                {fieldNames?.sort().join(', ')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{width: '22%'}}>
                                            <Typography component="span" styleName="tableCellFont">
                                                {startCase(reason.RejectionBy?.UserName || '')}
                                            </Typography>
                                            <br />
                                            <Typography component="span" styleName="rejectedDateField">
                                                {rejectedTime}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{width: '40%'}}>
                                            <Typography component="span" styleName="tableCellFont">
                                                {reason.Reason}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};
