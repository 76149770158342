import {API_FETCH_STATE} from '~/constants';
import {IFetchStatus} from '~/interfaces/common';
import {createSelector} from 'reselect';
import {IAppState} from '~/store';
export type FetchStates = IFetchStatus[];

// Checks all the API_FETCH_STATE in the array and returns the highest order
// state.
// ERROR > PENDING > SUCCESS
export const consolidateFetchState = (fetchStates: FetchStates) => {
    let pendingFlag = false;
    fetchStates.forEach((fetchState) => {
        if (fetchState === API_FETCH_STATE.ERROR) {
            return fetchState;
        } else if (fetchState === API_FETCH_STATE.PENDING || fetchState === null) {
            pendingFlag = true;
        }
    });
    if (pendingFlag) return API_FETCH_STATE.PENDING;
    return API_FETCH_STATE.SUCCESS;
};

// TODO: Replace consolidateFetchState to getConsolidateFetchState
export const getConsolidateFetchState = (selectors: {(state: IAppState): IFetchStatus}[]) =>
    createSelector(selectors, (...fetchStates) => {
        let pendingFlag = false;
        fetchStates.forEach((fetchState) => {
            if (fetchState === API_FETCH_STATE.ERROR) {
                return fetchState;
            } else if (fetchState === API_FETCH_STATE.PENDING || fetchState === null) {
                pendingFlag = true;
            }
        });
        if (pendingFlag) return API_FETCH_STATE.PENDING;
        return API_FETCH_STATE.SUCCESS;
    });
