import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {objNoResults, formatDateField, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export type IFosterChildrenData = IFosterChild[];

type IHeadRowData = Pick<IFosterChild, 'parsedStartDate'>;

interface IFosterChild {
    Name: string;
    StartDate: string;
    parsedStartDate?: number | string;
}

interface IFosterChildrenRows {
    sortedRows: IFosterChildrenData;
}

export const FosterChildrenRows = ({sortedRows}: IFosterChildrenRows) => {
    const getLabelFromIndex = (index: number) => {
        const counter = index + 1;
        return counter < 10 ? `0${counter}` : counter;
    };

    return (
        <>
            {sortedRows.map((row, index) => {
                const dateParts = row.StartDate.split('/');
                return (
                    <TableRow tabIndex={-1} key={index}>
                        <TableCell style={{width: '20px'}}>{getLabelFromIndex(index)}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell align="right">
                            {isNotEmpty(row.StartDate)
                                ? formatDateField(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`, false, true)
                                : row.StartDate}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

// Transform DD/MM/YYYY string date string format to DD
const parseStartDate = (date: string) => {
    const dateParts = date.split('/');
    return new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`).getTime();
};

export const FosterChildren = ({data}: {data: IFosterChildrenData}) => {
    const noResults = objNoResults(data);

    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {align: 'left', disablePadding: true, label: ''},
        {align: 'left', disablePadding: false, label: 'Name of Child'},
        {id: 'parsedStartDate', align: 'right', disablePadding: false, label: 'Start Date of Fostering'},
    ];

    data = data.map((child) => {
        return {
            ...child,
            parsedStartDate: isNotEmpty(child.StartDate) ? parseStartDate(child.StartDate) : child.StartDate,
        };
    });

    const headerProps = {
        columns: headColumns,
        customStyle: {
            backgroundColor: '#5c6bc0',
            color: '#ffffff',
        },
    };

    const hocProps = {
        rows: data,
        colSpan: 3,
        headerProps,
    };

    const props = {
        ...headerProps,
        ...hocProps,
        dataTestId: 'fosterParentTable',
    };

    const EnhancedTable = WithEnhancedTable<IFosterChildrenRows, IHeadRowData, IFosterChild>(
        FosterChildrenRows,
        'parsedStartDate',
        data,
        'desc',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        TABLE_TYPE.SIMPLE,
    );
    return noResults ? <NoData /> : <EnhancedTable {...props} />;
};
