/* eslint-disable react/display-name */
import {
    AssignmentIndRounded as AssignmentIndIcon,
    CheckCircle as ConsentIcon,
    DashboardRounded as DashboardIcon,
    EmailRounded as EmailIcon,
    FolderShared as FolderSharedIcon,
    GroupRounded as GroupIcon,
    CloudUpload as UploadIcon,
} from '@mui/icons-material';
import React from 'react';
import {ChecklistIcon, ReportIcon} from '~/components/Common/Icons';
import {config} from '~/config';
import {ACCESS_LEVELS} from '~/constants';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {isOpsUnitAdmin, isRole, isTeamAdmin} from '~/utils/roleUtils';
import './AdminSideBar.scss';

export const SELECTED_COLOR = '#3949ab';
export const INACTIVE_COLOR = '#3c3c3c';

export interface INavigationLink {
    name: string;
    path: string;
    isSelected: (currentPath: string, hash?: string) => boolean;
    isShowChildren?: (currentPath: string) => boolean;
    icon?: (isSelected: boolean) => JSX.Element;
    hash?: string;
    children?: INavigationLink[];
    dropdownChildren?: INavigationLink[]; // specifically for links that need to be rendered in a dropdown of a parent sidebar tab
}

const Dashboard = (): INavigationLink => {
    const path = '/admin/dashboard';
    return {
        name: 'Dashboard',
        path: path,
        isSelected: (currentPath: string) => currentPath == path,
        icon: (isSelected: boolean) => <DashboardIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

const getEntityChild = (adminPrefix: string): INavigationLink => {
    return {
        name: `${adminPrefix} Details`,
        path: '',
        isSelected: (_: string, hash: string | undefined) => (hash ? hash.includes(`#details`) : false),
        hash: 'details',
    };
};

const getAdminChildren = (adminPrefix: string): INavigationLink[] => {
    return [
        {
            name: `${adminPrefix} Admin 1`,
            path: '',
            isSelected: (_: string, hash: string | undefined) => (hash ? hash.includes(`#admin1`) : false),
            hash: `admin1`,
        },
        {
            name: `${adminPrefix} Admin 2`,
            path: '',
            isSelected: (_: string, hash: string | undefined) => (hash ? hash.includes(`#admin2`) : false),
            hash: `admin2`,
        },
    ];
};

const getExpandedDamChildren = (): INavigationLink[] => {
    const dataAccessManagementPath = `/admin/data-access-matrix`;
    const dataAccessManagement2Path = `/admin/dam2-data-access-matrix`;
    const dataAccessRequestWindowPath = `/admin/data-access-request-window`;
    const dataContributingAgencyPath = `/admin/data-contributing-agency`;
    const dataFieldManagementPath = `/admin/data-field-management`;

    const navigationLinks: INavigationLink[] = [
        {
            name: 'Data Access Management',
            path: dataAccessManagementPath,
            isSelected: (currentPath: string) =>
                currentPath === dataAccessManagementPath ||
                new RegExp(`^${dataAccessManagementPath}/ops-units/[0-9]`).test(currentPath) ||
                new RegExp(`^${dataAccessManagement2Path}/ops-units/[0-9]`).test(currentPath),
        },
        {
            name: 'Data Access Request Window',
            path: dataAccessRequestWindowPath,
            isSelected: (currentPath: string) => currentPath.startsWith(dataAccessRequestWindowPath),
        },
    ];
    if (config.featureFlags.ENABLE_DAM2_DCA) {
        navigationLinks.push({
            name: 'Data Contributing Agency',
            path: dataContributingAgencyPath,
            isSelected: (currentPath: string) => currentPath.startsWith(dataContributingAgencyPath),
        });
    }
    if (config.featureFlags.ENABLE_DAM2_DFM) {
        navigationLinks.push({
            name: 'Data Field Management',
            path: dataFieldManagementPath,
            isSelected: (currentPath: string) => currentPath.startsWith(dataFieldManagementPath),
        });
    }
    return navigationLinks;
};

export const SystemAdminManagement = (): INavigationLink => {
    const path = '/admin/system-admins';
    return {
        name: 'System Admin Management',
        path: path,
        isSelected: (currentPath: string) =>
            currentPath === path || currentPath === `${path}/create` || new RegExp(`^${path}/[0-9]`).test(currentPath),
        isShowChildren: (currentPath: string) =>
            currentPath === `${path}/create` || new RegExp(`^${path}/[0-9]`).test(currentPath),
        icon: (isSelected: boolean) => <AssignmentIndIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

export const AdminManagement = (accessToken: IAccessToken): INavigationLink => {
    const path = () =>
        isRole(accessToken, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) ||
        isRole(accessToken, ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR)
            ? '/admin/agencies'
            : isRole(accessToken, ACCESS_LEVELS.AGENCY_ADMINISTRATOR)
            ? `/admin/agencies/${accessToken.Permissions.AgencyId}/ops-units`
            : isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR) ||
              isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER)
            ? `/admin/ops-units/${accessToken.Permissions.OpsUnitId}/teams`
            : '';

    const adminPrefix =
        isRole(accessToken, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) ||
        isRole(accessToken, ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR)
            ? 'Agency'
            : isRole(accessToken, ACCESS_LEVELS.AGENCY_ADMINISTRATOR)
            ? `Ops Unit`
            : isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR) ||
              isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER)
            ? `Team`
            : '';

    const children = (): INavigationLink[] => {
        switch (accessToken.Permissions.AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return getAdminChildren(adminPrefix);
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return [getEntityChild(adminPrefix), ...getAdminChildren(adminPrefix)];
            default:
                return [];
        }
    };

    return {
        name: 'Admin Management',
        path: path(),
        isSelected: (currentPath: string) =>
            currentPath === path() ||
            currentPath === `${path()}/create` ||
            new RegExp(`^${path()}/[0-9]`).test(currentPath),
        isShowChildren: (currentPath: string) =>
            currentPath === `${path()}/create` || new RegExp(`^${path()}/[0-9]`).test(currentPath),
        icon: (isSelected: boolean) => <AssignmentIndIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
        children: children(),
    };
};

const UserManagement = (accessToken: IAccessToken): INavigationLink => {
    const path = () =>
        isOpsUnitAdmin(accessToken)
            ? `/admin/ops-units/${accessToken.Permissions.OpsUnitId}/users`
            : isTeamAdmin(accessToken)
            ? `/admin/teams/${accessToken.Permissions.TeamId}/users`
            : '';

    return {
        name: 'User Management',
        path: path(),
        isSelected: (currentPath: string) =>
            currentPath == path() ||
            currentPath === `${path()}/create` ||
            new RegExp(`^${path()}/[0-9]`).test(currentPath),
        icon: (isSelected: boolean) => <GroupIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

const ConsentManagement = (): INavigationLink => {
    const path = '/admin/client-consent-history';
    return {
        name: 'Consent Management',
        path: path,
        isSelected: (currentPath: string) => currentPath == path,
        icon: (isSelected: boolean) => <ConsentIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

const Reports = (): INavigationLink => {
    const path = '/reports';

    return {
        name: 'Reports',
        path: path,
        isSelected: (currentPath: string) => currentPath == path,
        icon: (isSelected: boolean) => <ReportIcon fill={isSelected ? SELECTED_COLOR : INACTIVE_COLOR} />,
    };
};

const MassEmail = (): INavigationLink => {
    const path = '/admin/mass-email';

    return {
        name: 'Mass Email',
        path: path,
        isSelected: (currentPath: string) => currentPath == path,
        icon: (isSelected: boolean) => <EmailIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

const AnnouncementManagement = (): INavigationLink => {
    const path = '/admin/announcements';

    return {
        name: 'Announcement Management',
        path: path,
        isSelected: (currentPath: string) => currentPath.startsWith(path),
        icon: (isSelected: boolean) => <AssignmentIndIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

export const DataUpload = (): INavigationLink => {
    const path = '/data-upload';

    return {
        name: 'Data Upload',
        path: path,
        isSelected: (currentPath: string) => currentPath == path,
        icon: (isSelected: boolean) => <UploadIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

export const DataContributorManagement = (): INavigationLink => {
    const path = '/admin/data-contributors';

    return {
        name: 'Data Contributor Management',
        path: path,
        isSelected: (currentPath: string) =>
            currentPath == path ||
            currentPath == `${path}/create` ||
            new RegExp(`^${path}/edit/[0-9]`).test(currentPath),
        icon: (isSelected: boolean) => <FolderSharedIcon styleName={isSelected ? 'selected' : 'cardIcon'} />,
    };
};

export const ExpandedDataAccessMatrix = (): INavigationLink => {
    return {
        name: 'Data Access',
        path: '',
        isSelected: () => false,
        dropdownChildren: getExpandedDamChildren(),
    };
};

export const DataAccessManagement = (opsUnitId?: number): INavigationLink => {
    const path = `/admin/data-access-management/ops-unit/${opsUnitId}`;

    return {
        name: 'Data Access Management',
        path: path,
        isSelected: (currentPath: string) => currentPath === path,
        icon: (isSelected: boolean) => <ChecklistIcon fill={isSelected ? SELECTED_COLOR : INACTIVE_COLOR} />,
    };
};

export const DataAccessManagementOpsUnits = (): INavigationLink => {
    const path = `/admin/data-access-management/ops-units`;

    return {
        name: 'Data Access Management',
        path: path,
        isSelected: (currentPath: string) => currentPath === path,
        icon: (isSelected: boolean) => <ChecklistIcon fill={isSelected ? SELECTED_COLOR : INACTIVE_COLOR} />,
    };
};

export const DataAccessMatrix = (): INavigationLink => {
    const path = '/admin/data-access-matrix';

    return {
        name: 'Data Access Matrix',
        path: path,
        isSelected: (currentPath: string) =>
            currentPath === path || new RegExp(`^${path}/ops-units/[0-9]`).test(currentPath),
        icon: (isSelected: boolean) => <ChecklistIcon fill={isSelected ? SELECTED_COLOR : INACTIVE_COLOR} />,
    };
};

export const GetAdminNavigation = (accessToken: IAccessToken): INavigationLink[] => {
    let sidebarIcons;
    switch (accessToken.Permissions.AccessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
            sidebarIcons = [Dashboard()];
            if (accessToken.Permissions.AccessLevel == ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR) {
                sidebarIcons = [...sidebarIcons, SystemAdminManagement()];
            }
            sidebarIcons = [
                ...sidebarIcons,
                AdminManagement(accessToken),
                ConsentManagement(),
                config.featureFlags.ENABLE_DAM2 ? ExpandedDataAccessMatrix() : DataAccessMatrix(),
                Reports(),
                MassEmail(),
                AnnouncementManagement(),
                DataContributorManagement(),
            ];
            if (
                accessToken.Permissions.DataUploadAllowedFor &&
                accessToken.Permissions.DataUploadAllowedFor.length > 0
            ) {
                sidebarIcons.push(DataUpload());
            }
            return sidebarIcons;
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            sidebarIcons = [Dashboard(), AdminManagement(accessToken), Reports(), MassEmail()];
            if (config.featureFlags.ENABLE_DAM2_VIEW) {
                sidebarIcons = [...sidebarIcons.slice(0, 2), DataAccessManagementOpsUnits(), ...sidebarIcons.slice(2)];
            }
            if (
                accessToken.Permissions.DataUploadAllowedFor &&
                accessToken.Permissions.DataUploadAllowedFor.length > 0
            ) {
                sidebarIcons.push(DataUpload());
            }
            return sidebarIcons;
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            sidebarIcons = [
                Dashboard(),
                AdminManagement(accessToken),
                UserManagement(accessToken),
                Reports(),
                MassEmail(),
            ];
            if (config.featureFlags.ENABLE_DAM2_VIEW) {
                sidebarIcons = [
                    ...sidebarIcons.slice(0, 3),
                    DataAccessManagement(accessToken?.Permissions.OpsUnitId),
                    ...sidebarIcons.slice(3),
                ];
            }
            if (
                accessToken.Permissions.DataUploadAllowedFor &&
                accessToken.Permissions.DataUploadAllowedFor.length > 0
            ) {
                sidebarIcons.push(DataUpload());
            }
            return sidebarIcons;
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
        case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
            sidebarIcons = [UserManagement(accessToken), Reports(), MassEmail()];
            if (
                accessToken.Permissions.DataUploadAllowedFor &&
                accessToken.Permissions.DataUploadAllowedFor.length > 0
            ) {
                sidebarIcons.push(DataUpload());
            }
            return sidebarIcons;
        case ACCESS_LEVELS.DATA_UPLOADER_APP_USER:
        case ACCESS_LEVELS.DATA_UPLOADER:
            return [DataUpload()];
        default:
            return [];
    }
};
