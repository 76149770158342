import React from 'react';
import {Container, LinearProgress, Typography} from '@mui/material';

import './AdminProgressBar.scss';

export interface IProgressBar {
    label?: string;
}
export const AdminProgressBar = ({label}: IProgressBar) => {
    return (
        <Container styleName="container" maxWidth="sm">
            {label && (
                <Typography variant="subtitle1" align="center" styleName="progressBarLabel">
                    {label}
                </Typography>
            )}

            <LinearProgress variant="indeterminate" styleName="progressBar" />
        </Container>
    );
};
