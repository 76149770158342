import React from 'react';
import {Grid} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {dateFormatter} from '~/utils/dateUtils';
import {allNoResults, extractAllValues, formatValue} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';

export interface ILongTermVisitorPass {
    LtvpExpiryDate: string;
    LtvpIssueDate: string;
    LtvpStatus: string;
}

export const LongTermVisitorPass: React.FunctionComponent<{data: ILongTermVisitorPass}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    const fields = [
        {label: 'Pass Status', value: data.LtvpStatus},
        {
            label: 'Expiry Date',
            value: formatValue(data.LtvpExpiryDate, dateFormatter),
        },
        {
            label: 'Issue Date',
            value: formatValue(data.LtvpIssueDate, dateFormatter),
        },
    ];

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <Grid container spacing={3}>
                    <SimpleGridDisplay fields={fields} />
                </Grid>
            )}
        </>
    );
};
