import React from 'react';
import {Grid} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import '../Common/Common.scss';
import {dateFormatter} from '~/utils/dateUtils';
import {formatValue, isNotEmpty} from '~/utils/contentUtils';

export interface ICurrentFlatDetails {
    OpenMarketRentalIndicator: string;
}

export interface ICurrentFlatDetailsDOS {
    UnitNumber: string;
    PostalCode: string;
    BlockNumber: string; // not used
    DwellingType: string; // not used
    StreetName: string; // not used

    PurchaseDate: string;
    OwnershipTransferDate: string;
    LeaseCommencementDate: string;
    LeaseTermsTenure: string;
}

export const CurrentFlatDetails: React.FunctionComponent<{
    data?: ICurrentFlatDetails;
    dosData?: ICurrentFlatDetailsDOS;
    toDisplayDos: boolean;
}> = ({data, dosData, toDisplayDos}) => {
    if (!data && !toDisplayDos) return <></>;

    const basicCurrentFlatDetails = [
        {
            label: 'Indicator of Open Market Rental',
            value: data?.OpenMarketRentalIndicator,
        },
    ];

    const dosCurrentFlatDetails = [
        {
            label: 'Purchased Date',
            value: dosData?.PurchaseDate && formatValue(dosData.PurchaseDate, dateFormatter),
        },
        {
            label: 'Date of Flat Ownership Transfer',
            value: dosData?.OwnershipTransferDate && formatValue(dosData.OwnershipTransferDate, dateFormatter),
        },
        {
            label: 'Lease Commencement Date',
            value: dosData?.LeaseCommencementDate && formatValue(dosData.LeaseCommencementDate, dateFormatter),
        },
        {
            label: 'Lease Tenure',
            value:
                dosData?.LeaseTermsTenure && isNotEmpty(dosData.LeaseTermsTenure)
                    ? dosData.LeaseTermsTenure + ' Years'
                    : dosData?.LeaseTermsTenure,
        },
    ];

    const currentFlatDetails = toDisplayDos
        ? data
            ? [...dosCurrentFlatDetails, ...basicCurrentFlatDetails]
            : dosCurrentFlatDetails
        : basicCurrentFlatDetails;

    return (
        <Grid data-testid="currentFlatDetails" container spacing={3} styleName="content">
            <SimpleGridDisplay fields={currentFlatDetails} />
        </Grid>
    );
};
