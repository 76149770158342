import React from 'react';
import {Grid, Typography} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './MortgageDetails.scss';
import {getYearMonthFormat} from '~/utils/dateUtils';
import {currencyFormatter} from '~/utils/currencyUtils';
import {getAlphabeticalComparator, getNumericComparator} from '~/components/Common/ListModel/comparators';
import {formatValue, isNotEmpty} from '~/utils/contentUtils';
import {FIELD_VALUES} from '~/constants';

export interface IMortgageDetails {
    TotalMonthlyPayment: string;
    MonthlyPaymentByCPF: string;
    AccumulatedAmount: string;
    MonthsAccumulated: string;
}

interface IMortgageTableData {
    Id?: number;
    MortageInformation: string;
    Amount: string;
}

export const LABEL_ACCUMULATED_AMOUNT = 'Household Accumulated Amount of Mortgage Arrears';

export const MortgageDetails: React.FunctionComponent<{data?: IMortgageDetails}> = ({data}) => {
    if (!data) return <></>;

    const formattedTableData = [
        {
            Id: 1,
            MortageInformation: 'Total Monthly Mortgage Payment Amount',
            Amount: formatValue(data.TotalMonthlyPayment, currencyFormatter),
        },
        {
            Id: 2,
            MortageInformation: 'Monthly Mortgage Payment by CPF',
            Amount: formatValue(data.MonthlyPaymentByCPF, currencyFormatter),
        },
        {
            Id: 3,
            MortageInformation: LABEL_ACCUMULATED_AMOUNT,
            Amount: formatValue(data.AccumulatedAmount, currencyFormatter),
        },
    ];
    const mortageInformation = (info: IMortgageTableData) => {
        if (info.MortageInformation == LABEL_ACCUMULATED_AMOUNT) {
            return (
                <>
                    <Typography data-testid="label_accumulated" variant="body2">
                        {LABEL_ACCUMULATED_AMOUNT}
                    </Typography>
                    {data.MonthsAccumulated !== FIELD_VALUES.NO_ACCESS && (
                        <Typography data-testid="caption_accumulated" variant="caption" className={styles.caption}>
                            {isNotEmpty(data.MonthsAccumulated) &&
                                `Accumulated for ${getYearMonthFormat(data.MonthsAccumulated)}`}
                        </Typography>
                    )}
                </>
            );
        }
        return info.MortageInformation;
    };
    const amount = (info: IMortgageTableData) => <div>{info.Amount}</div>;

    const mortageInformationValue = (info: IMortgageTableData) => info.MortageInformation;
    const amountValue = (info: IMortgageTableData): number => +info.Amount;
    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.customTable}>
                <ListModel
                    columns={[mortageInformation, amount]}
                    headers={['Mortage Information', 'Amount ($)']}
                    modelList={formattedTableData}
                    modelComparator={[
                        getAlphabeticalComparator(mortageInformationValue),
                        getNumericComparator(amountValue),
                    ]}
                    variant="expanded"
                    defaultSortOrder="desc"
                />
            </div>
        </Grid>
    );
};
