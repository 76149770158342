import React from 'react';
import {FixedMessage} from '~/components/Common/IconMessage/IconMessage';
import {COPIES} from '~/constants';
import {ComcareAssistanceTable} from '../ComcareAssistance/ComcareAssistanceTable';
import {IComcare2Assistance} from '../ComcareAssistance/interfaces';

export type ISCFAData = IComcare2Assistance[];

export const SCFA: React.FunctionComponent<{data: ISCFAData}> = ({data}) => {
    return (
        <>
            <FixedMessage message={COPIES.COMCARE_FIXED_MESSAGE} />
            <ComcareAssistanceTable data={data} data-testid="scfa" type="comcare2" />
        </>
    );
};
