import React from 'react';
import {Typography, Grid} from '@mui/material';
import {SubDomainStatus} from '~/interfaces/client';
import {renderTextByStatus} from '../SubDomainDisplay';
import {NoData} from '../CompletedSubDomain';
import upperFirst from 'lodash/upperFirst';
import toLower from 'lodash/toLower';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import './WelfareHomes.scss';
import {allNoResults, extractAllValues, formatDateField, isNoAccess} from '~/utils/contentUtils';

export type IWelfareHomeData = IWelfareHome;

interface IWelfareHome {
    Status: string;
    StartDate?: string;
    WelfareHomeCaseHistoryList: IWefareHomeHistory[];
}

interface IWefareHomeHistory {
    AdmissionDate: string;
    AdmissionType: string;
    CaseStatus: string;
    WelfareHome: string;
    DischargeDate: string;
}

const welfareHomeToFields = (welfareHome: IWefareHomeHistory) => {
    const {WelfareHome, CaseStatus, AdmissionDate, AdmissionType, DischargeDate} = welfareHome;

    return [
        {
            label: 'Welfare Home',
            value: <Typography variant="h6">{WelfareHome}</Typography>,
            fullWidth: true,
        },
        {label: 'Case Status', value: CaseStatus, fullWidth: true},
        {label: 'Admission Date', value: formatDateField(AdmissionDate, true, true)},
        {label: 'Admission Type', value: AdmissionType},
        {
            label: 'Discharged Date',
            value: formatDateField(DischargeDate, true, true),
        },
    ];
};

export const WelfareHomes: React.FunctionComponent<{data: IWelfareHomeData}> = ({data}) => {
    const allFields = extractAllValues(data);
    const noData = allNoResults(allFields);
    // const welfareHomeCaseHistory = noData ? [] : data.WelfareHomeCaseHistoryList;
    // Recent admission dates first
    const sortedWelfareHomeCaseHistory = data.WelfareHomeCaseHistoryList.slice().sort((wfA, wfB) => {
        return new Date(wfB.AdmissionDate).getTime() - new Date(wfA.AdmissionDate).getTime();
    });

    const fieldsToRender = sortedWelfareHomeCaseHistory.map(welfareHomeToFields);

    return noData ? (
        <NoData />
    ) : (
        <>
            <Grid data-testid="investigation" container spacing={3}>
                <Grid item xs={12}>
                    <Typography data-testid="investigationStatus" styleName="fieldName" variant="subtitle1">
                        {isNoAccess(data.Status) ? (
                            ''
                        ) : (
                            <>
                                Social Investigation Status:{' '}
                                {renderTextByStatus(
                                    upperFirst(toLower(data.Status)),
                                    data.Status === 'CLOSED' ? SubDomainStatus.ERROR : SubDomainStatus.COMPLETED,
                                )}
                            </>
                        )}
                        {data.StartDate
                            ? isNoAccess(data.StartDate)
                                ? ''
                                : ' | Since ' + formatDateField(data.StartDate, true, true)
                            : ''}
                    </Typography>
                </Grid>
                <Grid item xs={12} />
            </Grid>

            <MultiGridDisplay
                dataFieldGroups={fieldsToRender}
                dataTestId="welfareHomeCaseHistory"
                displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
            />
        </>
    );
};
