import {Grid, Typography} from '@mui/material';
import React from 'react';
import {COLORS} from '~/components/Common/Colors';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {IComcare2BenefitInfo} from '../ComcareAssistance/interfaces';
import {NoData} from '../CompletedSubDomain';
import styles from './HopeScheme.scss';
import {formatDateField, objNoAccess, objNoResults} from '~/utils/contentUtils';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';

export type IHopeSchemesData = IHopeScheme;

interface IHopeScheme {
    CaseReferenceNo: string;
    CaseLocationName: string;
    Indicator: string;
    CaseStatus: string;
    CaseApprovedDate: string;
    CaseClosureReason: string;
    CaseWorkerName: string;
    BenefitInfo: IComcare2BenefitInfoListModel[];
}

interface IComcare2BenefitInfoListModel extends IComcare2BenefitInfo {
    Id: number;
}

export const HopeScheme: React.FunctionComponent<{data: IHopeSchemesData}> = ({data}) => {
    const {
        CaseReferenceNo,
        CaseLocationName,
        Indicator,
        CaseStatus,
        CaseApprovedDate,
        CaseClosureReason,
        CaseWorkerName,
    } = data;
    const caseInfo = [
        {
            label: 'Case Reference Number',
            value: <Typography variant="h6">{CaseReferenceNo}</Typography>,
            fullWidth: true,
        },
        {label: 'Location Name', value: CaseLocationName},
        {
            label: 'Applicant Indicator',
            value: Indicator,
        },
        {
            label: 'Application Status',
            value:
                CaseStatus == 'Active' ? (
                    <Typography variant="body1" component="span">
                        <p style={{display: 'inline', color: COLORS.GREEN, fontWeight: 'bold'}}>Active</p>
                        <p style={{display: 'inline'}}>{CaseApprovedDate && ` - Approved`}</p>
                    </Typography>
                ) : (
                    CaseStatus
                ),
        },
        {
            label: 'Approval or Closure Date',
            value: formatDateField(CaseApprovedDate, true, true),
        },
        {
            label: 'Latest Intervention Closure Reason',
            value: CaseClosureReason,
        },
        {
            label: 'Name of Caseworker',
            value: CaseWorkerName,
        },
    ];
    const assistanceStartDate = (benefitInfo: IComcare2BenefitInfoListModel) =>
        formatDateField(benefitInfo.AssistanceStartDate);
    const benefitType = (benefitInfo: IComcare2BenefitInfoListModel) => benefitInfo.BenefitType;
    const beneficiaryNames = (benefitInfo: IComcare2BenefitInfoListModel) => benefitInfo.BeneficiaryNames;
    const amount = (benefitInfo: IComcare2BenefitInfoListModel) => benefitInfo.Amount;

    const sortedBenefitInfo =
        data.BenefitInfo &&
        data.BenefitInfo.slice().sort(
            (info1, info2) =>
                new Date(info2.AssistanceStartDate).getTime() - new Date(info1.AssistanceStartDate).getTime(),
        );

    if (objNoResults(data)) {
        return <NoData />;
    }

    return (
        <div id={styles.table}>
            <Grid data-testid="hopeSchemeHeader" container spacing={3} style={{marginBottom: 10}}>
                <Grid item xs={12} container alignItems="center" style={{color: 'var(--primary-grey)'}}>
                    <InfoIcon data-testid="headerIcon" style={{paddingRight: 10}} />
                    <Typography data-testid="headerText" variant="body1" display="inline">
                        History records retrieved are assistance received in the last 2 years.
                    </Typography>
                </Grid>
            </Grid>
            <Grid data-testid="HopeSchemeCaseInfo" container spacing={3}>
                <SimpleGridDisplay fields={caseInfo} />
            </Grid>

            {!objNoAccess(data.BenefitInfo) && (
                <>
                    <Typography variant="h6" style={{margin: '24px 0 24px'}}>
                        Benefit Details
                    </Typography>
                    {data.BenefitInfo && !objNoResults(data.BenefitInfo) ? (
                        <ListModel
                            columns={[assistanceStartDate, benefitType, beneficiaryNames, amount]}
                            headers={['Assistance Date', 'Benefit Type', 'Beneficiaries', 'Total Amount ($)']}
                            modelList={sortedBenefitInfo}
                            modelComparator={[() => 0, () => 0, () => 0, () => 0]}
                            variant="expanded"
                            sortable={false}
                            dataTestId="benefitTable"
                        />
                    ) : (
                        <NoData />
                    )}
                </>
            )}
        </div>
    );
};
