import React from 'react';
import {Typography} from '@mui/material';
import './DataField.scss';

interface IDataField {
    label: string | React.ReactElement;
    value: string | React.ReactElement;
}

export const DataField = ({label, value}: IDataField) => {
    return (
        <>
            {typeof label === 'string' ? (
                <Typography variant="body2" styleName="label">
                    {label}
                </Typography>
            ) : (
                label
            )}
            {typeof value === 'string' ? <Typography variant="body1">{value}</Typography> : value}
        </>
    );
};
