import React from 'react';
import {Button, Card, CardContent, Container, Typography} from '@mui/material';
import logoutCover from '~/assets/images/logoutCover.svg';
import './LogoutView.scss';
import {useHistory} from 'react-router-dom';

export interface ILogoutView {
    logoutMessage?: string;
}

export const LogoutView = ({logoutMessage}: ILogoutView) => {
    const history = useHistory();

    return (
        <Container
            fixed
            style={{
                marginBottom: '-30px',
            }}
        >
            <Card styleName="card">
                <CardContent>
                    <img src={logoutCover} alt="Logout Cover" styleName="image" />
                    <Typography variant="h6" gutterBottom color="primary" styleName="header">
                        Thank you for using OneCV.
                    </Typography>
                    {logoutMessage && (
                        <Typography data-testid="logoutMessageTypo" variant="body2" styleName="message">
                            {logoutMessage}
                        </Typography>
                    )}
                    <Button
                        color="primary"
                        id="setLocationButton"
                        data-testid="setLocationButton"
                        variant="contained"
                        styleName="setLocationButton"
                        onClick={() => history.push('/login')}
                    >
                        LOG IN AGAIN
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};
