import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import * as service from '~/services/dataDictionaryServices';
import {IDataSource} from '~/interfaces/admin';

export const setDataSource = createAsyncThunk<IDataSource[] | void, void, {state: RootState}>(
    '/dataSources/setDataSource',
    async (_, {getState, dispatch}) => {
        if (!getState().data.flags[Flag.DATA_SOURCE]) {
            dispatch(fetchStateActions.SET_DATA_SOURCE_FETCH_STATE(API_FETCH_STATE.PENDING));
            try {
                const dataSources = await service.getDataSources();
                dispatch(fetchStateActions.SET_DATA_SOURCE_FETCH_STATE(API_FETCH_STATE.SUCCESS));
                dispatch(hydrateFlagActions.UPDATE_FLAG(Flag.DATA_SOURCE));
                return dataSources;
            } catch (err) {
                dispatch(fetchStateActions.SET_DATA_SOURCE_FETCH_STATE(API_FETCH_STATE.ERROR));
                throw err;
            }
        }
    },
);

export const createDataSource = createAsyncThunk<IDataSource, IDataSource, {state: RootState}>(
    '/dataSources/createDataSource',
    async (dataSource, {rejectWithValue}) => {
        try {
            const res = await service.createDataSource(dataSource);
            return res;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const editDataSource = createAsyncThunk<IDataSource, IDataSource, {state: RootState}>(
    '/dataSources/editDataSource',
    async (dataSource, {rejectWithValue, dispatch}) => {
        try {
            dispatch({type: hydrateFlagActions.DELETE_FLAG.type, payload: Flag.DAM_FIELDS});
            await service.editDataSource(dataSource);
            return dataSource;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const deleteDataSource = createAsyncThunk<number | undefined, IDataSource, {state: RootState}>(
    '/dataSources/deleteDataSource',
    async (dataSource, {rejectWithValue}) => {
        try {
            await service.deleteDataSource(dataSource.Id);
            return dataSource.Id;
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error.message);
        }
    },
);
