import React, {useState} from 'react';
import {Grid, Tab, Tabs, Typography} from '@mui/material';
import {AccessReason} from './AccessReason';
import {IFamilyMember} from '~/interfaces/client';
import {IAccessReasons} from '~/interfaces/clientUser';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {COPIES, Relationship, USER_FLAGS} from '~/constants';
import {TabPanel} from '~/components/Common/TabPanel';
import {ConsentAndAccessHeader} from '../ConsentAndAccessHeader';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {isReasonsInvalid} from '../ConsentAndAccess';
import './FamilyAccessReasons.scss';

export interface IFamilyAccessReasonsProps {
    selectedFamilyMembers: IFamilyMember[];
    familyAccessReasons: {[key: string]: IAccessReasons};
    legislativelyLockedFields: IRowData[];
    onDeleteSubDomain: (subDomain: IRowData) => void;
    onAccessReasonsChange: (member: IFamilyMember, accessReason: IAccessReasons) => void;
    onApplyAllReasons: (accessReason: IAccessReasons) => void;
}

export const FamilyAccessReasons = ({
    selectedFamilyMembers,
    familyAccessReasons,
    legislativelyLockedFields,
    onDeleteSubDomain,
    onAccessReasonsChange,
    onApplyAllReasons,
}: IFamilyAccessReasonsProps) => {
    const [tabValue, setTabValue] = useState<number>(0);

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const renderTabLabel = (familyMember: IFamilyMember) => {
        const reasonIsValid = !isReasonsInvalid(familyAccessReasons[familyMember.UIN]);
        return (
            <Grid container styleName="tabLabel">
                <Grid item xs={10}>
                    <Typography styleName="nameOverflow">{familyMember.Name}</Typography>
                    {familyMember.Relationship !== Relationship.UNKNOWN && (
                        <Typography variant="body2" styleName="relationship">
                            {familyMember.Relationship}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={2}>
                    {reasonIsValid && <CheckCircleRoundedIcon color="primary" styleName="tabIcon" />}
                </Grid>
            </Grid>
        );
    };

    const handleAccessReasonsChange = (familyMember: IFamilyMember, accessReasons: IAccessReasons) => {
        onAccessReasonsChange(familyMember, accessReasons);
    };

    return (
        <Grid styleName="familyAccessReasons">
            <ConsentAndAccessHeader
                flagName={USER_FLAGS.ACCESS_REASONS}
                title={COPIES.ACCESS_REASON_TITLE}
                subTitle={COPIES.ACCESS_REASON_SUBTITLE}
                onDeleteSubDomain={onDeleteSubDomain}
                selectedSubDomains={legislativelyLockedFields}
            />

            {selectedFamilyMembers.length > 1 ? (
                <>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="family accessReasons tabs"
                        variant="fullWidth"
                        styleName="tabsWrapper"
                        indicatorColor="primary"
                    >
                        {selectedFamilyMembers.map((member) => {
                            const tabId = `accessReasons-tab-${member.UIN}`;
                            return <Tab label={renderTabLabel(member)} aria-controls={tabId} key={tabId} />;
                        })}
                    </Tabs>

                    {selectedFamilyMembers.map((member, index) => (
                        <TabPanel value={tabValue} index={index} key={index}>
                            <AccessReason
                                familyMember={member}
                                items={legislativelyLockedFields}
                                accessReasons={familyAccessReasons[member.UIN]}
                                onAccessReasonsChange={handleAccessReasonsChange}
                                onApplyAllReasons={selectedFamilyMembers.length > 1 ? onApplyAllReasons : undefined}
                            />
                        </TabPanel>
                    ))}
                </>
            ) : (
                <AccessReason
                    familyMember={selectedFamilyMembers[0]}
                    items={legislativelyLockedFields}
                    accessReasons={familyAccessReasons[selectedFamilyMembers[0].UIN]}
                    onAccessReasonsChange={handleAccessReasonsChange}
                    onApplyAllReasons={selectedFamilyMembers.length > 1 ? onApplyAllReasons : undefined}
                />
            )}
        </Grid>
    );
};
