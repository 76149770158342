import React, {useEffect, useState} from 'react';

import './EditResourceDialog.scss';
import {InputField} from '~/components/Common/InputField/InputField';
import {required} from '~/components/Common/InputField/ValidationRules';
import {Button, Dialog, DialogActions, DialogTitle, FormControl, FormHelperText, Snackbar} from '@mui/material';
import {editResource} from '~/services/apiServices';
import {useGrowl} from '~/utils/hooks/useGrowl';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {setResources} from '~/store/resources/resources.thunk';

export interface IEditResourceDialogProps {
    open: boolean;
    onClose: () => void;
    fileId: number;
    initialName: string;
    initialDescription: string;
}

export const EditResourceDialog: React.FC<IEditResourceDialogProps> = ({
    open,
    onClose,
    fileId,
    initialName,
    initialDescription,
}) => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState<string>(initialName);
    const [description, setDescription] = useState<string>(initialDescription);
    const [formCompleted, setFormCompleted] = useState<boolean>(false);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    useEffect(() => {
        if (name.length && name.length <= 60) {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
    }, [name, description]);

    const handleEdit = async () => {
        try {
            await editResource(fileId, name, description);
            dispatch(setResources());
            onClose();
            openGrowl(`${name} has been updated successfully!`);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        }
    };

    return (
        <>
            <Dialog open={open} aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth={true}>
                <DialogTitle id="form-dialog-title">Edit</DialogTitle>

                <span styleName="caveatText">Note that the uploaded file name does not change.</span>

                <div styleName="editFileForm">
                    <FormControl fullWidth styleName="formControl">
                        <InputField
                            htmlFor="name"
                            value={name}
                            onChange={setName}
                            label="File Name"
                            validations={[required('file name')]}
                            maxLength={60}
                        />
                    </FormControl>

                    <FormControl fullWidth styleName="formControl">
                        <InputField
                            htmlFor="description"
                            value={description}
                            onChange={setDescription}
                            label="Add description"
                            multiline
                            rows={2}
                            maxLength={100}
                        />
                        <FormHelperText styleName="textRight">
                            {100 - description.length} characters remaining
                        </FormHelperText>
                    </FormControl>
                </div>

                <DialogActions>
                    <Button onClick={() => onClose()} color="primary" variant="text">
                        Cancel
                    </Button>
                    <Button onClick={handleEdit} color="primary" variant="contained" disabled={!formCompleted}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};
