import React, {useState} from 'react';
import {Typography, Grid, Tabs, Tab} from '@mui/material';
import {IHouseholdMember, HouseholdMembers} from '../Common/HouseholdMembers';
import {DataField} from '~/components/Common/DataField/DataField';
import {ILastFlatSaleDetails, LastFlatSaleDetails} from './LastFlatSaleDetails';
import {IMortgageDetails, MortgageDetails} from './MortgageDetails';
import {AssistanceDetails, IAssistanceDetails, IHomeOwnershipSchemes} from './AssistanceDetails';
import {IHdbAddress, HdbAddress} from '../Common/HdbAddress';
import {Subsection, SFTP_SSNET_HDB, SFTP_DOS} from '../Common/Subsection';
import styles from './HomeOwnership.scss';
import {ICurrentFlatDetails, CurrentFlatDetails, ICurrentFlatDetailsDOS} from './CurrentFlatDetails';
import {GrantsAndLoans, IGrantsAndLoans, IGrantsAndLoansDOS} from './GrantsAndLoans';
import {LastThreeYears, ILastThreeYears} from './LastThreeYears';
import {StatusObject} from '../../SubDomainDisplay';
import {SubDomainStatus} from '~/interfaces/client';
import {AnnualValue, IAnnualValue} from '../Common/AnnualValue';
import {FIELD_VALUES, SCREENING_STATUS} from '~/constants';
import {extractAllValues} from '~/utils/contentUtils';

export type IHomeOwnershipData = {
    SFTP_SSNET_HDB: IHomeOwnership[];
    SFTP_DOS: IHomeOwnershipDOS[];
};
interface IHomeOwnership {
    Id: number;
    HdbAccountNumber: string;
    HdbAddress: IHdbAddress;
    HouseholdMembers: IHouseholdMember[];
    MortgageDetails: IMortgageDetails;
    AssistanceDetails: IAssistanceDetails;
    LastFlatSaleDetails: ILastFlatSaleDetails;
    HomeOwnershipSchemes: IHomeOwnershipSchemes;
    CurrentFlatDetails: ICurrentFlatDetails;
    GrantsAndLoans: IGrantsAndLoans;
    LastThreeYears: ILastThreeYears;
}

interface IHomeOwnershipDOS {
    AnnualValue: IAnnualValue;
    CurrentFlatDetails: ICurrentFlatDetailsDOS;
    GrantsAndLoans: IGrantsAndLoansDOS;
}

export const HomeOwnership: React.FunctionComponent<{data: IHomeOwnershipData; status: StatusObject}> = ({
    data,
    status,
}) => {
    const hdbData = data.SFTP_SSNET_HDB;
    const dosData = data.SFTP_DOS;

    // hdbData[0] will be the current address, if there's two returned
    const [currentHdb, setCurrentHdb] = React.useState(hdbData ? hdbData[0] : null);
    const [tabValue, setTabValue] = useState<number>(0);
    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        setCurrentHdb(hdbData[newValue]);
    };

    // from biz, to compare all dos records with ssnet and display the record with same postal and unit number for current flat and grants and loans
    const recordForCurrentFlatAndGrantAndLoans =
        hdbData &&
        dosData &&
        hdbData[0].HdbAddress &&
        dosData.find(
            (homeOwnershipDOS) =>
                homeOwnershipDOS.CurrentFlatDetails.PostalCode === hdbData[0].HdbAddress.PostalCode &&
                homeOwnershipDOS.CurrentFlatDetails.UnitNumber === hdbData[0].HdbAddress.UnitNumber,
        );

    const renderCurrentFlatAndGrantAndLoans = () => recordForCurrentFlatAndGrantAndLoans !== undefined;

    return (
        <div id={styles.customTable}>
            <Grid data-testid="homeOwnership" container>
                <Grid item xs={12} className={styles.containerGrid}>
                    {hdbData && hdbData.length == 2 && (
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            data-testid="tab"
                            variant="fullWidth"
                            styleName="tabsWrapper"
                        >
                            <Tab label="Recent Address" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                            <Tab label="Past Address" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        </Tabs>
                    )}
                    {currentHdb && currentHdb.HdbAccountNumber !== FIELD_VALUES.NO_ACCESS && (
                        <Grid data-testid="hdbAccountNumber" item xs={10}>
                            <DataField
                                label="HDB Account Number"
                                value={
                                    <Typography variant="h6">
                                        {status[SFTP_SSNET_HDB] == SubDomainStatus.ERROR
                                            ? SCREENING_STATUS.UNSUCCESSFUL
                                            : status[SFTP_SSNET_HDB] == SubDomainStatus.PENDING
                                            ? SCREENING_STATUS.PENDING
                                            : currentHdb.HdbAccountNumber}
                                    </Typography>
                                }
                            />
                        </Grid>
                    )}
                    <Subsection
                        data-testid="hdbAddress"
                        title="HDB Address"
                        fieldList={currentHdb ? extractAllValues(currentHdb.HdbAddress) : []}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <HdbAddress data={currentHdb?.HdbAddress} />
                    </Subsection>
                    <Subsection
                        data-testid="currentFlatDetails"
                        title="Current Flat Details"
                        fieldList={[
                            ...(recordForCurrentFlatAndGrantAndLoans
                                ? [
                                      recordForCurrentFlatAndGrantAndLoans?.CurrentFlatDetails.LeaseCommencementDate,
                                      recordForCurrentFlatAndGrantAndLoans?.CurrentFlatDetails.LeaseTermsTenure,
                                      recordForCurrentFlatAndGrantAndLoans?.CurrentFlatDetails.OwnershipTransferDate,
                                      recordForCurrentFlatAndGrantAndLoans?.CurrentFlatDetails.PurchaseDate,
                                  ]
                                : []),
                            ...(currentHdb ? [currentHdb.CurrentFlatDetails.OpenMarketRentalIndicator] : []),
                        ]}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <CurrentFlatDetails
                            data={currentHdb?.CurrentFlatDetails}
                            dosData={recordForCurrentFlatAndGrantAndLoans?.CurrentFlatDetails}
                            toDisplayDos={renderCurrentFlatAndGrantAndLoans()}
                        />
                    </Subsection>

                    <Subsection
                        data-testid="householdMembersInformation"
                        title="Household Members Information"
                        fieldList={
                            currentHdb && currentHdb.HouseholdMembers.length > 0
                                ? extractAllValues(currentHdb.HouseholdMembers[0])
                                : []
                        }
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <HouseholdMembers data={currentHdb?.HouseholdMembers} />
                    </Subsection>

                    <Subsection
                        data-testid="grantsAndLoans"
                        title="Grants and Loans"
                        fieldList={[
                            ...(recordForCurrentFlatAndGrantAndLoans
                                ? [
                                      recordForCurrentFlatAndGrantAndLoans?.GrantsAndLoans.LoanBalance,
                                      recordForCurrentFlatAndGrantAndLoans?.GrantsAndLoans.LoanTenure,
                                  ]
                                : []),
                            ...(currentHdb ? [currentHdb.GrantsAndLoans.ProximityHousingGrant] : []),
                        ]}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <GrantsAndLoans
                            data={currentHdb?.GrantsAndLoans}
                            dosData={recordForCurrentFlatAndGrantAndLoans?.GrantsAndLoans}
                            toDisplayDos={renderCurrentFlatAndGrantAndLoans()}
                        />
                    </Subsection>

                    <Subsection
                        data-testid="mortgageDetails"
                        title="Mortgage Details"
                        fieldList={currentHdb ? extractAllValues(currentHdb.MortgageDetails) : []}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <MortgageDetails data={currentHdb?.MortgageDetails} />
                    </Subsection>
                    <Subsection
                        data-testid="assistanceDetails"
                        title="Assistance Details"
                        fieldList={currentHdb ? extractAllValues(currentHdb.AssistanceDetails) : []}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <AssistanceDetails
                            assistanceDetails={currentHdb?.AssistanceDetails}
                            homeOwnershipSchemes={currentHdb?.HomeOwnershipSchemes}
                        />
                    </Subsection>

                    <Subsection
                        data-testid="lastThreeYears"
                        title="Immediate Previous Accommodation within the last 3 years"
                        fieldList={currentHdb ? extractAllValues(currentHdb.LastThreeYears) : []}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <LastThreeYears data={currentHdb?.LastThreeYears} />
                    </Subsection>

                    <Subsection
                        data-testid="lastFlatSaleDetails"
                        title="Last Flat Sale Details"
                        fieldList={currentHdb ? extractAllValues(currentHdb.LastFlatSaleDetails) : []}
                        status={status[SFTP_SSNET_HDB]}
                    >
                        <LastFlatSaleDetails data={currentHdb?.LastFlatSaleDetails} />
                    </Subsection>
                    <Subsection
                        data-testid="annualValue"
                        title="Annual Value"
                        fieldList={dosData ? extractAllValues(dosData[0].AnnualValue) : []}
                        status={status[SFTP_DOS]}
                        withDivider={false}
                    >
                        <AnnualValue data={dosData && dosData[0].AnnualValue} />
                    </Subsection>
                </Grid>
            </Grid>
        </div>
    );
};
