import {createSlice} from '@reduxjs/toolkit';
import {IUserFlags} from '~/interfaces/common';
import {setUserFlags, updateUserFlags} from './userFlags.thunk';

export const initialUserFlagsState = {} as IUserFlags;

export const userFlagsSlice = createSlice({
    name: 'userFlags',
    initialState: initialUserFlagsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setUserFlags.fulfilled, (_, action) => {
                if (action.payload) {
                    return action.payload;
                }
            })
            .addCase(updateUserFlags.fulfilled, (state, action) => {
                return {
                    ...state,
                    [action.payload as string]: true,
                };
            });
    },
});

export const userFlagsActions = userFlagsSlice.actions;
export default userFlagsSlice.reducer;
