import React, {useState} from 'react';
import {Button, Checkbox, Dialog, DialogContent, Divider, Grid, IconButton, Typography} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {COPIES} from '~/constants';
import './DoNotShowAgainDialog.scss';

export interface IDoNotShowAgainDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    handleDoNotShowThisMessageAgain: () => void;
    dialogTitle: string;
    dialogContent: string;
    buttonText: string;
}

export const DoNotShowAgainDialog: React.FC<IDoNotShowAgainDialogProps> = ({
    open,
    onClose,
    onSubmit,
    handleDoNotShowThisMessageAgain,
    dialogTitle = COPIES.ADD_SUBDOMAIN_TITLE,
    dialogContent = COPIES.ADD_SUBDOMAINS_CONFIRMATION,
    buttonText = COPIES.ADDED_ALL_TEXT,
}) => {
    const [disableConfirmation, setDisableConfirmation] = useState<boolean>(false);

    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setDisableConfirmation(checked);
    };

    const handleSubmit = () => {
        if (disableConfirmation) {
            handleDoNotShowThisMessageAgain();
        }
        onSubmit();
    };

    return (
        <Dialog open={open} aria-describedby="alert-dialog-description" styleName="doNotShowAgainDialog">
            <DialogContent styleName="container">
                <Grid container>
                    <Grid item xs={11}></Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" onClick={onClose} styleName="closeButton">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Typography styleName="title">{dialogTitle}</Typography>
                <Typography styleName="content">{dialogContent}</Typography>
                <Divider styleName="divider" />
                <label styleName="checkboxLabel">
                    <Checkbox color="primary" checked={disableConfirmation} onChange={handleChange} />
                    {COPIES.DO_NOT_SHOW_AGAIN}
                </label>

                <Grid container justifyContent="center">
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        id="confirmSelectionButton"
                        styleName="confirmButton"
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
