import React from 'react';
import {Grid} from '@mui/material';
import {DataField} from '../DataField/DataField';
import {ISubDomainFieldDisplay} from '~/interfaces/common';
import {FIELD_VALUES} from '~/constants';
import ReactDOMServer from 'react-dom/server';
import './SimpleGridDisplay.scss';

export interface ISimpleGridDisplay {
    fields: ISubDomainFieldDisplay[];
    displayAll?: boolean;
}

export const SimpleGridDisplay = ({fields, displayAll}: ISimpleGridDisplay) => {
    const allowedFields: {
        label: string | React.ReactElement;
        value?: string | React.ReactElement;
        fullWidth?: boolean;
        styleName?: string;
    }[] = fields.filter((field) => {
        if (field.value) {
            if (typeof field.value === 'string') {
                if (typeof displayAll != 'undefined' && displayAll === true) {
                    return true;
                }
                return field.value !== FIELD_VALUES.NO_ACCESS;
            } else {
                const elementStr = ReactDOMServer.renderToString(field.value);
                return !elementStr.includes(FIELD_VALUES.NO_ACCESS);
            }
        }
        return true;
    });

    return (
        <>
            {allowedFields.map((field, i) => {
                const {fullWidth, label, value, styleName} = field;
                const fieldStyle = styleName ? styleName : '';

                return (
                    <Grid key={i} item xs={fullWidth ? 10 : 5} styleName={fieldStyle}>
                        <DataField label={label} value={value as string} />
                    </Grid>
                );
            })}
        </>
    );
};
