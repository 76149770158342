import {
    fixedLength,
    email,
    singaporeMobileNumber,
    sameEmail,
    singaporeNric,
} from '~/components/Common/InputField/ValidationRules';
import {USER_TYPE} from '~/constants';
import {IUser} from '~/interfaces/admin';

export const mobileNumberValid = (mobileNumber: string | undefined) =>
    !mobileNumber || (!fixedLength(8)(mobileNumber || '') && !singaporeMobileNumber(mobileNumber || ''));

export const allowSaveUsers = (user1: IUser, user2?: IUser) => {
    // NGE and WOG admins can create both NGE and PSA users.
    // For PSA users, UserName can be blank as it will be populated by email later.

    const user1Valid =
        user1.Name &&
        user1.Designation &&
        user1.Division &&
        !fixedLength(8)(user1.OfficeNumber) &&
        mobileNumberValid(user1.MobileNumber) &&
        !email(user1.Email) &&
        (!user1.Id && user1.UserName && user1.UserType !== USER_TYPE.intranet ? !singaporeNric(user1.UserName) : true);

    if (user2) {
        const user2Valid =
            user2.Name &&
            user2.Designation &&
            user2.Division &&
            !fixedLength(8)(user2.OfficeNumber) &&
            mobileNumberValid(user2.MobileNumber) &&
            !email(user2.Email) &&
            !sameEmail(user1.Email, user2.Email) &&
            (!user2.Id && user2.UserName && user2.UserType !== USER_TYPE.intranet
                ? !singaporeNric(user2.UserName)
                : true);

        const user2Empty = Object.values(user2).every((x) => !x);

        return Boolean(user1Valid && (user2Empty || user2Valid));
    }

    return Boolean(user1Valid);
};

export const prepareUserCreation = (users: IUser[]): IUser[] => {
    return users.map((user) => {
        return {
            ...user,
            Id: undefined,
            UserName: user?.UserName ? user?.UserName : user?.Email, // use email as username if no nric
            UserType: user?.UserName ? USER_TYPE.internet : USER_TYPE.intranet,
        };
    });
};
