import IdleTimer from 'react-idle-timer';
import React, {useRef, useState, useEffect, useContext} from 'react';
import {CountdownDialog} from './CountdownDialog';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {LOGIN_STATE} from '~/constants';

interface IIdleProps {
    firstTimeoutInSecs: number;
    secondTimeoutInSecs: number;
    onIdleTimeOut?: () => {};
}

export const IdleTimerContainer = (props: IIdleProps) => {
    const [secondTimeoutInSecs, setSecondTimeoutInSecs] = useState<number>(props.secondTimeoutInSecs);
    const {loginState, updateLoginState} = useContext(AuthenticationContext);

    const [modalOpen, setModalOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sessionTimeoutId, _] = useState<number>();
    const sessionTimeoutRef = useRef(sessionTimeoutId);
    const idleTimerRef = useRef<IdleTimer | null>(null);

    const reset = () => {
        setModalOpen(false);
        clearTimeout(sessionTimeoutRef.current);
        setSecondTimeoutInSecs(props.secondTimeoutInSecs);
    };

    const logout = (isManualLogout: boolean) => {
        reset();
        updateLoginState(isManualLogout ? LOGIN_STATE.LOGGED_OUT_MANUAL : LOGIN_STATE.LOGGED_OUT_INACTIVE);
    };

    const onIdle = () => {
        if (!modalOpen) {
            setModalOpen(true);
            idleTimerRef.current?.reset();
            sessionTimeoutRef.current = window.setTimeout(() => {
                logout(false);
            }, secondTimeoutInSecs * 1000);
        }
    };

    useEffect(() => {
        if (modalOpen && secondTimeoutInSecs > 0) {
            const interval = setInterval(() => {
                setSecondTimeoutInSecs(secondTimeoutInSecs - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [modalOpen, secondTimeoutInSecs]);

    return loginState == LOGIN_STATE.LOGGED_IN ? (
        <div>
            <IdleTimer
                ref={(ref: IdleTimer) => {
                    idleTimerRef.current = ref;
                }}
                timeout={props.firstTimeoutInSecs * 1000}
                onIdle={onIdle}
            />
            <CountdownDialog
                modalOpen={modalOpen}
                countdownInSecs={secondTimeoutInSecs}
                onLogout={() => {
                    logout(true);
                }}
                onResume={reset}
            />
        </div>
    ) : (
        <div />
    );
};
