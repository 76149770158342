import React, {useState, useEffect, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Grid, Typography, Button, TextField, InputAdornment, Snackbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {cloneDeep, uniq} from 'lodash';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import * as services from '~/services/dataAccessMatrixService';
import {API_FETCH_STATE} from '~/constants';
import {IDataAccessMatrixDomain, IUser, IOpsUnit} from '~/interfaces/admin';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {Domain} from './Domain';
import {OpsUnitTypeEditModal} from './OpsUnitTypeEditModal';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDomains, getSelectedFieldIds} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {getDamFieldsFetchState} from '~/store/fetchState/fetchState.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {dataAccessMatrixActions} from '~/store/dataAccessMatrix/dataAccessMatrix.slice';
import {getDamOpsUnit} from '~/store/opsUnit/opsUnit.selector';
import {opsUnitActions} from '~/store/opsUnit/opsUnit.slice';

import '../Admin.scss';
import './DataAccessMatrixEdit.scss';

const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

export const SaveDataAccessMatrixButton = ({opsUnitId}: {opsUnitId: number}) => {
    const dispatch = useAppDispatch();
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const {accessToken} = useContext(AuthenticationContext);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const selectedFieldIds = useTypedSelector(getSelectedFieldIds(Number(opsUnitId)));
    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));

    const saveClickHandler = async () => {
        try {
            setIsSubmitting(true);
            await services.updateOpsUnitDataAccessMatrix(opsUnitId, {
                dataFieldIds: selectedFieldIds,
            });
            setIsSubmitting(false);
            openGrowl(`Data Access Matrix for ${opsUnit?.Name} updated`);

            // Update local DAM opsUnit
            const user: IUser = {
                Name: accessToken?.AdditionalUserInfo.Name || '-',
                UserName: '-',
                Designation: accessToken?.AdditionalUserInfo?.Designation || '-',
                Division: '-',
                Email: '-',
                OfficeNumber: '-',
                IsActive: true,
            };

            if (opsUnit) {
                const updatedOpsUnit: IOpsUnit = {
                    ...opsUnit,
                    DataDomainUpdatedAt: new Date(),
                    DataDomainUpdatedByUser: user,
                };

                dispatch(opsUnitActions.UPDATE_DAM_OPS_UNIT(updatedOpsUnit));
            }
        } catch (e) {
            const error = e as Error;
            openGrowl(error.message);
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={saveClickHandler} disabled={isSubmitting}>
                Save
            </Button>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};

export const OpsUnitDetails = ({opsUnitId}: {opsUnitId: number}) => {
    const [isEditingOpsUnitType, setIsEditOpsUnitType] = useState<boolean>(false);
    const opsUnit = useTypedSelector(getDamOpsUnit(Number(opsUnitId)));

    return (
        <>
            <Typography variant="h4">{opsUnit?.Name}</Typography>
            <Grid container direction="row" alignItems="center">
                <Typography variant="h5">
                    {opsUnit?.Agency?.Name} - {opsUnit?.OpsUnitType}
                </Typography>
                <Button variant="text" color="primary" onClick={() => setIsEditOpsUnitType(true)}>
                    Edit
                </Button>
            </Grid>
            {opsUnit && (
                <OpsUnitTypeEditModal
                    open={isEditingOpsUnitType}
                    onClose={() => setIsEditOpsUnitType(false)}
                    currentOpsUnitType={opsUnit.OpsUnitType}
                    opsUnit={opsUnit}
                />
            )}
        </>
    );
};

export const DataAccessMatrixEdit = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {opsUnitId} = useParams<{opsUnitId: string}>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [domainOptions, setDomainOptions] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const [filteredDomains, setFilteredDomains] = useState<IDataAccessMatrixDomain[]>([]);
    const [opsUnitFields, setOpsUnitFields] = useState<number[]>([]);
    const [fetchOpsUnitFieldsStatus, setFetchOpsUnitFieldsStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);
    const [combinedFetchStatus, setCombinedFetchStatus] = useState<API_FETCH_STATE>(API_FETCH_STATE.PENDING);

    const domains = useTypedSelector(getDomains);

    const domainFilterClasses = useDomainFilterStyles();
    const damFieldsFetchState = useTypedSelector(getDamFieldsFetchState);

    const getOpsUnitDataAccessMatrix = () => {
        const domainMatrix = domains.reduce((matrix, domain) => {
            const domainMatrix: {[key: number]: {[key: number]: boolean}} = {};
            domain.SubDomains.forEach((subDomain) => {
                domainMatrix[subDomain.Id] = {};
                subDomain.DataFields.forEach((field) => {
                    domainMatrix[subDomain.Id][field.Id] = opsUnitFields.includes(field.Id);
                });
            });

            matrix[domain.Id] = domainMatrix;

            return matrix;
        }, {} as {[key: number]: {[key: number]: {[key: number]: boolean}}});

        return {
            [Number(opsUnitId)]: domainMatrix,
        };
    };

    useEffect(() => {
        if (damFieldsFetchState === API_FETCH_STATE.SUCCESS && fetchOpsUnitFieldsStatus === API_FETCH_STATE.SUCCESS) {
            setCombinedFetchStatus(API_FETCH_STATE.SUCCESS);
        }

        if (damFieldsFetchState === API_FETCH_STATE.ERROR || fetchOpsUnitFieldsStatus === API_FETCH_STATE.ERROR) {
            setCombinedFetchStatus(API_FETCH_STATE.ERROR);
        }
    }, [damFieldsFetchState, fetchOpsUnitFieldsStatus]);

    useEffect(() => {
        if (!opsUnitId) {
            setFetchOpsUnitFieldsStatus(API_FETCH_STATE.ERROR);
            return;
        }
        dispatch(setDataAccessMatrix());

        (async () => {
            try {
                const fields = await services.getOpsUnitDataAccessMatrix(parseInt(opsUnitId));
                setOpsUnitFields(fields);
                setFetchOpsUnitFieldsStatus(API_FETCH_STATE.SUCCESS);
            } catch (e) {
                setFetchOpsUnitFieldsStatus(API_FETCH_STATE.ERROR);
            }
        })();
    }, []);

    useEffect(() => {
        if (domains.length > 0 && combinedFetchStatus === API_FETCH_STATE.SUCCESS) {
            dispatch(dataAccessMatrixActions.SET_OPS_UNIT_DATA_ACCESS_MATRIX(getOpsUnitDataAccessMatrix()));

            if (domainOptions.length === 0) {
                setDomainOptions(uniq(domains.map((row) => row.Name)));
            }
            applySearchFilter(searchValue);
        }
    }, [domains, combinedFetchStatus]);

    const applySearchFilter = (searchVal: string) => {
        const lowerCaseSearchValue = searchVal.toLowerCase();
        const filteredByDomains = cloneDeep(filterSelectedDomains(selectedDomains));

        const filteredDomains = filteredByDomains.filter((domain) => {
            domain.SubDomains = domain.SubDomains.filter((subDomain) => {
                subDomain.DataFields = subDomain.DataFields?.filter((field) => {
                    return (
                        (field.DataSource && field.DataSource.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1) ||
                        field.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                        subDomain.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1
                    );
                });
                return subDomain.DataFields && subDomain.DataFields?.length > 0;
            });
            return domain.SubDomains.length > 0;
        });
        setFilteredDomains(filteredDomains);
    };

    const filterSelectedDomains = (selectedDomains: string[]): IDataAccessMatrixDomain[] => {
        if (selectedDomains.length === 0) return domains;
        return domains.filter((domain) => selectedDomains.includes(domain.Name));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        applySearchFilter(e.target.value);
    };

    const handleSelectDomain = (selectedDomains: string[]) => {
        setSelectedDomains(selectedDomains);
        setFilteredDomains(filterSelectedDomains(selectedDomains));
    };

    const handleClearDomains = () => {
        handleSelectDomain([]);
    };

    const handleSelectAllDomains = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectDomain(domainOptions);
        } else {
            handleClearDomains();
        }
    };
    const DomainMemo = React.memo(Domain);
    const DomainContent = () => {
        return (
            <>
                {filteredDomains.map((domain) => {
                    return (
                        <DomainMemo
                            domain={domain}
                            onExpandAll={true}
                            key={domain.Id}
                            opsUnitId={parseInt(opsUnitId)}
                        />
                    );
                })}
            </>
        );
    };

    const cancelClickHandler = () => {
        history.push('/admin/data-access-matrix');
    };

    const DomainContentWithLoader = WithLoader<{}>(DomainContent, combinedFetchStatus);

    return (
        <>
            <Grid container data-testid="damHeader" direction="row" justifyContent="center" spacing={3}>
                <Grid item xs={8}>
                    <OpsUnitDetails opsUnitId={Number(opsUnitId)} />
                </Grid>
                <Grid item id="downloadReport" styleName="downloadReportBtn" xs={4}>
                    <Button variant="text" color="primary" style={{marginRight: '8px'}} onClick={cancelClickHandler}>
                        Cancel
                    </Button>
                    <SaveDataAccessMatrixButton opsUnitId={Number(opsUnitId)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        label="Search Subdomain or Field Name"
                        type="search"
                        variant="outlined"
                        value={searchValue}
                        onChange={handleSearchChange}
                        styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MultiSelectAutocomplete
                        options={domainOptions}
                        selectedValues={selectedDomains}
                        label="Select Domains"
                        tagRenderer={(value) => (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {value.length} Domains Selected
                            </Typography>
                        )}
                        onToggleOption={handleSelectDomain}
                        onClearOptions={handleClearDomains}
                        onSelectAll={handleSelectAllDomains}
                        classes={domainFilterClasses}
                    />
                </Grid>
            </Grid>
            <Grid styleName="reportTableStyle">
                <DomainContentWithLoader />
            </Grid>
        </>
    );
};
