import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    Typography,
} from '@mui/material';
import {InputField} from '~/components/Common/InputField/InputField';

import './DeleteWithReasonDialog.scss';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface IDeleteWithReasonDialogProps {
    id: string;
    users?: number | undefined;
    requireAcknowledgement?: boolean;
    acknowledgementMessage?: string;
    buttonMessage?: string;
    warningMessage?: string;
    title: string;
    open: boolean;
    onCancel: () => void;
    onConfirm: (reason: string) => void;
    isLoading?: boolean;
}

export const DeleteWithReasonDialog = ({
    id,
    users,
    requireAcknowledgement = false,
    acknowledgementMessage,
    buttonMessage,
    warningMessage,
    title,
    open,
    onCancel,
    onConfirm,
    isLoading,
}: IDeleteWithReasonDialogProps) => {
    const [deleteReason, setDeleteReason] = useState('');
    const [acknowledgeCheckBox, setAcknowledgeCheckBox] = useState(false);
    const acknowledgementCheck = requireAcknowledgement ? acknowledgeCheckBox : true;

    const maxLengthReason = 350;
    return (
        <>
            <Dialog id={id} open={open} aria-describedby="alert-dialog-description" maxWidth="xs" fullWidth={true}>
                <div styleName="deleteWithReasonForm">
                    <DialogTitle id="form-dialog-title" styleName="title">
                        {title}
                    </DialogTitle>

                    {users && users > 0 ? (
                        <Grid item styleName="showFlexCenter" data-testid="failure">
                            <WarningRoundedIcon styleName="warningIcon" />
                            <Typography styleName="errorText">{warningMessage}</Typography>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <FormControl fullWidth>
                        <InputField
                            htmlFor="deletionReason"
                            value={deleteReason}
                            onChange={setDeleteReason}
                            label="Reason for deletion"
                            multiline
                            rows={3}
                            maxLength={maxLengthReason}
                        />
                        <FormHelperText styleName="helperTextRight">
                            {maxLengthReason - deleteReason.length} characters remaining
                        </FormHelperText>
                    </FormControl>
                    {requireAcknowledgement && (
                        <div styleName="checkboxContainer">
                            <Checkbox
                                color="primary"
                                checked={acknowledgeCheckBox}
                                onChange={() => setAcknowledgeCheckBox(!acknowledgeCheckBox)}
                                style={{justifyContent: 'flex-start', paddingLeft: '0px'}}
                            />
                            <Typography>{acknowledgementMessage}</Typography>
                        </div>
                    )}
                    <DialogActions styleName="buttonContainer">
                        <Button onClick={() => onCancel()} color="primary" variant="text">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onConfirm(deleteReason);
                                setDeleteReason('');
                            }}
                            style={
                                acknowledgementCheck && deleteReason !== '' && !isLoading
                                    ? {backgroundColor: '#D60025', color: '#FFFFFF'}
                                    : {}
                            }
                            variant="contained"
                            disabled={!acknowledgementCheck || deleteReason === '' || isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : `DELETE ${buttonMessage}`}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};
