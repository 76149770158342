import React, {useRef, useState} from 'react';
import {Divider, Grid} from '@mui/material';
import {ISubDomainFieldGroupDisplay} from '~/interfaces/common';
import {SimpleGridDisplay} from './SimpleGridDisplay';
import {ExpandCollapseButton} from '../Button/ExpandCollapseButton';
import {take} from 'lodash';
import './MultiGridDisplay.scss';

export interface IMultiGridDisplay {
    dataFieldGroups: ISubDomainFieldGroupDisplay[];
    dataTestId?: string;
    displayLimit?: number;
}

export const MultiGridDisplay = ({
    dataFieldGroups,
    dataTestId,
    displayLimit = dataFieldGroups.length,
}: IMultiGridDisplay) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [displayLength, setDisplayLength] = useState(displayLimit);
    const dataLength = dataFieldGroups.length;

    const handleExpand = () => {
        setDisplayLength(dataLength);
    };

    const handleCollapse = () => {
        setDisplayLength(displayLimit);
        scrollRef.current?.scrollIntoView({behavior: 'auto', block: 'center', inline: 'nearest'});
    };

    const dataGroupsToRender = take(dataFieldGroups, displayLength);

    return (
        <div styleName="multiGridDisplay">
            {dataGroupsToRender.map((dataFieldGroup, i) => (
                <Grid data-testid={`${dataTestId}${i}`} key={i} container spacing={3} ref={i === 0 ? scrollRef : null}>
                    <SimpleGridDisplay key={i} fields={dataFieldGroup} />
                    {i < dataGroupsToRender.length - 1 && (
                        <Grid item xs={12}>
                            <Divider styleName="divider" />
                        </Grid>
                    )}
                </Grid>
            ))}
            <Grid item xs={12} styleName="readMore">
                <ExpandCollapseButton
                    totalLength={dataLength}
                    limitLength={displayLimit}
                    displayLength={displayLength}
                    onExpand={handleExpand}
                    onCollapse={handleCollapse}
                />
            </Grid>
        </div>
    );
};
