import React, {useState, useEffect} from 'react';
import {
    NotificationsRounded as NotificationsIcon,
    FiberManualRecordRounded as FiberManualRecordIcon,
} from '@mui/icons-material';
import {NotificationsDropdown} from './NotificationsDropdown';
import {Menu, MenuList} from '@mui/material';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setUserNotification} from '~/store/userNotification/userNotification.thunk';
import './NotificationsBell.scss';

export const NotificationsBell = () => {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [hasUnread, setHasUnread] = useState<boolean>(false);
    const userNotifications = useTypedSelector((state) => state.data.common.userNotifications);

    useEffect(() => {
        dispatch(setUserNotification());
    }, []);

    useEffect(() => {
        const hasUnread = userNotifications ? userNotifications.some((notification) => !notification.Read) : false;
        setHasUnread(hasUnread);
    }, [userNotifications]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div styleName="notificationsBell">
            <div onClick={handleClick} styleName="iconsContainer">
                <NotificationsIcon />
                {hasUnread && <FiberManualRecordIcon fontSize="inherit" styleName="hasUnreadIcon" />}
            </div>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 46, horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                MenuListProps={{style: {padding: 0}}}
            >
                <MenuList style={{outline: 'none', padding: 0}}>
                    <NotificationsDropdown handleClose={handleClose} userNotifications={userNotifications} />
                </MenuList>
            </Menu>
        </div>
    );
};
