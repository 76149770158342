import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from '@mui/material';
import {IDataSource} from '~/interfaces/admin';
import {InputField} from '~/components/Common/InputField/InputField';
import {DateInput} from '~/components/Common/DateInput/DateInput';
import style from './CreateDataSourceDialog.scss';
import {checkMaxLength} from '~/components/Common/InputField/ValidationRules';
import {DEFAULT_SHORT_FIELD_MAX_LENGTH} from '~/constants';

type Action = 'Add' | 'Edit';

export interface ICreateDataSourceDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (dataSource: IDataSource) => void;
    dataSources: IDataSource[];
    editDataSource?: IDataSource;
    action?: Action;
}

export const CreateDataSourceDialog = ({
    open,
    onClose,
    onSubmit,
    dataSources,
    editDataSource = {
        Name: '',
        DateAdded: '',
    },
    action = 'Add',
}: ICreateDataSourceDialogProps) => {
    const [dataSource, setDataSource] = useState<IDataSource>(editDataSource);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setDataSource(editDataSource);
    }, [open]);

    const handleSubmit = () => {
        onSubmit(dataSource);
    };

    const duplicateValidation = () => {
        return dataSources.some(
            (source) => source.Name.toUpperCase() === dataSource.Name.toUpperCase() && source.Id !== dataSource.Id,
        )
            ? 'This Data Source (Agency) already exists'
            : undefined;
    };

    const canSubmit = () => {
        return (
            !duplicateValidation() &&
            !!dataSource.Name &&
            !!dataSource.DateAdded &&
            dataSource.Name.length <= DEFAULT_SHORT_FIELD_MAX_LENGTH &&
            !hasError
        );
    };
    const handleDateChange = (value: string) => {
        setDataSource({...dataSource, DateAdded: value});
    };
    const handleHasError = (dateError: boolean) => {
        setHasError(dateError);
    };

    const onDialogClose = (_: unknown, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            aria-describedby="alert-dialog-description"
            className={style.createDataSourceDialog}
            disableEnforceFocus
        >
            <DialogTitle style={{width: 531}}>{action} Data Contributing Agency</DialogTitle>
            <DialogContent>
                <InputField
                    htmlFor="data-source-name"
                    value={dataSource.Name}
                    onChange={(value) => setDataSource({...dataSource, Name: value})}
                    label="Data Source (Agency)"
                    validations={[duplicateValidation]}
                    className={style.formInput}
                    customErrorMessage={checkMaxLength(dataSource.Name, 'Data Source', DEFAULT_SHORT_FIELD_MAX_LENGTH)}
                />
                <DateInput
                    label="Date Added"
                    value={dataSource.DateAdded}
                    onChange={(value) => handleDateChange(value as string)}
                    dateFormat="dd/MM/yyyy"
                    hasError={handleHasError}
                    allowFutureDate={false}
                    maxDate={new Date()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!canSubmit()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
