import React from 'react';
import {Alert, Typography} from '@mui/material';
import './Banner.scss';

export enum SERVERITY_TYPE {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}
type ALERTSEVERITY = SERVERITY_TYPE.SUCCESS | SERVERITY_TYPE.INFO | SERVERITY_TYPE.WARNING | SERVERITY_TYPE.ERROR;

export const Banner = (props: {
    severity: ALERTSEVERITY;
    message: String;
    action?: React.ReactNode;
    icon?: React.ReactNode;
}) => {
    const {severity, message, action, icon} = props;
    let backgroundColor;
    if (severity === SERVERITY_TYPE.SUCCESS) {
        backgroundColor = '#E2F5EE';
    } else if (severity === SERVERITY_TYPE.INFO) {
        backgroundColor = '#E0EDFD';
    } else if (severity === SERVERITY_TYPE.WARNING) {
        backgroundColor = '#FFFDE8';
    } else if (severity === SERVERITY_TYPE.ERROR) {
        backgroundColor = '#f4c7c7';
    }

    return (
        <Alert
            severity={severity}
            action={action}
            icon={icon}
            styleName="banner-alert"
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            <Typography styleName="banner-message">{message}</Typography>
        </Alert>
    );
};
