import {IAgency, IUser, IAgencyResponse, ISysAdminAgency} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {IParticipatingAgencies} from '~/interfaces/common';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getAgencies = async (): Promise<IAgency[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getSysAdminData = async (): Promise<ISysAdminAgency[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/sys-admin-data`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getAgency = async (agencyId: number): Promise<IAgency> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createAgency = async (agencyDetails: IAgency): Promise<IAgencyResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies`,
        data: JSON.stringify(agencyDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const updateAgencyDetails = async (agency: IAgency): Promise<IAgencyResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agency.Id}`,
        data: JSON.stringify(agency),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const updateAgencyAdmin = async (agencyId: number, userDetails: IUser) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/agency-admin/${userDetails.Id}`,
        data: JSON.stringify(userDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return {};
};

export const createAgencyAdmin = async (agencyId: number, userDetails: IUser[]): Promise<IAgencyResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/agency-admin`,
        data: JSON.stringify(userDetails),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw response;
    }

    return response.data;
};

export const deleteAgencyAdmin = async (
    agencyId: number,
    userId: number,
    deletedReason: string,
): Promise<IAgencyResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/agency-admin/${userId}`,
        withCredentials: true,
        data: JSON.stringify({DeletedReason: deletedReason}),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const activateAgencyAdmin = async (agencyId: number, userId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}/agency-admin/${userId}/reactivate`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getParticipatingAgencies = async (): Promise<IParticipatingAgencies> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/participating-agencies`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const deleteAgency = async (agencyId: number, deletedReason: string) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/agencies/${agencyId}`,
        data: JSON.stringify({deletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};
