import React from 'react';
import {Typography} from '@mui/material';
import {COLORS} from '~/components/Common/Colors';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {allNoResults, extractAllValues, formatDateField, isNotEmpty} from '~/utils/contentUtils';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {NoData} from '../CompletedSubDomain';

export type IIncarcerationRecordData = IIncarcerationRecord[];

interface IIncarcerationRecord {
    CustodyStatus: string;
    InmateNumber: string;
    AdmissionDate: string;
    ReleaseDate: string;
    InstitutionName: string;
    OffenceDescription: string;
}

export const STATUS_IN_CUSTODY = 'In custody';
export const STATUS_PERMANENT_RELEASE = 'Permanent release';

const incarcerationRecordToFields = (incarcerationRecord: IIncarcerationRecord, index: number) => {
    const {CustodyStatus, InmateNumber, AdmissionDate, ReleaseDate, InstitutionName, OffenceDescription} =
        incarcerationRecord;

    return [
        {
            label: 'Custody Status',
            value: isNotEmpty(CustodyStatus) ? (
                <Typography
                    data-testid={`custody_status_${index}`}
                    variant="body1"
                    style={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        color: CustodyStatus == STATUS_IN_CUSTODY ? COLORS.GREEN : 'black',
                    }}
                >
                    {CustodyStatus}
                </Typography>
            ) : (
                CustodyStatus
            ),
        },
        {label: 'Inmate Number', value: InmateNumber},
        {label: 'Admission Date', value: formatDateField(AdmissionDate, true, true)},
        {
            label:
                isNotEmpty(CustodyStatus) && CustodyStatus == STATUS_PERMANENT_RELEASE
                    ? 'Permanent Release Date'
                    : 'Estimated Release Date',
            value: formatDateField(ReleaseDate, true, true),
        },
        {label: 'Name of Institution', value: InstitutionName},
        {
            label: 'Offence Description',
            value: OffenceDescription,
        },
    ];
};

export const IncarcerationRecord: React.FunctionComponent<{data: IIncarcerationRecordData}> = ({data}) => {
    const noData = allNoResults(extractAllValues(data));
    const sortedIncarcerationRecords = noData
        ? data
        : data.sort((irA, irB) => {
              return new Date(irB.AdmissionDate).getTime() - new Date(irA.AdmissionDate).getTime();
          });

    const fieldsToRender = sortedIncarcerationRecords.map(incarcerationRecordToFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <MultiGridDisplay
                    dataFieldGroups={fieldsToRender}
                    dataTestId="incarcerationRecord"
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
