import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Grid, Paper, Typography} from '@mui/material';
import {IConsent} from '~/interfaces/client';
import {ConsentIndicator} from '../ConsentIndicator/ConsentIndicator';
import './ClientInfoHeader.scss';

export interface IClientInfoHeaderProps {
    clientName: string;
    clientUIN: string;
    clientConsent?: IConsent;
    isDeceased?: boolean;
}

export const ClientInfoHeader = ({clientName, clientUIN, clientConsent}: IClientInfoHeaderProps) => {
    return (
        <>
            <Paper elevation={0} id="clientInfoHeader" styleName="clientInfoHeader" square>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <Typography id="clientName" variant="h4" styleName="clientName">
                            {`${clientName}`}
                        </Typography>
                        <Typography id="clientUIN" variant="subtitle1" styleName="clientUIN">
                            {clientUIN}
                        </Typography>
                    </Grid>

                    <Grid item xs={2} styleName="requestMoreInfo">
                        <Button id="requestMoreInfo" component={Link} to="/search/screen-client">
                            Request More Info
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <ConsentIndicator consent={clientConsent} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
