import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Divider, Snackbar, Typography} from '@mui/material';
import {WithLoader} from '../../components/Common/WithLoader/WithLoader';
import {IRowData, ResourceTable} from '~/components/Admin/Resource/ResourceTable';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {
    deleteResource,
    downloadInternetResources,
    downloadIntranetResources,
    downloadSingleIntranetResource,
} from '~/services/apiServices';
import {config} from '~/config';
import './Resources.scss';
import {ACCESS_LEVELS} from '~/constants';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {UploadResourceDialog} from '~/components/Admin/ResourceDialog/UploadResourceDialog';
import {EditResourceDialog} from '~/components/Admin/ResourceDialog/EditResourceDialog';
import {IResource} from '~/interfaces/common';
import {useGrowl} from '~/utils/hooks/useGrowl';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {setResources} from '~/store/resources/resources.thunk';
import {getResourcesFetchState} from '~/store/fetchState/fetchState.selector';

type groupedRows = {type: string; rows: IRowData[]};

export const Resources = () => {
    const dispatch = useAppDispatch();
    const groupedResources = useTypedSelector((state) => state.data.common.resources);
    const fetchStatus = useTypedSelector(getResourcesFetchState);
    const [selected, setSelected] = useState<number[]>([]);
    const [rows, setRows] = useState<groupedRows[]>([]);
    const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
    const {accessToken} = useContext(AuthenticationContext);
    const [resourceToBeDeleted, setResourceToBeDeleted] = useState<number>(0);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [resourceToBeEdited, setResourceToBeEdited] = useState<number>(0);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    useEffect(() => {
        dispatch(setResources());
    }, []);

    useEffect(() => {
        const fetchedRows = groupedResources
            .map((res) => ({
                type: res.type,
                rows: res.resources.map((r) => ({
                    isSelected: selected.includes(r.id),
                    id: r.id,
                    name: r.name,
                    description: r.description,
                    lastUpdated: r.lastUpdated,
                    fileSize: r.fileSize,
                    type: r.type,
                })),
            }))
            .sort((a: groupedRows, b: groupedRows) => (a.type > b.type ? -1 : 1));
        setRows(fetchedRows);
    }, [groupedResources, selected]);

    const handleCheckBoxClick = (id: number) => {
        const updateSelectedList = selected.includes(id) ? selected.filter((s) => s !== id) : [...selected, id];
        setSelected(updateSelectedList);
    };

    const handleAllCheckBoxClick = (type: string) => () => {
        const categoryRows = rows.find((r) => r.type === type);
        if (!categoryRows || !categoryRows.rows.length) return;
        const categoryRowsId = categoryRows.rows.map((r) => r.id);
        const nonCategoryRowsId = selected.filter((id) => !categoryRowsId.includes(id));
        if (categoryRowsId.every((i) => selected.includes(i))) {
            setSelected(nonCategoryRowsId);
        } else {
            setSelected([...nonCategoryRowsId, ...categoryRowsId]);
        }
    };

    const getFileAttrFromId = (id: number, attr: keyof IResource) => {
        const resource = groupedResources
            .map((r) => r.resources)
            .flat(1)
            .find((d) => d.id === id);
        if (resource) return resource[attr];
    };

    const handleDownload = async () => {
        setIsDownloadLoading(true);
        try {
            if (config.internetEnabled) {
                await downloadInternetResources(selected);
            } else if (selected.length === 1) {
                const fileName = `${getFileAttrFromId(selected[0], 'fileName')}`;
                if (fileName) await downloadSingleIntranetResource(selected[0], fileName);
            } else if (selected.length > 1) {
                await downloadIntranetResources(selected);
            }
            setSelected([]);
        } catch (err) {
            const error = err as Error;
            alert(error.message);
        } finally {
            setIsDownloadLoading(false);
        }
    };

    const openDeleteDialog = (fileId: number) => {
        setDeleteDialogOpen(true);
        setResourceToBeDeleted(fileId);
    };

    const openEditDialog = (fileId: number) => {
        setEditDialogOpen(true);
        setResourceToBeEdited(fileId);
    };

    const handleFileDelete = async () => {
        try {
            setIsDeleting(true);
            if (resourceToBeDeleted) await deleteResource(resourceToBeDeleted);
            dispatch(setResources());
            setSelected([]);
            openGrowl(`${getFileAttrFromId(resourceToBeDeleted, 'fileName')} has been deleted.`);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        } finally {
            setIsDeleting(false);
            setDeleteDialogOpen(false);
            setResourceToBeDeleted(0);
        }
    };

    const ResourcesContent = () => {
        if (!rows.length) return <p>No resources are available for download</p>;

        return (
            <>
                {rows.map((r) => (
                    <Box key={r.type} mb={5}>
                        <Typography variant="h5" display="inline" styleName="tableDescription">
                            For {r.type}s
                        </Typography>
                        <ResourceTable
                            allCheckBoxClickHandler={handleAllCheckBoxClick(r.type)}
                            checkboxClickHandler={handleCheckBoxClick}
                            deleteFileHandler={openDeleteDialog}
                            editFileHandler={openEditDialog}
                            rows={r.rows}
                        />
                    </Box>
                ))}
            </>
        );
    };

    const ResourcesWithLoader = WithLoader<{}>(ResourcesContent, fetchStatus);

    return (
        <div styleName="resources">
            <div styleName="resources-header-container">
                <Typography variant="h4" display="inline" styleName="resources-header">
                    Guides and Toolkits
                </Typography>
                <div>
                    <Button
                        onClick={handleDownload}
                        disabled={isDownloadLoading}
                        styleName={selected.length ? '' : 'hidden'}
                        color="primary"
                        variant={
                            !config.internetEnabled &&
                            accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR
                                ? 'outlined'
                                : 'contained'
                        }
                    >
                        <span>DOWNLOAD</span> {isDownloadLoading && <CircularProgress size={24} color="inherit" />}
                    </Button>
                    {!config.internetEnabled &&
                        accessToken &&
                        accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR && (
                            <Button
                                disabled={selected.length > 0}
                                color="primary"
                                variant="contained"
                                onClick={() => setUploadDialogOpen(true)}
                            >
                                <span>UPLOAD</span>
                            </Button>
                        )}
                </div>
                {uploadDialogOpen && (
                    <UploadResourceDialog
                        open={uploadDialogOpen}
                        onClose={() => setUploadDialogOpen(false)}
                        resourceTypeOptions={groupedResources.map((res) => res.type)}
                    />
                )}

                {editDialogOpen && (
                    <EditResourceDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        fileId={resourceToBeEdited}
                        initialName={`${getFileAttrFromId(resourceToBeEdited, 'name')}`}
                        initialDescription={`${getFileAttrFromId(resourceToBeEdited, 'description')}`}
                    />
                )}

                {deleteDialogOpen && (
                    <ConfirmDialog
                        onCancel={() => setDeleteDialogOpen(false)}
                        onConfirm={handleFileDelete}
                        open={deleteDialogOpen}
                        title={`Delete ${getFileAttrFromId(resourceToBeDeleted, 'fileName')}?`}
                        message="The resource will be removed for all users."
                        cancelOption="Cancel"
                        confirmOption="Delete"
                        isLoading={isDeleting}
                    />
                )}
            </div>
            <Divider />
            <ResourcesWithLoader />

            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </div>
    );
};
