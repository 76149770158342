import {createAsyncThunk} from '@reduxjs/toolkit';
import {API_FETCH_STATE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import * as clientServices from '~/services/clientServices';
import {IFamilyScreeningResults} from '~/interfaces/client';

export const setScreenedClientInfo = createAsyncThunk<IFamilyScreeningResults, string, {rejectValue: string}>(
    '/client/setScreenedClientInfo',
    async (screeningRequestId, {rejectWithValue, dispatch}) => {
        try {
            dispatch({
                type: fetchStateActions.SET_SCREENED_CLIENT_INFO_FETCH_STATE.type,
                payload: API_FETCH_STATE.PENDING,
            });
            const screeningResults = await clientServices.getScreeningResults(screeningRequestId);
            dispatch({
                type: fetchStateActions.SET_SCREENED_CLIENT_INFO_FETCH_STATE.type,
                payload: API_FETCH_STATE.SUCCESS,
            });
            return screeningResults;
        } catch (err) {
            dispatch({
                type: fetchStateActions.SET_SCREENED_CLIENT_INFO_FETCH_STATE.type,
                payload: API_FETCH_STATE.ERROR,
            });
            return rejectWithValue('setScreenClientInfo error');
        }
    },
);
