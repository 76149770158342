import {Button} from '@mui/material';
import React, {useState} from 'react';
import {ConfirmationModal} from '~/components/ConfirmationModal/ConfirmationModal';
import style from './DataAccessRequestWindow.scss';

interface IDataAccessActionButtonProps {
    buttonText: string;
    confirmButtonHandler: () => void;
    modalTitle: string;
    modalContent?: string;
    cancelButtonText: string;
    confirmButtonText: string;
}

export const DataAccessActionButton = (props: IDataAccessActionButtonProps) => {
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        setOpen(false);
        props.confirmButtonHandler();
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <ConfirmationModal
                title={props.modalTitle}
                textBody={props.modalContent}
                confirmBtnText={props.confirmButtonText}
                cancelBtnTxt={props.cancelButtonText}
                open={open}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            ></ConfirmationModal>
            <Button
                size="medium"
                color="primary"
                className={style.actionBtn + ' ' + style.smallBtn}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <span>{props.buttonText}</span>
            </Button>
        </>
    );
};
