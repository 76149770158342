import {Box} from '@mui/material';
import React from 'react';

import style from './SuspendedIcon.scss';

export const SuspendedIcon = () => {
    return (
        <Box component="div" className={style.suspendedWrapper} id="suspended-icon">
            Suspended
        </Box>
    );
};
