import './AgencyList.scss';

import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, {createContext, useEffect, useRef, useState} from 'react';
import {setSysAdminData} from '~/store/agency/agency.thunk';

import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {AgencyRow} from './AgencyRow';
import {IAgency} from '~/interfaces/admin';
import {InputField} from '~/components/Common/InputField/InputField';
import {LOADING_MESSAGE} from '~/constants';
import {LeavePageDialog} from '~/components/Common/LeavePageDialog/LeavePageDialog';
import NavigationPrompt from 'react-router-navigation-prompt';
import {TableButton} from '~/components/Common/Button/TableButton';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {getAgenciesFetchState} from '~/store/fetchState/fetchState.selector';
import {updateOpsUnitCanScreenClient} from '~/store/opsUnit/opsUnit.thunk';
import {updateTeamsCanScreenClient} from '~/store/team/team.thunk';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {useHistory} from 'react-router-dom';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {sortAgencyNameAlphabetically} from '~/utils/arrayUtils';

export type ISysAdminContext = {
    onCheckBoxClick: (type: string, Id: number, value: boolean) => void;
};

export const FunctionContext = createContext<ISysAdminContext>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCheckBoxClick: () => {},
});

interface IUpdates {
    [Id: number]: boolean;
}

export const AgencyList = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const agencies = Object.values(useTypedSelector((state) => state.data.admin.agencies.agencies));
    const fetchStatus = useTypedSelector(getAgenciesFetchState);
    const [updatedOpsUnits, setUpdatedOpsUnits] = useState<IUpdates>({});
    const [updatedTeams, setUpdatedTeams] = useState<IUpdates>({});
    const domainHeaderRef = useRef<HTMLDivElement>(null);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleEdit = (agencyId: number) => {
        history.push(`/admin/agencies/${agencyId}`);
    };

    const handleClick = () => {
        history.push(`/admin/agencies/create`);
    };

    const handleSave = async () => {
        let opsUnitUpdateStatus = true;
        let teamsStatus = true;
        if (updatedOpsUnits) {
            const opsUnitThunkResult = await dispatch(updateOpsUnitCanScreenClient(updatedOpsUnits));
            if ('error' in opsUnitThunkResult) {
                opsUnitUpdateStatus = false;
            }
        }
        if (updatedTeams) {
            const teamThunkResult = await dispatch(updateTeamsCanScreenClient(updatedTeams));
            if ('error' in teamThunkResult) {
                teamsStatus = false;
            }
        }

        if (opsUnitUpdateStatus && teamsStatus) {
            dispatch(setSysAdminData());
            openGrowl('Changes have been saved successfully.');
        }
    };

    const handleCheckBoxClick = (type: string, Id: number, value: boolean) => {
        if (type === 'OpsUnit') {
            const newState = updatedOpsUnits;
            newState[Id] = value;
            setUpdatedOpsUnits(newState);
        } else {
            const newTeamState = updatedTeams;
            newTeamState[Id] = value;
            setUpdatedTeams(newTeamState);
        }
    };

    useEffect(() => {
        dispatch(setSysAdminData());
    }, []);
    const agencyAdminControls = (agency: IAgency) =>
        !agency.Admins ? (
            <TableButton text="Add" onClick={() => handleEdit(agency.Id as number)} />
        ) : (
            <TableButton text="Edit" onClick={() => handleEdit(agency.Id as number)} />
        );
    const getAgencyListContent = () => {
        const [searchValue, setSearchValue] = useState('');
        const [disableSaveButton, setDisableSaveButton] = useState(true);

        const filteredAgencies = agencies.filter(
            (agency) => agency.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
        );

        return (
            <>
                <NavigationPrompt when={!disableSaveButton}>
                    {({isActive, onConfirm, onCancel}) => (
                        <LeavePageDialog
                            id="leave-page-dialog"
                            open={isActive}
                            title="Leave page?"
                            message="Any unsaved changes will be lost"
                            confirmText="LEAVE PAGE"
                            cancelText="STAY ON PAGE"
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                        />
                    )}
                </NavigationPrompt>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Admin Management
                        </Typography>
                    </Grid>
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="agency-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search Agency Name"
                                type="search"
                                style={{width: '320px'}}
                            />
                        </Grid>
                        <Grid item ref={domainHeaderRef}>
                            <span style={{paddingRight: '16px'}}>
                                <AddButtonPrimary text="Add Agency" id="add-agency-button" onClick={handleClick} />
                            </span>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                style={{height: 36, width: 176}}
                                disabled={disableSaveButton}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <FunctionContext.Provider value={{onCheckBoxClick: handleCheckBoxClick}}>
                    <TableContainer style={{paddingTop: '24px'}}>
                        <Table aria-label="collapsible table" styleName="table">
                            <TableHead>
                                <TableRow styleName="headers">
                                    <TableCell styleName="title" style={{width: '40%', paddingLeft: '56px'}}>
                                        Agency / Ops Unit/ Team / User Name
                                    </TableCell>
                                    <TableCell styleName="title">Admin 1</TableCell>
                                    <TableCell styleName="title">Admin 2</TableCell>
                                    <TableCell styleName="title" align="center">
                                        Assign Role
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredAgencies &&
                                    filteredAgencies
                                        .slice()
                                        .sort(sortAgencyNameAlphabetically)
                                        .map((agency) => {
                                            return (
                                                <AgencyRow
                                                    agency={agency}
                                                    key={agency.Id}
                                                    buttons={agencyAdminControls(agency)}
                                                    setDisableSaveButton={(activeState) => {
                                                        setDisableSaveButton(activeState);
                                                    }}
                                                />
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </FunctionContext.Provider>
            </>
        );
    };

    const AgencyListContentWithLoader = WithAdminLoader<{}>(getAgencyListContent, fetchStatus, LOADING_MESSAGE.DATA);

    return (
        <>
            <AgencyListContentWithLoader />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
