export const mockFile = (name = 'file.txt', size = 1024, type = 'plain/txt'): File => {
    const blob = new Blob(['a'.repeat(size)], {type});
    return new File([blob], name, {type});
};

export const formatFileName = (file: File) => {
    const newFileName = file.name.split(' ').join('_');
    return new File([file], newFileName, {
        type: file.type,
        lastModified: file.lastModified,
    });
};

export const prependTimestampToFileName = (file: File) => {
    const now = new Date();
    const newFileName = now.getTime() + '-' + file.name;
    return new File([file], newFileName, {
        type: file.type,
        lastModified: file.lastModified,
    });
};
