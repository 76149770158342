import {createAsyncThunk} from '@reduxjs/toolkit';
import * as teamServices from '~/services/teamServices';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {RootState} from '../index';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {ITeam, IUser} from '~/interfaces/admin';

interface ISetTeam {
    opsUnitId: number;
    team: ITeam | {};
}

export const setTeams = createAsyncThunk<ISetTeam, number, {rejectValue: string; state: RootState}>(
    '/team/setTeams',
    async (opsUnitId, {rejectWithValue, dispatch}) => {
        dispatch({type: fetchStateActions.SET_TEAMS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
        try {
            let teamsObject = {};
            const teams = await teamServices.getTeams(opsUnitId);
            teamsObject = teams.reduce((obj, team) => {
                return {
                    ...obj,
                    [team.Team.Id as number]: {
                        ...team.Team,
                        ...team.CurrentTeamReviews,
                        Users: team.Team.Users ? (team.Team.Users as unknown as IUser[]).map((user) => user.Id) : [],
                    },
                } as ITeam;
            }, {});
            dispatch({type: fetchStateActions.SET_TEAMS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
            dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.TEAM});
            return {opsUnitId: opsUnitId, team: teamsObject};
        } catch (err) {
            dispatch({type: fetchStateActions.SET_TEAMS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            return rejectWithValue('Error in fetching teams info');
        }
    },
);

export const setTeam = createAsyncThunk('/team/setTeam', async (teamId: number, {rejectWithValue, dispatch}) => {
    dispatch({type: fetchStateActions.SET_TEAM_FETCH_STATE, payload: API_FETCH_STATE.PENDING});
    try {
        const teamInfo = await teamServices.getTeam(teamId);
        dispatch({type: fetchStateActions.SET_TEAM_FETCH_STATE, payload: API_FETCH_STATE.SUCCESS});
        return {teamId, teamInfo};
    } catch (error) {
        dispatch({type: fetchStateActions.SET_TEAM_FETCH_STATE, payload: API_FETCH_STATE.ERROR});
        return rejectWithValue('Error in fetching teams info');
    }
});

export const createTeam = createAsyncThunk<{opsUnitId: number; team: ITeam}, {opsUnitId: number; team: ITeam}>(
    '/team/createTeam',
    async ({opsUnitId, team}, {rejectWithValue}) => {
        try {
            const teamResponse = await teamServices.createTeam(opsUnitId, team);
            if (teamResponse.Error) {
                return rejectWithValue(teamResponse.Error);
            }
            return {opsUnitId: opsUnitId, team: teamResponse.Team};
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const updateTeamDetails = createAsyncThunk<ITeam, {team: ITeam}, {rejectValue: string}>(
    '/team/updateTeamDetails',
    async ({team}, {rejectWithValue}) => {
        try {
            const teamResponse = await teamServices.updateTeamDetails(team);
            if (teamResponse.Error) {
                return rejectWithValue(teamResponse.Error);
            }
            return team;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const createTeamAdmin = createAsyncThunk<
    {teamId: number; team: ITeam},
    {teamId: number; admin: IUser},
    {rejectValue: string}
>('/team/createTeamAdmin', async ({teamId, admin}, {rejectWithValue}) => {
    try {
        const teamResponse = await teamServices.createTeamAdmin(teamId, admin);
        if (teamResponse.Error) {
            return rejectWithValue(teamResponse.Error);
        }
        return {teamId: teamId, team: teamResponse.Team};
    } catch (error) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const updateTeamAdmin = createAsyncThunk<
    {teamId: number; adminDetails: IUser},
    {teamId: number; adminDetails: IUser}
>('/team/updateTeamAdmin', async ({teamId, adminDetails}, {rejectWithValue}) => {
    try {
        await teamServices.updateTeamAdmin(teamId, adminDetails);
        return {teamId, adminDetails};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const deleteTeamAdmin = createAsyncThunk<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ISetTeam | any,
    {opsUnitId: number; userId: number; deletedReason: string},
    {rejectValue: string}
>('/team/deleteTeamAdmin', async ({opsUnitId, userId, deletedReason}, {rejectWithValue}) => {
    try {
        const teamResponse = await teamServices.deleteTeamAdmin(opsUnitId, userId, deletedReason);
        if (teamResponse.Error) {
            return rejectWithValue(teamResponse.Error);
        }
        return {opsUnitId: opsUnitId, team: teamResponse.Team};
    } catch (err) {
        return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
    }
});

export const activateTeamAdmin = createAsyncThunk<
    {teamId: number; admin: IUser},
    {teamId: number; admin: IUser},
    {rejectValue: string}
>('/team/activateTeamAdmin', async ({teamId, admin}, {rejectWithValue}) => {
    if (admin.Id) {
        try {
            await teamServices.activateTeamAdmin(teamId, admin.Id);
            return {teamId, admin};
        } catch (err) {
            return rejectWithValue('Error in activating team admin');
        }
    }
    return rejectWithValue('Error in activating team admin');
});

export interface IUpdateTeamsCanScreenClient {
    [Id: number]: boolean;
}
export const updateTeamsCanScreenClient = createAsyncThunk<boolean, IUpdateTeamsCanScreenClient>(
    '.opsUnit/updateTeamsCanScreenClient',
    async (updatedOpsUnit) => {
        try {
            await teamServices.updateTeamsCanScreenClient(updatedOpsUnit);
            return true;
        } catch (err) {
            return false;
        }
    },
);
