import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export enum Flag {
    DATA_DICTIONARY_FIELD = 'DATA_DICTIONARY_FIELD',
    DATA_SOURCE = 'DATA_SOURCE',
    DATA_CONTRIBUTORS = 'DATA_CONTRIBUTORS',
    PARTICIPATING_AGENCY = 'PARTICIPATING_AGENCY',
    AGENCY = 'AGENCY',
    DOMAIN = 'DOMAIN',
    RESOURCES = 'RESOURCES',
    SYSTEM_ADMINS = 'SYSTEM_ADMINS',
    OPS_UNIT = 'OPS_UNIT',
    TEAM = 'TEAM',
    USER = 'USER',
    DAM_OPS_UNIT = 'DAM_OPS_UNIT',
    DAM_FIELDS = 'DAM_FIELDS',
    RELEASE_NOTE = 'RELEASE_NOTE',
}

export interface IUpdateHydrateFlagAction {
    type: string;
    flag: Flag;
}

export const initialHydrateFlagState = {
    [Flag.DATA_DICTIONARY_FIELD]: false,
    [Flag.DATA_SOURCE]: false,
    [Flag.DATA_CONTRIBUTORS]: false,
    [Flag.RESOURCES]: false,
    [Flag.PARTICIPATING_AGENCY]: false,
    [Flag.SYSTEM_ADMINS]: false,
    [Flag.AGENCY]: false,
    [Flag.DOMAIN]: false,
    [Flag.OPS_UNIT]: false,
    [Flag.TEAM]: false,
    [Flag.USER]: false,
    [Flag.DAM_OPS_UNIT]: false,
    [Flag.DAM_FIELDS]: false,
    [Flag.RELEASE_NOTE]: false,
};

export const hydrateFlagSlice = createSlice({
    name: 'hydrateFlag',
    initialState: initialHydrateFlagState,
    reducers: {
        UPDATE_FLAG: (state, action: PayloadAction<Flag>) => {
            state[action.payload] = true;
        },
        DELETE_FLAG: (state, action: PayloadAction<Flag>) => {
            delete state[action.payload];
        },
    },
});

export const hydrateFlagActions = hydrateFlagSlice.actions;
export default hydrateFlagSlice.reducer;
