import {createSlice} from '@reduxjs/toolkit';
import {IClientBasicInfo, IScreenedClients} from '~/interfaces/client';
import {setScreenedClientInfo} from './client.thunk';

export const initialClientState = {
    searchedClientInfo: {} as IClientBasicInfo,
    screenedClients: {} as IScreenedClients,
};

export const clientSlice = createSlice({
    name: 'client',
    initialState: initialClientState,
    reducers: {
        setSearchedClientInfo(state, action) {
            state.searchedClientInfo = action.payload;
        },
        clearSearchedClientInfo(state) {
            state.searchedClientInfo = {} as IClientBasicInfo;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setScreenedClientInfo.fulfilled, (state, action) => {
            if (action.payload) {
                state.screenedClients = {
                    ...state.screenedClients,
                    [action.payload.FamilyScreeningRequestId]: {
                        ...action.payload,
                        ScreeningResults: action.payload.ScreeningResults,
                    },
                };
            }
        });
    },
});

export const clientActions = clientSlice.actions;
export default clientSlice.reducer;
