import React, {useState} from 'react';
import {ConsentUpdateDialogForm} from './ConsentUpdateDialogForm';
import {Dialog, Grid} from '@mui/material';
import {CONSENT_STATUS} from '~/constants';

export interface IConsentUpdateProps {
    status: CONSENT_STATUS;
    action: string;
    onSubmit: (remarks: string, effectiveDate: Date, action: string, consentFormId: number) => void;
    onClose: () => void;
}

export const ConsentUpdateDialog = ({status, action, onSubmit, onClose}: IConsentUpdateProps) => {
    const [remarks, setRemarks] = useState<string>('');
    const [effectiveDate, setEffectiveDate] = useState<Date>(new Date());
    const [consentFormId, setConsentFormId] = useState<number>(0);

    const handleCloseModal = () => {
        setRemarks('');
        setEffectiveDate(new Date());
        onClose();
    };

    const handleEffectiveDateChange = (effectiveDate: Date) => {
        setEffectiveDate(effectiveDate);
    };

    const handleUpdateConsent = (): void => {
        onSubmit(remarks, effectiveDate, action, consentFormId);
        handleCloseModal();
    };

    return (
        <Dialog open={action != ''} aria-describedby="alert-dialog-description" disableEnforceFocus>
            <Grid container>
                <Grid item xs={12}>
                    <ConsentUpdateDialogForm
                        status={status}
                        action={action}
                        onSubmit={handleUpdateConsent}
                        remarks={remarks}
                        effectiveDate={effectiveDate}
                        consentFormId={consentFormId}
                        onRemarksChange={(rm) => setRemarks(rm)}
                        onEffectiveDateChange={(effectiveDate) => handleEffectiveDateChange(effectiveDate)}
                        onConsentFormIdChange={(id) => setConsentFormId(id)}
                        onCancel={handleCloseModal}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};
