import {createSlice} from '@reduxjs/toolkit';
import {ITeam} from '~/interfaces/admin';
import {
    setTeams,
    createTeam,
    updateTeamDetails,
    createTeamAdmin,
    updateTeamAdmin,
    deleteTeamAdmin,
    activateTeamAdmin,
    setTeam,
} from './team.thunk';

import {createUser, setUsers} from '../user/user.thunk';
import {omit} from 'lodash';

export const initialTeamState: {[teamId: number]: ITeam} = {};

export const teamSlice = createSlice({
    name: 'team',
    initialState: initialTeamState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setTeams.fulfilled, (_, action) => {
                if (action.payload) {
                    return action.payload.team;
                }
            })
            .addCase(createTeam.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.team.Id] = action.payload.team;
                }
            })
            .addCase(updateTeamDetails.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.Id] = action.payload;
                }
            })
            .addCase(createTeamAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.teamId] = action.payload.team;
                }
            })
            .addCase(updateTeamAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentAdmins = state[action.payload.teamId].Admins || [];
                    const editedAdmins = currentAdmins.map((admin) =>
                        admin.Id === action.payload.adminDetails.Id
                            ? {
                                  ...admin,
                                  ...action.payload.adminDetails,
                              }
                            : admin,
                    );
                    state[action.payload.teamId] = {...state[action.payload.teamId], Admins: [...editedAdmins]};
                }
            })
            .addCase(deleteTeamAdmin.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.opsUnitId] = action.payload.team;
                }
            })
            .addCase(activateTeamAdmin.fulfilled, (state, action) => {
                const admins = state[action.payload.teamId].Admins || [];
                const updatedAdmins = admins.map((admin) =>
                    admin.Id === action.payload.admin.Id
                        ? {
                              ...admin,
                              IsActive: true,
                          }
                        : admin,
                );
                state[action.payload.teamId] = {...state[action.payload.teamId], Admins: [...updatedAdmins]};
            })
            .addCase(createUser.fulfilled, (state, action) => {
                const currentUsers = state[action.payload.teamId].Users as number[];
                const newUsers = currentUsers
                    ? ([...currentUsers, action.payload.userDetails.Id] as number[])
                    : ([action.payload.userDetails.Id] as number[]);
                state[action.payload.teamId] = {...state[action.payload.teamId], Users: [...newUsers]};
            })
            .addCase(setTeam.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.teamId] = {
                        ...state[action.payload.teamId],
                        ...omit(action.payload.teamInfo, 'Users'),
                    };
                }
            })
            .addCase(setUsers.fulfilled, (state, action) => {
                if (action.payload) {
                    state[action.payload.teamId] = {
                        ...state[action.payload.teamId],
                        Users: Object.keys(action.payload.usersObject).map(Number),
                    };
                }
            });
    },
});

export const teamActions = teamSlice.actions;
export default teamSlice.reducer;
