import {createSlice} from '@reduxjs/toolkit';
import {setResources} from './resources.thunk';
import {IGroupedResource} from '~/interfaces/common';
import map from 'lodash/map';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';

export const initialResourcesState: IGroupedResource[] = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapResources = (resources: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return map(resources, (value: any, key: any) => ({type: key, resources: value}));
};

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState: initialResourcesState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setResources.fulfilled, (_, action) => {
            if (action.payload) {
                return flow(groupBy('type'), mapResources)(action.payload);
            }
        });
    },
});

export const resourcesActions = resourcesSlice.actions;
export default resourcesSlice.reducer;
