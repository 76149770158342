import React from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {IAppState} from '~/store';
import {config} from '~/config';
import {DevLoginView} from './DevLoginView';
import {LoginView} from './LoginView';
import {AdfsLoginView} from './AdfsLoginView';
import {useSuppressWidget} from '~/utils/hooks/useSuppressWidget';

export const Login = () => {
    const referer = useSelector((state: IAppState) => get(state, 'router.location.state.referer.pathname', '/'));
    if (config.internetEnabled) useSuppressWidget();

    return config.authenticationMode === 'CORPPASS' ? (
        <LoginView
            referer={referer}
            loginButtonLink={`${config.api.AUTH_SERVICE}/corppass-login`}
            loginButtonText="LOG IN WITH SINGPASS"
        />
    ) : config.authenticationMode === 'ADFS' ? (
        <AdfsLoginView />
    ) : config.authenticationMode === 'MOCK' && !(window.location.host === 'onecv.gov.sg') ? (
        <DevLoginView referer={referer} />
    ) : null;
};
