import React from 'react';
import {Grid, Typography, Divider, TableCell, TableRow} from '@mui/material';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {WithEnhancedTable, TABLE_TYPE} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import './MindefNsfAssistance.scss';
import {format, getTime} from 'date-fns';
import {
    allNoAccess,
    allNoResults,
    extractAllValues,
    formatValue,
    isNoAccess,
    isNotEmpty,
    renderIfHasAccess,
} from '~/utils/contentUtils';
import {transformInvalidDateString} from '~/utils/dateUtils';
import {NoData} from '../CompletedSubDomain';
import {currencyFormatter} from '~/utils/currencyUtils';

export type IMindefNsfAssistanceData = IMindefNsfAssistance[];
interface IDisbursement {
    Amount: string;
    AssistanceReceivedDate: string;
    parsedReceivedDateForSorting: number;
}

interface IMindefNsfAssistance {
    CaseNumber: string;
    AssistanceStartDate: string;
    AssistanceEndDate: string;
    DisbursementDetails: IDisbursement[];
}

type IHeadRowData = Pick<IDisbursement, 'parsedReceivedDateForSorting'>;

interface IDisbursementRows {
    sortedRows: IDisbursement[];
}

// Transform DD/MM/YY string date string format to DD MMM YYYY
const parseDateToFormat = (date: string) => format(new Date(transformInvalidDateString(date)), 'dd MMM yyyy');

export const DisbursementRows = ({sortedRows}: IDisbursementRows) => {
    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <TableRow tabIndex={-1} key={index}>
                        <TableCell>{formatValue(row.AssistanceReceivedDate, parseDateToFormat)}</TableCell>
                        <TableCell align="right">{formatValue(row.Amount, currencyFormatter)}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const MindefNsfAssistance = ({data}: {data: IMindefNsfAssistanceData}) => {
    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {id: 'parsedReceivedDateForSorting', align: 'left', disablePadding: false, label: 'Disbursed Date'},
        {align: 'right', disablePadding: false, label: 'Assistance Amount ($)'},
    ];

    //Check for data
    const noData = allNoResults(extractAllValues(data));

    //Sort by descending start date if data is available
    data = noData
        ? data
        : data.sort(
              (a, b) =>
                  getTime(new Date(transformInvalidDateString(b.AssistanceStartDate))) -
                  getTime(new Date(transformInvalidDateString(a.AssistanceStartDate))),
          );

    const headerProps = {
        columns: headColumns,
        customStyle: {
            backgroundColor: '#5c6bc0',
            color: '#ffffff',
        },
    };

    const hocProps = {
        colSpan: 2,
        headerProps,
    };

    interface IEnhancedTableProps {
        data: IDisbursement[];
    }

    const MindefDisbursementTable: React.FC<IEnhancedTableProps> = ({data}) => {
        const noAccess = allNoAccess(extractAllValues(data));
        const noData = allNoResults(extractAllValues(data));
        let dataWithSortableFields: IDisbursement[] = [];

        dataWithSortableFields =
            noAccess || noData
                ? data
                : data.map((record) => {
                      return {
                          ...record,
                          parsedReceivedDateForSorting: getTime(
                              new Date(transformInvalidDateString(record.AssistanceReceivedDate)),
                          ),
                      };
                  });

        const EnhancedTable = WithEnhancedTable<IDisbursementRows, IHeadRowData, IDisbursement>(
            DisbursementRows,
            'parsedReceivedDateForSorting',
            dataWithSortableFields,
            'desc',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            TABLE_TYPE.SIMPLE,
        );

        return noAccess ? null : <EnhancedTable {...{...headerProps, ...hocProps}} />;
    };

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <Grid styleName="mindefContainer">
                    {data.map((mindefCase, index) => (
                        <div key={index}>
                            <Grid
                                container
                                key={mindefCase.CaseNumber}
                                styleName="mindefTextContainer"
                                data-testid={`mindef-case-text${index}`}
                            >
                                {renderIfHasAccess(
                                    mindefCase.CaseNumber,
                                    <Typography>
                                        <span styleName="mindefGrayText">Case no. </span>
                                        {mindefCase.CaseNumber}
                                    </Typography>,
                                )}
                                {(!isNoAccess(mindefCase.AssistanceStartDate) ||
                                    !isNoAccess(mindefCase.AssistanceEndDate)) && (
                                    <Typography data-testid={`mindef-assistance-period${index}`}>
                                        <span styleName="mindefGrayText">Assistance Period: </span>
                                        <span styleName="mindefBoldText">
                                            {isNotEmpty(mindefCase.AssistanceStartDate) ||
                                            isNotEmpty(mindefCase.AssistanceEndDate)
                                                ? `${formatValue(
                                                      mindefCase.AssistanceStartDate,
                                                      parseDateToFormat,
                                                  )} to ${formatValue(mindefCase.AssistanceEndDate, parseDateToFormat)}`
                                                : mindefCase.AssistanceStartDate}
                                        </span>
                                    </Typography>
                                )}
                            </Grid>
                            <div data-testid={`mindef-assistance-table${index}`}>
                                <MindefDisbursementTable data={mindefCase.DisbursementDetails} />
                                {index + 1 < data.length && (
                                    <Divider
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </Grid>
            )}
        </>
    );
};
