import React from 'react';
import {List, ListItem} from '@mui/material';
import {
    ACCESS_LEVELS,
    FIELD_VALUES,
    MONTHLY_REVIEW_COMPLETED_MESSAGE,
    MONTHLY_REVIEW_INCOMPLETED_MESSAGE,
    MONTHLY_REVIEW_USER_ONGOING_MESSAGE,
    MONTHLY_REVIEW_STATUS,
    MONTHLY_REVIEW_ADMIN_ONGOING_MESSAGE,
} from '~/constants';
import _ from 'lodash/fp';
import {transform} from 'lodash';
import {formatDate, formatDateMonthName} from './dateUtils';
import {IconMessage, ICON_TYPE} from '~/components/Common/IconMessage/IconMessage';
import {MessageType} from '~/components/Common/Cards/ActionCard';
import {IMonthlyReviewResponse} from '~/interfaces/admin';
import {isAgencyAdmin} from '~/pages/Admin/AdminHelper';

export const assertNewLine = (value: string) => {
    const list = value.split('\n');
    return (
        <List style={{padding: 0, margin: 0}}>
            {list.map((item, i) => (
                <ListItem key={i} style={{padding: 0, margin: 0}}>
                    {item}
                </ListItem>
            ))}
        </List>
    );
};

export const assertNewLineIfMoreThanOne = (value: string) => {
    const list = value.split('\n');
    if (list.length > 1) {
        return (
            <List style={{padding: 0, margin: 0}}>
                {list.map((item, i) => (
                    <ListItem key={i} style={{padding: 0, margin: 0}}>
                        {item}
                    </ListItem>
                ))}
            </List>
        );
    } else {
        return value;
    }
};

export const shortenContent = (input: string, limit: number): string => {
    const shortened = input.slice(0, limit) + '...';
    return input.length > limit ? shortened : input;
};

export const getMonthlyReviewMessage = (): string => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 15).toLocaleString('default', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });
    return `Please review and update the list of users.\nOtherwise, this user list will be automatically submitted on ${lastDayOfMonth}.`;
};

export const getOngoingMonthlyReviewMessage = (
    currMonthlyReview: IMonthlyReviewResponse,
    accessLevel: ACCESS_LEVELS | undefined,
    location: string,
): MessageType => {
    const today = new Date();
    const lastDayOfReviewPeriod = formatDate(new Date(today.getFullYear(), today.getMonth(), 21), true, false);
    if (!accessLevel) return {title: 'Error retrieving results', body: ''};
    const getFormattedOngoingMessage = () => {
        let constantMsg = '';
        if (location.includes('users')) {
            constantMsg = MONTHLY_REVIEW_USER_ONGOING_MESSAGE[accessLevel];
            return constantMsg + ' ' + formatDateMonthName(lastDayOfReviewPeriod, false) + '.';
        } else if (
            location.includes('admin/ops-units') ||
            location.includes('admin/system-admins') ||
            location.includes('admin/data-contributors')
        ) {
            constantMsg = MONTHLY_REVIEW_ADMIN_ONGOING_MESSAGE[accessLevel];
            return constantMsg + ' ' + formatDateMonthName(lastDayOfReviewPeriod, false) + '.';
        }
        return MONTHLY_REVIEW_ADMIN_ONGOING_MESSAGE[accessLevel];
    };
    let title = '';
    switch (accessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            title = `Review System Admin accounts by ${formatDateMonthName(lastDayOfReviewPeriod, true)}`;
            break;
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            title = `Approve review of user accounts by ${formatDateMonthName(lastDayOfReviewPeriod, true)}`;
            break;
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
            title = `Review Data Contributor accounts by ${formatDateMonthName(lastDayOfReviewPeriod, true)}`;
            break;
        default:
            title = `Review user accounts in ${formatDateMonthName(lastDayOfReviewPeriod, false)}`;
            break;
    }
    switch (currMonthlyReview.Status) {
        case MONTHLY_REVIEW_STATUS.ONGOING:
            return {
                title: title,
                body: getFormattedOngoingMessage(),
            };

        case MONTHLY_REVIEW_STATUS.COMPLETED:
            if (isAgencyAdmin(accessLevel)) {
                return {
                    title: title,
                    body: getFormattedOngoingMessage(),
                };
            } else {
                return {
                    title: `Reviewed on ${formatDate(new Date(currMonthlyReview.MostRecentReview), true, false)} by ${
                        currMonthlyReview.RecentReviewBy
                    }`,
                    body: MONTHLY_REVIEW_COMPLETED_MESSAGE[accessLevel],
                };
            }

        case MONTHLY_REVIEW_STATUS.INCOMPLETE:
            if (isAgencyAdmin(accessLevel)) {
                return {
                    title: `Review of user accounts in ${formatDateMonthName(
                        lastDayOfReviewPeriod,
                        false,
                    )} has concluded`,
                    body: MONTHLY_REVIEW_INCOMPLETED_MESSAGE[accessLevel],
                };
            } else {
                return {
                    title: `No review submitted in ${formatDateMonthName(lastDayOfReviewPeriod, false)}`,
                    body: MONTHLY_REVIEW_INCOMPLETED_MESSAGE[accessLevel],
                };
            }
        default:
            return {title: 'Error retrieving results', body: ''};
    }
};

export const isNotEmpty = (value: string): boolean => {
    return !Object.values<string>(FIELD_VALUES).includes(value);
};

export const isNoAccess = (value: string): boolean => {
    return value === FIELD_VALUES.NO_ACCESS;
};

export const isNoResults = (value: string): boolean => {
    return value === FIELD_VALUES.NO_RESULTS;
};

export const allNoResults = (values: string[]): boolean => {
    return values.every((value: string) => value === FIELD_VALUES.NO_RESULTS);
};

export const allNoResultsOrNull = (values: string[]): boolean => {
    return values.every((value: string) => value === FIELD_VALUES.NO_RESULTS || value === null);
};

export const allNoAccess = (values: string[]): boolean => {
    return values.every((value: string) => value === FIELD_VALUES.NO_ACCESS);
};

export const allNoAccessOrResults = (values: string[]): boolean => {
    return values.every((value: string) => value === FIELD_VALUES.NO_ACCESS || value === FIELD_VALUES.NO_RESULTS);
};

export const renderIfHasAccess = (value: string, render: React.ReactNode): React.ReactNode => {
    if (isNoAccess(value)) {
        return null;
    }
    return render;
};

export const extractAllValues = (obj: Object): string[] => {
    let listOfValue: string[] = [];
    if (obj) {
        for (const value of Object.values(obj)) {
            if (typeof value === 'string') {
                listOfValue.push(value);
            } else if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    if (value.length > 0) {
                        listOfValue = listOfValue.concat(extractAllValues(value[0]));
                    }
                } else {
                    listOfValue = listOfValue.concat(extractAllValues(value));
                }
            }
        }
    }
    return listOfValue;
};

// objNoResults returns predicate if object or string is all no results
export const objNoResults = (obj: Object | string): boolean => {
    if (typeof obj === 'string') {
        return obj === FIELD_VALUES.NO_RESULTS;
    }
    return allNoResults(extractAllValues(obj));
};

// objNoAccess returns predicate if object or string is all no access
export const objNoAccess = (obj: Object | string): boolean => {
    if (typeof obj === 'string') {
        return obj === FIELD_VALUES.NO_ACCESS;
    }
    return allNoAccess(extractAllValues(obj));
};

export const objNoAccessAndNoResults = (obj: Object | string): boolean => {
    return allNoAccessOrResults(extractAllValues(obj));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatValue = (value: any, ...formatter: ((data: any) => any)[]): any => {
    if (isNotEmpty(value)) {
        return _.flow(...formatter)(value);
    }
    return value;
};

// formatDateField returns formated date string based on either string/date input
// returns the value itself if no data/no access/unsuccessful
export const formatDateField = (value: string | Date, isMonthLong = false, isDayLong = true, hideDayValue = false) => {
    if (value instanceof Date) {
        return formatDate(value, isMonthLong, isDayLong, hideDayValue);
    }

    if (isNotEmpty(value)) {
        const dateVal = new Date(value);
        return formatDate(dateVal, isMonthLong, isDayLong, hideDayValue);
    }

    return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createObjectWithValue = (obj: Object, value: string): any =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(obj, (res: {[key: string]: any}, val, key) => {
        if (typeof val === 'object') {
            res[key] = createObjectWithValue(val, value);
        } else if (typeof val === 'string') {
            res[key] = value;
        } else {
            res[key] = val;
        }
    });

export const formatMobileNumber = (mobileNumber: string) => {
    if (isNotEmpty(mobileNumber)) {
        return mobileNumber && mobileNumber.length == 8
            ? mobileNumber.substring(0, 4) + ' ' + mobileNumber.substring(4, 8)
            : 'Invalid';
    }
    return mobileNumber;
};

export const NotAuthorizedMessage = ({hasRecordsToDisplay}: {hasRecordsToDisplay: boolean}) => (
    <IconMessage
        data-testid="iconMessage"
        style={{backgroundColor: '#E0EDFD', marginBottom: '24px'}}
        type={ICON_TYPE.INFO}
        message={
            hasRecordsToDisplay
                ? 'Some records are not available as your agency is not authorised to access those information.'
                : 'Your agency is not authorised to access the information of this person.'
        }
    />
);

export const getEmailDirectAdminLink = (
    recipients: string,
    accessLevel: ACCESS_LEVELS | undefined,
    unitName: string,
    copyTo?: string | undefined,
) => {
    if (!accessLevel) {
        return;
    }
    switch (accessLevel) {
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            return `mailto:${recipients}?` + `subject=[OneCV] Please update the OAs for ${unitName}.`;
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            const copyToRecipients = copyTo ? `cc=${copyTo}&` : '';
            return (
                `mailto:${recipients}?` + copyToRecipients + `subject=[OneCV] Please update the AAs for ${unitName}.`
            );
    }
};
