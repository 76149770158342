import React from 'react';
import {Container, Dialog, DialogContent, Typography} from '@mui/material';
import {startCase} from 'lodash';
import {config} from '~/config';
import {WarningMessage} from '../IconMessage/IconMessage';

import './BrowserDetectModal.scss';

const recommendedBrowsers = ['chrome', 'safari', 'edge'];
const isRecommendedBrowser = (browserName: string) => recommendedBrowsers.includes(browserName);

export interface IBrowserDetectModalProps {
    browserName: string;
    browserIncompatible: boolean;
    browserOutdated: boolean;
    isMobile: boolean;
}

export const BrowserDetectModal = ({
    browserName,
    browserIncompatible,
    browserOutdated,
    isMobile,
}: IBrowserDetectModalProps) => {
    const MobileContent = (
        <DialogContent styleName="container">
            <Container styleName="title">
                <WarningMessage
                    message="Device Not Supported"
                    variant="h5"
                    style={{color: 'var(--primary-blue, #3949ab)', alignItems: 'flex-end'}}
                />
            </Container>
            <Typography>
                One Client View is not supported on mobile and tablet devices. Please access the system via your desktop
                or laptop.
            </Typography>
        </DialogContent>
    );

    const OutdatedBrowserContent = (
        <DialogContent styleName="container">
            <Container styleName="title">
                <WarningMessage
                    message="Browser Version Outdated"
                    variant="h5"
                    style={{color: 'var(--primary-blue, #3949ab)', alignItems: 'flex-end'}}
                />
            </Container>
            <Typography>
                To minimise security risk, please update your {browserName} browser to the latest version to access One
                Client View.
            </Typography>
            <br></br>
            {config.compatibleBrowserVersions && (
                <Typography>
                    The browser should be updated to
                    <span styleName="version">{` ${browserName} ${
                        config.compatibleBrowserVersions[browserName.toLowerCase()]
                    } `}</span>
                    and above.
                </Typography>
            )}
        </DialogContent>
    );

    const IncompatibleBrowserContent = (
        <DialogContent styleName="container">
            <Container styleName="title">
                <WarningMessage
                    message="Browser Not Supported"
                    variant="h5"
                    style={{color: 'var(--primary-blue, #3949ab)', alignItems: 'flex-end'}}
                />
            </Container>
            <Typography>
                This browser is currently not supported by One Client View. Please access the system using the following
                browsers:
            </Typography>
            <ul styleName="list">
                {config.compatibleBrowserVersions &&
                    Object.keys(config.compatibleBrowserVersions)
                        .filter(isRecommendedBrowser)
                        .map((browserName) => (
                            <li key={browserName}>
                                <span>{startCase(browserName)}</span>
                                <span styleName="version">{` ${config.compatibleBrowserVersions[browserName]} `}</span>
                                <span>and above</span>
                            </li>
                        ))}
            </ul>
        </DialogContent>
    );
    return (
        <React.Fragment>
            <Dialog
                open={browserIncompatible || isMobile || browserOutdated}
                aria-describedby="alert-dialog-description"
                styleName="browserDetectModal"
            >
                {isMobile ? (
                    MobileContent
                ) : browserIncompatible ? (
                    IncompatibleBrowserContent
                ) : browserOutdated ? (
                    OutdatedBrowserContent
                ) : (
                    <Typography>An error has occured, please contact your administrator!</Typography>
                )}
            </Dialog>
        </React.Fragment>
    );
};
