import {ACCESS_LEVELS} from '~/constants';
import {IEntity, TEntityType} from '~/interfaces/admin';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';

import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getOpsUnits} from '~/store/opsUnit/opsUnit.selector';
import {getTeams} from '~/store/team/team.selector';

export const useSelectOptions = ({Permissions}: IAccessToken) => {
    const {AccessLevel} = Permissions;
    const agencyId = Number(Permissions.AgencyId);
    const opsUnitId = Number(Permissions.OpsUnitId);
    const teamId = Number(Permissions.TeamId);

    const getAdminGroupType = (): TEntityType => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return 'Agency';
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return 'Ops Unit';
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
                return 'Team';
            default:
                return '';
        }
    };

    const getEntityType = (): TEntityType => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return 'Agency';
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return 'Ops Unit';
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
                return 'Team';
            default:
                return '';
        }
    };

    const getEntityLabel = (): string => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return 'Select Agency(s)';
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return 'Select Ops Unit(s)';
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return 'Select Team(s)';
            default:
                return '';
        }
    };

    const getSelectedGroupLabel = (selectedOptions: string[]) => {
        if (allUserGroupsSelected(selectedOptions)) {
            return 'All Groups Selected';
        } else {
            return `${selectedOptions.length} Group(s) Selected`;
        }
    };

    const getSelectedEntityLabel = (entities: string[], selectedOptions: string[]): string => {
        const isSelectAll = selectedOptions.length === entities.length;
        if (isSelectAll) {
            return `${getSelectAllEntityLabel()} Selected`;
        } else {
            return `${selectedOptions.length} ${getEntityType()}(s) Selected`;
        }
    };

    const getSelectAllEntityLabel = (): string => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return 'All Agencies';
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return 'All Ops Units';
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                return 'All Teams';
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return 'All Teams';
            default:
                return '';
        }
    };

    const getEntities = (): IEntity[] => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return Object.values(useTypedSelector((state) => state.data.admin.agencies.agencies));
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return Object.values(useTypedSelector(getOpsUnits(agencyId)));
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return Object.values(useTypedSelector(getTeams(opsUnitId)));
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                return Object.values(useTypedSelector(getTeams(opsUnitId)));
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR:
                const teamAdmin = useTypedSelector((state) => state.data.admin.teams[Number(teamId)]);
                return teamAdmin ? [teamAdmin] : [];
            case ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER:
                const team = useTypedSelector((state) => state.data.admin.teams[Number(teamId)]);
                return team ? [team] : [];
            default:
                return [];
        }
    };

    const getEntityOptions = (entities: IEntity[]): string[] =>
        entities
            .sort((currentEntity, nextEntity) => currentEntity.Name.localeCompare(nextEntity.Name))
            .map((entity) => entity.Name);

    const getEntityIds = (entities: IEntity[], selectedValue: string): number[] => {
        const selectedAgencies = entities.filter(
            (entity) => entity.Name === selectedValue || selectedValue === getSelectAllEntityLabel(),
        );
        return selectedAgencies.map((entity) => entity.Id);
    };

    const getUserOptions = (): string[] => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return ['Agency Admins', 'Ops Unit Admins', 'Team Admins', 'Users'];
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return ['Ops Unit Admins', 'Team Admins', 'Users'];
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return ['Team Admins', 'Users'];
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                return ['Team Admins', 'Users'];
            default:
                return [];
        }
    };

    const allUserGroupsSelected = (selectedUsers: string[]): boolean => {
        switch (AccessLevel) {
            case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
            case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
                return selectedUsers.length === 4;
            case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
                return selectedUsers.length === 3;
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
                return selectedUsers.length === 2;
            case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
                return selectedUsers.length === 2;
            default:
                return false;
        }
    };

    const allEntitiesSelected = (entities: string[], selectedOptions: string[]): boolean => {
        return entities.length == selectedOptions.length;
    };

    return {
        getAdminGroupType,
        getEntityType,
        getEntityLabel,
        getSelectedGroupLabel,
        getSelectedEntityLabel,
        getSelectAllEntityLabel,
        getEntities,
        getEntityOptions,
        getUserOptions,
        getEntityIds,
        allEntitiesSelected,
        allUserGroupsSelected,
    };
};
