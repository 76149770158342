import React from 'react';
import {Box} from '@mui/material';
import style from './UserIndicatorTag.scss';

const UserIndicatorTag = () => {
    return (
        <Box className={style.userIndicatorWrapper} id="user-indicator-tag">
            User
        </Box>
    );
};

export default UserIndicatorTag;
