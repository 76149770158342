import React, {useState, useEffect, useRef} from 'react';
import {List, ListItem, Typography, Collapse} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {useOnScreen} from '~/utils/hooks/useOnScreen';
import {Fields} from './Fields';

import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getAccessRejectedReasonsState} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {orderBy} from 'lodash';

import './RejectedAccessHistory.scss';

export interface ISubDomainProps {
    subDomain: IDataAccessMatrixSubDomain;
    expandAll: boolean;
    opsUnitId: number;
    domainId: number;
}

export interface ISubDomainHeaderProps {
    subDomain: IDataAccessMatrixSubDomain;
    expand: boolean;
    setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ISubDomainReasonProps {
    subDomainId: number;
    opsUnitId: number;
    domainId: number;
}

export const SubDomainHeader = ({subDomain, expand, setExpand}: ISubDomainHeaderProps) => {
    const handleClickSubDomain = () => {
        setExpand(!expand);
    };

    return (
        <ListItem button onClick={handleClickSubDomain} styleName="expandHeader">
            <Typography component={'span'} styleName="expandTitle">
                {subDomain.Name}
                <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
            </Typography>
        </ListItem>
    );
};

const FieldsMemo = React.memo(Fields);
export const SubDomain = ({subDomain, expandAll, opsUnitId, domainId}: ISubDomainProps) => {
    const intersectionRef = useRef(null);
    const {isOnScreen} = useOnScreen(intersectionRef);
    const [expand, setExpand] = useState(true);
    const reasons = useTypedSelector(getAccessRejectedReasonsState(opsUnitId, domainId, subDomain.Id));

    useEffect(() => {
        setExpand(expandAll);
    }, [expandAll]);

    if (reasons?.length) {
        const sortedReasons = orderBy(reasons, ['rejectedAt'], ['desc']);
        return (
            <List style={{padding: '0', marginTop: '0px'}} ref={intersectionRef}>
                <SubDomainHeader subDomain={subDomain} expand={expand} setExpand={setExpand} />
                <Collapse in={expand} timeout={500}>
                    {isOnScreen && (
                        <FieldsMemo reasons={sortedReasons} domainId={domainId} subDomainId={subDomain.Id} />
                    )}
                </Collapse>
            </List>
        );
    }
    return <></>;
};
