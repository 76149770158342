import React, {useState, useEffect} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {Container} from '@mui/material';
import {routeParameters} from './router';

import './styles.scss';

interface IPage {
    children: NonNullable<React.ReactNode>;
}

export const Page = ({children}: IPage) => {
    const defaultStyle = 'globalContainer';
    const [styleName, setStyleName] = useState(defaultStyle);
    const location = useLocation();

    useEffect(() => {
        const match = routeParameters.find((params) => {
            const matched = matchPath(location.pathname, {path: params.path});
            return matched && matched?.isExact;
        });

        if (match) {
            setStyleName(match.style);
        } else {
            setStyleName(defaultStyle);
        }
    }, [location.pathname]);

    return <Container styleName={styleName}>{children}</Container>;
};
