import React from 'react';
import {Divider, Grid, Typography} from '@mui/material';
import {FiberManualRecordRounded as FiberManualRecordIcon} from '@mui/icons-material';
import {IUserNotification} from '~/interfaces/common';
import {shortenContent} from '~/utils/contentUtils';
import {Link} from 'react-router-dom';
import {formatDistance} from 'date-fns';
import './NotificationItem.scss';

export interface INotificationItemProps {
    notification: IUserNotification;
    type: 'page' | 'dropdown';
    divider?: boolean;
    onClick?: () => void;
}

export const NotificationItem = ({notification, type, divider = true, onClick}: INotificationItemProps) => {
    const {Message} = notification.Notification;
    const formattedTime = formatDistance(new Date(notification.Notification.CreatedAt), new Date(), {
        addSuffix: true,
    });
    const formattedMessage = shortenContent(Message, 90);

    const DropdownItem = () => (
        <>
            <Grid item xs={1}>
                {!notification.Read && <FiberManualRecordIcon fontSize="inherit" styleName="unreadIcon" />}
            </Grid>
            <Grid item xs={11} styleName="messageContainer">
                <Grid item xs={12}>
                    <Link
                        to={`/notifications#${notification.Notification.Id}`}
                        style={{textDecoration: 'none'}}
                        onClick={onClick}
                    >
                        <Typography variant="inherit" styleName="message link" component={'span'}>
                            {formattedMessage}
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="inherit" styleName="createdTime">
                        {formattedTime}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );

    const PageItem = () => (
        <>
            <Grid item xs={1}>
                {!notification.Read && <FiberManualRecordIcon fontSize="inherit" styleName="unreadIcon pageIcon" />}
            </Grid>
            <Grid item xs={9} styleName="messageContainer">
                <Typography variant="inherit" styleName="message">
                    {Message}
                </Typography>
            </Grid>
            <Grid item xs={2} styleName="timeOffset">
                <Typography variant="inherit" styleName="createdTime">
                    {formattedTime}
                </Typography>
            </Grid>
        </>
    );

    return (
        <Grid
            container
            styleName={!notification.Read && type === 'page' ? 'highlight notificationItem' : 'notificationItem'}
        >
            {type === 'dropdown' ? <DropdownItem /> : <PageItem />}
            {divider && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    );
};
