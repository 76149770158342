import {Box, Link} from '@mui/material';
import {Info} from '@mui/icons-material';
import {KeyboardArrowRight} from '@mui/icons-material';
import React from 'react';
import './NotificationBanner.scss';

export interface INotificationBannerProps {
    componentName: string;
    messageNode: React.ReactNode;
    linkLabel: string;
    link: string;
}

export const NotificationBanner: React.FunctionComponent<INotificationBannerProps> = ({
    componentName,
    messageNode,
    linkLabel,
    link,
}) => {
    return (
        <div style={{paddingBottom: '12px', height: '36px'}} id={componentName}>
            <Box styleName="notificationBanner">
                <Info style={{paddingBottom: '2px', color: '#3F91EF'}} fontSize="small" />
                <span style={{paddingTop: '4px'}}>{messageNode}</span>
                <Link href={link} style={{letterSpacing: '1.25px', alignItems: 'center'}}>
                    {linkLabel}
                    <KeyboardArrowRight />
                </Link>
            </Box>
        </div>
    );
};
