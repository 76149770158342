import React, {useRef, useState, useEffect, useContext} from 'react';
import {Alert, AlertColor, Button, Grid, Typography, Tabs, Tab} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import {ACCESS_LEVELS, COPIES} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {getParticipatingAgenciesFetchState} from '~/store/fetchState/fetchState.selector';
import {setParticipatingAgencies} from '~/store/participatingAgency/participatingAgency.thunk';
import {participatingAgencyActions} from '~/store/participatingAgency/participatingAgency.slice';
import {createDataSource, editDataSource, deleteDataSource} from '~/store/dataSource/dataSource.thunk';
import {TabPanel} from '~/components/Common/TabPanel';
import {AgencyTable, IAgencyTableProps} from '~/components/Common/FooterResources/AgencyTable';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {CreateDataSourceDialog} from '~/components/Admin/DataDictionary/CreateDataSourceDialog';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {IDataSource} from '~/interfaces/admin';
import {IParticipatingAgency} from '~/interfaces/common';

import './ParticipatingAgencies.scss';

export interface IAddNewDataSourceButtonProps {
    dataSources: IParticipatingAgency[];
    openGrowl: (msg: string) => void;
    setAlertSeverity: (color: AlertColor) => void;
}

export const AddNewDataSourceButton = ({dataSources, openGrowl, setAlertSeverity}: IAddNewDataSourceButtonProps) => {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);

    const showModalHandler = () => {
        setShowModal(true);
    };

    const handleCreateDataSource = async (dataSource: IDataSource) => {
        const thunkActionResult = await dispatch(createDataSource(dataSource));

        if (thunkActionResult) {
            if ('error' in thunkActionResult) {
                setAlertSeverity('info');
                openGrowl(thunkActionResult.payload as string);
            } else {
                dispatch(participatingAgencyActions.setNewDataSource(thunkActionResult.payload));
                setAlertSeverity('success');
                openGrowl('New Data Contributing Agency successfully added!');
                setShowModal(false);
            }
        }
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={<AddRoundedIcon />}
                onClick={showModalHandler}
                styleName="addDataSourceBtn"
            >
                ADD AGENCY
            </Button>

            <CreateDataSourceDialog
                open={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={handleCreateDataSource}
                dataSources={dataSources}
            />
        </>
    );
};

export const ParticipatingAgencies = () => {
    const dispatch = useAppDispatch();
    const agencies = useTypedSelector((state) => state.data.common.participatingAgencies);
    const dataSources = agencies.DataAgencies;
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const fetchStatus = useTypedSelector(getParticipatingAgenciesFetchState);
    const [tabValue, setTabValue] = useState(0);
    const [editable, setEditable] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editDataSourceObject, setEditDataSourceObject] = useState<IParticipatingAgency>();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success');

    const gridContainerRef = useRef(null);

    const auth = useContext(AuthenticationContext);

    useEffect(() => {
        dispatch(setParticipatingAgencies());

        const accessToken = auth.accessToken;

        if (accessToken && accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) {
            setEditable(true);
        }
    }, []);

    const handleEditDataSource = async (dataSource: IDataSource) => {
        const thunkActionResult = await dispatch(editDataSource(dataSource));
        if (thunkActionResult) {
            if ('error' in thunkActionResult) {
                setAlertSeverity('info');
                openGrowl(thunkActionResult.payload as string);
            } else {
                dispatch(participatingAgencyActions.editDataSource(dataSource));
                setAlertSeverity('success');
                openGrowl('Data Contributing Agency successfully updated!');
                setShowModal(false);
            }
        }
    };

    const handleDeleteDataSource = async () => {
        const thunkActionResult = await dispatch(deleteDataSource(editDataSourceObject as IDataSource));
        if (thunkActionResult) {
            if ('error' in thunkActionResult) {
                setAlertSeverity('info');
                openGrowl(thunkActionResult.payload as string);
            } else {
                dispatch(participatingAgencyActions.deleteDataSource(editDataSourceObject));
                setAlertSeverity('success');
                openGrowl(`\`${editDataSourceObject?.Name}\` successfully deleted!`);
                setConfirmDialogOpen(false);
            }
        }
    };

    const handleEditAction = (row: IParticipatingAgency) => {
        setShowModal(true);
        setEditDataSourceObject(row);
    };

    const handleDeleteAction = (row: IParticipatingAgency) => {
        setConfirmDialogOpen(true);
        setEditDataSourceObject(row);
    };

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const AgencyTableWithLoader = WithLoader<IAgencyTableProps>(AgencyTable, fetchStatus);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4">{COPIES.PARTICIPATING_AGENCIES_TITLE}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                        variant="fullWidth"
                        styleName="tabsWrapper"
                    >
                        <Tab label={COPIES.DATA_AGENCIES_TITLE} id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                        <Tab label={COPIES.USER_AGENCIES_TITLE} id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                    </Tabs>

                    <TabPanel value={tabValue} index={0}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Typography styleName="description">{COPIES.DATA_AGENCIES_DESCRIPTION}</Typography>
                            </Grid>
                            <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {editable && (
                                    <AddNewDataSourceButton
                                        dataSources={dataSources}
                                        openGrowl={openGrowl}
                                        setAlertSeverity={setAlertSeverity}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <AgencyTableWithLoader
                            rows={agencies.DataAgencies}
                            editable={editable}
                            editFunc={handleEditAction}
                            deleteFunc={handleDeleteAction}
                        />
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <Typography styleName="description">{COPIES.USER_AGENCIES_DESCRIPTION}</Typography>
                        <AgencyTableWithLoader rows={agencies.UserAgencies} />
                    </TabPanel>

                    {editable && (
                        <>
                            <CreateDataSourceDialog
                                open={showModal}
                                onClose={() => setShowModal(false)}
                                onSubmit={handleEditDataSource}
                                dataSources={dataSources}
                                editDataSource={editDataSourceObject}
                                action="Edit"
                            />
                            <ConfirmDialog
                                onCancel={() => setConfirmDialogOpen(false)}
                                onConfirm={handleDeleteDataSource}
                                open={confirmDialogOpen}
                                title={`Delete \`${editDataSourceObject?.Name}\`?`}
                                message="This Agency will no longer exist on OneCV."
                                cancelOption="Keep Agency"
                                confirmOption="Delete"
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={gridContainerRef}
            >
                <Alert
                    variant="filled"
                    style={{backgroundColor: 'rgb(49, 49, 49)', width: '100%'}}
                    severity={alertSeverity}
                    iconMapping={{success: <CheckCircleIcon />, info: <InfoIcon />}}
                >
                    {growl.message}
                </Alert>
            </AdminSnackbar>
        </>
    );
};
