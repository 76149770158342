import React from 'react';
import {Checkbox, Grid, TableCell, TableRow} from '@mui/material';
import {FavoriteRounded as FavoriteIcon, FavoriteBorderRounded as FavoriteBorderIcon} from '@mui/icons-material';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {WithEnhancedTable} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {ToolTip} from './ToolTip';
import {SOURCE_SYSTEM_TYPE_WAITING_PERIOD, SUBDOMAIN_ADDITIONAL_INFO} from '~/constants';

import './ScreenClientTable.scss';

export interface IRowData {
    agencyName: string;
    domainName: string;
    subDomainName: string;
    subDomainCode: string;
    sourceSystemType?: string;
    subDomainId: number;
    isDisabled: boolean;
    isSelected: boolean;
    isFavourite: boolean;
    isStigmatising: boolean;
    isLegislativelyLocked: boolean;
}

export type IHeadRowData = Pick<IRowData, 'agencyName' | 'domainName' | 'subDomainName'>;

export interface IScreenClientTableProps {
    allCheckBoxClickHandler: () => void;
    checkboxClickHandler: (subDomainId: number) => void;
    favouriteClickHandler: (subDomaainId: number, isFavourite: boolean) => void;
    rows: IRowData[];
}

interface IScreenClientTableRowsProps {
    checkboxClickHandler: (subDomainId: number) => void;
    favouriteClickHandler: (subDomaainId: number, isFavourite: boolean) => void;
    sortedRows: IRowData[];
}

const showToolTip = (row: IRowData) => {
    if (row.isDisabled) return <ToolTip />;

    const subdomainAdditionalInfo = row.subDomainName in SUBDOMAIN_ADDITIONAL_INFO;
    return subdomainAdditionalInfo && <ToolTip message={SUBDOMAIN_ADDITIONAL_INFO[row.subDomainName]} />;
};

const ScreenClientTableRows = ({
    sortedRows,
    checkboxClickHandler,
    favouriteClickHandler,
}: IScreenClientTableRowsProps) => {
    const handleSelectToggle = (subDomainId: number, isDisabled: boolean) => {
        !isDisabled && checkboxClickHandler(subDomainId);
    };

    const handleFavouriteToggle =
        (subDomainId: number, isFavourite: boolean, isDisabled: boolean) =>
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            !isDisabled && favouriteClickHandler(subDomainId, isFavourite);
            event.stopPropagation();
        };

    return (
        <>
            {sortedRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <TableRow
                        hover={!row.isDisabled}
                        onClick={() => handleSelectToggle(row.subDomainId, row.isDisabled)}
                        role="checkbox"
                        aria-checked={row.isSelected}
                        tabIndex={-1}
                        key={row.subDomainName}
                        selected={row.isSelected}
                        styleName={row.isDisabled ? 'tableRowDisabled' : 'tableRow'}
                    >
                        <TableCell
                            padding="none"
                            style={{
                                padding: 16,
                            }}
                        >
                            <Checkbox
                                checked={row.isSelected}
                                inputProps={{'aria-labelledby': labelId}}
                                disabled={row.isDisabled}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            styleName={row.isDisabled ? 'textDisabled' : ''}
                        >
                            {row.agencyName}
                        </TableCell>
                        <TableCell align="left" styleName={row.isDisabled ? 'textDisabled' : ''}>
                            {row.domainName}
                        </TableCell>
                        <TableCell align="left" styleName={row.isDisabled ? 'textDisabled' : ''}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Grid item xs={12}>
                                        {row.subDomainName}
                                    </Grid>
                                    {row.sourceSystemType && (
                                        <Grid item xs={12} styleName="secondaryText">
                                            {SOURCE_SYSTEM_TYPE_WAITING_PERIOD[row.sourceSystemType]}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    {showToolTip(row)}
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox
                                onClick={handleFavouriteToggle(row.subDomainId, row.isFavourite, row.isDisabled)}
                                disableRipple
                                checked={row.isFavourite}
                                checkedIcon={<FavoriteIcon style={{color: '#3949AB)'}} />}
                                icon={<FavoriteBorderIcon style={{color: '#ccc'}} />}
                                color="primary"
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const ScreenClientTable = ({
    rows,
    checkboxClickHandler,
    favouriteClickHandler,
    allCheckBoxClickHandler,
}: IScreenClientTableProps) => {
    const selectedSubDomainNum = (rowsData: IRowData[]) => {
        const selected = rowsData.filter((row) => row.isSelected);
        return selected.length;
    };

    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {id: 'agencyName', align: 'inherit', disablePadding: true, label: 'Agency'},
        {id: 'domainName', align: 'inherit', disablePadding: false, label: 'Domain'},
        {id: 'subDomainName', align: 'inherit', disablePadding: false, label: 'Sub-Domain'},
        {align: 'center', disablePadding: false, label: 'Favourite'},
    ];

    const headerProps = {
        showCheckbox: true,
        onSelectAllClick: allCheckBoxClickHandler,
        numSelected: selectedSubDomainNum(rows),
        columns: headColumns,
    };

    const hocProps = {
        rows,
        colSpan: 6,
        headerProps,
    };

    const passThroughProps = {
        checkboxClickHandler,
        favouriteClickHandler,
    };

    const props = {
        ...hocProps,
        ...passThroughProps,
    };

    const EnhancedTable = WithEnhancedTable<IScreenClientTableRowsProps, IHeadRowData, IRowData>(
        ScreenClientTableRows,
        'agencyName',
        rows,
    );

    return <EnhancedTable {...props} />;
};
