import {createTheme} from '@mui/material/styles';
import {buttonTheme} from './ButtonTheme';
import {tableTheme} from './TableTheme';
import {typographyTheme} from './TypographyTheme';

export const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: '#3949ab',
        },
    },
    components: {
        MuiButton: buttonTheme,
        MuiTypography: typographyTheme,
        MuiTableCell: tableTheme,
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '42px!important',
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #FAFAFA',
                    paddingBottom: '8px',
                    marginBottom: 0,
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    minHeight: '300px',
                    '[role=grid]': {
                        height: '240px',
                        overflowY: 'scroll',
                    },
                },
            },
        },
        MuiMonthPicker: {
            styleOverrides: {
                root: {
                    marginTop: '24px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    justifyItems: 'center',
                    alignItems: 'center',
                    '> button': {
                        margin: '4px',
                    },
                },
            },
        },
        PrivatePickersMonth: {
            styleOverrides: {
                root: {
                    height: '40px',
                    padding: 0,
                    flex: null,
                    width: '50px',
                    borderRadius: '100px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    fontSize: '16px',
                    '&:hover': {
                        background: '#DEDEDE',
                        fontWeight: 500,
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#3949AB',
                        color: 'rgba(255, 255, 255, 0.87)',
                    },
                },
            },
        },
        PrivatePickersYear: {
            styleOverrides: {
                root: {
                    marginTop: '24px',
                },
                button: {
                    width: '52px',
                    height: '30px',
                    padding: '8px 12px',
                    margin: 0,
                    borderRadius: '100px',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '14px',
                    '&:hover': {
                        background: '#DEDEDE',
                        fontWeight: 500,
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#3949AB',
                        color: 'rgba(255, 255, 255, 0.87)',
                    },
                },
            },
        },
    },
});
