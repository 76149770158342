import React from 'react';
import Rating, {IconContainerProps} from '@mui/lab/Rating';
import {withStyles} from '@mui/styles';
import {
    SentimentVeryDissatisfiedRounded as SentimentVeryDissatisfiedIcon,
    SentimentDissatisfiedRounded as SentimentDissatisfiedIcon,
    SentimentSatisfiedRounded as SentimentSatisfiedIcon,
    SentimentSatisfiedAltRounded as SentimentSatisfiedAltIcon,
    SentimentVerySatisfiedRounded as SentimentVerySatisfiedIcon,
} from '@mui/icons-material';
import './RatingGroup.scss';

const StyledRating = withStyles({
    icon: {
        color: '#e4e4e4',
    },
    iconFilled: {
        color: '#ffb85c',
    },
    iconHover: {
        color: '#ffb85c',
    },
})(Rating);

interface IRatingIcon {
    icon: React.ReactElement;
    label: string;
}

const customIcons: {[key: string]: IRatingIcon} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};

export const IconContainer: React.FC<IconContainerProps> = ({value, ...other}: IconContainerProps) => {
    return (
        <>
            <div {...other} styleName="iconContainer" data-testid={`rating-${value}`}>
                {customIcons[value].icon}
            </div>
            {value === 1 && <div styleName="iconLabel">Very bad</div>}
            {value === 5 && <div styleName="iconLabel">Very good</div>}
        </>
    );
};

interface IRatingGroupProps {
    name: string;
    onChange: (e: React.ChangeEvent<{}>, rating: number | null) => void;
}

export const RatingGroup = ({name, onChange}: IRatingGroupProps) => {
    return (
        <StyledRating
            name={name}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={IconContainer}
            onChange={onChange}
            styleName="ratingGroup"
        />
    );
};
