import serialize from 'serialize-javascript';

interface IApi {
    ACCESS_CONTROL_SERVICE: string;
    AUTH_SERVICE: string;
    CLIENT_DATA_SERVICE: string;
    ADMIN_SERVICE: string;
    DATA_UPLOAD_SERVICE: string;
    DATA_UPLOAD_DECRYPT_SERVICE: string;
}

export interface IFeatureFlags {
    ENABLE_CANVAS?: boolean;
    ENABLE_ADVISORY?: boolean;
    ENABLE_DAM2_VIEW?: boolean;
    ENABLE_DAME2_EDIT_OA?: boolean;
    ENABLE_DAME2_EDIT_AA?: boolean;
    ENABLE_DAM2?: boolean;
    ENABLE_DAM2_DCA?: boolean;
    ENABLE_DAM2_DFM?: boolean;
    ENABLE_DAM2_OA_SDL_UPDATEINFO?: boolean;
}

interface IConfig {
    api: IApi;
    authenticationMode: 'MOCK' | 'CORPPASS' | 'ADFS';
    featureFlags: IFeatureFlags;
    environment: string;
    internetEnabled: boolean;
    maintenance: boolean;
    compatibleBrowserVersions: {[key: string]: string};
}

export let config: IConfig = {} as IConfig;
export const loadConfig = async () => {
    if (process.env.NODE_ENV === 'development') {
        config = (await require('../../config.json')) as IConfig;
    } else {
        // NOTE: single quotes are converted to double quotes on build
        const loadedConfig = serialize('__CONFIG_VALUES__');
        config = (await JSON.parse(loadedConfig)) as IConfig;
    }
};
