import React from 'react';
import {Grid, Typography, Button} from '@mui/material';
import emptyDashboard from '~/assets/images/emptyDashboard.svg';
import './EmptyDashboard.scss';
import {ACCESS_LEVELS} from '~/constants';

interface IEmptyDashboardProps {
    accessLevel: ACCESS_LEVELS;
    handleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
}

interface IMessage {
    welcomeMessage: string;
    buttonMessage: string;
}

export const getMessage = (accessLevel: ACCESS_LEVELS): IMessage => {
    switch (accessLevel) {
        case ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR:
        case ACCESS_LEVELS.SYSTEM_ADMINISTRATOR:
            return {
                welcomeMessage: 'Before you get to see active users, you’ll need to add admins to the agencies.',
                buttonMessage: 'Go to Admin Management',
            };
        case ACCESS_LEVELS.AGENCY_ADMINISTRATOR:
            return {
                welcomeMessage:
                    'Before you get to see active users, you’ll need to add your Ops Units and their admins.',
                buttonMessage: 'Add Ops Unit',
            };
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER:
        case ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR:
            return {
                welcomeMessage: 'Before you get to see active users, you’ll need to add your Teams and their admins.',
                buttonMessage: 'Add Teams',
            };
        default:
            return {} as IMessage;
    }
};

export const EmptyDashboard: React.FunctionComponent<IEmptyDashboardProps> = ({accessLevel, handleClick}) => {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
                <img src={emptyDashboard} alt="Empty dashboard" styleName="image" />
            </Grid>
            <Grid item>
                <Typography data-testid="generalMessage" component="h1" variant="h4">
                    Start building your dashboard!
                </Typography>
            </Grid>
            <Grid item>
                <Typography data-testid="welcomeMessage" component="h1" variant="subtitle1">
                    {getMessage(accessLevel).welcomeMessage}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    data-testid="button"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                    styleName="adminManagementButton"
                >
                    {getMessage(accessLevel).buttonMessage}
                </Button>
            </Grid>
        </Grid>
    );
};
