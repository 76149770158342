import React from 'react';
import {Grid, Typography} from '@mui/material';
import styles from './HousingSchemes.scss';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {getAlphabeticalComparator} from '~/components/Common/ListModel/comparators';
import {COLORS} from '~/components/Common/Colors';
import {EASE} from '../Common/Schemes';

export interface IRentalHousingSchemes {
    DirectApp: string;
    HomeImprovementProgram: string;
    InterimRentalHousing: string;
    ParenthoodProvisionalHousing: string;
}

interface IAssistanceTableData {
    Id?: number;
    Scheme: string;
    Indicator: string[];
    // Amount: string; Exclude this field for now, info not ready
}
export const HousingSchemes: React.FunctionComponent<{data?: IRentalHousingSchemes}> = ({data}) => {
    if (!data) return <></>;
    const formattedTableData = [
        {
            Id: 1,
            Scheme: 'Parenthood Provisional Housing Scheme (PPHS)',
            Indicator: [data.ParenthoodProvisionalHousing],
        },
        {
            Id: 2,
            Scheme: EASE,
            Indicator: [data.DirectApp, data.HomeImprovementProgram],
        },
        {
            Id: 3,
            Scheme: 'Interim Rental Housing',
            Indicator: [data.InterimRentalHousing],
        },
    ];

    const scheme = (info: IAssistanceTableData) => {
        if (info.Scheme === EASE) {
            return (
                <>
                    <Grid container styleName="easeTitle">
                        <Typography variant="body2">{EASE}</Typography>
                    </Grid>

                    <Typography variant="body2">
                        <i>- Direct Application</i>
                    </Typography>
                    <Typography variant="body2">
                        <i>- Home Improvement Program</i>
                    </Typography>
                </>
            );
        }
        return info.Scheme;
    };
    const schemeValue = (info: IAssistanceTableData) => info.Scheme;
    const indicator = (info: IAssistanceTableData) => {
        if (info.Scheme === EASE) {
            const directApp = info.Indicator[0];
            const homeImprovement = info.Indicator[1];
            return (
                <div>
                    <Grid styleName="easeTitle">
                        <Typography variant="body2">&nbsp;</Typography>
                    </Grid>
                    <Typography
                        data-testid="directApp"
                        variant="body2"
                        styleName="indicator"
                        style={{color: directApp == 'Yes' ? COLORS.GREEN : ''}}
                    >
                        {directApp}
                    </Typography>
                    <Typography
                        data-testid="homeImprovement"
                        variant="body2"
                        styleName="indicator"
                        style={{color: homeImprovement == 'Yes' ? COLORS.GREEN : ''}}
                    >
                        {homeImprovement}
                    </Typography>
                </div>
            );
        }
        return (
            <div>
                <Typography
                    variant="body2"
                    styleName="indicator"
                    style={{color: info.Indicator[0] == 'Yes' ? COLORS.GREEN : ''}}
                >
                    {info.Indicator}
                </Typography>
            </div>
        );
    };
    const indicatorValue = (info: IAssistanceTableData) => info.Indicator[0];

    return (
        <Grid item xs={12} id={styles.table}>
            <div id={styles.customTable}>
                <ListModel
                    columns={[scheme, indicator]}
                    headers={['Schemes', 'Indicator']}
                    modelList={formattedTableData}
                    modelComparator={[
                        getAlphabeticalComparator(schemeValue),
                        getAlphabeticalComparator(indicatorValue),
                    ]}
                    variant="expanded"
                />
            </div>
        </Grid>
    );
};
