import React from 'react';
import {IDashboardBasic} from '~/interfaces/dashboard';
import {Grid, Typography} from '@mui/material';
import {DashboardCard, CardType} from '~/components/Admin/Dashboard/DashboardCard';
import {ACCESS_LEVELS} from '~/constants';

interface IDashboardBasicProps {
    accessLevel: ACCESS_LEVELS;
    basicInfo: IDashboardBasic;
}

export const DashboardBasic: React.FunctionComponent<IDashboardBasicProps> = ({
    accessLevel,
    basicInfo,
}: IDashboardBasicProps) => {
    const {
        AgencyInfo,
        OpsUnitInfo,
        TeamInfo,
        UsersInfo,
        SearchesInfo,
        ScreeningsInfo,
        AgencyAdminInfo,
        OpsUnitAdminInfo,
        TeamAdminInfo,
    } = basicInfo;

    const format = (info: string): string => {
        const formatted = parseInt(info, 10).toLocaleString();
        if (formatted == 'NaN') {
            return info;
        }
        return formatted;
    };

    return (
        <Grid container spacing={1} data-testid="basicInfoSection">
            <Grid item lg={12}>
                <Typography
                    style={{
                        fontWeight: 500,
                        fontSize: '20px',
                        color: '#3C3C3C',
                    }}
                >
                    Overview
                </Typography>
            </Grid>
            <Grid item lg={3}>
                <DashboardCard
                    title={accessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ? 'Agencies' : 'Your Agency'}
                    value={format(AgencyInfo)}
                    cardType={CardType.AGENCY}
                    data-testid="agencyCard"
                />
            </Grid>
            <Grid item lg={3}>
                <DashboardCard
                    title={
                        accessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
                        accessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR
                            ? 'Ops Units'
                            : 'Your Ops Unit'
                    }
                    value={format(OpsUnitInfo)}
                    cardType={CardType.OPSUNIT}
                    data-testid="opsUnitCard"
                />
            </Grid>
            <Grid item lg={3}>
                <DashboardCard title="Teams" value={format(TeamInfo)} cardType={CardType.TEAM} data-testid="teamCard" />
            </Grid>
            <Grid item lg={12}>
                <Typography
                    style={{
                        marginTop: '24px',
                        fontWeight: 500,
                        fontSize: '20px',
                        color: '#3C3C3C',
                    }}
                >
                    Admins and Users
                </Typography>
            </Grid>
            {(accessLevel === ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR ||
                accessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) && (
                <Grid item lg={3}>
                    <DashboardCard
                        title="Agency Admins"
                        value={format(AgencyAdminInfo)}
                        cardType={CardType.USER}
                        data-testid="adminsCard"
                    />
                </Grid>
            )}
            {(accessLevel === ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR ||
                accessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
                accessLevel === ACCESS_LEVELS.AGENCY_ADMINISTRATOR) && (
                <Grid item lg={3}>
                    <DashboardCard
                        title="Ops Unit Admins"
                        value={format(OpsUnitAdminInfo)}
                        cardType={CardType.USER}
                        data-testid="adminsCard"
                    />
                </Grid>
            )}
            <Grid item lg={3}>
                <DashboardCard
                    title="Team Admins"
                    value={format(TeamAdminInfo)}
                    cardType={CardType.USER}
                    data-testid="adminsCard"
                />
            </Grid>
            <Grid item lg={3}>
                <DashboardCard
                    title="Users"
                    value={format(UsersInfo)}
                    cardType={CardType.USER}
                    data-testid="userCard"
                />
            </Grid>

            {(accessLevel === ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR ||
                accessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) && (
                <>
                    <Grid item lg={12}>
                        <Typography
                            style={{
                                marginTop: '24px',
                                fontWeight: 500,
                                fontSize: '20px',
                                color: '#3C3C3C',
                            }}
                        >
                            User Activities
                        </Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <DashboardCard
                            title="User Searches"
                            value={format(SearchesInfo)}
                            cardType={CardType.SEARCH}
                            data-testid="searchesCard"
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <DashboardCard
                            title="User Screenings"
                            value={format(ScreeningsInfo)}
                            cardType={CardType.SCREENING}
                            data-testid="screeningsCard"
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
