import {createSlice} from '@reduxjs/toolkit';
import {IDataAccessMatrix2State} from '../dataReducers';

export const initialDataAccessMatrix2State: IDataAccessMatrix2State = {
    opsUnits: {},
    domainDataRequestState: {
        FormMeta: {
            State: '',
            FormId: 0,
        },
        OpsUnitSubdomainRequestState: [],
        RequestedFields: [],
    },
    subdomainDataRequestFields: {},
    subdomainDataRequestReason: {},
    fieldsUpdated: false,
    fieldsAdded: false,
};

export const dataAccessMatrix2Slice = createSlice({
    name: 'dataAccessMatrix2',
    initialState: initialDataAccessMatrix2State,
    reducers: {
        SET_OPS_UNIT_DATA_ACCESS_MATRIX(state, action) {
            const opsUnitId = Object.keys(action.payload) && parseInt(Object.keys(action.payload)[0]);
            const values = action.payload[opsUnitId];
            if (state.opsUnits[opsUnitId]) {
                const data = [];
                data[opsUnitId] = {...state.opsUnits[opsUnitId], ...values};
                return {
                    ...state,
                    opsUnits: {
                        ...state.opsUnits,
                        ...data,
                    },
                };
            }
            return {
                ...state,
                opsUnits: {
                    ...state.opsUnits,
                    ...action.payload,
                },
            };
        },
        SET_DATA_FIELD(state, action) {
            state.opsUnits[action.payload.opsUnitId].dataFieldRequestStatuses[action.payload.domainId][
                action.payload.subDomainId
            ][action.payload.fieldId] = action.payload.selected;
        },
        SET_REJECT_DATA_FIELDS(state, action) {
            const {dataFields, opsUnitId, domainId, subDomainId} = action.payload;
            dataFields.forEach((df: number) => {
                state.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId][df] = {
                    ...state.opsUnits[opsUnitId].dataFieldRequestStatuses[domainId][subDomainId][df],
                    requestStatus: 'rejected',
                };
                state.opsUnits[opsUnitId].initialMatrix.dataFieldRequestStatuses[domainId][subDomainId][df] = {
                    ...state.opsUnits[opsUnitId].initialMatrix.dataFieldRequestStatuses[domainId][subDomainId][df],
                    requestStatus: 'rejected',
                };
            });
        },
        SET_GROUPED_FIELDS(state, action) {
            action.payload.forEach(
                (datafield: {
                    opsUnitId: number;
                    domainId: number;
                    subDomainId: number;
                    fieldId: number;
                    selected: boolean;
                }) => {
                    state.opsUnits[datafield.opsUnitId].dataFieldRequestStatuses[datafield.domainId][
                        datafield.subDomainId
                    ][datafield.fieldId].checked = datafield.selected;
                },
            );
        },
        SET_DOMAIN_DATA_REQUEST_STATE(state, action) {
            const ddrs = action.payload.domainDataRequestState;
            state.domainDataRequestState = ddrs;
        },
        SET_SUBDOMAIN_DATA_REQUEST_FIELDS(state, action) {
            const subdomainId: number = action.payload.subDomainId;
            const fields: number[] = [...action.payload.fields];

            if (isNaN(subdomainId)) {
                return state;
            }
            return {
                ...state,
                subdomainDataRequestFields: {
                    ...state.subdomainDataRequestFields,
                    [subdomainId]: fields,
                },
            };
        },
        SET_SUBDOMAIN_DATA_REQUEST_REASON_FOR_ACCESS(state, action) {
            const subdomainId: number = action.payload.subDomainId;
            const reasonForAccess: string = action.payload.reasonForAccess;

            return {
                ...state,
                subdomainDataRequestReason: {
                    ...state.subdomainDataRequestReason,
                    [subdomainId]: reasonForAccess,
                },
            };
        },
        SET_SUBDOMAIN_REASON_STATUS(state, action) {
            const {opsUnitId, domainId, subDomainId, status} = action.payload;
            const updatedReasonForAccess =
                state.opsUnits[opsUnitId].subDomainReason[domainId][subDomainId].updatedReasonForAccess;
            if (updatedReasonForAccess != undefined) {
                updatedReasonForAccess.status = status;
            }
        },
        SET_FIELDS_UPDATED(state, action) {
            const fieldsUpdated = action.payload.fieldsUpdated;

            return {
                ...state,
                fieldsUpdated: fieldsUpdated,
            };
        },
        SET_FIELDS_ADDED(state, action) {
            const fieldsAdded = action.payload.fieldsAdded;

            return {
                ...state,
                fieldsAdded: fieldsAdded,
            };
        },
    },
});

export const dataAccessMatrix2Actions = dataAccessMatrix2Slice.actions;
export default dataAccessMatrix2Slice.reducer;
