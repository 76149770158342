import React from 'react';
import {List, ListItem, Typography, Collapse} from '@mui/material';
import {IReleaseNoteSection} from '~/interfaces/common';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {ReleaseNoteTable} from '~/components/Common/FooterResources/ReleaseNoteTable';
import './ReleaseNoteDetail.scss';
export interface IOnExpand {
    (): void;
}

export interface IReleaseNoteDetailProps {
    releaseNoteSection?: IReleaseNoteSection[];
    title: string;
    expand?: boolean;
    icon?: JSX.Element;
    onExpand?: IOnExpand;
}

export const ReleaseNoteDetail = ({releaseNoteSection, title, expand, icon, onExpand}: IReleaseNoteDetailProps) => {
    return (
        <List>
            <ListItem button onClick={onExpand} styleName="expandHeader">
                <div styleName="sectionTitleContainer">
                    <span styleName="sectionTitle">
                        {icon}
                        <Typography variant="body1" styleName="expandTitle">
                            {title}
                        </Typography>
                        <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                    </span>
                </div>
            </ListItem>
            <Collapse in={expand} timeout={500} unmountOnExit>
                {releaseNoteSection && <ReleaseNoteTable rows={releaseNoteSection} />}
            </Collapse>
        </List>
    );
};
