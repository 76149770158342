/* eslint-disable react/display-name */
import React from 'react';
import styles from './SchoolingRecords.scss';
import {Grid} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {getNumericComparator, getAlphabeticalComparator} from '~/components/Common/ListModel/comparators';
import {allNoAccess, allNoResults, extractAllValues, isNotEmpty} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {FIELD_VALUES} from '~/constants';
import {StatusObject} from '../SubDomainDisplay';
import {SubSectionStatusWrapper} from '../SubSectionStatusWrapper';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {SubDomainStatus} from '~/interfaces/client';

enum SOURCES {
    SFTP_DOS = 'SFTP_DOS',
    MOE = 'MOE',
}

export type ISchoolingDetailsData = {
    PastSchoolRecords: IPastSchoolRecord[];
    SchoolAttendance: ISchoolAttendance;
};

interface ISchoolAttendance {
    SchoolName: string;
    SchoolPrincipal: string;
    SchoolStatus: string;
    SchoolStudentCare: string;
    FirstAy: string;
    FirstAyAttendance: string;
    SecondAy: string;
    SecondAyAttendance: string;
    SupportSchoolCounsellor: string;
}

interface IPastSchoolRecord {
    Id?: number;
    SchoolAttended: string;
    CertificateAttained: string;
    CertificateAttainedYear: string;
}

export const certificateOrder = ["'A' Level", "'O' Level", "'N' Level", 'PSLE'];

const getYearValue = (yearString: string): number =>
    yearString === (FIELD_VALUES.NO_RESULTS || FIELD_VALUES.NO_ACCESS) ? 0 : +yearString;

export const SchoolingRecords: React.FunctionComponent<{data: ISchoolingDetailsData; status: StatusObject}> = ({
    data,
    status,
}) => {
    const showSchoolAttendance = !allNoAccess(extractAllValues(data.SchoolAttendance));
    const showPastSchoolingRecords = !allNoAccess(extractAllValues(data.PastSchoolRecords));

    return (
        <>
            {/* Remove this condition - check status - after api stabilises in July, condition is for backward compability */}
            {showSchoolAttendance && status[SOURCES.MOE] && (
                <SubSectionStatusWrapper
                    name="Current Schooling Records"
                    status={status[SOURCES.MOE]}
                    hasData={Boolean(data.SchoolAttendance)}
                >
                    <SchoolAttendanceComponent data={data.SchoolAttendance} status={status[SOURCES.MOE]} />
                </SubSectionStatusWrapper>
            )}
            {showSchoolAttendance && showPastSchoolingRecords && (
                <hr style={{marginTop: '40px', marginBottom: '34px'}} />
            )}
            {showPastSchoolingRecords && (
                <SubSectionStatusWrapper
                    name="Past Schooling Records"
                    status={status[SOURCES.SFTP_DOS]}
                    hasData={Boolean(data.PastSchoolRecords)}
                >
                    <PastSchoolRecordsComponent pastSchoolRecords={data.PastSchoolRecords} />
                </SubSectionStatusWrapper>
            )}
        </>
    );
};

const PastSchoolRecordsComponent: React.FC<{pastSchoolRecords: IPastSchoolRecord[]}> = ({pastSchoolRecords}) => {
    const sortedRecords = [...pastSchoolRecords];
    sortedRecords.sort((r1, r2) => getYearValue(r2.CertificateAttainedYear) - getYearValue(r1.CertificateAttainedYear));

    const noData = allNoResults(extractAllValues(sortedRecords));
    if (noData) return <NoData />;

    return (
        <div id={styles.customTable}>
            <ListModel
                columns={[(i) => i.SchoolAttended, (i) => i.CertificateAttained, (i) => i.CertificateAttainedYear]}
                headers={['Past Schools Attended', 'Certificate Attained', 'Year Attained']}
                modelList={sortedRecords}
                modelComparator={[
                    getAlphabeticalComparator((i) => i.SchoolAttended),
                    getNumericComparator((i) => Number(certificateOrder.indexOf(i.CertificateAttained))),
                    getNumericComparator((i) =>
                        isNotEmpty(i.CertificateAttainedYear) ? Number(i.CertificateAttainedYear) : 0,
                    ),
                ]}
                variant="expanded"
                defaultSortIndex={2}
                defaultSortOrder="desc"
                dataTestId="schoolingRecords"
            />
        </div>
    );
};

const renderYesNoField = (value: string) => {
    if (value === FIELD_VALUES.NO_ACCESS || value === FIELD_VALUES.NO_RESULTS) return value;
    if (value === 'Y') return 'Yes';
    else return 'No';
};

const renderSchoolStatus = (value: string) => {
    if (value === FIELD_VALUES.NO_ACCESS || value === FIELD_VALUES.NO_RESULTS) return value;
    if (value === 'A') return 'Active';
    else return 'Inactive';
};

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

const SchoolAttendanceComponent: React.FC<{data: ISchoolAttendance; status: SubDomainStatus}> = ({data, status}) => {
    const noData = status === SubDomainStatus.COMPLETED && allNoResults(extractAllValues(data));
    if (noData) return <NoData />;

    const {
        SchoolStatus,
        SchoolName,
        SchoolPrincipal,
        FirstAy,
        SecondAy,
        FirstAyAttendance,
        SecondAyAttendance,
        SchoolStudentCare,
        SupportSchoolCounsellor,
    } = data;

    const gridFields = [
        {label: 'Current School Status', value: renderSchoolStatus(SchoolStatus)},
        {label: 'Name of Current School', value: SchoolName},
        {label: 'Name of School Principal', value: SchoolPrincipal},
    ];

    // The spaces here are important, as we are using css, whitespace:'pre' property here
    const tableFields = [
        {
            Id: 1,
            label: `Attained 80% of School Attendance\n\n${FirstAy}\n${SecondAy}`,
            value: `\n\n${renderYesNoField(FirstAyAttendance)}\n${renderYesNoField(SecondAyAttendance)}`,
        },
        {
            Id: 2,
            label: 'Enrolment in school-based student care',
            value: capitalize(SchoolStudentCare),
        },
        {
            Id: 3,
            label: 'Supported by School Counsellor / Student Welfare Officer',
            value: renderYesNoField(SupportSchoolCounsellor),
        },
    ];

    return (
        <>
            <Grid container spacing={3}>
                <SimpleGridDisplay fields={gridFields} />
            </Grid>
            {!allNoAccess([
                FirstAy,
                SecondAy,
                FirstAyAttendance,
                SecondAyAttendance,
                SchoolStudentCare,
                SupportSchoolCounsellor,
            ]) && (
                <div id={styles.customTable} style={{marginTop: '24px'}}>
                    <ListModel
                        customRowStyle={(): React.CSSProperties => ({whiteSpace: 'pre'})}
                        columns={[(i) => i.label, (i) => i.value]}
                        headers={['Indicators', 'Yes/No']}
                        modelList={tableFields}
                        modelComparator={[getAlphabeticalComparator((i) => i.label), () => 0]}
                        variant="expanded"
                        defaultSortIndex={0}
                        defaultSortOrder="desc"
                        dataTestId="currentSchoolingRecords"
                    />
                </div>
            )}
        </>
    );
};
