import React, {useState} from 'react';
import {Close, Info} from '@mui/icons-material';

import './CustomAlert.scss';
import {Typography} from '@mui/material';

export interface ICustomAlertProps {
    isDismissable?: boolean;
    children?: React.ReactNode;
}

export const CustomAlert = ({children, isDismissable = true}: ICustomAlertProps) => {
    const [isClosed, setIsClosed] = useState(false);

    const handleClose = () => {
        setIsClosed(true);
    };

    return (
        <div styleName={`alert ${isClosed ? 'hidden' : ''}`}>
            <Info fontSize="inherit" styleName="alertInfo" />
            <Typography styleName="alertText">{children}</Typography>
            {isDismissable && <Close fontSize="inherit" styleName="alertClose" onClick={handleClose}></Close>}
        </div>
    );
};
