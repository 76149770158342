import React from 'react';
import './ToolTipModalPopper.scss';
import {Button} from '@mui/material';
import {COPIES} from '~/constants';

export interface IToolTipModalPopper {
    displayType: string;
    onClose: () => void;
}

export const ToolTipModalPopper = React.forwardRef<HTMLDivElement, IToolTipModalPopper>(
    ({displayType, onClose}, ref) => {
        return (
            <div styleName="toolTipModalPopper" ref={ref}>
                <div styleName="displayMsg">
                    {displayType === COPIES.CONSENT_TITLE ? (
                        <>
                            When requesting for sub-domains with <b>legislatively-locked data</b>, you’ll be asked to
                            indicate whether consent has been given, and if so, the type of consent shared.
                        </>
                    ) : (
                        <>
                            When requesting for sub-domains with <b>sensitive information</b>, you’ll be asked to
                            indicate the reasons for accessing the information of each individual.
                        </>
                    )}
                </div>
                <div styleName="gotItButton">
                    <Button color="primary" variant="contained" onClick={onClose}>
                        GOT IT
                    </Button>
                </div>
            </div>
        );
    },
);
