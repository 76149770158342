import {createSlice} from '@reduxjs/toolkit';
import {IFetchState} from '../dataReducers';

export const initialFetchState: IFetchState = {
    dataDictionaryFields: null,
    dataSources: null,
    dataContributors: null,
    resources: null,
    participatingAgencies: null,
    systemAdmins: null,
    agencies: null,
    agency: null,
    domains: null,
    dashboard: null,
    opsUnit: null,
    opsUnits: null,
    screenedClientInfo: null,
    team: null,
    teams: null,
    users: null,
    clientUser: null,
    damOpsUnit: null,
    damFields: null,
    releaseNotes: null,
};

export const fetchStateSlice = createSlice({
    name: 'fetchState',
    initialState: initialFetchState,
    reducers: {
        SET_DASHBOARD_FETCH_STATE: (state, action) => {
            state.dashboard = action.payload;
        },
        SET_SYSTEMADMINS_FETCH_STATE: (state, action) => {
            state.systemAdmins = action.payload;
        },
        SET_USERS_FETCH_STATE: (state, action) => {
            state.users = action.payload;
        },
        SET_TEAMS_FETCH_STATE: (state, action) => {
            state.teams = action.payload;
        },
        SET_TEAM_FETCH_STATE: (state, action) => {
            state.team = action.payload;
        },
        SET_OPSUNITS_FETCH_STATE: (state, action) => {
            state.opsUnits = action.payload;
        },
        SET_OPSUNIT_FETCH_STATE: (state, action) => {
            state.opsUnit = action.payload;
        },
        SET_AGENCIES_FETCH_STATE: (state, action) => {
            state.agencies = action.payload;
        },
        SET_DOMAINS_FETCH_STATE: (state, action) => {
            state.domains = action.payload;
        },
        SET_DATACONTRIBUTORS_FETCH_STATE: (state, action) => {
            state.dataContributors = action.payload;
        },
        SET_SCREENED_CLIENT_INFO_FETCH_STATE: (state, action) => {
            state.screenedClientInfo = action.payload;
        },
        SET_DAM_OPS_UNITS_FETCH_STATE: (state, action) => {
            state.damOpsUnit = action.payload;
        },
        SET_DAM_FIELDS_FETCH_STATE: (state, action) => {
            state.damFields = action.payload;
        },
        SET_RESOURCES_FETCH_STATE: (state, action) => {
            state.resources = action.payload;
        },
        SET_PARTICIPATING_AGENCY_FETCH_STATE: (state, action) => {
            state.participatingAgencies = action.payload;
        },
        SET_DATA_SOURCE_FETCH_STATE: (state, action) => {
            state.dataSources = action.payload;
        },
        SET_RELEASE_NOTE_FETCH_STATE: (state, action) => {
            state.releaseNotes = action.payload;
        },
    },
});

export const fetchStateActions = fetchStateSlice.actions;
export default fetchStateSlice.reducer;
