import {createSlice} from '@reduxjs/toolkit';
import {IUser} from '~/interfaces/admin';
import {setSystemAdmins} from './systemAdmin.thunk';

export interface ISystemAdminState {
    systemAdmins: IUser[];
    loading: boolean;
}

export const initialSystemAdminsState: ISystemAdminState = {
    systemAdmins: [],
    loading: false,
};

export const systemAdminsSlice = createSlice({
    name: 'systemAdmins',
    initialState: initialSystemAdminsState,
    reducers: {
        addSystemAdmin(state, action) {
            if (action.payload) {
                state.systemAdmins = [...state.systemAdmins, action.payload];
            }
        },
        updateSystemAdmin(state, action) {
            if (action.payload) {
                const saToUpdate = state.systemAdmins.findIndex((sa) => sa.Id === action.payload.Id);
                if (saToUpdate > -1) {
                    state.systemAdmins[saToUpdate] = action.payload;
                }
            }
        },
        activateSystemAdmin(state, action) {
            if (action.payload) {
                const saToUpdate = state.systemAdmins.findIndex((sa) => sa.Id === action.payload.userId);
                if (saToUpdate > -1) {
                    state.systemAdmins[saToUpdate] = {...state.systemAdmins[saToUpdate], IsActive: true};
                }
            }
        },
        deleteSystemAdmin(state, action) {
            if (action.payload) {
                state.systemAdmins = state.systemAdmins.filter((sa) => sa.Id !== action.payload.userId);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setSystemAdmins.fulfilled, (state, action) => {
            if (action.payload) {
                state.systemAdmins = action.payload as IUser[];
            }
        });
    },
});

export const systemAdminsActions = systemAdminsSlice.actions;
export default systemAdminsSlice.reducer;
