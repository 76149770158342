import React, {useEffect} from 'react';
import {InputField} from '~/components/Common/InputField/InputField';
import {required, checkVersionNumber, checkMaxLength} from '../../Common/InputField/ValidationRules';
import './ReleaseNoteDetails.scss';
import {DEFAULT_SHORT_FIELD_MAX_LENGTH} from '~/constants';
import {DateInput} from '~/components/Common/DateInput/DateInput';

interface IReleaseNoteDetails {
    versionNumber?: string;
    versionNumberLabel?: string;
    setVersionNumber: React.Dispatch<React.SetStateAction<string>>;
    releaseDate: string;
    setReleaseDate: React.Dispatch<React.SetStateAction<string>>;
    dateLabel?: string;
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ReleaseNoteDetails = ({
    versionNumber,
    versionNumberLabel = 'Release Number',
    setVersionNumber,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    releaseDate,
    setReleaseDate,
    setHasError,
}: IReleaseNoteDetails) => {
    useEffect(() => {
        (versionNumber && versionNumber?.length > DEFAULT_SHORT_FIELD_MAX_LENGTH) || !releaseDate
            ? setHasError(true)
            : setHasError(false);
    }, [versionNumber, releaseDate]);
    return (
        <>
            <div styleName="inputsWrapper">
                <InputField
                    styleName="formInput"
                    htmlFor={`versionNumberInput`}
                    value={versionNumber as string}
                    onChange={setVersionNumber}
                    label={versionNumberLabel}
                    validations={[required('release number'), checkVersionNumber('release number')]}
                    customErrorMessage={checkMaxLength(
                        versionNumber as string,
                        'Version Number',
                        DEFAULT_SHORT_FIELD_MAX_LENGTH,
                    )}
                />
                <div styleName="releaseDateWrapper">
                    <DateInput
                        label="Date of Release"
                        value={releaseDate ? releaseDate : new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(value) => {
                            setReleaseDate(value as string);
                        }}
                        hasError={setHasError}
                        allowFutureDate={true}
                    />
                </div>
            </div>
        </>
    );
};
