import React, {useContext, useState, useEffect} from 'react';
import {Box, Checkbox, TableCell, TableRow, Typography, Table, TableBody} from '@mui/material';
import {ITeam} from '~/interfaces/admin';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import './AgencyList.scss';
import {FunctionContext} from './AgencyList';

export interface ITeamsProps {
    team: ITeam;
    expandTeams: boolean;
}

export const TeamsRow = ({team, expandTeams}: ITeamsProps) => {
    const [checked, setChecked] = useState(team.CanScreenClient);
    const functionContext = useContext(FunctionContext);

    const handleTeamCheckBox = () => {
        functionContext.onCheckBoxClick('Team', team.Id, checked);
    };

    useEffect(() => {
        if (expandTeams) {
            handleTeamCheckBox();
        }
    }, [checked]);

    const teamAdminDetails = (index: number) => (team: ITeam) => {
        if (!!team && !!team.Admins && team.Admins[index]) {
            return (
                <Box component="div">
                    <Box component="div" styleName="nameWrapper">
                        {team.Admins[index].Name}
                    </Box>
                    <Box component="div" styleName="designationWrapper">
                        {team.Admins[index].Designation}
                    </Box>
                    {!team.Admins[index].IsActive && <SuspendedIcon />}
                </Box>
            );
        } else {
            return '-';
        }
    };
    return (
        <Table size="small" aria-label="teams">
            <TableBody>
                <TableRow styleName="teamRows">
                    <TableCell align="left" styleName="teamsData" style={{width: '40%'}}>
                        <div style={{paddingLeft: '84px'}}>{team.Name}</div>
                    </TableCell>
                    <TableCell align="left" styleName="teamsData">
                        {teamAdminDetails(0)(team)}
                    </TableCell>
                    <TableCell align="left" styleName="teamsData">
                        {teamAdminDetails(1)(team)}
                    </TableCell>
                    <TableCell align="center" styleName="teamsData">
                        {team.Admins && team.Admins?.length > 0 ? (
                            <div styleName="userCheckBoxGroup">
                                <Checkbox
                                    style={{color: '#3949AB', paddingLeft: 0}}
                                    color="primary"
                                    checked={checked}
                                    onChange={() => {
                                        setChecked(!checked);
                                    }}
                                />
                                <Typography styleName="checkBoxLabel">USER</Typography>
                            </div>
                        ) : (
                            <div styleName="userCheckBoxGroup">
                                <Checkbox style={{color: 'gray', paddingLeft: 0}} disabled />
                                <Typography style={{color: 'gray'}} styleName="checkBoxLabel">
                                    USER
                                </Typography>
                            </div>
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
