import {config} from '~/config';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {httpRequest} from './middleware';

export const closeRequestWindow = async (id: number): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/simulate/window/${id}/close`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't close request window, please try again");
    }
};

export const openRequestWindow = async (id: number): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/simulate/window/${id}/open`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't open request window, please try again");
    }
};
