import React, {useEffect, useState, useContext} from 'react';
import {useHistory} from 'react-router';
import {Container, InputAdornment, TextField, Typography} from '@mui/material';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import {IScreenHistory, IScreeningRequestResponse} from '~/interfaces/clientUser';
import {SubDomainStatus} from '~/interfaces/client';
import {getDayDiffFromToday, formatDate, isToday} from '~/utils/dateUtils';
import {API_FETCH_STATE, Relationship} from '~/constants';
import {ScreeningHistoryTable, IRowData} from '~/components/Client/ScreeningHistoryTable/ScreeningHistoryTable';
import {EmptyScreeningHistory} from './EmptyScreeningHistory';
import {ProgressBar} from '~/components/Common/ProgressBar/ProgressBar';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {FirstScreeningSurvey} from '~/components/Client/Survey/FirstScreeningSurvey';
import {ExpandAllButton} from '~/components/Common/Button/ExpandAllButton';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getSubDomains} from '~/store/domain/domain.selector';
import {setDomains} from '~/store/domain/domains.thunk';
import {setClientUserScreeningHistory} from '~/store/clientUser/clientUser.thunk';
import {setSurveyTrigger} from '~/store/survey/survey.thunk';

import cloneDeep from 'lodash/cloneDeep';
import './ScreeningHistory.scss';
import {WarningMessage} from '~/components/Common/IconMessage/IconMessage';

export const ScreeningHistory = () => {
    const [masterRows, setMasterRows] = useState<IRowData[]>([]);
    const [rows, setRows] = useState<IRowData[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [expandAll, setExpandAll] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const histories = useTypedSelector((state) => state.data.clientUser.screenHistories.data);
    const fetchStatus = useTypedSelector((state) => state.data.clientUser.screenHistories.status);
    const subDomains = useTypedSelector(getSubDomains);
    const surveyTrigger = useTypedSelector((state) => state.data.common.surveys.surveyTrigger);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {accessToken} = useContext(AuthenticationContext);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getSubDomainName = (code: string) => {
        const subDomainMatch = subDomains.find((subDomain) => {
            return subDomain.Code === code;
        });
        return subDomainMatch?.Name || code;
    };

    const setStatus = (retrievalCompletionDate: string | null, SubDomains: IScreeningRequestResponse[]) => {
        if (retrievalCompletionDate) {
            const formattedDate = formatDate(new Date(retrievalCompletionDate), false, true);
            return `All screenings retrieved \n${formattedDate}`;
        } else {
            const pendingSubDomains = SubDomains.filter((subDomain) => subDomain.Status === 'pending');
            return `Pending ${pendingSubDomains.length} Sub-Domain(s)`;
        }
    };

    const setScreenedDate = (screenedDate: string) => {
        if (isToday(screenedDate)) {
            return `${formatDate(new Date(screenedDate), false, true)} (Today)`;
        } else {
            return formatDate(new Date(screenedDate), false, true);
        }
    };

    useEffect(() => {
        dispatch(setDomains());
        dispatch(setClientUserScreeningHistory());
        dispatch(setSurveyTrigger());
    }, []);

    useEffect(() => {
        if (histories?.length > 0) {
            const localHistory = cloneDeep(histories);
            const filteredHistories = localHistory.filter((history) => history.Relationship === Relationship.CLIENT);
            const mappedHistories: IRowData[] = filteredHistories.map((history) => {
                const requestedSubDomains = history.ScreeningRequestResponses || [];

                // Reduce responses from same subDomain but difference data-adapters to a single response
                // If any of the response is pending or have error. The status will be reflected as such, even if the other responses has completed status
                const reducedRequestedSubDomains = requestedSubDomains.reduce(
                    (accumulator: IScreeningRequestResponse[], requestResponse) => {
                        const foundIndex = accumulator.findIndex(
                            (item: IScreeningRequestResponse) => item.SubDomain === requestResponse.SubDomain,
                        );
                        const foundResponse = accumulator[foundIndex];

                        if (foundIndex < 0) {
                            accumulator.push(requestResponse);
                        } else {
                            if (
                                (requestResponse.Status === SubDomainStatus.PENDING ||
                                    requestResponse.Status === SubDomainStatus.ERROR) &&
                                foundResponse.Status !== SubDomainStatus.ERROR
                            ) {
                                foundResponse.Status = requestResponse.Status;
                            }
                        }

                        return accumulator;
                    },
                    [],
                );

                return {
                    id: history.Id,
                    screenedDate: history.ScreenedDate,
                    clientName: history.ClientName,
                    clientUIN: history.ClientUIN,
                    requestId: history.RequestId,
                    status: setStatus(history.RetrievalCompletionDate, reducedRequestedSubDomains),
                    dayLeftToExpire: history.ExpiryDate ? getDayDiffFromToday(history.ExpiryDate) : 0,
                    pendingSubDomains: reducedRequestedSubDomains.map((subDomain) => ({
                        subDomain: getSubDomainName(subDomain.SubDomain),
                        status: subDomain.Status,
                        expectedRetrivalDate: subDomain.ExpectedRetrivalDate,
                    })),
                    familyScreeningRequestId: history.FamilyScreeningRequest.RequestId as string,
                    requestedFamilyMembersLength: history.FamilyScreeningRequest.ScreeningRequests.length - 1,
                };
            });

            setRows(mappedHistories);
            setMasterRows(mappedHistories);
        }
    }, [histories]);

    const hasCompletedFirstScreening = (histories: IScreenHistory[]) => {
        return histories.some((history) => history.RetrievalCompletionDate !== null);
    };

    useEffect(() => {
        if (histories.length > 0 && surveyTrigger && hasCompletedFirstScreening(histories)) {
            setModalOpen(surveyTrigger.FirstScreeningSurvey);
        }
    }, [histories, surveyTrigger]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);

        const lowerCaseSearchValue = e.target.value.toLowerCase();

        const newData = masterRows.filter(
            (row) =>
                row.clientName.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                row.clientUIN.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                setScreenedDate(row.screenedDate).toLowerCase().indexOf(lowerCaseSearchValue) > -1,
        );
        setRows(newData);
    };

    const toggleExpandAll = () => {
        expandAll ? setExpandAll(false) : setExpandAll(true);
    };

    const handleButtonClick = () => {
        history.push('/search');
    };

    return (
        <>
            {fetchStatus === API_FETCH_STATE.SUCCESS ? (
                <>
                    <Typography variant="h4" styleName="myClientsTitle">
                        My Clients
                    </Typography>
                    <WarningMessage
                        message="The screenings will be cleared automatically 30 days after you receive the last
                        screening result. Generate the screening report if you'll like to retain a copy of the
                        results."
                        style={{color: 'var(--secondary-grey, rgba(0, 0, 0, 0.6))', alignItems: 'center'}}
                    />

                    {masterRows.length === 0 ? (
                        <EmptyScreeningHistory onButtonClick={handleButtonClick} />
                    ) : (
                        <>
                            <Container styleName="filterWrapper">
                                <TextField
                                    label="Search Name, NRIC, Date Screened"
                                    type="search"
                                    variant="outlined"
                                    value={searchValue}
                                    onChange={handleChange}
                                    styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ExpandAllButton onClick={toggleExpandAll} expandAll={expandAll} id="expandAllButton" />
                            </Container>
                            <ScreeningHistoryTable rows={rows} expandAll={expandAll} />
                        </>
                    )}
                    {accessToken?.Permissions.UserId && accessToken?.AdditionalUserInfo.Name && (
                        <FirstScreeningSurvey
                            open={modalOpen}
                            onHandleClose={handleModalClose}
                            userName={accessToken.AdditionalUserInfo.Name}
                        />
                    )}
                </>
            ) : (
                <ProgressBar />
            )}
        </>
    );
};
