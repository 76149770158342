import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogContent, DialogActions, FormHelperText, TextField, Typography} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import surveyImage from '~/assets/images/survey.svg';
import {RatingGroup} from './RatingGroup';
import {FORM_MAX_TEXT, SURVEY_TYPE} from '~/constants';
import {ISurvey, ISurveyData} from '~/interfaces/clientUser';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {ClientSnackBar} from '../ClientSnackBar/ClientSnackBar';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {createSurvey} from '~/store/survey/survey.thunk';

import './Survey.scss';

export interface IFirstScreeningSurveyProps {
    open: boolean;
    onHandleClose: () => void;
    userName: string;
}

export const FirstScreeningSurvey = ({open, userName, onHandleClose}: IFirstScreeningSurveyProps) => {
    const initialSurveyFields: ISurveyData = {
        UsefulnessRating: 0,
        ExperienceRating: 0,
        Feedback: '',
        ImprovementSelected: [],
        ImprovementOthers: '',
    };
    const [surveyFields, setSurveyFields] = useState(initialSurveyFields);
    const [hasError, setHasError] = useState(true);
    const dispatch = useAppDispatch();
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setSurveyFields({
            ...surveyFields,
            [name]: value,
        });
    };

    const handleRatingChange = (e: React.ChangeEvent<{}>, rating: number | null) => {
        const target = e.target as HTMLTextAreaElement;
        setSurveyFields({
            ...surveyFields,
            [target.name]: rating,
        });
    };

    const checkHasError = () => {
        const errors = {ExperienceRating: false};
        errors.ExperienceRating = !surveyFields.ExperienceRating;
        const noError = Object.values(errors).every((err) => err === false);
        setHasError(!noError);
    };

    useEffect(() => {
        checkHasError();
    }, [surveyFields]);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const survey: ISurvey = {
            SurveyType: SURVEY_TYPE.FIRST_SCREENING,
            SurveyData: {...surveyFields},
        };

        const surveyResponse = await dispatch(createSurvey(survey));
        if ('error' in surveyResponse) {
            openGrowl(surveyResponse.payload as string);
        } else {
            onHandleClose();
        }
    };

    const handleClose = () => {
        const survey: ISurvey = {
            SurveyType: SURVEY_TYPE.FIRST_SCREENING,
            IsDismissed: true,
        };
        dispatch(createSurvey(survey));
        onHandleClose();
    };

    return (
        <React.Fragment>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogActions>
                    <CloseIcon styleName="closeIcon" onClick={handleClose} />
                </DialogActions>
                <DialogContent>
                    <Typography>
                        Hi <b>{userName},</b>
                    </Typography>
                    <br />
                    <Typography>Thank you for using OneCV.</Typography>
                    <br />
                    <Typography>Congrats on completing your first screening!</Typography>
                    <Typography>
                        We&apos;ll like to hear your experience using OneCV so we can do better for you.
                    </Typography>
                    <div styleName="imageContainer">
                        <img src={surveyImage} alt="Survey Image" styleName="image" />
                    </div>
                    <Typography styleName="surveyLabel">
                        How would you rate your overall experience using OneCV?
                    </Typography>
                    <RatingGroup name="ExperienceRating" onChange={handleRatingChange} data-testid="experienceRating" />
                    <Typography styleName="surveyLabel">Feedback</Typography>

                    <TextField
                        value={surveyFields.Feedback}
                        placeholder="Optional"
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        styleName="textBox"
                        name="Feedback"
                        data-testid="feedback"
                    />
                    <FormHelperText>{FORM_MAX_TEXT - surveyFields.Feedback.length} characters remaining</FormHelperText>

                    <div styleName="submitButtonContainer">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            styleName="submitButton"
                            disabled={hasError}
                            data-testid="submitButton"
                        >
                            SEND FEEDBACK
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <ClientSnackBar
                {...{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
            />
        </React.Fragment>
    );
};
