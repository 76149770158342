import {IUserResponse} from '~/components/LoginForm/account';
import {config} from '~/config';
import {USER_TYPE} from '~/constants';
import {httpRequest} from '~/services/middleware';
import {ISubDomain, IFavourites, IFavourite, IScreenHistory} from '~/interfaces/clientUser';
import {ICurrentAnnouncement, IDomain, IResource} from '~/interfaces/common';
import fileDownload from 'js-file-download';
import {CancelToken} from 'axios';
import {isResponseOk} from '~/utils/apiResponseUtils';

interface IMockUsers {
    ad: Pick<IUserResponse, 'UserName'>[];
    corppass: IUserResponse[];
}

export const authenticateUser = async (payload: {userName: string; userType: USER_TYPE}): Promise<string> => {
    const response = await httpRequest(
        {
            url: `${config.api.AUTH_SERVICE}/token`,
            data: JSON.stringify(payload),
            method: 'POST',
        },
        false,
    );
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const authenticateCorppassUser = async (code: string): Promise<string> => {
    const response = await httpRequest(
        {
            url: `${config.api.AUTH_SERVICE}/corppass-token?code=${code}`,
            method: 'POST',
        },
        false,
    );

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getMockUsers = async (): Promise<IMockUsers> => {
    const response = await httpRequest(
        {
            url: `${config.api.ACCESS_CONTROL_SERVICE}/mock/accounts`,
        },
        false,
    );
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getClientUserDataAccess = async (): Promise<ISubDomain[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/client-user/data-access`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getClientUserSubDomainFavourites = async (): Promise<IFavourites> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/client-user/subdomain-favourites`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const postClientUserSubDomainFavourite = async (favourite: IFavourite): Promise<IFavourite> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/client-user/subdomain-favourite`,
        data: JSON.stringify(favourite),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const deleteClientUserSubDomainFavourite = async (favouriteId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/client-user/subdomain-favourites/${favouriteId}`,
        method: 'DELETE',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getClientUserScreeningHistory = async (): Promise<IScreenHistory[]> => {
    const response = await httpRequest({
        url: `${config.api.CLIENT_DATA_SERVICE}/screen-histories`,
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const postMassEmail = async (payload: FormData, test: boolean): Promise<string> => {
    const params = test ? '/true' : '/false';
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/mass-email${params}`,
        data: payload,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const getResources = async (): Promise<IResource[]> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const downloadIntranetResources = async (resourceIds: number[]) => {
    const resourcesIdJSON = JSON.stringify({ResourceIds: resourceIds});

    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources`,
        data: resourcesIdJSON,
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        method: 'POST',
        responseType: 'blob',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    const fileData = response.data;

    fileDownload(fileData, `downloaded.zip`);
};

export const downloadSingleIntranetResource = async (resourceId: number, fileName: string) => {
    const resourcesIdJSON = JSON.stringify({ResourceIds: [resourceId]});

    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources`,
        data: resourcesIdJSON,
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        method: 'POST',
        responseType: 'blob',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    const fileData = response.data;

    fileDownload(fileData, fileName);
};

export const downloadInternetResources = async (resourceIds: number[]) => {
    const resourcesIdJSON = JSON.stringify({ResourceIds: resourceIds});
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/internet-resources`,
        data: resourcesIdJSON,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    try {
        const urls = response.data;
        if (!urls.length) return;
        for (let i = 0; i < urls.length; i++) {
            const downloadResponse = await httpRequest({url: urls[i].presignedUrl, responseType: 'blob'}, false);
            if (!isResponseOk(downloadResponse.status)) {
                throw new Error();
            }
            const downloadBlob = downloadResponse.data;
            fileDownload(downloadBlob, urls[i].fileName);
        }
    } catch (err) {
        const error = err as Error;
        throw new Error(error.message);
    }
};

export const uploadResource = async (
    file: File,
    name: string,
    description: string,
    types: string[],
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    cancelToken: CancelToken,
) => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('file', file);
    formData.append('description', description);
    formData.append('types', types.join(','));

    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources/upload`,
        method: 'POST',
        data: formData,
        onUploadProgress: (pg) => setProgress((pg.loaded / pg.total) * 100),
        cancelToken,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const deleteResource = async (resourceId: number) => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources/${resourceId}`,
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const editResource = async (resourceId: number, name: string, description: string) => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/resources/${resourceId}`,
        method: 'PUT',
        data: JSON.stringify({
            name,
            description,
        }),
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getDomains = async (): Promise<IDomain[]> => {
    const response = await httpRequest(
        {
            url: `${config.api.ACCESS_CONTROL_SERVICE}/domains`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        },
        true,
    );
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getCurrentAnnouncements = async (): Promise<ICurrentAnnouncement[]> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/current-announcements`,
        withCredentials: true,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};
