import React, {useEffect, useState} from 'react';
import {
    Button,
    IconButton,
    Menu,
    MenuList,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    TableCell,
    Box,
    Link,
} from '@mui/material';
import {Delete, MoreVert} from '@mui/icons-material';
import {format} from 'date-fns';
import {ActiveIcon} from '~/components/Admin/ActiveIcon/ActiveIcon';
import {
    EnhancedTableExpandableRow,
    ICustomHeaderColumn,
} from '~/components/Common/Table/EnhancedTable/EnhancedTableExpandableRow';
import {CONSENT_STATUS, CONSENT_TYPE, COPIES, CONSENT_ACTIONS} from '~/constants';
import {IConsent, IConsentRequest, IConsentFileMeta} from '~/interfaces/client';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {InactiveIcon} from '~/components/Admin/InactiveIcon/InactiveIcon';
import revokeConsent from '~/assets/images/revokeConsent.svg';
import replaceFile from '~/assets/images/replaceFile.svg';
import {nameCase} from '~/utils/nameUtils';
import {ReportPasswordDialog} from '~/components/Common/ReportDialogs/ReportPasswordDialog';
import {getConsentFile} from '~/services/consentServices';
import {ClientConsentData} from '../ConsentEdit';

import './ConsentTable.scss';

interface ClientConsentTableProps {
    data: ClientConsentData;
    setConsentStatus: (status: CONSENT_STATUS) => void;
    expandedRows: number[];
    handleDelete: (consentId: number) => void;
    handleRevoke: (consentId: number) => void;
    handleUpdate: (consentId: number) => void;
    openGrowl: (msg: string) => void;
}

interface IRowData {
    id: number;
    model: IConsent;
    expandableContent?: React.ReactElement;
    status: string;
    consentType: CONSENT_TYPE;
    providedOn: Date;
    administeredBy: string;
    manage?: string; //unused
}

const formatConsentType = (consent: CONSENT_TYPE) => {
    switch (consent) {
        case CONSENT_TYPE.ONECV_CONSENT: {
            return 'OneCV - Normal';
        }
        case CONSENT_TYPE.ONECV_MINOR_CONSENT: {
            return 'OneCV - Minor';
        }
        case CONSENT_TYPE.ONECV_MI_CONSENT: {
            return 'OneCV - Mentally Incapacitated';
        }
        case CONSENT_TYPE.SSNET_CONSENT: {
            return 'SSNet - Normal';
        }
        case CONSENT_TYPE.SSNET_MINOR_CONSENT: {
            return 'SSNet - Minor';
        }
        case CONSENT_TYPE.SSNET_MI_CONSENT: {
            return 'SSNet - Mentally Incapacitated';
        }
        case CONSENT_TYPE.AGENCY_CONSENT: {
            return 'Agency-specific';
        }
        default: {
            return '';
        }
    }
};

const formatConsentStatus = (status: CONSENT_STATUS) => {
    switch (status) {
        case CONSENT_STATUS.ACTIVE_CONSENT: {
            return <ActiveIcon />;
        }
        case CONSENT_STATUS.REVOKED_CONSENT:
        case CONSENT_STATUS.EXPIRED_CONSENT:
        case CONSENT_STATUS.DELETED_CONSENT: {
            return <InactiveIcon message={nameCase(status.toLowerCase())} />;
        }
        case CONSENT_STATUS.ONE_TIME_CONSENT: {
            return <InactiveIcon message="One-time" />;
        }
        default: {
            return <InactiveIcon message="Unknown" />;
        }
    }
};

export const ClientConsentTable = ({
    data,
    setConsentStatus,
    expandedRows,
    handleDelete,
    handleRevoke,
    handleUpdate,
    openGrowl,
}: ClientConsentTableProps) => {
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
    const [consentFile, setConsentFile] = useState<IConsentFileMeta | null>();

    useEffect(() => {
        data.ConsentHistory = data.ConsentHistory.map((history) => {
            const minorConsentDate = history.MinorConsentDate || '';
            if (
                history.ConsentType === CONSENT_TYPE.ONECV_MINOR_CONSENT ||
                history.ConsentType === CONSENT_TYPE.SSNET_MINOR_CONSENT
            ) {
                const minorHistory = {
                    Id: 0,
                    ConsentForm: null,
                    Remarks: `Minor's date of birth: ${format(new Date(minorConsentDate), 'dd MMM yyyy')}`,
                    UpdatedBy: history.CreatedBy,
                    UpdatedAt: history.CreatedAt,
                    FormType: '',
                    Type: CONSENT_ACTIONS.UPDATE,
                };
                if (!history.ConsentHistory) {
                    history.ConsentHistory = [minorHistory];
                } else {
                    history.ConsentHistory = [minorHistory, ...history.ConsentHistory];
                }
            }

            return history;
        });
    }, []);

    const handlePasswordSubmit = async (password: string) => {
        const consentFormRequest: IConsentRequest = {
            Password: password,
        };

        try {
            consentFile && (await getConsentFile(consentFormRequest, consentFile));
        } catch (e) {
            openGrowl(`Error downloading report: Please try again later`);
        }
    };

    const openPasswordModal = (consentForm: IConsentFileMeta | null) => {
        setConsentFile(consentForm);
        if (consentForm) {
            setPasswordModalOpen(true);
        }
    };

    const tableValues = {
        status: function status(model: IConsent) {
            return formatConsentStatus(model.Status);
        },
        consentType: function consentType(model: IConsent) {
            return (
                <Box>
                    <Typography variant="body1">{formatConsentType(model.ConsentType)}</Typography>
                    {model.ConsentForm && (
                        <Link
                            variant="body2"
                            underline="always"
                            onClick={() => openPasswordModal(model.ConsentForm)}
                            id={`downloadLink_${model.ConsentForm.Id}`}
                        >
                            Consent form
                        </Link>
                    )}
                </Box>
            );
        },
        dateUpdated: function dateUpdated(model: IConsent) {
            return model.CreatedAt && formatDateValue(model.CreatedAt);
        },
        createdBy: function createdBy(model: IConsent) {
            return (
                <Box>
                    <Typography variant="body1">{model.CreatedBy}</Typography>
                    <Typography variant="body2" color="textPrimary">
                        {model.Agency}
                    </Typography>
                </Box>
            );
        },
        manage: function manage(model: IConsent) {
            return (
                <Box ml="16px">
                    <ManageIcon
                        disabled={
                            model.ConsentType === CONSENT_TYPE.AGENCY_CONSENT ||
                            (model.Status !== CONSENT_STATUS.ACTIVE_CONSENT &&
                                model.Status !== CONSENT_STATUS.REVOKED_CONSENT)
                        }
                        consentId={model.Id}
                        status={model.Status}
                        setConsentStatus={setConsentStatus}
                        handleDelete={handleDelete}
                        handleRevoke={handleRevoke}
                        handleUpdate={handleUpdate}
                    />
                </Box>
            );
        },
    };

    const headColumns: ICustomHeaderColumn<IConsent, keyof IHeadRowData>[] = [
        {
            id: 'status',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Status</Typography>
                </Grid>
            ),
            column: tableValues.status,
        },
        {
            id: 'consentType',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Type of consent</Typography>
                </Grid>
            ),
            column: tableValues.consentType,
        },
        {
            id: 'providedOn',
            align: 'inherit',
            disablePadding: false,
            label: <Typography variant="body1">Provided On</Typography>,
            column: tableValues.dateUpdated,
        },
        {
            id: 'administeredBy',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Administered By</Typography>
                </Grid>
            ),
            column: tableValues.createdBy,
        },
        {
            id: 'manage',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Typography variant="body1" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    Manage
                </Typography>
            ),
            column: tableValues.manage,
        },
    ];

    const columnGroup = (
        <colgroup>
            <col style={{width: '3%'}} />
            <col style={{width: '15%'}} />
            <col style={{width: '22%'}} />
            <col style={{width: '20%'}} />
            <col style={{width: '35%'}} />
            <col style={{width: '7%'}} />
        </colgroup>
    );

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{`${data.ClientName} | ${data.ClientUin}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {EnhancedTableExpandableRow<IHeadRowData, IRowData, IConsent>(
                        headColumns,
                        dataWithExpandableContent(data.ConsentHistory, openPasswordModal),
                        true,
                        false,
                        'providedOn',
                        10,
                        'desc',
                        columnGroup,
                        expandedRows,
                    )}
                </Grid>
            </Grid>
            <ReportPasswordDialog
                open={passwordModalOpen}
                onSubmit={handlePasswordSubmit}
                onClose={() => setPasswordModalOpen(false)}
                title={COPIES.DOWNLOAD_FORM_TITLE}
                description={''}
            />
        </>
    );
};

type IHeadRowData = Pick<IRowData, 'status' | 'consentType' | 'providedOn' | 'administeredBy' | 'manage'>;

const formatDateValue = (date: Date) => (
    <Box>
        <Typography variant="body1">{formatDate(new Date(date))}</Typography>
        <Typography variant="body2" color="textSecondary">
            {formatTime(new Date(date), true, true, false, true)}
        </Typography>
    </Box>
);

const TableHeader = ({content}: {content: string}) => (
    <TableCell style={{color: '#606060', padding: '16px'}}>{content}</TableCell>
);

const TableContent = ({content}: {content: string; colSpan?: number}) => (
    <TableCell style={{padding: '16px'}}>{content}</TableCell>
);

const formType = (type: string) => {
    switch (type) {
        case 'withdrawal':
            return 'Withdrawal form';
        case 'consent':
            return 'Consent form';
        default:
            // for no attachment
            return '-';
    }
};

export const expandableContent = (
    consent: IConsent,
    openPasswordModal: (consentForm: IConsentFileMeta | null) => void,
): React.ReactElement => {
    return (
        consent && (
            <>
                {
                    <Table>
                        <colgroup>
                            <col style={{width: '5%'}} />
                            <col style={{width: '15%'}} />
                            <col style={{width: '19%'}} />
                            <col style={{width: '18%'}} />
                            <col style={{width: '18%'}} />
                            <col style={{width: '25%'}} />
                        </colgroup>
                        <TableHead style={{backgroundColor: '#e8eaf6'}}>
                            <TableRow>
                                <TableHeader content="" />
                                <TableHeader content="Effective Date" />
                                <TableHeader content="Attachment" />
                                <TableHeader content="Date Updated" />
                                <TableHeader content="Updated By" />
                                <TableHeader content="Remarks" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {consent.ConsentHistory?.map((history, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableContent content="" />
                                        <TableContent
                                            content={
                                                history.EffectiveDate
                                                    ? formatDate(new Date(history.EffectiveDate))
                                                    : '-'
                                            }
                                        />
                                        {history.ConsentForm ? (
                                            <TableCell>
                                                <Link
                                                    underline="always"
                                                    onClick={() => openPasswordModal(history.ConsentForm)}
                                                    id={`downloadLink_${history?.ConsentForm.Id}`}
                                                >
                                                    {formType(history.FormType)}
                                                </Link>
                                            </TableCell>
                                        ) : (
                                            <TableContent content={formType(history.FormType)} />
                                        )}
                                        <TableCell>{history.UpdatedAt && formatDateValue(history.UpdatedAt)}</TableCell>
                                        <TableContent content={history.UpdatedBy} />
                                        <TableContent content={history.Remarks} />
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                }
            </>
        )
    );
};

const dataWithExpandableContent = (
    data: IConsent[],
    openPasswordModal: (consentForm: IConsentFileMeta | null) => void,
) => {
    return data.map((consent: IConsent): IRowData => {
        return {
            id: consent.Id,
            model: consent,
            status: consent.Status,
            consentType: consent.ConsentType,
            providedOn: consent.CreatedAt,
            administeredBy: consent.Agency + ' ' + consent.CreatedBy,
            expandableContent:
                consent.ConsentHistory && consent.ConsentHistory.length > 0
                    ? expandableContent(consent, openPasswordModal)
                    : undefined,
        };
    });
};

interface IManageIcon {
    disabled: boolean;
    consentId: number;
    status: CONSENT_STATUS;
    setConsentStatus: (status: CONSENT_STATUS) => void;
    handleDelete: (consentId: number) => void;
    handleRevoke: (consentId: number) => void;
    handleUpdate: (consentId: number) => void;
}

const ManageIcon = ({
    disabled,
    consentId,
    status,
    setConsentStatus,
    handleDelete,
    handleRevoke,
    handleUpdate,
}: IManageIcon) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                data-testid={'manageIcon' + consentId.toString()}
                size="small"
                style={{padding: '0px'}}
                onClick={handleClick}
                disabled={disabled}
            >
                <MoreVert />
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 28, horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {status === CONSENT_STATUS.ACTIVE_CONSENT && (
                    <MenuList style={{padding: '4px', border: 'none'}}>
                        <Button
                            startIcon={<img src={revokeConsent} />}
                            styleName="delete-button"
                            onClick={() => {
                                handleRevoke(consentId);
                            }}
                        >
                            <Typography variant="body1">Revoke consent</Typography>
                        </Button>
                    </MenuList>
                )}
                {status === CONSENT_STATUS.ACTIVE_CONSENT && (
                    <MenuList style={{padding: '4px'}}>
                        <Button
                            startIcon={<Delete />}
                            styleName="delete-button"
                            onClick={() => {
                                handleDelete(consentId);
                            }}
                        >
                            <Typography variant="body1">Delete consent</Typography>
                        </Button>
                    </MenuList>
                )}
                <MenuList style={{padding: '4px'}}>
                    <Button
                        startIcon={<img src={replaceFile} />}
                        styleName="delete-button"
                        onClick={() => {
                            setConsentStatus(status);
                            handleUpdate(consentId);
                        }}
                    >
                        {status === CONSENT_STATUS.ACTIVE_CONSENT ? (
                            <Typography variant="body1">Update consent form</Typography>
                        ) : (
                            <Typography variant="body1">Update withdrawal form</Typography>
                        )}
                    </Button>
                </MenuList>
            </Menu>
        </>
    );
};
