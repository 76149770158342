import React, {useState} from 'react';
import {IRentalDetails, RentalDetails} from './RentalDetails';
import {Typography, Grid, Tabs, Tab} from '@mui/material';
import {IHouseholdMember, HouseholdMembers} from '../Common/HouseholdMembers';
import {DataField} from '~/components/Common/DataField/DataField';
import {IHdbAddress, HdbAddress} from '../Common/HdbAddress';
import {Subsection, SFTP_SSNET_HDB, SFTP_DOS} from '../Common/Subsection';
import styles from './RentalHousing.scss';
import {IRentalHousingSchemes, HousingSchemes} from './HousingSchemes';
import {StatusObject} from '../../SubDomainDisplay';
import {SubDomainStatus} from '~/interfaces/client';
import {IAnnualValue, AnnualValue} from '../Common/AnnualValue';
import {allNoResults, extractAllValues} from '~/utils/contentUtils';
import {NoData} from '../../CompletedSubDomain';
import {FIELD_VALUES, SCREENING_STATUS} from '~/constants';

export type IRentalHousingData = {
    SFTP_SSNET_HDB: IRentalHousing[];
    SFTP_DOS: {
        AnnualValue: IAnnualValue;
    } | null;
};

interface IRentalHousing {
    Id: number;
    StartDateOfRentalTenancy: string;
    HdbAccountNumber: string;
    HdbAddress: IHdbAddress;
    HouseholdMembers: IHouseholdMember[];
    RentalDetails: IRentalDetails;
    RentalHousingSchemes: IRentalHousingSchemes;
}

export const RentalHousing: React.FunctionComponent<{data: IRentalHousingData; status: StatusObject}> = ({
    data,
    status,
}) => {
    const dosData = data.SFTP_DOS;
    const hdbData = data.SFTP_SSNET_HDB;

    const statusDOS = status[SFTP_DOS] && status[SFTP_DOS].toLowerCase();
    const statusFSR = status[SFTP_SSNET_HDB] && status[SFTP_SSNET_HDB].toLowerCase();

    const noData =
        statusDOS === SubDomainStatus.COMPLETED &&
        statusFSR === SubDomainStatus.COMPLETED &&
        allNoResults(extractAllValues(data));

    const [currentHdb, setCurrentHdb] = React.useState(hdbData ? hdbData[0] : null);
    const [tabValue, setTabValue] = useState<number>(0);
    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        setCurrentHdb(hdbData[newValue]);
    };

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <div id={styles.customTable}>
                    <Grid data-testid="rentalHousing" container>
                        <Grid item xs={12} className={styles.containerGrid}>
                            {hdbData && hdbData.length == 2 && (
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="simple tabs example"
                                    variant="fullWidth"
                                    styleName="tabsWrapper"
                                >
                                    <Tab label="Recent Address" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                                    <Tab label="Past Address" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                                </Tabs>
                            )}
                            {currentHdb && currentHdb.HdbAccountNumber !== FIELD_VALUES.NO_ACCESS && (
                                <Grid data-testid="hdbAccountNumber" item xs={10}>
                                    <DataField
                                        label="HDB Account Number"
                                        value={
                                            <Typography variant="h6">
                                                {status[SFTP_SSNET_HDB] == SubDomainStatus.ERROR
                                                    ? SCREENING_STATUS.UNSUCCESSFUL
                                                    : status[SFTP_SSNET_HDB] == SubDomainStatus.PENDING
                                                    ? SCREENING_STATUS.PENDING
                                                    : currentHdb.HdbAccountNumber}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            )}
                            <Subsection
                                data-testid="rentalDetails"
                                title="Rental Details"
                                fieldList={currentHdb ? extractAllValues(currentHdb.HdbAddress) : []}
                                status={status[SFTP_SSNET_HDB]}
                            >
                                <HdbAddress
                                    data={currentHdb?.HdbAddress}
                                    startDateOfRentalTenancy={currentHdb?.StartDateOfRentalTenancy}
                                />
                            </Subsection>
                            <Subsection
                                data-testid="housingSchemes"
                                title="Housing Schemes"
                                fieldList={currentHdb ? extractAllValues(currentHdb.RentalHousingSchemes) : []}
                                status={status[SFTP_SSNET_HDB]}
                            >
                                <HousingSchemes data={currentHdb?.RentalHousingSchemes} />
                            </Subsection>
                            <Subsection
                                data-testid="householdMembersInformation"
                                title="Household Members Information"
                                fieldList={
                                    currentHdb && currentHdb.HouseholdMembers.length > 0
                                        ? extractAllValues(currentHdb.HouseholdMembers[0])
                                        : []
                                }
                                status={status[SFTP_SSNET_HDB]}
                            >
                                <HouseholdMembers data={currentHdb?.HouseholdMembers} />
                            </Subsection>
                            <Subsection
                                data-testid="rentalPaymentDetails"
                                title="Rental Payment Details"
                                fieldList={currentHdb ? extractAllValues(currentHdb.RentalDetails) : []}
                                status={status[SFTP_SSNET_HDB]}
                            >
                                <RentalDetails data={currentHdb?.RentalDetails} />
                            </Subsection>
                            <Subsection
                                data-testid="annualValue"
                                title="Annual Value"
                                fieldList={dosData ? extractAllValues(dosData.AnnualValue) : []}
                                withDivider={false}
                                status={status[SFTP_DOS]}
                            >
                                <AnnualValue data={dosData?.AnnualValue} />
                            </Subsection>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};
