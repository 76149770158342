import React, {useState, useEffect} from 'react';
import {Checkbox, Grid, Typography} from '@mui/material';
import {IDataAccessMatrixDomain} from '~/interfaces/admin';
import {ExpandAllButton} from '~/components/Common/Button/ExpandAllButton';
import {SubDomain} from './SubDomain';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    getDomainMatrixCheckedState,
    getDomainMatrixisIndeterminateState,
} from '~/store/dataAccessMatrix/dataAccessMatrix2.selector';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';

import './DataAccessMatrixEdit.scss';

export interface IDomainProps {
    domain: IDataAccessMatrixDomain;
    onExpandAll: boolean;
    opsUnitId: number;
}

export interface IDomainHeaderProps {
    domain: IDataAccessMatrixDomain;
    expandAll: boolean;
    setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
    opsUnitId: number;
}

export const DomainHeader = ({domain, expandAll, setExpandAll, opsUnitId}: IDomainHeaderProps) => {
    const dispatch = useAppDispatch();
    const isChecked = useTypedSelector(getDomainMatrixCheckedState(opsUnitId, domain.Id));
    const isIndeterminate = useTypedSelector(getDomainMatrixisIndeterminateState(opsUnitId, domain.Id));

    const handleButtonClick = () => {
        setExpandAll(!expandAll);
    };

    const domainCheckBoxChangeHandler = () => {
        const payload = domain.SubDomains.map((SubDomain) => {
            return SubDomain.DataFields.map(({Id}) => ({
                opsUnitId,
                domainId: domain.Id,
                subDomainId: SubDomain.Id,
                fieldId: Id,
                selected: !isChecked,
            }));
        });

        dispatch(dataAccessMatrix2Actions.SET_GROUPED_FIELDS(payload.flat()));
    };

    return (
        <>
            <Grid item xs={8}>
                <Checkbox
                    color={'primary'}
                    styleName="domainCheckbox"
                    disableRipple
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onChange={domainCheckBoxChangeHandler}
                />
                <Typography variant="h5" styleName="domainName" id="domainName">
                    {domain.Name}
                </Typography>
            </Grid>
            <Grid item xs={4} id="dataDictionaryExpandAll">
                <ExpandAllButton onClick={handleButtonClick} expandAll={expandAll} />
            </Grid>
        </>
    );
};

const SubDomainMemo = React.memo(SubDomain);
export const Domain = ({domain, onExpandAll, opsUnitId}: IDomainProps) => {
    const [expandAll, setExpandAll] = useState(true);

    useEffect(() => {
        setExpandAll(onExpandAll);
    }, [onExpandAll]);

    return (
        <>
            <Grid container>
                <DomainHeader expandAll={expandAll} setExpandAll={setExpandAll} domain={domain} opsUnitId={opsUnitId} />
            </Grid>

            {domain.SubDomains.map((subDomain) => {
                return (
                    <SubDomainMemo
                        subDomain={subDomain}
                        expandAll={expandAll}
                        key={subDomain.Id}
                        opsUnitId={opsUnitId}
                        domainId={domain.Id}
                    />
                );
            })}
        </>
    );
};
