import React, {useState, useEffect, useContext} from 'react';
import {Box, Button, Divider, Fade, Grid, Popper, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {IClientBasicInfo, IFamilyMember, UINType} from '~/interfaces/client';
import {AddRounded as AddIcon, InfoRounded as InfoIcon} from '@mui/icons-material';
import {AddPersonDialog} from './AddPersonDialog';
import {ACCESS_LEVELS, familyMembersPerScreeningLimit, OPS_UNIT_TYPE, Relationship} from '~/constants';
import {FeatureModalPopper} from './FeatureModalPopper';
import './SelectFamilyMembers.scss';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';

export const useSelectStyles = makeStyles({
    autocomplete: {
        width: 320,
        backgroundColor: 'white',
        marginLeft: 16,
        marginRight: 0,
    },
    selectAllCheckBox: {},
    normalCheckBox: {},
});

export interface ISelectFamilyMembersProps {
    openSearch: boolean;
    onOpenSearch: () => void;
    onCloseSearch: () => void;
    clientInfo: IClientBasicInfo;
    selectedFamilyMembers: IFamilyMember[];
    onChangeSelected: (selected: IFamilyMember[]) => void;
}
export const SelectFamilyMembers = ({
    openSearch,
    onOpenSearch,
    onCloseSearch,
    clientInfo,
    selectedFamilyMembers,
    onChangeSelected,
}: ISelectFamilyMembersProps) => {
    const {accessToken} = useContext(AuthenticationContext);
    const canViewFamilyInfo =
        accessToken &&
        (accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.PSA);

    const renderRelationship = (relationship: Relationship): string =>
        relationship === Relationship.UNKNOWN ? '' : relationship;
    const selectStyles = useSelectStyles();
    const [options, setOptions] = useState<IFamilyMember[]>([]);
    const [showFeatureModal, setShowFeatureModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const ref = React.createRef<HTMLDivElement>();
    const optionToString = (member: IFamilyMember) => `${member.Name} | ${renderRelationship(member.Relationship)}`;
    const optionsToString = () => options.map(optionToString);
    const selectedToString = () => selectedFamilyMembers.map(optionToString);

    useEffect(() => {
        setOptions(getEligibleFamilyMembers());
    }, []);

    const getEligibleFamilyMembers = () => {
        const filteredMembers = clientInfo?.FamilyMembers
            ? clientInfo.FamilyMembers.filter((member) => member.UINType !== UINType.Other)
            : [];
        return filteredMembers;
    };

    const handleSelect = (selected: string[]) => {
        const selectedFamMembers = options.filter((person) => selected.includes(optionToString(person)));
        onChangeSelected(selectedFamMembers);
    };

    const handleRemove = (personInfo: IFamilyMember) => {
        const newSelected = selectedFamilyMembers.filter((person) => person.UIN !== personInfo.UIN);
        onChangeSelected(newSelected);
    };

    const handleAdd = (personInfo: IFamilyMember) => {
        const newOptions = [...options, personInfo];
        setOptions(newOptions);

        const newSelected = [...selectedFamilyMembers, personInfo];
        onChangeSelected(newSelected);
    };

    function handleOpenFeatureModal(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
        setShowFeatureModal(!showFeatureModal);
    }

    const isDuplicate = (personInfo: IFamilyMember) => {
        const isClient = clientInfo.HashedUin === personInfo.HashedUin;
        const isExistingOptions = options.some((option) => option.UIN === personInfo.HashedUin);
        return isClient || isExistingOptions;
    };

    const renderAddOthers = () => (
        <Button
            variant="text"
            color="primary"
            fullWidth
            disableRipple
            style={{background: 'transparent', justifyContent: 'flex-start'}}
            startIcon={<AddIcon style={{marginLeft: -8}} />}
            onClick={onOpenSearch}
        >
            Add Others
        </Button>
    );

    const renderSelectInfo = () => (
        <Box style={{padding: '16px 28px 6px 28px'}}>
            {canViewFamilyInfo && (
                <Typography
                    variant="caption"
                    style={{
                        color: '#9b9b9b',
                    }}
                >
                    Family members who do not hold a valid NRIC/FIN will not be reflected here.
                </Typography>
            )}
            <Typography
                style={{
                    color: '#3c3c3c',
                    marginTop: 12,
                    fontWeight: 500,
                }}
            >
                Select up to {familyMembersPerScreeningLimit}:
            </Typography>
        </Box>
    );

    return familyMembersPerScreeningLimit > 0 ? (
        <Grid container styleName="selectFamilyMembers">
            <Grid container alignItems="center">
                <Typography variant="subtitle1">For client and</Typography>
                <MultiSelectAutocomplete
                    options={optionsToString()}
                    selectedValues={selectedToString()}
                    label="Optional: Select Person(s)"
                    tagRenderer={(value: string[]) => {
                        const selectedLength = value.length;
                        return selectedLength > 0 ? (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {selectedLength} Person(s)
                            </Typography>
                        ) : (
                            <Typography variant="subtitle1" styleName="filterTag">
                                Optional: Select Person(s)
                            </Typography>
                        );
                    }}
                    onToggleOption={handleSelect}
                    classes={selectStyles}
                    actionRenderer={renderAddOthers}
                    sort={false}
                    infoRenderer={renderSelectInfo}
                    limit={familyMembersPerScreeningLimit}
                    data-testid="familySelectDropdown"
                />
                <Tooltip title="" styleName="tooltip" onClick={handleOpenFeatureModal}>
                    <InfoIcon styleName="infoLabel" />
                </Tooltip>
                <Popper
                    open={showFeatureModal}
                    anchorEl={anchorEl}
                    placement="right-start"
                    transition
                    sx={{zIndex: 100}}
                >
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps}>
                            <FeatureModalPopper ref={ref} onClose={() => setShowFeatureModal(false)} />
                        </Fade>
                    )}
                </Popper>
            </Grid>

            <Grid item xs={12}>
                <Divider styleName="divider" />
            </Grid>

            <AddPersonDialog
                open={openSearch}
                onClose={onCloseSearch}
                clientInfo={clientInfo}
                selectedFamilyMembers={selectedFamilyMembers}
                onRemove={handleRemove}
                onAdd={handleAdd}
                isDuplicate={isDuplicate}
            />
        </Grid>
    ) : null;
};
