import {CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput} from '@mui/material';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import React, {useState} from 'react';
import './ClientSearchForm.scss';
import {getNricError} from '~/utils/inputUtils';

export interface ISearchFormProps {
    onSubmit: (query: string) => void;
    disabled: boolean;
    placeholder?: string;
    getValidationError?: (id: string) => string;
}

export const ClientSearchForm = ({
    onSubmit,
    disabled,
    placeholder = `Try 'S1234567D'`,
    getValidationError = getNricError,
}: ISearchFormProps) => {
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [query, setQuery] = useState<string>('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorText = getValidationError(query);
        if (errorText !== '') {
            setErrorText(errorText);
            return;
        }
        onSubmit(query);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setErrorText(undefined);
        setQuery(e.target.value.toUpperCase());
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={!!errorText} variant="outlined" styleName="formControl">
                <OutlinedInput
                    disabled={disabled}
                    endAdornment={
                        <InputAdornment id="adornment" position="end">
                            <IconButton disabled={disabled} type="submit" aria-label="search" id="submitButton">
                                {disabled ? <CircularProgress size={24} /> : <SearchIcon htmlColor="white" />}
                            </IconButton>
                        </InputAdornment>
                    }
                    placeholder={placeholder}
                    id="searchInput"
                    value={query}
                    onChange={handleChange}
                    autoFocus
                    inputProps={{maxLength: 9}}
                />
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        </form>
    );
};
