import {ACCESS_LEVELS} from '~/constants';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';

export const isLeadSystemAdmin = (accessToken: IAccessToken) => {
    return isRole(accessToken, ACCESS_LEVELS.LEAD_SYSTEM_ADMINISTRATOR);
};

export const isSystemAdmin = (accessToken: IAccessToken) => {
    return isRole(accessToken, ACCESS_LEVELS.SYSTEM_ADMINISTRATOR);
};

export const isAppUser = (accessToken: IAccessToken | null) => {
    return (
        isRole(accessToken, ACCESS_LEVELS.APP_USER) ||
        isRole(accessToken, ACCESS_LEVELS.DATA_UPLOADER_APP_USER) ||
        isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER) ||
        isRole(accessToken, ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER)
    );
};

export const isDataUploader = (accessToken: IAccessToken) => {
    return (
        isRole(accessToken, ACCESS_LEVELS.DATA_UPLOADER) || isRole(accessToken, ACCESS_LEVELS.DATA_UPLOADER_APP_USER)
    );
};

export const isTeamAdmin = (accessToken: IAccessToken) => {
    return (
        isRole(accessToken, ACCESS_LEVELS.TEAM_ADMINISTRATOR) ||
        isRole(accessToken, ACCESS_LEVELS.TEAM_ADMINISTRATOR_APP_USER)
    );
};

export const isOpsUnitAdmin = (accessToken: IAccessToken) => {
    return (
        isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR) ||
        isRole(accessToken, ACCESS_LEVELS.OPSUNIT_ADMINISTRATOR_APP_USER)
    );
};

export const isRole = (accessToken: IAccessToken | null, role: string) => {
    return accessToken?.Permissions?.AccessLevel === role;
};
