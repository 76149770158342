import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Collapse, TableCell, TableRow, Checkbox, List, ListItem, Typography} from '@mui/material';
import {
    KeyboardArrowUpRounded as KeyboardArrowUpIcon,
    KeyboardArrowDownRounded as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {WithEnhancedTable} from '~/components/Common/Table/EnhancedTable/EnhancedTable';
import {IHeaderColumn} from '~/components/Common/Table/TableHeader/TableHeader';
import {PendingSubDomains, IPendingSubDomainData} from './PendingSubDomains';
import {ExpiryIndicator} from './ExpiryIndicator';
import {formatDate, isToday} from '~/utils/dateUtils';
import './ScreeningHistoryTable.scss';

export interface IRowData {
    id: number;
    screenedDate: string;
    clientName: string;
    clientUIN: string;
    requestId: string;
    status: string;
    dayLeftToExpire: number;
    pendingSubDomains: NullableArray<IPendingSubDomainData>;
    familyScreeningRequestId: string;
    requestedFamilyMembersLength: number;
}

export type IHeadRowData = Pick<IRowData, 'screenedDate' | 'clientName' | 'clientUIN' | 'status' | 'dayLeftToExpire'>;

export interface IScreeningHistoryTableProps {
    rows: IRowData[];
    expandAll: boolean;
}

interface IScreeningHistoryRowsProps {
    sortedRows: IRowData[];
    expandAll: boolean;
}

export const ScreeningHistoryRows = ({sortedRows, expandAll}: IScreeningHistoryRowsProps) => {
    const history = useHistory();
    const [expandList, setExpandList] = useState<number[]>([]);

    const isRowExpand = (id: number) => expandList.includes(id);

    const onButtonClickHandler =
        (familyScreeningRequestId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            history.push(`client/${familyScreeningRequestId}`);
            event.stopPropagation();
        };

    const expandCollapseRow = (id: number) => () => {
        const isExpand = isRowExpand(id);
        const updatedList: number[] = isExpand ? expandList.filter((val) => val !== id) : Array.from(expandList);

        !isExpand && updatedList.push(id);

        setExpandList(updatedList);
    };

    const expandAllRows = (expand: boolean) => {
        if (expand) {
            const ids = sortedRows.map((row) => row.id);
            setExpandList(ids);
        } else {
            setExpandList([]);
        }
    };

    const assertNewLine = (value: string) => {
        const list = value.split('\n');
        return (
            <List style={{padding: 0}}>
                {list.map((item, i) => (
                    <ListItem
                        key={i}
                        style={
                            i === list.length - 1
                                ? {
                                      padding: 0,
                                      margin: 0,
                                      marginTop: 5,
                                      color: 'var(--secondary-grey, rgba(0, 0, 0, 0.6))',
                                      fontSize: 12,
                                  }
                                : {padding: 0, margin: 0}
                        }
                    >
                        {item}
                    </ListItem>
                ))}
            </List>
        );
    };

    const setScreenedDate = (screenedDate: string) => {
        if (isToday(screenedDate)) {
            return `${formatDate(new Date(screenedDate), false, true)} (Today)`;
        } else {
            return formatDate(new Date(screenedDate), false, true);
        }
    };

    useEffect(() => {
        expandAllRows(expandAll);
    }, []);

    return (
        <>
            {sortedRows.map((row, index) => {
                return (
                    <React.Fragment key={index}>
                        <TableRow key={`mainRow_${index}`} onClick={expandCollapseRow(row.id)}>
                            <TableCell style={{width: '60px'}}>
                                <Checkbox
                                    checked={isRowExpand(row.id)}
                                    checkedIcon={<KeyboardArrowUpIcon style={{color: '#8d8d8d'}} />}
                                    icon={<KeyboardArrowDownIcon style={{color: '#ccc'}} />}
                                    color="primary"
                                    style={{padding: 0}}
                                />
                            </TableCell>
                            <TableCell>{setScreenedDate(row.screenedDate)}</TableCell>
                            <TableCell>
                                <Typography>{row.clientName}</Typography>
                                {row.requestedFamilyMembersLength > 0 && (
                                    <Typography
                                        variant="caption"
                                        style={{color: 'var(--secondary-grey, rgba(0, 0, 0, 0.6))'}}
                                    >{`+ ${row.requestedFamilyMembersLength} family member(s)`}</Typography>
                                )}
                            </TableCell>
                            <TableCell>{row.clientUIN}</TableCell>
                            <TableCell>{assertNewLine(row.status)}</TableCell>
                            <TableCell>
                                <ExpiryIndicator dayLeftToExpire={row.dayLeftToExpire} />
                            </TableCell>
                            <TableCell>
                                <Button
                                    id={`clientInfoBtn_${index}`}
                                    onClick={onButtonClickHandler(row.familyScreeningRequestId)}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                >
                                    VIEW CLIENT
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#f6f6f6'}} key={`subDomain_${index}`}>
                            <TableCell colSpan={7} padding="none" style={{borderBottom: 'none'}}>
                                <Collapse in={isRowExpand(row.id)}>
                                    <PendingSubDomains
                                        rows={row.pendingSubDomains}
                                        requestId={row.familyScreeningRequestId}
                                    />
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export const ScreeningHistoryTable = ({rows, expandAll}: IScreeningHistoryTableProps) => {
    const headColumns: IHeaderColumn<keyof IHeadRowData>[] = [
        {align: 'inherit', disablePadding: true, label: ''},
        {id: 'screenedDate', align: 'inherit', disablePadding: false, label: 'Date Screened'},
        {id: 'clientName', align: 'inherit', disablePadding: false, label: `Client's Name`},
        {id: 'clientUIN', align: 'inherit', disablePadding: false, label: `Client's NRIC/FIN`},
        {id: 'status', align: 'inherit', disablePadding: false, label: 'Information Status'},
        {id: 'dayLeftToExpire', align: 'inherit', disablePadding: false, label: 'Expiry'},
        {align: 'inherit', disablePadding: true, label: ''},
    ];

    const headerProps = {
        columns: headColumns,
    };

    const tableSortLabelIconStyle = {
        color: 'var(--primary-blue, #3949ab) !important',
    };

    const hocProps = {
        colSpan: 7,
        headerProps,
        expandAll,
        tableSortLabelIconStyle,
    };

    const EnhancedTable = WithEnhancedTable<IScreeningHistoryRowsProps, IHeadRowData, IRowData>(
        ScreeningHistoryRows,
        'screenedDate',
        rows,
        'desc',
        'screeningHistoryTable',
    );

    return <EnhancedTable {...hocProps} />;
};
