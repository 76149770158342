import {Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button} from '@mui/material';
import React from 'react';

export interface ISimpleDialogProps {
    id?: string;
    onClose: (event: object) => void;
    open: boolean;
    message: string;
    title?: string;
}

export const SimpleDialog = ({open, message, onClose, id, title = ''}: ISimpleDialogProps) => {
    return (
        <Dialog id={id} open={open} onClose={onClose} aria-describedby="alert-dialog-description">
            {title != '' && <DialogTitle style={{width: 531}}>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
