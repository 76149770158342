import {toLower, toUpper, startCase} from 'lodash';

export const getAbbreviationFromBrackets = (name: string): string => {
    if (!name.includes('(') || !name.includes(')')) {
        return '';
    }
    return name.split('(')[1].split(')')[0];
};

export const formatOrganisationName = (name: string): string => {
    const wordsToSkip = ['and', 'the', 'to', 'for', 'is', 'in', 'a', 'at', 'an', 'from', 'by', 'if', 'of'];
    const nonAbbrName = toLower(name).split(' (')[0];

    const formattedNonAbbrName = nonAbbrName
        .split(' ')
        .map((word) => {
            if (wordsToSkip.includes(word)) {
                return word;
            }
            return startCase(word);
        })
        .join(' ');

    const abbrName = toUpper(getAbbreviationFromBrackets(name));
    return abbrName === '' ? formattedNonAbbrName : `${formattedNonAbbrName} (${abbrName})`;
};

export const nameCase = (name: string): string => {
    return startCase(name);
};

export const maskNric = (nric: string): string => {
    return `${nric.substring(0, 1)}XXXX${nric.substring(5, nric.length)}`;
};

export const formatLongName = (fullName: string) => {
    if (fullName.length > 20) {
        return fullName.slice(0, 20) + '...';
    }
    return fullName;
};
