import React, {useState, useEffect} from 'react';
import {Autocomplete, Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField} from '@mui/material';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {IDataAccessMatrixField, IDataSource} from '~/interfaces/admin';
import {getDataSources} from '~/store/dataSource/dataSource.selector';
import {getSortedSubDomains} from '~/store/domain/domain.selector';
import {InputField} from '~/components/Common/InputField/InputField';
import {checkMaxLength, required} from '~/components/Common/InputField/ValidationRules';

import style from './CreateDataDictionaryDialog.scss';
import {DEFAULT_SHORT_FIELD_MAX_LENGTH, DEFAULT_LONG_FIELD_MAX_LENGTH} from '~/constants';
export interface ICreateDataDictionaryDialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (dataDictionary: IDataAccessMatrixField) => void;
    editDataDictionary?: IDataAccessMatrixField;
}

export const CreateDataDictionaryDialog = ({
    title,
    open,
    onClose,
    onSubmit,
    editDataDictionary = {
        Id: 0,
        Name: '',
        Description: '',
        SubDomainId: 0,
        DataSourceId: 0,
    },
}: ICreateDataDictionaryDialogProps) => {
    const [dataDictionary, setDataDictionary] = useState<IDataAccessMatrixField>(editDataDictionary);
    const subDomainOptions = useTypedSelector(getSortedSubDomains);
    const dataSourceOptions = useTypedSelector(getDataSources);

    useEffect(() => {
        setDataDictionary(editDataDictionary);
    }, [open]);

    const handleSubmit = () => {
        onSubmit(dataDictionary);
    };

    const canSave = () =>
        dataDictionary.Name.length > 0 &&
        dataDictionary.Name.length <= DEFAULT_SHORT_FIELD_MAX_LENGTH &&
        dataDictionary.Description.length &&
        dataDictionary.SubDomainId &&
        dataDictionary.SubDomainId > 0 &&
        dataDictionary.DataSourceId &&
        dataDictionary.DataSourceId > 0;

    const currSubDomain =
        subDomainOptions && subDomainOptions.filter((subDomain) => subDomain.Id === dataDictionary.SubDomainId)[0];
    const currDataSource =
        dataSourceOptions && dataSourceOptions.filter((dataSource) => dataSource.Id === dataDictionary.DataSourceId)[0];

    const onDialogClose = (_: unknown, reason: string) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            aria-describedby="alert-dialog-description"
            className={style.createDataDictionaryDialog}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{width: 531}}>{title}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        className={style.formInput}
                        id="sub-domain-select"
                        options={subDomainOptions}
                        getOptionLabel={(option) => option.Name}
                        disabled
                        disableClearable={true}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Select Sub Domain" />}
                        value={currSubDomain}
                    />
                    <InputField
                        htmlFor="data-dictionary-name"
                        value={dataDictionary.Name}
                        onChange={(value) => setDataDictionary({...dataDictionary, Name: value})}
                        label="Field Name"
                        validations={[required('field name')]}
                        className={style.formInput}
                        customErrorMessage={checkMaxLength(
                            dataDictionary.Name,
                            'Field Name',
                            DEFAULT_SHORT_FIELD_MAX_LENGTH,
                        )}
                    />

                    <InputField
                        htmlFor="data-dictionary-desc"
                        value={dataDictionary.Description}
                        onChange={(value) => setDataDictionary({...dataDictionary, Description: value})}
                        label="Description"
                        validations={[required('description')]}
                        className={style.formInput}
                        multiline
                        maxLength={DEFAULT_LONG_FIELD_MAX_LENGTH}
                        displayCharCounter
                    />

                    <Autocomplete
                        className={style.formInput}
                        id="data-source-select"
                        options={dataSourceOptions}
                        getOptionLabel={(option) => option.Name}
                        onChange={(_: React.ChangeEvent<{}>, dataSource: IDataSource | null) =>
                            setDataDictionary({
                                ...dataDictionary,
                                DataSourceId: dataSource?.Id,
                                DataSource: dataSource ? {...dataSource} : undefined,
                            })
                        }
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Select Data Source" />
                        )}
                        value={currDataSource}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={!canSave()}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
