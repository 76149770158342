import React from 'react';
import {Button} from '@mui/material';
import {DeleteRounded as DeleteIcon} from '@mui/icons-material';

interface IDeleteButton {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    id?: string;
}

export const DeleteButton = ({onClick, id}: IDeleteButton) => {
    return (
        <Button variant="text" color="primary" onClick={onClick} id={id}>
            <DeleteIcon style={{marginRight: 8}} />
            Delete
        </Button>
    );
};
