import React from 'react';
import {Box, Link} from '@mui/material';
import './Masthead.scss';
import governmentLogo from '~/assets/images/governmentLogo.svg';

export const Masthead = () => {
    return (
        <Link data-testid="link" href="https://www.gov.sg" target="_blank" rel="noopener noreferrer" styleName="link">
            <Box component="div" styleName="masthead">
                <img data-testid="logo" src={governmentLogo} alt="SGDS Logo" styleName="logo" />
                <span data-testid="text" styleName="text">
                    A Singapore Government Agency Website
                </span>
            </Box>
        </Link>
    );
};

export default Masthead;
