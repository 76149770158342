import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {IOnboardingTimestamp} from '~/pages/FooterResources/GettingStarted';

export const fetchVideo = async (): Promise<Blob> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/onboarding-video`,
        withCredentials: true,
        responseType: 'blob',
    });
    const blob = await response.data;
    return blob;
};

export const fetchInternetVideo = async (): Promise<string> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/internet-onboarding-video`,
        withCredentials: true,
    });
    const res = await response.data;
    return res.presignedUrl;
};

export const fetchSubs = async (): Promise<string> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/onboarding-video-subs`,
        withCredentials: true,
    });
    const text = await response.data;
    return text;
};

export const fetchTimestamps = async (): Promise<IOnboardingTimestamp[]> => {
    const response = await httpRequest({
        url: `${config.api.ADMIN_SERVICE}/onboarding-video-timestamps`,
        withCredentials: true,
    });
    const data = await response.data;
    return data;
};
