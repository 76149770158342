import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {formatCurrency} from '~/utils/currencyUtils';
import style from './CPFAccountDetails.scss';
import {formatDate} from '~/utils/dateUtils';
import {transformInvalidDateString} from '~/utils/dateUtils';
import {format} from 'date-fns';
import {SubDomainStatus} from '~/interfaces/client';
import {formatDateField, formatValue, isNotEmpty, objNoResults} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import {ERROR_COPY, FIELD_VALUES} from '~/constants';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {ErrorSubDomainText} from '../ErrorSubDomain';

export type LifeSchemeDetail = {
    Data: {
        HasCpfLifeCoverage: string;
        CpfLifePlan: string;
        MonthlyPayoutAmount: string;
        HasPaymentCommenced: string;
        LastPaymentDate: string;
    } | null;
    ErrorMessage: string;
    Status: string;
};

export type RlePayoutDetail = {
    Data: {
        TotalRleMthPayoutAmount: string;
        LastRleMthPayoutAmount: string;
        LastPaymentRelevantMonth: string;
        FirstPaymentRelevantMonth: string;
    };
    ErrorMessage: string;
    Status: string;
};

export interface IMonthlyWithdrawalDetails {
    apiDetails: {
        RlePayout: RlePayoutDetail;
        LifeScheme: LifeSchemeDetail;
        RspPayout: IRspPayoutDetail;
    }; // & extends other api type in the future;
}

export type IRspPayoutDetail = {
    Data: {
        LastMonthPayoutAmount: string;
        LastMonthPayoutDate: string;
        MonthlyPayoutAmount: string;
        TotalExemptionAmount: string;
    };
    ErrorMessage: string;
    Status: string;
};

export const MonthlyWithdrawalDetails: React.FC<IMonthlyWithdrawalDetails> = ({apiDetails}) => {
    const noResults =
        apiDetails?.RlePayout?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.LifeScheme?.Status === SubDomainStatus.COMPLETED &&
        apiDetails?.RspPayout?.Status === SubDomainStatus.COMPLETED &&
        objNoResults({
            ...apiDetails.RlePayout.Data,
            ...apiDetails.LifeScheme.Data,
            ...apiDetails.RspPayout.Data,
        });

    if (
        apiDetails?.RlePayout?.Status === SubDomainStatus.ERROR &&
        apiDetails?.LifeScheme?.Status === SubDomainStatus.ERROR &&
        apiDetails?.RspPayout?.Status === SubDomainStatus.ERROR
    ) {
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;
    }

    if (noResults) {
        return <NoData />;
    }

    return (
        <div id={style.custom3ColTable}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Scheme</TableCell>
                            <TableCell align="right">Amount of Last Monthly Payout ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-testid="table-body">
                        <TableRow data-testid="rspRow">
                            <TableCell>
                                <Typography variant="body1">Retirement Sum Scheme</Typography>
                                <Typography variant="body2" styleName="grey">
                                    {
                                        apiDetails.RspPayout &&
                                        apiDetails.RspPayout.Status === SubDomainStatus.COMPLETED
                                            ? `Monthly Payout: ${
                                                  isNotEmpty(apiDetails.RspPayout.Data?.MonthlyPayoutAmount)
                                                      ? `$${apiDetails.RspPayout.Data?.MonthlyPayoutAmount}`
                                                      : FIELD_VALUES.NO_RESULTS
                                              }`
                                            : undefined //hide field when there is subdomain error to only show the error message once
                                    }
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1">
                                    {
                                        apiDetails.RspPayout.Status !== SubDomainStatus.COMPLETED
                                            ? ERROR_COPY //Error
                                            : apiDetails.RspPayout.Data &&
                                              isNotEmpty(apiDetails.RspPayout.Data.LastMonthPayoutAmount)
                                            ? formatValue(
                                                  apiDetails.RspPayout.Data?.LastMonthPayoutAmount,
                                                  parseFloat,
                                                  formatCurrency,
                                              ) //Has Value
                                            : Boolean(apiDetails.RspPayout.ErrorMessage)
                                            ? `No results (${apiDetails.RspPayout.ErrorMessage})` //No result with error code
                                            : apiDetails.RspPayout.Data?.LastMonthPayoutAmount //No result, No access
                                    }
                                </Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.RspPayout.Status !== SubDomainStatus.COMPLETED
                                        ? undefined //hide field when there is subdomain error to only show the error message once
                                        : apiDetails.RspPayout.Data &&
                                          isNotEmpty(apiDetails.RspPayout.Data.LastMonthPayoutDate)
                                        ? formatValue(
                                              apiDetails.RspPayout.Data?.LastMonthPayoutDate,
                                              (dataStr: string) =>
                                                  format(
                                                      new Date(transformInvalidDateString(dataStr, 'YYYYMMDD')),
                                                      'dd MMM yyyy',
                                                  ),
                                          ) //Has Value
                                        : undefined}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow data-testid="lifeRow">
                            <TableCell>
                                <Typography variant="body1">CPF LIFE Scheme</Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.LifeScheme.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.LifeScheme.Data &&
                                    apiDetails.LifeScheme.Data.HasCpfLifeCoverage === 'Yes'
                                        ? apiDetails.LifeScheme.Data.CpfLifePlan
                                        : undefined}
                                </Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.LifeScheme.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.LifeScheme.Data &&
                                    apiDetails.LifeScheme.Data.HasCpfLifeCoverage
                                        ? `LIFE Coverage: ${apiDetails.LifeScheme.Data.HasCpfLifeCoverage}`
                                        : undefined}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" align="right">
                                    {apiDetails.LifeScheme.Status !== SubDomainStatus.COMPLETED
                                        ? ERROR_COPY
                                        : apiDetails.LifeScheme.Data &&
                                          apiDetails.LifeScheme.Data?.HasCpfLifeCoverage !== 'No'
                                        ? apiDetails.LifeScheme.Data.MonthlyPayoutAmount
                                        : FIELD_VALUES.NO_RESULTS}
                                </Typography>
                                <Typography variant="body2" styleName="grey" align="right">
                                    {apiDetails.LifeScheme.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.LifeScheme.Data &&
                                    apiDetails.LifeScheme.Data?.LastPaymentDate &&
                                    apiDetails.LifeScheme.Data?.HasCpfLifeCoverage === 'Yes'
                                        ? formatDateField(
                                              apiDetails.LifeScheme.Data.LastPaymentDate,
                                              false,
                                              true,
                                              false,
                                          )
                                        : undefined}
                                </Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.LifeScheme.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.LifeScheme.Data &&
                                    apiDetails.LifeScheme.Data?.HasCpfLifeCoverage === 'Yes'
                                        ? `Payment Commencement: ${apiDetails.LifeScheme.Data.HasPaymentCommenced}`
                                        : undefined}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow data-testid="rleRow">
                            <TableCell>
                                <Typography variant="body1">Reduced Life Expectancy Scheme</Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.RlePayout.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.RlePayout.Data &&
                                    isNotEmpty(apiDetails.RlePayout.Data.FirstPaymentRelevantMonth)
                                        ? `First monthly payout in ${formatDate(
                                              new Date(apiDetails.RlePayout.Data.FirstPaymentRelevantMonth),
                                              false,
                                              false,
                                              true,
                                          )}`
                                        : undefined}
                                </Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.RlePayout.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.RlePayout.Data
                                        ? `Total RLE Payout Amount over last 12 months: ${
                                              isNotEmpty(apiDetails.RlePayout.Data?.TotalRleMthPayoutAmount)
                                                  ? `$${apiDetails.RlePayout.Data?.TotalRleMthPayoutAmount}`
                                                  : FIELD_VALUES.NO_RESULTS
                                          }`
                                        : undefined}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1">
                                    {
                                        apiDetails.RlePayout.Status !== SubDomainStatus.COMPLETED
                                            ? ERROR_COPY //Error
                                            : apiDetails.RlePayout.Data &&
                                              isNotEmpty(apiDetails.RlePayout.Data.LastRleMthPayoutAmount)
                                            ? apiDetails.RlePayout.Data.LastRleMthPayoutAmount //Has Value
                                            : Boolean(apiDetails.RlePayout.ErrorMessage)
                                            ? `No results (${apiDetails.RlePayout.ErrorMessage})` //No result with error code
                                            : apiDetails.RlePayout.Data.LastRleMthPayoutAmount //No access No result
                                    }
                                </Typography>
                                <Typography variant="body2" styleName="grey">
                                    {apiDetails.RlePayout.Status === SubDomainStatus.COMPLETED &&
                                    apiDetails.RlePayout.Data &&
                                    isNotEmpty(apiDetails.RlePayout.Data.LastPaymentRelevantMonth)
                                        ? formatDate(
                                              new Date(apiDetails.RlePayout.Data.LastPaymentRelevantMonth),
                                              false,
                                              false,
                                              true,
                                          )
                                        : undefined}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
