import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import styles from './Income.scss';
import {IIncomeData} from '~/components/Client/SubDomainDisplay/Income/Income';
import {formatValue, isNoAccess, renderIfHasAccess} from '~/utils/contentUtils';

export const AnnualIncomeInfo = ({AnnualIncomeRecord, LatestYearOfAssessment}: IIncomeData) => {
    if (AnnualIncomeRecord == null) {
        return <></>;
    }
    const annualIncomeData = [
        {name: 'Annual Total Employee Income', value: AnnualIncomeRecord.TotalEmployeeIncome},
        {name: 'Annual Net Income (Sole Proprietor)', value: AnnualIncomeRecord.NetIncomeProprietor},
        {name: 'Annual Net Income (Partnership)', value: AnnualIncomeRecord.NetIncomePartner},
        {name: 'Annual Property Rental Income', value: AnnualIncomeRecord.PropertyRentalIncome},
    ].filter((data) => !isNoAccess(data.value));

    return (
        <div data-testid="incomeSection">
            {renderIfHasAccess(
                LatestYearOfAssessment,
                <>
                    <Typography styleName="grey" variant="subtitle1">
                        Latest Year of Assessment
                    </Typography>
                    <Typography variant="h6">{LatestYearOfAssessment}</Typography>
                </>,
            )}
            <Typography variant="h6" styleName="title">
                Income Information
            </Typography>
            <div id={styles.annualIncome}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" data-testid="incomeTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Assessable Income</TableCell>
                                <TableCell>Amount($)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {annualIncomeData.map(({name, value}, i) => (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1">{name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {formatValue(value, (val) => parseFloat(val).toFixed(2))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {renderIfHasAccess(
                                        AnnualIncomeRecord.TotalOtherIncome,
                                        <Typography variant="body1">Annual Total Other Income</Typography>,
                                    )}
                                    {renderIfHasAccess(
                                        AnnualIncomeRecord.EstateTrustIncome,
                                        <Typography variant="body2">Estate / Trust Income</Typography>,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {renderIfHasAccess(
                                        AnnualIncomeRecord.TotalOtherIncome,
                                        <Typography variant="body1">
                                            {formatValue(AnnualIncomeRecord.TotalOtherIncome, (val) =>
                                                parseFloat(val).toFixed(2),
                                            )}
                                        </Typography>,
                                    )}
                                    {renderIfHasAccess(
                                        AnnualIncomeRecord.EstateTrustIncome,
                                        <Typography styleName="grey" variant="body2">
                                            {formatValue(AnnualIncomeRecord.EstateTrustIncome, (val) =>
                                                parseFloat(val).toFixed(2),
                                            )}
                                        </Typography>,
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
