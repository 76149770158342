import React from 'react';
import {Box} from '@mui/material';
import {IAdminRow} from '~/interfaces/admin';
import {formatDate} from '~/utils/dateUtils';

import './RecentReview.scss';

export const RecentReview = (admin: IAdminRow) => {
    const NIL_DATE = '0001-01-01T00:00:00Z';
    const hasRecord = admin.OpsUnitId || admin.AgencyId;
    return hasRecord && admin.MostRecentReview ? (
        <Box component="div">
            <Box component="div" styleName="dateWrapper">
                {admin.MostRecentReview != NIL_DATE ? formatDate(new Date(admin.MostRecentReview)) : '-'}
            </Box>
            <Box component="div" styleName="nameWrapper">
                {admin.RecentReviewBy}
            </Box>
        </Box>
    ) : (
        <>-</>
    );
};

export default RecentReview;
