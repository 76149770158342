import React from 'react';
import {NoAnnualIncome} from '~/components/Client/SubDomainDisplay/Income/NoAnnualIncome';
import {AnnualIncomeInfo} from '~/components/Client/SubDomainDisplay/Income/AnnualIncomeInfo';

export enum IncomeInformationType {
    NOT_FOUND = 0,
    FOUND = 1,
    EXCEEDED = 4,
    NO_ASSESSMENT = 2,
}
export interface IAnnualIncome {
    TotalEmployeeIncome: string;
    NetIncomeProprietor: string;
    NetIncomePartner: string;
    PropertyRentalIncome: string;
    TotalOtherIncome: string;
    EstateTrustIncome: string;
}

export interface IIncomeData {
    InformationType: IncomeInformationType;
    LatestYearOfAssessment: string;
    AnnualIncomeRecord?: IAnnualIncome;
}

export const Income: React.FunctionComponent<{data: IIncomeData}> = ({data}) => {
    const IncomeComponent = data.InformationType == IncomeInformationType.FOUND ? AnnualIncomeInfo : NoAnnualIncome;
    return <IncomeComponent {...data} />;
};
