import React, {useEffect, useState, useRef} from 'react';
import {Button, Divider, Grid, Radio, Typography, CircularProgress} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {SearchUser} from '~/components/Admin/SearchUser/SearchUser';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {emptyUser, IAgency, IUser, IUserSearchResults} from '~/interfaces/admin';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import './DataContributorCreate.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {createDataContributor} from '~/store/dataContributor/dataContributor.thunk';
import {setAgencies} from '~/store/agency/agency.thunk';

export const DataContributorCreate = () => {
    const dispatch = useAppDispatch();
    const domainHeaderRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const agencies = Object.values(useTypedSelector((state) => state.data.admin.agencies.agencies));
    const dataContributorAgencies = agencies.filter((agency) => agency.IsDataContributor);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>(emptyUser);
    const [agency, setAgency] = useState<IAgency | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cancelDialogueOpen, setCancelDialogueOpen] = useState<boolean>(false);
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const [hasError, setHasError] = useState(false);
    const disableSubmit = !allowSaveUsers(user) || !agency || hasError;

    useEffect(() => {
        dispatch(setAgencies());
    }, []);

    const resetState = () => {
        setIsNewUser(false);
        setUser(emptyUser);
        setAgency(undefined);
        setCancelDialogueOpen(false);
    };

    const handleRadioChange = (isNewUser: boolean) => {
        setIsNewUser(isNewUser);
        setUser(emptyUser);
        setAgency(undefined);
    };

    const handleSearchUser = (searchResults: IUserSearchResults) => {
        setUser(searchResults.User);
        setAgency(searchResults.Agency);
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        resetState();
    };

    const handleCreate = async () => {
        setIsLoading(true);
        const userToCreate = isNewUser ? prepareUserCreation([user])[0] : user;
        const thunkActionResult = await dispatch(
            createDataContributor({agencyId: agency?.Id as number, user: userToCreate}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            successfulSubmit();
            const userId = thunkActionResult.payload.Id;
            history.push(`/admin/data-contributors/edit/${userId}`);
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        history.push('/admin/data-contributors');
        resetState();
    };

    const SubmitButtons = () => {
        return (
            <div styleName="buttonWrappers">
                <Button
                    onClick={() => setCancelDialogueOpen(true)}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    id="submit-button"
                    onClick={handleCreate}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    return (
        <div styleName="dataContributorCreate">
            <div styleName="userEditForm">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Data Contributor Details
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        <SubmitButtons />
                    </Grid>
                </Grid>

                <div styleName="radioGroup">
                    <label onClick={() => handleRadioChange(false)}>
                        <Radio color="primary" checked={!isNewUser} />
                        Existing User
                    </label>
                    <label onClick={() => handleRadioChange(true)}>
                        <Radio color="primary" checked={isNewUser} />
                        New User
                    </label>
                </div>

                <Divider styleName="divider" />

                {isNewUser ? (
                    <UserDetails
                        heading={'data contributor'}
                        details={user}
                        setUser={setUser}
                        fieldsToDisable={[]}
                        disableInput={false}
                        agencies={dataContributorAgencies}
                        setAgency={setAgency}
                        setHasError={setHasError}
                    />
                ) : (
                    <SearchUser onSearch={handleSearchUser} />
                )}
            </div>
            <SubmitFooter
                isButtonDisabled={disableSubmit}
                domainHeaderRef={domainHeaderRef}
                isLoading={isLoading}
                handleCancel={() => setCancelDialogueOpen(true)}
                handleSubmit={handleCreate}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={() => setCancelDialogueOpen(false)}
                onConfirm={handleCancel}
                open={cancelDialogueOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
        </div>
    );
};
