import React from 'react';
import {Grid, Typography, Divider, Box} from '@mui/material';
import {FIELD_VALUES} from '~/constants';
import {
    EnhancedTableExpandableRow,
    ICustomHeaderColumn,
} from '~/components/Common/Table/EnhancedTable/EnhancedTableExpandableRow';
import {formatDateField, isNotEmpty, objNoAccess, objNoResults} from '~/utils/contentUtils';
import {NoData} from '../CompletedSubDomain';
import './MUISAssistance.scss';

export type IMUISAssistanceData = IMUISAssistance[];

export interface IMUISAssistance {
    Id: number;
    TypeOfDisbursement: string;
    TypeOfAssistance: string;
    CaseStatus: string;
    StartDate: string;
    EndDate: string;
    AssistanceAmount: string;
    DisbursedDate: string;
    DisbursementDesc: string;
}

export interface IRowData {
    id: number;
    model: IMUISAssistance;
    expandableContent?: React.ReactElement;
    assistanceStartDate: string;
    assistanceEndDate: string;
    disbursedDate: string;
    assistanceAmount: string;
    startDateNumber: number;
    endDateNumber: number;
    disbursedDateNumber: number;
}

type IHeadRowData = Pick<
    IRowData,
    | 'assistanceStartDate'
    | 'assistanceEndDate'
    | 'disbursedDate'
    | 'assistanceAmount'
    | 'startDateNumber'
    | 'endDateNumber'
    | 'disbursedDateNumber'
>;

interface IRamadhanRowData extends IRowData {
    assistanceType?: string;
}

type IHeadRamadhanRowData = Pick<
    IRamadhanRowData,
    | 'assistanceType'
    | 'assistanceStartDate'
    | 'assistanceEndDate'
    | 'disbursedDate'
    | 'assistanceAmount'
    | 'startDateNumber'
    | 'endDateNumber'
    | 'disbursedDateNumber'
>;

export enum DISBURSEMENT_TYPE {
    ZAKAT = 'Zakat',
    FIDYAH = 'Fidyah',
    RAMADHAN = 'Ramadhan',
    YEAREND = 'YearEnd',
}

export const MUISAssistance: React.FunctionComponent<{data: IMUISAssistance[]}> = ({data}) => {
    const [zakatData, fidyahData, yearEndData, ramadhanData] = destructureDisbursementTypes(data);

    const muisHeadColumns: ICustomHeaderColumn<IMUISAssistance, keyof IHeadRowData>[] = [
        {
            id: 'startDateNumber',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1" styleName="padLeft">
                        Assistance Start Date
                    </Typography>
                </Grid>
            ),
            column: tableValues.startDate,
        },
        {
            id: 'endDateNumber',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Assistance End Date</Typography>
                </Grid>
            ),
            column: tableValues.endDate,
        },
        {
            id: 'disbursedDateNumber',
            align: 'inherit',
            disablePadding: false,
            label: (
                <Grid>
                    <Typography variant="body1">Disbursement Date</Typography>
                </Grid>
            ),
            column: tableValues.disbursedDate,
        },
        {
            id: 'assistanceAmount',
            align: 'right',
            disablePadding: false,
            label: <Typography variant="body1">Assistance Amount ($)</Typography>,
            column: tableValues.assistanceAmount,
        },
    ];

    const ramadhanHeadColumns = (
        [
            {
                id: 'assistanceType',
                align: 'inherit',
                disablePadding: false,
                label: (
                    <Typography variant="body1" styleName="padLeft">
                        Assistance Type
                    </Typography>
                ),
                column: tableValues.assistanceType,
            },
            {
                id: 'startDateNumber',
                align: 'inherit',
                disablePadding: false,
                label: (
                    <Grid>
                        <Typography variant="body1">Assistance Start Date</Typography>
                    </Grid>
                ),
                column: tableValues.startDate,
            },
        ] as unknown as ICustomHeaderColumn<IMUISAssistance, keyof IHeadRowData>[]
    ).concat(muisHeadColumns.slice(1));

    const createAssistanceTable = (assistanceData: IMUISAssistance[]) => {
        const numRows = assistanceData.length;
        const defaultRows = numRows < 5 ? numRows : numRows < 10 ? 5 : 10;
        return assistanceData[0].TypeOfDisbursement === DISBURSEMENT_TYPE.RAMADHAN ? (
            <div data-testid="ramadhan-muis-assistance-table">
                {EnhancedTableExpandableRow<IHeadRamadhanRowData, IRamadhanRowData, IMUISAssistance>(
                    ramadhanHeadColumns as ICustomHeaderColumn<IMUISAssistance, keyof IHeadRamadhanRowData>[],
                    convertToRowData(assistanceData),
                    false,
                    true,
                    'startDateNumber',
                    defaultRows,
                    'desc',
                )}
            </div>
        ) : (
            <div data-testid="general-muis-assistance-table">
                {EnhancedTableExpandableRow<IHeadRowData, IRowData, IMUISAssistance>(
                    muisHeadColumns,
                    convertToRowData(assistanceData),
                    false,
                    true,
                    'startDateNumber',
                    defaultRows,
                    'desc',
                )}
            </div>
        );
    };

    const createAssistanceDisplay = (assistanceData: IMUISAssistance[], title: string) => {
        const tableFields = assistanceData.map((data: IMUISAssistance) => {
            return {
                StartDate: data.StartDate,
                EndDate: data.EndDate,
                DisbursedDate: data.DisbursedDate,
                AssistanceAmount: data.AssistanceAmount,
                TypeOfAssistance: data.TypeOfAssistance,
            };
        });

        return (
            <>
                {assistanceData.length > 0 ? (
                    <div data-testid={`assistance-display-container-${assistanceData[0].TypeOfDisbursement}`}>
                        <Typography data-testid="disbursement-type-header" variant="h5" styleName="disbursementType">
                            {title}
                        </Typography>
                        <Divider
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        />
                        {assistanceData[0].CaseStatus !== FIELD_VALUES.NO_ACCESS && (
                            <Box
                                bgcolor="#007B4F"
                                borderRadius="4px"
                                color="white"
                                padding="5px"
                                display="inline-block"
                                data-testid="assistance-status"
                                mb="5px"
                            >{`${assistanceData[0].CaseStatus}`}</Box>
                        )}

                        {assistanceData[0].DisbursementDesc !== FIELD_VALUES.NO_ACCESS && (
                            <Typography
                                data-testid="disbursement-description"
                                variant="body1"
                                styleName="disbursementDescription"
                            >
                                {`Disbursement Description: ${assistanceData[0].DisbursementDesc}`}
                            </Typography>
                        )}

                        {!objNoAccess(tableFields) && createAssistanceTable(assistanceData)}

                        <Divider
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        />
                    </div>
                ) : undefined}
            </>
        );
    };

    if (objNoResults(data)) {
        return <NoData />;
    }

    return (
        <div data-testid="muis-container" styleName="muisAssistance">
            <>{createAssistanceDisplay(zakatData, 'Zakat')}</>
            <>{createAssistanceDisplay(ramadhanData, 'Ramadhan')}</>
            <>{createAssistanceDisplay(fidyahData, 'Fidyah')}</>
            <>{createAssistanceDisplay(yearEndData, 'Year End')}</>
        </div>
    );
};

export const destructureDisbursementTypes = (data: IMUISAssistance[]) => {
    const zakatData: IMUISAssistance[] = [];
    const fidyahData: IMUISAssistance[] = [];
    const yearEndData: IMUISAssistance[] = [];
    const ramadhanData: IMUISAssistance[] = [];
    data.forEach((assistance: IMUISAssistance) => {
        if (isNotEmpty(assistance.TypeOfDisbursement)) {
            switch (assistance.TypeOfDisbursement) {
                case DISBURSEMENT_TYPE.ZAKAT:
                    zakatData.push(assistance);
                    break;
                case DISBURSEMENT_TYPE.FIDYAH:
                    fidyahData.push(assistance);
                    break;
                case DISBURSEMENT_TYPE.YEAREND:
                    yearEndData.push(assistance);
                    break;
                case DISBURSEMENT_TYPE.RAMADHAN:
                    return ramadhanData.push(assistance);
                default:
                    // eslint-disable-next-line no-console
                    console.error('Unexpected disbursement type', assistance.TypeOfDisbursement);
            }
        }
    });
    return [zakatData, fidyahData, yearEndData, ramadhanData];
};

const tableValues = {
    startDate: function startDate(model: IMUISAssistance) {
        return (
            <Grid>
                <Typography variant="body1" styleName="padLeft">
                    {formatDateField(model.StartDate, false, true)}
                </Typography>
            </Grid>
        );
    },
    endDate: function endDate(model: IMUISAssistance) {
        return (
            <Grid>
                <Typography variant="body1">{formatDateField(model.EndDate, false, true)}</Typography>
            </Grid>
        );
    },
    disbursedDate: function disbursedDate(model: IMUISAssistance) {
        return (
            <Grid>
                <Typography variant="body1">{formatDateField(model.DisbursedDate, false, true)}</Typography>
            </Grid>
        );
    },
    assistanceAmount: function disbursedDate(model: IMUISAssistance) {
        return (
            <Grid justifyContent="flex-end">
                <Typography align="right" variant="body1" styleName="padRight">
                    {model.AssistanceAmount}
                </Typography>
            </Grid>
        );
    },
    assistanceType: function assistanceType(model: IMUISAssistance) {
        return (
            <Grid>
                <Typography variant="body1" styleName="padLeft">
                    {model.TypeOfAssistance}
                </Typography>
            </Grid>
        );
    },
};

export const convertToRowData = (data: IMUISAssistance[]) => {
    return data.map((assistance: IMUISAssistance) => {
        return assistance.TypeOfDisbursement === DISBURSEMENT_TYPE.RAMADHAN
            ? {
                  id: assistance.Id,
                  model: assistance,
                  assistanceStartDate: assistance.StartDate,
                  assistanceEndDate: assistance.EndDate,
                  disbursedDate: assistance.DisbursedDate,
                  assistanceAmount: assistance.AssistanceAmount,
                  assistanceType: assistance.TypeOfAssistance,
                  startDateNumber: new Date(assistance.StartDate).getTime(),
                  endDateNumber: new Date(assistance.StartDate).getTime(),
                  disbursedDateNumber: new Date(assistance.StartDate).getTime(),
              }
            : {
                  id: assistance.Id,
                  model: assistance,
                  assistanceStartDate: assistance.StartDate,
                  assistanceEndDate: assistance.EndDate,
                  disbursedDate: assistance.DisbursedDate,
                  assistanceAmount: assistance.AssistanceAmount,
                  startDateNumber: new Date(assistance.StartDate).getTime(),
                  endDateNumber: new Date(assistance.StartDate).getTime(),
                  disbursedDateNumber: new Date(assistance.StartDate).getTime(),
              };
    });
};
