export const useUpdateWigetSetting = () => {
    const updateWidgetVerticalOffset = (verticalOffset: string) => {
        if (window && typeof window.zE === 'function')
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    offset: {
                        vertical: verticalOffset,
                    },
                },
            });
    };

    return {updateWidgetVerticalOffset};
};
