import Grid from '@mui/material/Grid';
import React from 'react';

interface IGridHeaderTwoColumn {
    style?: React.CSSProperties;
    leftHeader: React.ReactNode;
    rightHeader: React.ReactNode;
}

const GridHeaderTwoColumn = (param: IGridHeaderTwoColumn) => {
    return (
        <Grid
            padding={'1rem'}
            container
            color={'white'}
            height={'auto'}
            alignItems={'center'}
            xs={12}
            item
            display={'flex'}
            style={{backgroundColor: '#3948AB'}}
            border={1}
            borderColor={'#DEDEDE'}
        >
            <Grid item xs={6}>
                {param.leftHeader}
            </Grid>
            <Grid container item xs={6} justifyContent={'flex-end'}>
                {param.rightHeader}
            </Grid>
        </Grid>
    );
};

export default GridHeaderTwoColumn;
