import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import {ACCESS_REASON, COPIES} from '~/constants';
import {IFamilyMember} from '~/interfaces/client';
import {IAccessReasons} from '~/interfaces/clientUser';
import styles from './AccessReason.scss';

interface IAccessReasonProps {
    familyMember: IFamilyMember;
    items: IRowData[];
    accessReasons: IAccessReasons;
    onAccessReasonsChange: (familyMember: IFamilyMember, accessReasons: IAccessReasons) => void;
    onApplyAllReasons?: (accessReason: IAccessReasons) => void;
}

export const AccessReason = ({
    familyMember,
    items,
    accessReasons,
    onAccessReasonsChange,
    onApplyAllReasons,
}: IAccessReasonProps) => {
    const accessReasonOptions = [
        {code: ACCESS_REASON.LOCATE_CLIENT_FAMILY_MEMBER, text: `To locate the client's family member`},
        {code: ACCESS_REASON.CHECK_CLIENT_ELIGIBILITY, text: `To check client's eligibility`},
        {code: ACCESS_REASON.OTHERS, text: `Others (Indicate in the text box below)*`},
    ];

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        onAccessReasonsChange(familyMember, {
            Codes: accessReasons.Codes.includes(event.target.name as ACCESS_REASON)
                ? accessReasons.Codes.filter((c) => c !== (event.target.name as ACCESS_REASON))
                : [...accessReasons.Codes, event.target.name as ACCESS_REASON],
            OtherReason: accessReasons.OtherReason,
        });
    };

    const handleOthersReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
        onAccessReasonsChange(familyMember, {Codes: accessReasons.Codes, OtherReason: e.target.value});
    };

    return (
        <Box component="div" className={styles.accessReason}>
            {items.length > 0 ? (
                <Card id="mainCard">
                    <CardContent>
                        <FormControl component="fieldset" fullWidth>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="h5" color="primary" className={styles.formTitle}>
                                        {familyMember.Name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {onApplyAllReasons && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            className={styles.headerButton}
                                            onClick={() => onApplyAllReasons(accessReasons)}
                                            data-testid="applyToAll"
                                        >
                                            {COPIES.APPLY_ALL_BUTTON}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Typography variant="subtitle1" className={styles.formSubtitle}>
                                Please indicate the reason(s) for requesting the above sub-domains.
                            </Typography>
                            <FormGroup>
                                {accessReasonOptions.map(({code, text}) => (
                                    <FormControlLabel
                                        key={code}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={accessReasons.Codes.includes(code)}
                                                onChange={handleCheckboxChange}
                                                name={code}
                                                data-testid={`accessReasonOption${code}`}
                                            />
                                        }
                                        label={text}
                                        className={styles.checkbox}
                                    />
                                ))}
                                <TextField
                                    data-testid="otherReasonTextBox"
                                    label="Indicate reason"
                                    variant="outlined"
                                    disabled={!accessReasons.Codes.includes(ACCESS_REASON.OTHERS)}
                                    value={accessReasons.OtherReason}
                                    onChange={handleOthersReasonChange}
                                    style={{marginTop: '15px'}}
                                    inputProps={{
                                        maxLength: 200,
                                    }}
                                    fullWidth
                                    multiline
                                />
                                <FormHelperText className={styles.alignRight}>
                                    {200 - accessReasons.OtherReason.length} characters remaining
                                </FormHelperText>
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
            ) : (
                <Typography variant="h6" styleName="noItem">
                    Sub-Domains Removed
                </Typography>
            )}
        </Box>
    );
};
