export const typographyTheme: {} = {
    styleOverrides: {
        caption: {
            fontSize: 12,
            lineHeight: 1.33,
            letterSpacing: 0.4,
            fontWeight: 400,
        },
    },
};
