import React, {ComponentType, ReactNode, useEffect, useState} from 'react';
import {
    AutocompleteGetTagProps,
    AutocompleteRenderOptionState,
    Checkbox,
    FilterOptionsState,
    InputAdornment,
    Paper,
    TextField,
    Autocomplete,
    PopperProps,
    Box,
    createFilterOptions,
} from '@mui/material';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {
    ArrowDropDownOutlined as ArrowDropDownIcon,
    CheckBoxRounded as CheckBoxIcon,
    CheckBoxOutlineBlankRounded as CheckBoxOutlineBlankIcon,
    SearchRounded as SearchIcon,
} from '@mui/icons-material';
import './MultiSelectAutocomplete.scss';

export const ACTION_OPTION = '_ACTION_OPTION';

const defaultStyles = {
    autocomplete: {},
    selectAllCheckBox: {},
    normalCheckBox: {},
    optionSelected: {
        '&[aria-selected="true"]': {
            backgroundColor: 'white',
        },
    },
};

interface sortProps {
    (stringA: string, stringB: string): number;
}

const styles = () => createStyles(defaultStyles);
interface IMultiSelectAutocomplete extends WithStyles<typeof styles> {
    options: string[];
    selectedValues: string[];
    label: string;
    selectAllLabel?: string;
    clearAllLabel?: string;
    tagRenderer: (value: string[], getTagProps: AutocompleteGetTagProps) => ReactNode;
    onSelectAll?: (isSelected: boolean) => void;
    onClearOptions?: () => void;
    onToggleOption: (selectedOptions: string[]) => void;
    actionRenderer?: () => ReactNode;
    //sort can take in a functional component(see arrayUtils.ts) to pass to .sort() method so sort strings based on a certain criteria if required
    //if sort parameter is not passed, it will sort by ascending default. if false is passed, no sorting will be done
    sort?: boolean | sortProps;
    popper?: ComponentType<PopperProps>;
    customizeOptionDisplay?: (option: string) => string;
    infoRenderer?: () => ReactNode;
    limit?: number;
    'data-testid'?: string;
}

const CheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckboxCheckedIcon = <CheckBoxIcon fontSize="small" />;

export const UnstyledMultiSelectAutocomplete = ({
    options,
    selectedValues,
    label,
    tagRenderer,
    selectAllLabel = 'Select All',
    clearAllLabel = 'Clear All',
    onToggleOption,
    onClearOptions,
    onSelectAll,
    classes,
    actionRenderer,
    sort = true,
    popper,
    customizeOptionDisplay,
    infoRenderer,
    limit,
    'data-testid': dataTestId,
}: IMultiSelectAutocomplete) => {
    const renderSelectAll = onSelectAll && onClearOptions;
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [isHover, setIsHover] = useState<boolean>(false);
    const filter = createFilterOptions<string>();
    const allSelected = options.length === selectedValues.length;
    const handleToggleSelectAll = () => {
        onSelectAll && onSelectAll(!allSelected);
    };

    useEffect(() => {
        if (!isInputFocused) setInputValue('');
    }, [isInputFocused]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const optionRenderer = (props: any, option: string, {selected}: AutocompleteRenderOptionState) => {
        const selectAllProps =
            option === selectAllLabel || option === clearAllLabel
                ? {className: classes.selectAllCheckBox, checked: allSelected}
                : {};

        const selectAllLabelProps =
            option === selectAllLabel || option === clearAllLabel ? 'optionTagWithSelectAll' : 'optionTag';

        if (option === ACTION_OPTION && actionRenderer) {
            return actionRenderer();
        } else {
            return (
                <li styleName={selectAllLabelProps} key={option} {...props}>
                    <div>
                        <Checkbox
                            icon={CheckboxIcon}
                            checkedIcon={CheckboxCheckedIcon}
                            checked={selected}
                            color="primary"
                            className={classes.normalCheckBox}
                            {...selectAllProps}
                        />
                        <label styleName="labelTag">
                            {customizeOptionDisplay ? customizeOptionDisplay(option) : option}
                        </label>
                    </div>
                </li>
            );
        }
    };

    const getCursor = () => {
        return isInputFocused ? 'text' : 'pointer';
    };

    const getEndAdornment = () => {
        return (
            <InputAdornment position="end">
                {isInputFocused ? <SearchIcon /> : <ArrowDropDownIcon style={{color: '#3949ab'}} />}
            </InputAdornment>
        );
    };

    const getLabel = (): string => {
        if (!isInputFocused && selectedValues.length === 0) {
            return label;
        }
        return '';
    };

    const getPlaceholder = (): string => {
        if (!isInputFocused && selectedValues.length > 0) {
            return '';
        }
        return 'Search';
    };

    const handleChange = (_: React.ChangeEvent<{}>, selectedOptions: string[] | null, reason: string) => {
        if (selectedOptions === null) return;

        if (reason === 'selectOption' || reason === 'removeOption') {
            if (selectedOptions.find((option) => option === selectAllLabel || option === clearAllLabel)) {
                handleToggleSelectAll();
            } else {
                onToggleOption && onToggleOption(selectedOptions);
            }
        } else if (reason === 'clear') {
            onClearOptions && onClearOptions();
        }
    };

    const handleInputChange = (_: React.ChangeEvent<{}>, value: string | null, reason: string) => {
        if (value === null) return;
        if (reason === 'reset' && isInputFocused) {
            setInputValue(inputValue);
        } else if (reason === 'reset' && !isInputFocused) {
            setInputValue('');
        } else {
            setInputValue(value);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        if (event.key === 'Backspace') {
            event.stopPropagation();
        }
    };

    const tagRendererWrapper = (value: string[], getTagProps: AutocompleteGetTagProps) => {
        if (!isInputFocused && selectedValues.length > 0) {
            return tagRenderer(value, getTagProps);
        }
        return null;
    };

    return (
        <Paper
            className={classes.autocomplete}
            elevation={isHover || isInputFocused ? 6 : 1}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            data-testid={dataTestId}
        >
            <Autocomplete
                PopperComponent={popper ? popper : undefined}
                classes={{
                    option: classes.optionSelected,
                }}
                open={isInputFocused}
                multiple
                forcePopupIcon={false}
                disableClearable
                inputValue={inputValue}
                options={options}
                disableCloseOnSelect
                onInputChange={handleInputChange}
                onChange={handleChange}
                value={selectedValues}
                filterOptions={(options: string[], params: FilterOptionsState<string>): string[] => {
                    let filtered: string[] = filter(options, params);
                    if (sort === true) {
                        filtered.sort();
                    } else if (sort !== false) {
                        filtered.sort(sort);
                    }
                    if (params.inputValue.length > 0) {
                        return filtered;
                    }

                    if (renderSelectAll) {
                        const topLabel = allSelected ? clearAllLabel : selectAllLabel;
                        filtered = [topLabel, ...filtered];
                    }
                    if (actionRenderer) {
                        filtered = [...filtered, ACTION_OPTION];
                    }
                    return filtered;
                }}
                renderOption={optionRenderer}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            styleName="textField"
                            onKeyDown={handleKeyDown}
                            onBlur={() => setIsInputFocused(false)}
                            onFocus={() => setIsInputFocused(true)}
                            variant="outlined"
                            InputLabelProps={{...params.InputLabelProps, shrink: false, disableAnimation: true}}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: getEndAdornment(),
                                style: {cursor: getCursor()},
                            }}
                            inputProps={{
                                ...params.inputProps,
                                style: {cursor: getCursor(), appearance: 'none', minWidth: 0},
                            }}
                            label={getLabel()}
                            placeholder={getPlaceholder()}
                        />
                    );
                }}
                renderTags={tagRendererWrapper}
                getOptionDisabled={
                    limit ? (option) => selectedValues.length >= limit && !selectedValues.includes(option) : () => false
                }
                PaperComponent={(params) => (
                    <Paper data-testid="autocomplete-popup">
                        {infoRenderer && infoRenderer()}
                        <Box {...params}></Box>
                    </Paper>
                )}
            />
        </Paper>
    );
};

export const MultiSelectAutocomplete = withStyles(styles)(UnstyledMultiSelectAutocomplete);
