export type Order = 'asc' | 'desc';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    const elemA = a[orderBy];
    const elemB = b[orderBy];

    // Apply case insensitive comparator if types are string
    if (typeof elemA === 'string' && typeof elemB === 'string') {
        if (elemB.toLowerCase() < elemA.toLowerCase()) {
            return -1;
        }
        if (elemB.toLowerCase() > elemA.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    if (elemB < elemA) {
        return -1;
    }
    if (elemB > elemA) {
        return 1;
    }
    return 0;
};

export const getComparator = <K>(
    order: Order,
    orderBy: keyof K,
): ((a: {[key in keyof K]: K[key]}, b: {[key in keyof K]: K[key]}) => number) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
