import React from 'react';
import {Helmet} from 'react-helmet';
import {config} from '~/config';

export const HtmlHead = () => {
    let title = 'One Client View';
    const env = config.environment;

    if (env != 'prod') {
        title = `One Client View - ${env}`;
    }

    let wogaaSrc = '';
    if (env === 'prod') {
        wogaaSrc = 'https://assets.wogaa.sg/scripts/wogaa.js';
    } else if (env === 'dev' || env === 'uat') {
        wogaaSrc = 'https://assets.dcube.cloud/scripts/wogaa.js';
    }

    return (
        <Helmet>
            <title>{title}</title>
            {wogaaSrc && config.internetEnabled && <script src={wogaaSrc}></script>}
        </Helmet>
    );
};
