import React from 'react';
import {Grid, Typography} from '@mui/material';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import styles from './GrantsAndLoans.scss';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {getAlphabeticalComparator, getNumericComparator} from '~/components/Common/ListModel/comparators';
import {currencyFormatter} from '~/utils/currencyUtils';
import {allNoAccess, formatValue, isNotEmpty} from '~/utils/contentUtils';

export interface IGrantsAndLoans {
    ProximityHousingGrant: string;
}

export interface IGrantsAndLoansDOS {
    LoanBalance: string;
    LoanTenure: string;
}

interface IGrantsAndLoansData {
    Id?: number;
    HousingGrantsAndLoans: string;
    Amount: string;
}

const HDB_LOAN_BALANCE = 'HDB Loan Balance';

export const GrantsAndLoans: React.FunctionComponent<{
    data?: IGrantsAndLoans;
    dosData?: IGrantsAndLoansDOS;
    toDisplayDos: boolean;
}> = ({data, dosData, toDisplayDos}) => {
    if (!data && !toDisplayDos) return <></>;

    const grantsAndLoans = [
        {
            label: 'Indicator of Proximity Housing Grant',
            value: data?.ProximityHousingGrant,
        },
    ];
    const grantsAndLoansFormattedData = [
        {
            Id: 1,
            HousingGrantsAndLoans: HDB_LOAN_BALANCE,
            Amount: dosData ? formatValue(dosData.LoanBalance, currencyFormatter) : '',
        },
    ];

    const housingGrantsAndLoans = (info: IGrantsAndLoansData) => {
        if (info.HousingGrantsAndLoans == HDB_LOAN_BALANCE) {
            return (
                <>
                    <Typography data-testid="label_accumulated" variant="body2">
                        {HDB_LOAN_BALANCE}
                    </Typography>
                    {dosData?.LoanTenure && (
                        <Typography data-testid="caption_accumulated" variant="caption" className={styles.caption}>
                            {isNotEmpty(dosData.LoanTenure)
                                ? `${dosData.LoanTenure} Years Loan Tenure`
                                : dosData.LoanTenure}
                        </Typography>
                    )}
                </>
            );
        }
        return info.HousingGrantsAndLoans;
    };
    const amount = (info: IGrantsAndLoansData) => <div>{info.Amount}</div>;
    const housingGrantsAndLoansValue = (info: IGrantsAndLoansData) => info.HousingGrantsAndLoans;
    const amountValue = (info: IGrantsAndLoansData): number => +info.Amount;

    return (
        <Grid data-testid="grantsAndLoans" item styleName="content">
            <div id={styles.customTable}>
                {data && (
                    <Grid data-testid="assistanceDetails" container spacing={3} id={styles.details}>
                        <SimpleGridDisplay fields={grantsAndLoans} />
                    </Grid>
                )}
                {dosData && toDisplayDos && !allNoAccess([dosData?.LoanBalance, dosData?.LoanTenure]) ? (
                    <ListModel
                        columns={[housingGrantsAndLoans, amount]}
                        headers={['Housing Grants and Loans', 'Amount ($)']}
                        modelList={grantsAndLoansFormattedData}
                        modelComparator={[
                            getAlphabeticalComparator(housingGrantsAndLoansValue),
                            getNumericComparator(amountValue),
                        ]}
                        variant="expanded"
                        defaultSortOrder="desc"
                    />
                ) : (
                    <></>
                )}
            </div>
        </Grid>
    );
};
