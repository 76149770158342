import {createAsyncThunk} from '@reduxjs/toolkit';
import * as services from '~/services/releaseNoteServices';
import {IReleaseNote} from '~/interfaces/common';
import {RootState} from '../index';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {API_FETCH_STATE} from '~/constants';
import {Flag} from '~/store/hydrateFlag/hydrateFlag.slice';

export interface ICreateReleaseNote {
    releaseNoteId: number;
    releaseNoteResponse: IReleaseNote;
}

export interface IUpdateReleaseNote {
    releaseNoteId: number;
    releaseNoteDetails: IReleaseNote;
}

export interface IDeleteReleaseNote {
    releaseNoteId: number;
    releaseNote: IReleaseNote;
}

export const getReleaseNotes = createAsyncThunk<IReleaseNote[], void, {state: RootState; rejectValue: string}>(
    '/releaseNotes/getReleaseNotes',
    async (_, {rejectWithValue, dispatch}) => {
        dispatch(fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE(API_FETCH_STATE.PENDING));
        try {
            const releaseNotes = await services.getReleaseNotes();
            dispatch(fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE(API_FETCH_STATE.SUCCESS));
            return releaseNotes;
        } catch (error) {
            dispatch(fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE(API_FETCH_STATE.ERROR));
            return rejectWithValue('Error in fetching release notes');
        }
    },
);

export const setReleaseNote = createAsyncThunk<IReleaseNote | void, number, {state: RootState}>(
    '/releaseNote/setReleaseNote',
    async (releaseNoteId, {dispatch, getState}) => {
        if (!getState().data.flags[Flag.RELEASE_NOTE]) {
            dispatch({type: fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
            try {
                const releaseNote = await services.getReleaseNote(releaseNoteId);
                dispatch({type: fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
                return releaseNote;
            } catch (err) {
                dispatch({type: fetchStateActions.SET_RELEASE_NOTE_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
                throw err;
            }
        }
    },
);

export const createReleaseNote = createAsyncThunk<void, IReleaseNote, {state: RootState; rejectValue: string}>(
    '/releaseNote/createReleaseNote',
    async (releaseNote, {rejectWithValue}) => {
        try {
            await services.createReleaseNote(releaseNote);
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error.message);
        }
    },
);

export const updateReleaseNoteDetails = createAsyncThunk<void, IReleaseNote, {state: RootState}>(
    '/release-note/updateReleaseNoteDetails',
    async (releaseNote, {rejectWithValue}) => {
        try {
            await services.updateReleaseNoteDetails(releaseNote);
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error.message);
        }
    },
);

export const deleteReleaseNote = createAsyncThunk<
    void,
    {releaseNoteId: number},
    {state: RootState; rejectValue: string}
>('/release-note/deleteReleaseNote', async ({releaseNoteId}, {rejectWithValue}) => {
    try {
        await services.deleteReleaseNote(releaseNoteId);
    } catch (err) {
        const error = err as Error;
        return rejectWithValue(error.message);
    }
});
