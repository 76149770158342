import React, {useEffect} from 'react';
import {Button, CircularProgress, Grid, Paper} from '@mui/material';
import {setFooterDisplay} from '~/utils/forceFooterDisplay';
import './EmailFooter.scss';

export interface IEmailFooterProps {
    buttonsDisabled: boolean;
    primaryButtonText: string;
    secondaryButtonText: string;
    handlePrimary: () => void;
    handleSecondary: () => void;
    primaryLoading?: boolean;
    secondaryLoading?: boolean;
}

export const EmailFooter = ({
    buttonsDisabled,
    primaryButtonText,
    secondaryButtonText,
    handlePrimary,
    handleSecondary,
    primaryLoading,
    secondaryLoading,
}: IEmailFooterProps) => {
    useEffect(() => {
        setFooterDisplay(false);
        return () => {
            setFooterDisplay(true);
        };
    }, []);

    return (
        <>
            <Paper id="emailFooter" elevation={2} styleName="emailFooter" square>
                <Grid container justifyContent="flex-end" styleName="submitWrapper">
                    <Grid item styleName="footerButton">
                        <Button
                            variant="text"
                            size="large"
                            color="primary"
                            data-testid="secondaryButton"
                            onClick={() => {
                                handleSecondary();
                            }}
                            disabled={buttonsDisabled}
                        >
                            {secondaryLoading ? <CircularProgress size={24} color="inherit" /> : secondaryButtonText}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            data-testid="primaryButton"
                            onClick={() => {
                                handlePrimary();
                            }}
                            disabled={buttonsDisabled}
                        >
                            {primaryLoading ? <CircularProgress size={24} color="inherit" /> : primaryButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
