import React from 'react';
import {Dialog, DialogContentText, DialogTitle, DialogActions, Button, IconButton, DialogContent} from '@mui/material';
import {WithStyles, createStyles, withStyles} from '@mui/styles';
import {CloseRounded as CloseIcon} from '@mui/icons-material';

import './CustomButtonsDialog.scss';

const defaultStyles = {
    root: {},
    actions: {},
    buttons: {},
};

const styles = () => createStyles(defaultStyles);

export interface ICustomButtonsDialogProps extends WithStyles<typeof styles> {
    id?: string;
    onClose: (event: object) => void;
    open: boolean;
    contentText: string;
    buttonText?: string;
    buttonFunction?: (event: object) => void;
    children?: React.ReactNode;
    title?: string;
}

// This differs from SimpleDialog in that it simplifies dialog creation by allowing multiple
// actions to be created via props.children
export const UnstyledCustomButtonsDialog = ({
    open,
    title,
    contentText,
    onClose,
    id,
    children,
    classes,
}: ICustomButtonsDialogProps) => {
    const defaultAction = () => (
        <Button onClick={onClose} color="primary">
            Ok
        </Button>
    );

    return (
        <Dialog
            id={id}
            open={open}
            onClose={onClose}
            aria-describedby="alert-dialog-description"
            styleName="general-dialog"
        >
            <DialogTitle styleName="dialog-title">
                {onClose ? (
                    <IconButton aria-label="close" styleName="close-button" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            {title && (
                <DialogContent>
                    <DialogContentText styleName="dialog-title">{title}</DialogContentText>
                </DialogContent>
            )}
            <DialogContent data-testid="custom-text">
                {contentText.split('\n').map((elementText, index) => (
                    <DialogContentText key={index} styleName="content-text">
                        {elementText}
                    </DialogContentText>
                ))}
            </DialogContent>
            <DialogActions className={classes.actions}>{children || defaultAction}</DialogActions>
        </Dialog>
    );
};

export const CustomButtonsDialog = withStyles(styles)(UnstyledCustomButtonsDialog);
