import {InfoRounded as InfoIcon} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {InputField} from '~/components/Common/InputField/InputField';
import {DateInput} from '../../Common/DateInput/DateInput';
import {checkMaxLength, preventNRICInsertion, required} from '../../Common/InputField/ValidationRules';

import {Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tooltip} from '@mui/material';
import {withStyles} from '@mui/styles';
import {AGENCY_TYPE, DEFAULT_LONG_FIELD_MAX_LENGTH, oneCVStartDay} from '~/constants';
import {validateChecksum} from '~/utils/validateChecksum';
import style from './EntityDetails.scss';
interface IEntityDetails {
    name?: string;
    nameLabel?: string;
    description?: string;
    descriptionLabel?: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    disableInput?: boolean;
    descriptionPlaceholder?: string;
    isAgency?: boolean;
    setIsAgency?: React.Dispatch<React.SetStateAction<boolean>>;
    dateAdded?: string;
    setDateAdded?: React.Dispatch<React.SetStateAction<string>>;
    dateLabel?: string;
    agencyType?: string;
    setAgencyType?: React.Dispatch<React.SetStateAction<AGENCY_TYPE>>;
    dataContributor?: boolean;
    setDataContributor?: React.Dispatch<React.SetStateAction<boolean>>;
    setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EntityDetails = ({
    name,
    nameLabel = 'Name',
    description,
    descriptionLabel = 'Description',
    setName,
    setDescription,
    disableInput,
    descriptionPlaceholder = 'eg. Officers who assess and administer national ComCare schemes',
    isAgency = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsAgency = undefined,
    dateAdded = undefined,
    setDateAdded = undefined,
    agencyType = undefined,
    setAgencyType = undefined,
    dataContributor = undefined,
    setDataContributor = undefined,
    setHasError,
}: IEntityDetails) => {
    const [dateError, setDateError] = useState(false);
    const nameMaxLength = 150;
    const StyledTooltip = withStyles({
        tooltip: {
            fontSize: '12px',
            fontWeight: 'normal',
            maxWidth: 'none',
        },
    })(Tooltip);
    useEffect(() => {
        if (setHasError) {
            const hasError =
                validateChecksum(name || '') ||
                validateChecksum(description || '') ||
                (name && name?.length > nameMaxLength) ||
                dateError;
            setHasError(hasError);
        }
    }, [name, dateError, description]);
    return (
        <div className={style.inputsWrapper}>
            <InputField
                className={style.formInput}
                htmlFor={`name-input`}
                value={name as string}
                onChange={(value) => setName(value)}
                label={nameLabel}
                validations={[required('name'), preventNRICInsertion]}
                isDisable={disableInput}
                customErrorMessage={checkMaxLength(name as string, nameLabel, nameMaxLength)}
            />
            <InputField
                className={style.formInput}
                htmlFor={`description-input`}
                value={description as string}
                onChange={(value) => setDescription(value)}
                label={descriptionLabel}
                multiline={true}
                rows={4}
                validations={[preventNRICInsertion]}
                placeholder={descriptionPlaceholder}
                isDisable={disableInput}
                maxLength={DEFAULT_LONG_FIELD_MAX_LENGTH}
                displayCharCounter
            />
            {setIsAgency && isAgency ? setIsAgency(isAgency) : undefined}
            {isAgency ? (
                <>
                    <div className={style.dateAddedWrapper}>
                        <DateInput
                            label="Date added"
                            value={dateAdded ?? ''}
                            onChange={(value) => {
                                if (setDateAdded) setDateAdded(value as string);
                            }}
                            dateFormat="dd/MM/yyyy"
                            disabled={disableInput}
                            hasError={(dateError) => setDateError(dateError)}
                            maxDate={new Date()}
                            minDate={new Date(oneCVStartDay)}
                            allowFutureDate={false}
                        />
                        <StyledTooltip title="Date Added as displayed in participating agencies page" placement="right">
                            <InfoIcon className={style.dateAddedToolTip} />
                        </StyledTooltip>
                    </div>
                    <FormControl className={style.formInput} disabled={disableInput}>
                        <InputLabel id="agency-type-select-label">Agency Type</InputLabel>
                        <Select
                            labelId="agency-type-select-label"
                            value={agencyType as string}
                            onChange={(e) => {
                                if (setAgencyType) setAgencyType(e.target.value as AGENCY_TYPE);
                            }}
                        >
                            <MenuItem value={'PSA'}>Public Sector Agency (PSA)</MenuItem>
                            <MenuItem value={'NGE'}>Non-Government Entity (NGE)</MenuItem>
                        </Select>
                    </FormControl>
                    <Divider />
                    <FormControlLabel
                        className={style.formInput}
                        control={
                            <Checkbox
                                checked={dataContributor}
                                onChange={(e) => {
                                    if (setDataContributor) setDataContributor(e.target.checked);
                                }}
                                name="checkedA"
                            />
                        }
                        label="Data Contributor"
                    />
                </>
            ) : undefined}
        </div>
    );
};
