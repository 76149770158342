import {Typography, Grid, Divider} from '@mui/material';
import React from 'react';
import './SubDomainDisplay.scss';
import {PendingMessage} from '~/components/Common/IconMessage/IconMessage';
import {camelCase} from 'lodash';

export interface ISubDomainNameProps {
    name?: string;
    code?: string;
    isSubSection?: boolean;
    includeHeader?: boolean;
    errorMessages?: string[];
}

export const PendingSubDomain = ({name, isSubSection = false, includeHeader = true}: ISubDomainNameProps) => {
    return (
        <>
            {includeHeader ? (
                <Grid container spacing={2}>
                    <Grid item id="header" xs={8} styleName={isSubSection ? 'subSectionHeader' : 'subDomainHeader'}>
                        <Typography
                            variant={isSubSection ? 'h6' : 'h4'}
                            styleName={isSubSection ? 'subSectionHeader' : 'subDomainHeader'}
                        >
                            {name} (Pending)
                        </Typography>
                    </Grid>
                    {!isSubSection && (
                        <Grid item xs={12}>
                            <Divider styleName="divider" />
                        </Grid>
                    )}

                    <Grid item xs={12} data-testid={`pendingMessage_${camelCase(name)}`}>
                        <PendingMessage />
                    </Grid>
                </Grid>
            ) : (
                <PendingMessage />
            )}
        </>
    );
};
