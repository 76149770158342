import {CircularProgress, Container, Divider, Grid, Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {emptyAnnouncement, IAnnouncement} from '~/interfaces/admin';
import {getAllAnnouncements} from '~/services/announcementServices';
import styles from './Announcement.scss';
import {ScheduledAnnouncementTable} from '~/components/Admin/ScheduledAnnouncementTable/ScheduledAnnouncementTable';
import {AnnouncementView} from '../../../components/Admin/AnnouncementView/AnnouncementView';

export const Announcement = () => {
    const [defaultAnnouncement, setDefaultAnnouncement] = useState<IAnnouncement>(emptyAnnouncement);
    const [scheduledAnnouncements, setScheduledAnnouncements] = useState<IAnnouncement[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fetchError, setFetchError] = useState<boolean>(false);

    const fetchAnnouncements = async () => {
        try {
            const announcements = await getAllAnnouncements();
            setDefaultAnnouncement(announcements.default);
            setScheduledAnnouncements(announcements.scheduled);
            setIsLoading(false);
        } catch (e) {
            setFetchError(true);
        }
    };

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    if (isLoading) {
        return (
            <Container className={styles.loadingContainer}>
                <CircularProgress size={24} />
            </Container>
        );
    }

    if (fetchError) {
        return <div>An error has occured while fetching announcements</div>;
    }

    return (
        <div className={styles.announcements}>
            <Typography variant="h4">Announcements</Typography>
            <AnnouncementView id={defaultAnnouncement.Id} isDefault={true} />

            <Divider />

            <Grid container direction="column" className={styles.scheduledAnnouncements}>
                <Grid item>
                    <Typography component="h2" variant="h6">
                        Scheduled Announcements
                    </Typography>
                </Grid>
                <Grid item className={styles.scheduledTable}>
                    <ScheduledAnnouncementTable announcements={scheduledAnnouncements} />
                </Grid>
            </Grid>
        </div>
    );
};
