import React, {useContext, useRef, useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {Button, Box, Divider, Typography, Grid} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {IReleaseNote} from '~/interfaces/common';
import {ReleaseNoteItem} from '~/components/Common/FooterResources/ReleaseNoteItem';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';
import {getReleaseNoteFetchState} from '~/store/fetchState/fetchState.selector';
import {ACCESS_LEVELS, LOGIN_STATE} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';

import {getReleaseNotes} from '~/store/releaseNotes/releaseNote.thunk';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {sortReleaseNoteByVersionNumber} from '~/utils/arrayUtils';
import {useReleaseFilterStyles} from './ReleaseNotes.styles';
import './ReleaseNotes.scss';

interface LocationState {
    message: string;
}

export const ReleaseNotes = () => {
    const {loginState, accessToken} = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const domainHeaderRef = useRef<HTMLDivElement>(null);
    const [message, setMessage] = useState('');

    const releaseFilterClasses = useReleaseFilterStyles();
    const fetchState = useTypedSelector(getReleaseNoteFetchState);
    const releaseNotes = useTypedSelector((state) => state.data.common.releaseNotes);
    const loggedInAsAdmin =
        loginState === LOGIN_STATE.LOGGED_IN &&
        accessToken?.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR;

    useEffect(() => {
        dispatch(getReleaseNotes());
    }, []);

    useEffect(() => {
        if (location.state) {
            openGrowl(location.state.message);
            setMessage('');
            history.replace({pathname: '/release-notes', state: {}});
        }
    }, [message]);

    const ReleaseNotesContent = () => {
        const [selectedReleases, setSelectedReleases] = useState<string[]>([]);
        const [filteredReleases, setFilteredReleases] = useState<IReleaseNote[]>([]);
        const [releaseOptions, setReleaseOptions] = useState<string[]>([]);

        const filterSelectedRelease = (selectedReleases: string[]): IReleaseNote[] => {
            if (releaseOptions.length !== 0 && selectedReleases.length !== 0) {
                return releaseNotes.filter((releaseNote: IReleaseNote) => {
                    return selectedReleases.includes(releaseNote.VersionNumber);
                });
            }
            return [];
        };

        useEffect(() => {
            const loadReleaseOptions = releaseNotes
                .slice()
                .sort(sortReleaseNoteByVersionNumber)
                .map((release) => release.VersionNumber);
            setReleaseOptions(loadReleaseOptions);
            setSelectedReleases(loadReleaseOptions);
            setFilteredReleases(releaseNotes);
        }, [releaseNotes]);
        const handleSelectRelease = (userSelectedReleases: string[]) => {
            setSelectedReleases(userSelectedReleases);
            setFilteredReleases(filterSelectedRelease(userSelectedReleases));
        };

        const handleSelectAllReleases = (isSelectAll: boolean) =>
            isSelectAll ? handleSelectRelease(releaseOptions) : handleClearRelease();

        const handleClearRelease = () => handleSelectRelease([]);

        return (
            <React.Fragment>
                <Grid>
                    <MultiSelectAutocomplete
                        options={releaseOptions}
                        selectedValues={selectedReleases}
                        label="Release"
                        sort={false}
                        tagRenderer={(value: string[]) => (
                            <Typography variant="subtitle1" styleName="filterTag">
                                {`${value.length} ${value.length > 1 ? 'Releases' : 'Release'} Selected`}
                            </Typography>
                        )}
                        onToggleOption={handleSelectRelease}
                        onClearOptions={handleClearRelease}
                        onSelectAll={handleSelectAllReleases}
                        classes={releaseFilterClasses}
                    />
                </Grid>
                {filteredReleases &&
                    filteredReleases.map((releaseNote, index) => (
                        <ReleaseNoteItem
                            key={releaseNote.VersionNumber}
                            releaseNote={releaseNote}
                            onExpandAll={index === 0}
                            isLoggedInAsAdmin={loggedInAsAdmin}
                            setGrowl={openGrowl}
                        />
                    ))}
                <AdminSnackbar
                    snackBarProps={{
                        anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                        key: growl?.key,
                        open: growl?.open,
                        onClose: closeGrowl,
                        autoHideDuration: growl.autoHideDuration,
                        message: growl.message.length > 0 ? growl.message : undefined,
                    }}
                    domainHeaderRef={domainHeaderRef}
                />
            </React.Fragment>
        );
    };
    const ReleaseNotesWithLoader = WithLoader<{}>(ReleaseNotesContent, fetchState);

    return (
        <>
            <Box styleName="addReleaseNoteButtonWrapper">
                <Typography variant="h4">Release Notes</Typography>
                {loggedInAsAdmin && (
                    <Button
                        color="primary"
                        styleName="addReleaseNoteButton"
                        variant="contained"
                        startIcon={<AddRoundedIcon />}
                        onClick={() => history.push('/release-notes/create')}
                    >
                        ADD RELEASE NOTE
                    </Button>
                )}
            </Box>
            <Divider />
            <ReleaseNotesWithLoader />
        </>
    );
};
