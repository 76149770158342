import {KeyboardArrowRight} from '@mui/icons-material';
import {Chip, Grid, Link, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import GridHeaderTwoColumn from '~/components/Common/GridHeaderTwoColumn/GridHeaderTwoColumn';
import {IDataAccessMatrixSubDomain, ISubdomainLatestStatus, IRequestWindow} from '~/interfaces/admin';
import './SubdomainDataAccessManagement.scss';
import SubdomainDataFieldsTable from './SubdomainDataFieldsTable';
import './SubdomainDataFieldsTable.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {dataAccessMatrix2Actions} from '~/store/dataAccessMatrix/dataAccessMatrix2.slice';
import {DataRestrictions} from '~/services/dataAccessMatrixService';
import {formatDateMonthName} from '~/utils/dateUtils';
import SubdomainInfo from './SubdomainInfo';

const SubdomainDataAccessManagement = (props: {
    opsUnitId: number;
    domainData: IDataAccessMatrixSubDomain;
    subDomainRequestStateData: OpsUnitSubdomainRequest;
    newFields: boolean;
    activeWindow: boolean;
    lastUpdated: ISubdomainLatestStatus | undefined;
    opsUnitDataFields: {[key: number]: number};
    requestedFields: number[];
    domainId: number;
    restrictions: DataRestrictions;
    latestClosedWindow: IRequestWindow;
}) => {
    const {
        opsUnitId,
        subDomainRequestStateData,
        activeWindow,
        lastUpdated,
        opsUnitDataFields,
        requestedFields,
        restrictions,
        latestClosedWindow,
        domainId,
    } = props;
    const {
        Name: name,
        Id: subId,
        DataFields: dataFields,
        Remarks: remarks,
        Guidelines: guidelines,
        Id: subdomainId,
    }: IDataAccessMatrixSubDomain = {...props.domainData};

    const lastUpdatedDate = !!lastUpdated?.LastUpdatedAt ? formatDateMonthName(lastUpdated?.LastUpdatedAt) : '-';
    const lastUpdatedBy = !!lastUpdated?.LastUpdatedBy ? lastUpdated.LastUpdatedBy : '-';
    const subdomainInfo = {
        subdomainId: subId,
        lastUpdatedInfo: `Last updated on ${lastUpdatedDate}, by ${lastUpdatedBy}`,
        remarks: remarks,
        updateReason: props.subDomainRequestStateData?.ReasonForAccess?.Pending,
        currentReason: props.subDomainRequestStateData?.ReasonForAccess?.Accepted,
        guidelines: guidelines,
    };

    const [additionalFieldsAdded, setAdditionalFieldsAdded] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    function handleAddFields() {
        setAdditionalFieldsAdded(true);

        dispatch(dataAccessMatrix2Actions.SET_FIELDS_ADDED({fieldsAdded: true}));
    }

    function handleFieldsUpdated() {
        dispatch(dataAccessMatrix2Actions.SET_FIELDS_UPDATED({fieldsUpdated: true}));
    }

    const LeftHeader = (subdomainName: string, newField: boolean) => {
        return newField ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography paddingRight={'1rem'} noWrap>
                    {subdomainName}
                </Typography>
                <Chip label="New fields" styleName="newFieldsChip" />
            </div>
        ) : (
            <>{subdomainName}</>
        );
    };

    const rightHeader = () => {
        const history = useHistory();
        return (
            <Link
                onClick={() => {
                    history.push(
                        `/admin/v2/data-access-management/ops-unit/${opsUnitId}/domain/${domainId}/subdomain/${subdomainId}/data-access-request-history`,
                    );
                }}
                styleName="viewHistory"
            >
                <span>VIEW HISTORY</span>
                <KeyboardArrowRight styleName="arrow" fontSize="small" />
            </Link>
        );
    };

    return (
        <>
            <Grid container item xs={12} alignItems="center">
                <Grid item xs={12}>
                    <GridHeaderTwoColumn leftHeader={LeftHeader(name, props.newFields)} rightHeader={rightHeader()} />
                </Grid>
                <Grid item xs={12}>
                    <SubdomainInfo
                        subdomainInfo={subdomainInfo}
                        activeWindow={activeWindow}
                        canEditReason={
                            additionalFieldsAdded ||
                            (subdomainInfo.currentReason || '') !== (subdomainInfo.updateReason || '')
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <SubdomainDataFieldsTable
                        opsUnitId={opsUnitId}
                        subdomainId={subId}
                        dataFields={dataFields}
                        subDomainRequestStateData={subDomainRequestStateData}
                        activeWindow={activeWindow}
                        handleAddFields={handleAddFields}
                        handleFieldsUpdated={handleFieldsUpdated}
                        opsUnitDataFields={opsUnitDataFields}
                        requestedFields={requestedFields}
                        restrictions={restrictions}
                        latestClosedWindow={latestClosedWindow}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default SubdomainDataAccessManagement;
