import React, {useState, useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Typography, Button, Divider, Grid, Box, CircularProgress} from '@mui/material';
import {allowSaveUsers, prepareUserCreation} from '../sharedFunctions';
import {UserDetails} from '~/components/Admin/UserDetails/UserDetails';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {UserView} from '~/components/Admin/UserView/UserView';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {useGrowl} from '~/utils/hooks/useGrowl';
import style from './OpsUnitEdit.scss';
import {IOpsUnit, ISysAdminOpsUnit, ITeam} from '~/interfaces/admin';
import {EntityView} from '~/components/Admin/EntityView/EntityView';
import {EntityDetails} from '~/components/Admin/EntityDetails/EntityDetails';
import {sameEmail, sameUserName} from '~/components/Common/InputField/ValidationRules';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {BackButton} from '../../../components/Common/Button/BackButton';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {
    createOpsUnit,
    updateOpsUnit,
    createOpsUnitAdmin,
    updateOpsUnitAdmin,
    deleteOpsUnitAdmin,
    activateOpsUnitAdmin,
} from '~/store/opsUnit/opsUnit.thunk';
import {DeleteWithReasonDialog} from '~/components/Admin/DeleteWithReasonDialog/DeleteWithReasonDialog';
import {DELETE_REASONS} from '~/constants';
import {deleteOpsUnit} from '~/services/opsUnitServices';
import {isInternetUser} from '~/utils/userType';

export const OpsUnitEdit = () => {
    const dispatch = useAppDispatch();
    const {opsUnitId, agencyId} = useParams<{agencyId?: string; opsUnitId?: string}>();
    const opsUnitDetails = useTypedSelector((state) => state.data.admin.opsUnits)[Number(opsUnitId)];
    const agencyDetails = useTypedSelector((state) => state.data.admin.agencies.agencies)[Number(agencyId)];
    const agencyName = agencyDetails ? agencyDetails.Name : undefined;
    const allOpsUnits = useTypedSelector((state) => state.data.admin.opsUnits);

    const usersDetails = opsUnitDetails?.Admins || [];
    const isToCreateOpsUnit = !opsUnitDetails;
    const [name, setName] = useState(opsUnitDetails?.Name || '');
    const [description, setDescription] = useState(opsUnitDetails?.Description || '');
    const [user1, setUser1] = useState({...usersDetails[0]});
    const [user2, setUser2] = useState({...usersDetails[1]});
    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();
    const [isOpsUnitHasError, setIsOpsUnitHasError] = useState(false);
    const [isAdmin1HasError, setIsAdmin1HasError] = useState(false);
    const [isAdmin2HasError, setIsAdmin2HasError] = useState(false);
    const [hasError, setHasError] = useState(false);
    const disableButton = !allowSaveUsers(user1, user2) || name.length == 0 || description.length == 0;
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(disableButton);
    const backUrl = `/admin/agencies/${agencyId}/ops-units`;

    const pathname = history.location.pathname;
    const scrollId = history.location.hash.split('#')[1];
    const scrollRef = useRef<HTMLDivElement>(null);

    const isOnlyOneAdmin = usersDetails && usersDetails.length == 1;
    const hasMoreThanOneOpsUnit = Object.keys(allOpsUnits).length > 1;
    const [isCreateOpsUnitDetails, setIsCreateOpsUnitDetails] = useState<boolean>(isToCreateOpsUnit);
    const [isEditOpsUnitDetails, setIsEditOpsUnitDetails] = useState<boolean>(false);
    const [isCreateUser1, setIsCreateUser1] = useState<boolean>(isToCreateOpsUnit);
    const [isCreateUser2, setIsCreateUser2] = useState<boolean>(isToCreateOpsUnit);
    const [isEditUser1, setIsEditUser1] = useState<boolean>(false);
    const [isEditUser2, setIsEditUser2] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteOpsUnitDialogOpen, setIsConfirmDeleteOpsUnitDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState({name: '', userId: 0});
    const isShowSubmitButton =
        isCreateUser1 || isCreateUser2 || isEditUser1 || isEditUser2 || isCreateOpsUnitDetails || isEditOpsUnitDetails;
    const isCreateOpsUnit = isCreateOpsUnitDetails && isCreateUser1 && isCreateUser2;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const closeAllEdits = () => {
        setIsCreateUser1(false);
        setIsCreateUser2(false);
        setIsEditUser1(false);
        setIsEditUser2(false);
        setIsCreateOpsUnitDetails(false);
        setIsEditOpsUnitDetails(false);
    };

    const removeUrlFragment = () => {
        if (scrollId) {
            history.push(pathname);
        }
    };

    const successfulSubmit = () => {
        openGrowl(`Changes successfully saved`);
        closeAllEdits();
        removeUrlFragment();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const filteredAdmins = [user1, user2].filter((user) => user.Name);
        const updatedOpsUnit = {
            Id: isCreateOpsUnit ? undefined : Number(opsUnitId),
            Name: name,
            Description: description,
            AgencyId: Number(agencyId),
            Admins: filteredAdmins,
        };
        setIsLoading(true);
        if (isCreateOpsUnit) {
            updatedOpsUnit.Admins = prepareUserCreation(updatedOpsUnit.Admins);
            const thunkActionResult = await dispatch(
                createOpsUnit({agencyId: Number(agencyId), opsUnit: updatedOpsUnit as IOpsUnit}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                history.push(`/admin/agencies/${agencyId}/ops-units/${thunkActionResult.payload.Id}`);
            }
            setIsLoading(false);
        } else if (isEditOpsUnitDetails) {
            const thunkActionResult = await dispatch(updateOpsUnit({updatedOpsUnit: updatedOpsUnit as IOpsUnit}));
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isCreateUser1) {
            updatedOpsUnit.Admins = prepareUserCreation(updatedOpsUnit.Admins);
            const thunkActionResult = await dispatch(
                createOpsUnitAdmin({opsUnitId: Number(opsUnitId), admin: updatedOpsUnit.Admins[0]}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isCreateUser2) {
            updatedOpsUnit.Admins = prepareUserCreation(updatedOpsUnit.Admins);
            const thunkActionResult = await dispatch(
                createOpsUnitAdmin({opsUnitId: Number(opsUnitId), admin: updatedOpsUnit.Admins[1]}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isEditUser1) {
            const thunkActionResult = await dispatch(
                updateOpsUnitAdmin({opsUnitId: Number(opsUnitId), adminDetails: updatedOpsUnit.Admins[0]}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else if (isEditUser2) {
            const thunkActionResult = await dispatch(
                updateOpsUnitAdmin({opsUnitId: Number(opsUnitId), adminDetails: updatedOpsUnit.Admins[1]}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                successfulSubmit();
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
            return;
        }
    };

    const handleActivateUser = async (userId?: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);

        if (user) {
            const thunkActionResult = await dispatch(
                activateOpsUnitAdmin({opsUnitId: Number(opsUnitId), admin: {...user, IsActive: true}}),
            );
            if ('error' in thunkActionResult) {
                openGrowl(thunkActionResult.payload as string);
            } else {
                openGrowl(`${user.Name} has been reactivated`);
            }
        }
    };

    const handleDeleteUser = (userId: number) => {
        const user = [user1, user2].find((user) => user.Id === userId);
        if (user) {
            setIsDeleteDialogOpen(true);
            setUserToBeDeleted({name: user.Name, userId: userId});
        }
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setIsConfirmDeleteOpsUnitDialogOpen(false);
    };
    const confirmDelete = async (deletedReason: string) => {
        setIsDeleting(true);
        const thunkActionResult = await dispatch(
            deleteOpsUnitAdmin({opsUnitId: Number(opsUnitId), userId: Number(userToBeDeleted.userId), deletedReason}),
        );
        if ('error' in thunkActionResult) {
            openGrowl(thunkActionResult.payload as string);
        } else {
            openGrowl(`${userToBeDeleted.name} has been deleted`);
        }
        setIsDeleting(false);
        setUserToBeDeleted({name: '', userId: 0});
        closeDeleteDialog();
        closeAllEdits();
        removeUrlFragment();
    };

    const handleEditOpsUnit = () => {
        history.push(`${pathname}#details`);
        setIsEditOpsUnitDetails(true);
    };

    const handleEditUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsEditUser1(true);
    };
    const handleEditUser2 = () => {
        history.push(`${pathname}#admin2`);
        setIsEditUser2(true);
    };

    const handleCreateUser1 = () => {
        history.push(`${pathname}#admin1`);
        setIsCreateUser1(true);
    };
    const handleCreateUser2 = () => {
        history.push(`${pathname}#admin2`);
        setIsCreateUser2(true);
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleOpsUnitDelete = () => {
        setIsConfirmDeleteOpsUnitDialogOpen(true);
    };

    const confirmOpsUnitDelete = async (reason: string) => {
        try {
            setIsDeleting(true);
            await deleteOpsUnit(Number(opsUnitId), reason);
            closeAllEdits();
            openGrowl(`${opsUnitDetails.Name} has been deleted successfully!`);
            history.push(backUrl);
        } catch (err) {
            const error = err as Error;
            openGrowl(error.message);
        }
        setIsDeleting(false);
    };

    const confirmCancel = () => {
        if (isCreateOpsUnit) {
            history.push(backUrl);
        }

        closeAllEdits();
        removeUrlFragment();

        const usersDetails = opsUnitDetails?.Admins || [];
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
        setName(opsUnitDetails?.Name || '');
        setDescription(opsUnitDetails?.Description || '');
        closeConfirmDialog();
    };

    useEffect(() => {
        setUser1({...usersDetails[0]});
        setUser2({...usersDetails[1]});
    }, [opsUnitDetails]);

    useEffect(() => {
        setHasError(isOpsUnitHasError || isAdmin1HasError || isAdmin2HasError);
    }, [isOpsUnitHasError, isAdmin1HasError, isAdmin2HasError]);

    useEffect(() => {
        setIsButtonDisabled(disableButton || hasError);
    }, [user1, user2, name, description, hasError]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [scrollId]);

    const getOpsUnitUserCounts = (opsUnit: ISysAdminOpsUnit) => {
        let userCount = 0;
        if (opsUnit.Teams && opsUnit.Teams.length > 0) {
            opsUnit.Teams.map((team: ITeam) => {
                if (team.Users) {
                    userCount += team.Users.length;
                }
            });
        }
        return userCount;
    };

    const SubmitButtons = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancelButton"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };
    const DeleteOpsUnitButton = () => {
        return (
            <div className={style.buttonWrappers}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    id="delete-ops-unit-button"
                    data-testid="deleteOpsUnitButton"
                    onClick={() => {
                        handleOpsUnitDelete();
                    }}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Delete'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Box component="div" className={style.backButtonWrapper}>
                <BackButton
                    onClick={() => {
                        history.push(backUrl);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} className={style.opsUnitEditForm}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Ops Unit Details
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        {!isShowSubmitButton && hasMoreThanOneOpsUnit ? (
                            <Grid>{<DeleteOpsUnitButton />}</Grid>
                        ) : isShowSubmitButton ? (
                            <SubmitButtons />
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                            {agencyName}
                        </Typography>
                    </Grid>
                </Grid>
                <div ref={scrollId === 'details' ? scrollRef : null} />
                {isCreateOpsUnitDetails || isEditOpsUnitDetails ? (
                    <EntityDetails
                        name={name}
                        nameLabel="Ops Unit Name"
                        description={description}
                        descriptionLabel="Brief Description of Ops Unit"
                        setName={setName}
                        setDescription={setDescription}
                        disableInput={isLoading}
                        setHasError={setIsOpsUnitHasError}
                    />
                ) : (
                    <EntityView
                        name={name}
                        nameLabel="Ops Unit Name"
                        description={description}
                        descriptionLabel="Brief Description of Ops Unit"
                        isDisplayControls={!isShowSubmitButton}
                        handleEditEntity={handleEditOpsUnit}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin1' ? scrollRef : null} />
                {isCreateUser1 || isEditUser1 ? (
                    <UserDetails
                        heading="Ops Unit Admin 1"
                        details={user1}
                        setUser={setUser1}
                        fieldsToDisable={
                            isCreateUser1 ? [] : isInternetUser(user1) ? ['userName'] : ['email', 'userName']
                        }
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setIsAdmin1HasError}
                    />
                ) : (
                    <UserView
                        heading="Ops Unit Admin 1"
                        details={user1}
                        userRole="Ops Unit Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser1}
                        isDisplayControls={
                            !(isEditUser2 || isCreateUser2 || isCreateOpsUnitDetails || isEditOpsUnitDetails)
                        }
                        hideDeleteButton={isOnlyOneAdmin}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser1}
                    />
                )}
                <Divider />
                <div ref={scrollId === 'admin2' ? scrollRef : null} />
                {isCreateUser2 || isEditUser2 ? (
                    <UserDetails
                        heading="Ops Unit Admin 2"
                        details={user2}
                        setUser={setUser2}
                        fieldsToDisable={
                            isCreateUser2 ? [] : isInternetUser(user2) ? ['userName'] : ['email', 'userName']
                        }
                        customError={{
                            email: sameEmail(user1.Email, user2.Email),
                            userName: sameUserName(user1.UserName, user2.UserName),
                        }}
                        handleActivateUser={handleActivateUser}
                        disableInput={isLoading}
                        setHasError={setIsAdmin2HasError}
                    />
                ) : (
                    <UserView
                        heading="Ops Unit Admin 2"
                        details={user2}
                        userRole="Ops Unit Admin"
                        handleActivateUser={handleActivateUser}
                        handleEditUser={handleEditUser2}
                        isDisplayControls={
                            !(isEditUser1 || isCreateUser1 || isCreateOpsUnitDetails || isEditOpsUnitDetails)
                        }
                        hideDeleteButton={isOnlyOneAdmin}
                        handleDeleteUser={handleDeleteUser}
                        handleCreateUser={handleCreateUser2}
                    />
                )}
                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Changes you have made so far will not be saved."
                cancelOption="Cancel"
                confirmOption="Discard"
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteOpsUnitDialog"
                users={(opsUnitDetails && getOpsUnitUserCounts(opsUnitDetails as ISysAdminOpsUnit)) || 0}
                title="Delete Ops Unit?"
                requireAcknowledgement={true}
                acknowledgementMessage={DELETE_REASONS['OpsUnit']}
                warningMessage="Existing Users will not be able to use OneCV anymore."
                buttonMessage="OPS UNIT"
                open={isConfirmDeleteOpsUnitDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmOpsUnitDelete}
                isLoading={isDeleting}
            />
            <DeleteWithReasonDialog
                id="ConfirmDeleteOpsUnitAdmin"
                title={`Delete Ops Unit Admin?`}
                buttonMessage="OPS UNIT ADMIN"
                open={isDeleteDialogOpen}
                onCancel={closeDeleteDialog}
                onConfirm={confirmDelete}
                isLoading={isDeleting}
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
