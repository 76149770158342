import React, {useState} from 'react';
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Radio,
    Snackbar,
    Typography,
    TextField,
} from '@mui/material';
import {CloseRounded as CloseIcon} from '@mui/icons-material';
import {OPS_UNIT_TYPE} from '~/constants';
import {IOpsUnit} from '~/interfaces/admin';
import {updateOpsUnitDetails} from '~/services/dataAccessMatrixService';
import './OpsUnitTypeEditModal.scss';
import {useGrowl} from '~/utils/hooks/useGrowl';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {opsUnitActions} from '~/store/opsUnit/opsUnit.slice';

export interface IOpsUnitTypeEditModalDialog {
    open: boolean;
    onClose: () => void;
    currentOpsUnitType: OPS_UNIT_TYPE;
    currentOpsUnitReasonForAccess: string;
    opsUnit: IOpsUnit;
}

export const OpsUnitTypeEditModal = ({
    open,
    onClose,
    currentOpsUnitType,
    currentOpsUnitReasonForAccess,
    opsUnit,
}: IOpsUnitTypeEditModalDialog) => {
    const dispatch = useAppDispatch();
    const [opsUnitType, setOpsUnitType] = useState<OPS_UNIT_TYPE>(currentOpsUnitType);
    const [optsUnitReasonForAccess, setOptsUnitReasonForAccess] = useState(opsUnit.ReasonForAccess);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleClose = () => {
        setOpsUnitType(currentOpsUnitType);
        setOptsUnitReasonForAccess(currentOpsUnitReasonForAccess);
        onClose();
    };

    const handleSave = async () => {
        try {
            await updateOpsUnitDetails(String(opsUnit.Id), opsUnitType, optsUnitReasonForAccess || '');
            openGrowl(`Ops Unit Details saved successfully.`);
            dispatch(
                opsUnitActions.UPDATE_DAM_OPS_UNIT({
                    ...opsUnit,
                    OpsUnitType: opsUnitType,
                    ReasonForAccess: optsUnitReasonForAccess,
                }),
            );
            onClose();
        } catch (e) {
            const error = e as Error;
            openGrowl(error.message);
        }
    };

    const MAX_CHRACTERS_COUNT = 2000;
    const remainingCharactersCount = MAX_CHRACTERS_COUNT - (optsUnitReasonForAccess || '').length;
    const remainingCharactersForReason = remainingCharactersCount < 0 ? 0 : remainingCharactersCount;

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let reason = event.target.value;
        if (event.target.value.toString().length > MAX_CHRACTERS_COUNT) {
            reason = reason.substring(0, MAX_CHRACTERS_COUNT);
        }
        setOptsUnitReasonForAccess(reason);
    };

    return (
        <>
            {' '}
            <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-description">
                <Grid container>
                    <Grid item xs={11}>
                        <DialogTitle>Ops Unit Details</DialogTitle>
                    </Grid>
                    <Grid item xs={1}>
                        <DialogActions>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogContent>
                            <Typography variant="h6" mb={2} style={{color: '#3C3C3C'}}>
                                Select Ops Units Type
                            </Typography>
                            <DialogContentText>
                                Please select the user type this Ops Unit falls under. NGE users should not have access
                                to family members information.
                            </DialogContentText>
                            <div styleName="radioGroup">
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.PSA)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.PSA} color="primary" />
                                    PSA
                                </label>
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.NGE_BIP)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.NGE_BIP} color="primary" />
                                    NGE (BIP)
                                </label>
                                <label onClick={() => setOpsUnitType(OPS_UNIT_TYPE.NGE_NON_BIP)}>
                                    <Radio checked={opsUnitType === OPS_UNIT_TYPE.NGE_NON_BIP} color="primary" />
                                    NGE (Non-BIP)
                                </label>
                            </div>
                            <Typography variant="h6" mb={2} mt={2} style={{color: '#3C3C3C'}}>
                                Reason(s) for accessing One Client View
                            </Typography>
                            <div>
                                <TextField
                                    fullWidth
                                    id="outlined-disabled"
                                    label="Reason"
                                    value={optsUnitReasonForAccess}
                                    onChange={handleReasonChange}
                                    multiline
                                    rows={4}
                                />
                            </div>
                            <div style={{float: 'right'}}>
                                <Typography
                                    variant="body1"
                                    mt={2}
                                    style={{color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px'}}
                                >
                                    {`${remainingCharactersForReason} characters remaining`}
                                </Typography>
                            </div>
                        </DialogContent>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                        <DialogContent style={{float: 'right'}}>
                            <Button onClick={handleClose} color="primary" variant="text">
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={
                                    opsUnit.OpsUnitType === opsUnitType &&
                                    opsUnit.ReasonForAccess === optsUnitReasonForAccess
                                }
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                    bottom: 90,
                }}
            />
        </>
    );
};
