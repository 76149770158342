import React, {useContext, useState} from 'react';
import {Redirect, useHistory, Link} from 'react-router-dom';
import {Box, Button, Card, CardContent, CircularProgress, Typography} from '@mui/material';
import {updateLastAgreedDate} from '~/services/userServices';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {USER_TYPE} from '~/constants';
import './TermsAndConditions.scss';

export const TermsAndConditions = () => {
    const {accessToken, setAccessToken} = useContext(AuthenticationContext);
    const [isLoading, setIsLoading] = useState(false);
    const isInternetUser = accessToken?.UserType === USER_TYPE.internet;
    const history = useHistory();
    const redirectURL = '/search';

    const agreeToTerms = async (): Promise<void> => {
        if (accessToken != null) {
            setIsLoading(true);
            updateLastAgreedDate()
                .then(setAccessToken)
                .then(() => {
                    history.push(redirectURL);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const TermsAndConditionsContent = () => (
        <div id="TermsAndConditions">
            <Card variant="outlined">
                <CardContent>
                    <Typography styleName="title" variant="h5">
                        Undertaking to Safeguard Confidentiality of Personal Data
                    </Typography>
                    <div styleName="container">
                        <Typography variant="subtitle1">My attention has been drawn to:</Typography>
                        <ol styleName="lowerAlpha">
                            <li>the Public Sector (Governance) Act 2018;</li>
                            <li>the Personal Data Protection Act 2012;</li>
                            <li>the Computer Misuse Act 1993;</li>
                            <li>the Official Secrets Act 1935;</li>
                            <li>the Income Tax Act 1947;</li>
                            <li>the Children and Young Persons Act 1993;</li>
                            <li>the Vulnerable Adults Act 2018; and </li>
                            <li>the Women&apos;s Charter 1961.</li>
                        </ol>
                        <Typography variant="subtitle1">
                            I will access client’s information only on a need-to-know-basis for the following purposes:
                        </Typography>
                        <ol styleName="lowerRoman">
                            <li>
                                verifying an individual and his family members&apos; identity and family relationships,
                                for the Services or Scheme;
                            </li>
                            <li>
                                determining if an individual and his family members qualify or meet the eligibility
                                criteria for the Services or Scheme;
                            </li>
                            <li>
                                providing the Services or Scheme by a Public Agency or Participating Organisation;
                                and/or
                            </li>
                            <li>
                                scenarios covered under the Public Sector (Governance) Act 2018; the Personal Data
                                Protection Act 2012 and Government Instructional Manual (IM) (where relevant).
                            </li>
                        </ol>
                        <Typography variant="subtitle1">
                            I understand that any breach or misuse of personal data may render me liable to prosecution
                            under the relevant legislations, including and not limited to the ones listed above.
                        </Typography>

                        <Link to="/undertaking-to-safeguard-confidentiality-of-personal-data">
                            Read more about the undertaking here
                        </Link>

                        {isInternetUser && (
                            <Box mt={3} data-testid="security-clause">
                                <Typography variant="subtitle1">
                                    In accessing the system via the internet, the agency-issued device that I am using
                                    is:
                                </Typography>
                                <ol styleName="lowerRoman">
                                    <li>Enabled with personal firewall to secure all network connections;</li>
                                    <li>Installed with encryption software to encrypt sensitive data;</li>
                                    <li>
                                        Installed with anti-virus software and that virus signatures are updated
                                        regularly;
                                    </li>
                                    <li>Enabled with up-to-date security patches; and</li>
                                    <li>Enabled with hard disk encryption, where applicable. </li>
                                </ol>
                            </Box>
                        )}
                    </div>
                </CardContent>
            </Card>
            <div styleName="buttonContainer">
                <Button id="agreeButton" color="primary" variant="contained" onClick={agreeToTerms}>
                    {isLoading ? <CircularProgress size={24} /> : 'I Agree'}
                </Button>
            </div>
        </div>
    );

    return accessToken?.AgreedToTermsAndConditions ? <Redirect to={redirectURL} /> : <TermsAndConditionsContent />;
};
