import {Typography} from '@mui/material';
import React from 'react';
import './IconMessage.scss';
import {WarningIcon, ErrorIcon, InfoIcon, TickIcon} from '../Icons';
import {Variant} from '@mui/material/styles/createTypography';
import {Link} from 'react-router-dom';

export enum ICON_TYPE {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
    SUCCESSFUL = 'SUCCESSFUL',
    GREY_INFO = 'GREY_INFO',
    PURPLE_INFO = 'PURPLE_INFO',
    BLUE_INFO = 'BLUE_INFO',
}

const PendingMessageText = () => (
    <Typography id="body" variant="body2" data-testid="pending-message">
        Some of the information is still pending. Please check&nbsp;
        <Link id="pendingMyClientsLink" to="/my-clients" style={{color: '#3949ab'}}>
            My Client
        </Link>
        &nbsp;for status updates later.
    </Typography>
);

export interface IIconMessageProps {
    message?: string | JSX.Element;
    style?: React.CSSProperties;
    variant?: Variant;
    isPending?: boolean;
    iconStyle?: React.CSSProperties;
    fontStyle?: React.CSSProperties;
    'data-testid'?: string;
}

const GetIcon = (iconType: ICON_TYPE) => {
    switch (iconType) {
        case ICON_TYPE.ERROR:
            return <ErrorIcon />;
        case ICON_TYPE.WARNING:
            return <WarningIcon />;
        case ICON_TYPE.INFO:
            return <InfoIcon />;
        case ICON_TYPE.GREY_INFO:
            return <InfoIcon color="#606060" />;
        case ICON_TYPE.PURPLE_INFO:
            return <InfoIcon color="#5C6AC0" />;
        case ICON_TYPE.BLUE_INFO:
            return <InfoIcon color="#3F91EF" />;
        case ICON_TYPE.SUCCESSFUL:
            return <TickIcon />;
    }
};

export const IconMessage = ({
    message,
    style,
    variant = 'body2',
    type,
    isPending,
    iconStyle,
    fontStyle,
    'data-testid': dataTestId,
}: IIconMessageProps & {type: ICON_TYPE}) => (
    <div styleName="note-body" style={style} data-testid={dataTestId}>
        <div styleName="icon-container" style={iconStyle}>
            {GetIcon(type)}
        </div>
        <div data-testid="body" styleName="message">
            {!isPending ? (
                <Typography variant={variant} style={fontStyle}>
                    {message}
                </Typography>
            ) : (
                <PendingMessageText />
            )}
        </div>
    </div>
);

// Following components are based on: https://www.figma.com/file/fW1age8CIgEbxroOQIebdg/Frontline-Users?node-id=2654%3A19126
const errorMessageStyle = {
    backgroundColor: '#FFEAEF',
} as React.CSSProperties;

const pendingMessageStyle = {
    backgroundColor: '#E0EDFD',
} as React.CSSProperties;

const infoMessageStyle = {
    backgroundColor: '#F0F0F0',
} as React.CSSProperties;

const successMessageStyle = {
    backgroundColor: '#E2F5EE',
} as React.CSSProperties;

const fixedMessageStyle = {
    backgroundColor: '#E8EAF6',
} as React.CSSProperties;

export const ErrorMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} style={errorMessageStyle} type={ICON_TYPE.ERROR} />
);
export const WarningMessage = (props: IIconMessageProps) => <IconMessage {...props} type={ICON_TYPE.WARNING} />;
export const InfoMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} style={infoMessageStyle} type={ICON_TYPE.INFO} />
);

export const FixedMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} style={fixedMessageStyle} type={ICON_TYPE.PURPLE_INFO} />
);

export const SuccessMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} style={successMessageStyle} type={ICON_TYPE.SUCCESSFUL} />
);

export const UnsuccessfulMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} style={infoMessageStyle} type={ICON_TYPE.GREY_INFO} />
);

// Subdomain status  messages
export const PendingMessage = () => <IconMessage style={pendingMessageStyle} type={ICON_TYPE.INFO} isPending />;
export const SuccessfulNoResultMessage = (props: IIconMessageProps) => (
    <IconMessage {...props} type={ICON_TYPE.SUCCESSFUL} />
);
