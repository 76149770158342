import React, {useRef, useState, useEffect, useContext} from 'react';
import {Divider, Typography, Grid, Button, TextField, InputAdornment} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import {uniq, cloneDeep, debounce} from 'lodash';
import {ACCESS_LEVELS, API_FETCH_STATE} from '~/constants';
import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getDomains} from '~/store/dataAccessMatrix/dataAccessMatrix.selector';
import {getDomainsFetchState, getDamFieldsFetchState} from '~/store/fetchState/fetchState.selector';
import {setDataAccessMatrix} from '~/store/dataAccessMatrix/dataAccessMatrix.thunk';
import {setDataSource} from '~/store/dataSource/dataSource.thunk';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {getConsolidateFetchState} from '~/utils/apiFetchStateUtils';
import {IDataAccessMatrix} from '~/interfaces/admin';
import {WithLoader} from '~/components/Common/WithLoader/WithLoader';
import {DownloadIcon} from '~/components/Common/Icons';
import {DomainDataDictionary} from '~/components/Common/FooterResources/DomainDataDictionary';
import {MultiSelectAutocomplete} from '~/components/Client/MultiSelectAutocomplete/MultiSelectAutocomplete';

import './DataDictionary.scss';

export const useDomainFilterStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
        float: 'right',
    },
    selectAllCheckBox: {},
});

export const DataDictionary = () => {
    const dispatch = useAppDispatch();
    const domainFilterClasses = useDomainFilterStyles();
    const printComponentRef = useRef(null);
    const domains = useTypedSelector(getDomains, (left, right) => left.length > 0 && right.length > 0);
    const [editable, setEditable] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredDomains, setFilteredDomains] = useState<IDataAccessMatrix>([]);
    const [domainOptions, setDomainOptions] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const [renderPrintContent, setRenderPrintContent] = useState<boolean>(false);
    const fetchStatus = useTypedSelector(getConsolidateFetchState([getDamFieldsFetchState]));

    const auth = useContext(AuthenticationContext);

    useEffect(() => {
        dispatch(setDataAccessMatrix());
    }, []);

    useEffect(() => {
        if (domains.length > 0 && fetchStatus === API_FETCH_STATE.SUCCESS) {
            if (domainOptions.length === 0) {
                setDomainOptions(uniq(domains.map((row) => row.Name)));
            }
            applySearchFilter(searchValue);

            const accessToken = auth.accessToken;

            if (accessToken && accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) {
                setEditable(true);
            }
        }
    }, [domains, fetchStatus]);

    useEffect(() => {
        if (domains.length > 0) {
            applySearchFilter(searchValue);
        }
    }, [searchValue]);

    useEffect(() => {
        if (editable) dispatch(setDataSource());
    }, [editable]);

    const filterSelectedDomains = (selectedDomains: string[]): IDataAccessMatrix => {
        if (selectedDomains.length === 0) return domains;
        return domains.filter((domain) => {
            return selectedDomains.includes(domain.Name);
        });
    };

    const handleSelectDomain = (selectedDomains: string[]) => {
        setSelectedDomains(selectedDomains);
        setFilteredDomains(filterSelectedDomains(selectedDomains));
    };

    const handleClearDomains = () => {
        handleSelectDomain([]);
    };

    const handleSelectAllDomains = (isSelectAll: boolean) => {
        if (isSelectAll) {
            handleSelectDomain(domainOptions);
        } else {
            handleClearDomains();
        }
    };

    const applySearchFilter = (searchVal: string) => {
        const lowerCaseSearchValue = searchVal.toLowerCase();
        const filteredByDomains = cloneDeep(filterSelectedDomains(selectedDomains));

        const filteredDomains = filteredByDomains.filter((domain) => {
            domain.SubDomains = domain.SubDomains.filter((subDomain) => {
                subDomain.DataFields = subDomain.DataFields?.filter((field) => {
                    return (
                        field.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1 ||
                        (field.DataSource && field.DataSource.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1) ||
                        subDomain.Name.toLowerCase().indexOf(lowerCaseSearchValue) > -1
                    );
                });
                return subDomain.DataFields && subDomain.DataFields.length > 0;
            });
            return domain.SubDomains.length > 0;
        });
        setFilteredDomains(filteredDomains);
    };

    const handleNewSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const debounced = debounce(handleNewSearchValue, 500);
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        debounced(e);
    };

    const DataDictionaryContent = () => {
        return (
            <>
                {filteredDomains.map((domain) => {
                    const domainIndex = domains.findIndex((originalDomain) => originalDomain.Id === domain.Id);
                    return (
                        <DomainDataDictionary
                            domain={domain}
                            key={domain.Id}
                            onExpandAll={true}
                            domainIndex={domainIndex}
                        />
                    );
                })}
            </>
        );
    };
    const DataDictionaryContentWithLoader = WithLoader<{}>(DataDictionaryContent, fetchStatus);

    const DataDictionaryPrintContent = () => {
        return (
            <React.Fragment>
                {filteredDomains.map((domain) => {
                    const domainIndex = domains.findIndex((originalDomain) => originalDomain.Id === domain.Id);
                    return (
                        <DomainDataDictionary
                            domain={domain}
                            key={`print${domain.Id}`}
                            onExpandAll={true}
                            mountAllFields={true}
                            domainIndex={domainIndex}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    const onBeforeGetContentHandler = async () => {
        if (!renderPrintContent) await setRenderPrintContent(true);
    };

    return (
        <>
            <Grid styleName="dataDictionary">
                <style type="text/css" media="print">
                    {
                        '\
                    body {visibility: visible;}\
                    #dataDictionaryDomain {page-break-after: always;}\
                    #dataDictionaryExpandAll {display: none;}\
                    #domainName {padding: 16px 0 0 16px;}\
                    '
                    }
                </style>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="h4">Data Dictionary</Typography>
                    </Grid>
                    <Grid item styleName="generateReportButton" xs={8}>
                        <ReactToPrint
                            content={() => printComponentRef.current}
                            onBeforeGetContent={onBeforeGetContentHandler}
                            trigger={() => (
                                <Button id="downloadPdfButton" color="primary" startIcon={<DownloadIcon />}>
                                    DOWNLOAD PDF
                                </Button>
                            )}
                            documentTitle="Data Dictionary"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            label="Search Agency, Field Name or Sub-Domain"
                            type="search"
                            variant="outlined"
                            onChange={handleSearchChange}
                            styleName={`${searchValue ? 'searchInput filled' : 'searchInput'}`}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiSelectAutocomplete
                            options={domainOptions}
                            selectedValues={selectedDomains}
                            label="Domain"
                            tagRenderer={(value) => (
                                <Typography variant="subtitle1" styleName="filterTag">
                                    {value.length} Domains Selected
                                </Typography>
                            )}
                            onToggleOption={handleSelectDomain}
                            onClearOptions={handleClearDomains}
                            onSelectAll={handleSelectAllDomains}
                            classes={domainFilterClasses}
                        />
                    </Grid>
                </Grid>

                <Grid id="dataDictionary">
                    <DataDictionaryContentWithLoader />
                </Grid>

                <Grid ref={printComponentRef} styleName="printContent">
                    {renderPrintContent && <DataDictionaryPrintContent />}
                </Grid>
            </Grid>
        </>
    );
};
