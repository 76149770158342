import React from 'react';
import {Container, Typography, Button} from '@mui/material';
import favouritesEmptyState from '~/assets/images/favouritesEmptyState.svg';
import './EmptyFavourite.scss';

interface IProps {
    addFavouriteButtonHandler: (value: number) => void;
}

export const EmptyFavourite = ({addFavouriteButtonHandler}: IProps) => {
    return (
        <Container styleName="container">
            <img src={favouritesEmptyState} alt="Empty Favourite Image" styleName="image" />
            <Typography variant="h4" styleName="label">
                Favourite a sub-domain for easy search
            </Typography>
            <Button onClick={() => addFavouriteButtonHandler(0)} variant="outlined" color="primary" styleName="button">
                ADD FAVOURITES
            </Button>
        </Container>
    );
};
