import React, {useState} from 'react';
import {Table, TableCell, TableRow, Typography, Checkbox, Collapse, TableBody} from '@mui/material';
import {
    KeyboardArrowUpRounded as KeyboardArrowUpIcon,
    KeyboardArrowDownRounded as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {formatCurrency} from '~/utils/currencyUtils';
import './ExpandableRow.scss';
import {formatValue} from '~/utils/contentUtils';
interface IExpandedRow {
    name: string;
    amount: string;
}
interface IExpandableRow {
    expandable: boolean;
    description: string;
    amount: string;
    expandedRow?: IExpandedRow[];
    meta?: string;
    secondaryMeta?: string;
    descriptionMeta?: string;
    secondaryDescriptionMeta?: string;
}

export const ExpandableRow: React.FC<IExpandableRow> = ({
    expandable,
    description,
    amount,
    expandedRow,
    meta,
    secondaryMeta,
    descriptionMeta,
    secondaryDescriptionMeta,
}) => {
    const [expandTable, setexpandTable] = useState<boolean>(false);
    return (
        <>
            <TableRow
                onClick={expandable ? () => setexpandTable(!expandTable) : undefined}
                data-testid="expandable_row"
            >
                <TableCell style={{width: '25px'}}>
                    {expandable && (
                        <Checkbox
                            checked={expandTable}
                            checkedIcon={<KeyboardArrowUpIcon style={{color: '#8d8d8d'}} />}
                            icon={<KeyboardArrowDownIcon style={{color: '#ccc'}} />}
                            color="primary"
                            style={{padding: 0}}
                        />
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="body1">{description}</Typography>
                    {descriptionMeta && (
                        <Typography variant="body2" styleName="grey">
                            {descriptionMeta}
                        </Typography>
                    )}
                    {secondaryDescriptionMeta && (
                        <Typography variant="body2" styleName="grey">
                            {secondaryDescriptionMeta}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="right">
                    <Typography variant="body1">{amount}</Typography>
                    {meta && (
                        <Typography variant="body2" styleName="grey">
                            {meta}
                        </Typography>
                    )}
                    {secondaryMeta && (
                        <Typography variant="body2" styleName="grey">
                            {secondaryMeta}
                        </Typography>
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={3} padding="none" style={{borderBottom: 'none'}}>
                    <Collapse in={expandTable}>
                        <Table aria-label="simple table" style={{backgroundColor: 'rgb(244, 245, 250)'}}>
                            <TableBody>
                                {expandedRow &&
                                    expandedRow.map((row, index) => (
                                        <TableRow key={index} data-testid="expandable_row">
                                            <TableCell style={{width: '25px'}} />
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">{row.name}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    {formatValue(row.amount, parseFloat, formatCurrency)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
