import {connectRouter, routerMiddleware, RouterState} from 'connected-react-router';
import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import storage from 'redux-persist/lib/storage';
import {AnyAction, CombinedState, configureStore, Reducer} from '@reduxjs/toolkit';

//Reducers
import {DataReducer, IDataState, ISurveyState} from './dataReducers';
import {persistReducer} from 'redux-persist';
import {Flag} from './hydrateFlag/hydrateFlag.slice';
import {
    IDataDictionaryField,
    IDomain,
    IGroupedResource,
    IParticipatingAgencies,
    IReleaseNote,
    IUserFlags,
    IUserNotification,
} from '~/interfaces/common';
import {IUser} from '~/interfaces/admin';

export const history = createBrowserHistory({basename: '/'});
export interface IAppState {
    readonly router: RouterState;
    data: IDataState;
}

const appReducer = combineReducers({
    router: connectRouter(history),
    data: DataReducer,
});
type stateType =
    | CombinedState<{
          data: CombinedState<{
              settings: never;
              common: CombinedState<{
                  dataDictionaryFields: IDataDictionaryField[];
                  domains: IDomain[];
                  participatingAgencies: IParticipatingAgencies;
                  resources: IGroupedResource[];
                  surveys: ISurveyState;
                  userNotifications: IUserNotification[];
                  userFlags: IUserFlags;
                  releaseNotes: IReleaseNote[];
              }>;
              admin: CombinedState<{
                  dataSources: never;
                  dataContributors: never;
                  systemAdmins: never;
                  agencies: never;
                  opsUnits: never;
                  teams: never;
                  users: {[userId: number]: IUser};
              }>;
              flags: {
                  [Flag.DATA_DICTIONARY_FIELD]: boolean;
                  [Flag.DATA_SOURCE]: boolean;
                  [Flag.DATA_CONTRIBUTORS]: boolean;
                  [Flag.PARTICIPATING_AGENCY]: boolean;
                  [Flag.RESOURCES]: boolean;
                  [Flag.SYSTEM_ADMINS]: boolean;
                  [Flag.AGENCY]: boolean;
                  [Flag.DOMAIN]: boolean;
                  [Flag.OPS_UNIT]: boolean;
                  [Flag.TEAM]: boolean;
                  [Flag.USER]: boolean;
                  [Flag.DAM_FIELDS]: boolean;
                  [Flag.DAM_OPS_UNIT]: boolean;
                  [Flag.RELEASE_NOTE]: boolean;
              };
              client: never;
              clientUser: never;
              dashboard: never;
              dam: never;
              dam2: never;
              fetch: CombinedState<{
                  dataDictionaryFields: never;
                  dataSources: never;
                  dataContributors: never;
                  participatingAgencies: never;
                  agencies: never;
                  systemAdmins: never;
                  agency: never;
                  domains: never;
                  dashboard: never;
                  opsUnit: never;
                  opsUnits: never;
                  resources: never;
                  screenedClientInfo: never;
                  team: never;
                  teams: never;
                  users: never;
                  clientUser: never;
                  damOpsUnit: never;
                  damFields: never;
                  releaseNotes: never;
              }>;
          }>;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          router: RouterState<any>;
      }>
    | undefined;
const persistConfig = {
    key: 'root',
    storage,
    // version: 1,
    // blacklist: ['router'],
};

const rootReducer: Reducer<IAppState | undefined, AnyAction> = (state, action) => {
    if (action.type === 'logout/logout') {
        state = {
            data: {
                // settings: state?.data.settings,
            },
        } as IAppState;
    }
    return appReducer(state as stateType, action);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer as any);
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(routerMiddleware(history)),
});

export const configureMockStore = (initialState?: object) =>
    configureStore({
        reducer: appReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({serializableCheck: false, immutableCheck: false}).concat(routerMiddleware(history)),
        preloadedState: initialState,
    });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof appReducer>;
export type RootActions = Parameters<typeof DataReducer>[1];
