import React, {useContext, useState, useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Typography, Button, Divider, Grid, Box, CircularProgress} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import {
    WhatsNewIcon,
    WhatsImprovedIcon,
    WhatsFixedIcon,
    WhatsRemovedIcon,
    WhatsChangedIcon,
} from '~/components/Common/Icons';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import {ConfirmDialog} from '~/components/Common/ConfirmDialog/ConfirmDialog';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {SubmitFooter} from '~/components/Admin/SubmitFooter/SubmitFooter';
import {IReleaseNote, IReleaseNoteSection} from '~/interfaces/common';
import {BackButton} from '../../components/Common/Button/BackButton';
import {ReleaseNoteDetails} from '~/components/Admin/ReleaseNoteDetails/ReleaseNoteDetails';
import {format} from 'date-fns';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {createReleaseNote, updateReleaseNoteDetails} from '~/store/releaseNotes/releaseNote.thunk';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {ReleaseNoteDetailEdit} from '~/components/Common/FooterResources/ReleaseNoteDetailEdit';
import {getReleaseNoteById} from '~/store/releaseNotes/releaseNote.selector';
import './ReleaseNoteEdit.scss';

export const ReleaseNoteEdit = () => {
    const dispatch = useAppDispatch();
    const {accessToken} = useContext(AuthenticationContext);

    const {releaseNoteId} = useParams<{releaseNoteId?: string}>();
    const newReleaseNoteSection: IReleaseNote = {
        Id: 0,
        VersionNumber: '',
        ReleaseDate: '',
        WhatsNew: [],
        WhatsFixed: [],
        WhatsImproved: [],
        WhatsChanged: [],
        WhatsRemoved: [],
        UpdatedByUserId: 0,
        UpdatedByUser: '',
    };
    const releaseNoteDetails =
        cloneDeep(useTypedSelector(getReleaseNoteById(Number(releaseNoteId)))) || newReleaseNoteSection;

    const canCreateReleaseNote = !releaseNoteDetails;
    const [versionNumber, setVersionNumber] = useState(releaseNoteDetails?.VersionNumber || '');
    const [releaseDate, setReleaseDate] = useState(releaseNoteDetails?.ReleaseDate || new Date().toISOString());
    const [whatsNew, setWhatsNew] = useState(releaseNoteDetails?.WhatsNew || []);
    const [whatsFixed, setWhatsFixed] = useState(releaseNoteDetails?.WhatsFixed || []);
    const [whatsImproved, setWhatsImproved] = useState(releaseNoteDetails?.WhatsImproved || []);
    const [whatsChanged, setWhatsChanged] = useState(releaseNoteDetails?.WhatsChanged || []);
    const [whatsRemoved, setWhatsRemoved] = useState(releaseNoteDetails?.WhatsRemoved || []);
    const [hasError, setHasError] = useState(false);

    const {growl, openGrowl, closeGrowl} = useGrowl();
    const history = useHistory();

    const isEmpty = () => {
        return (
            whatsNew.length + whatsFixed.length + whatsImproved.length + whatsChanged.length + whatsRemoved.length === 0
        );
    };

    const disableButton =
        versionNumber.length === 0 ||
        releaseDate === undefined ||
        isEmpty() ||
        !/^\d+(\.\d+){0,2}$/.test(versionNumber);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(disableButton);
    const backUrl = '/release-notes';

    const pathname = history.location.pathname;
    const scrollId = history.location.hash.split('#')[1];
    const scrollRef = useRef<HTMLDivElement>(null);

    const [isCreateReleaseNoteDetails, setIsCreateReleaseNoteDetails] = useState<boolean>(canCreateReleaseNote);
    const [isEditReleaseNoteDetails, setIsEditReleaseNoteDetails] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isShowSubmitButton = isCreateReleaseNoteDetails || isEditReleaseNoteDetails;
    const isCreateReleaseNote = isCreateReleaseNoteDetails;

    const closeAllEdits = () => {
        setIsCreateReleaseNoteDetails(false);
        setIsEditReleaseNoteDetails(false);
    };

    const removeUrlFragment = () => {
        if (scrollId) {
            history.push(pathname);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // eslint-disable-next-line
        const updatedReleaseNote: IReleaseNote = {
            Id: isCreateReleaseNote ? 0 : Number(releaseNoteId),
            VersionNumber: versionNumber,
            ReleaseDate: new Date(format(new Date(releaseDate), 'yyyy-MM-dd')).toISOString(),
            WhatsNew: whatsNew.length === 0 ? undefined : whatsNew,
            WhatsChanged: whatsChanged.length === 0 ? undefined : whatsChanged,
            WhatsFixed: whatsFixed.length === 0 ? undefined : whatsFixed,
            WhatsImproved: whatsImproved.length === 0 ? undefined : whatsImproved,
            WhatsRemoved: whatsRemoved.length === 0 ? undefined : whatsRemoved,
            CreatedAt: releaseNoteDetails.CreatedAt,
            UpdatedByUserId: accessToken ? accessToken.Permissions.UserId : undefined,
        };

        const checkIfAnyEmpty = (releaseNoteSection?: IReleaseNoteSection[]) => {
            let hasError = false;
            if (releaseNoteSection) {
                releaseNoteSection.map((releaseNoteItem) => {
                    const {User, ContentList} = releaseNoteItem;
                    if (
                        (User === '' && ContentList.length === 0) ||
                        (ContentList.length === 1 && ContentList[0] === '')
                    ) {
                        hasError = true;
                        return true;
                    } else if (User === '' && ContentList.length > 0) {
                        hasError = true;
                        return true;
                    } else if (
                        User &&
                        User.length > 0 &&
                        (ContentList.length === 0 || (ContentList.length === 1 && ContentList[0] === ''))
                    ) {
                        hasError = true;
                        return true;
                    }
                });
            }
            return hasError;
        };

        isEditReleaseNoteDetails && updatedReleaseNote.UpdatedByUserId === accessToken?.Permissions?.UserId;
        setIsLoading(true);
        if (
            checkIfAnyEmpty(updatedReleaseNote.WhatsNew) ||
            checkIfAnyEmpty(updatedReleaseNote.WhatsChanged) ||
            checkIfAnyEmpty(updatedReleaseNote.WhatsFixed) ||
            checkIfAnyEmpty(updatedReleaseNote.WhatsImproved) ||
            checkIfAnyEmpty(updatedReleaseNote.WhatsRemoved)
        ) {
            setIsLoading(false);
            return openGrowl('Role or description cannot be empty.');
        } else {
            if (isCreateReleaseNote) {
                const createdReleaseNote = await dispatch(createReleaseNote(updatedReleaseNote));
                if ('error' in createdReleaseNote) {
                    openGrowl(createdReleaseNote.payload as string);
                } else {
                    history.push({
                        state: {message: 'Release note added successfully'},
                        pathname: '/release-notes',
                    });
                }
                setIsLoading(false);
            } else if (isEditReleaseNoteDetails) {
                const updatedReleaseNoteResult = await dispatch(updateReleaseNoteDetails(updatedReleaseNote));
                if ('error' in updatedReleaseNoteResult) {
                    openGrowl(updatedReleaseNoteResult.payload as string);
                } else {
                    history.push({
                        state: {message: 'Release note edited successfully'},
                        pathname: '/release-notes',
                    });
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
                return;
            }
        }
    };

    const closeConfirmDialog = () => {
        setIsConfirmDialogOpen(false);
    };
    const handleCancel = () => {
        setIsConfirmDialogOpen(true);
    };
    const confirmCancel = () => {
        if (isCreateReleaseNote || isEditReleaseNoteDetails) {
            history.push(backUrl);
        }
        closeAllEdits();
        removeUrlFragment();

        setVersionNumber(releaseNoteDetails?.VersionNumber || '');
        setReleaseDate(releaseNoteDetails?.ReleaseDate);
        setWhatsNew(releaseNoteDetails?.WhatsNew || []);
        setWhatsFixed(releaseNoteDetails?.WhatsFixed || []);
        setWhatsImproved(releaseNoteDetails?.WhatsImproved || []);
        setWhatsChanged(releaseNoteDetails?.WhatsChanged || []);
        setWhatsRemoved(releaseNoteDetails?.WhatsRemoved || []);
        closeConfirmDialog();
    };

    useEffect(() => {
        if (pathname.includes('/edit')) {
            setIsEditReleaseNoteDetails(true);
        } else if (pathname.includes('/create')) {
            setIsCreateReleaseNoteDetails(true);
        }
        setIsButtonDisabled(disableButton);
    }, [versionNumber, releaseDate, whatsNew, whatsFixed, whatsImproved, whatsChanged, whatsRemoved]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [scrollId]);

    const SubmitButtons = () => {
        return (
            <div styleName="buttonWrappers">
                <Button
                    onClick={() => {
                        handleCancel();
                    }}
                    type="button"
                    color="primary"
                    variant="text"
                    disabled={isLoading}
                    data-testid="cancel_button"
                >
                    Cancel
                </Button>
                <Button
                    data-testid="save_button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled || hasError}
                    id="submit-button"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </div>
        );
    };

    const domainHeaderRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <Box component="div" styleName="backButtonWrapper">
                <BackButton
                    onClick={() => {
                        history.push(backUrl);
                    }}
                />
            </Box>
            <form onSubmit={handleSubmit} styleName="releaseNoteEditForm">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            Release Note
                        </Typography>
                    </Grid>
                    <Grid item ref={domainHeaderRef}>
                        <SubmitButtons />
                    </Grid>
                </Grid>
                <div ref={scrollId === 'details' ? scrollRef : null} />
                <ReleaseNoteDetails
                    versionNumber={versionNumber}
                    versionNumberLabel="Release Number"
                    setVersionNumber={setVersionNumber}
                    releaseDate={releaseDate}
                    setReleaseDate={(value) => setReleaseDate(value)}
                    setHasError={setHasError}
                />
                <Divider styleName="divider" />
                <div ref={scrollId === 'admin1' ? scrollRef : null} />
                <ReleaseNoteDetailEdit
                    releaseNoteSection={whatsNew}
                    setReleaseNoteSection={setWhatsNew}
                    title={"What's New"}
                    icon={<WhatsNewIcon height={24} width={24} />}
                    setHasError={setHasError}
                />
                <ReleaseNoteDetailEdit
                    releaseNoteSection={whatsImproved}
                    setReleaseNoteSection={setWhatsImproved}
                    title={"What's Improved"}
                    icon={<WhatsImprovedIcon height={24} width={24} />}
                    setHasError={setHasError}
                />
                <ReleaseNoteDetailEdit
                    releaseNoteSection={whatsFixed}
                    setReleaseNoteSection={setWhatsFixed}
                    title={"What's Fixed"}
                    icon={<WhatsFixedIcon height={24} width={24} />}
                    setHasError={setHasError}
                />
                <ReleaseNoteDetailEdit
                    releaseNoteSection={whatsChanged}
                    setReleaseNoteSection={setWhatsChanged}
                    title={"What's Changed"}
                    icon={<WhatsChangedIcon height={24} width={24} />}
                    setHasError={setHasError}
                />
                <ReleaseNoteDetailEdit
                    releaseNoteSection={whatsRemoved}
                    setReleaseNoteSection={setWhatsRemoved}
                    title={"What's Removed"}
                    icon={<WhatsRemovedIcon height={24} width={24} />}
                    setHasError={setHasError}
                />

                {isShowSubmitButton && (
                    <SubmitFooter
                        isButtonDisabled={isButtonDisabled}
                        handleCancel={handleCancel}
                        domainHeaderRef={domainHeaderRef}
                        isLoading={isLoading}
                    />
                )}
            </form>
            <ConfirmDialog
                id="ConfirmDialog"
                onCancel={closeConfirmDialog}
                onConfirm={confirmCancel}
                open={isConfirmDialogOpen}
                title="Discard Changes?"
                message="Any unsaved changes will be lost."
                cancelOption="KEEP EDITING"
                confirmOption="DISCARD"
            />
            <AdminSnackbar
                snackBarProps={{
                    anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                    key: growl?.key,
                    open: growl?.open,
                    onClose: closeGrowl,
                    autoHideDuration: growl.autoHideDuration,
                    message: growl.message.length > 0 ? growl.message : undefined,
                }}
                domainHeaderRef={domainHeaderRef}
            />
        </>
    );
};
