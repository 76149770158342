import React, {useEffect, useState, useContext, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Typography, Grid, IconButton, Link, InputAdornment} from '@mui/material';
import {IMonthlyReviewResponse, IUser} from '~/interfaces/admin';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {InputField} from '~/components/Common/InputField/InputField';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {IAccessToken} from '~/utils/contexts/authentication/authenticationProvider';
import {formatDate, formatTime} from '~/utils/dateUtils';
import {EmptyList} from '~/components/Admin/EmptyList/EmptyList';
import {consolidateFetchState} from '~/utils/apiFetchStateUtils';
import {WithAdminLoader} from '~/components/Admin/WithAdminLoader/WithAdminLoader';
import {COPIES, LOADING_MESSAGE, MONTHLY_REVIEW_STATUS, LEARN_MORE_LINK} from '~/constants';
import {AddButtonPrimary} from '~/components/Common/Button/AddButtonPrimary';
import {TableButton} from '~/components/Common/Button/TableButton';
import {createTeamAndAdminMonthlyReview, getRequiresMonthlyReview} from '~/services/userServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {AdminSnackbar} from '~/components/Admin/AdminSnackbar/AdminSnackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {SearchRounded as SearchIcon} from '@mui/icons-material';
import './OpsUnitUserList.scss';

import {useAppDispatch} from '~/hooks/useAppDispatch';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {getUsersFromOpsUnit} from '~/store/user/user.selector';
import {getTeams} from '~/store/team/team.selector';
import {setUsersFromOpsUnit} from '~/store/user/user.thunk';
import {setTeams} from '~/store/team/team.thunk';
import {setOpsUnit} from '~/store/opsUnit/opsUnit.thunk';
import {getUsersFetchState, getTeamsFetchState, getOpsUnitFetchState} from '~/store/fetchState/fetchState.selector';
import {ReviewBanner} from '~/components/Common/ReviewBanner/ReviewBanner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {getEmailDirectAdminLink} from '~/utils/contentUtils';
import {forEach, size} from 'lodash';
import UserIndicatorTag from '~/components/Admin/UserIndicatorTag/UserIndicatorTag';

export const OpsUnitUserList = () => {
    const dispatch = useAppDispatch();
    const {accessToken} = useContext(AuthenticationContext);
    const history = useHistory();

    const opsUnitId = (accessToken as IAccessToken).Permissions.OpsUnitId;
    const agencyName = (accessToken as IAccessToken).AdditionalUserInfo.Agency;
    const opsUnitName = (accessToken as IAccessToken).AdditionalUserInfo.OpsUnit;

    const users = Object.values(useTypedSelector(getUsersFromOpsUnit(Number(opsUnitId))));
    const teams = useTypedSelector(getTeams(Number(opsUnitId)));
    const opsUnitDetails = useTypedSelector((state) => state.data.admin.opsUnits[Number(opsUnitId)]);

    const usersFetchState = useTypedSelector(getUsersFetchState);
    const teamsFetchState = useTypedSelector(getTeamsFetchState);
    const opsUnitFetchState = useTypedSelector(getOpsUnitFetchState);
    const fetchStatus = consolidateFetchState([opsUnitFetchState, teamsFetchState, usersFetchState]);

    const [monthlyReviewStatus, setMonthlyReviewStatus] = useState<IMonthlyReviewResponse>();

    const {growl, openGrowl, closeGrowl} = useGrowl();

    const handleEdit = (userId: number) => {
        history.push(`/admin/ops-units/${opsUnitId}/users/${userId}`);
    };

    const handleClick = () => {
        history.push(`/admin/ops-units/${opsUnitId}/users/create`);
    };

    const getDirectAdminEmails = () => {
        if (opsUnitDetails.Agency && opsUnitDetails.Agency.Admins) {
            const formatAAEmailRecipients = opsUnitDetails.Agency?.Admins?.map((admin) => admin.Email).join(',');
            return getEmailDirectAdminLink(
                formatAAEmailRecipients,
                accessToken?.Permissions.AccessLevel,
                opsUnitDetails.Name,
            );
        }
    };

    useEffect(() => {
        dispatch(setUsersFromOpsUnit(Number(opsUnitId)));
        dispatch(setTeams(Number(opsUnitId)));
        dispatch(setOpsUnit(Number(opsUnitId)));
        getRequiresMonthlyReview().then((resp) => {
            setMonthlyReviewStatus(resp);
        });
    }, []);

    const getIndexOfAdmin = (name: string) => {
        if (opsUnitDetails.Admins) {
            return opsUnitDetails.Admins?.map((admin, index) => {
                if (admin.Name === name) {
                    return index + 1;
                }
            });
        }
        return '';
    };

    const userDetails = (user: IUser) => {
        return (
            <Box component="div">
                <Box component="div" styleName="nameWrapper" data-testid="opsunit-userlist-username">
                    {user.Name}
                </Box>
                <Box component="div" styleName="designationWrapper" data-testid="opsunit-userlist-designation">
                    {user.TeamId === 0 ? `Ops Unit Admin ${getIndexOfAdmin(user.Name)}` : user.Designation}
                </Box>
                <Box display="flex" data-testid="opsunit-userlist-tag-container">
                    {user.CanScreenClient && <UserIndicatorTag />}
                    {!user.IsActive && <SuspendedIcon />}
                </Box>
            </Box>
        );
    };

    const getAdminText = (userId: number | undefined): string => {
        if (!userId || !opsUnitDetails || !opsUnitDetails.Admins) {
            return '';
        }
        if (
            opsUnitDetails.Admins.find((admin) => {
                return admin.Id == userId;
            })
        ) {
            return 'Ops Unit Admin';
        }
        return 'Team Admin';
    };

    const userLastUpdatedAdmin = (user: IUser) => {
        return user.UpdatedByUser ? (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {user.UpdatedByUser?.Name}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {getAdminText(user.UpdatedByUser?.Id)}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };
    const userLastUpdatedDateComponent = (user: IUser) => {
        return user.UpdatedByUser ? (
            <Box component="div">
                <Box component="div" styleName="nameWrapper">
                    {user.UpdatedAt ? formatDate(new Date(user.UpdatedAt), false, true, false) : ''}
                </Box>
                <Box component="div" styleName="designationWrapper">
                    {user.UpdatedAt ? formatTime(new Date(user.UpdatedAt), true, true, true, true, false) : ''}
                </Box>
            </Box>
        ) : (
            <Box component="div">-</Box>
        );
    };

    const userControls = (user: IUser) => {
        if (user.isOA) {
            return (
                <Link href={getDirectAdminEmails()} target="_blank" rel="noopener noreferrer">
                    <Box display="flex" alignItems="center">
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '16px',
                                letterSpacing: '1.25px',
                                color: '#3949AB',
                            }}
                            variant="body2"
                        >
                            EMAIL{<br />}AGENCY{<br />}ADMIN
                        </Typography>
                        <NavigateNextIcon fontSize="small" />
                    </Box>
                </Link>
            );
        }
        return <TableButton text="Edit" onClick={() => handleEdit(user.Id as number)} />;
    };

    const userNameAndDesignation = (user: IUser) => user.Name + user.Designation;
    const teamName = (user: IUser) => (teams[Number(user.TeamId)] ? teams[Number(user.TeamId)].Name : '-');
    const userLastUpdatedAdminName = (user: IUser) => (user.UpdatedByUser ? user.UpdatedByUser.Name : '');
    const userLastUpdatedDate = (user: IUser) => (user.UpdatedAt ? new Date(user.UpdatedAt) : new Date());
    const handleReviewSubmit = () => {
        const promise = createTeamAndAdminMonthlyReview();
        promise
            .then(() => {
                openGrowl(COPIES.SUBMIT_MONTHLY_REVIEW_MESSAGE.replace('zz', 'Agency Admin(s)'));
                getRequiresMonthlyReview().then((resp) => {
                    setMonthlyReviewStatus(resp);
                });
            })
            .catch((err) => {
                openGrowl(err.message);
            });
    };

    const dockedOA = () => {
        if (opsUnitDetails.Admins) {
            return [
                ...opsUnitDetails.Admins.map((admin, index) => ({
                    ...admin,
                    isOA: true,
                    Designation: `Ops Unit Admin ${index + 1}`,
                    CanScreenClient: opsUnitDetails.CanScreenClient,
                })),
            ];
        }
    };

    const customRowStyle = (adminRow: IUser) => {
        return adminRow.isOA ? {background: '#F0F0F0'} : {};
    };

    const hasOneTeamWithoutTA = () => {
        const teamsWithAdmin: Boolean[] = [];
        forEach(teams, function (value) {
            if (value.Admins && value.Admins?.length > 0) {
                teamsWithAdmin.push(false);
            } else {
                teamsWithAdmin.push(true);
            }
        });
        return size(teams) == 1 && teamsWithAdmin.every((value) => value === true);
    };

    const getOpsUnitUserListContent = () => {
        const [searchValue, setSearchValue] = useState('');
        const filteredUsers = users.filter((user) => user.Name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        const domainHeaderRef = useRef<HTMLDivElement>(null);
        const opsUnitAdmins = dockedOA();
        const withDockedRows = opsUnitAdmins ? [...opsUnitAdmins, ...filteredUsers] : filteredUsers;
        return (
            <>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography component="h1" variant="h4">
                            User Management
                        </Typography>
                        <Typography component="h1" variant="h5">
                            {`${agencyName} / ${opsUnitName}`}
                        </Typography>
                    </Grid>
                    {hasOneTeamWithoutTA() &&
                        monthlyReviewStatus &&
                        monthlyReviewStatus.Status !== MONTHLY_REVIEW_STATUS.NEW && (
                            <ReviewBanner
                                monthlyReviewStatus={monthlyReviewStatus}
                                handleReviewSubmit={handleReviewSubmit}
                                messageCallToAction={
                                    <Link
                                        display="inline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={LEARN_MORE_LINK}
                                    >
                                        Learn more {'>'}
                                    </Link>
                                }
                            />
                        )}
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputField
                                htmlFor="user-list-search"
                                value={searchValue}
                                onChange={setSearchValue}
                                label="Search User Name"
                                type="search"
                                style={{
                                    background: '#FFFFFF',
                                    width: '320px',
                                    height: '44px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                isDense={true}
                            />
                        </Grid>
                        <Grid item>
                            <AddButtonPrimary text="Add User" onClick={handleClick} id="add-user-button" />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {users.length > 0 ? (
                            <ListModel
                                columns={[
                                    userDetails,
                                    teamName,
                                    userLastUpdatedAdmin,
                                    userLastUpdatedDateComponent,
                                    userControls,
                                ]}
                                headers={['User Name', 'Team Name', 'Last Updated by', 'Date Updated', '']}
                                modelList={withDockedRows as IUser[]}
                                numberOfFixedRows={opsUnitAdmins?.length}
                                modelComparator={[
                                    getAlphabeticalComparator(userNameAndDesignation),
                                    getAlphabeticalComparator(teamName),
                                    getAlphabeticalComparator(userLastUpdatedAdminName),
                                    getDateComparator(userLastUpdatedDate),
                                    () => 0,
                                ]}
                                variant="paginated"
                                hover
                                customRowStyle={customRowStyle}
                            />
                        ) : (
                            <EmptyList entity="User" handleClick={handleClick} />
                        )}
                    </Grid>
                    <AdminSnackbar
                        snackBarProps={{
                            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
                            key: growl?.key,
                            open: growl?.open,
                            onClose: closeGrowl,
                            autoHideDuration: growl.autoHideDuration,
                            message:
                                growl.message.length > 0 ? (
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" alignItems="center" marginRight={1.75}>
                                            <CheckCircleIcon />
                                        </Box>

                                        {growl.message}
                                    </Box>
                                ) : undefined,
                            action: (
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeGrowl}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                        domainHeaderRef={domainHeaderRef}
                    />
                </Grid>
            </>
        );
    };

    const OpsUnitUserListWithAdminLoader = WithAdminLoader<{}>(
        getOpsUnitUserListContent,
        fetchStatus,
        LOADING_MESSAGE.DATA,
    );

    return <OpsUnitUserListWithAdminLoader />;
};
