import {IMonthlyReviewResponse} from '~/interfaces/admin';
import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {isResponseOk} from '~/utils/apiResponseUtils';

export const getRequiresMonthlyAdminReview = async (): Promise<IMonthlyReviewResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/admin-monthly-reviews`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createMonthlyAdminReview = async () => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/admin-monthly-reviews`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const createOpsUnitApproval = async (opsUnitId: number | undefined) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/approve`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getSubdomainsWithNewFields = async (): Promise<number[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/subdomains/new-fields`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};
