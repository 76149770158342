import React from 'react';
import {Box, Button} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import './ExpandCollapseButton.scss';

interface IExpandCollapseButton {
    totalLength: number;
    limitLength: number;
    displayLength: number;
    onExpand: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCollapse: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ExpandCollapseButton = ({
    totalLength,
    limitLength,
    displayLength,
    onExpand,
    onCollapse,
}: IExpandCollapseButton) =>
    totalLength > limitLength ? (
        <Box styleName="expandCollapseButton" boxShadow={1}>
            {displayLength < totalLength ? (
                <Button styleName="readMore" onClick={onExpand} data-testid="readMoreButton">
                    <ExpandMoreIcon styleName="icon" />
                    Read More
                </Button>
            ) : (
                <Button styleName="readMore" onClick={onCollapse} data-testid="readLessButton">
                    <ExpandLessIcon styleName="icon" />
                    Read Less
                </Button>
            )}
        </Box>
    ) : null;
