import React from 'react';

const BaseIcon = ({d, fill}: {d: string; fill: string}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path fill={fill} d={d} />
        </g>
    </svg>
);

export const SwitchAccountIcon = () => (
    <BaseIcon
        fill="#3949AB"
        d="M17 20H4V7c0-.55-.45-1-1-1s-1 .45-1 1v13c0 1.1.9 2 2 2h13c.55 0 1-.45 1-1s-.45-1-1-1zm3-18H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-6 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zM7.76 16c1.47-1.83 3.71-3 6.24-3s4.77 1.17 6.24 3H7.76z"
    />
);

export const WarningIcon = () => (
    <BaseIcon
        fill="#FDAB30"
        d="M4.471 20.508h15.06c1.54 0 2.5-1.67 1.73-3l-7.53-13.01c-.77-1.33-2.69-1.33-3.46 0l-7.53 13.01c-.77 1.33.19 3 1.73 3zm7.53-7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
    />
);

export const InfoIcon = ({color}: {color?: string}) => (
    <BaseIcon
        fill={color ? color : '#3F91EF'}
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"
    />
);

export const ErrorIcon = () => (
    <BaseIcon
        fill="#D60025"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
    />
);

export const ChecklistIcon = ({fill}: {fill: string}) => (
    <BaseIcon
        fill={fill}
        d="M15.83 10.29l-2.12-2.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l1.41 1.41 3.54-3.54c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.24 4.24c-.39.4-1.02.4-1.41.01zM10 7H3c-.55 0-1 .45-1 1s.45 1 1 1h7c.55 0 1-.45 1-1s-.45-1-1-1zm10.29 5.71c-.39-.39-1.02-.39-1.41 0L17 14.59l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L15.59 16l-1.88 1.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L17 17.41l1.88 1.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L18.41 16l1.88-1.88c.39-.39.39-1.02 0-1.41zM10 15H3c-.55 0-1 .45-1 1s.45 1 1 1h7c.55 0 1-.45 1-1s-.45-1-1-1z"
    />
);

export const ReportIcon = ({fill}: {fill: string}) => (
    <BaseIcon
        fill={fill}
        d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
    />
);

export const DemographicsIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z"
    />
);

export const FinancialAssistanceIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="currentColor">
            <g fill="currentColor">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M18.723.098c-.583 0-1.059.482-1.059 1.073v5.493c0 .537-.183 1.007-.55 1.4l-2.02 2.2c-.4.438-.908.657-1.492.657h-.075c-.119 0-.216-.066-.27-.175-.044-.12-.022-.24.064-.328l2.81-2.988c.367-.394.356-1.028-.033-1.412-.4-.372-1.026-.36-1.404.033l-3.609 3.841c-.378.394-.561.865-.561 1.423v6.27h5.283v-2.681l3.576-4.771c.27-.372.41-.777.41-1.237V1.171c0-.59-.486-1.073-1.07-1.073zM5.089 5.953c-.368-.394-1.005-.405-1.394-.033-.389.383-.41 1.018-.033 1.422l2.81 2.977c.086.087.107.22.054.328-.043.11-.14.175-.26.175H6.18c-.583 0-1.08-.219-1.48-.656l-2.032-2.2c-.356-.394-.54-.864-.54-1.4V1.072C2.129.482 1.654 0 1.07 0 .475 0 0 .482 0 1.072v7.726c0 .46.13.864.41 1.236l3.566 4.771v2.681H9.27v-6.27c0-.558-.195-1.028-.562-1.422l-3.62-3.84z"
                                            transform="translate(-26 -294) translate(0 94) translate(26 200) translate(0 6)"
                                        />
                                    </g>
                                    <path
                                        d="M16.158 6.667C16.158 2.99 13.207 0 9.578 0 5.952 0 3 2.99 3 6.667c0 3.676 2.952 6.666 6.579 6.666s6.579-2.99 6.579-6.666zm-6.58 5c-2.756 0-5-2.243-5-5s2.244-5 5-5c2.758 0 5 2.243 5 5s-2.242 5-5 5z"
                                        transform="translate(-26 -294) translate(0 94) translate(26 200)"
                                    />
                                </g>
                            </g>
                            <path
                                d="M10.065 10v-.718c.445-.046.796-.196 1.052-.449.255-.252.383-.591.383-1.015 0-.435-.127-.777-.38-1.027-.253-.25-.636-.46-1.15-.63-.346-.13-.584-.258-.712-.386-.129-.128-.193-.297-.193-.508 0-.226.062-.405.186-.54.123-.133.313-.2.57-.2.245 0 .435.083.57.249.136.165.204.39.204.675h.854l.011-.018c.005-.462-.109-.833-.342-1.115-.232-.283-.56-.452-.98-.508V3h-.579v.802c-.426.05-.761.205-1.006.466s-.367.597-.367 1.006c0 .435.128.778.385 1.03.257.25.642.46 1.156.627.324.115.554.24.688.377.135.136.202.308.202.517 0 .228-.074.408-.222.54-.148.133-.36.2-.636.2-.259 0-.473-.073-.643-.217-.17-.145-.254-.388-.254-.73h-.854L8 7.639c-.01.52.127.913.41 1.18.282.268.64.424 1.077.468V10h.578z"
                                transform="translate(-26 -294) translate(0 94) translate(26 200)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const SocialSupportIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <path
                id="oqt9p6r0xa"
                d="M6 11l-.87-.791C2.04 7.409 0 5.563 0 3.297 0 1.451 1.452 0 3.3 0 4.344 0 5.346.486 6 1.253 6.654.486 7.656 0 8.7 0 10.548 0 12 1.45 12 3.297c0 2.266-2.04 4.112-5.13 6.918L6 11z"
            />
        </defs>
        <g fill="none">
            <g>
                <g>
                    <g>
                        <g fill="#3C3C3C">
                            <path
                                d="M19.626.527l-.09-.094c-.57-.59-1.59-.592-2.276.086L12.724 4.93h-3.93c-.218 0-.615-.74 0-1.263.004-.004.006.015.01.015h3.258c.816 0 1.291-.578 1.291-1.404v-.13c0-.827-.475-1.462-1.291-1.462h-6.22c-.02 0-.037-.015-.056-.014-.277-.005-.525.066-.72.249L0 5.665 4.196 10l1.725-1.575h7.427c.366 0 .7-.25.958-.472.085-.062.168-.187.247-.265l4.864-4.835c.686-.678.78-1.735.21-2.326z"
                                transform="translate(-26 -407) translate(0 94) translate(26 313) translate(0 11)"
                            />
                        </g>
                        <g transform="translate(-26 -407) translate(0 94) translate(26 313) matrix(-1 0 0 1 20 0)">
                            <mask id="t32zyawzkb" fill="#fff">
                                <use xlinkHref="#oqt9p6r0xa" />
                            </mask>
                            <g fill="#3C3C3C" mask="url(#t32zyawzkb)">
                                <path d="M0 0H14.4V14.667H0z" transform="translate(-1.2 -1.222)" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const HousingIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M16 11V5c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h5c.55 0 1-.45 1-1v-3h2v3c0 .55.45 1 1 1h5c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-2zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm4 4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z"
    />
);

export const EducationIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M3.908 12.9v2.81c0 .73.4 1.41 1.04 1.76l5 2.73c.6.33 1.32.33 1.92 0l5-2.73c.64-.35 1.04-1.03 1.04-1.76V12.9l-6.04 3.3c-.6.33-1.32.33-1.92 0l-6.04-3.3zm6.04-9.66l-8.43 4.6c-.69.38-.69 1.38 0 1.76l8.43 4.6c.6.33 1.32.33 1.92 0l8.04-4.39v5.91c0 .55.45 1 1 1s1-.45 1-1V9.31c0-.37-.2-.7-.52-.88l-9.52-5.19c-.6-.32-1.32-.32-1.92 0z"
    />
);

export const IncomeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="currentColor">
            <g fill="currentColor">
                <g>
                    <path
                        d="M44 554v1c0 1.1-.9 2-2 2H28c-1.11 0-2-.9-2-2v-14c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10v-8H35v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                        transform="translate(-26 -633) translate(0 94)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const MedicalHealthIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M19 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 11h-4v4h-4v-4H6v-4h4V6h4v4h4v4z"
    />
);

export const EmploymentIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M19 6h-4V4c0-1.11-.89-2-2-2H9c-1.11 0-2 .89-2 2v2H3c-1.11 0-1.99.89-1.99 2L1 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0H9V4h4v2z"
    />
);

export const OthersIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    />
);

export const ExpandAllIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
        <g fill="none" fillRule="evenodd">
            <g fill="#3949AB" fillRule="nonzero">
                <g>
                    <g>
                        <path
                            d="M12.5 11.002c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9zM4.473 5.195c.26-.26.68-.26.94 0L8 7.782l2.587-2.587c.26-.26.686-.253.946 0 .26.26.26.68 0 .94l-3.06 3.06c-.26.26-.68.26-.94 0l-3.06-3.06c-.26-.26-.26-.68 0-.94z"
                            transform="translate(-1177 -277) translate(1164 262) translate(10 10)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const CollapseAllIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
        <g fill="none" fillRule="evenodd">
            <g fill="#3949AB" fillRule="nonzero">
                <g>
                    <g>
                        <path
                            d="M12.5 11.002c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9zM4.473 5.195c.26-.26.68-.26.94 0L8 7.782l2.587-2.587c.26-.26.686-.253.946 0 .26.26.26.68 0 .94l-3.06 3.06c-.26.26-.68.26-.94 0l-3.06-3.06c-.26-.26-.26-.68 0-.94z"
                            transform="translate(-1169 -276) translate(1164 262) matrix(1 0 0 -1 2 26)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const WhatsNewIcon = ({height = 32, width = 32}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0H32V32H0z" transform="translate(-382 -315) translate(382 315)" />
                        <g fill="#D47500">
                            <path
                                d="M2.653 27.053l12.054-4.306c1.84-.654 2.373-3.014.986-4.4l-6.04-6.04c-1.386-1.387-3.733-.854-4.4.986L.947 25.347c-.374 1.066.64 2.08 1.706 1.706zM17.413 14.667L24.16 7.92c.653-.653 1.707-.653 2.36 0l.08.08c.387.387 1.027.387 1.413 0 .387-.387.387-1.027 0-1.413l-.08-.08c-1.426-1.427-3.76-1.427-5.186 0L16 13.253c-.387.387-.387 1.027 0 1.414.387.386 1.027.386 1.413 0zM10.747 7.84l-.08.08c-.387.387-.387 1.027 0 1.413.386.387 1.026.387 1.413 0l.08-.08c1.427-1.426 1.427-3.76 0-5.186L12.093 4c-.4-.4-1.04-.4-1.426 0-.387.387-.387 1.027 0 1.413l.08.08c.64.64.64 1.707 0 2.347zM20.08 14.507l-1.413 1.413c-.387.387-.387 1.027 0 1.413.386.387 1.026.387 1.413 0l1.413-1.413c.654-.653 1.707-.653 2.36 0l1.44 1.44c.387.387 1.027.387 1.414 0 .386-.387.386-1.027 0-1.413l-1.44-1.44c-1.44-1.427-3.76-1.427-5.187 0zM17.413 6.507l-4.08 4.08c-.386.386-.386 1.026 0 1.413.387.387 1.027.387 1.414 0l4.08-4.08c1.426-1.427 1.426-3.76 0-5.187L17.413 1.32c-.386-.387-1.026-.387-1.413 0s-.387 1.027 0 1.413l1.413 1.414c.64.653.64 1.72 0 2.36z"
                                transform="translate(-382 -315) translate(382 315) translate(2.667 1.333)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const WhatsImprovedIcon = ({height = 32, width = 32}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0H32V32H0z" transform="translate(-382 -446) translate(382 446)" />
                        <g fill="#D47500">
                            <path
                                d="M15.213 18.053l5.867 5.867c.787.787 2.053.787 2.827 0 .786-.787.786-2.053 0-2.827l-5.867-5.866-2.827 2.826zM19.12 10.387c1.173.413 2.52.48 4.04-.52 1.053-.694 1.867-1.76 2.093-3 .187-1 .04-1.934-.333-2.76l-3.227 3.226-2.36-2.36 3.24-3.24c-.826-.373-1.773-.52-2.773-.333-1.24.24-2.307 1.053-3 2.107-.987 1.52-.92 2.853-.507 4.026l-2.96 2.974-2.253-2.254c.507-.506.507-1.333 0-1.84l-.747-.746 3.094-3.094c-.827-.826-1.92-1.24-3-1.24-1.094 0-2.174.414-3 1.24L3.613 6.387c-.52.52-.52 1.36 0 1.88l.72.72H2c-.253 0-.493.093-.667.28-.373.373-.373.96 0 1.333L4 13.267c.373.373.96.373 1.333 0 .174-.174.28-.414.28-.667v-2.32l.054.053.746.747c.507.507 1.334.507 1.84 0l2.254 2.253-7.76 7.76c-.787.787-.787 2.054 0 2.827.786.787 2.053.787 2.826 0l7.174-7.173 6.373-6.36z"
                                transform="translate(-382 -446) translate(382 446) translate(2.667 2.667)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const WhatsRemovedIcon = ({height = 32, width = 32}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                d="M0 0L32 0 32 32 0 32z"
                                transform="translate(-386 -1628) translate(378 1179) translate(8 449)"
                            />
                            <path
                                fill="#D47500"
                                d="M8 25.333C8 26.8 9.2 28 10.667 28h10.666C22.8 28 24 26.8 24 25.333v-16H8v16zm16-20h-3.333l-.947-.946c-.24-.24-.587-.387-.933-.387h-5.574c-.346 0-.693.147-.933.387l-.947.946H8c-.733 0-1.333.6-1.333 1.334C6.667 7.4 7.267 8 8 8h16c.733 0 1.333-.6 1.333-1.333 0-.734-.6-1.334-1.333-1.334z"
                                transform="translate(-386 -1628) translate(378 1179) translate(8 449)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const WhatsFixedIcon = ({height = 32, width = 32}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M0 0H32V32H0z" transform="translate(-382 -758) translate(382 758)" />
                                <path d="M0 0H32V32H0z" transform="translate(-382 -758) translate(382 758)" />
                            </g>
                            <path
                                fill="#D47500"
                                d="M28 18.667c0-.734-.6-1.334-1.333-1.334h-2.76c-.067-.52-.16-1.026-.294-1.52l2.294-1.32c.64-.373.853-1.186.493-1.826-.373-.64-1.187-.854-1.827-.494l-2.013 1.16c-.373-.64-.827-1.213-1.32-1.72.053-.306.093-.613.093-.946 0-1.067-.32-2.067-.866-2.907l1.253-1.253c.52-.52.52-1.36 0-1.88-.52-.52-1.36-.52-1.88 0l-1.36 1.36c-2.24-1.187-4.133-.44-4.973 0L12.16 4.613c-.52-.52-1.36-.52-1.88 0-.52.52-.52 1.36 0 1.88l1.253 1.254c-.546.853-.866 1.853-.866 2.92 0 .333.04.64.093.96-.493.506-.947 1.08-1.32 1.706l-2.013-1.16c-.64-.36-1.454-.146-1.814.494-.373.64-.146 1.453.494 1.826l2.293 1.32c-.133.494-.227 1-.293 1.52H5.333c-.733 0-1.333.6-1.333 1.334C4 19.4 4.6 20 5.333 20h2.76c.067.52.16 1.027.294 1.52l-2.294 1.32c-.64.373-.853 1.187-.493 1.827.373.64 1.187.853 1.827.493L9.44 24c1.44 2.413 3.84 4 6.56 4 2.72 0 5.12-1.587 6.56-4l2.013 1.16c.64.373 1.454.147 1.827-.493s.147-1.454-.493-1.827l-2.294-1.32c.134-.493.227-1 .294-1.52h2.76C27.4 20 28 19.4 28 18.667zm-12 4c-.733 0-1.333-.6-1.333-1.334V16c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333v5.333c0 .734-.6 1.334-1.333 1.334z"
                                transform="translate(-382 -758) translate(382 758)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const WhatsChangedIcon = ({height = 32, width = 32}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0L32 0 32 32 0 32z" transform="translate(-382 -966) translate(382 966)" />
                        <path
                            fill="#D47500"
                            d="M8 13.333c-1.467 0-2.667 1.2-2.667 2.667 0 1.467 1.2 2.667 2.667 2.667 1.467 0 2.667-1.2 2.667-2.667 0-1.467-1.2-2.667-2.667-2.667zm16 0c-1.467 0-2.667 1.2-2.667 2.667 0 1.467 1.2 2.667 2.667 2.667 1.467 0 2.667-1.2 2.667-2.667 0-1.467-1.2-2.667-2.667-2.667zm-8 0c-1.467 0-2.667 1.2-2.667 2.667 0 1.467 1.2 2.667 2.667 2.667 1.467 0 2.667-1.2 2.667-2.667 0-1.467-1.2-2.667-2.667-2.667z"
                            transform="translate(-382 -966) translate(382 966)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const DataDictionaryIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M17.15.145l-4 4c-.1.1-.15.22-.15.36v8.17c0 .43.51.66.83.37l4-3.6c.11-.09.17-.23.17-.37V.505c0-.45-.54-.67-.85-.36zm4.32 3.85c-.47-.24-.96-.44-1.47-.61v12.03c-1.14-.41-2.31-.62-3.5-.62-1.9 0-3.78.54-5.5 1.58v-12.1c-1.62-.93-3.49-1.48-5.5-1.48-1.79 0-3.48.44-4.97 1.2-.33.16-.53.51-.53.88v12.08c0 .76.81 1.23 1.48.87 1.21-.63 2.57-1.03 4.02-1.03 2.07 0 3.98.82 5.5 2 1.52-1.18 3.43-2 5.5-2 1.45 0 2.81.4 4.02 1.04.67.36 1.48-.11 1.48-.87V4.875c0-.37-.2-.72-.53-.88z"
    />
);

export const TermsOfUseIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6 14H8c-.55 0-1-.45-1-1s.45-1 1-1h5c.55 0 1 .45 1 1s-.45 1-1 1zm3-4H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z"
    />
);

export const ReleaseNotesIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M7 6h10c0 .55.45 1 1 1s1-.45 1-1V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1zm9.12 9.88l3.17-3.17c.39-.39.39-1.02 0-1.41l-3.17-3.17c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41L17.17 12l-2.47 2.47c-.39.39-.39 1.02 0 1.41.39.39 1.03.39 1.42 0zm-6.83-1.42L6.83 12l2.46-2.46c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0L4.7 11.3c-.39.39-.39 1.02 0 1.41l3.17 3.17c.39.39 1.03.39 1.42 0 .4-.39.39-1.03 0-1.42zM17 18H7c0-.55-.45-1-1-1s-1 .45-1 1v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1z"
    />
);

export const ResourcesIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M1.263 6.545C.568 6.545 0 7.036 0 7.636v14.182C0 23.018 1.137 24 2.526 24h13.895c.695 0 1.263-.49 1.263-1.09 0-.6-.568-1.092-1.263-1.092H2.526V7.636c0-.6-.568-1.09-1.263-1.09zM17.166.644C16.686.229 16.042 0 15.373 0H7.579c-1.39 0-2.514.982-2.514 2.182l-.012 15.273c0 1.2 1.124 2.181 2.513 2.181h13.908c1.39 0 2.526-.981 2.526-2.181V7.45c0-.578-.265-1.135-.745-1.538L17.166.643zm-2.008 5.901V1.636l6.947 6h-5.684c-.695 0-1.263-.49-1.263-1.09z"
    />
);

export const ParticipatingAgenciesIcon = () => (
    <BaseIcon
        fill="currentColor"
        d="M15 11V5.83c0-.53-.21-1.04-.59-1.41L12.7 2.71c-.39-.39-1.02-.39-1.41 0l-1.7 1.7C9.21 4.79 9 5.3 9 5.83V7H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-4zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"
    />
);

export const DownloadIcon = () => (
    <svg width="27" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2941 6.99167L9.66065 8.55833V1.33333C9.66065 0.875 9.26966 0.5 8.79177 0.5C8.31389 0.5 7.92289 0.875 7.92289 1.33333V8.55833L6.28071 6.99167C5.94185 6.66667 5.39446 6.66667 5.0556 6.99167C4.71673 7.31667 4.71673 7.84167 5.0556 8.16667L8.17487 11.1583C8.51373 11.4833 9.06112 11.4833 9.39999 11.1583L12.5193 8.16667C12.8581 7.84167 12.8581 7.31667 12.5193 6.99167C12.1804 6.66667 11.633 6.66667 11.2941 6.99167ZM14.8737 13V8.83333C14.8737 8.375 15.2647 8 15.7426 8C16.2205 8 16.6115 8.375 16.6115 8.83333V13.8333C16.6115 14.75 15.8295 15.5 14.8737 15.5H2.70944C1.75367 15.5 0.97168 14.75 0.97168 13.8333V8.83333C0.97168 8.375 1.36268 8 1.84056 8C2.31844 8 2.70944 8.375 2.70944 8.83333V13C2.70944 13.4583 3.10043 13.8333 3.57832 13.8333H14.0049C14.4827 13.8333 14.8737 13.4583 14.8737 13Z"
            fill="white"
        />
    </svg>
);

export const TickIcon = () => (
    <BaseIcon
        fill="#007B4F"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
    />
);

export const UploadFileIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6667 5.00004H10L8.82502 3.82504C8.50835 3.50837 8.08335 3.33337 7.64169 3.33337H3.33335C2.41669 3.33337 1.67502 4.08337 1.67502 5.00004L1.66669 15C1.66669 15.9167 2.41669 16.6667 3.33335 16.6667H16.6667C17.5834 16.6667 18.3334 15.9167 18.3334 15V6.66671C18.3334 5.75004 17.5834 5.00004 16.6667 5.00004ZM10.8334 10.8334V13.3334C10.8334 13.7917 10.4584 14.1667 10 14.1667C9.54169 14.1667 9.16669 13.7917 9.16669 13.3334V10.8334H7.67502C7.30002 10.8334 7.11669 10.3834 7.38335 10.125L9.71669 7.80004C9.88335 7.63337 10.1417 7.64171 10.3084 7.80004L12.6334 10.125C12.8834 10.3834 12.7 10.8334 12.3334 10.8334H10.8334V10.8334Z"
            fill="#3949AB"
        />
    </svg>
);

export const DocumentIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1H14L20 7V19C20 20.1 19.1 21 18 21H5.99C4.89 21 4 20.1 4 19L4.01 3C4.01 1.9 4.9 1 6 1ZM8 17H16V15H8V17ZM16 13H8V11H16V13ZM13 2.5V8H18.5L13 2.5Z"
            fill="#818181"
        />
    </svg>
);
