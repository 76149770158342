import {createSlice} from '@reduxjs/toolkit';
import {IDashboardInfo} from '~/interfaces/dashboard';
import {setDashboard} from './dashboard.thunk';

export const initialDashboardState = {
    dashboardInfo: {} as IDashboardInfo,
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDashboard.fulfilled, (state, action) => {
            state.dashboardInfo = action.payload;
        });
    },
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
