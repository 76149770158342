import React from 'react';

export const nextLineToParagraphs = (text: string) => {
    const regex = /\\r\\n|\\r|\\n/;
    const paragraphs = text ? (
        text.split(regex).map((str, index) => (
            <p key={index} style={{margin: 0, lineHeight: 1.4}}>
                {str}
            </p>
        ))
    ) : (
        <p style={{margin: 0, lineHeight: 1.4}}>-</p>
    );
    return paragraphs;
};
