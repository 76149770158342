import React from 'react';
import {Button, Dialog, DialogContent, Typography} from '@mui/material';
import oneCVLongHorizontalLogo from '~/assets/images/oneCVLongHorizontalLogo.svg';
import onboardingModalPicture from '~/assets/images/onboardingModalPicture.svg';
import './OnboardingModal.scss';
import {styled} from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';

export interface IOnboardingModalProps {
    openModal: boolean;
    onHandleSkip: () => void;
    onHandleGetStarted: () => void;
}

const BootstrapDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        padding: '24px 16px',
    },
}));

const handleClick = () => {
    const newWindow = window.open();
    if (newWindow) {
        newWindow.opener = null;
        newWindow.location = '/getting-started';
        newWindow.focus();
    }
};

export const OnboardingModal = ({openModal, onHandleSkip, onHandleGetStarted}: IOnboardingModalProps) => {
    return (
        <>
            <BootstrapDialog open={openModal}>
                <DialogContent>
                    <div styleName="modalHead">
                        <Typography styleName="modalTitle">Learn all about</Typography>
                        <img styleName="dialogLogo" src={oneCVLongHorizontalLogo} />
                    </div>
                    <img styleName="dialogImage" src={onboardingModalPicture} />
                    <Typography styleName="modalText">
                        Follow along a tutorial video to get to know OneCV better! Keep it open in a new tab, and
                        navigate at your own pace.
                    </Typography>
                    <div styleName="buttonContainer">
                        <Button variant="outlined" color="primary" id="skipButton" onClick={onHandleSkip}>
                            SKIP TUTORIAL
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{marginLeft: 12}}
                            onClick={() => {
                                onHandleGetStarted();
                                handleClick();
                            }}
                            id="getStartedButton"
                        >
                            GET STARTED
                            <LaunchIcon style={{fontSize: '15px', paddingLeft: 6.5}} />
                        </Button>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
};
