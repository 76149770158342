import {config} from '~/config';
import {
    ICurrentRequestWindow,
    IRequestWindow,
    IWindowCreationRequest,
    IWindowCreationResponse,
    IWindowPeriodReportResponse,
} from '~/interfaces/admin';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {httpRequest} from './middleware';

export const getCurrentRequestWindow = async (): Promise<ICurrentRequestWindow> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/current-window`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getLatestClosedWindow = async (): Promise<IRequestWindow> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/latest-closed-window-period`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getAllRequestWindows = async (): Promise<IRequestWindow[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/window`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't get request windows, please try refreshing the page");
    }
    return response.data.Windows;
};

export const createNewRequestWindow = async (
    requestDetails: IWindowCreationRequest,
): Promise<IWindowCreationResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/window`,
        data: JSON.stringify(requestDetails),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const deleteRequestWindow = async (id: number): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/window/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't delete request window, please try again");
    }
};

export const cancelRequestWindow = async (id: number): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/window/${id}/cancel`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PUT',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't cancel request window, please try again");
    }
};

export const getWindowPeriodReport = async (windowId: number): Promise<IWindowPeriodReportResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/dam2.0/window/${windowId}/report`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error("Couldn't cancel request window, please try again");
    }
    return response.data;
};
