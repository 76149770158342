import React from 'react';
import {INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH} from '~/constants';
import {allNoResults, extractAllValues, formatDateField, formatMobileNumber, isNotEmpty} from '~/utils/contentUtils';
import {MultiGridDisplay} from '~/components/Common/GridDisplay/MultiGridDisplay';
import {NoData} from '../CompletedSubDomain';

export interface IFamilyServiceCentre {
    OtpReferenceNumber: string;
    CaseLocation: string;
    StartDate: string;
    EndDate: string;
    CaseStatus: string;
    CaseworkerName: string;
    CaseworkerContact: string;
    CaseClosureReason: string;
    LastApptDate: string;
    OutcomePlanMembers: string;
}

export type IFamilyServiceCentreData = IFamilyServiceCentre[];

const fscToFields = (familyServiceCentre: IFamilyServiceCentre) => {
    const {
        OtpReferenceNumber,
        CaseLocation,
        StartDate,
        EndDate,
        CaseStatus,
        CaseworkerName,
        CaseworkerContact,
        CaseClosureReason,
        LastApptDate,
        OutcomePlanMembers,
    } = familyServiceCentre;

    const caseStatusStyle = CaseStatus === 'Open' ? 'activeCase' : '';
    const caseDetails = [
        {label: 'Case Reference Number', value: OtpReferenceNumber},
        {label: 'Name of Family Service Centre', value: CaseLocation},
        {label: 'Case Status', value: CaseStatus, styleName: caseStatusStyle},
        {label: 'Case Start Date', value: formatDateField(StartDate, true, true)},
        {label: 'Case End Date', value: formatDateField(EndDate, true, true)},
        {label: 'Name of Caseworker', value: CaseworkerName},
        {label: 'Contact Number of Caseworker', value: formatMobileNumber(CaseworkerContact)},
        {label: 'Last Appointment Date', value: formatDateField(LastApptDate, true, true)},
    ];
    const outcomePlanMembers = {
        label: `Outcome Plan Members (${isNotEmpty(OutcomePlanMembers) ? OutcomePlanMembers.split(' / ').length : 0})`,
        value: OutcomePlanMembers,
    };
    const dischargeDetails = [{label: 'Case Closure Reason', value: CaseClosureReason}];
    return CaseStatus === 'Open'
        ? [...caseDetails, outcomePlanMembers]
        : [...caseDetails, ...dischargeDetails, outcomePlanMembers];
};

const sortOrder: {[key: string]: number} = {Open: 1, Closed: 2};

export const FamilyServiceCentre: React.FunctionComponent<{data: IFamilyServiceCentreData}> = ({data}) => {
    const noData = allNoResults(extractAllValues(data));
    const sortedFSC = noData
        ? data.map(fscToFields)
        : data
              .sort((caseA, caseB) => {
                  return sortOrder[caseA.CaseStatus] - sortOrder[caseB.CaseStatus];
              })
              .sort((caseA, caseB) => {
                  return caseA.CaseStatus == caseB.CaseStatus
                      ? new Date(caseB.StartDate).getTime() - new Date(caseA.StartDate).getTime()
                      : 0;
              })
              .map(fscToFields);

    return (
        <>
            {noData ? (
                <NoData />
            ) : (
                <MultiGridDisplay
                    dataTestId="familyServiceCentres"
                    dataFieldGroups={sortedFSC}
                    displayLimit={INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH}
                />
            )}
        </>
    );
};
