import React from 'react';
import {Typography} from '@mui/material';
import {IConsent} from '~/interfaces/client';
import {CheckCircleRounded as CheckCircleIcon} from '@mui/icons-material';
import {formatDate} from '~/utils/dateUtils';
import {consentClause} from '~/constants';
import './ConsentIndicator.scss';

export interface IConsentIndicatorProps {
    consent?: IConsent;
}

export const ConsentIndicator = ({consent}: IConsentIndicatorProps) => {
    return consent ? (
        <div styleName="consentIndicator">
            <CheckCircleIcon styleName="consentTrueIcon" />
            <Typography variant="subtitle1" display="inline">
                {consentClause(consent.Agency, consent.ConsentType)}
                {consent.CreatedAt && ` on ${formatDate(new Date(consent.CreatedAt), false, true)}.`}
            </Typography>
        </div>
    ) : null;
};
