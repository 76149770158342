import React from 'react';
import {Container, Typography, Button} from '@mui/material';
import myClientsEmptyState from '~/assets/images/myClientsEmptyState.svg';
import './EmptyScreeningHistory.scss';

interface IProps {
    onButtonClick: () => void;
}

export const EmptyScreeningHistory = ({onButtonClick}: IProps) => {
    return (
        <Container styleName="container">
            <img src={myClientsEmptyState} alt="Empty My Clients Image" styleName="image" />
            <Typography variant="h4" styleName="label">
                Perform your first screening now
            </Typography>
            <Button onClick={onButtonClick} variant="outlined" color="primary" styleName="button">
                SEARCH CLIENT
            </Button>
        </Container>
    );
};
