import {createSlice} from '@reduxjs/toolkit';
import {IReleaseNote} from '~/interfaces/common';
import {getReleaseNotes} from './releaseNote.thunk';
import {sortReleaseNoteByVersionNumber} from '~/utils/arrayUtils';

export const initialReleaseNotesState: IReleaseNote[] = [];

export const releaseNotesSlice = createSlice({
    name: 'releaseNotes',
    initialState: [] as IReleaseNote[],
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReleaseNotes.fulfilled, (_, action) => {
            if (action.payload) {
                return action.payload.sort(sortReleaseNoteByVersionNumber);
            }
        });
    },
});

export const releaseNoteActions = releaseNotesSlice.actions;
export default releaseNotesSlice.reducer;
