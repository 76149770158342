import React, {useContext} from 'react';
import {Grid, Paper, Typography, Link as MuiLink} from '@mui/material';
import {Link} from 'react-router-dom';
import {config} from '~/config';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {COPIES, LOGIN_STATE, MAIL_TO_LINK} from '~/constants';

import './Footer.scss';

export const Footer = () => {
    const {loginState} = useContext(AuthenticationContext);
    const date = new Date();
    const year = date.getFullYear();
    const isInternetUser = config.internetEnabled;

    return (
        <Paper elevation={5} square styleName="site-footer" id="siteFooter">
            <Grid container styleName="site-footer__container">
                <Grid container item xs={3}>
                    <Typography noWrap variant="subtitle2">
                        &copy; {year} Government of Singapore
                    </Typography>
                </Grid>
                <Grid container item xs={9} justifyContent="flex-end" styleName="site-footer__links">
                    {isInternetUser && (
                        <Typography noWrap variant="subtitle2">
                            <MuiLink
                                href="https://www.tech.gov.sg/report_vulnerability"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Report Vulnerability
                            </MuiLink>
                        </Typography>
                    )}
                    <Typography noWrap variant="subtitle2">
                        <MuiLink href={MAIL_TO_LINK} target="_blank" rel="noopener noreferrer">
                            Contact Us
                        </MuiLink>
                    </Typography>
                    <Typography noWrap variant="subtitle2">
                        <Link to="/privacy-and-terms-of-use">Privacy and Terms of Use</Link>
                    </Typography>
                    <Typography noWrap variant="subtitle2">
                        <Link to="/release-notes">Release Notes</Link>
                    </Typography>
                    {loginState === LOGIN_STATE.LOGGED_IN && (
                        <Typography noWrap variant="subtitle2" id="resourcesLink">
                            <Link to="/getting-started">Resources</Link>
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {loginState === LOGIN_STATE.LOGGED_IN && (
                <Grid container styleName="site-footer__banner" data-testid="footer-banner">
                    <Grid container item>
                        <Typography noWrap variant="caption">
                            {COPIES.FOOTER_BANNER}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};
