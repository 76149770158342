import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress, Button, Link, Paper, Typography} from '@mui/material';
import {NAVBAR_PADDING, ACCESS_LEVELS, OPS_UNIT_TYPE, COPIES} from '~/constants';
import usePageBottom from '~/utils/hooks/usePageBottom';
import {IConsent} from '~/interfaces/client';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {InfoRounded as InfoIcon} from '@mui/icons-material';
import {IRowData} from '~/components/Client/ScreenClientTable/ScreenClientTable';
import './ClientInfoFooter.scss';

export interface IClientInfoFooterProps {
    clientName: string;
    clientUIN: string;
    clientConsent?: IConsent;
    buttonText: string;
    isButtonDisabled: boolean;
    isButtonHidden: boolean;
    isFooterHidden: boolean;
    isButtonLoading: boolean;
    onButtonClick: (event: object) => void;
    headerRef?: React.RefObject<HTMLDivElement>;
    legislativelyLockedFields?: IRowData[];
    screenName?: string;
    onDeleteSubDomain?: (subDomainId: number) => void;
}

export const ClientInfoFooter = ({
    clientName,
    clientUIN,
    buttonText,
    isButtonDisabled,
    isButtonHidden,
    isFooterHidden,
    onButtonClick,
    headerRef,
    isButtonLoading,
    legislativelyLockedFields,
    screenName,
    onDeleteSubDomain,
}: IClientInfoFooterProps) => {
    const {accessToken} = useContext(AuthenticationContext);
    const canGenerateReport =
        accessToken &&
        (accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.PSA ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.NGE_BIP);

    const [isbuttonHiddenState, setIsButtonHiddenState] = useState<boolean>(isButtonHidden);
    const [isFooterHiddenState, setIsFooterHiddenState] = useState<boolean>(isFooterHidden);
    const {isPageBottom} = usePageBottom();
    const hiddenFooterStyle = (style: string) => (isFooterHiddenState ? `${style} hidden` : style);
    const bottomStyle = (style: string) => (isPageBottom ? `${style} bottom` : style);
    const legislativelyLockedText = (() => {
        let text = '';
        if (legislativelyLockedFields && legislativelyLockedFields.length > 0) {
            legislativelyLockedFields.every((domain, index) => {
                if (index === 4) {
                    text += '... (+1)';
                    return false;
                }
                index === 0 ? (text += domain.subDomainName) : (text = text.concat(', ', domain.subDomainName));
                return true;
            });
            return (
                <>
                    <span style={{fontWeight: 'bold'}}>{text}</span>
                    {legislativelyLockedFields.length > 1 ? ' sub-domains' : ' sub-domain'}
                </>
            );
        }
        return null;
    })();
    const llClientInfoStyle = (style: string) =>
        screenName === 'select-domains' && legislativelyLockedText ? `${style} initial` : `${style} auto`;
    // Passing of ref object from ClientInfoView to ClientInfo(parent) causes the parent component to rerender on scroll,
    // and reset states of child components causing expanded nested table to collapse unexpectedly on scroll
    // So the ref is passed from ClientInfoView to ClientInfoFooter(children) to prevent the rerender
    useEffect(() => {
        const headerHandler = ([entry]: IntersectionObserverEntry[]) => {
            setIsButtonHiddenState(entry.isIntersecting);
            isFooterHidden && setIsFooterHiddenState(entry.isIntersecting);
        };

        const headerObserver = new IntersectionObserver(headerHandler, {
            threshold: 0,
            rootMargin: `-${NAVBAR_PADDING}px 0px 0px 0px`,
        });

        if (headerRef?.current) {
            headerObserver.observe(headerRef?.current);
        }

        return () => {
            if (headerRef && headerRef.current && headerObserver) headerObserver.disconnect();
        };
    }, [headerRef?.current]);

    return (
        <>
            <Paper
                id="clientInfoFooter"
                data-testid="clientInfoFooter"
                elevation={2}
                styleName={bottomStyle(hiddenFooterStyle('clientInfoFooter'))}
                square
            >
                <div styleName="flexContainer">
                    <div styleName={llClientInfoStyle('clientProfile')}>
                        <div data-testid="clientName" styleName="clientName">
                            {clientName}
                        </div>
                        <div data-testid="clientUIN" styleName="clientUIN">
                            {clientUIN}
                        </div>
                    </div>
                    {screenName === 'select-domains' && legislativelyLockedText != null && (
                        <div styleName="flexContainer select">
                            <InfoIcon data-testid="tooltipIcon" styleName="tooltip" />
                            <Typography data-testid="selectLiner">
                                {'You need to obtain client consent and may require signed proof to access the '}
                                {legislativelyLockedText}
                                {'.'}
                            </Typography>
                        </div>
                    )}
                    {screenName === COPIES.CONSENT_TITLE &&
                        legislativelyLockedText != null &&
                        onDeleteSubDomain != undefined && (
                            <div styleName="flexContainer consent">
                                <Typography data-testid="consentLiner">
                                    {"Don't need access to the "}
                                    {legislativelyLockedText}
                                    {'? '}
                                    <Link
                                        data-testid="consentLink"
                                        onClick={() => {
                                            legislativelyLockedFields?.map((data) =>
                                                onDeleteSubDomain(data.subDomainId),
                                            );
                                        }}
                                    >
                                        Skip this page
                                    </Link>
                                </Typography>
                            </div>
                        )}
                    {/* {{Display Button if not generate report button OR if user can generate report}} */}
                    {(buttonText !== 'GENERATE REPORT' || canGenerateReport) && !isbuttonHiddenState && (
                        <div styleName="footerButton">
                            <Button
                                id="footerButton"
                                data-testid="footerButton"
                                onClick={onButtonClick}
                                disabled={isButtonDisabled || isButtonLoading}
                                styleName="normalButton"
                                size="large"
                                color="primary"
                                variant="contained"
                            >
                                <span>{buttonText}</span>{' '}
                                {isButtonLoading && <CircularProgress size={24} color="inherit" />}
                            </Button>
                        </div>
                    )}
                </div>
            </Paper>
        </>
    );
};
