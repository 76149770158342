import React, {useEffect, useState} from 'react';
import {IFetchStatus} from '~/interfaces/common';
import {API_FETCH_STATE} from '~/constants';
import {AdminProgressBar} from './AdminProgressBar';

export const Error = () => <div>An unexpected error has occurred. Please try again later</div>;

export const WithAdminLoader = <P extends {}>(
    Component: React.ComponentType<P>,
    fetchStatus: IFetchStatus,
    label?: string,
) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(fetchStatus === API_FETCH_STATE.PENDING);
        setHasError(fetchStatus === API_FETCH_STATE.ERROR);
    }, [fetchStatus]);

    const WithAdminLoaderComponent = ({...passThroughProps}: P) => {
        return isLoading ? (
            <AdminProgressBar label={label} />
        ) : hasError ? (
            <Error />
        ) : (
            <Component {...passThroughProps} />
        );
    };

    return WithAdminLoaderComponent;
};
