import './AgencyList.scss';

import {Box, Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import React, {useContext, useEffect, useState} from 'react';

import {FunctionContext} from './AgencyList';
import {ISysAdminOpsUnit} from '~/interfaces/admin';
import {SuspendedIcon} from '~/components/Admin/SuspendedIcon/SuspendedIcon';
import {TeamsRow} from './TeamsRow';

export interface IOpsUnitProps {
    opsUnit: ISysAdminOpsUnit;
}
export const OpsUnitRow = ({opsUnit}: IOpsUnitProps) => {
    const [expandOpsUnit, setExpandOpsUnit] = useState(false);
    const [checked, setChecked] = useState(opsUnit.CanScreenClient);
    const [hasEdit, setHasEdit] = useState(false);
    const [expandTeams, setExpandTeams] = useState(false);
    const teams = opsUnit.Teams;
    const functionContext = useContext(FunctionContext);

    const handleOpsUnitCheckBox = () => {
        if (hasEdit) {
            functionContext.onCheckBoxClick('OpsUnit', opsUnit.Id, checked);
        }
    };

    useEffect(() => {
        handleOpsUnitCheckBox();
    }, [checked]);

    const opsUnitAdminDetails = (index: number) => (opsUnit: ISysAdminOpsUnit) => {
        if (!!opsUnit && !!opsUnit.Admins && opsUnit.Admins[index]) {
            return (
                <Box component="div">
                    <Box component="div" styleName="nameWrapper">
                        {opsUnit.Admins[index].Name}
                    </Box>
                    <Box component="div" styleName="designationWrapper">
                        {opsUnit.Admins[index].Designation}
                    </Box>
                    {!opsUnit.Admins[index].IsActive && <SuspendedIcon />}
                </Box>
            );
        } else {
            return '-';
        }
    };
    return (
        <Table size="small" aria-label="opsUnit">
            <TableBody>
                <TableRow styleName="opsUnitRows">
                    <TableCell align="left" styleName="opsUnitData" style={{width: '40%'}}>
                        <div style={{display: 'flex', paddingLeft: '28px'}}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    setExpandOpsUnit(!expandOpsUnit);
                                    setExpandTeams(true);
                                }}
                                style={{padding: 0}}
                                disabled={teams ? false : true}
                            >
                                {expandOpsUnit ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                            <div style={{paddingLeft: '24px'}}>{opsUnit.Name}</div>
                        </div>
                    </TableCell>
                    <TableCell align="left" styleName="opsUnitData">
                        {opsUnitAdminDetails(0)(opsUnit)}
                    </TableCell>
                    <TableCell align="left" styleName="opsUnitData">
                        {opsUnitAdminDetails(1)(opsUnit)}
                    </TableCell>
                    <TableCell align="center" styleName="opsUnitData">
                        {opsUnit.Admins && opsUnit.Admins?.length > 0 ? (
                            <div styleName="userCheckBoxGroup">
                                <Checkbox
                                    style={{color: '#3949AB', paddingLeft: 0}}
                                    color="primary"
                                    checked={checked}
                                    onChange={() => {
                                        setChecked(!checked);
                                        setHasEdit(true);
                                    }}
                                />
                                <Typography styleName="checkBoxLabel">USER</Typography>
                            </div>
                        ) : (
                            <div styleName="userCheckBoxGroup">
                                <Checkbox style={{color: 'gray', paddingLeft: 0}} disabled />
                                <Typography style={{color: 'gray'}} styleName="checkBoxLabel">
                                    USER
                                </Typography>
                            </div>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{padding: 0}} colSpan={5}>
                        <Collapse in={expandOpsUnit} style={{paddingLeft: '0px'}}>
                            {teams &&
                                teams.map((team, index) => {
                                    return <TeamsRow team={team} key={index} expandTeams={expandTeams} />;
                                })}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
