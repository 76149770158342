import {makeStyles} from '@mui/styles';

export const useDialogStyles = makeStyles({
    actions: {
        display: 'block',
        textAlign: 'center',
        padding: '16px 0 40px 0',
    },
    buttons: {
        width: '100%',
        maxWidth: '328px',
        padding: '14px 16px',
        backgroundColor: '#3949ab',
        color: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export const useCategoryAutocompleteStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        paddingTop: '2px',
    },
    selectAllCheckBox: {},
});

export const useAgencyAutocompleteStyles = makeStyles({
    autocomplete: {
        width: '320px',
        backgroundColor: 'white',
        marginRight: '16px',
        paddingTop: '2px',
    },
    selectAllCheckBox: {},
});
