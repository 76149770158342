import {createSlice} from '@reduxjs/toolkit';
import {ISurveyTrigger} from '~/interfaces/clientUser';
import {setSurveyTrigger} from './survey.thunk';

export const initialSurveyState = {
    surveyTrigger: {} as ISurveyTrigger,
};

export const surveySlice = createSlice({
    name: 'survey',
    initialState: initialSurveyState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setSurveyTrigger.fulfilled, (state, action) => {
            state.surveyTrigger = action.payload;
        });
    },
});

export const surveyActions = surveySlice.actions;
export default surveySlice.reducer;
