import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';
import style from './ConfirmationModal.scss';

interface IConfirmDialogProps {
    onCancel: () => void;
    onConfirm: () => void;
    cancelBtnTxt?: string;
    confirmBtnText?: string;
    textBody?: string;
    open: boolean;
    title: string;
}

export const ConfirmationModal = ({
    onCancel,
    onConfirm,
    title,
    cancelBtnTxt = 'Cancel',
    confirmBtnText = 'Confirm',
    textBody,
    open,
}: IConfirmDialogProps) => {
    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth={true}>
                <DialogTitle className={style.titleContainer}>{title}</DialogTitle>

                <DialogContent className={style.contentContainer}>
                    <DialogContentText>{textBody}</DialogContentText>
                </DialogContent>

                <DialogActions className={style.buttonContainer}>
                    <Button onClick={onCancel} variant="outlined" color="primary" data-testid={'modal-cancel'}>
                        {cancelBtnTxt}
                    </Button>
                    <Button
                        className={style.confirmButton}
                        onClick={onConfirm}
                        variant="contained"
                        data-testid={'modal-confirm'}
                    >
                        {confirmBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
