import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {ISubdomainDataRequest, ISubdomainRequestSummaryObj} from '~/interfaces/admin';
import {ListModel} from '../Common/ListModel/ListModel';
import style from './SubmissionModal.scss';

interface ISubmissionModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
    subdomainRequestSummary: ISubdomainDataRequest[];
}

interface IModalTableRowData {
    Id: number;
    subdomainName: string;
    isReasonUpdated: boolean;
    numFieldsRemoved: number;
    numFieldsToAdd: number;
}

export const SubmissionModal = ({
    onCancel,
    onConfirm,
    open,
    subdomainRequestSummary: subdomainRequestStates,
}: ISubmissionModalProps) => {
    const [hasAcceptedReasonUpdates, setHasAcceptedReasonUpdates] = useState(false);
    const [hasAcceptedFieldRemovals, setHasAcceptedFieldRemovals] = useState(false);

    const extractSummaryFromRequest = (
        request: ISubdomainDataRequest[],
    ): {[subdomainId: number]: ISubdomainRequestSummaryObj} => {
        return request.reduce((map, request) => {
            map[request.SubdomainId] = request.SubdomainRequestSummary;
            return map;
        }, {} as {[subdomainId: number]: ISubdomainRequestSummaryObj});
    };

    const summaryMap = extractSummaryFromRequest(subdomainRequestStates);
    const modalTableRowData: IModalTableRowData[] = Object.keys(summaryMap)
        .map((subdomainId) => {
            const id = parseInt(subdomainId);
            return {
                Id: id,
                subdomainName: summaryMap[id].SubdomainName,
                isReasonUpdated: summaryMap[id].ReasonUpdated,
                numFieldsRemoved: summaryMap[id].FieldsRemoved,
                numFieldsToAdd: summaryMap[id].FieldsRequested,
            };
        })
        .filter((row) => row.numFieldsToAdd > 0 || row.numFieldsRemoved > 0 || row.isReasonUpdated);

    const areNewFieldsAdded = modalTableRowData.some((row) => row.numFieldsToAdd > 0);
    const areFieldsRemoved = modalTableRowData.some((row) => row.numFieldsRemoved > 0);
    const isAnyReasonUpdated = modalTableRowData.some((row) => row.isReasonUpdated);

    const subdomainColumn = (rowData: IModalTableRowData) => (
        <div data-testid="subdomain name">
            <div>{rowData.subdomainName}</div>
        </div>
    );

    const changesColumn = (rowData: IModalTableRowData) => (
        <div data-testid={`changes-column-${rowData.Id}`}>
            {rowData.isReasonUpdated && (
                <div data-testid={`reason-${rowData.Id}`}>{`Update 'Reason(s) for accessing subdomain'`}</div>
            )}
            {rowData.numFieldsToAdd > 0 && (
                <div data-testid={`add-${rowData.Id}`}>
                    {`Submit request for ${rowData.numFieldsToAdd} data field${rowData.numFieldsToAdd > 1 ? 's' : ''}`}{' '}
                </div>
            )}
            {rowData.numFieldsRemoved > 0 && (
                <div data-testid={`remove-${rowData.Id}`}>
                    {`Remove access to ${rowData.numFieldsRemoved} data field${
                        rowData.numFieldsRemoved > 1 ? 's' : ''
                    }`}
                </div>
            )}
        </div>
    );

    const onClickCancel = () => {
        setHasAcceptedReasonUpdates(false);
        setHasAcceptedFieldRemovals(false);
        onCancel();
    };

    const onClickConfirm = () => {
        setHasAcceptedReasonUpdates(false);
        setHasAcceptedFieldRemovals(false);
        onConfirm();
    };

    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth={true} data-testid="submission-modal">
                <DialogTitle className={style.titleContainer}>Confirm the following changes?</DialogTitle>

                {areNewFieldsAdded && (
                    <DialogContent className={style.contentContainer} data-testid="new-fields-remark">
                        <DialogContentText>
                            Requests are subjected to the approval of the Data Contributing Agency, and the outcome of
                            your requests will be sent to you via email.
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogContent className={style.contentContainer}>
                    <ListModel
                        columns={[subdomainColumn, changesColumn]}
                        headers={['Subdomain', 'Changes']}
                        modelList={modalTableRowData}
                        variant="expanded"
                        sortable={false}
                        customHeaderCellStyle={() => ({
                            backgroundColor: '#E8EAF6',
                        })}
                        defaultSortOrder="asc"
                    />
                </DialogContent>
                {isAnyReasonUpdated && (
                    <DialogContent className={`${style.contentContainer} ${style.checkboxContainer}`}>
                        <Checkbox
                            data-testid={'reason-updates-checkbox'}
                            color="primary"
                            checked={hasAcceptedReasonUpdates}
                            onChange={() => setHasAcceptedReasonUpdates(!hasAcceptedReasonUpdates)}
                        />
                        <Typography>{`I understand that by updating 'Reason(s) for accessing subdomain', all required data fields in the subdomain will be sent for review and subjected to approval based on the updated reasons.`}</Typography>
                    </DialogContent>
                )}

                {areFieldsRemoved && (
                    <DialogContent className={`${style.contentContainer} ${style.checkboxContainer}`}>
                        <Checkbox
                            data-testid={'remove-fields-checkbox'}
                            color="primary"
                            checked={hasAcceptedFieldRemovals}
                            onChange={() => setHasAcceptedFieldRemovals(!hasAcceptedFieldRemovals)}
                        />
                        <Typography>{`I understand that by removing access to the data field(s), users in my Ops Unit will lose access to them once the request window closes. I will need to submit a new request for my Ops Unit to regain access.`}</Typography>
                    </DialogContent>
                )}
                <DialogActions className={style.buttonContainer}>
                    <Button onClick={onClickCancel} variant="outlined" color="primary" data-testid={'modal-cancel'}>
                        Cancel
                    </Button>
                    <Button
                        className={style.confirmButton}
                        onClick={onClickConfirm}
                        variant="contained"
                        data-testid={'modal-confirm'}
                        disabled={
                            (isAnyReasonUpdated && !hasAcceptedReasonUpdates) ||
                            (areFieldsRemoved && !hasAcceptedFieldRemovals)
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
