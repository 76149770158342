import {createSlice} from '@reduxjs/toolkit';
import {API_FETCH_STATE} from '~/constants';
import {IClientUserDataState} from '~/store/dataReducers';
import {
    setClientUserScreeningHistory,
    setClientUserDataAccess,
    setClientUserSubDomainFavourites,
    setNewClientUserSubDomainFavourites,
    deleteClientUserSubDomainFavourite,
} from './clientUser.thunk';

export const initialClientUserState: IClientUserDataState = {
    dataAccess: {
        data: [],
        status: null,
    },
    favourites: [],
    screenHistories: {
        data: [],
        status: null,
    },
};

export const clientUserSlice = createSlice({
    name: 'clientUser',
    initialState: initialClientUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setClientUserScreeningHistory.fulfilled, (state, action) => {
                state.screenHistories.data = action.payload;
                state.screenHistories.status = API_FETCH_STATE.SUCCESS;
            })
            .addCase(setClientUserScreeningHistory.pending, (state) => {
                state.screenHistories.status = API_FETCH_STATE.PENDING;
            })
            .addCase(setClientUserScreeningHistory.rejected, (state) => {
                state.screenHistories.status = API_FETCH_STATE.ERROR;
            })
            .addCase(setClientUserDataAccess.fulfilled, (state, action) => {
                state.dataAccess.data = action.payload;
                state.dataAccess.status = API_FETCH_STATE.SUCCESS;
            })
            .addCase(setClientUserDataAccess.pending, (state) => {
                state.dataAccess.status = API_FETCH_STATE.PENDING;
            })
            .addCase(setClientUserDataAccess.rejected, (state) => {
                state.dataAccess.status = API_FETCH_STATE.ERROR;
            })
            .addCase(setClientUserSubDomainFavourites.fulfilled, (state, action) => {
                state.favourites = action.payload;
            })
            .addCase(setNewClientUserSubDomainFavourites.fulfilled, (state, action) => {
                const favourites = state.favourites.filter((fav) => fav.SubDomainId != action.payload.SubDomainId);
                state.favourites = [...favourites, action.payload];
            })
            .addCase(deleteClientUserSubDomainFavourite.fulfilled, (state, action) => {
                const newFavourites = state.favourites.filter((fav) => fav.Id != action.payload);
                state.favourites = newFavourites;
            });
    },
});

export const clientUserActions = clientUserSlice.actions;
export default clientUserSlice.reducer;
