import {createAsyncThunk} from '@reduxjs/toolkit';
import * as userServices from '~/services/userServices';
import {API_FETCH_STATE, ERROR_MESSAGE} from '~/constants';
import {fetchStateActions} from '../fetchState/fetchState.slice';
import {RootState} from '../index';
import {hydrateFlagActions, Flag} from '../hydrateFlag/hydrateFlag.slice';
import {IUser} from '~/interfaces/admin';

interface ICreateUser {
    teamId: number;
    userDetails: IUser;
    opsUnitId?: number;
}

export const setUsers = createAsyncThunk<
    {teamId: number; usersObject: IUser[] | {}} | void,
    number,
    {state: RootState; rejectValue: string}
>('/users/setUsers', async (teamId: number, {rejectWithValue, dispatch, getState}) => {
    if (!getState().data.flags[Flag.USER]) {
        dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
        try {
            let usersObject = {};
            const users = await userServices.getUsers(teamId);
            if (users !== null) {
                usersObject = users.reduce((obj, user) => {
                    return {
                        ...obj,
                        [user.Id as number]: user,
                    };
                }, {});
            }
            dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
            dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.USER});
            return {teamId, usersObject};
        } catch (err) {
            dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
            return rejectWithValue('Error in fetching user info');
        }
    }
});

export const setUsersFromOpsUnit = createAsyncThunk<
    {opsUnitId: number; usersObject: IUser[] | {}} | void,
    number,
    {rejectValue: string; state: RootState}
>('/users/setUsersFromOpsUnit', async (opsUnitId, {dispatch, rejectWithValue}) => {
    dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.PENDING});
    try {
        let usersObject = {};
        const users = await userServices.getUsersFromOpsUnit(opsUnitId);
        if (users !== null) {
            usersObject = users.reduce((obj, user) => {
                return {
                    ...obj,
                    [user.Id as number]: user,
                };
            }, {});
        }
        dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.SUCCESS});
        dispatch({type: hydrateFlagActions.UPDATE_FLAG.type, payload: Flag.USER});
        return {opsUnitId: opsUnitId, usersObject: usersObject};
    } catch (err) {
        dispatch({type: fetchStateActions.SET_USERS_FETCH_STATE.type, payload: API_FETCH_STATE.ERROR});
        return rejectWithValue('Error in setting user info from ops unit');
    }
});

export const createUser = createAsyncThunk<ICreateUser, ICreateUser, {rejectValue: string}>(
    '/users/createUser',
    async ({teamId, userDetails, opsUnitId}, {rejectWithValue}) => {
        try {
            const userResponse = await userServices.createUser(teamId, userDetails);
            if (userResponse.Error) {
                return rejectWithValue(userResponse.Error as string);
            }
            if (opsUnitId) {
                return {teamId: teamId, userDetails: userResponse.User, opsUnitId: opsUnitId};
            }
            return {teamId: teamId, userDetails: userResponse.User};
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const updateUser = createAsyncThunk<IUser, IUser, {rejectValue: string}>(
    '/users/updateUser',
    async (user, {rejectWithValue}) => {
        try {
            const userResponse = await userServices.updateUser(user);
            if (userResponse.Error) {
                return rejectWithValue(userResponse.Error);
            }
            return userResponse.User;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const deleteUser = createAsyncThunk<number, {userId: number; deletedReason: string}, {rejectValue: string}>(
    '/users/deleteUser',
    async ({userId, deletedReason}, {rejectWithValue}) => {
        try {
            const userResponse = await userServices.deleteUser(userId, deletedReason);
            if (userResponse.Error) {
                return rejectWithValue(userResponse.Error);
            }
            return userId;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);

export const activateUser = createAsyncThunk<IUser, IUser, {rejectValue: string}>(
    '/users/activateUser',
    async (user, {rejectWithValue}) => {
        try {
            await userServices.activateUser(user.Id as number);
            return user;
        } catch (err) {
            return rejectWithValue(ERROR_MESSAGE.GENERIC_SERVER_ERROR);
        }
    },
);
