import React, {useState, useEffect, useContext} from 'react';
import {List, ListItem, Typography, Collapse} from '@mui/material';
import {ExpandLessRounded as ExpandLessIcon, ExpandMoreRounded as ExpandMoreIcon} from '@mui/icons-material';
import {IDataAccessMatrixSubDomain} from '~/interfaces/admin';
import {ACCESS_LEVELS} from '~/constants';
import {DataDictionaryTable} from '~/components/Common/FooterResources/DataDictionaryTable';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import './DataDictionary.scss';

export interface ISubDomainDataDictionaryProps {
    subDomain: IDataAccessMatrixSubDomain;
    expandAll: boolean;
    mountAllFields?: boolean;
    domainIndex: number;
}

export const SubDomainDataDictionary = ({
    subDomain,
    expandAll,
    domainIndex,
    mountAllFields = false,
}: ISubDomainDataDictionaryProps) => {
    const [expand, setExpand] = useState(true);
    const [editable, setEditable] = useState<boolean>(false);
    const auth = useContext(AuthenticationContext);

    const handleClickSubDomain = () => {
        setExpand(!expand);
    };

    useEffect(() => {
        const accessToken = auth.accessToken;

        if (accessToken && accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR) {
            setEditable(true);
        }
    }, []);

    useEffect(() => {
        setExpand(expandAll);
    }, [expandAll]);

    return (
        <List>
            <ListItem button onClick={handleClickSubDomain} styleName="expandHeader">
                <Typography variant="body1" styleName="expandTitle">
                    {subDomain.Name}
                    <span styleName="expandIcon">{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                </Typography>
            </ListItem>
            <Collapse in={expand} timeout={500}>
                {subDomain.DataFields && (
                    <DataDictionaryTable
                        subDomainId={subDomain.Id}
                        domainIndex={domainIndex}
                        mountAllFields={mountAllFields}
                        editable={editable}
                    />
                )}
            </Collapse>
        </List>
    );
};
