import {config} from '~/config';
import {httpRequest} from '~/services/middleware';
import {IMonthlyReviewResponse, IUser, IUserResponse, IUserSearchParams, IUserSearchResults} from '~/interfaces/admin';
import {IClientUserOpsUnit, ISurvey, ISurveyResponse, ISurveyTrigger} from '~/interfaces/clientUser';
import {IUserNotification, IUserFlags} from '~/interfaces/common';
import {isResponseOk} from '~/utils/apiResponseUtils';
import {notificationsLimit} from '~/constants';

export const getUsers = async (teamId: number): Promise<IUser[] | null> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/users`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getUsersFromOpsUnit = async (opsUnitId: number): Promise<IUser[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/ops-units/${opsUnitId}/users`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getUser = async (userId: number): Promise<IUser> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/users/${userId}`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createUser = async (teamId: number, user: IUser): Promise<IUserResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/teams/${teamId}/user`,
        data: JSON.stringify(user),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateUser = async (user: IUser) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/users/${user.Id}`,
        data: JSON.stringify(user),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateLastAgreedDate = async (): Promise<string> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/agree-to-terms-and-conditions`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error(response.status.toString());
    }

    return response.data;
};

export const deleteUser = async (userId: number, deletedReason: string): Promise<IUserResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/users/${userId}`,
        data: JSON.stringify({DeletedReason: deletedReason}),
        method: 'DELETE',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }

    return response.data;
};

export const activateUser = async (userId: number) => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/users/${userId}/reactivate`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const generateReport = async (): Promise<IClientUserOpsUnit> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/generate-report`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createSurvey = async (survey: ISurvey): Promise<ISurveyResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/survey`,
        data: JSON.stringify(survey),
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getSurveyTrigger = async (): Promise<ISurveyTrigger> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/survey-trigger`,
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const getUserNotifications = async (): Promise<IUserNotification[]> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/notifications/${notificationsLimit}`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateUserNotifications = async () => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/notifications`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const getRequiresMonthlyReview = async (): Promise<IMonthlyReviewResponse> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/monthly-reviews`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const createMonthlyReview = async () => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/monthly-reviews`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const createTeamAndAdminMonthlyReview = async () => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/team-and-admin-monthly-reviews`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
};

export const searchUser = async (searchParams: IUserSearchParams): Promise<IUserSearchResults> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/search/user`,
        method: 'POST',
        data: JSON.stringify(searchParams),
    });
    if (!isResponseOk(response.status)) {
        throw new Error();
    }
    return response.data;
};

export const updateAcknowledgeAdvisoryDate = async (): Promise<string> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/acknowledge-to-advisory-note`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error(response.status.toString());
    }

    return response.data;
};

export const getUserFlags = async (): Promise<IUserFlags> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/user-flags`,
        method: 'GET',
    });
    if (!isResponseOk(response.status)) {
        throw new Error(response.status.toString());
    }

    return response.data;
};

export const updateUserFlags = async (flagName: string): Promise<void> => {
    const response = await httpRequest({
        url: `${config.api.ACCESS_CONTROL_SERVICE}/user/update-user-flags/${flagName}`,
        method: 'POST',
    });
    if (!isResponseOk(response.status)) {
        throw new Error(response.status.toString());
    }
};
